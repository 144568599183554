import "./customDialogStyle.css";
import { Dialog } from "primereact/dialog";
import { Text } from "../Text";

const CustomDialog = ({
  header = () => {},
  visible,
  footer,
  onHide = () => {},
  className = "",
  prevCss = true,
  children,
  style = {},
  showHeader = true
}) => {
  return (
    <Dialog
      header={header}
      visible={visible}
      onHide={onHide}
      footer={footer}
      className={`${prevCss ? `custom-dialog` : ``} ${className}`}
      style={style}
      headerStyle={{display:showHeader ? "flex" : "none"}}
    >
      {children}
    </Dialog>
  );
};

export default CustomDialog;
