import "./style.css";
import { BreadCrumb } from "primereact/breadcrumb";
export default function CustomBreadcrumb({
  home = { icon: "pi pi-home" },
  itemslist = [],
}) {
  return (
    <BreadCrumb model={itemslist} home={home} className="custom-breadcumb" />
  );
}
