import styles from "./style.module.css";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../api/axios.instance";
import { useNavigate, useParams } from "react-router";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { CustomButton } from "../../components/CustomButton";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import { Text } from "../../components/Text";
import CustomInputText from "../../components/common/CustomInputText";
import { sortAlphabeticalObjectArr } from "../../utils/tableUtils";
import CustomDropdown from "../../components/common/CustomDropdown";
import CustomInputTextArea from "../../components/common/CustomInputTextArea";
import CustomInputNumber from "../../components/common/CustomInputNumber";
import CustomInputPrice from "../../components/common/CustomInputPrice";
import { useRef } from "react";
import {
  addProduct,
  changeMode,
  getProductbyId,
  resetAll,
  updateProduct,
} from "../../reducers/productTableSlice";
import { useLayout } from "../../context/LayoutContext";
import CustomSaveModel from "../../components/CustomeSaveModel";
import CONSTANTS from "../../config/Constants";
import * as Messag from "../../config/ToastMessage";
import { v4 as uuidv4 } from "uuid";
import { RadioButton } from "primereact/radiobutton";
import Notice from "../../components/Alert/Notice";
import { changeShowNotice } from "../../reducers/appSlice";
import ImagePreview from "./ImagePreview";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import { useUtility } from "../../context/UtilityContext";

const statusOption = [
  { key: "Available", value: "Available" },
  { key: "Unavailable", value: "Unavailable" },
];
//

function NewProduct({}) {
  const [defaultFormValues, setdefaultFormValues] = useState({});
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState([]);
  const [savedImages, setSavedImage] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [prodType, setProdType] = useState("item");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { toast, setLoading } = useLayout(null);
  const mediaFileRef = useRef(null);
  const { accesskey } = useSelector((state) => state.authenticate);
  const { id } = useParams();

  const { loading, mode, selectedProduct } = useSelector(
    (state) => state.productTable
  );

  const { showNotice } = useSelector((state) => state.application);

  const breadcumbHome = { label: "Products", url: "/products/list" };
  const breadcumnList = [
    { label: `${id ? "Update" : "New"} Product`, url: "#" },
    // { label: `${mode == "update" ? "Update" : "New"} Product`, url: "#" },
  ];

  const defaultValues = {
    productName: "",
    categoryId: "",
    quantity: undefined,
    itemsInPack: null,
    price: undefined,
    status: "",
    SKUCode: "",
    desc: "",
  };

  //function form get error message
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const handleSelectedImage = (e) => {
    let newImgLists = [...e.target.files]?.map((imgFile) => {
      return {
        id: uuidv4(),
        image: imgFile,
      };
    });
    setImages((prev) => [...newImgLists, ...prev]);
  };

  // const { id } = useParams();

  // useEffect(() => {
  //   if (id) {
  //     dispatch(getProductbyId({ productId: id })).then((e) => {});
  //   }
  // }, [id]);

  const typechecker = (selectedImage) => {
    if (
      selectedImage.type === "image/png" ||
      selectedImage.type === "image/jpeg"
    ) {
      return true;
    }
    return false;
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({ defaultValues });

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const [showSave, setShowSave] = useState(false);

  const onSubmit = (data) => {
    if (savedImages.length == 0 && newImages.length == 0) {
      toast.current.show({
        severity: "error",
        detail: "Please add product image.",
      });
      setShowSave(false);
      return;
    }

    let configData = {
      ...data,
      productName:
        prodType == "pack"
          ? `${data.productName} (pack of ${data.itemsInPack} items)`
          : data.productName,
      isActive: 1,
    };

    delete configData.itemsInPack;
    const newImagesToAdd = newImages?.map((img) => {
      delete img.id;
      return img;
    });
    if (mode === "update" || id) {
      const productId = selectedProduct?.id ? selectedProduct?.id : id;

      dispatch(
        updateProduct({
          productId,
          data: {
            ...configData,
            images: [...newImagesToAdd, ...deletedImages],
          },
        })
      )
        .unwrap()
        .then((res) => {
          // dispatch(changeShowNotice(true));
          let Message_Success = Messag.Update_Product_ToastSuccessMessage;
          toast.current.show({
            severity: "success",
            detail: Message_Success,
          });
          navigate(`/products/list`);
        })
        .catch((err) => {
          toast.current.show({ severity: "error", detail: err.message });
        });
    } else {
      dispatch(
        addProduct({
          data: { ...configData, images: [...newImagesToAdd] },
        })
      )
        .unwrap()
        .then(() => {
          // dispatch(changeShowNotice(true));
          let Message_Success = Messag.Add_Product_ToastSuccessMessage;
          toast.current.show({
            severity: "success",
            detail: Message_Success,
          });
          navigate(`/products/list`);
        })
        .catch((err) => {
          toast.current.show({ severity: "error", detail: err.message });
        });
    }
    setShowSave(false);
  };

  useEffect(() => {
    dispatch(changeShowNotice(true));
  }, []);

  const SaveModel = () => {
    return (
      <CustomSaveModel
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };

  const alertText = () => {
    return <div>{CONSTANTS.PRODUCT_SAVE_CONFIMATION}</div>;
  };

  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false);
          }}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    );
  };
  const { setAlert, navigation } = useUtility();
  const onHide = () => {
    setShowSave(false);
  };
  useEffect(() => {
    return () => {
      setAlert(false);
    };
  }, []);

  const handleSaveImages = (img) => {
    setNewImages((prev) => [...prev, img]);
  };
  const handleRemoveImage = (remove_img, isUploaded) => {
    if (isUploaded) {
      setNewImages((prev) => prev?.filter((img) => img?.id != remove_img?.id));
    }
    setImages((prev) => prev?.filter((img) => img.id !== remove_img.id));
  };

  const deleteProdImg = (image) => {
    setSavedImage((prev) => prev?.filter((img) => img?.id != image?.id));
    setDeletedImages((prev) => [...prev, { id: image?.id, isActive: false }]);
  };

  let itemsInPack = null;

  useEffect(() => {
    //fetch all category list for dropdown
    axiosInstance
      .get(`/category?page=0&limit=100000&isActive=1`)
      .then((resp) => {
        let sortedCateGories = sortAlphabeticalObjectArr(
          resp.data.rows,
          "categoryName"
        );
        setCategories(sortedCateGories);
      })
      .catch((err) => {
        console.error(err);
      });

    if (id) {
      // let itemsInPack = null
      dispatch(changeMode("update"));
      dispatch(getProductbyId({ productId: id })).then((res) => {
        let prodData = res?.payload;
        let prodName = prodData.productName;

        if (prodData.productName.includes("pack of")) {
          let splitedProdName = prodData.productName.split(" (pack of");
          prodName = splitedProdName[0];
          let noOfItem = splitedProdName[1].split(" ")[1];

          setProdType("pack");
          itemsInPack = noOfItem;
        } else {
          setProdType("item");
        }

        setValue("productName", prodName, {
          shouldValidate: true,
        });
        setValue("categoryId", prodData.categoryId, {
          shouldValidate: true,
        });
        setValue("quantity", prodData?.quantity || 0, {
          shouldValidate: true,
        });
        setValue("price", prodData?.price || 0, { shouldValidate: true });
        setValue("status", prodData.status, { shouldValidate: true });
        setValue("SKUCode", prodData.SKUCode, { shouldValidate: true });
        setValue("desc", prodData?.desc ? prodData?.desc : " ", {
          shouldValidate: true,
        });
        setSavedImage(prodData?.images);

        if (itemsInPack) {
          setValue("itemsInPack", itemsInPack, { shouldValidate: true });
        }
        const sec = getValues();
        setdefaultFormValues({ ...sec });
      });
    }

    return () => {
      dispatch(resetAll());
    };
  }, [id]);

  const onReset = () => {
    setValue("productName", defaultFormValues.productName);
    setValue("categoryId", defaultFormValues.categoryId);
    setValue("status", defaultFormValues.status);
    setValue("price", defaultFormValues.price);
    setValue("quantity", defaultFormValues.quantity);
    setValue("desc", defaultFormValues.desc);
    if (defaultFormValues.itemsInPack) {
      setProdType("pack");
    } else {
      setProdType("item");
    }
    setValue("itemsInPack", defaultFormValues.itemsInPack);
  };

  return (
    <div>
      <CustomBreadcrumb home={breadcumbHome} itemslist={breadcumnList} />
      {showSave && <SaveModel />}
      <div className="m-3">
        {showNotice && (
          <Notice message={"Products will be uploaded every midnight."} />
        )}
        <TableActionsContainer>
          <div className="hidden sm:hidden md:flex justify-content-between align-items-center gap-3 flex-wrap">
            <Text type={"heading"}>Product Information</Text>
            <div className="flex gap-2">
              <CustomButton
                varient="text"
                label={"Cancel"}
                onClick={() => navigate("/products/list")}
              />
              <CustomButton
                varient="outline"
                label={"Reset"}
                onClick={() => (id ? onReset() : reset())}
              />
              {accesskey[modulesName.PRODUCT]?.includes(
                `${mode == "update" ? permissions.UPDATE : permissions.CREATE}`
              ) && (
                <CustomButton
                  varient="filled"
                  label={mode == "update" ? "Update" : "Save"}
                  onClick={handleSubmit(() => {
                    setShowSave(true);
                  })}
                  disabled={newImages.length != images.length}
                />
              )}
            </div>
          </div>
          {/* ***** For Mobile Screens ***** */}
          <div className="hidden sm:flex md:hidden flex flex-column justify-content-between align-items-start gap-3 flex-wrap">
            <div className="flex justify-content-start">
              <Text type={"heading"}>Product Information</Text>
            </div>
            <div className="flex justify-content-end  w-full ">
              <div className="flex gap-3">
                <CustomButton
                  varient="text"
                  label={"Cancel"}
                  onClick={() => navigation("/products/list")}
                />
                <CustomButton
                  varient="outline"
                  label={"Reset"}
                  onClick={() => (id ? onReset() : reset())}
                />
                {accesskey[modulesName.PRODUCT]?.includes(
                  `${
                    mode == "update" ? permissions.UPDATE : permissions.CREATE
                  }`
                ) && (
                  <CustomButton
                    varient="filled"
                    label={mode == "update" ? "Update" : "Save"}
                    onClick={handleSubmit(() => {
                      setShowSave(true);
                    })}
                    disabled={
                      mode == "create"
                        ? !(savedImages.length == images.length)
                        : !(newImages.length == images.length)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </TableActionsContainer>
      </div>
      <div className={`mx-3 p-3 ${styles["form-container"]}`}>
        <form
          onSubmit={handleSubmit(() => {
            setShowSave(true);
          })}
          onChange={() => setAlert(true)}
        >
          <div className="w-full flex flex-column lg:flex-row flex-wrap gap-2 mb-4">
            <div className="flex-1">
              <CustomInputText
                placeholer="Product Name"
                label="Name "
                required={true}
                control={control}
                name="productName"
                maxLength={200}
              />
              {getFormErrorMessage("productName")}
            </div>
            <div className="flex-1">
              <CustomInputText
                placeholer="xx-0000"
                label="SKU Code"
                required={true}
                control={control}
                name="SKUCode"
                rules={{
                  pattern: {
                    value: /^[a-zA-Z0-9-]*$/,
                    message:
                      "Only alphanumeric characters without white spaces are allowed.",
                  },
                }}
                style={{ textTransform: "uppercase" }}
                maxLength={20}
                disable={id ? true : false}
              />
              {getFormErrorMessage("SKUCode")}
            </div>
            <div className="flex-1">
              <CustomDropdown
                label="Category "
                placeholder="Choose"
                control={control}
                name="categoryId"
                optionLabel="categoryName"
                optionValue="id"
                isMandatory={true}
                options={categories}
              />
              {getFormErrorMessage("categoryId")}
            </div>
            <div className="flex-1">
              <CustomDropdown
                label="Stock "
                placeholder="Choose"
                control={control}
                name="status"
                optionLabel="key"
                optionValue="value"
                isMandatory={true}
                options={statusOption}
              />
              {getFormErrorMessage("status")}
            </div>
          </div>
          <div className="w-full flex flex-wrap align-items-center gap-2 mb-4">
            <div className="flex-1">
              <CustomInputPrice
                label={"Price"}
                placeholder="Enter Price"
                control={control}
                name="price"
                isMandatory={false}
              />
            </div>
            <div className="flex-1">
              <CustomInputNumber
                label="Quantity in stock"
                placeholder="Enter Quantity"
                name="quantity"
                control={control}
                isMandatory={true}
              />
              {getFormErrorMessage("quantity")}
            </div>

            <div className={`flex-1 flex-wrap gap-2 align-items-center`}>
              <label>Packing*</label>
              <div className="w-full flex-1 flex flex-wrap gap-2 my-2">
                <div className="flex-1 flex  gap-3">
                  <div className="flex align-items-center">
                    <RadioButton
                      inputId="prodType1"
                      name="prodType"
                      value="item"
                      onChange={(e) => setProdType(e.value)}
                      checked={prodType === "item"}
                    />
                    <label htmlFor="prodType1" className="ml-2">
                      Single
                    </label>
                  </div>
                  <div className="flex align-items-center">
                    <RadioButton
                      inputId="prodType2"
                      name="ProdType"
                      value="pack"
                      onChange={(e) => setProdType(e.value)}
                      checked={prodType === "pack"}
                    />
                    <label htmlFor="prodType2" className="ml-2">
                      Bundle
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {prodType == "pack" ? (
              <div className="flex-1">
                <CustomInputNumber
                  label="Items in bundle"
                  placeholder="Number of items in bundle"
                  name="itemsInPack"
                  control={control}
                  isMandatory={true}
                />
                {getFormErrorMessage("itemsInPack")}
              </div>
            ) : (
              <div className="flex-1"></div>
            )}
          </div>

          <div className="w-full lg:w-6 mb-4">
            <CustomInputTextArea
              label="Descriptions "
              placeholder="Product description ..."
              name="desc"
              control={control}
              className="firstcapital"
              isMandatory={false}
              rules={{
                required: false,
                pattern: {
                  value: /[ a-z]/,
                  message:
                    "Description must have upper and lower case characters",
                },
              }}
            />
            {getFormErrorMessage("desc")}
          </div>
          <div>
            <div className="flex justfy-content-center align-items-center gap-2 w-full lg:w-6">
              <input
                ref={mediaFileRef}
                type="file"
                accept={`image/*`}
                hidden
                onChange={handleSelectedImage}
                multiple
              />
              <button
                type="button"
                className="w-full flex justify-content-center gap-3 p-3 cursor-pointer"
                onClick={() => mediaFileRef.current.click()}
                style={{ border: "1px dashed #5E5E5E", background: "#EDEDED" }}
              >
                <i className={`pi pi-image`} />

                <Text type={"sub-heading"}>Capture Or Upload Image</Text>
              </button>
            </div>
          </div>
          <div className="w-full lg:w-6">
            <div className="flex gap-2 flex-wrap flex-row  mt-3">
              {images?.map((image) => (
                <ImagePreview
                  imgId={image.id}
                  image={image.image}
                  removeImgClick={handleRemoveImage}
                  handleSaveImages={handleSaveImages}
                  mode={mode}
                  isSaved={false}
                  key={image.id}
                />
              ))}
              {savedImages?.map((image) => (
                <ImagePreview
                  image={image}
                  removeImgClick={deleteProdImg}
                  mode={mode}
                  isSaved={true}
                  key={image.id}
                />
              ))}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewProduct;
