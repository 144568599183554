import React, { useEffect, useRef, useState } from "react";
import styles from "./replyMessageModal.module.css";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Text } from "../../components/Text";
import { Image } from "primereact/image";
import { formatMobNumber } from "../../utils/commonUtils";

const ReplayMessageModal = ({ message, key_id = "" }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const { user } = useSelector((state) => state.enqChats);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  const msgBody = () => {
    return (
      <>
        <div className="flex flex-column  w-full gap-1">
          {message?.messageType === "outbound" ? (
            <div className=" w-full flex flex-column gap-2">
              <Text type={"heading"}>You</Text>
              {message.message.messageType === "image" && (
                <i className="pi pi-camera" style={{ fontSize: "1rem" }}></i>
              )}
            </div>
          ) : (
            <div className="w-full">
              {user?.displayName ? (
                <div className=" w-full">
                  <Text type={"heading"}>
                    {user?.displayName &&
                      user?.displayName?.charAt(0)?.toUpperCase() +
                        user?.displayName?.substring(1)}
                  </Text>
                </div>
              ) : (
                <div className="w-full ">
                  <Text type={"heading"}> {formatMobNumber(user?.mob)} </Text>
                  {/* <Text type={"heading"}>{user?.mob}</Text> */}
                </div>
              )}
            </div>
          )}
          <Text type={"sub-heading"}>
            {truncateText(message?.message?.text)?.split("\n")[0]}
          </Text>
          <Text type={"sub-heading"}>
            {truncateText(message?.message?.text, 50)?.split("\n")[1]}
          </Text>
        </div>
      </>
    );
  };

  const OrderTable = (items) => {
    const limitedItems = items.slice(0, 3);
    return (
      <div
        className={`${styles.orderCard}`}
        style={{
          borderRadius: "4px",
          overflow: "scroll",
          //   marginTop:"120px",
          height: "80px",
        }}
      >
        <DataTable
          //   className="skalebot-table border-1"
          className={`${styles.orderTable} `}
          value={limitedItems}
          //   tableStyle={{ minWidth: "1rem", }}
        >
          <Column field="id" header="id"></Column>
          <Column field="amount" header="amount"></Column>
          <Column field="quantity" header="Quantity"></Column>
        </DataTable>
      </div>
    );
  };

  const CampaignLink = (msg) => {
    let date = formatAMPM(new Date(message?.lastUpdatedAt));
    return (
      <>
        <div className="flex w-full justify-content-end align-items-center">
          <Link to={`/${msg.link}`}>Go to Campaign</Link>
        </div>
      </>
    );
  };

  const truncateText = (text, maxLength) => {
    const lines = text.split("\n");

    // If there's more than one line, truncate the second line
    if (lines.length > 1) {
      const secondLine = lines[1];
      // Truncate the second line to maxLength characters
      if (secondLine.length > maxLength) {
        lines[1] = secondLine.substring(0, maxLength) + "...";
      }
    }
    // Join the lines back together and return
    return lines.join("\n");
  };

  const body = ({ iconName }) => {
    const renderIcon = (messageType) => {
      const iconTypes = [
        "image",
        "video",
        "audio",
        "file",
        "link",
        "order",
        "location",
      ];
      if (iconTypes.includes(messageType)) {
        return (
          <i className={`pi ${iconName}`} style={{ fontSize: "1rem" }}></i>
        );
      }
      return null;
    };

    const renderMessageContent = (messageType) => {
      return (
        <div className="w-full flex flex-column gap-2">
          {renderIcon(messageType)}
        </div>
      );
    };

    const displayName = user?.displayName
      ? user.displayName.charAt(0).toUpperCase() + user.displayName.slice(1)
      : null;

    return (
      <div className="flex flex-column w-full gap-1">
        {message?.messageType === "outbound" ? (
          <div className="w-full flex flex-column gap-2">
            <Text type={"heading"}>You</Text>
            {renderMessageContent(message.message.messageType)}
          </div>
        ) : (
          <div className="w-full">
            {user?.displayName ? (
              <div className="w-full flex flex-column gap-2">
                <Text type={"heading"}>{displayName}</Text>
                {renderMessageContent(message.message.messageType)}
              </div>
            ) : (
              <div className="w-full flex flex-column gap-2">
                <Text type={"heading"}>{formatMobNumber(user?.mob)}</Text>
                {renderMessageContent(message.message.messageType)}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <div key={key_id} className={styles.message}>
        <div className={styles.messageContent}>
          <div className="flex flex-row align-items-center justify-content-between w-full ">
            <div
              className={
                message.message.messageType == "audio" ||
                message.message.messageType == "file"
                  ? "ml-0 w-full"
                  : `{ ${styles.container} w-12 ml-0 f }`
              }
            >
              {message.message.messageType == "image" && (
                <div className={styles.chatImgContainer} style={{}}>
                  {body({ iconName: "pi-camera" })}
                  <div className={styles.imgContainer}>
                    <Image
                      src={
                        message.message.url || "../images/ImgPlaceholder.svg"
                      }
                      zoomSrc={
                        message.message.url || "./images/ImgPlaceholder.svg"
                      }
                      onError={(e) =>
                        (e.target.src = "./images/ImgPlaceholder.svg")
                      }
                      alt=""
                      width="100%"
                      height="100%"
                      style={{
                        width: "100px",
                        height: "80px",
                        aspectRatio: "auto",
                        maxWidth: "100px",
                      }}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   e.stopPropagation();
                      // }}
                      className={styles.img}
                    />
                  </div>
                </div>
              )}
              {message.message.messageType == "video" && (
                <div
                  className={`${styles.chatImgContainer} w-full  flex justify-content-between`}
                >
                  {body({ iconName: "pi-video" })}
                  <video
                    id="videoPlayer"
                    width={"100%"}
                    height={"250px"}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      maxWidth: "100px",
                      maxHeight: "80px",
                      aspectRatio: "auto",
                    }}
                  >
                    <source src={message.message.url} type="video/mp4" />
                  </video>
                </div>
              )}
              {message.message.messageType == "file" && (
                <div className={styles.chatImgContainer}>
                  {body({ iconName: "pi-file" })}
                  <div
                    className={`flex ${styles.__file} justify-content-center align-items-center`}
                  >
                    <i
                      className="pi pi-file "
                      style={{ fontSize: "38px", color: "gray" }}
                    />
                    <div className="ml-1 flex flex-column  w-8 lg:w-full ">
                      <span
                        style={{ fontSize: "15px", color: "gray" }}
                        className="overflow-hidden"
                      >
                        {message.message.filename}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {message.message.messageType == "audio" && (
                <div className={styles.chatImgContainer}>
                  {body({ iconName: "pi-volume-up" })}
                  <audio
                    src={message.message.url}
                    controls
                    controlsList="nodownload noplaybackrate"
                    className={styles.audio}
                  />
                </div>
              )}
              {message.message.messageType === "link" && (
                <div className={styles.chatImgContainer}>
                  {body({ iconName: "pi-link" })}
                  {CampaignLink(message.message)}
                </div>
              )}
              {(message.message.messageType === "text" ||
                message.message.messageType === "address_message") && (
                <div className={styles.chatImgContainer}>{msgBody()}</div>
              )}
              {message.message.messageType === "location" && (
                <div className={styles.chatImgContainer}>
                  {body({ iconName: "pi pi-map-marker" })}
                  <div
                    className="chatImgContainer"
                    style={{
                      position: "relative",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={"./images/GooglemapMarker.svg"}
                      alt=""
                      preview
                      width="100%"
                      style={{
                        maxWidth: "400px",
                        aspectRatio: "auto",
                        border: ".5px solid #1c738e",
                        borderRadius: "6px",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "14px",
                      }}
                    >
                      <i
                        class="pi pi-map-marker"
                        style={{ fontSize: "1rem", top: "30%" }}
                      >
                        {" "}
                      </i>
                      Location
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-wrap gap-2">
            {(message.message.messageType == "list" ||
              message.message.messageType == "product_details") && (
              <div className="flex flex-column">
                {message.messageType === "outbound" ? (
                  <Text type={"heading"}>You</Text>
                ) : (
                  <div className="w-full">
                    {user?.displayName ? (
                      <div className=" w-full">
                        <Text type={"heading"}>
                          {user?.displayName &&
                            user.displayName.charAt(0).toUpperCase() +
                              user.displayName.substring(1)}
                        </Text>
                      </div>
                    ) : (
                      <div className="w-full ">
                        <Text type={"heading"}>
                          {" "}
                          {formatMobNumber(user?.mob)}{" "}
                        </Text>
                      </div>
                    )}
                  </div>
                )}

                <Text type={"sub-heading"}>
                  {truncateText(message?.message?.text).split("\n")[0]}
                </Text>
                <Text type={"sub-heading"}>
                  {truncateText(message?.message?.text, 50).split("\n")[1]}
                </Text>
              </div>
            )}
            {message.message.messageType == "order" && (
              <div
                className={styles.chatImgContainer}
                style={{ width: "100%" }}
              >
                {body({ iconName: "pi-shopping-cart" })}
                {OrderTable(message.message.orderItems)}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ReplayMessageModal;
