import { FileUpload } from "primereact/fileupload";
import "./index.css";

import Loader from "../Loader";
import { useState } from "react";
import { compressImg } from "../../utils/templateUtils";
export const CustomImageInput = ({ label, setSelectedImage, disabled }) => {
  const [load,setload]=useState(false)
  const handleUpload = async(e) => {
    setload(true);
    const imageFile = e.files[0];
    try {
      // console.log(imageFile.size)
      const compressedFile = await compressImg(imageFile);
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    let data =new File ([compressedFile], compressedFile.name, {type: compressedFile.type});
    await setSelectedImage(data)  
    setload(false);
  } catch (error) {
      console.log(error);
    }
    e.options.clear();
  };
  return (
    <div className="flex file-upload">
      <Loader visible={load}/>
      <FileUpload
        accept="image/*"
        mode="basic"
        auto
        customUpload={true}
        chooseLabel={label}
        uploadHandler={handleUpload}
        disabled={disabled}
      />
    </div>
  );
};
