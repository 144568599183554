import { InputTextarea } from "primereact/inputtextarea";
import { Controller } from "react-hook-form";
import { capitalizeFirstLetter } from "../../../utils/tableUtils";
import { useUtility } from "../../../context/UtilityContext";
function CustomInputTextArea({
  label = "",
  placeholder = "",
  className = "",
  name = "",
  rules = {},
  rows = 3,
  cols = 30,
  control = null,
  isMandatory = false,
  disable = false,
  capitalize = true,
  onChangealert=false
}) {
  const { setAlert }=useUtility()
  return (
    <div className="flex flex-column gap-2 flex-grow-1">
      <label htmlFor={name}>
        {label}
        {isMandatory && <span>*</span>}
      </label>
      <Controller
        name={name}
        control={control}
        rules={{
          required: isMandatory && `${label} is mandatory.`,
          ...rules,
        }}
        render={({ field, fieldState }) => (
          <InputTextarea
            id={field.name}
            rows={rows}
            cols={cols}
            className={`${className}`}
            {...field}
            autoResize
            placeholder={placeholder}
            disabled={disable}
            onChange={(e) => {
              onChangealert&&setAlert(true);
              capitalize
                ? field.onChange(capitalizeFirstLetter(e.target.value))
                : field.onChange(e.target.value);
            }}
          />
        )}
      />
    </div>
  );
}

export default CustomInputTextArea;
