import React, { useRef } from "react";
import { Button } from "primereact/button";
import { Galleria } from "primereact/galleria";
import Thumbnail from "./Thumbnail";
import styles from "./style.module.css";

export default function ImageGallary({ images = [] }) {
  const galleria = useRef(null);
  const mediaUrl = images?.mediaUrl;

  const itemTemplate = (item) => {
    return (
      <img
        src={item?.url?.trim() || mediaUrl}
        alt={item?.fileName}
        style={{ width: "80%", display: "block" }}
        // style={{ width: "100%", maxHeight:"400px", maxWidth:"300px" , aspectRatio:"16/16", objectFit:"contain" }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item?.url?.trim() || mediaUrl}
        alt={item.fileName}
        style={{ display: "block", maxHeight: "50px" }}
      />
    );
  };

  const handleThumbnailClick = () => {
    galleria.current.show();
  };

  return (
    <div className=" flex justify-content-center align-item-center">
      <Galleria
        ref={galleria}
        value={images}
        numVisible={9}
        style={{ maxWidth: "50%" }}
        circular
        fullScreen
        // showItemNavigators
        showItemNavigators={images?.length > 1}
        showThumbnails={false}
        item={itemTemplate}
        thumbnail={thumbnailTemplate}
      />
      <Thumbnail images={images} onClick={handleThumbnailClick} />
    </div>
  );
}
