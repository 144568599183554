import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { API_GET_WABAHEALTH } from "../../api/company.service";
import { useLayout } from "../../context/LayoutContext";
import { Skeleton } from "primereact/skeleton";

const StatusCard = ({ data }) => {
  const iconColors = [
    {
      status: ["active", "connected", "green"],
      color: "#4DAD01",
    },
    {
      status: ["flagged", "yellow"],
      color: "#F5B379",
    },
    {
      status: ["banned", "red"],
      color: "#E61E50",
    },
    {
      status: ["unavailabel"],
      color: "#808080",
    },
    {
      status: ["unknown"],
      color: "#B0B0B2",
    },
  ];
  const getIconColor = (status) => {
    for (let i = 0; i < iconColors.length; i++) {
      if (iconColors[i].status.includes(status)) {
        return iconColors[i].color;
      }
    }
  };
  const getTitle = (status) => {
    switch (status) {
      case "green":
        return "High";
      case "yellow":
        return "Medium";
      case "red":
        return "low";
      default:
        return status;
    }
  };
  return (
    <div className={`mr-4`}>
      <h4 className="m-0 white-space-nowrap capitalize">
        {data?.icon && (
          <i
            className={`${data.icon} text-xs mr-2 `}
            style={{ color: getIconColor(data?.status) || "inherit" }}
          />
        )}

        <span>{getTitle(data?.status)}</span>
      </h4>

      <p className="mt-1 mb-0 text-sm white-space-nowrap">{data.label}</p>
    </div>
  );
};
export default function PostpaidAccountInfo() {
  const [loading, setLoading] = useState(false);
  const [wabaHealthInfo, setWabaHealth] = useState(null);
  const { toast } = useLayout(null);

  useEffect(() => {
    setLoading(true);
    API_GET_WABAHEALTH()
      .then((data) => {
        let wabaHealth = data?.wabaInfo;
        const details = [
          {
            icon: "pi pi-circle-fill",
            status: wabaHealth?.accountStatus?.toLowerCase() || "N/A",
            label: "Account Status",
            id: "akannw234",
          },
          {
            icon: "pi pi-circle-fill",
            status: wabaHealth?.dockerStatus?.toLowerCase() || "N/A",
            label: "Docker Status",
            id: "akannw235",
          },
          {
            icon: "",
            status: wabaHealth?.messagingLimit?.toLowerCase() || "N/A",
            label: "Message limit",
            id: "akannw236",
          },
          {
            icon: "pi pi-circle-fill",
            status: wabaHealth?.phoneQuality?.toLowerCase() || "N/A",
            label: "Phone Quality",
            id: "akannw237",
          },
        ];

        if (wabaHealth?.accountStatus?.toLowerCase() == "banned") {
          setWabaHealth([details[0]]);
        } else {
          setWabaHealth(details);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          detail: `${err?.response?.data?.message || "Something went wrong!"}`,
        });
        console.log("err", err.response.data.message);
      });
  }, []);
  return (
    <div className={`p-3 w-full ${styles["proinfo-box"]} overflow-y-auto`}>
      {loading ? (
        <Skeleton width="w-full" height="3rem"></Skeleton>
      ) : (
        <div className="flex gap-4 align-items-end">
          {wabaHealthInfo?.map((item, i) => (
            <StatusCard key={item?.id || i} data={item} />
          ))}
        </div>
      )}
    </div>
  );
}
