import axiosInstance from "./axios.instance";

const API_GET_USAGE = async (
  pageNo = 1,
  limit = 30,
  startDate = null,
  endDate = null,
  filterData = [],
  globalFilterValue = null,
  companyids=[],
) => {
  try {
    let allFilter = "";
    const dates={
        from: startDate,
        to: endDate,
    }
    if (filterData?.length) {
      filterData.forEach(({ key, value }) => {
        if (key && value) {
          allFilter += `&${key}=${value}`;
        }
      });
    }

    if (globalFilterValue) {
      allFilter += `&global=${globalFilterValue}`;
    }

    if (startDate && endDate) {
      allFilter += `&startDate=${startDate}&endDate=${endDate}`;
    }
    const endpoint = `/analytics/usage?page=${pageNo}&limit=${limit}&isActive=1${allFilter}`;
    const resp = await axiosInstance.post(endpoint,{
      ...dates,
      companies:companyids,
    });
    return resp.data;
  } catch (err) {
    console.error("Error fetching usage data:", err.message);
    throw new Error("Failed to fetch usage data. Please try again.");
  }
};



export {
  API_GET_USAGE,
};
