import { Skeleton } from "primereact/skeleton";

function CustomSkeleton({parentcss,countline=1,width,height,style,shape,className}) {
   let arr=new Array(countline).fill(0)
   return ( <div className={parentcss}>
        {arr.map((x,index)=>{return <Skeleton
            key={index}
            shape={shape}
            style={style}
            height={height}
            width={width}
            className={className}
        />})}
    </div> );
}

export default CustomSkeleton;