import styles from "./style.module.css";
import { Text } from "../../../components/Text";
import { useState } from "react";
import { useEffect } from "react";
import {
  API_GET_AGENT_STATUS,
  API_GET_AGENT_TITCKETS,
  API_PUT_AGENT_STATUS,
} from "../../../api/enquiry.services";
import { ReactComponent as RefreshIcon } from "../../../assets/svgIcons/refresh.svg";
import { useSelector } from "react-redux";
import permissions from "../../../roles/permissions";
import modulesName from "../../../roles/modulesName";
function AgentPlace() {
  const { user,accesskey } = useSelector((state) => state.authenticate);
  const [agentTicketInfo, setAgentTicketInfo] = useState(null);

  const [isAgentAvailable, setAgentAvailable] = useState(false);

  const handleStatusChange = (newStatus) => {
    if (newStatus != isAgentAvailable) {
      setAgentAvailable(newStatus);
      API_PUT_AGENT_STATUS(user.id, {
        status: newStatus ? "Available" : "Unavailable",
      })
        .then((res) => {
          setAgentAvailable(newStatus);
        })
        .catch((err) => {
          setAgentAvailable(!newStatus);
          console.log("err", err);
        });
    }
  };
  const refreshAnalytics = () => {
    API_GET_AGENT_STATUS(user.id)
      .then((res) => {
        setAgentAvailable(res.status.toLowerCase() === "available");
      })
      .catch((err) => {
        console.log(err.response.data);
      });

    API_GET_AGENT_TITCKETS(user.id)
      .then((data) => {
        setAgentTicketInfo(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (user.id && !accesskey[modulesName.SALES]?.includes(permissions.UPDATE)) {
      API_GET_AGENT_STATUS(user.id)
        .then((res) => {
          setAgentAvailable(res.status.toLowerCase() === "available");
        })
        .catch((err) => {
          console.log(err.response.data);
        });

      API_GET_AGENT_TITCKETS(user.id)
        .then((data) => {
          setAgentTicketInfo(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);
  return (
    <div className={`${styles["agent-container"]} p-3`}>
      <div className="w-full flex justify-content-between align-items-center gap-2 flex-wrap">
        <div className={`${styles["info-container"]} p-2 flex flex-grow-1 `}>
          {/* <div>
            <Text type="sub-heading" color={"#000"}>
              Login Time: 3:30 PM
            </Text>
          </div> */}
          <div className="ticket-info flex justify-content-around align-items-center flex-grow-1 gap-2 flex-wrap">
            {agentTicketInfo == null ? (
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "1rem" }}
              ></i>
            ) : (
              <>
                <div
                  className={`cursor-pointer ${styles["refeshiconcontainer"]} p-1 px-2  border-round-md`}
                  onClick={refreshAnalytics}
                >
                  <div className={`${styles["refeshicon"]}`}>
                    <RefreshIcon />
                  </div>
                </div>
                <Text type="sub-heading">
                  Total:&nbsp;
                  <span style={{ fontWeight: "600" }}>
                    {agentTicketInfo?.totalTickets}
                  </span>
                </Text>
                <Text type="sub-heading">
                  New:&nbsp;
                  <span style={{ fontWeight: "600" }}>
                    {agentTicketInfo?.newTickets}
                  </span>
                </Text>
                <Text type="sub-heading">
                  In Progress:&nbsp;
                  <span style={{ fontWeight: "600" }}>
                    {agentTicketInfo?.activeTickets}
                  </span>
                </Text>
                <Text type="sub-heading">
                  Follow Up:&nbsp;
                  <span style={{ fontWeight: "600" }}>
                    {agentTicketInfo?.followUpTickets}
                  </span>
                </Text>
                <Text type="sub-heading">
                  Closed:&nbsp;
                  <span style={{ fontWeight: "600" }}>
                    {agentTicketInfo?.closedTickets}
                  </span>
                </Text>
              </>
            )}
          </div>
        </div>
        <div className="w-full md:w-3 flex justify-content-end align-items-center">
          <Text type="sub-heading">Status</Text>
          <div className="pl-3">
            <div className={`${styles["toggle-button"]} flex`}>
              <div
                className={`py-1 px-2 ${
                  isAgentAvailable && styles["available"]
                } ${styles["option"]} cursor-pointer`}
                onClick={() => handleStatusChange(true)}
              >
                <Text type={"sub-heading"}>On</Text>
              </div>
              <div
                className={`py-1 px-2 ${
                  !isAgentAvailable && styles["unavailable"]
                } ${styles["option"]} cursor-pointer`}
                onClick={() => handleStatusChange(false)}
              >
                <Text type={"sub-heading"}>Off</Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentPlace;
