import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { Text } from "../Text";
import { FileUpload } from "primereact/fileupload";
import { CustomButton } from "../CustomButton";
import { useEffect } from "react";
import CSVDataPreviewTable from "../CSVDataPreviewTable";
import ColumnMapping from "../CSVDataPreviewTable/ColumnMapping";
import * as XLSX from "xlsx";
import { importSheetStemps } from "../../utils/csvUtils";
import ImportValidationInfo from "./ImportValidationInfo";

const CSVupload = ({
  showDialog = false,
  headerTitle = "Import data from SpreadSheet",
  targetColumns = [],
  dataToAdd = [],
  sampleFileUrl = "",
  validateData = () => {},
  handleSaveData = () => {},
  hideDialog = () => {},
  handleDataChange = () => {},
  resetSelectedData = () => {},
}) => {
  const [currImportStep, setImportStep] = useState(importSheetStemps[0].value);
  const [file, setFile] = useState(null);
  const [importedData, setImportedData] = useState(null);
  const [importedCSVHeaders, setImportedDataHeaders] = useState(null);
  const [savedTarget, setSavedTarget] = useState(targetColumns);
  const [validNextClick, setValidNextClick] = useState(false);

  const handleChangeTarget = (targetCol, newTarget) => {
    setSavedTarget((prev) => {
      return prev?.map((col) => {
        if (col.label?.toLowerCase() == targetCol.label?.toLowerCase()) {
          return { ...col, spreadSheetColumn: newTarget };
        } else return col;
      });
    });
  };
  const handleFileUpload = (event) => {
    let excelSheet = event.files[0];

    setFile(event.files[0]);

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0]; // Assuming the data is in the first sheet
      const sheet = workbook.Sheets[sheetName];

      const jsonResult = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      // Extract headers from the first row
      const headers = jsonResult.length > 0 ? jsonResult[0] : [];
      // Create an array of objects where each object represents a row with headers as keys
      const jsonDataWithHeaders = jsonResult.slice(1).map((row) =>
        headers.reduce((acc, header, index) => {
          acc[header] = row[index];
          return acc;
        }, {})
      );
      let formatedHeaders = headers.map((header) => {
        let colHeader = {
          field: header,
          header: header,
        };
        return colHeader;
      });
      setImportedDataHeaders(formatedHeaders);
      setImportedData(jsonDataWithHeaders);
    };

    reader.readAsBinaryString(excelSheet);
  };

  const handleHideDialog = () => {
    hideDialog();
    setImportedData([]);
  };

  const dialogHeader = () => {
    return <Text type={"heading"}>{headerTitle}</Text>;
  };

  const handleNextClick = (currStep) => {
    switch (currStep) {
      case "import":
        setImportStep(importSheetStemps[1].value);
        break;
      case "map":
        setImportStep(importSheetStemps[2].value);
        break;
      case "format_cell":
        setImportStep(importSheetStemps[3].value);
        break;
      default:
        break;
    }
  };

  const handlePrevClick = (currStep) => {
    switch (currStep) {
      case "validate":
        setImportStep(importSheetStemps[2].value);
        break;
      case "format_cell":
        resetSelectedData();
        setImportStep(importSheetStemps[1].value);
        break;
      case "map":
        setImportStep(importSheetStemps[0].value);
      default:
        break;
    }
  };

  const FileSelect = () => {
    return (
      <div className="mt-4">
        <div className="">
          <Text>Select your file to upload.</Text>
          <div className="w-full flex justify-content-between align-items-center mt-3">
            <div className="flex align-items-center gap-2 flex-grow-1">
              <FileUpload
                mode="basic"
                accept=".xlsx, .csv"
                chooseLabel="Choose File"
                className="file-upload"
                customUpload={true}
                uploadHandler={handleFileUpload}
                auto
              />
              {file != null ? (
                <Text>{file.name}</Text>
              ) : (
                <Text>{"No file choosen."}</Text>
              )}
            </div>
          </div>
          <div className="mt-3"></div>
          <Text>You can download the sample file for your refrence.</Text>
          <div className="flex my-2">
            <a href={sampleFileUrl} className="no-underline" download>
              <CustomButton varient="text" label={"Download Sample"} />
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderImportStepComponent = (currStep) => {
    switch (currStep) {
      case "import":
        return <FileSelect />;
      case "map":
        return (
          <ColumnMapping
            incommingColumns={importedCSVHeaders}
            targetColumns={savedTarget}
            handleChangeTarget={handleChangeTarget}
          />
        );
      case "format_cell":
        return (
          <CSVDataPreviewTable
            columns={savedTarget}
            data={importedData}
            selectedRow={dataToAdd}
            handleSelectRow={handleDataChange}
          />
        );
      case "validate":
        return <ImportValidationInfo handleValidate={validateData} />;
      default:
        <FileSelect />;
        break;
    }
  };

  useEffect(() => {
    const getTargetHeader = (target, arr) => {
      for (let i = 0; i < arr.length; i++) {
        if (target.label?.toLowerCase() == arr[i]?.header?.toLowerCase()) {
          return arr[i];
        }
      }
      return null;
    };
    if (importedCSVHeaders != null) {
      savedTarget?.forEach((target) => {
        let targetHeaderInspreadsheet = getTargetHeader(
          target,
          importedCSVHeaders
        );
        handleChangeTarget(target, targetHeaderInspreadsheet);
      });
    }
  }, [importedCSVHeaders]);

  useEffect(() => {
    switch (currImportStep) {
      case "import":
        if (file != null) {
          setValidNextClick(true);
        }
        break;
      case "map":
        let fieldValid = true;
        savedTarget?.forEach((field) => {
          if (field.isRequired && field.spreadSheetColumn == null) {
            fieldValid = false;
          }
        });
        setValidNextClick(fieldValid);
        break;
      case "format_cell":
        setValidNextClick(dataToAdd.length !== 0);
        break;
      default:
        break;
    }
  }, [currImportStep, savedTarget, dataToAdd]);

  useEffect(() => {
    return () => {
      resetSelectedData();
    };
  }, []);

  return (
    <Dialog
      header={dialogHeader}
      visible={showDialog}
      onHide={handleHideDialog}
      className={"w-11 md:w-9"}
    >
      <div>
        <div
          className="flex align-items-center gap-3"
          style={{ textWrap: "nowrap", overflowY: "auto" }}
        >
          {importSheetStemps?.map((step, i) => (
            <div key={step.value} className="flex">
              <Text
                type={currImportStep == step.value ? "heading" : "sub-heading"}
                color={currImportStep == step.value ? "#005068" : "grey"}
              >
                {step.key}
              </Text>
              {i < importSheetStemps.length - 1 && (
                <i
                  className="pi pi-angle-right ml-3"
                  style={{ color: "grey" }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="mt-3">{renderImportStepComponent(currImportStep)}</div>
      <div className="mt-3">
        <div className="">
          <div className="flex gap-3 justify-content-between align-items-center">
            <div>
              {currImportStep !== "import" && (
                <CustomButton
                  varient="icon-button"
                  label={"Prev"}
                  onClick={() => handlePrevClick(currImportStep)}
                />
              )}
            </div>
            <div>
              {currImportStep == "validate" ? (
                <CustomButton
                  varient="filled"
                  label="Save"
                  onClick={handleSaveData}
                  disabled={dataToAdd.length == 0}
                />
              ) : (
                <CustomButton
                  varient="filled"
                  label={"Next"}
                  disabled={!validNextClick}
                  onClick={() => handleNextClick(currImportStep)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CSVupload;
