import { Dialog } from "primereact/dialog";
import { ReactComponent as Success } from "../../svg/Success.svg";
import { ReactComponent as Failure } from "../../svg/Failure.svg";
import { fetchINV } from "../../reducers/paymentSlice";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { Toast } from "primereact/toast";
function TransactionAlert({ status, transaction, setTransaction }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, transactionD } = useSelector((state) => state.payment);
  const handelclose = () => {
    setTransaction(false);
    window.location.href = "/dashboard";
  };
  const toast = useRef(null);
  const [clientDetail, setclientDetail] = useState({
    amount: "",
    txnid: "",
    productinfo: "",
    firstname: "",
    email: "",
    phone: "",
    address1: "",
  });
  useEffect(() => {
    if (status !== undefined && status.length > 5) {
      dispatch(fetchINV({ data: status }))
        .unwrap()
        .then((res) => {})
        .catch((err) => {
          navigate("/dashboard");
          // toast.current.show({ severity: "error", detail: err?.message });
        });
    }
  }, [status]);

  if (
    status !== undefined &&
    (transactionD.status === "success" ||transactionD.status === "captured"|| transactionD.status === "failure")
  ) {
    setTransaction(true);
    return (
      <>
        <Loader visible={loading} />
        <Toast ref={toast} />
        <Dialog
          visible={transaction}
          onHide={handelclose}
          className="w-10 xl:w-4 lg:w-5 flex m-auto"
        >
          <div className="w-10 m-auto flex flex-column mb-4">
            {sfImg(transactionD.status)}
            <div className="w-12 flex justify-content-between text-base my-2">
              <div>Transaction Status</div>
              <div>{transactionD.status === "captured"?"SUCCESSFULL":transactionD?.status?.toUpperCase()}</div>
            </div>
            <div className="w-12 flex justify-content-between text-base my-2">
              <div>Product info</div>
              <div>{transactionD?.notes?.productType}</div>
            </div>
            <div className="w-12 flex justify-content-between text-base my-2">
              <div>Mobile</div>
              <div>{transactionD?.contact}</div>
            </div>
            <div className="w-12 flex justify-content-between text-base my-2">
              <div>Email</div>
              <div>{transactionD?.email}</div>
            </div>
            <div className="w-12 flex justify-content-between text-base my-2">
              <div>Amount</div>
              <div>{transactionD?.notes?.amount}</div>
            </div>
            <div className="w-12 flex justify-content-between text-base my-2">
              <div>Conversations</div>
              <div className="font-bold">
                {(transactionD?.notes?.amount * 2 * 100) / 118}
              </div>
            </div>
            <div className="w-12 flex justify-content-between text-base my-2 ">
              <div className="w-4">Transaction&nbsp;Id</div>
              <div className="w-8 text-right">{transactionD?.id}</div>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
  return <Toast ref={toast} />;
}
function sfImg(status) {
  if (status == "SUCCESS" || status == "success"||status === "captured")
    return (
      <div className="w-12 flex justify-content-center text-2xl flex-column align-items-center mb-4">
        <div className="text-green-500  font-bold m-2">Payment Success</div>
        <div>
          <Success style={{ width: "100px" }} />
        </div>
      </div>
    );
  else
    return (
      <div className="w-12 flex justify-content-center text-2xl flex-column align-items-center mb-4">
        <div className=" m-2" style={{ color: "red" }}>
          Payment Failure
        </div>
        <div>
          <Failure style={{ width: "100px" }} />
        </div>
      </div>
    );
}

export default TransactionAlert;
