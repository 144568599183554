import styles from "./style.module.css";
import { Text } from "../../components/Text";
import { useState, useRef, useEffect } from "react";
import PaymentGateway from "../../components/PaymentGateway";
import { ReactComponent as Wallet } from "../../assets/svgIcons/wallet.svg";
import { Toast } from "primereact/toast";
import { useSelector, useDispatch } from "react-redux";
import { Skeleton } from "primereact/skeleton";
import DashboardCard from "../../components/Cards/DashboardCards";
import axiosInstance from "../../api/axios.instance";
import { getDate } from "../../utils/datemaker";
import { dateCompiler } from "../../utils/tableUtils";
import {ReactComponent as GreenTickIcon} from "../../assets/svgIcons/greentick.svg"
import {ReactComponent as DownarrowIcon} from "../../assets/downarrow.svg"
import {ReactComponent as DowngraphIcon} from "../../assets/downgraphgrey.svg"
import {ReactComponent as DowngraphRIcon} from "../../assets/downgraph.svg"
import {ReactComponent as UpgraphIcon} from "../../assets/upgraph.svg"
import { getCountAgent, getCountCampaign, getCountEnquiry, getCountOrder, getCountTickets } from "../../reducers/analyticSlice";
import { useLayout } from "../../context/LayoutContext";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";


function SectionSelection({analyticSection,setAnalyticSection}) {
  const dispatch = useDispatch();
  const {setLoading} = useLayout(null);
  const { overAllCount,analyticalDetails,skeletonloading, loading ,dateRange } = useSelector((state) => state.analytic);
  const { accesskey } = useSelector((state) => state.authenticate);
  
  const loderSkaleton = () => {
    return (
      <Skeleton
        width={"5rem"}
        height={"3rem"}
        borderRadius={"4px"}
        className="mb-2"
      />
    );
  };

  useEffect(()=>{
    setLoading(loading);
  },[loading])

  useEffect(()=>{
    dispatch(getCountOrder({dateRange}));
    dispatch(getCountEnquiry({dateRange}));
    dispatch(getCountCampaign({dateRange}));
    dispatch(getCountAgent({dateRange}));
    dispatch(getCountTickets({dateRange}));
  },[dateRange]);
  const countsmap=[
    {
      name:"Orders",
      sideLine:true,
      value:`${analyticalDetails?.order?.statistics?.allOrderCount|0}`,
      secondvalue:`₹${analyticalDetails?.order?.statistics?.totalOrderAmount|0}`,
      lineclass:``,
      skeleton:skeletonloading?.order,
      show:accesskey[modulesName.ORDER]?.includes(permissions.READ),
      growthdetails:analyticalDetails?.order?.percentageIncrease
    },
    {
      name:"Enquiries",
      sideLine:true,
      value:`${analyticalDetails?.enquiry?.statistics?.allEnquiriesCount|0}`,
      lineclass:``,
      skeleton:skeletonloading?.enquiry,
      show:accesskey[modulesName.ENQUIRES]?.includes(permissions.READ),
      growthdetails:analyticalDetails?.enquiry?.percentageIncrease
    },
    {
      name:"Campaigns",
      sideLine:true,
      value:`${analyticalDetails?.campaign?.statistics?.allCampaignsCount|0}`,
      lineclass:``,
      skeleton:skeletonloading?.campaign,
      show:accesskey[modulesName.CAMPAIGN]?.includes(permissions.READ),
      growthdetails:analyticalDetails?.campaign?.percentageIncrease
    },
    {
      name:"Agents",
      sideLine:true,
      value:`${analyticalDetails?.agent?.statistics?.allAgentsCount|0}`,
      lineclass:``,
      skeleton:skeletonloading?.agent,
      show:accesskey[modulesName.AGENT]?.includes(permissions.READ),
      growthdetails:analyticalDetails?.agent?.percentageIncrease
    },
    {
      name:"Tickets",
      sideLine:true,
      value:`${analyticalDetails?.ticket?.statistics?.allTicketsCount|0}`,
      lineclass:``,
      skeleton:skeletonloading?.ticket,
      show:accesskey[modulesName.TICKET]?.includes(permissions.READ),
      growthdetails:analyticalDetails?.ticket?.percentageIncrease
    },
  ]
  return (<div className={`w-full cp-10 flex flex-column  align-items-start  w-12 ${styles["record-wrap-stat"]}`}>
      <div className={`  ${styles["record-wrap-stat"]} flex flex-row gap-3 flex justify-content-between overflow-y-hidden overflow-x-auto w-full`}>
        {countsmap?.filter((x)=>x.show)?.map((x,index)=><div key={index}  className="flex flex-column justify-content-center cursor-pointer  lg:w-3">
          <div 
            className={`${styles['sectiontile']} justify-content-between border-round-xs  ${analyticSection===x.name?styles['sectiontile-onselect']:''}`} 
            onClick={()=>{setAnalyticSection(x.name)}}
            >
            <div className="flex flex-row justify-content-between md:gap-2 lg:gap-3  ">
              <div className="flex flex-column m-1 py-2 gap-2">
                {x?.skeleton?<Skeleton width="5rem"/>:<div className="font-semibold">
                  {x.value}
                </div>}
                {x?.skeleton?<Skeleton width="5rem"/>:<div>
                  {x.name}&nbsp;{x?.secondvalue?<>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<span className="font-bold">{x?.secondvalue}</span></>:``}
                </div>}
              </div>
              {x?.skeleton?<div className="pl-2 pt-2 mt-2"><Skeleton width="2rem" height="2rem"/></div>:<div className="flex flex-row gap-2 align-content-center align-items-center m-1 py-2">
                  <div>
                  {dateRange?.type!=="all"?<span>{x?.growthdetails>0 ? <UpgraphIcon/> :x?.growthdetails===0?<DowngraphIcon/>:<DowngraphRIcon/>}</span>:<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                  </div>
                  {dateRange?.type!=="all"?<div className="pb-1" style={{color:x?.growthdetails===0?'#C1C1C1':x?.growthdetails>0?'#00AB72':'#E61E50'}}>
                    {x?.growthdetails? `${Math.round(x?.growthdetails)}%`:<>0%</>}
                  </div>:<div className="pb-1">&nbsp;&nbsp;&nbsp;&nbsp;</div>}
              </div>}
            </div>
          </div>
          <div className={`w-12 flex justify-content-center pt-1 ${analyticSection===x.name?'':'opacity-0'} `} >
              <DownarrowIcon/>
          </div>
        </div>
        )}
      </div>
    </div>
    );
}

export default SectionSelection;