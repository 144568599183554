import { useNavigate } from "react-router";
import { CustomButton } from "../../components/CustomButton";
import { Text } from "../../components/Text";
import permissions from "../../roles/permissions";
import modulesName from "../../roles/modulesName";
import { useSelector } from "react-redux";
function PageNotFound() {
  const navigate = useNavigate();
  const { accesskey } = useSelector((state) => state.authenticate);
  const backToHome = () => {
    if (accesskey[modulesName.DASHBOARD]?.includes(permissions.READ)) {
      navigate("/dashboard");
    } else if (accesskey[modulesName.SALES]?.includes(permissions.READ)) {
      navigate("/chat");
    } else {
      navigate("/");
    }
  };
  return (
    <div className="h-screen">
      <div className="flex flex-column w-full h-full justify-content-center align-items-center">
        <div className="text-center">
          <h1 style={{ fontSize: "6rem", margin: "0px" }}>404 </h1>
          <Text color={"grey"}>
            <p style={{ fontSize: "3rem", margin: "1rem" }}>Page Not Found</p>
          </Text>
          <div className="flex justify-content-center">
            <CustomButton
              varient="text"
              icon="pi pi-arrow-left"
              label={"Back To Home"}
              onClick={backToHome}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default PageNotFound;
