import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { reseSelectedCustomers, resetPagination } from "../../reducers/customerTableSlice";

function Customer() {
  const location = useLocation();
  const currentPath = location.pathname;
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(reseSelectedCustomers());
      dispatch(resetPagination())
    };
  }, []);
  if (currentPath == "/customers" || currentPath == "/customers/") {
    return <Navigate to={"/customers/list"} replace />;
  }
  return <Outlet />;
}
export default Customer;
