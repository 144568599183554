import axiosInstance from "./axios.instance";

const API_GET_TEMPLATES = async () => {
  try {
    const resp = await axiosInstance.get(
      `/template?page=0&limit=10&templateType=default&isActive=1`
    );
    return resp.data.rows;
  } catch (err) {
    throw err;
  }
};

const API_GET_IMAGE_CONFIG = async (config) => {
  try {
    const resp = await axiosInstance.post(`/image`, config);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_ADD_CAMPAIGN = async (campaignData,schedule=false) => {
  try {
    var api_check=schedule?"/schedule":"";
    const resp = await axiosInstance.post(`/campaign${api_check}`, campaignData);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_PUT_CAMPAIGN = async (id,campaignData,schedule=false) => {
  try {
    
    var api_check=schedule?"/schedule":"";
    const resp = await axiosInstance.put(`/campaign${api_check}/${id}`, campaignData);
    return resp.data;
  } catch (err) {
    throw err;
  }
};



const API_GET_CAMPAIGNS = async (page,limit,startDate,endDate,globalFilterValue,schedule=false) => {
  try{
    
    var api_check=schedule?"/schedule":"";
    var resp;
    if (globalFilterValue || (startDate && endDate)) {
      let allFilter = "";
      if (globalFilterValue) {
        allFilter += `&global=${globalFilterValue}`;
      }
      if (startDate && endDate) {
        allFilter += `&startDate=${startDate}&endDate=${endDate}&isActive=1`;
      }
      resp = await axiosInstance.get(
        `/campaign${api_check}?page=${page}&limit=${limit}&${allFilter}`
         )
      return resp.data;
    }else{
       resp = await axiosInstance.get(
        `/campaign${api_check}?page=${page}&limit=${limit}`
      );  
      return resp.data;
    }
  } catch (err) {
    throw err;
  }
};

const API_GET_CAMPAIGN_BY_ID = async (id) => {
  try {
    if(id[0]=='s')
    {
      const resp = await axiosInstance.get(
        `/campaign/schedule/${id.slice(2)}`
        );
        return resp.data;
    }else {
      const resp = await axiosInstance.get(
        `/campaign/${id}`
        );
        return resp.data;
    }
  } catch (err) {
    throw err;
  }
};

const API_DEL_CAMPAIGN = async (id,schedule=true) => {
  try {
    var api_check=schedule?"/schedule":"";
    const resp = await axiosInstance.delete(`/campaign${api_check}/${id}`);
    return resp;
  } catch (err) {
    throw err;
  }
};

export {
  API_ADD_CAMPAIGN,
  API_GET_TEMPLATES,
  API_GET_IMAGE_CONFIG,
  API_GET_CAMPAIGNS,
  API_GET_CAMPAIGN_BY_ID,
  API_DEL_CAMPAIGN,
  API_PUT_CAMPAIGN,
}
