import { Dialog } from "primereact/dialog";
import styles from "./confirmation.style.module.css";
import { Text } from "../Text";

export const ConfirmationAlert = ({
  dialogFooter,
  displayConfirmation,
  alertText,
  onHide = () => {},
}) => {
  return (
    <Dialog
      header="Confirmation"
      visible={displayConfirmation}
      footer={dialogFooter}
      onHide={onHide}
      style={{ width: "45vw" }}
      className={"dialog-custom"}
    >
      <div className={`flex flex-column  gap-3`}>
        <Text type={"sub-heading"} cust={`${styles["alertText"]}`}>
          {alertText}
        </Text>
      </div>
    </Dialog>
  );
};
