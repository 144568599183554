import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import Loader from "../../components/Loader";
import { CustomButton } from "../../components/CustomButton";
import { AddNewCustomer } from "../../components/Forms/AddNewCustomer";
import { Column } from "primereact/column";
import { ReactComponent as Edit } from "../../svg/edit.svg";
import { ReactComponent as Delete } from "../../svg/delete.svg";
import { useDispatch, useSelector } from "react-redux";
import { DeleteAlert } from "../../components/Alert/DeleteAlert";
import CustomPaginator from "../../components/CustomPaginator";
import CreateGroup from "../../components/CreateGroup";
import "./style.css";
import {
  changeMode,
  changeSelectedCustomer,
  changeShowCustomersType,
  changePage,
  getCustomers,
  resetMode,
  resetSelectedCustomer,
  changelimit,
  getGroups,
} from "../../reducers/customerTableSlice";
import GroupTable from "./GroupTable";
import { Button } from "primereact/button";
import CSVupload from "../../components/CSVupload";
import GoogleContactsUpload from "../../components/GoogleContactsUpload";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { formatGoogleContacts } from "../../utils/tableUtils";
import { Menu } from "primereact/menu";
import { Text } from "../../components/Text";

import TableActionContainer from "../../components/CustomTable/TableActionsContainer";
import DebounceSearch from "../../components/common/DebounceSearch";
import CustomTabMenu from "../../components/CustomTabmenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TabMenu } from "primereact/tabmenu";
import { useLayout } from "../../context/LayoutContext";
import ImportGroupFromExcel from "./ImportGroupFromExcel";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";

const GroupList = () => {
  const { user } = useSelector((state) => state.authenticate);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [showCreateGropeModule, setShowCreateGroupModule] = useState(false);
  const [displayAlertDelete, setDisplayAlertDelete] = useState(false);
  const [csvUpload, setCsvUpload] = useState(false);
  const [showGoogleContacts, setShowGoogleContacts] = useState(false);
  const [googleContacts, setGoogleContacts] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const { accesskey } = useSelector((state) => state.authenticate);

  const items = [
    {
      label: "All Customers",
      icon: "",
      value: "all",
      route: "/customers/list",
      show:accesskey[modulesName.CUSTOMER]?.includes(permissions.READ)||accesskey[modulesName.CUSTOMERGROUP]?.includes(permissions.READ)
    },
    { label: "Groups", icon: "", value: "groups", route: "/customers/groups",
      show:accesskey[modulesName.CUSTOMERGROUP]?.includes(permissions.READ)
     },
  ];

  // const googleLogin = useGoogleLogin({
  //   onSuccess: async (tokenResponse) => {
  //     const contactUrl = `${process.env.REACT_APP_GOOGLE_CONTACT_API+process.env.REACT_APP_GOOGLE_API_KEY}`;
  //     const contacts = await axios.get(contactUrl, {
  //       headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
  //     });
  //     const formatedContactList = formatGoogleContacts(
  //       contacts.data.connections
  //     );
  //     setGoogleContacts(formatedContactList);
  //     setShowGoogleContacts(true);
  //   },
  //   scope: `${process.env.REACT_APP_GOOGLE_SCOPE}`,
  //   onError: (errorResponse) => console.error(errorResponse),
  // });

  const hideGoogleContacts = () => {
    setShowGoogleContacts(false);
    setGoogleContacts([]);
  };
  const menu = useRef(null);
  const dispatch = useDispatch();

  const importContactOptions = [
    {
      label: "Import from CSV",
      icon: "pi pi-file-excel",
      command: () => {
        setCsvUpload(true);
      },
    },
    // {
    //   label: "Import from Gmail(comming soon)",
    //   icon: "pi pi-google",
    //   command: () => {
    //     //googleLogin()
    //   },
    // },
  ];

  const {
    customerData,
    page,
    limit,
    loading,
    totalCustomerCount,
    showCustomersType,
    selectedGroupID,
  } = useSelector((state) => state.customerTable);

  const { toast, setLoading } = useLayout(null);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const onAddNewClick = () => {
    navigate(`/customers/gnew`);
  };

  const onHide = (reset) => {
    setDisplayBasic(false);
    dispatch(resetSelectedCustomer());
    dispatch(resetMode());
    reset();
  };
  const { id } = useParams();
  const location = useLocation();
  const [index, setIndex] = useState(1);

  const navigate = useNavigate();
  const handleEdit = (customer) => {
    dispatch(changeSelectedCustomer(customer));
    dispatch(changeMode("update"));
    navigate(`/customers/${customer.id}/update`);
  };

  const handleDelete = (customer) => {
    setDisplayAlertDelete(true);
    dispatch(changeSelectedCustomer(customer));
  };

  const deleteModule = () => {
    return (
      <DeleteAlert
        item="customer"
        displayAlertDelete={displayAlertDelete}
        setDisplayAlertDelete={setDisplayAlertDelete}
        toast={toast}
      />
    );
  };

  const addCustomerModal = () => {
    return (
      <AddNewCustomer
        onHide={onHide}
        displayBasic={displayBasic}
        toast={toast}
      />
    );
  };

  const CreateGroupModule = () => {
    return (
      <CreateGroup
        createGrpModule={showCreateGropeModule}
        // setCreateGrpModule={setShowCreateGroupModule}
        toast={toast}
      />
    );
  };

  const handleTabChange = (e) => {
    dispatch(changeShowCustomersType(e.value.value));
    setIndex(e.index);
    navigate(e.value.route);
  };


  return (
    <div className="p-3">
      <GoogleContactsUpload
        contactList={googleContacts}
        showGoogleContacts={showGoogleContacts}
        hideGoogleContacts={hideGoogleContacts}
        toast={toast}
      />
      {deleteModule()}
      <TableActionContainer>
        <div className="flex justify-content-between align-items-center flex-wrap gap-3">
          <TabMenu
            model={items.filter(({show})=>show)}
            activeIndex={index}
            onTabChange={handleTabChange}
            className={`custom-tabmenu`}
          />
          <div className="flex gap-3 flex-wrap-reverse">
            <DebounceSearch
              placeholder={"Search Group"}
              inputValue={inputValue}
              handleInputChange={(value) => setInputValue(value)}
            />
            <div className="flex gap-2 justify-content-end align-items-center flex-grow-1">
              <CustomButton
                varient="filled"
                label={"Create New Group"}
                icon={"pi pi-plus"}
                onClick={onAddNewClick}
              />
            </div>
          </div>
        </div>
      </TableActionContainer>

      <div className="mt-3">
        {accesskey[modulesName.CUSTOMERGROUP]?.includes(permissions.READ)&&<GroupTable searchgroup={inputValue} />}
      </div>
    </div>
  );
};

export default GroupList;
