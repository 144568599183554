import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { CustomButton } from "../../components/CustomButton";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import { Text } from "../../components/Text";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActivePlan, sendPayment, sendPaymentRazorPay } from "../../reducers/paymentSlice";
import { Toast } from "primereact/toast";
import { getPlans } from "../../api/payment.service";
import plansJson from './plans.json';
import activePromoCodes from './promoCodes.json';
import styles from './style.module.css';
import PlanCarousalItem from './PlanCarousalItem';
import { useRazorpay } from "react-razorpay";
import { InputText } from 'primereact/inputtext';

const calculatePrice = (planPrice, offPercentage) => {
  if (offPercentage) {
    return planPrice * (offPercentage / 100)
  } else {
    return 0
  }
}

function Subscription() {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { error, isLoading, Razorpay } = useRazorpay();
  const { user } = useSelector((state) => state.authenticate);
  const { activePlan } = useSelector((state) => state.payment);
  const [subscriptionPlans, setSubscriptionPlans] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef();
  const [plan, setPlan] = useState({ value: 0, name: 'Monthly' });
  const [planIndex, setPlanIndex] = useState(null);
  const plans = plansJson.plans;
  const monthsplan = { 1: 'Monthly', 4: 'Quarterly', 12: 'Yearly' };
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [promo, setPromo] = useState('');
  const [discount,setDiscount] = useState(0);
  const [gstPrice,setGstPrice] = useState(0);

  useEffect(() => {
    dispatch(ActivePlan(user?.companyId));
    getPlans("subscription")
      .then((data) => {
        const allTopUps = data?.rows || [];
        const obj = allTopUps.reduce((acc, pack) => {
          acc[pack?.id] = pack;
          return acc;
        }, {});
        setSubscriptionPlans({ ...obj });
      })
      .catch((err) => console.error("Error:", err));
  }, [user?.companyId]);

  // const handlePayPayU = (plan) => {
  //   const data = {
  //     productType: "SUBSCRIPTION",
  //     ...user,
  //     ...plan,
  //   };
  //   dispatch(sendPayment({ data }))
  //     .unwrap()
  //     .then(() => {})
  //     .catch((err) => {
  //       toast.current.show({ severity: "error", detail: err.message });
  //     });
  // };
  const { company } = useSelector((state) => state.company);
  const handlePay = (plan) => {
    const data = {
      productType: "SUBSCRIPTION",
      productId: plan?.id,
      ...user,
      ...plan
    };

    if (selectedPromo) {
      data.couponCode = selectedPromo?.code
    }

    dispatch(sendPaymentRazorPay({ data }))
      .unwrap()
      .then((order) => {
        const options = {
          ...order,
          order_id: order.id,
          prefill: {
            name: company?.companyName,
            email: company?.email,
            contact: company?.phone,
          },
          theme: {
            color: "#F37254",
          },

        };
        const razorpayInstance = new Razorpay(options);
        razorpayInstance.open();
      })
      .catch((err) => {
        toast.current.show({ severity: "error", detail: err.message });
      });
  };

  const setSubPlans = (id) => {
    const selectedPlanDetails = subscriptionPlans[id];
    setPlanIndex(id);
    setSelectedPlan({
      amount: selectedPlanDetails.amount,
      total: selectedPlanDetails.amount,
      ...selectedPlanDetails,
    });
  };

  const resetPlanData = ()=>{
    setSelectedPlan(null);
    setSelectedPromo(null);
    setDiscount(0);
    setGstPrice(0);
    setPromo('')
  }

  const Plans = () => (
    <div className="w-full flex justify-center flex-column align-items-center p-2 flex-wrap">
      <div className={`flex w-12 md:w-6 justify-content-center cursor-pointer ${styles['switch-btn']}`}>
        {['Monthly', 'Quarterly', 'Yearly'].map((item, idx) => (
          <div
            className={`flex align-items-center justify-content-center flex-column w-4 cursor-pointer h-3rem font-bold ${plan.value === idx ? styles['selected-plan-button'] : styles['plan-button']}`}
            key={idx}
            onClick={() => {
              resetPlanData();
              setPlan({ value: idx, name: item });
            }}
          >
            <div className="flex">{item}</div>
            {idx === 0 && <div className={`text-xs ${plan.value === idx ? '' : styles['plan-discount']}`}>Validity 1 month</div>}
            {idx === 1 && <div className={`text-xs ${plan.value === idx ? '' : styles['plan-discount']}`}>Validity 3 months</div>}
            {idx === 2 && <div className={`text-xs ${plan.value === idx ? '' : styles['plan-discount']}`}>Validity 12 months</div>}
          </div>
        ))}
      </div>
      <div className={`${styles['plan-carousal-container']} flex w-12  mt-2 gap-2 p-2  cursor-pointer `}  >
        {plans.map((data, index) => (
          <PlanCarousalItem
            key={index}
            id={index}
            plan={data}
            time={plan}
            activePlan={activePlan}
            planIndex={planIndex}
            setPlansIndex={setSubPlans}
            subscriptionPlans={subscriptionPlans}
            handlePay={handlePay}
          />
        ))}
      </div>
    </div>
  );

  const handleApplyPromoCodeClick = (promo, availablePromoCodes) => {
    const validPromoCode = availablePromoCodes.find(item => item.code === promo);
    if (validPromoCode) {
      toast.current.show({ severity: "success", detail: "Promo Code Applied!" });
      setSelectedPromo(validPromoCode);
    } else {
      toast.current.show({ severity: "error", detail: 'Invalid Promo Code' });
      setSelectedPromo(null)
    }
  }


  useEffect(()=>{

    if(selectedPlan){
      let newAmount = selectedPlan.amount;
      const shouldPromoApply = [3,12]?.includes(selectedPlan?.validityMonths);
      if(selectedPromo && shouldPromoApply){
        const discount = calculatePrice(selectedPlan?.amount, selectedPromo?.discount_percentage);
        newAmount = selectedPlan.amount - discount;
        setDiscount(discount);
      }
      const gst =  Math.ceil(calculatePrice(newAmount,18));
      setGstPrice(gst);
      
    }
  },[selectedPlan, selectedPromo])

  return (
    <div className="w-full mb-2">
      <Toast ref={toast} />
      <CustomBreadcrumb home={{ label: "Dashboard", url: "/dashboard" }} itemslist={[{ label: "Subscription", url: "#" }]} />
      <div className="m-2 pl-3">
        <TableActionsContainer>
          <div className="flex justify-content-between align-items-center">
            <Text type={"heading"}>Subscription</Text>
            <CustomButton varient="outline" label="Cancel" onClick={() => navigate("/dashboard")} />
          </div>
        </TableActionsContainer>
      </div>
      <div className="flex justify-content-center">
        <div className="w-11">
          <div className="w-full flex justify-content-center flex-column align-items-center p-2">
            <div className="text-2xl font-bold">Choose Your Plan</div>
            <div className="font-normal text-center mb-2">
              If you need more info about our pricing, please check{' '}
              <a style={{ color: '#005068' }} href="https://www.skaleBot.com" target="_blank" rel="noopener noreferrer">
                www.skaleBot.com
              </a>.
            </div>
          </div>
          <Plans />
          {selectedPlan &&
            <div className="my-3">
              {
                [3,12].includes(selectedPlan?.validityMonths) && (

                  <div className="m-auto w-full md:w-8 mb-2 px-3">
                  <div className=" flex flex-row align-items-end gap-2">
                    <div className="flex-1">
                      <div className="flex flex-column gap-2">
                        <label htmlFor="promo"><Text type={'heading'}>Promo Code</Text></label>
                        <InputText id="promo" aria-describedby="promo-help" placeholder="Enter Promo code." value={promo} onChange={(e) => setPromo(e.target.value)} />
                      </div>
                    </div>
                    <CustomButton label={selectedPromo === null ? 'Apply' : 'Applied'} varient="outline" type="button" onClick={() => handleApplyPromoCodeClick(promo, activePromoCodes)} disabled={selectedPromo !== null} />
                  </div>
                  {
                    selectedPromo && (
                      <h3 id="promo-status" className={styles.promo_code_status}>
                        Congratulations! you have got {selectedPromo?.discount_percentage} % off on selected plan.
                      </h3>
                    )
                  }
  
                </div>
                )
              }
             

              <div className="w-full flex justify-content-center align-items-center mt-4 flex-column">
                <div className="flex w-full md:w-8 justify-content-between w-full mb-2 px-3">
                  <div>
                    <div className="font-semibold text-md">Skalebot subscription ({selectedPlan?.name})</div>
                  </div>
                  <div className="text-right">
                    <div className="text-xl font-semibold" style={{ color: "#005068" }}> ₹ {selectedPlan?.amount}</div>
                  </div>
                </div>
                {
                  selectedPromo && (
                    <div className="flex w-full md:w-8 justify-content-between w-full mb-2 px-3">
                      <div>
                        <div className="font-semibold text-md">Discount</div>
                      </div>
                      <div className="text-right">
                        <div className="text-xl font-semibold" style={{ color: "#005068" }}> <span>-</span> ₹ {discount}</div>
                      </div>
                    </div>
                  )
                }
                 <div className="flex w-full md:w-8 justify-content-between w-full mb-2 px-3">
                  <div>
                    <div className="font-semibold text-md">GST</div>
                    <div className="text-xs">(+ 18%)</div>
                  </div>
                  <div className="text-right">
                    <div className="text-xl font-semibold" style={{ color: "#005068" }}>+ ₹ {gstPrice}</div>
                    
                  </div>
                </div>
                <div className="flex w-full md:w-8 justify-content-between w-full mb-2 px-3">
                  <div>
                    <div className="font-semibold text-md">Amount to Pay</div>
                    <div className="text-sm">Renewing after every {selectedPlan?.validityMonths} months</div>
                  </div>
                  <div className="text-right">
                    <div className="text-xl font-semibold" style={{ color: "#005068" }}>₹ {selectedPlan?.amount - discount + gstPrice}</div>
                  </div>
                </div>

                <div className="w-full md:w-8 flex justify-content-center mt-2">
                  <CustomButton
                    varient={"filled"}
                    label={<span>Pay &#x20B9; { selectedPlan?.amount - discount + gstPrice}</span>}
                    onClick={() => handlePay(selectedPlan)}
                    style={{ width: "100%", padding: "1rem", fontSize: "1rem" }}
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Subscription;
