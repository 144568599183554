import { DataTable } from "primereact/datatable";
import { Text } from "../Text";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
function ColumnMapping({
  targetColumns = [],
  incommingColumns = [],
  handleChangeTarget = () => {},
}) {
  function checkIsSelectedBefore(option, currValue) {
    let isSelected = false;
    targetColumns.forEach((col) => {
      if (
        col?.spreadSheetColumn?.field == option?.field &&
        col?.spreadSheetColumn?.field != currValue?.field
      ) {
        isSelected = true;
      }
    });
    return isSelected;
  }

  const dorpdownSelect = (row) => {
    const selectedValueTemplate = (option, props) => {
      if (option) {
        return (
          <div className="flex align-items-center">
            <div>{option.header}</div>
          </div>
        );
      }

      return <span>{props.placeholder}</span>;
    };

    const itemOptionTemplate = (option) => {
      return (
        <div className="flex align-items-center">
          <div>{option.header}</div>
        </div>
      );
    };
    return (
      <div className="card">
        <Dropdown
          value={row?.spreadSheetColumn}
          onChange={(e) => handleChangeTarget(row, e.value)}
          options={incommingColumns}
          optionLabel="header"
          placeholder="Select"
          filter
          showClear
          valueTemplate={selectedValueTemplate}
          itemTemplate={itemOptionTemplate}
          className="min-w-full"
          optionDisabled={(e) =>
            checkIsSelectedBefore(e, row?.spreadSheetColumn)
          }
        />
      </div>
    );
  };

  return (
    <div className="">
      <Text type="heading">Map column headers</Text>
      <div className="mt-3">
        <DataTable
          value={targetColumns}
          scrollable
          scrollHeight="450px"
          header={
            <div className="py-1 pl-3">
              <Text type={"headeing"}>
                Note: Target fields with star (*) is mandatory.{" "}
              </Text>
            </div>
          }
        >
          <Column
            header={"Target fields"}
            field="label"
            body={(rowData) => (
              <Text>
                {rowData.label} {rowData?.isRequired ? "*" : ""}
              </Text>
            )}
          />
          <Column body={() => <i className="pi pi-arrow-right ml-6" />} />

          <Column
            header={"Your spreadsheet"}
            field=""
            body={(rowData) => dorpdownSelect(rowData)}
            bodyStyle={{ width: "40%" }}
          />
        </DataTable>
      </div>
    </div>
  );
}

export default ColumnMapping;
