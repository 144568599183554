import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_REGISTER_COMPANY,
  API_CHECK_COMPANY_REGISTRATION,
  API_STOP_AI_SERVICE,
  API_START_AI_SERVICE,
  API_UPDATE_COMPANY,
} from "../api/ai.service";

const initialState = {
  isActive: false,
  isLoading: false,
  error: null,
  company: null,
};

export const registerCompany = createAsyncThunk(
  "ai/registerCompany",
  async (formData, thunkAPI) => {
    try {
      const response = await API_REGISTER_COMPANY(formData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const checkCompanyRegistration = createAsyncThunk(
  "ai/checkCompanyRegistration",
  async (_, thunkAPI) => {
    try {
      const response = await API_CHECK_COMPANY_REGISTRATION();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const stopAIService = createAsyncThunk(
  "ai/stopAIService",
  async (_, thunkAPI) => {
    try {
      const response = await API_STOP_AI_SERVICE();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const startAIService = createAsyncThunk(
  "ai/startAIService",
  async ({ file, url }, thunkAPI) => {
    try {
      const formData = new FormData();
      if (file) formData.append("file", file);
      if (url) formData.append("url", url);
      const response = await API_START_AI_SERVICE(formData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const updateAICompany = createAsyncThunk(
  "ai/updateAICompany",
  async (updates, thunkAPI) => {
    try {
      const response = await API_UPDATE_COMPANY(updates);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const aiSlice = createSlice({
  name: "ai",
  initialState,
  reducers: {
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Register Company
    builder.addCase(registerCompany.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(registerCompany.fulfilled, (state, action) => {
      state.isLoading = false;
      state.company = action.payload;
    });
    builder.addCase(registerCompany.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || action.error.message;
    });
    // update Company
    builder.addCase(updateAICompany.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateAICompany.fulfilled, (state, action) => {
      state.isLoading = false;
      state.company = action.payload;
    });
    builder.addCase(updateAICompany.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || action.error.message;
    });

    // Check Company Registration
    builder.addCase(checkCompanyRegistration.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(checkCompanyRegistration.fulfilled, (state, action) => {
      state.isLoading = false;
      state.company = action.payload;
    });
    builder.addCase(checkCompanyRegistration.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || action.error.message;
    });

    // Stop AI Service
    builder.addCase(stopAIService.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(stopAIService.fulfilled, (state) => {
      state.isLoading = false;
      state.isActive = false;
    });
    builder.addCase(stopAIService.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || action.error.message;
    });

    // Start AI Service
    builder.addCase(startAIService.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(startAIService.fulfilled, (state) => {
      state.isLoading = false;
      state.isActive = true;
    });
    builder.addCase(startAIService.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || action.error.message;
    });
  },
});

export const { resetError } = aiSlice.actions;
export default aiSlice.reducer;
