import { Text } from "../Text";
const SelectionAnalytics = ({ items = [] }) => {
  return (
    <div
      className="flex card-container md:flex-row xl:flex-row lg:flex-row  justify-content-start border-1 border-round-sm border-dashed border-grey-500"
      style={{ backgroundColor: "#1C738E1A" }}
    >
      {items?.map((item, idx) => {
        return (
          <div key={idx} className="flex-1 m-2 px-3 py-2 flex align-items-center">
            <Text type={"heading"}>
              <span style={{ fontSize: "1.6rem" }}>{item.count}</span>
            </Text>
            <Text type={"heading"}>&nbsp;{item.label}</Text>
          </div>
        );
      })}
    </div>
  );
};

export default SelectionAnalytics;
