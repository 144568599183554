import Search from "./search";
import Chats from "./chats";

const Sidebar = () => {
  return (
    <div className="__sidebar flex flex-column">
      <Search />
      <Chats />
    </div>
  );
};

export default Sidebar;
