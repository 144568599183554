import { Button } from "primereact/button";
import "./index.css";
const defaultToolTipOptions ={ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }
export const CustomButton = ({
  label = null,
  icon = null,
  onClick = () => {},
  varient = "filled",
  type = "button",
  disabled = false,
  style = {},
  tooltip = null,
  tooltipOptions=defaultToolTipOptions
}) => {
  return (
    <Button
      type={type}
      label={label}
      icon={icon}
      onClick={onClick}
      disabled={disabled}
      className={`skalebot-button flex ${varient} font-normal`}
      style={style}
      tooltip={tooltip}
      tooltipOptions={tooltipOptions}
    />
  );
};
