import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "../style.module.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axiosInstance from "../../../api/axios.instance";
import { getDate } from "../../../utils/datemaker";
import { useDispatch, useSelector } from "react-redux";
import { getEnquiries } from "../../../reducers/enquiryTabelSlice";
import { useLayout } from "../../../context/LayoutContext";
import { getCustomers } from "../../../reducers/customerTableSlice";
import { API_GET_ENQURIES } from "../../../api/enquiry.services";
import {
  getEnquiriesOverall,
  getTicketsOverall
} from "../../../reducers/analyticSlice";
import { emptyContent } from "../../../utils/tableUtils";
import { Doughnut } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
} from "chart.js";
import CustomSkeleton from "../../../components/CustomSkeleton";
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function EnquiriesSection() {
  const phoneBodyTemplate = (rowdata) => {
    return `+${rowdata?.phone}`;
  };
  const {
    enquiryOverall,
    loading: analyticloading,
    dateRange,
    analyticalDetails,
    skeletonloading
  } = useSelector((state) => state.analytic);

  const { toast, setLoading } = useLayout(null);
  const dispatch = useDispatch();

  const [filter, setFilter] = useState("total-enq");
  useEffect(() => {
    let filterData = [];
    if (filter !== "total-enq") {
      filterData = [
        { key: "isCustomerActive", value: filter === "new-enq" ? "0" : "1" }
      ];
    }
    dispatch(
      getEnquiriesOverall({
        page: 0,
        limit: 10,
        dateRange,
        filter: filterData,
        globalFilterValue: ""
      })
    );
  }, [filter, dateRange]);
  let donutdata = {
    labels: false,
    datasets: [
      {
        data: [
          analyticalDetails?.enquiry?.statistics?.newCustomerEnquiriesCount | 0,
          analyticalDetails?.enquiry?.statistics
            ?.existingCustomerEnquiriesCount | 0
        ],
        backgroundColor: ["#FFC832", "#018E42"],
        borderColor: ["#FFC832", "#018E42"],
        borderWidth: 1
      }
    ]
  };
  return (
    <div
      className={`w-full flex flex-column justify-content-between align-items-start  w-12 ${styles["record-wrap-stat"]}`}
    >
      <div
        className={` ${styles["record-wrap-stat"]} flex flex-column lg:flex-row w-full cp-10 gap-2`}
      >
        <div className="w-full lg:w-8 ">
          <div
            className={`w-full flex flex-column ${styles["dashboard-tablecontent"]} border-round-sm p-2`}
          >
            <div className={`w-full justify-content-start p-2 `}>
              <div
                className={`w-full flex flex-row overflow-auto  gap-4 lg:gap-0  ${styles["enquries-filter"]}`}
              >
                <div
                  className={`pl-0 cursor-pointer text-md mr-2 p-2 ${
                    styles["enquries-filter-el"]
                  } ${
                    filter === "total-enq" && styles["enquries-filter-el-sel"]
                  }`}
                  onClick={() => {
                    setFilter("total-enq");
                  }}
                >
                  Total Enquiries(
                  {analyticalDetails?.enquiry?.statistics?.allEnquiriesCount |
                    0}
                  )
                </div>
                <div
                  className={`p-2 cursor-pointer text-md mr-2 ${
                    styles["enquries-filter-el"]
                  } ${
                    filter === "new-enq" && styles["enquries-filter-el-sel"]
                  } `}
                  onClick={() => {
                    setFilter("new-enq");
                  }}
                >
                  New Customers(
                  {analyticalDetails?.enquiry?.statistics
                    ?.newCustomerEnquiriesCount | 0}
                  )
                </div>
                <div
                  className={`p-2 cursor-pointer text-md mr-2 ${
                    styles["enquries-filter-el"]
                  } ${
                    filter === "existing-enq" &&
                    styles["enquries-filter-el-sel"]
                  } `}
                  onClick={() => {
                    setFilter("existing-enq");
                  }}
                >
                  Existing Customers(
                  {analyticalDetails?.enquiry?.statistics
                    ?.existingCustomerEnquiriesCount | 0}
                  )
                </div>
              </div>
            </div>
            <div>
              {skeletonloading?.enquiry?<div className="flex flex-column gap-4">
                <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={4} height={"2rem"} className="border-round w-4"/>
                <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={4} height={"2rem"} className="border-round w-4"/>
                <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={4} height={"2rem"} className="border-round w-4"/>
                <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={4} height={"2rem"} className="border-round w-4"/>
                <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={4} height={"2rem"} className="border-round w-4"/>  
              </div>:<DataTable
                className={`${styles["dashboard-table"]} w-full`}
                value={enquiryOverall}
                responsiveLayout="scroll"
                resizableColumns
                stripedRows
                columnResizeMode="expand"
                emptyMessage="No data found."
                selectionMode="single"
              >
                <Column
                  field="createdAt"
                  header="Date"
                  style={{width:'20ch'}}
                  body={(rowData) =>
                    rowData.createdAt && getDate(rowData.createdAt)
                  }
                  bodyStyle={{
                    textTransform: "uppercase",
                    lineHeitght: "1rem"
                  }}
                  className="text-sm"
                />

                <Column
                  field="customerName"
                  className="text-sm"
                  header={"Name"}
                  bodyStyle={{ textTransform: "capitalize" }}
                  body={(rowData) => emptyContent(rowData?.name)}
                />
                <Column
                  field="phone"
                  header="Phone number"
                  className="text-sm"
                  body={phoneBodyTemplate}
                  style={{width:'25ch'}}
                />
              </DataTable>}
            </div>
          </div>
        </div>
        <div className="w-full lg:w-4 ">
          <div className="w-full flex flex-column gap-2">
            <div
              className={`w-full flex flex-column bg-white border-round-sm p-2`}
            >
              <div className="w-10 flex justify-content-start w-12 flex pl-2  m-auto text-lg font-normal py-3 m-auto">
                Enquiries Overview
              </div>
              {skeletonloading?.enquiry?<div>
                  <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={1} height={"367px"} className="border-round w-full"/>
              </div>:<div>
                <div
                    className="w-8 lg:w-6 py-2 m-auto flex justify-content-center  align-items-center"
                    style={{ maxHeight: "367px" }}
                  >
                    <Doughnut
                      data={donutdata}
                      options={{
                        plugins: {
                          tooltip: {
                            enabled: false
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="w-12  lg:w-8 align-items-center xl:w-6 m-auto flex flex-column pl-0 py-2 ">
                    <div className="flex flex-column gap-2">
                      {/* <div className="flex flex-row font-normal justify-content-between"><div className="flex flex-row"><div className={`${styles['orderDot']}`} style={{background:'#61F2C2'}}></div>&nbsp;&nbsp;Total&nbsp;Enquiries&nbsp;</div>({analyticalDetails?.enquiry?.statistics?.allEnquiriesCount|0})</div> */}
                      <div className="flex flex-row font-normal justify-content-between gap-4">
                        <div className="flex flex-row">
                          <div
                            className={`${styles["orderDot"]}`}
                            style={{ background: "#FFC832" }}
                          ></div>
                          &nbsp;&nbsp;New&nbsp;Customers&nbsp;
                        </div>
                        (
                        {analyticalDetails?.enquiry?.statistics
                          ?.newCustomerEnquiriesCount | 0}
                        )
                      </div>
                      <div className="flex flex-row font-normal justify-content-between gap-4">
                        <div className="flex flex-row">
                          <div
                            className={`${styles["orderDot"]}`}
                            style={{ background: "#018E42" }}
                          ></div>
                          &nbsp;&nbsp;Existing&nbsp;Customers&nbsp;
                        </div>
                        (
                        {analyticalDetails?.enquiry?.statistics
                          ?.existingCustomerEnquiriesCount | 0}
                        )
                      </div>
                    </div>
                  </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnquiriesSection;
