import { useForm } from "react-hook-form";
import Loader from "../../../components/Loader";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { CustomButton } from "../../../components/CustomButton";
import CustomInputText from "../../../components/common/CustomInputText";
import CustomInputTextArea from "../../../components/common/CustomInputTextArea";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { Text } from "../../../components/Text";
import TableActionsContainer from "../../../components/CustomTable/TableActionsContainer";
import {
  addQuickNote,
  deleteQuickNote,
  getQuickNote,
  updateQuickNote,
} from "../../../reducers/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { ConfirmationAlert } from "../../../components/Alert/ConfirmationAlert";
import { useUtility } from "../../../context/UtilityContext";
function QuickNoteForm() {
  // const [loading, setLoading] = useState(false);
  const { loading } = useSelector((state) => state.account);
  const [displayConfimation, setDisplayConfirmation] = useState(false);
  const [deleteConfimation, setDeleteConfirmation] = useState(false);
  const { navigation, setAlert } = useUtility();

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef();
  const breadcumbHome = { label: "Quick Notes", url: "/account" };
  const breadcumbList = [{ label: `${id ? "edit" : "new"}`, url: "#" }];

  const defaultvalues = {
    title: "",
    notes: "",
  };
  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small></small>
    );
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({ defaultvalues });

  const onSubmit = (data) => {
    if (id) {
      dispatch(updateQuickNote({ id, payload: data }))
        .unwrap()
        .then((res) => {
          toast.current.show({
            severity: "success",
            detail: "Quick note updated",
            life: 3000,
          });
          navigate("/account");
        })
        .catch((err) => {
          console.log(err);

          toast.current.show({
            severity: "error",
            detail: err?.message,
            life: 3000,
          });
        });
    } else {
      dispatch(addQuickNote({ payload: data }))
        .unwrap()
        .then((res) => {
          toast.current.show({
            severity: "success",
            detail: "Quick note added",
            life: 3000,
          });
          navigate("/account");
        })
        .catch((err) => {
          console.log("err", err);
          toast.current.show({
            severity: "error",
            detail: err?.message,
            life: 3000,
          });
        });
    }
  };

  const handleDelete = (id) => {
    dispatch(deleteQuickNote({ id }))
      .unwrap()
      .then((res) => {
        toast.current.show({
          severity: "success",
          detail: "Quick note deleted",
          life: 3000,
        });
        navigate("/account");
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          detail: err?.message,
          life: 3000,
        });
      });
  };
  const handleReset = () => {
    reset();
    setValue("title", "");
    setValue("notes", "");
  };
  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          type="button"
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setDisplayConfirmation(false);
          }}
        />
        <CustomButton
          type="submit"
          label="Confirm"
          varient="filled"
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    );
  };
  const deleteConfirm = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          type="button"
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setDeleteConfirmation(false);
          }}
        />
        <CustomButton
          type="buttom"
          label="Confirm"
          varient="filled"
          onClick={() => handleDelete(id)}
        />
      </div>
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(getQuickNote(id))
        .then((res) => {
          let selectedQuickNote = res.payload.quickNotes;
          setValue("title", selectedQuickNote.title);
          setValue("notes", selectedQuickNote.notes);
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            detail: err?.message,
            life: 3000,
          });
        });
    }
    return () => {
      setAlert(false);
    };
  }, [id]);
  return (
    <div>
      <Loader visible={loading} />
      <Toast ref={toast} />
      <ConfirmationAlert
        displayConfirmation={displayConfimation}
        onHide={() => setDisplayConfirmation(false)}
        alertText={`You are about to ${id ? "update" : "add"} a quick note?`}
        dialogFooter={dialogFooter}
      />
      <ConfirmationAlert
        displayConfirmation={deleteConfimation}
        onHide={() => setDeleteConfirmation(false)}
        alertText={`Are you sure? You want to delete it.`}
        dialogFooter={deleteConfirm}
      />
      <CustomBreadcrumb home={breadcumbHome} itemslist={breadcumbList} />

      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => {
          setAlert(true);
        }}
      >
        <div className="m-3">
          <TableActionsContainer>
            <div className="flex justify-content-between align-items-center gap-3 flex-wrap">
              <Text type={"heading"}>Text Automation</Text>
              <div className="flex gap-2">
                <CustomButton
                  type="button"
                  varient="text"
                  label={"Cancel"}
                  onClick={() => navigation("/account")}
                />
                {id && (
                  <CustomButton
                    type="button"
                    varient="text"
                    icon={"pi pi-trash"}
                    label={"Delete"}
                    onClick={() => {
                      setDeleteConfirmation(true);
                    }}
                  />
                )}

                <CustomButton
                  type="button"
                  varient="outline"
                  label={"Reset"}
                  onClick={handleReset}
                />
                <CustomButton
                  type="submit"
                  varient="filled"
                  label={id ? "Update" : "Save"}
                  onClick={handleSubmit(() => {
                    setDisplayConfirmation(true);
                  })}
                />
              </div>
            </div>
          </TableActionsContainer>
        </div>
        <div className="m-3">
          <TableActionsContainer>
            <div className="mb-2 w-full md:w-5">
              <CustomInputText
                control={control}
                label="Title"
                name="title"
                placeholer="Enter title"
                required={true}
              />
              {getFormErrorMessage("title")}
            </div>
            <div className="mb-2 w-full md:w-5">
              <CustomInputTextArea
                control={control}
                label="Note"
                name="notes"
                placeholder="Enter your note ..."
                isMandatory={true}
              />
              {getFormErrorMessage("notes")}
            </div>
          </TableActionsContainer>
        </div>
      </form>
    </div>
  );
}

export default QuickNoteForm;
