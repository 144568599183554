import { useNavigate } from "react-router";
import { CustomButton } from "../../../components/CustomButton";
import TableActionsContainer from "../../../components/CustomTable/TableActionsContainer";
import { Text } from "../../../components/Text";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AIServiceDialog from "../../../components/AIDialog"; // Adjust the import path as needed

import {
  changeBotMode,
  changeBotflowLimit,
  changeBotflowPage,
  changeSelectectBotFlow,
  deleteBotFlow,
  getAllBotFlow,
} from "../../../reducers/canvas/canvasSlice";
import { BOT_MODES } from "../bot-constants";
import Loader from "../../../components/Loader";
import CustomPaginator from "../../../components/CustomPaginator";
import SklbtConfirmation from "../../../components/common/SklbtConfirmation";
import CONSTANTS from "../../../config/Constants";
import { useLayout } from "../../../context/LayoutContext";
import BotFlowForm from "./BotFlowForm";
import { BotListTable } from "./components/BotListTable";
import permissions from "../../../roles/permissions";
import modulesName from "../../../roles/modulesName";

export default function BotList() {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showdelDialog, setShowDelDialog] = useState(false);
  const [showBotForm, setBotForm] = useState(false);
  const flowid = useRef(null);
  const [aiDialogVisible, setAIDialogVisible] = useState(false);
  const { accesskey } = useSelector((state) => state.authenticate);
  const { botFlows, page, limit, loading, totalbotflowCount } = useSelector(
    (state) => state.canvas
  );
  const { toastMessage } = useLayout();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEditClick = (flow) => {
    flowid.current = flow.id;
    setShowEditDialog(true);
  };
  const onHandleEditSubmit = () => {
    dispatch(changeBotMode(BOT_MODES.EDIT));
    dispatch(changeSelectectBotFlow(flowid.current));
    navigate(`/bots/${flowid.current}/update`);
  };
  const handleDeleteClick = (flowId) => {
    flowid.current = flowId;
    setShowDelDialog(true);
  };

  const onHandleDelSubmit = () => {
    dispatch(deleteBotFlow(flowid.current))
      .unwrap()
      .then(() => {
        toastMessage({ severity: "success", detail: "Botflow deleted." });
        setShowDelDialog(false);
      })
      .catch((err) => {
        toastMessage({ severity: "danger", detail: "Botflow delete failed" });
      });
  };

  const handleNewBotFlowClick = () => {
    setBotForm(true);
  };
  const showAIDialog = () => {
    setAIDialogVisible(true);
  };

  const hideAIDialog = () => {
    setAIDialogVisible(false);
  };
  useEffect(() => {
    dispatch(getAllBotFlow({ page, limit }));
  }, []);

  return (
    <div>
      <Loader visible={loading} />
      {showBotForm && (
        <BotFlowForm
          showBotFlowForm={showBotForm}
          onCancel={() => setBotForm(false)}
        />
      )}
      <SklbtConfirmation
        icon="pi pi-trash"
        message={CONSTANTS.BOT_EDIT_CONFIRMATION}
        visible={showEditDialog}
        handleConfirm={onHandleEditSubmit}
        handleReject={() => setShowEditDialog(false)}
      />
      <SklbtConfirmation
        icon="pi pi-trash"
        message={CONSTANTS.BOT_DELETE_CONFIRMATION}
        visible={showdelDialog}
        handleConfirm={onHandleDelSubmit}
        handleReject={() => setShowDelDialog(false)}
      />
      <div className="m-3">
        <TableActionsContainer>
          <div className="flex justify-content-between align-items-center">
            <Text type={"heading"}>Bot Flows</Text>
            <div className="flex justify-content-center align-items-center">
              <CustomButton
                style={{ marginRight: "1rem", width: "200px" }}
                icon={"pi pi-plus"}
                label={"Create new bot flow"}
                onClick={handleNewBotFlowClick}
              />
              {(accesskey[modulesName.AI]?.includes(permissions.CREATE)&&accesskey[modulesName.AI]?.includes(permissions.CREATE))&&<CustomButton
                icon={"pi pi-plus"}
                label={"Start AI Flow"}
                onClick={showAIDialog}
                style={{ marginRight: "1rem", width: "200px" }}
              />}
            </div>
          </div>
        </TableActionsContainer>
      </div>
      <div className="px-3 flex">
        <a
          href="https://youtu.be/ujh37o4A4LY?si=Z62YH4NT4v_XOa3W"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <CustomButton
            varient="outline-white"
            label={
              <Text cust="flex align-items-center">
                <i
                  className="pi pi-youtube mr-2"
                  style={{ color: "#C6272D" }}
                />
                <span>How to create bot flow?</span>
              </Text>
            }
            type="button"
          />
        </a>
      </div>
      <div className="p-3 w-full overflow-x-auto">
        <BotListTable
          botflowsData={botFlows}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
        />
      </div>

      <TableActionsContainer>
        <div className="flex  justify-content-end">
          <CustomPaginator
            page={page}
            limit={limit}
            changelimit={changeBotflowLimit}
            totalRecords={totalbotflowCount}
            changePage={changeBotflowPage}
          />
        </div>
      </TableActionsContainer>
      <AIServiceDialog visible={aiDialogVisible} onHide={hideAIDialog} />
    </div>
  );
}
