import { CustomButton } from "../CustomButton";
import { Dialog } from "primereact/dialog";
// import {ReactComponent as PaymentG} from '../../svg/paymentg.svg'
import { ReactComponent as PaymentG } from "../../svg/payu.svg";
import { RadioButton } from "primereact/radiobutton";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendPayment, PlansDetails } from "../../reducers/paymentSlice";
import "./index.css";
import Loader from "../Loader";
import * as Messag from "../../config/ToastMessage";
import { payuInvoice } from "../../api/payment.service";
function PaymentGateway({ gateway, category, setGateway, toast }) {
  let dispatch = useDispatch();
  const handelclose = () => {
    setGateway(false);
  };
  const { user } = useSelector((state) => state.authenticate);
  const { loading, plans } = useSelector((state) => state.payment);

  const handelsubmit = () => {
    if (plan !== null) {
      let data = { productType: category, ...user, ...plan };

      dispatch(sendPayment({ data }));
    } else {
      toast.current.show({ severity: "error", detail: Messag.ERR_Select_Plan });
    }
  };
  const [radio, setradio] = useState(null);
  const [plan, setPlan] = useState(null);
  useEffect(() => {
    dispatch(PlansDetails(category));
  }, [category]);

  const subs = {
    count: 2,
    rows: [
      {
        id: 2,
        name: "Monthly",
        amount: 1000,
        type: "Monthly",
        description: "",
        isActive: true,
        createdAt: "2023-01-17T20:12:48.000Z",
        updatedAt: "2023-01-17T20:12:48.000Z",
      },
      {
        id: 1,
        name: "Quaterly",
        amount: 3000,
        type: "Quaterly",
        description: "",
        isActive: true,
        createdAt: "2023-01-17T20:12:34.000Z",
        updatedAt: "2023-01-17T20:12:34.000Z",
      },
    ],
  };

  const planarr = plans;
  const handlePlan = (e) => {
    setPlan(e);
    setradio(e.amount);
  };
  const modelload = () => {
    return <Loader visible={loading} />;
  };

  return (
    <Dialog
      header={category}
      visible={gateway}
      onHide={handelclose}
      className="w-10"
      style={{ minheight: "90vw" }}
    >
      {modelload()}
      <div className="w-12 m-auto p-2 md:p-4 lg:p-4 xl:p-4 flex flex-column">
        <div className="w-12 p-2 flex-wrap text-justify m-auto border-dotted border-1 border-round-xs border-gray-800 font-semibold">
          All conversations are measured in fixed 24-hour sessions. A
          conversation starts when the first business message in a conversation
          is delivered, either initiated by the business or in reply to a user
          message. Businesses and users can exchange any number of messages,
          including template messages, within a 24 hour conversation session
          without incurring additional charges. Each 24 hour conversation
          session results in a single charge
        </div>

        <div className="w-12 flex mt-5 flex-column-reverse xl:flex-row lg:flex-row">
          <div className="w-12">
            <div className="font-bold">Choose Plan</div>
            {planarr.map((item) => {
              return (
                <div
                  key={item.id}
                  className="border-round border-200 border-3 w-12 md:w-10 xl:w-5 lg:w-8 gap-2 p-2 my-3 flex flex-wrap sm:justify-content-between m-auto cursor-pointer"
                  onClick={(e) => handlePlan(item)}
                  style={{ background: "#1C738E25" }}
                >
                  <div className="field-radiobutton mb-0 align-self-center ">
                    <RadioButton
                      inputId="amount"
                      name="amount"
                      value={item.amount}
                      checked={radio === item.amount}
                      onChange={(e) => setradio(e.value)}
                    />
                    <label htmlFor="amount" className="font-bold">
                      {item.name}
                    </label>
                  </div>
                  <div
                    className="p-3 border-200 border-1 border-round align-self-center h-3rem flex align-items-center justify-content-center align-content-center text-blue-50 font-semibold"
                    style={{ background: "#005068" }}
                  >
                    {item.amount} &#8377; {"+18% GST"}
                  </div>
                </div>
              );
            })}

            {plan === null ? (
              <CustomButton
                varient="filled xl:w-5 lg:w-6 md:w-10 w-12 mt-0  h-4rem m-auto"
                type={"submit"}
                onClick={handelsubmit}
                label={"Make a Payment"}
                disabled
              />
            ) : (
              <CustomButton
                varient="filled xl:w-5 lg:w-6 md:w-10 w-12 mt-0  h-4rem m-auto"
                type={"submit"}
                onClick={handelsubmit}
                label={"Make a Payment"}
                //    disabled
              />
            )}
          </div>
        </div>

        <div className="flex justify-content-center m-2 mt-5">
          <PaymentG className="border-round border-200 border-3" />
        </div>
      </div>

      <div className="w-12 flex mt-5 flex-column-reverse xl:flex-row lg:flex-row">
        <div className="w-12">
          <div className="font-bold">Choose Plan</div>
          {planarr.map((item) => {
            return (
              <div
                key={item.id}
                className="border-round border-200 border-3 w-12 md:w-10 xl:w-5 lg:w-8 gap-2 p-2 my-3 flex flex-wrap sm:justify-content-between m-auto cursor-pointer"
                onClick={(e) => handlePlan(item)}
                style={{ background: "#1C738E25" }}
              >
                <div className="field-radiobutton mb-0 align-self-center ">
                  <RadioButton
                    inputId="amount"
                    name="amount"
                    value={item.amount}
                    checked={radio === item.amount}
                    onChange={(e) => setradio(e.value)}
                  />
                  <label htmlFor="amount" className="font-bold">
                    {item.name}
                  </label>
                </div>
                <div
                  className="p-3 border-200 border-1 border-round align-self-center h-3rem flex align-items-center justify-content-center align-content-center text-blue-50 font-semibold"
                  style={{ background: "#1C738E" }}
                >
                  {item.amount} &#8377; {"+18% GST"}
                </div>
              </div>
            );
          })}

          {plan === null ? (
            <CustomButton
              varient="filled xl:w-5 lg:w-6 md:w-10 w-12 mt-0  h-4rem m-auto"
              type={"submit"}
              onClick={handelsubmit}
              label={"Make a Payment"}
              disabled
            />
          ) : (
            <CustomButton
              varient="filled xl:w-5 lg:w-6 md:w-10 w-12 mt-0  h-4rem m-auto"
              type={"submit"}
              onClick={handelsubmit}
              label={"Make a Payment"}
              //    disabled
            />
          )}
        </div>
      </div>

      <div className="flex justify-content-center m-2 mt-5">
        <PaymentG className="border-round border-200 border-3" />
      </div>
    </Dialog>
  );
}

export default PaymentGateway;
