import { createSlice } from "@reduxjs/toolkit";
import { shareBroadcast, startConversation } from "./actions";
import { selectElements } from "../../utils/tableUtils";
const initialState = {
  shareLoading: false,
  campaignName: "",
  shareMessageDialog: false,
  moduleTitle: "",
  shareType: "",
  selectedTemplate: null,
  steps: "info",
  broadcastdetails: null,
  schedule: false,
  scheduleDetail: {
    startDate: "",
    endDate: "",
    dayOfWeek: [],
    runTime: [],
  },
  mode: "new",
  selectedCustomers: [],
  selectedGroupId: "all",
  completeSteps: 0,
  campDetails: {},
};

const MesssageSlice = createSlice({
  name: "messageShare",
  initialState,
  reducers: {
    changeCampaignName(state, { payload }) {
      state.campaignName = payload;
    },
    showShareMessageDialog(state, { payload }) {
      // state.shareMessageDialog = true;
      state.moduleTitle = payload?.title;
      state.selectedTemplate = payload.selectedTemplate;
    },
    hideShareMessageDialog(state) {
      state.shareMessageDialog = false;
      state.selectedTemplate = null;
    },
    changestepcount(state, { payload }) {
      state.completeSteps = payload;
    },
    stepChange(state, { payload }) {
      state.steps = payload.steps;
    },
    setBroadcastDetails(state, { payload }) {
      state.broadcastdetails = { ...payload };
      // console.log(state.broadcastdetails)
    },
    saveProduct(state, { payload }) {
      state.productSelected = { ...payload };
      if (state.completeSteps < 3) {
        state.completeSteps = 3;
      }
    },
    setMode(state, { payload }) {
      state.mode = payload;
      state.completeSteps = 5;
    },
    setScheduler(state, { payload }) {
      state.schedule = true;
      state.scheduleDetail = { ...payload };
      if (state.completeSteps < 5) {
        state.completeSteps = 5;
      }
    },
    cancelScheduler(state) {
      state.schedule = false;
      state.scheduleDetail = {
        startDate: "",
        endDate: "",
        dayOfWeek: [],
        runTime: [],
      };
    },
    changeselectedCustomers(state, action) {
      const { selectedCust, currCust } =action.payload;
      state.selectedCustomers=selectElements(state.selectedCustomers,selectedCust,currCust)
    },
    changeselectedGroupId(state, { payload }) {
      state.selectedGroupId = payload;
    },
    saveCustomer(state) {
      state.completeSteps = 5;
    },
    setCampDetails(state, { payload }) {
      state.mode = "update";
      state.campDetails = payload;
    },
    resetBroadcast(state) {
      state.shareLoading = false;
      state.shareMessageDialog = false;
      state.moduleTitle = "";
      state.shareType = "";
      state.selectedTemplate = null;
      state.broadcastdetails = null;
      state.schedule = false;
      state.scheduleDetail = {
        startDate: "",
        endDate: "",
        dayOfWeek: [],
        runTime: [],
      };
      state.selectedCustomers = [];
      state.selectedGroupId = "all";
      state.completeSteps = 0;
      state.mode = "new";
      state.campDetails = {};
      state.campaignName = "";
    },
    removeCustomerFromSeletedList(state, action) {
      let removeCustomerId = action.payload;
      let currSelectedCustomers = [...state.selectedCustomers];
      state.selectedCustomers = currSelectedCustomers.filter(
        (customer) => customer.id != removeCustomerId
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(shareBroadcast.fulfilled, (state, { payload }) => {
      state.shareLoading = false;
    });
    builder.addCase(shareBroadcast.pending, (state) => {
      state.shareLoading = true;
    });
    builder.addCase(shareBroadcast.rejected, (state, { payload }) => {
      state.shareLoading = false;
    });

    builder.addCase(startConversation.fulfilled, (state, { payload }) => {
      state.shareLoading = false;
    });
    builder.addCase(startConversation.pending, (state) => {
      state.shareLoading = true;
    });
    builder.addCase(startConversation.rejected, (state, { payload }) => {
      state.shareLoading = false;
    });
  },
});

export const {
  showShareMessageDialog,
  hideShareMessageDialog,
  stepChange,
  setBroadcastDetails,
  setScheduler,
  changeselectedGroupId,
  changeselectedCustomers,
  cancelScheduler,
  saveProduct,
  saveCustomer,
  resetBroadcast,
  setMode,
  setCampDetails,
  changeCampaignName,
  changestepcount,
  removeCustomerFromSeletedList,
} = MesssageSlice.actions;

export default MesssageSlice.reducer;
