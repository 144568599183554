import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup";
import styles from "./contact-card.module.css";
import { Text } from "../../../../components/Text";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import ContactPlate from "./contact-plate";
import TableActionsContainer from "../../../../components/CustomTable/TableActionsContainer";

const msg = {
  app: "Logicloom1",
  timestamp: 1722408784377,
  version: 2,
  type: "message",
  payload: {
    id: "wamid.HBgMOTE2MzAyOTg1ODE2FQIAEhggMTAwMjU2QjM0NzBEQ0JFRUFFN0RFQzQ0RTFGQ0Q3NjgA",
    source: "916302985816",
    type: "contact",
    payload: {
      contacts: [
        {
          name: {
            first_name: "Amar",
            formatted_name: "AmarBabuAnna",
            last_name: "Anna",
            middle_name: "Babu",
          },
          phones: [
            {
              phone: "+919502813255",
              type: "Phone",
            },
          ],
        },
        {
          name: {
            first_name: "Vijay",
            formatted_name: "VijayYadav",
            last_name: "Yadav",
            middle_name: "",
          },
          phones: [
            {
              phone: "+919502813258",
              type: "Phone",
            },
            {
              phone: "+919502813258",
              type: "Tel",
            },
          ],
        },
        {
          name: {
            first_name: "Porus",
            formatted_name: "Porus Rathore",
            last_name: "Rathore",
            middle_name: "",
          },
          phones: [
            {
              phone: "+919502813255",
              type: "Phone",
            },
          ],
        },
        {
          name: {
            first_name: "Prakash",
            formatted_name: "Prakash Hemani",
            last_name: "Hemani",
            middle_name: "",
          },
          phones: [
            {
              phone: "+919502813255",
              type: "Phone",
            },
          ],
        },
        {
          name: {
            first_name: "Dinesh",
            formatted_name: "DineshKannab",
            last_name: "Kannab",
            middle_name: "",
          },
          phones: [
            {
              phone: "+919502813255",
              type: "Phone",
            },
          ],
        },
        {
          name: {
            first_name: "Chetan",
            formatted_name: "Chetan Malviya",
            last_name: "Malviya",
            middle_name: "",
          },
          phones: [
            {
              phone: "+919502813255",
              type: "Phone",
            },
          ],
        },
        {
          name: {
            first_name: "Porus",
            formatted_name: "Porus Rathore",
            last_name: "Rathore",
            middle_name: "",
          },
          phones: [
            {
              phone: "+919502813255",
              type: "Phone",
            },
          ],
        },
        {
          name: {
            first_name: "Prakash",
            formatted_name: "Prakash Hemani",
            last_name: "Hemani",
            middle_name: "",
          },
          phones: [
            {
              phone: "+919502813255",
              type: "Phone",
            },
          ],
        },
        {
          name: {
            first_name: "Dinesh",
            formatted_name: "DineshKannab",
            last_name: "Kannab",
            middle_name: "",
          },
          phones: [
            {
              phone: "+919502813255",
              type: "Phone",
            },
          ],
        },
        {
          name: {
            first_name: "Chetan",
            formatted_name: "Chetan Malviya",
            last_name: "Malviya",
            middle_name: "",
          },
          phones: [
            {
              phone: "+919502813255",
              type: "Phone",
            },
          ],
        },
      ],
    },
    sender: {
      phone: "916302985816",
      name: "MissYouMyDad😭",
      country_code: "91",
      dial_code: "6302985816",
    },
  },
};
const Contacts = (props) => {
  const [showContact, setShowContacts] = useState(false);

  const { message } = props;
  const { contacts } = message.payload;
  let isMoreThanOne = contacts?.length > 1 ? true : false;

  const handleClick = () => {
    setShowContacts(true);
  };
  const hideDialog = () => {
    setShowContacts((prev) => !prev);
  };

  return (
    <>
      <div className="w-full cursor-pointer" onClick={handleClick}>
        <div className=" card flex align-items-center">
          <AvatarGroup>
            {contacts?.map((_, i) => (
              <Avatar icon="pi pi-user" shape="circle" key={i} />
            ))}
            {isMoreThanOne ? (
              <>
                <Text type={"sub-heading"}>
                  {contacts[0].name.first_name} and {contacts.length} other
                  contacts
                </Text>
              </>
            ) : (
              <Text>{contacts[0].name.first_name}</Text>
            )}
          </AvatarGroup>
        </div>
        {isMoreThanOne && (
          <div className={`text-center ${styles.view_all} my-2 text-sm pt-2`}>
            View All
          </div>
        )}
      </div>
      <Dialog
        visible={showContact}
        onHide={hideDialog}
        header={<Text type={"heading"}>View Contacts</Text>}
        className="w-11 md:w-6 lg:w-4"
      >
        <TableActionsContainer>
          {contacts?.map((item) => (
            <ContactPlate contact={item} />
          ))}
        </TableActionsContainer>
      </Dialog>
    </>
  );
};

export default Contacts;
