// Save Message is in Constants

// Messages
// Product List
export const Add_Product_ToastSuccessMessage = 'Product Successfully Added';
export const Update_Product_ToastSuccessMessage = 'Product Successfully Updated';
export const Delete_Product_ToastSuccessMessage = 'Product Successfully Deleted';
export const Share_Product_ToastSuccessMessage = 'Product Successfully Shared';


export const Add_Product_ToastFailMessage = 'Product Fail to Add';
export const Update_Product_ToastFailMessage = 'Product Fail to Update';
export const Delete_Product_ToastFailMessage = 'Product Fail to Delete';
export const Share_Product_ToastFailMessage = 'Product Fail to Share';

// Orders Notification 

export const Delivered_Order_ToastSuccessMessage = 'Order Delivered';
export const Pending_Order_ToastSuccessMessage = 'Order Pending';
export const Progress_Order_ToastSuccessMessage = 'Order In Progress';
export const Cancel_Order_ToastSuccessMessage = 'Order Canceled';

export const Delivered_Order_ToastFailMessage = 'Order Failed to Make Changes';
export const Pending_Order_ToastFailMessage = 'Order Failed to Make Changes';
export const Cancel_Order_ToastFailMessage = 'Order Failed to Make Changes';

// Enquiry Notification
export const Pending_Enq_ToastSuccessMessage = 'Enquiry Pending';
export const Progress_Enq_ToastSuccessMessage = 'Enquiry In Progress';
export const Done_Enq_ToastSuccessMessage = 'Enquiry Done';

export const Pending_Enq_ToastFailMessage = 'Enquiry Failed to Edit';
export const Done_Enq_ToastFailMessage = 'Enquiry Failed to Edit';



// Categories Notification
export const Add_Cat_ToastSuccessMessage = 'Category Successfully Added';
export const Update_Cat_ToastSuccessMessage = 'Category Successfully Updated';
export const Delete_Cat_ToastSuccessMessage = 'Category Successfully Deleted';

export const Add_Cat_ToastFailMessage = 'Category Failed to Added';
export const Update_Cat_ToastFailMessage = 'Category Fail to Update';
export const Delete_Cat_ToastFailMessage = 'Category Fail to Delete';

// Customer Notification
export const Add_Cust_ToastSuccessMessage = 'Customer Successfully Added';
export const Add_Cust_In_Group_ToastSuccessMessage = 'Customer Successfully Added to Selected Groups';
export const Update_Cust_ToastSuccessMessage = 'Customer Successfully Updated';
export const Delete_Cust_ToastSuccessMessage = 'Customer Successfully Deleted';

export const Add_Cust_ToastFailMessage = 'Customer Failed to Added';
export const Update_Cust_ToastFailMessage = 'Customer Fail to Update';
export const Delete_Cust_ToastFailMessage = 'Customer Fail to Delete';

// Group Notification
export const Add_Group_ToastSuccessMessage = 'Group Successfully Added';
export const Update_Group_ToastSuccessMessage = 'Group Successfully Updated';
export const Delete_Group_ToastSuccessMessage = 'Group Successfully Deleted';

export const Name_Group_ToastFailMessage = 'Please Enter Group Name';
export const Add_Group_ToastFailMessage = 'Group Failed to Added';
export const Update_Group_ToastFailMessage = 'Group Fail to Update';
export const Delete_Group_ToastFailMessage = 'Group Fail to Delete';


// Campaign Toast
export const Saved_Camp_Detail_ToastSuccessMessage = 'Campaign Successfully Saved';
export const Share_Camp_ToastSuccessMessage = 'Campaign Successfully Shared';
export const Add_Camp_ToastSuccessMessage = 'Campaign Successfully Added';
export const Update_Camp_ToastSuccessMessage = 'Campaign Successfully Updated';
export const ERR_Camp_Media_ToastFailMessage = 'Campaign Successfully Deleted';
export const ERR_Camp_Cust_ToastFailMessage = 'Campaign Successfully Deleted';


export const Share_Camp_ToastFailMessage = 'Campaign Failed to Shared';
export const Add_Camp_ToastFailMessage = 'Campaign Failed to Added';
export const Update_Camp_ToastFailMessage = 'Campaign Fail to Update';
export const Delete_Camp_ToastFailMessage = 'Campaign Fail to Delete';

// Template Notification
export const Add_Template_ToastSuccessMessage = 'Template Added';
export const Update_Template_ToastSuccessMessage = 'Template Updated';
export const Delete_Template_ToastSuccessMessage = 'Template Deleted';


//Payment 
export const ERR_Select_Plan = 'Please Select a Plan';


// Password 
export const Update_Pass_ToastSuccessMessage = 'Password changed successfully';


// USER
export const Create_USER_ToastSuccessMessage = 'New user successfully created';
export const Update_USER_ToastSuccessMessage = 'User successfully updated';
export const Delete_USER_ToastSuccessMessage = 'User successfully deleted';
export const Succes_USER_Logo_ToastSuccessMessage = 'Logo successfully updated';


// Login
export const Forgot_ToastSuccessMessage = 'Password reset, Please login using new credentials';
export const Success_Register_ToastSuccessMessage = 'Successfully registered, Please login using credentials';


//
export const Rename_MEDIA_ToastSuccessMessage = 'Media successfully renamed';
export const Delete_MEDIA_ToastSuccessMessage = 'Media successfully deleted';
export const Delete_MEDIA_MULTIPLE_ToastSuccessMessage = 'Media successfully deleted';

export const Delete_UPI_ToastSuccessMessage = 'UPI Id successfully deleted'