import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from "primereact/button";
import { CustomButton } from "../components/CustomButton";
import style from "./styles.module.css";
const UtilityContext = React.createContext(null);
export function useUtility() {
  return useContext(UtilityContext);
}

export function UtilityProvider(props) {
  const [alert,setAlert]=useState(false)
  const navigate=useNavigate()
  const navigation=(...prop)=>{
    if(alert){
        const accept = () => {
            setAlert(false);
            navigate(...prop)
        }
        const reject = () => {
            return;
        }
        confirmDialog({
            group: 'headless',
            mahmut: 'mahmut',
            message: 'Closing this window may result in data loss. Are you sure you want to proceed?',
            // icon: 'pi pi-exclamation-triangle',
            position:'top',
            header:false,
            showHeader:false,
            contentClassName:'pt-4 ',
            className:`${style['head-pop']}`,
            rejectLabel:'Cancel',
            acceptLabel:'Confirm',
            acceptClassName:`${style['accept-btn']}`,
            rejectClassName:`${style['reject-btn']}`,
            accept,
            reject
        });
    }else{
        navigate(...prop)
    }
  }
  useEffect(() => {
    if(alert){
        const handleBeforeUnload = (event) => {
          const message = 'Closing this window may result in data loss. Are you sure you want to proceed?';
          event.returnValue = message;
          return message;
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }
  }, []);
  
  
  const value={
    alert,
    setAlert,
    navigate,
    navigation,
  };
  return (
    <UtilityContext.Provider value={value}>
      <ConfirmDialog/>
      {props.children}
    </UtilityContext.Provider>
  );
}
