import axios from "axios";
import axiosInstance from "./axios.instance";
import { async } from "q";
const API_MEDIA_UPLOAD = async (imageData) => {
  try {
    let finalresp = await axiosInstance.post("/image/add", {
      imageType: "product",
      ...imageData,
      SKUCode: imageData?.SKUCode,
    });
    return finalresp?.data;
  } catch (error) {
    return error;
  }
};

const API_MEDIA_EDIT = async (imageData) => {
  try {
    let finalresp = await axiosInstance.put(`/image/${imageData.id}`, {
      imageType: "product",
      ...imageData,
      SKUCode: imageData?.SKUCode,
    });
    return finalresp?.data;
  } catch (error) {
    return error;
  }
};

const API_GET_MEDIA = async (
  pageNo,
  limit,
  startDate,
  endDate,
  filterData,
  globalFilterValue
) => {
  try {
    var resp;
    if (filterData || globalFilterValue || (startDate && endDate)) {
      let allFilter = "";
      if (filterData) {
        filterData.forEach((element) => {
          allFilter += `&${element.key}=${element.value}`;
        });
      }
      if (globalFilterValue) {
        allFilter += `&fileName=${globalFilterValue}`;
      }
      if (startDate && endDate) {
        allFilter += `&startDate=${startDate}&endDate=${endDate}`;
      }
      resp = await axiosInstance.get(
        `/image/?page=${pageNo}&limit=${limit}&isActive=1${allFilter}`
      );
      return resp.data;
    } else {
      resp = await axiosInstance.get(
        `/image/?page=${pageNo}&limit=${limit}&isActive=1`
      );
      return resp.data;
    }
  } catch (error) {
    return error;
  }
};

const API_MEDIA_DELETE = (id) => {
  try {
    const resp = axiosInstance.delete(`/image/${id}`);
    return id;
  } catch (error) {
    return error;
  }
};

const API_MEDIA_DELETE_BULK = async (id) => {
  try {
    let resp = await axiosInstance.delete(`/image/bulk`, {
      data: { id },
    });
    return resp;
  } catch (error) {
    return error;
  }
};

export {
  API_MEDIA_UPLOAD,
  API_GET_MEDIA,
  API_MEDIA_DELETE,
  API_MEDIA_EDIT,
  API_MEDIA_DELETE_BULK,
};
