import { useEffect } from "react";
import { getDate } from "../../utils/datemaker";
import CustomPaginator from "../../components/CustomPaginator";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "primereact/card";
import Loader from "../../components/Loader";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { Text } from "../../components/Text";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import CONSTANTS from "../../config/Constants";
import DateRangePicker from "../../components/common/DateRangePicker";
import DebounceSearch from "../../components/common/DebounceSearch";
import "./style.css";
import { Image } from "primereact/image";
import { ReactComponent as UploadIcon } from "../../svg/uploadIcon.svg";
import {
  changePage,
  changelimit,
  deletebulkMedias,
  getMedia,
  reset,
  uploadMedia,
} from "../../reducers/mediaTabelSlice";
import { dateCompiler } from "../../utils/tableUtils";
import ImagePreview from "./ImagePreview";
import styles from "./style.module.css";
import SklbtConfirmation from "../../components/common/SklbtConfirmation";
import * as Messag from "../../config/ToastMessage";
import { useLayout } from "../../context/LayoutContext";
import permissions from "../../roles/permissions";
import modulesName from "../../roles/modulesName";

const ImageInventory = () => {
  const { mediaData, totalMediaCount, loading, page, limit } = useSelector(
    (state) => state.media
  );
  const [dateRange, setDateRange] = useState({ fromDate: "", toDate: "" });
  const [inputValue, setInputValue] = useState("");
  const { accesskey } = useSelector((state) => state.authenticate);
  const [deletedImages, setDeletedImages] = useState([]);
  const [select, setSelect] = useState(false);
  const handleDateRangeFrom = (newDate) => {
    setDateRange((prev) => {
      return { ...prev, fromDate: newDate };
    });
  };
  const handleDateRangeTo = (newDate) => {
    setDateRange((prev) => {
      return { ...prev, toDate: newDate };
    });
  };
  const toast = useRef();
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const mediaFileRef = useRef(null);
  const managePaginationData = () => {
    let filterData = [];
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    var { fromDate, toDate } = dateRange;
    if (!toDate) toDate = fromDate;
    let paginationData = {
      page: page,
      limit: limit,
      startDate: fromDate
        ? new Date(fromDate - tzoffset).toISOString().substring(0, 10)
        : fromDate,
      endDate: toDate
        ? new Date(toDate - tzoffset).toISOString().substring(0, 10)
        : toDate,
      globalFilterValue: inputValue,
    };
    return paginationData;
  };
  const { toastMessage } = useLayout();

  useEffect(() => {
    let paginationData = managePaginationData();
    var { fromDate, toDate } = dateRange;
    if ((fromDate && !toDate) || (!fromDate && toDate)) return;
    else {
      dispatch(getMedia(paginationData));
    }
    return () => {
      setImages([]);
    };
  }, [page, limit, dateRange, inputValue]);

  useEffect(() => {
    return () => {
      dispatch(reset());
      setImages([]);
    };
  }, []);

  const imageUpload = (e) => {
    let newImgLists = [...e.target.files]?.map((imgFile) => {
      return {
        id: uuidv4(),
        image: imgFile,
      };
    });
    setImages((prev) => [...newImgLists, ...prev]);
  };

  const multipleDelete = () => {
    setSelect(true);
  };

  const removeImageLocal = (image) => {
    setImages((prev) => prev?.filter((img) => img?.id != image?.id));
  };

  const clearSelected = () => {
    setDeletedImages([]);
    setSelect(false);
  };
  const [showDeleteModel, setshowDeleteModel] = useState(false);

  const handlemultipleImage = async () => {
    dispatch(deletebulkMedias({ id: deletedImages }))
      .unwrap()
      .then((e) => {
        setDeletedImages([]);
        toastMessage({
          message: "success",
          detail: Messag.Delete_MEDIA_MULTIPLE_ToastSuccessMessage,
        });
      });
    clearSelected();
    setshowDeleteModel(false);
  };

  const closeselectRef = useRef();

  return (
    <div className="m-3">
      <Toast ref={toast} />
      <Loader visible={loading} />
      <TableActionsContainer>
        <div className="flex flex-column md:flex-row justify-content-between gap-3">
          <div className="flex justify-content-between align-items-center gap-2">
            <Text type="heading">Image Inventory</Text>
            {select && (
              <div
                className="flex cursor-pointer flex-row align-items-center text-base font-normal "
                style={{ color: "#005068" }}
              >
                <div
                  className={`${deletedImages?.length ? `` : `opacity-40`}`}
                  onClick={() =>
                    deletedImages?.length && setshowDeleteModel(true)
                  }
                >
                  ({deletedImages?.length | 0}) Delete selected
                </div>
                &nbsp;&nbsp;
                <div
                  className="text-black-alpha-90 text-xl cursor-pointer pb-1 pl-4"
                  onClick={clearSelected}
                >
                  {" "}
                  x{" "}
                </div>
              </div>
            )}
          </div>

          <div className="flex gap-2 flex-wrap ">
            <DateRangePicker
              fromDate={dateRange.fromDate}
              toDate={dateRange.toDate}
              handleFromDate={handleDateRangeFrom}
              handleToDate={handleDateRangeTo}
            />
            <DebounceSearch
              placeholder={"Search Images"}
              inputValue={inputValue}
              handleInputChange={(value) => setInputValue(value)}
            />
          </div>
        </div>
      </TableActionsContainer>
      <SklbtConfirmation
        icon="pi pi-trash"
        message={CONSTANTS.MEDIA_DELETE_CONFIRMATION.replace(
          "{{value}}",
          deletedImages.length
        )}
        visible={showDeleteModel}
        handleConfirm={handlemultipleImage}
        handleReject={() => setshowDeleteModel(false)}
      />

      <div className="my-3">
        <Text type="small-text">
          * Link the product image with its SKU code by matching the image name.
        </Text>
      </div>
      <div className="flex justify-content-center flex-column">
        <div className="flex gap-3 flex-wrap flex-row cards-img">
          {accesskey[modulesName.PRODUCT]?.includes(permissions.CREATE) && (
            <div
              className={`p-1 card relative flex flex-row justify-content-center cursor-pointer gap-2 align-items-center ${styles["preview-container"]} `}
              style={{ minWidth: "200px", minHeight: "240px" }}
              onDragEnd={(e) => {
                // console.log(e);
              }}
              onClick={() => mediaFileRef.current.click()}
            >
              <input
                ref={mediaFileRef}
                type="file"
                accept={`image/*`}
                hidden
                onChange={imageUpload}
                multiple
              />
              <UploadIcon />
              <span>Upload Image</span>
            </div>
          )}
          {images?.map((image) => (
            <div key={image.id}>
              <ImagePreview
                imgId={image?.id}
                image={image.image}
                removeImgClick={removeImageLocal}
                isSaved={false}
                select={select}
                showSelect={multipleDelete}
                deletedImages={deletedImages}
                setDeletedImages={setDeletedImages}
                closeselectRef={closeselectRef}
              />
            </div>
          ))}
          {mediaData?.map((image) => (
            <div key={image?.id}>
              <ImagePreview
                image={image}
                isSaved={true}
                select={select}
                showSelect={multipleDelete}
                deletedImages={deletedImages}
                setDeletedImages={setDeletedImages}
                closeselectRef={closeselectRef}
              />
            </div>
          ))}
        </div>
        <TableActionsContainer>
          <div className="flex justify-content-end">
            <CustomPaginator
              page={page}
              limit={limit}
              totalRecords={totalMediaCount}
              changePage={changePage}
              changelimit={changelimit}
            />
          </div>
        </TableActionsContainer>
      </div>
    </div>
  );
};

export default ImageInventory;
