import { useState } from "react";
import { CustomButton } from "../../../components/CustomButton";
import { Text } from "../../../components/Text";
import { useDispatch, useSelector } from "react-redux";
import QNoteCard from "./QNoteCard";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { changeQNotepage, getQuickNotes } from "../../../reducers/accountSlice";
import { useRef } from "react";

function TextAutomation() {
  const [loading, setLoading] = useState(false);

  const { quickNotes, quickNotesCounts, qNotePage, qNoteLimit } = useSelector(
    (state) => state.account
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollRef = useRef();

  const handleScroll = () => {
    if (scrollRef.current && !loading) {
      const { scrollTop, clientHeight, scrollHeight } = scrollRef.current;

      const tolerance = 1; // to handle decimal values of scrollTop

      if (scrollTop + clientHeight + tolerance >= scrollHeight) {
        const previousScrollTop = scrollRef.current.scrollTop;
        dispatch(changeQNotepage(qNotePage + 1));
        scrollRef.current.scrollTop = previousScrollTop;
      }
    }
  };

  useEffect(() => {
    //if (qNotePage == 0 && quickNotes.length != 0) return;
    if (quickNotes.length == 0 || quickNotes.length < quickNotesCounts) {
      setLoading(true);
      dispatch(getQuickNotes({ page: qNotePage, limit: qNoteLimit }))
        .unwrap()
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
        });
    }
  }, [qNotePage, qNoteLimit]);
  return (
    <div className="mt-3">
      <Text type={"heading"}>Text Automation</Text>
      <CustomButton
        type="button"
        varient="filled"
        icon={"pi pi-plus"}
        label={"Add quick note"}
        onClick={() => navigate("/account/quick-notes/new")}
        style={{ margin: "15px 0" }}
      />
      <div
        ref={scrollRef}
        style={{ maxHeight: "500px", overflowY: "auto" }}
        onScroll={handleScroll}
      >
        <div className="h-full w-full">
          {quickNotes?.map((item) => (
            <div key={item?.id}>
              <QNoteCard data={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TextAutomation;
