import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Loader from "../../components/Loader";
import { Text } from "../../components/Text";
import { ReactComponent as Edit } from "../../svg/edit.svg";
import { CustomButton } from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { getDate } from "../../utils/datemaker";
import * as Messag from "../../config/ToastMessage";
import {
  changeSelectedOrder,
  updateMode,
  getOrders,
  resetMode,
  updateOrder,
  changePage,
  resetSelectedOrder,
  changelimit,
  updateSelectedOrdersList,
  resetSelectedOrdersList,
  getOrdersbyid,
} from "../../reducers/orderTableSlice";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import CustomPaginator from "../../components/CustomPaginator";
import "./style.css";
import OrderPreview from "./OrderPreview";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import DateRangePicker from "../../components/common/DateRangePicker";
import DebounceSearch from "../../components/common/DebounceSearch";
import { useNavigate, useParams } from "react-router-dom";
import CustomSaveModel from "../../components/CustomeSaveModel";
import CONSTANTS from "../../config/Constants";
import { ReactComponent as PrintIcon } from "../../svg/printicon.svg";
import { formatMobNumber } from "../../utils/commonUtils";
import TrailDashboard from "../TrailDashboard";

const statusOptions = [
  { key: "New", value: "New" },
  { key: "In Progress", value: "In Progress" },
  { key: "Delivered", value: "Delivered" },
  { key: "Cancelled", value: "Cancelled" },
];
const OrderTable = () => {
  const [inputValue, setInputValue] = useState("");
  const [dateRange, setDateRange] = useState({ fromDate: "", toDate: "" });

  const {
    orderData,
    loading,
    mode,
    page,
    limit,
    selectedOrder,
    totalOrderCount,
    selectedOrderId,
    selectedOrdersList,
  } = useSelector((state) => state.orderTable);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getOrdersbyid({ orderId: id }))
        .unwrap()
        .then((e) => {
          const selectedOrders = [e];
          dispatch(updateSelectedOrdersList({ selectedOrders }));
        })
        .catch((err) => {
          navigate("/orders");
        });
    } else {
      dispatch(updateSelectedOrdersList({ selectedOrders: [] }));
      dispatch(resetSelectedOrder());
    }
  }, [id]);
  const { userSub } = useSelector((state) => state.authenticate);

  const [status, setStatus] = useState(selectedOrder.status);
  const [previewOrder, setPreviewOrder] = useState(false);

  const dispatch = useDispatch();

  const toast = useRef(null);
  const modalLoad = () => {
    return <Loader visible={loading} />;
  };

  const handleDateRangeFrom = (newDate) => {
    setDateRange((prev) => {
      return { ...prev, fromDate: newDate };
    });
  };
  const handleDateRangeTo = (newDate) => {
    setDateRange((prev) => {
      return { ...prev, toDate: newDate };
    });
  };

  const handleEdit = (rowdata) => {
    if (!rowdata["orderLabel"]) {
      let orderWithLabel = {
        ...rowdata,
        orderLabel: `Order Id #${rowdata.id}`,
      };
      dispatch(changeSelectedOrder(orderWithLabel));
      navigate(`/orders/${rowdata.id}`);
    } else {
      dispatch(changeSelectedOrder(rowdata));
    }
    dispatch(updateMode("update"));
  };
  const [showSave, setShowSave] = useState(false);
  const status_value = useRef(null);

  const SaveModel = () => {
    return (
      <CustomSaveModel
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };

  const alertText = () => {
    let value =
      status_value?.current?.charAt(0)?.toUpperCase() +
      status_value?.current?.slice(1);
    return (
      <div>
        {CONSTANTS.ORDER_STATUS_CONFIRMATION.replace("{{value}}", value)}
      </div>
    );
  };

  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false);
          }}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={() => {
            handleSaveStatus();
            setShowSave(false);
          }}
        />
      </div>
    );
  };

  const onHide = () => {
    setShowSave(false);
  };

  const statusModule = (rowData) => {
    if (mode === "update" && selectedOrder?.id === rowData.id) {
      return (
        <Dropdown
          value={status}
          onChange={(e) => {
            status_value.current = e.target.value;
            setStatus(e.target.value);
          }}
          options={statusOptions}
          optionLabel="key"
          optionValue="value"
        />
      );
    } else return rowData.status;
  };

  const handleSaveStatus = () => {
    const orderId = selectedOrder.id;
    const updatedStatus = {
      status: status,
    };
    dispatch(updateOrder({ orderId, updatedStatus }))
      .unwrap()
      .then((res) => {
        if (updatedStatus.status === "Cancelled") {
          let Message_Success = Messag.Cancel_Order_ToastSuccessMessage;
          toast.current.show({ severity: "success", detail: Message_Success });
        }
        if (updatedStatus.status === "Delivered") {
          let Message_Success = Messag.Delivered_Order_ToastSuccessMessage;
          toast.current.show({ severity: "success", detail: Message_Success });
        }
        if (updatedStatus.status === "In Progress") {
          let Message_Success = Messag.Progress_Order_ToastSuccessMessage;
          toast.current.show({ severity: "success", detail: Message_Success });
        }
      })
      .catch((err) => {
        //show toast from here
        toast.current.show({ severity: "error", detail: err.response.data });
      });
  };

  const handleCancelUpdate = () => {
    dispatch(resetMode());
  };

  const actionBodyTemplate = (rowData) => {
    if (mode === "update" && selectedOrder.id === rowData.id) {
      return (
        <div className="flex gap-2">
          <CustomButton
            varient="icon-button"
            onClick={() => {
              setShowSave(true);
            }}
            icon={"pi pi-check"}
          />
          <CustomButton
            varient="icon-button"
            onClick={() => handleCancelUpdate()}
            icon={"pi pi-times"}
          />
        </div>
      );
    } else {
      return (
        <CustomButton
          varient="icon-button"
          onClick={() => handleEdit(rowData)}
          icon={<Edit />}
        />
      );
    }
  };

  useEffect(() => {
    if (selectedOrderId !== "all") {
      setStatus(selectedOrder.status);
    }
  }, [selectedOrder]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedOrdersList());
      dispatch(resetSelectedOrder());
    };
  }, []);

  useEffect(() => {
    const paginationData = managePaginationData();
    const { fromDate, toDate } = dateRange;
    if ((fromDate && !toDate) || (!fromDate && toDate)) return;

    dispatch(getOrders(paginationData))
      .unwrap()
      .catch(() => {
        //show toast from here
      });
    return () => {
      dispatch(resetSelectedOrder());
    };
  }, [inputValue, dateRange, page, limit]);

  const phoneBodyTemplate = (rowdata) => {
    return rowdata?.phone ? formatMobNumber(rowdata.phone) : ``;
  };
  const navigate = useNavigate();
  const handleOrderSelect = (order) => {
    dispatch(resetSelectedOrdersList());
    if (order.id !== selectedOrderId) {
      if (mode === "update") dispatch(resetMode());

      if (order.id !== "all" && !order["orderLabel"]) {
        let orderWithLabel = { ...order, orderLabel: `Order Id #${order.id}` };
        dispatch(changeSelectedOrder(orderWithLabel));
        navigate(`/orders/${order.id}`);
      } else {
        dispatch(changeSelectedOrder(order));
      }
    } else return;
  };

  ///filter--------------------------------
  const [filtersData, setFiltersData] = useState({
    name: null,
    status: null,
  });

  const [filters1, setFilters1] = useState({
    name: { value: null },
    status: { value: null },
  });

  let stocks = ["New", "In Progress", "Delivered", "Cancelled"];

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={stocks}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        itemTemplate={statusItemTemplate}
        placeholder="Select"
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };

  const onClearStatusFilter = () => {
    filters1.status.value = null;
    filtersData.status = null;
    onApplyAllFilter();
  };

  const onClickFilter = (e) => {
    switch (e.field) {
      case "name":
        filters1.name.value = e.constraints.constraints[0].value;
        filtersData.name = e.constraints.constraints[0].value;
        break;

      case "status":
        filters1.status.value = e.constraints.value;
        filtersData.status = e.constraints.value;
        break;
      default:
        console.log("on click filter ", e);
        break;
    }

    onApplyAllFilter();
  };

  const managePaginationData = () => {
    let filterData = [];
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    const { fromDate, toDate } = dateRange;
    for (var key in filtersData) {
      if (filtersData[key]) {
        filterData.push({
          key: key,
          value: filtersData[key],
        });
      }
    }
    let paginationData = {
      page: page,
      limit: limit,
      startDate: fromDate
        ? new Date(fromDate - tzoffset).toISOString().substring(0, 10)
        : fromDate,
      endDate: toDate
        ? new Date(toDate - tzoffset).toISOString().substring(0, 10)
        : toDate,
      filterData,
      globalFilterValue: inputValue,
    };
    return paginationData;
  };

  const onApplyAllFilter = () => {
    let paginationData = managePaginationData();
    dispatch(getOrders(paginationData))
      .unwrap()
      .catch(() => {
        //show toast from here
      });
  };

  // order printout
  const openPreview = () => {
    setPreviewOrder(true);
  };

  const orderPreviewModal = () => {
    return (
      <OrderPreview
        previewOrder={previewOrder}
        setPreviewOrder={setPreviewOrder}
        toast={toast}
      />
    );
  };

  const onSelectOrder = (e) => {
    const selectedOrders = e.value;
    const currOrders = orderData;
    dispatch(updateSelectedOrdersList({ selectedOrders, currOrders }));
  };

  const viewDetailsBody = (rowData) => {
    return (
      <CustomButton
        varient="icon-button"
        onClick={(e) => handleOrderSelect(rowData)}
        label="View Details"
      />
    );
  };

  const rowIndexTemplate = (rowData, props) => {
    let index = parseInt(props.rowIndex, 10);
    return (
      <React.Fragment>
        <span>{totalOrderCount - limit * page - index}</span>
      </React.Fragment>
    );
  };
  return (
    <div>
      <SaveModel />
      <div className="p-3">
        <Toast ref={toast} />
        {!userSub.isUserSub ? (
          <TrailDashboard/>
        ) : (
          <div>
            <div className="card">
              <div className="mb-3">
                <TableActionsContainer>
                  <div className="flex justify-content-between align-items-center flex-wrap gap-3">
                    <div className="flex gap-3 justify-content-between align-items-center flex-grow-1">
                      <Text type={"heading"}>Order List</Text>

                      <div
                        className={`block lg:hidden xl:hidden cursor-pointer ${
                          !selectedOrdersList?.length ? "opacity-60" : ""
                        }`}
                        onClick={() => {
                          selectedOrdersList?.length && openPreview();
                        }}
                      >
                        <PrintIcon />
                      </div>
                    </div>
                    <div className="flex gap-3 flex-wrap-reverse">
                      {selectedOrderId === "all" && (
                        <div className="flex gap-2 flex-wrap ">
                          <DateRangePicker
                            fromDate={dateRange.fromDate}
                            toDate={dateRange.toDate}
                            handleFromDate={handleDateRangeFrom}
                            handleToDate={handleDateRangeTo}
                          />
                          <DebounceSearch
                            placeholder={"Search Order"}
                            inputValue={inputValue}
                            handleInputChange={(value) => setInputValue(value)}
                          />
                        </div>
                      )}
                      <div
                        className={`hidden lg:block xl:block cursor-pointer  ${
                          !selectedOrdersList?.length ? "opacity-60" : ""
                        }`}
                        onClick={() => {
                          selectedOrdersList?.length && openPreview();
                        }}
                      >
                        <PrintIcon />
                      </div>
                    </div>
                  </div>
                </TableActionsContainer>
              </div>

              {modalLoad()}
              {previewOrder && orderPreviewModal()}
              <DataTable
                value={orderData}
                className="skalebot-table"
                selectionMode="checkbox"
                selection={selectedOrdersList}
                onSelectionChange={onSelectOrder}
                resizableColumns
                columnResizeMode="expand"
                filters={filters1}
                filterDisplay="menu"
                dataKey="id"
                globalFilterFields={["name", "status"]}
                // header={header1}
              >
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: "3rem" }}
                ></Column>

                <Column
                  field="orderNumber"
                  header="Order No."
                  className="text-sm font-normal"
                  style={{ width: "3rem" }}
                />
                {/* <Column field="id" header="Order Id" /> */}
                <Column
                  field="createdAt"
                  header="Date"
                  style={{ width: "30ch" }}
                  className="text-sm font-normal"
                  body={(rowData) =>
                    rowData?.createdAt ? getDate(rowData.createdAt) : ""
                  }
                  bodyStyle={{ textTransform: "uppercase" }}
                />
                <Column
                  field={(rowData) =>
                    rowData.businessName ? rowData.businessName : rowData.name
                  }
                  header="Name"
                  className="capitalize text-sm font-normal"
                />
                <Column
                  field="phone"
                  header="Phone"
                  style={{ width: "30ch" }}
                  body={phoneBodyTemplate}
                />
                <Column
                  field="status"
                  header={() => (
                    <div className="flex gap-2 align-items-end">
                      <Text>Status</Text>
                    </div>
                  )}
                  body={statusModule}
                  showFilterMatchModes={false}
                  filterMenuStyle={{ width: "8rem" }}
                  style={{ width: "20ch" }}
                  filter
                  className="text-sm font-normal"
                  filterElement={statusFilterTemplate}
                  onFilterApplyClick={(e) => onClickFilter(e)}
                  onFilterClear={onClearStatusFilter}
                />
                <Column
                  header="Actions"
                  style={{ width: "5ch" }}
                  body={actionBodyTemplate}
                />

                <Column
                  body={viewDetailsBody}
                  style={{ width: "10ch" }}
                  bodyStyle={{ color: "#005068" }}
                />
              </DataTable>
            </div>
            <div className="mt-3">
              <TableActionsContainer>
                <div className="flex justify-content-end">
                  <CustomPaginator
                    page={page}
                    limit={limit}
                    changelimit={changelimit}
                    totalRecords={totalOrderCount}
                    changePage={changePage}
                  />
                </div>
              </TableActionsContainer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderTable;
