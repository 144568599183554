import { useDispatch } from "react-redux";
import { Text } from "../Text";
import styles from "./style.module.css";
import { removeProductFromProductsBList } from "../../reducers/productTableSlice";
import { removeCustomerFromSeletedList } from "../../reducers/ShareMessage/messageSlice";

const ItemCard = ({ title, itemId, target }) => {
  const dispatch = useDispatch();

  const handleRemoveItem = () => {
    switch (target) {
      case "product":
        dispatch(removeProductFromProductsBList(itemId));

        break;
      case "customer":
        dispatch(removeCustomerFromSeletedList(itemId));
        break;
      default:
        break;
    }
  };
  return (
    <div key={itemId} className={`px-2 py-1 ${styles["item-card"]}`}>
      <div className="flex justify-content-between gap-2 align-items-center">
        <Text type={"heading"}>{title}</Text>

        <div
          className={` cursor-pointer text-center`}
          onClick={handleRemoveItem}
        >
          <i className={`pi pi-times ${styles["icon-cancel"]}`} />
        </div>
      </div>
    </div>
  );
};
function SelectedItemList({ items = [], itemLabel, removeId, target = "" }) {
  return (
    <div className={`w-full overflow-x-auto p-2 ${styles["main-contaier"]}`}>
      <div className="flex gap-2 align-items-center">
        {items?.map((item) => (
          <ItemCard
            title={item[itemLabel]}
            itemId={item[removeId]}
            target={target}
          />
        ))}
        {/* <div>
          {items.slice(6).length != 0 && (
            <Text type={"heading"}>
              +&nbsp;{items.slice(6).length}&nbsp;more
            </Text>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default SelectedItemList;
