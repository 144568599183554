import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_GET_TICKETS } from "../api/ticket.services";
import { extractVariablesFormMessage } from "../utils/templateUtils";

const initialState = {
  ticketData: [],
  totalTicketCount: 0,
  loading: false,
  page: 0,
  limit: 30,
};

export const getTickets = createAsyncThunk(
  "ticketTable/getTicketList",
  async (
    { page, limit, startDate, endDate, filterData, globalFilterValue },
    thunkAPI
  ) => {
    try {
      const enquiries = await API_GET_TICKETS(
        page,
        limit,
        startDate,
        endDate,
        filterData,
        globalFilterValue
      );
      return enquiries;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const ticketTableSlice = createSlice({
  name: "ticketsTable",
  initialState,
  reducers: {
    changePage(state, action) {
      state.page = action.payload;
    },
    changelimit(state, action) {
      state.limit = action.payload;
    },
    resetTicketPagination(state) {
      state.page = initialState.page;
      state.limit = initialState.limit;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getTickets.fulfilled, (state, action) => {
      state.totalTicketCount = action.payload.count;
      state.ticketData = action.payload.row;
      state.loading = false;
    });
    builder.addCase(getTickets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTickets.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { changePage, changelimit, resetTicketPagination } =
  ticketTableSlice.actions;

export default ticketTableSlice.reducer;
