import React from 'react'
import styles from "./style.module.css";

export const CustomMessageText = ({ type, color, style, children, cust = "" }) => {

    
      const parseText = (textStr) => {
        // const text = String(textStr); // Convert children to string
        const text = Array.isArray(textStr) ? textStr.join('') : String(textStr);
        let segments = [];
        let currentSegment = "";
    
        for (let i = 0; i < text.length; i++) {
          if (text[i] === "_") {
            // If it's italic, add the current segment as plain text
            if (currentSegment !== "") {
              segments.push(currentSegment);
              currentSegment = "";
            }
    
            // Move to the end of the italic section
            const endIdx = text.indexOf("_", i + 1);
            if (endIdx !== -1) {
              segments.push(
                <i key={segments.length}>
                  {text.substring(i + 1, endIdx)}
                </i>
              );
              i = endIdx;
            }
          } else if (text[i] === "*") {
            // If it's bold, add the current segment as plain text
            if (currentSegment !== "") {
              segments.push(currentSegment);
              currentSegment = "";
            }
    
            // Move to the end of the bold section
            const endIdx = text.indexOf("*", i + 1);
            if (endIdx !== -1) {
              segments.push(
                <b key={segments.length}>
                  {text.substring(i + 1, endIdx)}
                </b>
              );
              i = endIdx;
            }
          } else {
            // Add characters to the current segment
            currentSegment += text[i];
          }
        }
    
        // Add the last segment if there's any remaining text
        if (currentSegment.trim() !== "") {
            segments.push(currentSegment);
        }
        return segments
    };
 
    return (
        <div
          className={`${styles[type]} ${cust} ${styles["custom-text"]}`}
          style={{ color: color, ...style }}
        >
          {parseText(children)}
        </div>
      );
}
