import Header from "./Header";
import Welcome from "./Welcome";
import VideoTour from "./VideoTour";
import PaymentPlan from "./paymentPlan";
import Footer from "./Footer";
import SalesTeam from "./SalesTeam";
import { Navigate } from "react-router-dom";

export const Login = () => {
  const token = localStorage.getItem("token");
  if (token) return <Navigate to={"/"} replace />;
  else
    return (
      <div className={` flex flex-column w-full`}>
        <Header />
        <Welcome />
        <VideoTour />
        <PaymentPlan />
        <SalesTeam />
        <Footer />
      </div>
    );
};
