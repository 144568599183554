import React from "react";

export default function FilesDragAndDrop({
  onUpload,
  children,
  count,
  formats,
}) {
  const drop = React.useRef(null);
  const drag = React.useRef(null);
  const [dragging, setDragging] = React.useState(false);

  React.useEffect(() => {
    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.target !== drag.current) {
        setDragging(true);
      }
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();

      setDragging(true);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();

      setDragging(false);

      // this is required to convert FileList object to array
      const files = [...e.dataTransfer.files];
      // check if the provided count prop is less than uploaded count of files
      if (count && count < files.length) {
        return;
      }

      // check if some uploaded file is not in one of the allowed formats
      if (
        formats &&
        files.some(
          (file) =>
            !formats.some((format) =>
              file.name.toLowerCase().endsWith(format.toLowerCase())
            )
        )
      ) {
        return;
      }

      if (files && files.length) {
        onUpload({ files });
      }
    };

    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);
    drop.current.addEventListener("dragenter", handleDragEnter);
    drop.current.addEventListener("dragleave", handleDragLeave);

    return () => {
      drop.current?.removeEventListener("dragover", handleDragOver);
      drop.current?.removeEventListener("drop", handleDrop);
      drop.current?.removeEventListener("dragenter", handleDragEnter);
      drop.current?.removeEventListener("dragleave", handleDragLeave);
      setDragging(false);
    };
  }, [count, formats, onUpload]);

  return (
    <div
      className={`FilesDragAndDrop__area ${dragging ? "dragging" : ""}`}
      ref={drop}
    >
      {dragging ? (
        <div className="dotted-border-box">Drop your files here</div>
      ) : (
        children
      )}
    </div>
  );
}
