import axiosInstance from "./axios.instance";

const API_ADD_BOT_FLOW_STEPS = async (id, botFlowSteps) => {
  try {
    const resp = await axiosInstance.post(
      `/botflowstep/addFlow/${id}`,
      botFlowSteps
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_GET_BOT_FLOW_SCHEMA = async (id) => {
  try {
    const resp = await axiosInstance.get(`/botflowSchema/${id}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_UPDATE_BOT_FLOW_SCHEMA = async (botFlowData, id) => {
  try {
    const resp = await axiosInstance.post(`/botflowSchema/${id}`, botFlowData);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_DELETE_BOT_FLOW = async (id) => {
  try {
    await axiosInstance.put(`/botflow/${id}`, { isActive: false });
    return id;
  } catch (err) {
    throw err;
  }
};

const API_ADD_BOTFLOW = async (botData) => {
  try {
    const resp = await axiosInstance.post(`/botflow/`, { ...botData });
    return resp.data;
  } catch (error) {
    throw error;
  }
};
const API_UPDATE_BOT_FLOW = async (id, payload) => {
  try {
    const resp = await axiosInstance.put(`/botflow/${id}`, { ...payload });
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_GET_ALL_BOT_FLOWS = async (pageNo, limit) => {
  try {
    const resp = await axiosInstance.get(
      `/botflow?page=${pageNo}&limit=${limit}&isActive=1`
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
};

export {
  API_GET_ALL_BOT_FLOWS,
  API_ADD_BOT_FLOW_STEPS,
  API_GET_BOT_FLOW_SCHEMA,
  API_UPDATE_BOT_FLOW_SCHEMA,
  API_DELETE_BOT_FLOW,
  API_UPDATE_BOT_FLOW,
  API_ADD_BOTFLOW,
};
