import { Dialog } from "primereact/dialog";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { updateCompany } from "../../../reducers/companySlice";
import { classNames } from "primereact/utils";
import { isValidPhoneNumber } from "react-phone-number-input";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { CustomButton } from "../../../components/CustomButton";
import * as Messag from "../../../config/ToastMessage";
import TableActionsContainer from "../../../components/CustomTable/TableActionsContainer";
import { Text } from "../../../components/Text";
import LogoInput from "./LogoInput";
import { useState } from "react";
function EditProfile({
  showDialog = false,
  hideDialog = () => {},
  toast = null,
  profilePicData = null,
}) {
  const [newProfilePic, setNewProfilePic] = useState(null);
  const { company, loadingC } = useSelector((state) => state.company);
  const { user } = useSelector((state) => state.authenticate);

  const dispatch = useDispatch();

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const defaultValues = {
    businessMobno: "",
    personalMobno: "",
    gst: "",
    pan: "",
    billadd: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    if (newProfilePic?.type === "image/svg+xml") {
      toast.current.show({
        severity: "error",
        detail: "SVG images are not supported",
      });
      return;
    }

    const updatedData = {
      billingAddress: data.billadd,
      // phone: data.businessMobno,
      // personalPhone: data.personalMobno,
      gstNumber: data.gst,
      panNumber: data.pan,
    };

    dispatch(
      updateCompany({
        companyId: user.companyId,
        updatedData,
        logoUpdate: { src: profilePicData?.src, newLogo: newProfilePic },
      })
    )
      .unwrap()
      .then(() => {
        hideDialog();
        toast.current.show({
          severity: "success",
          detail: Messag.Update_USER_ToastSuccessMessage,
        });
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          detail: err.message,
        });
      });
  };

  useEffect(() => {
    if (company) {
      setValue("businessMobno", company.phone || "");
      setValue("billadd", company.billingAddress || "");
      setValue("gst", company.gstNumber || "");
      setValue("pan", company.panNumber || "");
      setValue("personalMobno", company.personalPhone || "");
    }
  }, []);

  return (
    <Dialog
      visible={showDialog}
      onHide={hideDialog}
      header={<Text type={"heading"}>Edit profile details</Text>}
      className="w-11 md:w-6 lg:w-4"
    >
      <TableActionsContainer>
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid w-full">
          <div className="field">
            <label
              htmlFor="businessMobno"
              className={classNames({ "p-error": errors.name })}
            >
              <Text>Business Mobile Number</Text>
            </label>
            <Controller
              name="businessMobno"
              control={control}
              rules={{
                // required: "Please enter a Business Mobile Number",
                // validate: (value) =>
                //   isValidPhoneNumber("+" + value.toString()) ||
                //   `Please enter a valid phone number.`,
                pattern: {
                  value: /^[0-9]*$/,
                  message: "Only Numbers are allowed",
                },
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  className={classNames({
                    "p-invalid": fieldState.invalid, 
                  })}
                  placeholder="Enter Business Mobile Number"
                  {...field}
                  disabled={true}
                />
              )}
            />
            {getFormErrorMessage("businessMobno")}
          </div>
          <div className="field">
            <label
              htmlFor="personalMobno"
              className={classNames({ "p-error": errors.name })}
            >
              <Text>Personal Mobile Number</Text>
            </label>
            <Controller
              name="personalMobno"
              control={control}
              rules={{
                required: "Please enter a Personal Mobile Number",
                validate: (value) =>
                  isValidPhoneNumber("+" + value.toString()) ||
                  `Please enter a valid phone number.`,
                pattern: {
                  value: /^[0-9]*$/,
                  message: "Only Numbers are allowed",
                },
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                  placeholder="Enter Personal Mobile Number"
                  {...field}
                  disabled={true}
                />
              )}
            />
            {getFormErrorMessage("personalMobno")}
          </div>
          <div className="field">
            <label
              htmlFor="gst"
              className={classNames({ "p-error": errors.name })}
            >
              <Text>GST No</Text>
            </label>
            <Controller
              name="gst"
              control={control}
              // rules={{ required: "Please enter a GST No" }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                  placeholder="Enter GST No"
                  {...field}
                />
              )}
            />
            {getFormErrorMessage("gst")}
          </div>
          <div className="field">
            <label
              htmlFor="pan"
              className={classNames({ "p-error": errors.name })}
            >
              <Text>PAN No</Text>
            </label>
            <Controller
              name="pan"
              control={control}
              // rules={{ required: "Please enter a PAN No" }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                  placeholder="Enter PAN No"
                  {...field}
                />
              )}
            />
            {getFormErrorMessage("pan")}
          </div>
          <div className="field">
            <label
              htmlFor="billadd"
              className={classNames({ "p-error": errors.name })}
            >
              <Text>Billing Address</Text>
            </label>
            <Controller
              name="billadd"
              control={control}
              // rules={{
              //   required: "Please enter a Billing Address",
              // }}
              render={({ field, fieldState }) => (
                <InputTextarea
                  id={field.name}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                  placeholder="Enter Billing Address"
                  {...field}
                  autoResize
                  rows={3}
                />
              )}
            />
            {getFormErrorMessage("billadd")}
          </div>

          <div className="field">
            <label
              htmlFor="billadd"
              // className={classNames({ "p-error": errors.name })}
            >
              <Text>Profile picture</Text>
            </label>
            <LogoInput
              currLogo={profilePicData?.url}
              handleSelectedImage={(img) => setNewProfilePic(img)}
            />
          </div>

          <div className="flex gap-2">
            <CustomButton
              varient="icon-button "
              type={"button"}
              label={<Text color={"#005068"}>Cancel</Text>}
              onClick={hideDialog}
            />
            <CustomButton varient="filled " type={"submit"} label="Save" />
          </div>
        </form>
      </TableActionsContainer>
    </Dialog>
  );
}

export default EditProfile;
