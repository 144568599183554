import { Dropdown } from "primereact/dropdown";
import { Text } from "../../../components/Text";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_GET_ALL_AGENTS } from "../../../api/account.service";
import { changeTicketAgent } from "../../../reducers/accountSlice";
import CustomSaveModel from "../../../components/CustomeSaveModel";
import CONSTANTS from "../../../config/Constants";
import { CustomButton } from "../../../components/CustomButton";
import modulesName from "../../../roles/modulesName";
import permissions from "../../../roles/permissions";
import { Toast } from 'primereact/toast';      
const TicketReasign = () => {
  const toast = useRef(null);
  const [currAgent, setAgent] = useState(null);
  const [allAgents, setAllAgents] = useState([]);
  const { allotedAgent, user, chatId } = useSelector((state) => state.enqChats);
  const { accesskey } = useSelector((state) => state.authenticate);
  const dispatch = useDispatch();

  const handleChangeAgent = (newAgent) => {
    const payload = {
      ticketId: user.ticketId,
      configData: {
        agentId: newAgent.id,
      },
    };
    dispatch(changeTicketAgent(payload))
      .unwrap()
      .then((res) => {
        setAgent(newAgent);
      })
      .catch((err) => {
        console.log("err", err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: err?.message || "An error occurred.",
        });
      });
  };

  useEffect(() => {
    if (accesskey[modulesName.SALES]?.includes(permissions.CREATE)) {
      API_GET_ALL_AGENTS()
        .then((data) => {
          let supportAgents = data.user.rows.map((agent) => {
            return { id: agent?.id, userName: agent?.userName };
          });
          setAllAgents(supportAgents);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
  }, []);

  useEffect(() => {
    let agent_value=null;
    if (user?.agentId) {
      allAgents?.forEach((agent) => {
        if (agent.id == user?.agentId) {
          agent_value=agent
        }
      });
    }
    setAgent(agent_value);
  }, [user, allAgents]);
  const reassign_user = useRef(null);
  const [showSave, setShowSave] = useState(false);
  const SaveModel = () => {
    return (
      <CustomSaveModel
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };
  const alertText = () => {
    let agent =
      reassign_user?.current?.userName.charAt(0).toUpperCase() +
      reassign_user?.current?.userName.slice(1);
    return (
      <div>
        {CONSTANTS.TICKET_REASSIGN_CONFIRMATION.replace("{{value}}", agent)}
      </div>
    );
  };
  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false);
          }}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={() => {
            handleChangeAgent(reassign_user.current);
            setShowSave(false);
          }}
        />
      </div>
    );
  };
  const onHide = () => {
    setShowSave(false);
  };
  return (
    <div  className=" w-full flex justify-content-end " >
       <Toast ref={toast} />
      {chatId && (
        <div className="">
          <SaveModel />
          <Text type={"sub-heading"}>
            Assign to:&nbsp;&nbsp;
            {accesskey[modulesName.SALES]?.includes(permissions.UPDATE)?<Dropdown
              value={currAgent}
              onChange={(e) => {
                reassign_user.current = e.value;
                setShowSave(true);
              }}
              options={allAgents}
              optionLabel="userName"
              placeholder="Select an agent"
              className="  "
            />:currAgent?.userName?<span>{currAgent?.userName}</span>:<span> - </span>}
          </Text>
        </div>
      )}
    </div>
  );
};
export default TicketReasign;
