import { Image } from "primereact/image";
import { Text } from "../Text";
import styles from "./stylesheets/startTag.module.css";
import { Dialog } from "primereact/dialog";
import PatternQr from "../../view/Bots/BotList/components/PatternQr";
import { useState } from "react";

export const StartTag = ({ startNodeData }) => {
  const isPattern = startNodeData?.type === "pattern";
  const [qrModelVisible, setQrVisible] = useState(false);
  const handleQrClick = () => {
    setQrVisible(true);
  };
  const closeQrModel = () => {
    setQrVisible(false);
  };
  return (
    <div className="flex">
      {qrModelVisible && (
        <Dialog
          header={<Text type={"heading"}>Pattern Qr Code</Text>}
          visible={qrModelVisible}
          style={{ width: "30vw" }}
          onHide={closeQrModel}
        >
          <PatternQr
            pattern={startNodeData?.label || ""}
            showQr={true}
            showDowload={true}
          />
        </Dialog>
      )}
      <div
        className={`px-3 py-2 mb-2 flex align-items-center ${styles.style_tag}`}
      >
        <Image src={"/svgIcons/skalebot-icon.svg"} className="mr-2" />
        <Text type={"heading"}>Start</Text>
        {isPattern && (
          <i
            className="pi pi-qrcode ml-2 cursor-pointer"
            onClick={handleQrClick}
          />
        )}
      </div>
    </div>
  );
};
