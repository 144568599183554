import { useRef } from "react";
import { Toast } from "primereact/toast";
import { Template } from "./Template";

function RunCampaigns() {
  const toast = useRef(null);
  return (
    <div>
      <div>
        <Toast ref={toast} />
        <Template toast={toast} />
      </div>
    </div>
  );
}

export default RunCampaigns;
