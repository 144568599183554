import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Loader from "../../components/Loader";
import { Text } from "../../components/Text";
import { CustomButton } from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { getDate } from "../../utils/datemaker";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import CustomPaginator from "../../components/CustomPaginator";
import "./style.css";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import DateRangePicker from "../../components/common/DateRangePicker";
import DebounceSearch from "../../components/common/DebounceSearch";
import { useNavigate } from "react-router-dom";
import {
  getTickets,
  changePage,
  changelimit,
  resetTicketPagination,
} from "../../reducers/ticketTabelSlice";
import { emptyContent } from "../../utils/tableUtils";
import { changeUser } from "../../reducers/enqChatSlice";
import { ReactComponent as ChatIcon } from "../../assets/chat.svg";
import {
  formatMobNumber,
  maskMobNumber,
  removeCountryCode,
} from "../../utils/commonUtils";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import TrailDashboard from "../TrailDashboard";
import {getAllUsers} from '../../reducers/accountSlice'
const Tickets = () => {
  const [inputValue, setInputValue] = useState("");
  const [dateRange, setDateRange] = useState({ fromDate: "", toDate: "" });
  const toast = useRef(null);
  const { userSub, accesskey } = useSelector((state) => state.authenticate);
  const { allUsers } = useSelector((state) => state.account);

  const dispatch = useDispatch();
  const { ticketData, page, limit, loading, totalTicketCount } = useSelector(
    (state) => state.ticketTable
  );

  const handleDateRangeFrom = (newDate) => {
    setDateRange((prev) => {
      return { ...prev, fromDate: newDate };
    });
  };
  const handleDateRangeTo = (newDate) => {
    setDateRange((prev) => {
      return { ...prev, toDate: newDate };
    });
  };

  const modalLoad = () => {
    return <Loader visible={loading} />;
  };

  const navigate = useNavigate();

  ///filter--------------------------------
  const [filtersData, setFiltersData] = useState({
    name: null,
    ticketStatus: null,
    agentName:null,
  });

  const [filters1, setFilters1] = useState({
    name: { value: null },
    ticketStatus: { value: null },
    agentName:{value:null}
  });

  let status = ["New", "In Progress", "Follow Up", "Closed"];

  const filterTemplate = (options, filterOptions ) => {
    return (
      <Dropdown
        value={options.value}
        options={filterOptions}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        itemTemplate={filterItemTemplate}
        placeholder="Select"
        className="p-column-filter"
        showClear
      />
    );
  };

  const filterItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };

  const onClearStatusFilter = () => {
    filters1.ticketStatus.value = null;
    filtersData.ticketStatus = null;
    onApplyAllFilter();
  };

 const onClearAgentFilter = () =>{
    filters1.agentName.value = null;
    filtersData.agentName = null;
    onApplyAllFilter();
  }

  const onClickFilter = (e) => {
    switch (e.field) {
      case "name":
        filters1.name.value = e.constraints.constraints[0].value;
        filtersData.name = e.constraints.constraints[0].value;
        break;
      case "ticketStatus":
        filters1.ticketStatus.value = e.constraints.value;
        filtersData.ticketStatus = e.constraints.value;
        break;
      case 'agentName':
        filters1.agentName.value = e.constraints.value;
        filtersData.agentName = e.constraints.value; 
        break;
      default:
        break;
    }

    onApplyAllFilter();
  };

  const managePaginationData = () => {
    let filterData = [];
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    var { fromDate, toDate } = dateRange;
    if (!toDate) toDate = fromDate;
    for (var key in filtersData) {
      if (filtersData[key]) {
        filterData.push({
          key: key,
          value: filtersData[key],
        });
      }
    }

    let paginationData = {
      page: page,
      limit: limit,
      startDate: fromDate
        ? new Date(fromDate - tzoffset).toISOString().substring(0, 10)
        : fromDate,
      endDate: toDate
        ? new Date(toDate - tzoffset).toISOString().substring(0, 10)
        : toDate,
      filterData,
      globalFilterValue: inputValue,
    };
    return paginationData;
  };

  const onApplyAllFilter = () => {
    let paginationData = managePaginationData();
    dispatch(getTickets(paginationData));
  };

  useEffect(() => {
    const paginationData = managePaginationData();

    const { fromDate, toDate } = dateRange;
    if ((fromDate && !toDate) || (!fromDate && toDate)) return;

    dispatch(getTickets(paginationData))
      .unwrap()
      .then((res) => {})
      .catch(() => {
        //show toast from here
        toast.current.show({
          severity: "error",
          detail: "Something went wrong!",
        });
      });
  }, [inputValue, dateRange, page, limit]);

  useEffect(() => {
    dispatch(getAllUsers());
    //reset state on unmount
    return () => {
      dispatch(resetTicketPagination());
    };
  }, []);

  const statusbody = (rowData) => {
    let colormap = {
      New: { background: "#D1E0C1", color: "#000" },
      "In Progress": { background: "#EEE5CD", color: "#000" },
      "Follow Up": { background: "#C9DEE9", color: "#000" },
      Closed: { background: "#F2D5E3", color: "#000" },
    };
    return (
      <div className="w-auto">
        <span
          className="p-1 px-2 font-normal w-auto border-round-sm"
          style={{ ...colormap[rowData?.ticketStatus] }}
        >
          {rowData?.ticketStatus}
        </span>
      </div>
    );
  };
  const handleTicketSelect = (data) => {
    dispatch(changeUser({ ...data, mob: data.customerPhone }));
    let mob = removeCountryCode(data.customerPhone);
    navigate(`/chat/${mob}`, { data });
    return;
  };
  return (
    <div className="w-full m-auto">
      <Toast ref={toast} />
      <div className="m-3">
        {!userSub.isUserSub ? (
          <TrailDashboard/>
        ) : (
          <div className="">
            <TableActionsContainer>
              <div className="flex align-items-center justify-content-between flex-wrap gap-3">
                <Text type="heading">Tickets List</Text>
                <div className="flex gap-2 flex-wrap ">
                  <DateRangePicker
                    fromDate={dateRange.fromDate}
                    toDate={dateRange.toDate}
                    handleFromDate={handleDateRangeFrom}
                    handleToDate={handleDateRangeTo}
                  />
                  <DebounceSearch
                    placeholder={"Search Tickets"}
                    inputValue={inputValue}
                    handleInputChange={(value) => setInputValue(value)}
                  />
                </div>
              </div>
            </TableActionsContainer>
            <div className="card lg:my-2">
              {modalLoad()}
              <div
                className={`w-full flex flex-column dashboard-tablecontent border-round-sm `}
              >
                <DataTable
                  selectionMode={
                    accesskey[modulesName.SALES]?.includes(permissions.READ) &&
                    "single"
                  }
                  value={ticketData}
                  resizableColumns
                  columnResizeMode="expand"
                  className="skalebot-table "
                  style={{ borderTop: "0px" }}
                  filters={filters1}
                  filterDisplay="menu"
                  onSelectionChange={(e) => handleTicketSelect(e.value)}
                  globalFilterFields={["name", "status"]}
                >
                  <Column
                    header={"SI. No"}
                    bodyStyle={{ textTransform: "capitalize" }}
                    className="text-sm"
                    style={{ width: "3rem" }}
                    body={(_, i) => {
                      return (
                        <div className="font-normal">
                          {page * limit + i.rowIndex + 1}
                        </div>
                      );
                    }}
                  />
                  <Column
                    field="createdAt"
                    header="Date & Time"
                    style={{ width: "100px" }}
                    className="text-sm font-normal"
                    body={(rowData) =>
                      rowData.createdAt && getDate(rowData.createdAt)
                    }
                    bodyStyle={{
                      textTransform: "uppercase",
                      lineHeitght: "1rem",
                    }}
                  />

                  <Column
                    field="ticketCode"
                    header={"Ticket Id"}
                    style={{ width: "10ch" }}
                    bodyStyle={{ textTransform: "capitalize" }}
                    className="text-sm"
                    body={(rowData) => {
                      return (
                        <div className="font-normal">
                          #{rowData?.ticketCode}
                        </div>
                      );
                    }}
                  />
                  <Column
                    field="customerName"
                    header={"Customer name"}
                    bodyStyle={{ textTransform: "capitalize" }}
                    body={(rowData) => emptyContent(rowData?.customerName)}
                  />
                  <Column
                    field="mobnumber"
                    header={"Phone Number"}
                    style={{ width: "20ch" }}
                    bodyStyle={{ textTransform: "capitalize" }}
                    body={(rowData) =>
                      accesskey[modulesName.CUSTOMER]?.includes(
                        permissions.READ
                      )
                        ? formatMobNumber(rowData?.customerPhone)
                        : maskMobNumber(rowData?.customerPhone)
                    }
                  />
                  <Column
                    field="ticketStatus"
                    header={"Status"}
                    bodyStyle={{ textTransform: "capitalize" }}
                    className="text-sm"
                    filter
                    style={{ width: "20ch" }}
                    body={statusbody}
                    showFilterMatchModes={false}
                    filterElement={(options) => filterTemplate(options, status)}
                    onFilterApplyClick={(e) => onClickFilter(e)}
                    onFilterClear={onClearStatusFilter}
                  />
                  <Column
                    field="agentName"
                    header={"Assignee"}
                    filter
                    style={{ width: "20ch" }}
                    bodyStyle={{ textTransform: "capitalize" }}
                    body={(rowData) => emptyContent(rowData?.agentName)}
                    showFilterMatchModes={false}
                    filterElement={(options) => filterTemplate(options, allUsers?.filter(agent => agent.role == 'support').map(agent => agent.name))}
                    onFilterApplyClick={(e) => onClickFilter(e)}
                    onFilterClear={onClearAgentFilter}
                  />
                  {accesskey[modulesName.SALES]?.includes(permissions.READ) && (
                    <Column
                      field=""
                      header={""}
                      bodyStyle={{ textTransform: "capitalize" }}
                      style={{ width: "5ch" }}
                      body={(e) => (
                        <CustomButton
                          varient="icon-button"
                          icon={<ChatIcon />}
                        />
                      )}
                    />
                  )}
                </DataTable>
              </div>
            </div>
            <div className="mt-3">
              <TableActionsContainer>
                <div className="flex  justify-content-end">
                  <CustomPaginator
                    page={page}
                    limit={limit}
                    changelimit={changelimit}
                    totalRecords={totalTicketCount}
                    changePage={changePage}
                  />
                </div>
              </TableActionsContainer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tickets;
