import { Dialog } from "primereact/dialog";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Text } from "../../../components/Text";
import TableActionsContainer from "../../../components/CustomTable/TableActionsContainer";
import { CustomButton } from "../../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { postBuisenessinfo } from "../../../reducers/accountSlice";
import QRCodeStyling from "qr-code-styling";
import QrIcon from "../../../assets/qrcenter.png";
import { useEffect, useRef } from "react";
import axiosInstance from "../../../api/axios.instance";
import axios from "axios";
import { useLayout } from "../../../context/LayoutContext";
function NewUPIForm({ visiable = false, handleHide = () => {} }) {
  const defaultValues = {
    upi: ""
  };
  const { user } = useSelector((state) => state.authenticate);
  const { businessdata } = useSelector((state) => state.account);
  const ref = useRef(null);
  const qrCode = new QRCodeStyling({
    width: 180,
    height: 180,
    image: QrIcon,
    dotsOptions: {
      color: "#000",
      type: "rounded"
    },
    backgroundOptions: {
      color: "#f8f9fe"
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff"
      }
    },
    qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "L" },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.5, margin: 0 },
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#6a1a4c",
        color2: "#6a1a4c",
        rotation: "0"
      }
    },
    cornersSquareOptions: { type: "square", color: "#000000" },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0"
      }
    },
    cornersDotOptions: { type: "", color: "#000000" },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0"
      }
    }
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    watch
  } = useForm({ defaultValues: defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  const { setLoading, toastMessage } = useLayout();
  useEffect(() => {
    setValue("upi", businessdata?.upi || "");

    return () => {
      setLoading(false);
    };
  }, []);

  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    qrCode.append(ref.current);
    setLoading(true);
    qrCode.update({
      data: `upi://pay?pa=${data?.upi}&cu=INR`
    });
    let imagefile = await qrCode.getRawData();
    const uploadAt = await axiosInstance.post("/image", {
      imageType: `upi`,
      fileName: `${data?.upi}.png`
    });
    if (uploadAt) {
      let upload = axios.put(uploadAt.data.src, imagefile, {
        headers: { "Content-Type": "image/png" }
      });
    }
    dispatch(
      postBuisenessinfo({
        id: user?.id,
        data: { upi: data?.upi, upiQR: uploadAt.data.url }
      })
    )
      .unwrap()
      .then((e) => {
        toastMessage({ detail: "Upi Succesfully got changed" });
      });
    setLoading(false);
    handleHide();
    // alert("data", data);
  };
  return (
    <Dialog
      visible={visiable}
      onHide={handleHide}
      className="w-11 md:w-6 lg:w-4"
      header={<Text>Edit UPI</Text>}
    >
      <TableActionsContainer>
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid w-full">
          <div className="field">
            <label
              htmlFor="upi"
              className={classNames({ "p-error": errors.name })}
            >
              <Text>Enter New UPI*</Text>
            </label>
            <Controller
              name="upi"
              control={control}
              // rules={{
              //   required: "Please enter your UPI ID.",
              //   //   validate: (value) =>
              //   //     isValidPhoneNumber("+" + value.toString()) ||
              //   //     `Please enter a valid phone number.`,
              //   //   pattern: {
              //   //     value: /^[0-9]*$/,
              //   //     message: "Only Numbers are allowed",
              //   //   },
              // }}
              // rules={{
              //   required: "Please enter your UPI ID.",
              //   pattern: { value: /@/, message: "UPI ID should contain @" }
              // }}
              rules={{ required: "UPI ID is required." }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  className={classNames({
                    "p-invalid": fieldState.invalid
                  })}
                  placeholder="UPI Id"
                  {...field}
                />
              )}
            />
            {getFormErrorMessage("upi")}
          </div>
          <div className="field">
            <div className="flex gap-2">
              <CustomButton
                type="button"
                label={"Cancel"}
                varient="icon-button"
                onClick={handleHide}
              />
              <CustomButton label={"Submit"} type="submit" />
            </div>
          </div>
        </form>
      </TableActionsContainer>
    </Dialog>
  );
}
export default NewUPIForm;
