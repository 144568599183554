import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import styles from "./style.module.css";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { updateNodePayload } from "../../../reducers/canvas/canvasSlice";
import { getDataFromId } from "../../../utils/nodeUtils";
import { CustomButton } from "../../CustomButton";

const Params = ({ url, setUrl }) => {
  const dispatch = useDispatch();
  const { selectedNode, nodes } = useSelector((state) => state.canvas);
  const [params, setParams] = useState([
    {
      id: uuidv4(),
      key: "",
      value: "",
    },
  ]);

  const appendParamInUrl = (updatedParams) => {
    let paramToAppend = "";
    for (let i = 0; i < updatedParams.length; i++) {
      paramToAppend += `${updatedParams[i].key}=${updatedParams[i].value}&`;
    }
    return paramToAppend;
  };

  const handleUrlParam = (updatedParams) => {
    let urlArr = url.split("?");
    let urlWithParam =
      urlArr[0] + "?" + appendParamInUrl(updatedParams).slice(0, -1);
    setUrl(urlWithParam);
    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { url: urlWithParam },
      })
    );
  };
  const handleParamChange = (id, e, type) => {
    const updatedParams = params.map((param) =>
      param.id === id ? { ...param, [type]: e.target.value } : param
    );
    setParams(updatedParams);
    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { params: updatedParams },
      })
    );
    handleUrlParam(updatedParams);
  };

  const handleAddParam = () => {
    let id = uuidv4();
    let value = "";
    let key = "";
    const newParam = {
      id,
      key,
      value,
    };
    const updatedParams = [...params, newParam];
    setParams(updatedParams);
    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { params: updatedParams },
      })
    );
  };

  const handleParamDelete = (id) => {
    let updatedParams = params.filter((param) => param.id !== id);
    setParams(updatedParams);
    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { params: updatedParams },
      })
    );
    handleUrlParam(updatedParams);
  };

  useEffect(() => {
    if (selectedNode) {
      const selectedNodeData = getDataFromId(nodes, selectedNode?.id);
      setParams(selectedNodeData.params);
    }
  }, [selectedNode]);

  return (
    <div>
      <div className="mt-3">
        <div className="w-12 flex  mt-3 gap-2  ">
          <div className={`${styles.params} `}>Key</div>
          <div className={`${styles.params} `}>Value</div>
        </div>
        {params &&
          params.map((param, idx) => (
            <div key={param.id}>
              <div className="flex align-items-center gap-2 mt-1">
                <InputText
                  value={param.key}
                  onChange={(e) => {
                    handleParamChange(param.id, e, "key");
                  }}
                  placeholder="Key"
                  className={`${styles.customInput}`}
                />

                <InputText
                  value={param.value}
                  key={`${param.id}-value`}
                  onChange={(e) => {
                    handleParamChange(param.id, e, "value");
                  }}
                  placeholder="Value"
                  className={`${styles.customInput}`}
                />

                <i
                  className="pi pi-trash ml-1"
                  onClick={() => handleParamDelete(param.id)}
                ></i>
              </div>
              {(!param.value?.trim() || !param.key?.trim()) && (
                <small className="p-error">
                  Please enter a valid key, value pair.
                </small>
              )}
            </div>
          ))}
        <div className="mt-2 ">
          <CustomButton
            varient="outline"
            label={"Add Param"}
            icon={"pi pi-plus"}
            onClick={handleAddParam}
          />
        </div>
      </div>
    </div>
  );
};

export default Params;
