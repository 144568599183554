import { Handle, Position } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedNode,
  closeConfigScreen,
  openConfigScreen,
  undoNodeData,
} from "../../../reducers/canvas/canvasSlice";
import styles from "./style.module.css";
import { handleInputType } from "../../../reducers/multiInputSlice";
import { Image } from "primereact/image";
import { getTheNode } from "../../../utils/canvasUtils";
import { Tag } from "primereact/tag";
import CustomHandle from "../CustomHandler";
import { CustomButton } from "../../CustomButton";
import { Text } from "../../Text";
import { CANVAS_NODE_TITLES } from "../node_constant";
import { NodeHeader } from "../NodeHeader";
import { StartTag } from "../StartTag";
import EditButton from "../Editbutton";
import { isQuickReplyNodeValid } from "../../../view/Bots/BotBuilder/node-constant";

function QuickReplyNode(props) {
  let { label, options, image, video, type } = props.data;
  const dispatch = useDispatch();
  const { nodes, selectedNode, rootStepId, configScreenOpen } = useSelector(
    (state) => state.canvas
  );

  const handleNodeSelection = () => {
    if (selectedNode?.id !== props.id) {
      if (configScreenOpen) {
        dispatch(undoNodeData());
        dispatch(closeConfigScreen());
      }
      dispatch(changeSelectedNode(getTheNode(nodes, props?.id)));
    }
  };
  const handleEditClick = (e) => {
    e.stopPropagation();
    dispatch(undoNodeData());
    dispatch(handleInputType("quick_reply"));
    dispatch(openConfigScreen(getTheNode(nodes, props?.id)));
  };
  return (
    <>
      {rootStepId == props?.id && <StartTag />}

      <div
        onClick={handleNodeSelection}
        className={`${styles.quickReplyNode} ${
          selectedNode?.id == props?.id
            ? styles["selected-node"]
            : !isQuickReplyNodeValid(props.data)
            ? styles["invalid-node"]
            : ""
        }`}
      >
        {/* <CustomHandle
          type="target"
          position={Position.Left}
          isConnectable={1}
        /> */}
        <Handle type="target" position={Position.Left} />

        <div className={`w-full p-2 ${styles.nodeData}`}>
          <NodeHeader nodeType={props.type} />
          <EditButton onClick={handleEditClick} />

          <div className={`mt-2 ${styles["text-message"]}`}>
            {image && <Image src={image} width="100%" alt="node_image" />}

            {video && (
              <div className={styles.videoContainer}>
                <video className={styles.videoElement} width="100">
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
            <div
              style={{
                whiteSpace: "pre-line",
              }}
              className="my-2"
            >
              <Text type={"small-text"}>{label}</Text>
            </div>
            <div className="py-1 w-full">
              <div className="flex gap-2 flex-column mb-2">
                {options?.map((btn, i) => (
                  <button
                    style={{
                      flexGrow: "1",
                      padding: "4px",
                      fontSize: "0.8rem",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #ECECEC",
                      borderRadius: "4px",
                    }}
                    key={btn.id}
                    onClick={() => {}}
                  >
                    {`${btn.title}` || <i>label text ...</i>}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* <CustomHandle
          type="source"
          position={Position.Right}
          isConnectable={3}
        /> */}

        <Handle type="source" position={Position.Right} />
      </div>
    </>
  );
}

export default QuickReplyNode;
