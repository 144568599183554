import axiosInstance from "./axios.instance";

// api calls for OverAllCount
const API_GET_OVER_ALL_COUNT = async (startDate,endDate) => {
  try {
  if (startDate || endDate) {
    if(!endDate){
      endDate=startDate
    }
    const resp = await axiosInstance.get(
      `/analytics/overall?startDate=${startDate}&endDate=${endDate}`
    );
    return resp.data;
  }else{
    const resp = await axiosInstance.get(
      '/analytics/overall'
    );
    return resp.data;
  }
   
  } catch (err) {
    throw err;
  }
};

const API_GET_COUNT_ORDER = async (startDate,endDate) => {
  try {
    if (startDate || endDate) {
      if(!endDate){
        endDate=startDate
      }
    const resp = await axiosInstance.get(
      `/dashboard/order?startDate=${startDate}&endDate=${endDate}`
    );
    return resp.data;
  }else{
    const resp = await axiosInstance.get(
      '/dashboard/order'
    );
    return resp.data;
  }
   
  } catch (err) {
    throw err;
  }
};

const API_GET_COUNT_CAMPAIGN = async (startDate,endDate) => {
  try {
    if (startDate || endDate) {
    if(!endDate){
      endDate=startDate
    }
    const resp = await axiosInstance.get(
      `/dashboard/campaign?startDate=${startDate}&endDate=${endDate}`
    );
    return resp.data;
  }else{
    const resp = await axiosInstance.get(
      '/dashboard/campaign'
    );
    return resp.data;
  }
   
  } catch (err) {
    throw err;
  }
};

const API_GET_CONVERSATION = async (startDate,endDate) => {
  try {
    if (startDate || endDate) {
    if(!endDate){
      endDate=startDate
    }
    const resp = await axiosInstance.get(
      `/dashboard/conversation?startDate=${startDate}&endDate=${endDate}`
    );
    return resp.data;
  }else{
    const resp = await axiosInstance.get(
      '/dashboard/conversation'
    );
    return resp.data;
  }
   
  } catch (err) {
    throw err;
  }
};



const API_GET_COUNT_AGENT = async (startDate,endDate) => {
  try {
    if (startDate || endDate) {
    if(!endDate){
      endDate=startDate
    }
    const resp = await axiosInstance.get(
      `/dashboard/agent?startDate=${startDate}&endDate=${endDate}`
    );
    return resp.data;
  }else{
    const resp = await axiosInstance.get(
      '/dashboard/agent'
    );
    return resp.data;
  }
   
  } catch (err) {
    throw err;
  }
};

const API_GET_COUNT_AGENT_TOP = async (startDate,endDate) => {
  try {
    if (startDate || endDate) {
    if(!endDate){
      endDate=startDate
    }
    const resp = await axiosInstance.get(
      `/dashboard/agent/top?page=${0}&limit=${5}&startDate=${startDate}&endDate=${endDate}`
    );
    return resp.data;
  }else{
    const resp = await axiosInstance.get(
      `/dashboard/agent/top?page=${0}&limit=${5}`
    );
    return resp.data;
  }
   
  } catch (err) {
    throw err;
  }
};

const API_GET_COUNT_AGENT_TICKETS = async (pageNo,limit,startDate,endDate,filter,globalFilterValue) => {
  try {
    var resp;
    if (filter || globalFilterValue || (startDate && endDate)) {

      let allFilter=''
      if (filter!== 'all') {
          allFilter += `&isAgentActive=${filter}`
      }
      if (globalFilterValue) {
         allFilter += `&global=${globalFilterValue}`
      }
        if (startDate || endDate) {
    if(!endDate){
      endDate=startDate
    }     
          allFilter += `&startDate=${startDate}&endDate=${endDate}`
       }
      resp = await axiosInstance.get(
        `/dashboard/agent/tickets?page=${pageNo}&limit=${limit}${allFilter}`
         )
      return resp.data;
    }else{
       resp = await axiosInstance.get(
        `/dashboard/agent/tickets?page=${pageNo}&limit=${limit}`
      );  
      return resp.data;
    }   
  } catch (err) {
    throw err;
  }
};

const API_GET_COUNT_TICKET = async (startDate,endDate) => {
  try {
    if (startDate || endDate) {
    if(!endDate){
      endDate=startDate
    }
    const resp = await axiosInstance.get(
      `/dashboard/ticket?startDate=${startDate}&endDate=${endDate}`
    );
    return resp.data;
  }else{
    const resp = await axiosInstance.get(
      '/dashboard/ticket'
    );
    return resp.data;
  }
   
  } catch (err) {
    throw err;
  }
};

const API_GET_COUNT_TICKET_RESPONSE = async (startDate,endDate) => {
  try {
    if (startDate || endDate) {
    if(!endDate){
      endDate=startDate
    }
    const resp = await axiosInstance.get(
      `/dashboard/responsetime?startDate=${startDate}&endDate=${endDate}`
    );
    return resp.data;
  }else{
    const resp = await axiosInstance.get(
      '/dashboard/responsetime'
    );
    return resp.data;
  }
   
  } catch (err) {
    throw err;
  }
};
const API_GET_COUNT_ENQUIRY = async (startDate,endDate) => {
  try {
    if (startDate || endDate) {
    if(!endDate){
      endDate=startDate
    }
    const resp = await axiosInstance.get(
      `/dashboard/enquiry?startDate=${startDate}&endDate=${endDate}`
    );
    return resp.data;
  }else{
    const resp = await axiosInstance.get(
      '/dashboard/enquiry'
    );
    return resp.data;
  }
   
  } catch (err) {
    throw err;
  }
};

const API_GET_TICKETS_OVERALL = async (pageNo,limit,startDate,endDate,filter,globalFilterValue) => {
  try {
    var resp;
    if (filter || globalFilterValue || startDate || endDate) {
      let allFilter=''
      if (filter) {
        filter.forEach((element) => {
          if(element.value==="all"){
            allFilter +=``;
          }else{
            allFilter += `&${element.key}=${element.value}`;
          }
        });
      }
      if (globalFilterValue) {
         allFilter += `&global=${globalFilterValue}`
      }
      if (startDate || endDate) {   
        allFilter += `&startDate=${startDate}&endDate=${endDate}`
      }
      resp = await axiosInstance.get(
        `/dashboard/ticket/overall?&page=${pageNo}&limit=${limit}${allFilter}`
        )
      return resp.data;
    }else{
       resp = await axiosInstance.get(
        `/dashboard/ticket/overall?&page=${pageNo}&limit=${limit}`
      );  
      return resp.data;
    }   
  } catch (err) {
    throw err;
  }
};

const API_GET_ENQUIRIES_OVERALL = async (pageNo,limit,startDate,endDate,filter,globalFilterValue) => {
  try {
    var resp;
    if (filter || globalFilterValue || (startDate || endDate)) {
      let allFilter=''
      if (filter) {
        filter.forEach((element) => {
          if(element.value==="all"){
            allFilter +=``;
          }else{
            allFilter += `&${element.key}=${element.value}`;
          }
        });
      }
      if (globalFilterValue) {
         allFilter += `&global=${globalFilterValue}`
      }
    if (startDate || endDate) {
        if(!endDate){
          endDate=startDate
        }     
          allFilter += `&startDate=${startDate}&endDate=${endDate}`
       }
      resp = await axiosInstance.get(
        `/dashboard/enquiry/overall?&page=${pageNo}&limit=${limit}${allFilter}`
         )
      return resp.data;
    }else{
       resp = await axiosInstance.get(
        `/dashboard/enquiry/overall?&page=${pageNo}&limit=${limit}`
      );  
      return resp.data;
    }   
  } catch (err) {
    throw err;
  }
};

export { 
         API_GET_OVER_ALL_COUNT,
         API_GET_COUNT_ORDER,
         API_GET_COUNT_CAMPAIGN,
         API_GET_CONVERSATION,
         API_GET_COUNT_AGENT,
         API_GET_COUNT_AGENT_TOP,
         API_GET_COUNT_AGENT_TICKETS,
         API_GET_COUNT_TICKET,
         API_GET_COUNT_ENQUIRY,
         API_GET_COUNT_TICKET_RESPONSE,
         API_GET_TICKETS_OVERALL,
         API_GET_ENQUIRIES_OVERALL,
      };