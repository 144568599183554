import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { Text } from "../../components/Text";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton } from "../../components/CustomButton";
import { RadioButton } from "primereact/radiobutton";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import CONSTANTS from "../../config/Constants";
import {
  addNewUser,
  changeMode,
  changeUserToast,
  getAllUsers,
  getUserById,
  resetAccountUser,
  updateUser,
} from "../../reducers/accountSlice";
import Loader from "../../components/Loader";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { ConfirmationAlert } from "../../components/Alert/ConfirmationAlert";
import styles from "./style.module.css";
import { useUtility } from "../../context/UtilityContext";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";

const User = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toast = useRef(null);
  const { id } = useParams();
  const { selectedUser, superAdminUser, mode, loading } = useSelector(
    (state) => state.account
  );
  const { user,accesskey } = useSelector((state) => state.authenticate);

  const [role, setRole] = useState("user");

  const [activeUser, setActiveUser] = useState("active");
  const [displayConfirmation, setDisplayConfirmation] = useState(false);

  const companyId = user.companyId;

  const home = { label: "Account settings", url: "/account" };
  const itemslist = [
    { label: !isNaN(parseInt(id)) ? `User #${id}` : `New ${role} user` },
  ];
  const { navigation, setAlert } = useUtility();

  const defaultValues = {
    name: "",
    userName: "",
    phone: "",
    email: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const modalLoad = () => {
    return <Loader visible={loading} />;
  };

  const userRole = {
    support: "Support Agent",
    campaign: "Campaign User",
    admin: "Admin User",
  };

  const roleDesc = () => {
    switch (role) {
      case "admin":
        return (
          <Text cust="my-3" type={"sub-heading"}>
            Choosing "ADMIN" would allow user with full account access with
            permissions to make changes
          </Text>
        );
      case "support":
        return (
          <Text cust="my-3" type={"sub-heading"}>
            Choosing “Support Agent” would allow user with access to attend the
            user queries only
          </Text>
        );
      case "campaign":
        return (
          <Text cust="my-3" type={"sub-heading"}>
            Choosing "Campaign User” would allow user with access to create and
            manage campaigns only
          </Text>
        );
      default:
        return <></>;
    }
  };

  const alertText = () => {
    return <div>{CONSTANTS.USER_CREATE_CONFIRMATION}</div>;
  };
  const onHide = () => {
    setDisplayConfirmation(false);
  };

  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={onHide}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    );
  };

  const confirmationDialog = () => {
    return (
      <ConfirmationAlert
        displayConfirmation={displayConfirmation}
        setDisplayConfirmation={setDisplayConfirmation}
        alertText={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };

  useEffect(() => {
    dispatch(getAllUsers())
      .unwrap()
      .catch((err) => {
        toast.current.show({ severity: "error", detail: err.message });
      });
  }, []);

  useEffect(() => {
    if (!isNaN(parseInt(id))) {
      dispatch(getUserById(id))
        .unwrap()
        .catch((err) => {
          toast.current.show({ severity: "error", detail: err.message });
        });

      dispatch(changeMode("update"));
    } else {
      dispatch(changeMode("create"));
      switch (id) {
        case "admin":
          setRole("admin");
          break;
        case "campaign_user":
          setRole("campaign");
          break;
        case "support_agent":
          setRole("support");
          break;
        default:
          setRole("user");
      }
    }

    return () => {
      dispatch(resetAccountUser());
      dispatch(changeMode(""));
      setAlert(false);
    };
  }, [id]);

  useEffect(() => {
    if (!isNaN(parseInt(id)) && selectedUser) {
      setValue("name", selectedUser?.name || "");
      setValue("userName", selectedUser.userName);
      setValue("phone", "+" + handlePhoneNo(selectedUser.phone));
      setValue("email", selectedUser.email || "");
      setRole(selectedUser.role);
      setActiveUser(selectedUser.isActive ? "active" : "inactive");
    }
  }, [selectedUser]);

  const handlePhoneNo = (phone) => {
    if (!phone) return;
    var phoneStr = phone.toString();
    if (phoneStr.length === 10) {
      phoneStr = "91" + phoneStr;
    }
    return parseInt(phoneStr);
  };

  const handleTableActionHeader = () => {
    if (!isNaN(parseInt(id))) {
      if (
        selectedUser?.role === "admin" &&
        selectedUser.id === superAdminUser?.id
      ) {
        return "Edit Super Admin User";
      } else {
        return `Edit ${userRole[selectedUser?.role]}`;
      }
    } else {
      return `Add new ${role} user`;
    }
  };

  const onSubmit = (data) => {
    data = {
      ...data,
      phone: parseInt(data.phone.substring(1)),
      companyId,
      role,
    };

    if (isNaN(parseInt(id)) && mode === "create") {
      const userData = { ...data, role };
      dispatch(addNewUser(userData))
        .unwrap()
        .then(() => {
          dispatch(changeUserToast("create"));
          navigate("/account");
        })
        .catch((err) => {
          toast.current.show({ severity: "error", detail: err.message });
        });
    } else if (!isNaN(parseInt(id)) && mode === "update") {
      const isActive = activeUser === "active" ? true : false;
      const userData = { ...data, role, isActive };
      dispatch(updateUser({ id, userData }))
        .unwrap()
        .then(() => {
          dispatch(changeUserToast("update"));
          navigate("/account");
        })
        .catch((err) => {
          toast.current.show({ severity: "error", detail: err.message });
        });
    }
    setDisplayConfirmation(false);
  };

  return (
    <div>
      <CustomBreadcrumb home={home} itemslist={itemslist} />
      <Toast ref={toast} />
      {modalLoad()}
      {confirmationDialog()}
      <div className="mt-3 mx-3">
        <TableActionsContainer>
          <div className="hidden sm:hidden md:flex flex-wrap justify-content-between align-items-center">
            <Text type={"heading"}>{handleTableActionHeader()}</Text>
            <div className="flex gap-2 mt-3 sm:mt-0">
              <CustomButton
                label={"Cancel"}
                varient="outline"
                onClick={() => navigation("/account")}
              />
              <CustomButton
                label={!isNaN(parseInt(id)) ? "Save" : "Create"}
                varient="filled"
                style={{ minWidth: "125px" }}
                onClick={handleSubmit(() => setDisplayConfirmation(true))}
              />
            </div>
          </div>
          <div className="hidden sm:flex md:hidden flex flex-column flex-wrap justify-content-between align-items-start">
            <div className="flex justify-content-start">
              <Text type={"heading"}>{handleTableActionHeader()}</Text>
            </div>
            <div className="flex justify-content-end  w-full ">
              <div className="flex gap-2 mt-3 sm:mt-0">
                <CustomButton
                  label={"Cancel"}
                  varient="outline"
                  onClick={() => navigation("/account")}
                />
                <CustomButton
                  label={!isNaN(parseInt(id)) ? "Save" : "Create"}
                  varient="filled"
                  style={{ minWidth: "125px" }}
                  onClick={handleSubmit(() => setDisplayConfirmation(true))}
                />
              </div>
            </div>
          </div>
        </TableActionsContainer>

        <form
          onChange={() => {
            setAlert(true);
          }}
          className="p-3 p-fluid"
          onSubmit={handleSubmit(() => setDisplayConfirmation(true))}
        >
          {!isNaN(parseInt(id)) &&
            // superAdminUser?.role === user?.role &&
            accesskey[modulesName.USER]?.includes(permissions.DELETE) &&
            superAdminUser?.id !== selectedUser?.id &&(
              <div className="flex flex-wrap mt-3 gap-5 md:gap-7">
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="active"
                    name="active"
                    value="active"
                    onChange={(e) => setActiveUser(e.value)}
                    checked={activeUser === "active"}
                  />
                  <label htmlFor="active" className="ml-2">
                    Active
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="inactive"
                    name="inactive"
                    value="inactive"
                    onChange={(e) => setActiveUser(e.value)}
                    checked={activeUser === "inactive"}
                  />
                  <label htmlFor="inactive" className="ml-2">
                    Inactive
                  </label>
                </div>
              </div>
            )}

          {!isNaN(parseInt(id)) && roleDesc()}
          <div className="flex justify-content-start">
            <div className={`card w-full md:w-6 lg:w-4`}>
              <div className="field">
                <label htmlFor="name">Name</label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      maxLength={50}
                      disabled={activeUser === "active" ? false : true}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      placeholder="Enter name"
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage("name")}
              </div>
              <div className="field">
                <label htmlFor="userName">User Name *</label>
                <Controller
                  name="userName"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[a-zA-Z0-9-]*$/,
                      message:
                        "Only alphanumeric (min 6) characters without white spaces are allowed.",
                    },
                    required: "User name is required.",
                    validate: (value) =>
                      value.length > 5 ||
                      "Username must contain atleast 6 characters",
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      maxLength={50}
                      disabled={!isNaN(parseInt(id)) ? true : false}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      placeholder="Enter username"
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage("userName") || (
                  <small>
                    * Only alphanumeric characters are allowed (min 6
                    characters).
                  </small>
                )}
              </div>
              <div className="field">
                <label htmlFor="phone">Phone *</label>
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: "Phone number is required.",
                    validate: (value) =>
                      isValidPhoneNumber(value.toString()) ||
                      "Please enter a valid phone number. ",
                  }}
                  render={({ field, fieldState }) => (
                    <PhoneInputWithCountry
                      name="phone"
                      control={control}
                      defaultCountry="IN"
                      id={field.name}
                      disabled={!isNaN(parseInt(id)) ? true : false}
                      placeholder="Enter phone number"
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage("phone")}
              </div>
              <div className="field">
                <label htmlFor="email">Email</label>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: false,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Enter a valid e-mail address",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      placeholder="Enter your email"
                      disabled={activeUser === "active" ? false : true}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage("email")}
              </div>
            </div>
          </div>
        </form>
        {isNaN(parseInt(id)) && (
          <Text type={"sub-heading"} cust="mt-5">
            An initial username and password will be shared with the respective
            user via WhatsApp. They will need to change it after logging in.
          </Text>
        )}
      </div>
    </div>
  );
};

export default User;
