import { useSelector } from "react-redux";
import Chart from "chart.js/auto"; // Important for working with react-chartjs-2
import { Doughnut, Pie } from "react-chartjs-2";
import { useState, useEffect } from "react";
import styles from "./style.module.css";
const CampaignPieChart = () => {
  const { stats, selectedCampaignId } = useSelector((state) => state.campaign);
  const [showChart, setShowChart] = useState(false);

  const pieStatusData = {
    labels: ["Sent", "Delivered", "Read", "Replied", "Opt-Out", "Failed"],
    datasets: [
      {
        label: "Conversations",
        data: [
          stats.sent,
          stats.delivered,
          stats.read,
          stats.replied,
          stats["opt-out"],
          stats.failed,
        ],
        backgroundColor: [
          "grey",
          "#FFE66D",
          "#C4E0A5",
          "#018e42",
          "#E61E50",
          "#570000",
        ],
        borderColor: [
          "transparent",
        ],
        borderWidth: 2,
        hoverOffset: 1,
      },
    ],
  };

  const pieOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    if (
      stats.sent ||
      stats.delivered ||
      stats.read ||
      stats.replied ||
      stats["opt-out"] ||
      stats.failed
    ) {
      setShowChart(true);
    } else {
      setShowChart(false);
    }
  }, [stats, selectedCampaignId]);

  return (
    <>
      {showChart ? (
        <div  className={`w-full  flex flex-column `}>
            <div className="flex w-full  justify-content-center mb-2 md:mb-5">
                Campaign Statistics
            </div>
        <div className="w-12 flex justify-content-center flex-wrap flex-column md:flex-row">
        <div className="w-12 md:w-8 lg:w-10 xl:w-10 flex flex-wrap md:flex-nowrap  justify-content-center md:justify-content-between gap-2 ">
          <div className="flex-grow-1 flex justify-content-center">
            <div className="" style={{ maxWidth: "200px" }}>
              <Doughnut data={pieStatusData} options={pieOptions} />
            </div>
          </div>
          <div className="flex-grow-1 flex justify-content-center">
            <div className={`px-4 py-2 ${styles["pie-data-container"]}`}>
              {pieStatusData.labels.map((label, index) => (
                <div className="pb-2 flex align-items-center gap-2" key={index}>
                  <div
                    style={{
                      width: "20px",
                      height: "15px",
                      borderRadius: "50%",
                      backgroundColor:
                        pieStatusData.datasets[0].backgroundColor[index],
                    }}
                  ></div>
                  <div className="w-full flex gap-2 justify-content-between">
                    <div className="">{label}</div>
                    <span>:&nbsp;{pieStatusData.datasets[0].data[index]}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        </div>
        </div>
      ) : (
        <div className="py-3 flex justify-content-center">
          {/* Campaign stats not available. */}
        </div>
      )}
    </>
  );
};

export default CampaignPieChart;
