import React, { useEffect } from "react";
import "./toggleCard.css";

const ToggleCard = ({
  isVisible,
  setIsVisible,
  children,
  className = "",
  cardRef,
  preventHideSelector = "",
  header,
}) => {
  const handleClickOutside = (event) => {
    if (
      cardRef.current &&
      !cardRef.current.contains(event.target) &&
      (preventHideSelector === "" ||
        !preventHideSelector.split(",").some((selector) => {
          return event.target.closest(selector);
        }))
    ) {
      // setIsVisible(false) // to make it false
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, preventHideSelector]);

  return (
    <div>
      {isVisible && (
        <div ref={cardRef} className={`floating-card ${className}`}>
          <div className="floating-card-header">
            <span>{header}</span>
            <span onClick={() => setIsVisible(false)} className="close-button">
              X
            </span>
          </div>
          <div className="card-containt">{children}</div>
        </div>
      )}
    </div>
  );
};

export default ToggleCard;
