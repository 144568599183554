import { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDebounce } from "primereact/hooks";
import { InputText } from "primereact/inputtext";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { useSelector } from "react-redux";
import { API_GET_CUSTOMERS } from "../../api/customer.services";
import axiosInstance from "../../api/axios.instance";
import { Dropdown } from "primereact/dropdown";

const defaultOption = { id: "all", groupName: "All Customers" };

export const SelectableCustomers = ({
  selectedCustomer,
  setSelectedCustomer,
  customersFrom = "global",
  campCustomerStatus = "all",
  selectedGroupId = "all",
  setSelectedGroupId,
  saved = false,
  limit = 100,
}) => {
  const [searchQuery, debouncedValue, setSearchQuery] = useDebounce("", 1000);
  const [customers, setCustomers] = useState([]);
  const [groups, setGroups] = useState([]);
  // const [selectedGroupId, setSelectedGroupId] = useState("all");
  const [currPage, setCurrpage] = useState(0);
  const [prevpage, setPrevPage] = useState(-1);
  const [wasLastList, setWasLastList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const listInnerRef = useRef();
  const { selectedCampaignId } = useSelector((state) => state.campaign);

  const handleSearch = (e) => {
    setWasLastList(false);
    setCurrpage(0);
    setPrevPage(-1);
    setSearchQuery(e.target.value);
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        Math.floor(scrollTop + clientHeight) === scrollHeight ||
        Math.ceil(scrollTop + clientHeight) === scrollHeight
      ) {
        setCurrpage(currPage + 1);
      }
    }
  };

  const phoneBodyTemplate = (rowdata) => {
    return `+${rowdata.phone}`;
  };

  const dropDownSelect = (id) => {
    if (selectedGroupId !== id) {
      setSelectedCustomer([]);
      setSelectedGroupId(id);
      setCurrpage(0);
      setPrevPage(-1);
      setWasLastList(false);
    }
  };

  const renderGrpFilter = () => {
    return (
      <div
        className="flex m-auto w-full  py-3 px-2 border-round-top"
        style={{ background: "#EDEDED" }}
      >
        <div className="w-12">
          <Dropdown
            options={[defaultOption, ...groups]}
            placeholder="Select A Group"
            optionLabel={"groupName"}
            optionValue={"id"}
            value={selectedGroupId}
            className="w-12"
            onChange={(e) => dropDownSelect(e.value)}
          ></Dropdown>
        </div>
      </div>
    );
  };

  const renderHeader1 = () => {
    return (
      <span className="p-input-icon-right w-full">
        <InputText
          type={"text"}
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search customer"
          style={{ width: "100%" }}
        />
        <i className="pi pi-search" />
      </span>
    );
  };

  let headerGroup = (
    <ColumnGroup style={{ background: "#EDEDED" }}>
      <Row>
        <Column selectionMode="multiple" />
        <Column
          header={renderHeader1}
          colSpan={2}
          className="customer-search-div"
        />
      </Row>
    </ColumnGroup>
  );
  const [sortcust, setSortcust] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading((prev) => !prev);
      try {
        let data;

        switch (customersFrom) {
          case "group":
            if (selectedGroupId !== "all") {
              let response = await axiosInstance.get(
                `/customer-group/${selectedGroupId}/customer?page=${currPage}&limit=${limit}`
              );
              data = response.data;
              setSelectedCustomer([...data.rows]);
            } else {
              data = await API_GET_CUSTOMERS(currPage, limit, searchQuery);
            }

            break;
          case "campaign":
            let response = await axiosInstance.get(
              `campaign/${selectedCampaignId}/customer?page=${currPage}&limit=${limit}&status=${campCustomerStatus}&global=${searchQuery}`
            );
            data = response.data;

            break;
          default:
            data = await API_GET_CUSTOMERS(currPage, limit, searchQuery);
            break;
        }

        setIsLoading((prev) => !prev);

        if (prevpage === -1) {
          setCustomers(data.rows);
        } else {
          setCustomers((prevCustomers) => [...prevCustomers, ...data.rows]);
        }

        if (data.rows.length < limit) {
          setWasLastList(true);
          return;
        }
        setPrevPage(currPage);
      } catch (err) {
        setIsLoading((prev) => !prev);
        console.log(err.response);
      }
    };
    if (!wasLastList) {
      fetchData();
    }

    const fetchGroup = async () => {
      const response = await axiosInstance.get(
        `/customer-group?page=0&limit=${100000}`
      );
      if (response.data.rows) setGroups(response.data.rows);
    };
    if (groups.length == 0 && customersFrom == "group") {
      fetchGroup();
    }
  }, [currPage, debouncedValue, selectedGroupId]);

  // useEffect(()=>{
  //   let arr =selectedCustomer.map((x)=>x.id)
  //   let temp=customers.filter((x)=>!arr.includes(x.id))
  //   setSortcust([...temp])

  // },[customers])

  // const sortCustomers=(e)=>{
  //   let arr =e.value.map((x)=>x.id)
  //   let temp=customers.filter((x)=>!arr.includes(x.id))
  //   setSelectedCustomer(e.value)
  //   setSortcust([...temp])
  // }

  return (
    <>
      {customersFrom === "group" && renderGrpFilter()}
      <div
        className={`card overflow-y-auto`}
        ref={listInnerRef}
        onScroll={onScroll}
        style={{ height: "550px" }}
      >
        <DataTable
          value={saved ? [...selectedCustomer] : [...customers]}
          selection={selectedCustomer}
          headerColumnGroup={headerGroup}
          onSelectionChange={(e) => setSelectedCustomer(e.value)}
          disabled={saved}
          dataKey="id"
          responsiveLayout="scroll"
          scrollable
          className={`skalebot-table2 min-h-full border-round-left-xs ${
            selectedGroupId !== "all" ? "p-disabled" : ""
          }`}
          loading={isLoading}
        >
          <Column
            selectionMode="multiple"
            className="w-1"
            style={{ flex: "none" }}
          ></Column>

          <Column field="name" className="w-5"></Column>
          <Column
            field="phone"
            body={phoneBodyTemplate}
            className="w-6"
          ></Column>
        </DataTable>
      </div>
    </>
  );
};
