export const amounts = {
    IN: "999",
    otherCountry: 12
};

export const BasicPlanData = {
    IN: [
        "1000 free chatbot conversation On Joining",
        "Generate, capture & track your potential leads",
        "Whatsapp Catalogue",
        "Whatsapp marketing through campaign",
        "One Customised bot flow",
        "Bulk product & customer upload",
        "Free 50 marketing templates",
        "Whatsapp business API & Integration",
    ],
    otherCountry: [
        "1000 free chatbot conversation On Joining",
        "Generate, capture & track your potential leads",
        "Whatsapp Catalogue",
        "Whatsapp marketing through campaign",
        "One Customised bot flow",
        "Bulk product & customer upload",
        "Free 50 marketing templates",
        "Whatsapp business API & Integration",
    ]
};

export const conversationTexts = {
    IN: "Conversation charges: As per Meta rates",
    otherCountry: "Conversation charges: As per Meta rates"
};
