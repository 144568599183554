import { Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { capitalizeFirstLetter } from "../../../utils/tableUtils";
import { useUtility } from "../../../context/UtilityContext";
function CustomInputText({
  placeholer = "",
  control = null,
  label = "",
  name = "",
  required = false,
  maxLength = 24,
  rules = {},
  style = {},
  disable = false,
  capitalize = true,
  onChangealert = false,
  helpingText = "",
}) {
  const { setAlert } = useUtility();
  return (
    <div className="flex flex-column gap-2 flex-grow-1">
      {label && (
        <label htmlFor={name}>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={{ required: required && `${label} is mandatory.`, ...rules }}
        render={({ field, fieldState }) => (
          <InputText
            id={field.name}
            value={field.value}
            maxLength={maxLength}
            {...field}
            placeholder={placeholer}
            className={classNames({ "p-invalid": fieldState.invalid })}
            disabled={disable}
            style={style}
            onChange={(e) => {
              onChangealert && setAlert(true);
              capitalize
                ? field.onChange(capitalizeFirstLetter(e.target.value))
                : field.onChange(e.target.value);
            }}
            aria-describedby={`${name}-help`}
          />
        )}
      />
      {helpingText && <small id={`${name}-help`}>{helpingText}</small>}
    </div>
  );
}

export default CustomInputText;
