import { Outlet, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import AppLogout from "../../utils/AppLogout";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { roleAccess, subscription } from "../../reducers/authSlice";
import Sidebar from "../../components/Sidebar";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import axiosInstance from "../../api/axios.instance";
import { getAuth, signInWithCustomToken } from "firebase/auth";

export const Home = ({ interceptorId }) => {
  const [type, setType] = useState(true);

  const { user, conversation } = useSelector((state) => state.authenticate);
  const location = useLocation();
  const dispatch = useDispatch();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    if (user && conversation == null) {
      dispatch(subscription(user?.companyId));
    }
    var w = window.innerWidth;
    if (w < 768) {
      setType(false);
    }
  }, []);

  useEffect(async () => {
    const currentPath = location.pathname;

    dispatch(roleAccess())
      .unwrap()
      .then((res) => {
        let accesskey = res.roleDefinition;

        if (
          currentPath == "/" &&
          accesskey[modulesName.DASHBOARD]?.includes(permissions.READ)
        ) {
          navigate("/dashboard");
        } else if (
          currentPath == "/" &&
          accesskey[modulesName.SALES]?.includes(permissions.READ)
        ) {
          navigate("/chat");
        } else {
          navigate(currentPath);
        }
      });

    const resp = await axiosInstance.get("/firebase/token");
    const firebaseToken = resp.data.token;
    await signInWithCustomToken(auth, firebaseToken)
      .then((userCredential) => {
        // const user = userCredential?.user;
      })
      .catch((error) => {
        console.log("Error signing in with custom token: ", error);
      });
  }, []);

  useEffect(() => {
    return () => {
      axiosInstance.interceptors.request.eject(interceptorId);
    };
  }, []);

  return (
    <AppLogout>
      <div>
        <header className="header__app ">
          <Header type={type} setType={setType} />
        </header>
        <div className="flex">
          <div>
            <Sidebar type={type} setType={setType} />
          </div>
          <div
            className={`w-full h-full flex flex-column justify-content-between main-content-area  ${
              type ? `side-p-o` : `side-p-c`
            }`}
          >
            <div className="flex-grow-1 ml-2">
              <Outlet />
            </div>
            {currentPath !== "/chat" && <Footer />}
          </div>
        </div>
      </div>
    </AppLogout>
  );
};
