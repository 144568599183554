

export const ProPlanData = {
    IN: [
        "3 FREE user License",
        "Green tick verification & assistance",
        "IP whitelisting & restriction",
        "Advanced sales chat box",
        "Custom bot flow builder",
        "Advanced Ticket & Agents analytics",
        "Dedicated support manager"
    ],
    otherCountry: [
        "3 FREE user License",
        "Green tick verification & assistance",
        "IP whitelisting & restriction",
        "Advanced sales chat box",
        "Custom bot flow builder",
        "Advanced Ticket & Agents analytics",
        "Dedicated support manager"
    ]
};

export const amounts = {
    IN: "4999",
    otherCountry: 59
};


// export const additionalUserLicenseDetails = {
//     IN: "₹800 /Month per user",
//     otherCountry: "3 FREE user License "
// };

export const additionalUserLicenseCost = {
    IN: "₹1199 /Month per user",
    otherCountry: "$15/Month per user"
};

export const conversationDetails = {
    IN: "Conversation charges: As per Meta rates",
    otherCountry: "Conversation charges: As per Meta rates"
};
