import React from 'react';
import styles from "./style.module.css"
import { Dialog } from 'primereact/dialog';

const ReactionsModal = ({ reactions={}, visible, onHide }) => {
  return (
    <Dialog header="Message Reactions" visible={visible} onHide={onHide} >
      <div className="flex flex-column">
        {Object?.entries(reactions)?.map(([userId, emoji], index) => (
          <div key={index} className="flex align-items-center justify-content-between gap-2">
              <span className={styles.reactionUser}>
              {userId}
            </span>
            <span className={styles.reactionEmoji} style={{ fontSize: '24px' }}>
              {emoji}
            </span>
            
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default ReactionsModal;
