import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { getDate } from "../../utils/datemaker";
import { useDispatch, useSelector } from "react-redux";
import CustomPaginator from "../../components/CustomPaginator";
import { FilterMatchMode } from "primereact/api";
import { Text } from "../../components/Text";
import { CustomButton } from "../../components/CustomButton";
import CreateGroup from "../../components/CreateGroup";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { ReactComponent as Bluecheck } from "../../svg/blueright.svg";
import { ReactComponent as Greencheck } from "../../svg/greenright.svg";
import { ReactComponent as Greycheck } from "../../svg/greyright.svg";
import { ReactComponent as Star } from "../../svg/star.svg";
import { ReactComponent as Replied } from "../../svg/Replied.svg";
import { ReactComponent as Failed } from "../../svg/fail.svg";

import {
  getCampaignCustomers,
  clearCampaignCustomers,
} from "../../reducers/campaignSlice";
import "./customerAnalyticsStyle.css";

const CustomerAnalyticsDataView = () => {
  const {
    selectedCampaignId,
    selectedCampaign,
    loading,
    limit,
   
  } = useSelector((state) => state.campaign);
  const dispatch = useDispatch();
  const toast = useRef();
  const time=[
    {name:"00:00 AM",value:0},
    {name:"01:00 AM",value:1},
    {name:"02:00 AM",value:2},
    {name:"03:00 AM",value:3},
    {name:"04:00 AM",value:4},
    {name:"05:00 AM",value:5},
    {name:"06:00 AM",value:6},
    {name:"07:00 AM",value:7},
    {name:"08:00 AM",value:8},
    {name:"09:00 AM",value:9},
    {name:"10:00 AM",value:10},
    {name:"11:00 AM",value:11},
    {name:"12:00 PM",value:12},
    {name:"01:00 PM",value:13},
    {name:"02:00 PM",value:14},
    {name:"03:00 PM",value:15},
    {name:"04:00 PM",value:16},
    {name:"05:00 PM",value:17},
    {name:"06:00 PM",value:18},
    {name:"07:00 PM",value:19},
    {name:"08:00 PM",value:20},
    {name:"09:00 PM",value:21},
    {name:"10:00 PM",value:22},
    {name:"11:00 PM",value:23},
  ];
  useEffect(() => {

    dispatch(
      getCampaignCustomers({  })
    )
      .unwrap()
      .catch((err) => {
        //show toast here
      });

      return  ()=>{
        dispatch(clearCampaignCustomers())
        }
      
  }, [selectedCampaignId]);

  
  return (
    <div>
      <Toast ref={toast} />  
          <DataTable
            key={"id"}
            // value={}
            className="skalebot-table"
            responsiveLayout="scroll"
            emptyMessage="No data found."
            dataKey="id"
            resizableColumns 
            columnResizeMode="expand"
          >
            <Column field="name" header="Customer Name" />
            <Column field="status" header="Status" />
          </DataTable>
    </div>
  );
};

export default CustomerAnalyticsDataView;
