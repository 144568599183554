import { useNavigate, useParams } from "react-router";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { CustomButton } from "../../components/CustomButton";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import { Text } from "../../components/Text";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useRef } from "react";
import {
  changeMode,
  changePage,
  changelimit,
  setChunkpage,
  getGroup,
  createGroup,
  getsortedCustomers,
  resetSelectedGroup,
  updateGroup,
  updatecustomersInSelectedGroup,
  resetPagination,
  reseSelectedCustomers,
} from "../../reducers/customerTableSlice";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomPaginator from "../../components/CustomPaginator";
import DebounceSearch from "../../components/common/DebounceSearch";
import { InputText } from "primereact/inputtext";
import { useLayout } from "../../context/LayoutContext";
import CustomSaveModel from "../../components/CustomeSaveModel";
import CONSTANTS from "../../config/Constants";
import * as Messag from "../../config/ToastMessage";
import { API_GET_ALL_AGENTS } from "../../api/account.service";
import { Dropdown } from "primereact/dropdown";
import { capitalizeFirstLetter } from "../../utils/tableUtils";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import { useUtility } from "../../context/UtilityContext";
import ImportGroupFromExcel from "./ImportGroupFromExcel";
import { formatMobNumber } from "../../utils/commonUtils";
import { changeUser } from "../../reducers/enqChatSlice";
import { removeCountryCode } from "../../utils/commonUtils";
import { ReactComponent as ChatIcon } from "../../assets/chat.svg";

function NewGroup() {
  const { setLoading, toastMessage } = useLayout();
  const navigate = useNavigate();
  const {
    customerData,
    chunkpage,
    page,
    limit,
    loading,
    totalCustomerCount,
    mode,
    selectedGroup,
    customersInSelectedGroup,
  } = useSelector((state) => state.customerTable);

  const [nameCust, setNameCust] = useState("");
  const dispatch = useDispatch();

  const { accesskey } = useSelector((state) => state.authenticate);

  const { id } = useParams();
  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const breadcumbHome = { label: "Groups", url: "/customers/groups" };
  const breadcumbList = [
    { label: `${id ? "Update" : "New"} Groups`, url: "#" },
  ];

  const [inputValue, setInputValue] = useState("");
  const { setAlert } = useUtility();
  const renderHeader1 = () => {
    return (
      <div className="lg:flex justify-content-start   lg:mb-0 sm:mb-0">
        <div className="flex __search ml-2 md:ml-4">
          <DebounceSearch
            placeholder={"Search Customer"}
            className=" __searchInput"
            inputValue={inputValue}
            handleInputChange={(value) => setInputValue(value)}
          />
        </div>
      </div>
    );
  };
  useEffect(() => {
    return () => {
      setAlert(false);
    };
  }, []);
  const header1 = renderHeader1();
  const onSubmit = () => {
    if (nameCust.length === 0) {
      toastMessage({
        severity: "error",
        detail: "Please enter group name",
      });
      setShowSave(false);
      return;
    }
    const data = {
      groupName: nameCust,
      agentId: currAgent?.id ? currAgent?.id : null,
      customerIds: Array.from(customersInSelectedGroup, (x) => {
        return x["id"];
      }),
    };

    if (mode === "update" && id) {
      const groupId = selectedGroup.id;
      dispatch(updateGroup({ groupId, data }))
        .unwrap()
        .then((res) => {
          toastMessage({
            severity: "success",
            detail: Messag.Update_Group_ToastSuccessMessage,
          });
          navigate(`/customers/groups`);
        })
        .catch((errResponse) => {
          toastMessage({ severity: "error", detail: errResponse.message });
        });
    } else {
      dispatch(createGroup(data))
        .unwrap()
        .then((e) => {
          toastMessage({
            severity: "success",
            detail: Messag.Add_Group_ToastSuccessMessage,
          });
          navigate(`/customers/groups`);
        })
        .catch((errResponse) => {
          toastMessage({ severity: "error", detail: errResponse.message });
        });
    }
    setShowSave(false);
  };

  function onSelectCustomer(e) {
    const selectedCust = e.value;
    const currCust = customerData;

    dispatch(updatecustomersInSelectedGroup({ selectedCust, currCust }));
  }
  const [currAgent, setAgent] = useState(null);
  const [allAgents, setAllAgents] = useState([]);
  const [apirequst, setApiRequest] = useState(false);
  const { navigation } = useUtility();
  useEffect(() => {
    API_GET_ALL_AGENTS()
      .then((data) => {
        let supportAgents = data.user.rows.map((agent) => {
          return { id: agent?.id, userName: agent?.userName };
        });
        setAllAgents(supportAgents);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(changeMode("update"));
      dispatch(getGroup({ id: id }))
        .unwrap()
        .then((e) => {
          dispatch(
            getsortedCustomers({
              page,
              limit,
              globalFilterValue: inputValue,
              customerIds: e?.customerIds,
            })
          )
            .unwrap()
            .catch(() => {});
          setApiRequest(true);
        });
    } else {
      dispatch(
        getsortedCustomers({
          page,
          limit,
          globalFilterValue: inputValue,
          customerIds:
            customersInSelectedGroup?.length > 0
              ? customersInSelectedGroup.map((x) => x.id)
              : [],
        })
      )
        .unwrap()
        .catch(() => {});
    }
    return () => {
      dispatch(reseSelectedCustomers());
      dispatch(resetPagination());
    };
  }, [id]);

  useEffect(() => {
    if (selectedGroup?.id !== "all") {
      setNameCust(selectedGroup?.groupName);
      let agent_value = null;
      if (selectedGroup?.agentId) {
        allAgents?.forEach((agent) => {
          if (agent?.id == selectedGroup?.agentId) {
            agent_value = agent;
          }
        });
      }
      setAgent(agent_value);
      let ids =
        selectedGroup?.customerIds?.length > 0
          ? selectedGroup?.customerIds
          : [];
      let selectedCustomer = ids?.map((x) => {
        return { id: x };
      });
      onSelectCustomer({ value: selectedCustomer });
    }
  }, [selectedGroup, allAgents]);

  const listInnerRef = useRef();
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        Math.floor(scrollTop + clientHeight + 5) >= scrollHeight ||
        Math.ceil(scrollTop + clientHeight + 5) >= scrollHeight
      ) {
        dispatch(setChunkpage(chunkpage + 1));
      }
    }
  };

  useEffect(() => {
    if (apirequst) {
      dispatch(
        getsortedCustomers({
          page: limit <= 100 ? page : chunkpage,
          limit: limit <= 100 ? limit : 100,
          globalFilterValue: inputValue,
          customerIds:
            customersInSelectedGroup?.length > 0
              ? customersInSelectedGroup.map((x) => x.id)
              : [],
        })
      )
        .unwrap()
        .catch(() => {});
    }
    setApiRequest(true);
  }, [page, limit, inputValue, limit >= 100 ? chunkpage : ""]);

  const [showSave, setShowSave] = useState(false);
  const SaveModel = () => {
    return (
      <CustomSaveModel
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };
  const alertText = () => {
    return <div>{CONSTANTS.GROUP_SAVE_CONFIRMATION}</div>;
  };
  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false);
          }}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={() => {
            onSubmit();
          }}
        />
      </div>
    );
  };
  const onHide = () => {
    setShowSave(false);
  };
  const resetform = () => {
    if (!id) {
      setNameCust("");
      setAgent(null);
    }else{
        dispatch(getGroup({ id: id }))
          .unwrap()
          .then((e) => {
            dispatch(
              getsortedCustomers({
                page,
                limit,
                globalFilterValue: inputValue,
                customerIds: e?.customerIds,
              })
            )
              .unwrap()
              .catch(() => {});
          });
      }
  }

  const statusbody = (rowData) => {
    let colormap = {
      New: { background: "#D1E0C1", color: "#000" },
      "In Progress": { background: "#EEE5CD", color: "#000" },
      "Follow Up": { background: "#C9DEE9", color: "#000" },
      Closed: { background: "#F2D5E3", color: "#000" },
    };
    return (
      <div className="w-auto">
        <span
          className="p-1 px-2 font-normal w-auto border-round-sm"
          style={{ ...colormap[rowData?.ticketStatus] }}
        >
          {rowData?.ticketStatus}
        </span>
      </div>
    );
  };

  const handleEnqSelect = (data) => {
    dispatch(changeUser({ ...data, mob: data.phone }));
    let mob = removeCountryCode(data.phone);
    navigate(`/chat/${mob}`, { data });
    return;
  };

  const actionBodyTemplate = (rowData) => {
    return (
          <CustomButton
            varient="icon-button"
            icon={<ChatIcon />}
            onClick={() => handleEnqSelect(rowData)}
          />
    )
  }

  return (
    <div>
      <CustomBreadcrumb home={breadcumbHome} itemslist={breadcumbList} />
      {<SaveModel />}
      <div className="m-3">
        <TableActionsContainer>
          <div className="hidden sm:hidden md:flex justify-content-between align-items-center gap-3 flex-wrap">
            <Text type={"heading"}>{`${
              id ? "Update" : "Create New"
            } Group`}</Text>
            <div className="flex gap-2">
              {id && (
                <ImportGroupFromExcel
                  toast={null}
                  groupName={selectedGroup?.groupName}
                />
              )}
              {/* export the customer on manual selection or selected group */}
              {/* <CustomButton
                varient="icon-button"
                onClick={exportToCsv}
                label={"Export"}
                disabled={customersInSelectedGroup?.length > 0 ? false : true}
              /> */}
              <CustomButton
                varient="text"
                label={"Cancel"}
                onClick={() => navigation(`/customers/groups`)}
              />
              <CustomButton
                varient="outline"
                label={"Reset"}
                onClick={() => {
                  dispatch(resetSelectedGroup());
                  resetform();
                }}
              />
              {accesskey[modulesName.CUSTOMERGROUP]?.includes(
                `${id ? permissions.UPDATE : permissions.CREATE}`
              ) && (
                <CustomButton
                  varient="filled"
                  label={id ? "Update" : "Save"}
                  onClick={() => {
                    if (nameCust.length === 0 || nameCust === "") {
                      toastMessage({
                        severity: "error",
                        detail: "Please enter group name",
                      });
                      setShowSave(false);
                      return;
                    }
                    setShowSave(true);
                  }}
                  disabled={nameCust?.length === 0 || nameCust === ""}
                />
              )}
            </div>
          </div>
          {/* ***** For Mobile Screens ***** */}
          <div className="hidden sm:flex md:hidden flex flex-column justify-content-between align-items-start gap-3 flex-wrap">
            <div className="flex justify-content-start">
              <Text type={"heading"}>{`${
                id ? "Update" : "Create New"
              } Group`}</Text>
            </div>
            <div className="flex justify-content-end  w-full ">
              <div className="flex gap-3">
                <CustomButton
                  varient="text"
                  label={"Cancel"}
                  onClick={() => navigation(`/customers/groups`)}
                />
                <CustomButton
                  varient="outline"
                  label={"Reset"}
                  onClick={() => {
                    dispatch(resetSelectedGroup());
                    setNameCust("");
                    setAgent(null);
                    navigate(`/customers/gnew`);
                  }}
                />
                {accesskey[modulesName.CUSTOMERGROUP]?.includes(
                  `${id ? permissions.UPDATE : permissions.CREATE}`
                ) && (
                  <CustomButton
                    varient="filled"
                    label={id ? "Update" : "Save"}
                    onClick={() => {
                      if (nameCust.length === 0 || nameCust === "") {
                        toastMessage({
                          severity: "error",
                          detail: "Please enter group name",
                        });
                        setShowSave(false);
                        return;
                      }
                      setShowSave(true);
                    }}
                    disabled={nameCust?.length === 0 || nameCust === ""}
                  />
                )}
              </div>
            </div>
          </div>
        </TableActionsContainer>
      </div>
      <div className="m-3 p-3 form-container">
        <div className="flex gap-3  align-items-center flex-wrap">
          <div className="w-full lg:w-3 xl:w-3 md:w-4 ">
            <div className="flex flex-column gap-2 flex-grow-1">
              <label htmlFor={"name"}>Name *</label>
              <InputText
                value={nameCust}
                onChange={(e) => {
                  setAlert(true);
                  setNameCust(capitalizeFirstLetter(e.target.value));
                }}
                placeholder="Enter group name"
                className="w-12"
              ></InputText>
            </div>
          </div>
          <div className="w-full lg:w-3 xl:w-3 md:w-4 ">
            <div className="flex flex-column gap-2 flex-grow-1">
              <label htmlFor={"name"}>Assigned</label>
              <Dropdown
                id={"assigned"}
                value={currAgent}
                options={allAgents}
                optionLabel="userName"
                placeholder="Select Agent"
                className="w-12 capitalize"
                showClear
                onChange={(e) => {
                  setAlert(true);
                  setAgent(e.value);
                }}
              />
            </div>
          </div>

          <div className="w-full lg:w-3 xl:w-3 md:w-4 ">
            <div className="flex flex-column gap-2 flex-grow-1">
              <label htmlFor={"name"} className=" hidden md:flex">
                &nbsp;
              </label>
              <div className="w-12 flex justify-content-start align-items-center font-semibold md:-3">
                Total Customers: {customersInSelectedGroup?.length || 0}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-column w-full p-3">
        {/* <div className="w-full"> */}
        <div
          className="overflow-y-auto w-full"
          ref={listInnerRef}
          onScroll={onScroll}
          style={{ maxHeight: "70vh" }}
        >
          <DataTable
            value={customerData}
            dataKey="id"
            responsiveLayout="scroll"
            resizableColumns
            columnResizeMode="expand"
            className="skalebot-table"
            selection={customersInSelectedGroup}
            onSelectionChange={(e) => {
              setAlert(true);
              onSelectCustomer(e);
            }}
            selectionMode="checkbox"
            header={header1}
          >
            <Column
              selectionMode="multiple"
              className="text-sm font-normal"
              headerStyle={{ width: "3rem" }}
              exportable={false}
            ></Column>
            <Column
              field="customerCode"
              header="Customer Code"
              className="text-sm font-normal"
              style={{ width: "15ch" }}
              bodyStyle={{ height: "50px", textTransform: "capitalize" }}
            />
            <Column
              header="Customer Name"
              field="name"
              className="text-sm font-normal"
            />
            <Column
              header="Phone"
              field="phone"
              style={{ width: "25ch" }}
              className="text-sm font-normal"
              body={(rowdata) => formatMobNumber(rowdata?.phone)}
            />
            <Column
              field="email"
              header="Email"
              style={{ width: "50ch" }}
              className="text-sm font-normal"
              bodyStyle={{ textTransform: "lowercase" }}
            />
            <Column 
              header="Ticket Status"
              body={statusbody}
              style={{width: '25ch'}}
              className="text-sm font-normal"
              bodyStyle={{ textTransform: "capitalize"}}
            />
            <Column
              field=""
              header={""}
              bodyStyle={{ textTransform: "capitalize" }}
              style={{ width: "5ch" }}
              body={actionBodyTemplate}
            />
          </DataTable>
        </div>
        <div className="mt-3">
          <TableActionsContainer>
            <div className="flex justify-content-end">
              <CustomPaginator
                page={page}
                limit={limit}
                changelimit={changelimit}
                totalRecords={totalCustomerCount}
                changePage={changePage}
              />
            </div>
          </TableActionsContainer>
        </div>
      </div>
    </div>
  );
}

export default NewGroup;
