import React, { useEffect, useState } from "react";
import "../stylesheets/bot-item.css";
import { DataTable } from "primereact/datatable";
import { InputSwitch } from "primereact/inputswitch";
import { Column } from "primereact/column";
import { ReactComponent as Edit } from "../../../../svg/edit.svg";
import { ReactComponent as Delete } from "../../../../svg/delete.svg";
import { CustomButton } from "../../../../components/CustomButton";
import PatternQr from "./PatternQr";
import { Text } from "../../../../components/Text";
import { BotflowName } from "./column-templates/BotflowName";
import {
  updateBotflowDetails,
  getAllBotFlow,
} from "../../../../reducers/canvas/canvasSlice";
import { useDispatch, useSelector } from "react-redux";
import { API_UPDATE_BOT_FLOW } from "../../../../api/botFlow.service";

export const BotListTable = ({
  botflowsData = [],
  handleEditClick,
  handleDeleteClick,
  handleEnableDisableClick,
}) => {
  const dispatch = useDispatch();
  const { page, limit } = useSelector((state) => state.canvas);
  const [botflowsDataState, setBotflowsData] = useState([]);

  useEffect(() => {
    setBotflowsData(botflowsData);
  }, [botflowsData]);

  useEffect(() => {
    fetchBotflows();
  }, [dispatch, page, limit]);

  const fetchBotflows = async () => {
    const result = await dispatch(getAllBotFlow({ page, limit }));
    if (getAllBotFlow.fulfilled.match(result)) {
      setBotflowsData(result.payload.data);
    }
  };

  const handleSwitchToggle = async (botflowId, isEnable) => {
    try {
      const payload = { isEnable };
      const result = await dispatch(
        updateBotflowDetails({ id: botflowId, updatedData: payload })
      );

      if (updateBotflowDetails.fulfilled.match(result)) {
        setBotflowsData((prev) =>
          prev.map((bot) => (bot.id === botflowId ? { ...bot, isEnable } : bot))
        );
      } else {
      }
    } catch (error) {
      console.error("Error updating bot flow:", error);
    }
  };

  const enableDisableTemplate = (bflow) => (
    <InputSwitch
      checked={bflow.isEnable}
      onChange={(e) => handleSwitchToggle(bflow.id, e.value)}
      className={`${bflow.isEnable ? "custom-enabled" : ""}`}
    />
  );

  return (
    <DataTable
      value={botflowsDataState}
      dataKey="id"
      className="skalebot-table"
      tableStyle={{ minWidth: "60rem" }}
    >
      <Column
        header="S.No."
        body={(_, options) => options.rowIndex + 1}
        className="text-sm font-normal"
      />

      <Column
        field="name"
        header="Flow Name & Date"
        body={(bflow) => <BotflowName bflow={bflow} />}
        className="text-sm font-normal"
      />

      <Column
        field=""
        header="Name/Type"
        className="text-sm font-normal"
        body={(bflow) => {
          const { rootNode } = bflow;
          return rootNode ? (
            <div>
              <Text>{rootNode.pattern || rootNode.template?.templateName}</Text>
              <Text color={"#00000080"} type={"small-text"}>
                {rootNode.pattern ? "Pattern" : "Template"}
              </Text>
            </div>
          ) : (
            "-"
          );
        }}
      />

      <Column
        header="Qr Code"
        className="text-sm font-normal"
        body={(bflow) =>
          bflow.rootNode && bflow.rootNode.pattern ? (
            <PatternQr
              pattern={bflow.rootNode.pattern}
              showQr={false}
              showDownload={true}
            />
          ) : (
            "-"
          )
        }
        headerStyle={{ display: "flex", justifyContent: "center" }}
      />

      <Column
        header="Status"
        className="text-sm font-normal"
        body={(bflow) => (
          <div className={`flex-nowrap fStatus-${bflow.status}`}>
            <Text>{bflow.status}</Text>
          </div>
        )}
      />

      <Column
        header="Enable/Disable"
        className="text-sm font-normal"
        body={enableDisableTemplate}
      />

      <Column
        header="Action"
        body={(bflow) => (
          <div className="flex justify-content-start align-items-center gap-1">
            <CustomButton
              varient="text"
              icon={<Edit />}
              onClick={() => handleEditClick(bflow)}
            />
            <CustomButton
              varient="text"
              icon={<Delete />}
              onClick={() => handleDeleteClick(bflow.id)}
            />
          </div>
        )}
      />
    </DataTable>
  );
};
