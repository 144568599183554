import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton } from "../../../components/CustomButton";
import { Text } from "../../../components/Text";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import AgentName from "./AgentName";
import { formatMobNumber } from "../../../utils/commonUtils";
import { useNavigate } from "react-router";
import { ReactComponent as EditIcon } from "../../../svg/edit.svg";
import { handleAccountUser } from "../../../reducers/accountSlice";
function AdminUsers() {
  const [adminUsers, setAdminUsers] = useState([]);
  const { user } = useSelector((state) => state.authenticate);
  const { allUsers, superAdminUser, loading, userToast } = useSelector(
    (state) => state.account
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEditUser = (userData) => {
    if (userData.id === superAdminUser?.id && user.id !== superAdminUser?.id)
      return;
    dispatch(handleAccountUser(userData));
    navigate(`./${userData.id}`);
  };

  useEffect(() => {
    // const supportUsers = allUsers.filter((user) => user.role === "support");
    // const campaignUsers = allUsers.filter((user) => user.role === "campaign");
    const adminUsers = allUsers.filter((user) => user.role === "admin");

    // setSupportAgents(supportUsers);
    // setCampaignUser(campaignUsers);
    setAdminUsers(adminUsers);
  }, [allUsers]);

  return (
    <div>
      <div className="mb-3">
        <Text type={"heading"}>Admin users</Text>
      </div>
      <CustomButton
        label={"Add admin user"}
        icon={"pi pi-plus"}
        onClick={() => navigate("./new/admin")}
      />
      <div className="mt-3">
        <div
          className="mt-3 p-3 border-round-md"
          style={{ background: "#fff" }}
        >
          <div className="mb-3">
            <Text type={"heading"}> Super admin information</Text>
          </div>
          <DataTable
            className={`w-full skalebot-table`}
            value={[superAdminUser]}
            responsiveLayout="scroll"
            resizableColumns
            columnResizeMode="expand"
            emptyMessage="No data found."
          >
            <Column
              field="name"
              header="Super admin name"
              style={{minWidth:'100px',maxWidth:'25ch'}}
              body={(rowData) => <AgentName data={rowData} />}
            />
            <Column field="email" header="Email" />
            <Column
              field="phone"
              header="Mobile number"
              style={{width:'25ch'}}
              body={(rowData) => formatMobNumber(rowData?.phone)}
            />
          </DataTable>
        </div>
      </div>
      <div className="mt-3 p-3 border-round-md" style={{ background: "#fff" }}>
        <div>
          <div className="mb-3">
            <Text type={"heading"}>Admin users information</Text>
          </div>
          <DataTable
            className={`w-full skalebot-table`}
            value={adminUsers}
            responsiveLayout="scroll"
            resizableColumns
            columnResizeMode="expand"
            emptyMessage="No data found."
          >
            <Column
              field="name"
              header="Name"
              style={{minWidth:'100px',maxWidth:'25ch'}}
              body={(rowData) => <AgentName data={rowData} />}
            />
            <Column field="email" header="Email" />
            <Column
              field="phone"
              header="Mobile number"
              body={(rowData) => formatMobNumber(rowData?.phone)}
              style={{width:'20ch'}}
            />
            <Column
              field=""
              header="Status"
              body={(rowData) => (
                <Text>{rowData.isActive ? "Active" : "Inactive"}</Text>
              )}
              style={{width:'15ch'}}
            />
            <Column
              field=""
              header={"Actions"}
              body={(rowData) => (
                <div>
                  <CustomButton
                    varient={`icon-button`}
                    icon={<EditIcon />}
                    onClick={() => handleEditUser(rowData)}
                    style={{ padding: "0px" }}
                  />
                </div>
              )}
              style={{width:'5ch'}}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
}
export default AdminUsers;
