import { useDispatch } from "react-redux";
import { Outlet, useParams } from "react-router";
import OrderTable from "./OrderTable";
import { useEffect } from "react";
import { resetOrderPagination } from "../../reducers/orderTableSlice";

const Orders = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetOrderPagination());
    };
  }, []);
  return <>{id ? <Outlet /> : <OrderTable />}</>;
};

export default Orders;
