import { ReactComponent as DashboardIcon } from "../../assets/sidebarIcons/dashboard.svg";
import { ReactComponent as ProductIcon } from "../../assets/sidebarIcons/products.svg";
import { ReactComponent as OrderIcon } from "../../assets/sidebarIcons/orders.svg";
import { ReactComponent as CategoryIcon } from "../../assets/sidebarIcons/category.svg";
import { ReactComponent as CustmerIcon } from "../../assets/sidebarIcons/customer.svg";
import { ReactComponent as EnquiryIcon } from "../../assets/sidebarIcons/enquiry.svg";
import { ReactComponent as CampaignIcon } from "../../assets/sidebarIcons/campaign.svg";
import { ReactComponent as LogoutIcon } from "../../assets/sidebarIcons/logout.svg";
import { ReactComponent as AccountIcon } from "../../assets/sidebarIcons/account.svg";
import { ReactComponent as PaymentIcon } from "../../assets/sidebarIcons/payment.svg";
import { ReactComponent as DashboardBlackIcon } from "../../assets/sidebarIcons/dashboardblack.svg";
import { ReactComponent as ProductBlackIcon } from "../../assets/sidebarIcons/productsblack.svg";
import { ReactComponent as OrderBlackIcon } from "../../assets/sidebarIcons/ordersblack.svg";
import { ReactComponent as CategoryBlackIcon } from "../../assets/sidebarIcons/categoryblack.svg";
import { ReactComponent as CustmerBlackIcon } from "../../assets/sidebarIcons/customerblack.svg";
import { ReactComponent as EnquiryBlackIcon } from "../../assets/sidebarIcons/enquiryblack.svg";
import { ReactComponent as CampaignBlackIcon } from "../../assets/sidebarIcons/campaignblack.svg";
import { ReactComponent as LogoutBlackIcon } from "../../assets/sidebarIcons/logoutblack.svg";
import { ReactComponent as AccountBlackIcon } from "../../assets/sidebarIcons/accountblack.svg";
import { ReactComponent as TemplateIcon } from "../../assets/sidebarIcons/template.svg";
import { ReactComponent as PaymentBlackIcon } from "../../assets/sidebarIcons/paymentblack.svg";
import { ReactComponent as MenuOpenIcon } from "../../assets/sidebarIcons/menuOpen.svg";
import { ReactComponent as MenuCloseIcon } from "../../assets/sidebarIcons/menuClose.svg";
import { ReactComponent as SettingIcon } from "../../assets/sidebarIcons/setting.svg";
import { ReactComponent as SettingBlackIcon } from "../../assets/sidebarIcons/settingblack.svg";
import { ReactComponent as TicketIcon } from "../../assets/sidebarIcons/ticket.svg";
import { ReactComponent as ChatIcon } from "../../assets/sidebarIcons/chaticonblack.svg";

export const SideIcon = ({ icon }) => {
  switch (icon) {
    case "dashboard":
      return <DashboardIcon />;
    case "product":
      return <ProductIcon />;
    case "order":
      return <OrderIcon />;
    case "enquiry":
      return <EnquiryIcon />;
    case "category":
      return <CategoryIcon />;
    case "ticket":
      return <TicketIcon />;
    case "customer":
      return <CustmerIcon />;
    case "campaign":
      return <CampaignIcon />;
    case "logout":
      return <LogoutIcon />;
    case "account":
      return <AccountIcon />;
    case "payment":
      return <PaymentIcon />;
    case "setting":
      return <SettingIcon />;
    case "dashboardb":
      return <DashboardBlackIcon />;
    case "productb":
      return <ProductBlackIcon />;
    case "orderb":
      return <OrderBlackIcon />;
    case "enquiryb":
      return <EnquiryBlackIcon />;
    case "categoryb":
      return <CategoryBlackIcon />;
    case "customerb":
      return <CustmerBlackIcon />;
    case "campaignb":
      return <CampaignBlackIcon />;
    case "logoutb":
      return <LogoutBlackIcon />;
    case "accountb":
      return <AccountBlackIcon />;
    case "paymentb":
      return <PaymentBlackIcon />;
    case "settingb":
      return <SettingBlackIcon />;
    case "openmenu":
      return <MenuOpenIcon />;
    case "closemenu":
      return <MenuCloseIcon />;
    case "template":
      return <TemplateIcon />;
    case "chats":
      return <ChatIcon />;
    default:
      <p>Notfound</p>;
  }
};
