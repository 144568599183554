import axios from "axios";
import loadImage from "blueimp-load-image/js";
const languageOptions = [
  { key: "English", value: "en" },
  { key: "Hindi", value: "hi" },
];
const templateTypes = [
  { key: "Text", value: "TEXT" },
  { key: "Image", value: "IMAGE" },
  { key: "Video", value: "VIDEO" },
  { key: "Document", value: "DOCUMENT" },
];

const buttonTypes = [
  {
    key: "None",
    value: "none",
  },

  {
    key: "Phone Number",
    value: "PHONE_NUMBER",
  },
  {
    key: "URL",
    value: "URL",
  },
  {
    key: "Quick Reply",
    value: "QUICK_REPLY",
  },
];

const tempProductButons = [
  {
    id: Math.floor(1000 + Math.random() * 9000),
    text: "Check",
    type: "QUICK_REPLY",
    isSaved: true,
  },
  {
    id: Math.floor(1000 + Math.random() * 9000),
    text: "Not Now",
    type: "QUICK_REPLY",
    isSaved: true,
  },
];

const quickReplyButtons = [
  {
    id: 1,
    text: "Interested",
  },
  {
    id: 2,
    text: "Not Now",
  },
  {
    id: 3,
    text: "Menu",
  },
  {
    id: 4,
    text: "Support Agent",
  },
];

const tempCategories = [
  {
    key: "Marketing",
    value: "MARKETING",
  },
  {
    key: "Utility",
    value: "UTILITY",
  },
  // {
  //   key: "Authentication",
  //   value: "AUTHENTICATION",
  // },
];

const templateAboutOptions = [
  {
    key: "Marketing",
    value: "Marketing",
  },
  {
    key: "Welcome Message",
    value: "Welcome Message",
  },
  {
    key: "Promotion Announcement",
    value: "Promotion Announcement",
  },
];
const categoryFilterOptions = [
  "MARKETING",
  "UTILITY",
  //"AUTHENTICATION"
];
const templateFilterOptions = ["Text", "Image", "Video", "Document"];

const templeteVariablesType = {
  "{{customerName}}": "customerName",
  "{{customerMobile}}": "customerMobile",
  "{{customerEmail}}": "customerEmail",
  "{{message}}": "customNote",
};

const formateMessageInput = (message, purpose, variables) => {
  if (message !== undefined) {
    let updatedMessage = message;
    for (let i = 0; i < variables.length; i++) {
      const { variableName, variableValue } = variables[i];
      switch (purpose) {
        case "preview":
          updatedMessage = updatedMessage.replaceAll(
            variableName,
            variableValue
          );
          break;
        case "submit":
          updatedMessage = updatedMessage.replaceAll(
            variableName,
            `[${variableValue}]`
          );
          break;
        default:
          updatedMessage = updatedMessage.replace(variableName, `{{${i + 1}}}`);
      }
    }
    return updatedMessage.replace(/\n\s*\n\s*\n/g, "\n\n");
  } else return "";
};

const isVariableExist = (newVar, varsArr) => {
  for (let i = 0; i < varsArr.length; i++) {
    if (varsArr[i].variableName == newVar.variableName) return true;
  }
  return false;
};

const extractVariablesFormMessage = (message) => {
  let variablesArr = [];
  let posibleVariables = [
    "customerName",
    "customerEmail",
    "customerMobile",
    "message",
  ];
  if (message != undefined) {
    message.split("{{").forEach((str) => {
      if (str.includes("}}")) {
        let varStr = str.split("}}")[0];
        let isMessageVar = varStr.includes("message");
        if (posibleVariables.includes(varStr) || isMessageVar) {
          variablesArr.push({
            id: Math.floor(1000 + Math.random() * 9000),
            variableName: `{{${varStr}}}`,
            variableValue: "",
            variableType: isMessageVar ? "customNote" : varStr, // templeteVariablesType[`{{${varStr}}}`],
          });
        }
      }
    });
  }
  return variablesArr;
};

const formatBodyContent = (message) => {
  if (message != undefined) {
    let updateContent = message;
    let allVariablesInMessage = extractVariablesFormMessage(message);
    for (let i = 0; i < allVariablesInMessage.length; i++) {
      const { variableName } = allVariablesInMessage[i];
      updateContent = updateContent.replace(variableName, `{{${i + 1}}}`);
    }
    return updateContent.replace(/\n\s*\n\s*\n/g, "\n\n");
  } else return "";
};

const formatVariableArr = (message, sampleVarswithValueArr) => {
  let formateVarsArr = [];
  if (message != "undefined") {
    let allVariables = extractVariablesFormMessage(message);
    for (let i = 0; i < allVariables.length; i++) {
      for (let j = 0; j < sampleVarswithValueArr.length; j++) {
        if (
          allVariables[i].variableName == sampleVarswithValueArr[j].variableName
        ) {
          let tempVar = {
            variableName: `{{${i + 1}}}`,
            variableType: allVariables[i].variableType,
            variableValue: sampleVarswithValueArr[j].variableValue,
          };
          formateVarsArr.push(tempVar);
        }
      }
    }
  }

  return formateVarsArr;
};

const validateMediaSize = (selectedMediaSize, mediaType) => {
  let allowedMediaSize = {
    // image: 1048576 * 3, //1mb = 1048576 bytes
    image: 1048576 * 2, //2mb = 1048576 bytes
    video: 1048576 * 20, // 20mb = 10485760 bytes,
    // video: 1048576 * 10, // 10mb = 10485760 bytes,
    document: 1048576 * 2, //3mb =- 4194304 bytes
  };

  return {
    isValid: selectedMediaSize <= allowedMediaSize[mediaType],
    acceptedSize: allowedMediaSize[mediaType] / 1048576,
  };
};

const blob = async (url) => {
  // return await fetch( url ).then( r => r.blob())
  return await axios.get(url, { responseType: "blob" }).then((res) => {
    return res.data;
  });
};

const compressImg = async (file) => {
  let maxFileSize = 10e5 / 3;
  const image = await loadImage(file, { orientation: true });
  const compressFactor = Math.sqrt(maxFileSize / file.size) * 0.9;
  const canvas = await loadImage.scale(image.image, {
    maxWidth: image.originalWidth * compressFactor,
    maxHeight: image.originalHeight * compressFactor,
    canvas: true,
  });
  return await new Promise((resolve) => {
    canvas.toBlob(
      (blob) => resolve(new File([blob], file.name, { type: file.type })),
      file.type
    );
  });
};
function isValidURL(url) {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR an IP address
      "(:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(#[-a-z\\d_]*)?$",
    "i"
  );
  return urlPattern.test(url);
}

const refineMessage = (variable, message) => {
  for (let key in variable) {
    if (key) {
      message = message.replace(`${key}`, variable[key]);
    }
  }
  return message;
};

export {
  validateMediaSize,
  formateMessageInput,
  extractVariablesFormMessage,
  formatBodyContent,
  blob,
  compressImg,
  categoryFilterOptions,
  templateFilterOptions,
  languageOptions,
  tempCategories,
  buttonTypes,
  templateTypes,
  templeteVariablesType,
  quickReplyButtons,
  tempProductButons,
  templateAboutOptions,
  isVariableExist,
  formatVariableArr,
  isValidURL,
  refineMessage,
};
