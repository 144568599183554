import { Controller } from "react-hook-form";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import { useUtility } from "../../../context/UtilityContext";
function CustomInputPrice({
  label = "",
  name = "",
  placeholder = "",
  control = control,
  isMandatory = false,
  onChangealert=false,
}) {
  const {setAlert}=useUtility();
  return (
    <div className="flex flex-column gap-2 flex-grow-1">
      <label htmlFor="quantity">
        {label} {isMandatory && <span>*</span>}
      </label>
      <Controller
        name={name}
        control={control}
        rules={{ required: isMandatory && `${name} is required.` }}
        render={({ field, fieldState }) => (
          <InputNumber
            id={field.name}
            value={field.value}
            onChange={(e) =>{onChangealert&&setAlert(true); field.onChange(e.value)}}
            useGrouping={false}
            placeholder={placeholder}
            className={classNames({ "p-invalid": fieldState.invalid })}
            mode="currency"
            currency="INR"
            currencyDisplay="code"
            locale="en-IN"
          />
        )}
      />
    </div>
  );
}

export default CustomInputPrice;
