import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios.instance";
import axios from "axios";
import { API_ADD_CAMPAIGN, API_GET_IMAGE_CONFIG } from "../../api/campaign.service";
import { blob } from "../../utils/templateUtils";
import { API_START_CONVERSATION } from "../../api/template.service";
const shareBroadcast = createAsyncThunk(
  "messageShare/sharebroadcast",
  async ({ configData, media, schedule=false}, thunkAPI) => {
    try {
      var config={...configData};
      if (media) {
        let img = await blob(media).then(blobFile => new File([blobFile], `file.${blobFile.type.split('/')[1]}`, { type: blobFile.type }))
        const { src, url } = await API_GET_IMAGE_CONFIG({
          imageType: "broadcast",
        });
        
        const data= await axios.put(src, img,{
          headers: { "Content-Type": img.type },
        })  
        config={
          ...config,
          variables:{
            ...config.variables,
            mediaUrl:url
          }
        }

      }
      const {data}  = await API_ADD_CAMPAIGN(config,schedule);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

const startConversation = createAsyncThunk(
  "messageShare/startconversation",
  async ({ config}, thunkAPI) => {
    try {
      const {data}  = await API_START_CONVERSATION(config);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export { shareBroadcast ,startConversation};
