function updateTableData(currentList, updatedData) {
  const updatedList = currentList.map((item) => {
    if (item.id === updatedData.id) return updatedData;
    else return item;
  });
  return updatedList;
}
function updateProductTable(currentList, updatedData) {
  const updatedList = currentList.map((item) => {
    if (item.id === updatedData.id) return { ...item, ...updatedData };
    else return item;
  });
  return updatedList;
}

function removeDeleteData(currentList, deletedId) {
  const newList = currentList.filter((item) => item.id !== deletedId);
  return newList;
}

function getCampaignCustomers(campaign) {
  let customers = [...campaign.campaigncustomerconnectors];
  let campaignGroups = [...campaign.campaigngroupconnectors];
  for (let i = 0; i < campaignGroups.length; i++) {
    customers = [...customers, ...campaignGroups[0].customergroupconnectors];
  }
  return customers;
}
function selectElements(oldSelected,selectedElements,currElement){
  if (!oldSelected?.length) {
    oldSelected = selectedElements;
  } else {
    const newItems = selectedElements.filter((element) => {
      if (!isElementsInList(oldSelected, element))
        return element;
    });
    const unselectedElements = getUnselectedElements(
      currElement,
      selectedElements
    );

    const onlySelectedItems = oldSelected.filter(
      (element) => {
        if (!isElementsInList(unselectedElements, element)) return element;
      }
    );
    oldSelected = [...onlySelectedItems, ...newItems];
  }
  return oldSelected;
  
}


function isElementsInList(elements, element){
  for(let i =0; i<elements?.length; i++){
      if((elements[i].id == element.id)) return true
  }
  return false;
}

function getUnselectedElements(elementsArr, selectedElementsArr) {
  let unselectedElements = [];
  for(let i = 0; i<elementsArr?.length; i++){
    if(!isElementsInList(selectedElementsArr,elementsArr[i])) unselectedElements?.push(elementsArr[i])
  }
  return unselectedElements;
}


function isProductInList(productList, product){
    for(let i =0; i<productList.length; i++){
        if((productList[i].id == product.id)||(productList[i].SKUCode == product.SKUCode)) return true
    }
    return false;
}

function getUnselectedProducts(productsArr, selectedProductsArr) {
  let unselectedProducts = [];
  for(let i = 0; i<productsArr.length; i++){
    if(!isProductInList(selectedProductsArr,productsArr[i])) unselectedProducts.push(productsArr[i])
  }
  return unselectedProducts;
}

function isCustomerInList(cust, addcust){
  for(let i=0; i<cust.length; i++){
      if(cust[i].id == addcust.id) return true
  }
  return false;
}

function getUnselectedCust(cust, selectedcust) {
let unselectedCust = [];
for(let i = 0; i<cust.length; i++){
  if(!isProductInList(selectedcust,cust[i])){ unselectedCust.push(cust[i])}
}
return unselectedCust;
}

function getCustomersFromSelectedGroup(groupsArr, selectedGroupId){
  for(let i = 0;i<groupsArr.length; i++){
    if(groupsArr[i].id===selectedGroupId) return groupsArr[i].customerIds
  }
  return []
}

function getAlphabeticalSortedArr(dataArr){
   let sortedArr =  dataArr.sort(function (a, b) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
})
return sortedArr
}

function formatGoogleContacts(arr){
  let contacts = arr.map( element => {
    let contact = {};
    contact['name'] =  element.names && element.names.length!==0 ? element.names[0].displayName : "";
    contact['phone'] = element.phoneNumbers && element.phoneNumbers.length !== 0 ? `${element.phoneNumbers[0].canonicalForm}`.split('+')[1] : ""
    contact['email'] = element.emailAddresses && element.emailAddresses.length!==0 ? element.emailAddresses[0].value : ""
    return contact;
});

return contacts
}
function sortAlphabeticalObjectArr(data,fieldName){
  let sortedData = data.sort(function(a,b){
    return a[fieldName].toLowerCase().localeCompare(b[fieldName].toLowerCase())
  })
  return sortedData
}

function formatText(str){
  let firstChar = str.charAt(0).toUpperCase();
  let formatedStr = firstChar + str.slice(1);

  return formatedStr;
}

function dateCompiler(date){
  let x=new Date(date)
  const month=["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"]
  const dayOfWeek=['SUN','MON','TUE','WED','THU','FRI','SAT'];
  let str=`No Plan Found`
  if(x.getDate()&&month[x.getMonth()]&&x.getFullYear()){
    str=`${x.getDate()} ${month[x.getMonth()]} ${x.getFullYear()}`
  }
  return `${str}`
}

function capitalizeFirstLetter(sentence){
  if (typeof sentence !== 'string' || sentence?.length === 0) {
    return sentence; 
  }
  return sentence?.charAt(0)?.toUpperCase() + sentence?.slice(1);
}

const emptyContent=(value)=>{
  return value?<div className="text-sm font-normal">{value}</div>:<div>-</div>
}
export {
  updateTableData,
  removeDeleteData,
  updateProductTable,
  getCampaignCustomers,
  getUnselectedProducts,
  isProductInList,
  getCustomersFromSelectedGroup,
  getAlphabeticalSortedArr,
  formatGoogleContacts,
  formatText,
  sortAlphabeticalObjectArr,
  isCustomerInList,
  getUnselectedCust,
  dateCompiler,
  capitalizeFirstLetter,
  emptyContent,
  isElementsInList,
  getUnselectedElements,
  selectElements
};
