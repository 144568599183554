import React, { useState } from "react";
import { TabMenu } from "primereact/tabmenu";
import BussinessNumber from "../BussinessNumber";
import SupportAgents from "../Agents/SupportAgents";
import CampaignUsers from "../Agents/CampaignUsers";
import AdminUsers from "../Agents/AdminUsers";
import TextAutomation from "../TextAutomation";
import "./style.css";
import Notification from "../Notification";

function Tabview() {
  const [activeIndex, setActiveIndex] = useState(0);
  const items = [
    // { label: "Bussiness Numbers", id: "bussiness_number" },
    { label: "Notification", id: "notification" },
    { label: "Support Agents", id: "support" },
    { label: "Campaign Users", id: "campaign" },
    { label: "Admin Users", id: "admin" },
    { label: "Text Automation", id: "text_automation" },
  ];
  Object.freeze(items);

  const renderPage = (item_id) => {
    switch (item_id) {
      case "notification":
          return <Notification />;
      case "bussiness_number":
        return <BussinessNumber />;
      case "support":
        return <SupportAgents />;
      case "campaign":
        return <CampaignUsers />;
      case "admin":
        return <AdminUsers />;
      case "text_automation":
        return <TextAutomation />;
      default:
        return <BussinessNumber />;
    }
  };
  return (
    <>
      <div className="mt-3">
        <TabMenu
          model={items}
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
          className="custom-tabmenu-acc"
        />
      </div>
      <div className="p-3" style={{ background: "#F8F9FE" }}>
        {renderPage(items[activeIndex]?.id)}
      </div>
    </>
  );
}

export default Tabview;
