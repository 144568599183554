import { createSlice } from "@reduxjs/toolkit";
import {
  addTemplate,
  getTemplates,
  updateTemplate,
  deleteTemplate,
  getTemplatesbyId,
  getTemplatesbyappId,
} from "./templateActions";

const initialState = {
  loading: false,
  templateList: [],
  totalTemplatesCount: 0,
  selectedTemplate: null,
  pageNo: 0,
  limit: 30,
  templateMode: "create",
  templateFormModule: false,
  selectcampaignType:null,
};

const templateSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    changeTemplatePage(state, action) {
      state.pageNo = action.payload;
    },
    changeTemplateType(state, action) {
      state.selectcampaignType = action.payload;
    },
    changeTemplateLimit(state, action) {
      state.limit = action.payload;
    },
    changeSelectedTemplate(state, { payload }) {
      state.selectedTemplate = payload;
    },
    changeTemplateMode(state, { payload }) {
      state.templateMode = payload;
    },
    showTemplateForm(state) {
      state.templateFormModule = true;
    },
    closeTemplateForm(state) {
      state.templateFormModule = false;
      state.selectedTemplate = null;
      state.templateMode = "create";
    },
    resetSelectedTemplate(state) {
      state.selectedTemplate = null;
      state.templateMode = initialState.templateMode;
      state.selectcampaignType=null
    },
    addUTemplate(state,action){
      if(!(state.templateList.find((x)=>(x.templateId===action.payload.templateId)))){
        state.templateList=[action.payload,...state.templateList]
      }
    },
    resetPage(state){
      state.pageNo=initialState.pageNo;
      state.limit=initialState.limit;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTemplates.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.templateList = [...payload.rows];
      state.totalTemplatesCount = payload.count;
    });

    builder.addCase(getTemplates.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTemplates.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTemplatesbyId.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.selectedTemplate = payload;
    });

    builder.addCase(getTemplatesbyId.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTemplatesbyId.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTemplatesbyappId.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.selectedTemplate = payload;
    });

    builder.addCase(getTemplatesbyappId.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTemplatesbyappId.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addTemplate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.templateList = [payload.template, ...state.templateList];
      state.totalTemplatesCount += 1;
    });

    builder.addCase(addTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addTemplate.rejected, (state) => {
      state.loading = false;
    });
    //update template
    builder.addCase(updateTemplate.fulfilled, (state, payload) => {
      state.loading = false;
      state.templateList = state.templateList.map((item) => {
        if (item.id == payload.id) return payload;
        else return item;
      });
    });
    builder.addCase(updateTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTemplate.rejected, (state) => {
      state.loading = false;
    });
    //delete template
    builder.addCase(deleteTemplate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.totalTemplatesCount -= 1;
      state.templateList = state.templateList.filter(
        (item) => item.elementName != payload
      );
    });
    builder.addCase(deleteTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTemplate.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  changeTemplatePage,
  changeTemplateLimit,
  changeSelectedTemplate,
  changeTemplateMode,
  showTemplateForm,
  closeTemplateForm,
  resetSelectedTemplate,
  addUTemplate,
  changeTemplateType,
  resetPage,
} = templateSlice.actions;

export default templateSlice.reducer;
