import { CustomButton } from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import styles from "./style.module.css";
import { Text } from "../../components/Text";
import {
  API_FB_CREATE_APP,
  API_FB_APP_STATUS,
} from "../../api/account.service";
import { useEffect, useState } from "react";
import { ReactComponent as FacebookIcon } from "../../svg/Facebook.svg";
import { ReactComponent as DummyVideoIcon } from "../../svg/dummy-video.svg";

import { Divider } from "primereact/divider";
import { Link } from "react-router-dom";
import { fbStatus } from "./fb-constants";
import { subscription } from "../../reducers/authSlice";
import { useLayout } from "../../context/LayoutContext";
import Loader from "../../components/Loader";
function FbRegistrationModule() {
  const [appStatus, setStatus] = useState(fbStatus.PENDING);
  const { user } = useSelector((state) => state.authenticate);
  const [createAppLoading, setAppLoading] = useState(false);
  const { toastMassage, setLoading } = useLayout();

  const dispatch = useDispatch();

  const createAppCall = () => {
    setAppLoading(true);
    API_FB_CREATE_APP()
      .then((data) => {
        setAppLoading(false);
        window.open(data.link, "_blank");
      })
      .catch((err) => {
        setAppLoading(false);
        toastMassage("error");
      });
  };

  const handleOpenFBModule = () => {
    createAppCall();
  };

  useEffect(async () => {
    try {
      setLoading(true);
      const data = await API_FB_APP_STATUS();
      if (data.status !== fbStatus.PENDING && data?.companyId) {
        dispatch(subscription(user?.companyId))
          .unwrap()
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
        setStatus(data?.status || fbStatus.PENDING);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);
  return (
    <div className="flex justify-content-center align-items-center w-full">
      <div
        className={`my-3 w-full lg:w-8 pl-3 ${styles["fb-registration-module"]}`}
      >
        <Loader />
        <div className="fb-app-status  mb-3 mx-auto">
          <Text type={"heading"} color={"#58585A"} cust="flex flex-row gap-2 align-items-center">
            Your app status is :<br />
            <div className="flex">
              <div className={`py-2 px-5 ${styles["fb-status-tag"]}`}>
                <Text type={"heading"} color={"#060405"}>
                  {appStatus}
                </Text>
              </div>
            </div>
          </Text>
        </div>
        <Text type={"heading"}>
          Please follow the steps below to register your app on Facebook:
        </Text>
        <div className="my-3">
          <Text type={"sub-heading"}>1. New Phone Number</Text>
          <div className=" my-3">
            <Text color={"#1a1a1a80"} type={"small-text"}>
            Ensure you use a phone number that isn't already linked to WhatsApp during registration. This helps ensure a smooth registration process. 
            </Text>
          </div>
        </div>

        <Text type={"sub-heading"}>2. App Registration on Facebook</Text>
        <div className="my-3">
          <button
            className={`${styles["fb-regisation-btn"]} flex align-items-center gap-3 justify-content-center px-8 cursor-pointer py-3 border-0`}
            onClick={handleOpenFBModule}
          >
            {createAppLoading ? (
              <div className="text-center">
                <i className="pi pi-spinner" />
              </div>
            ) : (
              <>
                <FacebookIcon />
                <span>Continue with Facebook</span>
              </>
            )}
          </button>
        </div>

        <Text type={"sub-heading"}>
          3. After completing the Facebook registration, please refresh the page.
        </Text>

        <Divider />
        <div>
          <div className="flex align-items-center pl-2">
            <Text>For more clarity, watch this video.</Text>
            <CustomButton
              varient="text"
              icon={<DummyVideoIcon />}
              label={
              <Link to={`https://www.youtube.com/watch?v=8kICTq323DE`}
              style={{ textDecoration: "none" }}
              target="_">
                <Text
                  color={"#34629A"}
                  style={{ marginLeft: "10px", textDecoration: "underline" }}
                  >
                  Watch Video
                </Text>
                </Link>
              }
            />
          </div>

          <div
            className={`flex flex-wrap align-items-center justify-content-between p-3 ${styles["sales-help"]}`}
          >
            <div className="text-left">
              <div className="flex justify-content-end align-items-center">
                <Text type={"heading"} style={{ fontSize: "1.2rem" }}>
                  Priyanka&nbsp;Ganguly,
                </Text>
                <Text
                  style={{
                    fontSize: "1rem",
                    marginLeft: "0.4rem",
                    marginTop: "0.3rem",
                  }}
                >
                  Sales&nbsp;Manager
                </Text>
              </div>
              <Link
                to={`https://wa.me/${+919900064161}`}
                style={{ textDecoration: "none" }}
                target="_"
              >
                <div className="flex gap-1 align-items-center mt-1">
                  <i
                    className="pi pi-whatsapp"
                    style={{
                      fontSize: "0.8rem",
                      marginRight: "5px",
                      color: "#868C8A",
                    }}
                  />
                  <Text type={"sub-heading"} color={"#000000"}>
                    +91 9900064161
                  </Text>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* {fbRegistrationModule && (
          <CustomDialog
            visible={fbRegistrationModule}
            onHide={handleHideFBModule}
            header={<Text>Register on Facebook</Text>}
            className="h-full"
          >
            <div className="h-full">
              <iframe
                src={gupshupUrl}
                title="description"
                className={`w-full h-full ${styles[`fb-iframe`]}`}
              ></iframe>
            </div>
          </CustomDialog>
        )} */}
      </div>
    </div>
  );
}

export default FbRegistrationModule;
