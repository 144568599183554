import React from "react";
import styles from "./videoTour.module.css";

const VideoTour = () => {
  return (
    <div className={styles.container} id="videoTour">
      <div className={styles.subContainer}>
        <h1 className={styles.title}>Curious About SKALEBOT ? </h1>
        {/* <h1 className={styles.title}>Video Tour In Skalebot</h1> */}
        {/* <p className={styles.desc}>Discover Our Product more with Video</p> */}
        <p className={styles.desc}>
          Watch our quick demo Video ! Click to Discover More.
        </p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/c_Q_RwU57iM?si=uvVG2KeNbZJNfAeZ&rel=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className={styles.iframe}
        ></iframe>
      </div>
    </div>
  );
};

export default VideoTour;
