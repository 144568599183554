import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Text } from "../../components/Text";
import { CustomButton } from "../../components/CustomButton";
import { ReactComponent as Edit } from "../../svg/edit.svg";
import { ReactComponent as Delete } from "../../svg/delete.svg";
import { DeleteAlert } from "../../components/Alert/DeleteAlert";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedCategory,
  getCategories,
  changePage,
  changeMode,
  changelimit,
  resetCategoryList,
  changeSelectedCategories,
  bulkDeleteCategory,
  reseSelectedCategories,
} from "../../reducers/categoryTableSlice";

import { getDate } from "../../utils/datemaker";
import CustomPaginator from "../../components/CustomPaginator";
import { Dropdown } from "primereact/dropdown";
import DebounceSearch from "../../components/common/DebounceSearch";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import { useNavigate } from "react-router";
import { useLayout } from "../../context/LayoutContext";
import SklbtConfirmation from "../../components/common/SklbtConfirmation";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";

const CategoryList = () => {
  const [displayAlertDelete, setDisplayAlertDelete] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [bulkDeleteAlert, setBulkDeleteAlert] = useState(false);
  const { toast, setLoading } = useLayout(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    categoryData,
    page,
    limit,
    loading,
    totalCategoryCount,
    selectedCategories,
  } = useSelector((state) => state.categoryTable);

  const [filtersData, setFiltersData] = useState({
    status: null,
  });

  const [filters1, setFilters1] = useState({
    status: { value: null },
  });

  let stocks = ["Available", "Unavailable"];

  useEffect(() => {
    return () => {
      dispatch(resetCategoryList());
      dispatch(reseSelectedCategories());
    };
  }, []);

  const stockFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={stocks}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        itemTemplate={stockItemTemplate}
        placeholder="Select Category"
        className="p-column-filter"
        showClear
      />
    );
  };

  const stockItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };
  const onClearStatusFilter = () => {
    setFilters1((prev) => {
      return {
        ...prev,
        status: {
          ...prev.status,
          value: null,
        },
      };
    });

    setFiltersData((prev) => {
      return {
        ...prev,
        status: null,
      };
    });
  };

  const onClickFilter = (e) => {
    switch (e.field) {
      case "status":
        setFilters1((prev) => {
          return {
            ...prev,
            status: {
              ...prev.status,
              value: e.constraints.value,
            },
          };
        });

        setFiltersData((prev) => {
          return {
            ...prev,
            status: e.constraints.value,
          };
        });
        break;
      default:
        break;
    }
  };

  const managePaginationData = () => {
    let filterData = [];
    for (var key in filtersData) {
      if (filtersData[key]) {
        filterData.push({
          key: key,
          value: filtersData[key],
        });
      }
    }
    let paginationData = {
      page: page,
      limit: limit,
      filterData,
      globalFilterValue: inputValue,
    };
    return paginationData;
  };
  const { accesskey } = useSelector((state) => state.authenticate);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);
  const deleteModule = () => {
    return (
      <DeleteAlert
        item="category"
        displayAlertDelete={displayAlertDelete}
        setDisplayAlertDelete={setDisplayAlertDelete}
        toast={toast}
      />
    );
  };

  const handleEdit = (category) => {
    dispatch(changeSelectedCategory(category));
    dispatch(changeMode("update"));
    navigate(`/categories/${category.id}/update`);
  };

  const handleDelete = (category) => {
    dispatch(changeSelectedCategory(category));
    setDisplayAlertDelete(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        {accesskey[modulesName.CATEGORY]?.includes(permissions.UPDATE) && (
          <CustomButton
            varient="icon-button"
            onClick={() => handleEdit(rowData)}
            icon={<Edit />}
          />
        )}
        {accesskey[modulesName.CATEGORY]?.includes(permissions.DELETE) && (
          <CustomButton
            varient="icon-button"
            onClick={() => handleDelete(rowData)}
            icon={<Delete />}
          />
        )}
      </div>
    );
  };

  function handleSelectCategory(selectedData) {
    dispatch(changeSelectedCategories({ selectedData, categoryData }));
  }

  const handleBulkDelete = () => {
    const categoryIds = selectedCategories?.map((category) => category?.id);
    dispatch(bulkDeleteCategory(categoryIds))
      .unwrap()
      .then((res) => {
        setBulkDeleteAlert(false);
        toast.current.show({
          severity: "success",
          detail: `${res.length} Categories deleted successfully`,
          life: 3000,
        });
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          detail: err?.error || err?.message,
          life: 3000,
        });
      });
  };

  useEffect(() => {
    let paginationData = managePaginationData();
    dispatch(getCategories(paginationData))
      .unwrap()
      .catch(() => {
        //show toast from here
      });
  }, [page, limit, inputValue, filtersData]);

  return (
    <div className="p-3 ">
      {bulkDeleteAlert && (
        <SklbtConfirmation
          visible={bulkDeleteAlert}
          handleConfirm={handleBulkDelete}
          handleReject={() => setBulkDeleteAlert(false)}
          message={`Are you sure, you want to delete ${selectedCategories.length} categories?`}
        />
      )}
      <TableActionsContainer>
        <div className="flex justify-content-between align-items-center flex-wrap gap-3">
          <div className="flex justify-content-between align-items-center gap-2 flex-grow-1">
            <div className="flex align-items-center gap-2">
              <Text type={"heading"}>Category List</Text>
              {selectedCategories.length != 0 && (
                <CustomButton
                  varient="icon-button"
                  label={
                    <Text>({selectedCategories.length}) Delete Selected</Text>
                  }
                  onClick={() => setBulkDeleteAlert(true)}
                />
              )}
            </div>
            {accesskey[modulesName.CATEGORY]?.includes(permissions.CREATE) && (
              <div className="block  md:hidden lg:hidden xl:hidden ">
                <CustomButton
                  varient="filled"
                  label={"Add New Category"}
                  icon={"pi pi-plus"}
                  onClick={() => navigate("/categories/new")}
                />
              </div>
            )}
          </div>
          <div className="flex justify-content-between align-items-center gap-3 flex-wrap-reverse flex-grow-1 md:flex-grow-0 lg:flex-grow-0 xl:flex-grow-1:0">
            <DebounceSearch
              placeholder={"Search Category"}
              inputValue={inputValue}
              handleInputChange={(value) => setInputValue(value)}
            />
            {accesskey[modulesName.CATEGORY]?.includes(permissions.CREATE) && (
              <div className="hidden md:block lg:block xl:block">
                <CustomButton
                  varient="filled"
                  label={"Add New Category"}
                  icon={"pi pi-plus"}
                  onClick={() => navigate("/categories/new")}
                />
              </div>
            )}
          </div>
        </div>
      </TableActionsContainer>
      <div className="card my-3">
        {deleteModule()}
        <div className="card p-fluid">
          <DataTable
            value={categoryData}
            dataKey="id"
            responsiveLayout="scroll"
            resizableColumns
            columnResizeMode="expand"
            className="skalebot-table"
            filters={filters1}
            globalFilterFields={["categoryName", "status"]}
            selection={selectedCategories}
            onSelectionChange={(e) => handleSelectCategory(e.value)}
            selectionMode={accesskey[modulesName.CATEGORY]?.includes(permissions.DELETE)?"checkbox":""}
          >
            {accesskey[modulesName.CATEGORY]?.includes(permissions.DELETE) && (
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
                exportable={false}
              ></Column>
            )}
            <Column
              field="categoryName"
              header="Category Name"
              className="capitalize text-sm font-normal"
              style={{ height: "50px" }}
            ></Column>
            <Column
              field="status"
              style={{ maxWidth: "110px" }}
              header={(rowData) => (
                <div className="flex gap-2 align-items-center text-sm ">
                  <Text>Status</Text>
                </div>
              )}
              filter
              className="text-sm font-normal"
              showFilterMatchModes={false}
              filterElement={stockFilterTemplate}
              onFilterApplyClick={(e) => onClickFilter(e)}
              onFilterClear={onClearStatusFilter}
            ></Column>
            <Column
              field="createdAt"
              header="Date"
              className="text-sm font-normal"
              style={{ maxWidth: "110px" }}
              body={(rowData) => getDate(rowData.createdAt)}
              bodyStyle={{ textTransform: "uppercase" }}
            />
            {accesskey[modulesName.CATEGORY]?.includes(permissions.UPDATE) &&
              accesskey[modulesName.CATEGORY]?.includes(permissions.DELETE) && (
                <Column
                  header="Actions"
                  className="text-sm font-normal"
                  body={actionBodyTemplate}
                  style={{ width: "100px" }}
                ></Column>
              )}
          </DataTable>
        </div>
        <div className="mt-3">
          <TableActionsContainer>
            <div className="flex  justify-content-end">
              <CustomPaginator
                page={page}
                limit={limit}
                changelimit={changelimit}
                totalRecords={totalCategoryCount}
                changePage={changePage}
              />
            </div>
          </TableActionsContainer>
        </div>
      </div>
    </div>
  );
};

export default CategoryList;
