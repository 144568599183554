import icon1 from "../../assets/welcomeScreen/welcomeCardIcon/icon1.svg";
import bottom1 from "../../assets/welcomeScreen/welcomeCardIcon/bottom1.svg";
import icon2 from "../../assets/welcomeScreen/welcomeCardIcon/icon2.svg";
import bottom2 from "../../assets/welcomeScreen/welcomeCardIcon/bottom2.svg";
import icon3 from "../../assets/welcomeScreen/welcomeCardIcon/icon3.svg";
import bottom3 from "../../assets/welcomeScreen/welcomeCardIcon/bottom3.svg";
import icon4 from "../../assets/welcomeScreen/welcomeCardIcon/icon4.svg";
import bottom4 from "../../assets/welcomeScreen/welcomeCardIcon/bottom4.svg";
import icon5 from "../../assets/welcomeScreen/welcomeCardIcon/icon5.svg";
import bottom5 from "../../assets/welcomeScreen/welcomeCardIcon/bottom5.svg";
import icon6 from "../../assets/welcomeScreen/welcomeCardIcon/icon6.svg";
import bottom6 from "../../assets/welcomeScreen/welcomeCardIcon/bottom6.svg";

export const WelcomeCardData = [
  {
    icon: icon1,
    title: "Empower your business with SkaleBot",
    bottom: bottom1,
    hoverText:"Empowering diverse businesses to maximize growth through WhatsApp."
  },
  {
    icon: icon2,
    title: "Build a robust customer base & Generate new Leads",
    bottom: bottom2,
    hoverText:"Elevate customer loyalty and retention through personalized engagement."

  },
  {
    icon: icon3,
    title: "Integrate the Catalogue feature seamlessly.",
    bottom: bottom3,
    hoverText:"Seamless creation and sharing of personalized product catalogs, boosting sales performance."

  },
  {
    icon: icon4,
    title: "Unleash impactful Campaigns",
    bottom: bottom4,
    hoverText:"Create targeted campaigns for engagement and conversions. Track results, optimize strategies."

  },
  {
    icon: icon5,
    title: "Sales Agent & Order Management system",
    bottom: bottom5,
    hoverText:"Connect customers to sales agents for efficient support & Streamline order management for enhanced performance."


  },
  {
    icon: icon6,
    title: "Dashboard with Actionable insights",
    bottom: bottom6,
    hoverText:"Intelligent insights for smart business decisions and optimized performance."

  },
];
