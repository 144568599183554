import CustomPaginator from "../../components/CustomPaginator";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";
import {
  changeSelectedGroup,
  getCustomersInGroups,
  changeGroupPage,
  getGroups,
  resetSelectedGroup,
  changelimit,
} from "../../reducers/customerTableSlice";
import CreateGroup from "../../components/CreateGroup";
import { Toast } from "primereact/toast";
import { useNavigate, useParams } from "react-router-dom";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import { exportToExcel } from "../../utils/export";
import { CustomButton } from "../../components/CustomButton";

const GroupTable = ({ searchgroup = "" }) => {
  const {
    groups,
    limit,
    groupPage,
    groupCustomerPage,
    totalGroupCount,
    selectedGroup,
    selectedGroupID,
    customerData,
  } = useSelector((state) => state.customerTable);
  const { accesskey } = useSelector((state) => state.authenticate);

  const [showGroupModule, setShowGroupModule] = useState(false);

  const dispatch = useDispatch();
  const toast = useRef(null);

  const handleGroupSelect = (group) => {
    const groupId = group.id;
    if (selectedGroupID !== groupId) {
      dispatch(changeSelectedGroup(group));
      navigate(`/customers/${groupId}/gupdate/`);
    }
  };

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    const page = groupPage;
    dispatch(getGroups({ page, limit, globalFilterValue: searchgroup }))
      .unwrap()
      .catch((err) => {
        // toast here
      });
    return () => {
      dispatch(resetSelectedGroup());
    };
  }, [groupPage, limit, searchgroup]);

  useEffect(() => {
    if (id) {
      const page = groupCustomerPage;
      dispatch(
        getCustomersInGroups({
          id,
          page,
          limit,
        })
      )
        .unwrap()
        .catch((err) => {
          // toast here
        });
    }
  }, [selectedGroup, groupCustomerPage, limit]);

  const totalCustomer = (groupData) => {
    return groupData?.customerIds?.length;
  };

  const exportToCsv = (group) => {
    const selectedCustomerIds = group?.customerIds;
    let exportData = (customerData || [])
      .filter((item) => (selectedCustomerIds || []).includes(item.id))
      .map(({ customerCode, name, phone, email }) => ({
        "Customer Code": customerCode,
        Name: name || "",
        Phone: phone ? phone.toString() : "", // Ensure phone is converted to a string
        Email: email || "",
      }));
    exportToExcel(exportData, `Customer_group`);
  };
  return (
    <div>
      <Toast ref={toast} />
      {showGroupModule ? (
        <CreateGroup
          createGrpModule={showGroupModule}
          setCreateGrpModule={setShowGroupModule}
          toast={toast}
        />
      ) : (
        <></>
      )}

      <DataTable
        value={!id ? groups : [selectedGroup]}
        dataKey="id"
        responsiveLayout="scroll"
        className="skalebot-table"
        selectionMode="single"
        resizableColumns
        columnResizeMode="expand"
        // onSelectionChange={(e) => handleGroupSelect(e.value)}
      >
        <Column
          field="id"
          header="Id"
          className="text-sm font-normal"
          style={{ width: "3rem" }}
          body={(rowData, i) => {
            return (
              <div className="font-normal">
                {groupPage * limit + i.rowIndex + 1}
              </div>
            );
          }}
        />
        <Column
          header="Group Name"
          field="groupName"
          className="text-sm font-normal"
          bodyStyle={{ height: "50px", textTransform: "capitalize" }}
          body={(rowData, i) => {
            return (
              <div className="font-normal" onClick={() => handleGroupSelect(rowData)}>
                {rowData?.groupName}
              </div>
            );
          }}
        />
        <Column
          header="Total Customers"
          className="text-sm font-normal"
          style={{ width: "25ch" }}
          field={totalCustomer}
        />
        {accesskey[modulesName.CUSTOMERGROUP]?.includes(permissions.UPDATE) && (
          <Column
            style={{ width: "10ch" }}
            className="text-sm font-normal"
            bodyStyle={{ color: "#005068", minWidth: "120px" }}
            body={(rowData) => (
              <CustomButton
                varient="icon-button"
                onClick={()=>exportToCsv(rowData)}
                label={"Export"}
                disabled={rowData?.customerIds?.length === 0 ? true : false}
              />
            )}
          />
        )}
        {accesskey[modulesName.CUSTOMERGROUP]?.includes(permissions.UPDATE) && (
          <Column
            // body={"View Details"}
            style={{ width: "20ch" }}
            className="text-sm font-normal"
            bodyStyle={{ color: "#005068", minWidth: "120px" }}
            body={(rowData, i) => (
              <span
                onClick={() => handleGroupSelect(rowData)}
                style={{ cursor: "pointer", color: "#005068" }}
              >
                View Details
              </span>
            )}
          />
        )}
      </DataTable>
      <div className="mt-3">
        <TableActionsContainer>
          <div className="flex justify-content-end">
            <CustomPaginator
              page={groupPage}
              limit={limit}
              changelimit={changelimit}
              totalRecords={totalGroupCount}
              changePage={changeGroupPage}
            />
          </div>
        </TableActionsContainer>
      </div>
    </div>
  );
};

export default GroupTable;
