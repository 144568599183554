import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { CustomButton } from "../../components/CustomButton";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PdfDocument from "./../../components/OrderData/PdfDocument";
import axiosInstance from "../../api/axios.instance";

const OrderPreview = ({ previewOrder, setPreviewOrder }) => {
  const { selectedOrdersList } = useSelector((state) => state.orderTable);
  const { company } = useSelector((state) => state.company);

  const InvoiceData = {
    invoice_no: generateInvoiceNumber(),
    companyID: company?.id,
    logo: company?.url,
    companyName: company?.companyName,
    companyEmail: company?.email,
    companyAddress: company?.billingAddress,
    GSTIN: company?.gstNumber,
    companyPhone: `+${company?.personalPhone}`,
    orders: OrderList(),
  };

  function OrderList() {
    const data = [];
    selectedOrdersList.forEach((order, i) => {
      const orderItem = { ...order };
      orderItem["totalPrice"] = 0;
      order?.productItems.forEach((item, index) => {
        orderItem["totalPrice"] += item.item_price * item.quantity;
      });
      data.push(orderItem);
    });
    return data;
  }

  const fileName = `Invoice_${InvoiceData.invoice_no}.pdf`;

  function generateInvoiceNumber() {
    const min = 1000000000;
    const max = 9999999999;
    const invoiceNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return invoiceNumber;
  }

  const onHide = () => {
    setPreviewOrder(false);
  };

  return (
    <Dialog
      header="Order Preview"
      visible={previewOrder}
      onHide={() => onHide()}
      style={{ width: "90%" }}
    >
      <div>
        <div className="flex justify-content-center">
          <PDFViewer width={"100%"} height={500} showToolbar={true}>
            <PdfDocument invoicedata={InvoiceData} />
          </PDFViewer>
        </div>

        <div className="text-decoration-none flex justify-content-end mt-3">
          <PDFDownloadLink
            document={<PdfDocument invoicedata={InvoiceData} />}
            fileName={fileName}
            className="download-button"
            style={{ textDecoration: "none", color: "white" }}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading..."
              ) : (
                <div className="flex gap-2">
                  <CustomButton varient="filled" label={"Download"} />
                </div>
              )
            }
          </PDFDownloadLink>
        </div>
      </div>
    </Dialog>
  );
};

export default OrderPreview;
