import React from 'react'
import { ReactComponent as SkalebotLogo } from "../../assets/svgIcons/Skalebot.svg"
import styles from "./style.module.css";

const Header = () => {
  return (
    <div className={`w-full px-6 py-3 ${styles.header} `}  > 
        <SkalebotLogo className={`${styles["logo-css"]} `} />
              <span
                style={{
                  marginLeft: "5px",
                  marginTop: "3px",
                  fontSize: "0.7rem",
                  marginRight:"5px",
                  color:"#fff"
                }}
              >
                Pro
              </span>
    </div>
  )
}

export default Header