import { Image } from "primereact/image";
import { CANVAS_NODE_ICONS, CANVAS_NODE_TITLES } from "./node_constant.js";
import styles from "./stylesheets/nodeheader.module.css";
import { Text } from "../Text";

export const NodeHeader = ({ nodeType }) => {
  return (
    <div>
      <div className="flex gap-2">
        <div className={`p-2 ${styles.icon_conatiner}`}>
          <Image src={CANVAS_NODE_ICONS[nodeType]} />
        </div>
        <div>
          <Text type={"small-text"} color={"#303030"}>
            Bot Response
          </Text>
          <Text type={"sub-heading"} cust="mt-1">
            {CANVAS_NODE_TITLES[nodeType]}
          </Text>
        </div>
      </div>
    </div>
  );
};
