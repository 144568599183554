import React from 'react'
import styles from "./salesTeam.module.css"
import phoneSvg from "../../assets/welcomeScreen/socialLogos/phone.svg";
import mailSvg from "../../assets/welcomeScreen/socialLogos/mail.svg";
import { SalesTeamData } from '../../assets/welcomeScreen/SalesTeamData';
const SalesTeam = () => {
    return (
        <div className={styles.container} id="team">
          <div className={styles.subContainer}>
            <h1 className={styles.heading}>Our sales team</h1>
            <h1 className={styles.subHeading}>
              We are here to help with any questions
            </h1>
            <div className={`${styles.scrollableContainer}`}>
              <div className={styles.cardContainer}>
                {SalesTeamData.map((item, index) => {
                  return (
                    <div className={styles.card} key={index}>
                      <img
                        src={item.img}
                        alt="alosiaSvg"
                        className={styles.cardImg}
                      />
                      <h3 className={styles.cardName}>{item.name}</h3>
                      <h3 className={styles.cardPosition}>{item.position}</h3>
                      <div className={styles.contactDetailsContainer}>
                        <div className={styles.contactContainer}>
                          <img src={phoneSvg} alt="phoneSvg" />
                          <p className={styles.contactDetails}>{item.mobileNo}</p>
                        </div>
                        <div className={styles.contactContainer}>
                          <img src={mailSvg} alt="mailSvg" />
                          <p className={styles.contactDetails}>{item.mailId}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
}

export default SalesTeam