import { useForm, Controller } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";
import { CustomButton } from "../../components/CustomButton";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCampaignName,
  changeselectedCustomers,
  changeselectedGroupId,
  changestepcount,
  hideShareMessageDialog,
  resetBroadcast,
  setBroadcastDetails,
  setCampDetails,
  setMode,
  setScheduler,
  showShareMessageDialog,
  stepChange,
} from "../../reducers/ShareMessage/messageSlice";
import TemplatePreview from "../../components/TemplatePreview";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { getIds } from "../../utils/shareUtils";
import { categoryFilterOptions } from "../../utils/templateUtils";
import { templateFilterOptions } from "../../utils/templateUtils";
import {
  templateTypes,
  formateMessageInput,
  validateMediaSize,
  blob,
} from "../../utils/templateUtils";
import { useState, useRef } from "react";
import { Text } from "../../components/Text";
import { useEffect } from "react";
import { Badge } from "primereact/badge";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import {
  getTemplates,
  getTemplatesbyId,
} from "../../reducers/templateReducer/templateActions";
import { Column } from "primereact/column";
import { getDate } from "../../utils/datemaker";
import CustomPaginator from "../../components/CustomPaginator";
import { DataTable } from "primereact/datatable";
import {
  addUTemplate,
  changeTemplateLimit,
  changeTemplatePage,
} from "../../reducers/templateReducer/templateSlice";
import {
  changeSelectedProductBlist,
  changeSelectedProductsList,
  resetAll,
} from "../../reducers/productTableSlice";
import {
  getCampaignById,
  getCampaignCustomers,
  getCampaignsAnalytics,
} from "../../reducers/campaignSlice";
import {
  changeSelectedGroup,
  updateCustomer,
  updatecustomers,
} from "../../reducers/customerTableSlice";
import axios from "axios";
import { capitalizeFirstLetter } from "../../utils/tableUtils";
import { useUtility } from "../../context/UtilityContext";
const addsteps = (name, value, url, show = true, panel = false) => {
  return { name, value, url, show, panel };
};

export const SelectTemplate = ({ toast }) => {
  const [tempVariables, setTempvariables] = useState([]);
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [documentPreview, setDocumentPreview] = useState(null);
  const [templateButtons, setTemplateButtons] = useState([]);
  const [filters1, setFilters1] = useState({
    category: { value: "" },
    templateType: { value: "" },
  });
  const { completeSteps, selectedTemplate, mode, campDetails } = useSelector(
    (state) => state.shareMessage
  );
  const { id } = useParams();
  if (selectedTemplate) {
    var { templateType, campaignType } = selectedTemplate;
  }
  useEffect(() => {
    // console.log(selectedTemplate)
  }, [selectedTemplate]);

  const defaultValues = {
    campaignName: "",
    templateType: "",
  };

  const {
    templateList,
    totalTemplatesCount,
    loading,
    pageNo,
    limit,
    selectcampaignType,
  } = useSelector((state) => state.template);

  const { campaignName } = useSelector((state) => state.shareMessage);
  const { selectedCampaign, campaignLoading, stats } = useSelector(
    (state) => state.campaign
  );

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    reset,
    setValue,
  } = useForm(defaultValues);

  //function form get error message
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setValue("campaignName", campaignName, {
      shouldValidate: campaignName.length > 0,
    });
  }, [campaignName]);

  const steps = [];
  steps.push(
    addsteps(
      "Information",
      "info",
      `${id ? `/runcampaign/${id}` : `/runcampaign/new`}`,
      true,
      true
    )
  );
  steps.push(
    addsteps(
      "Edit Template",
      "info",
      `${
        id
          ? `/runcampaign/selecttemplate/${id}`
          : `/runcampaign/selecttemplate/new`
      }`
    )
  );
  steps.push(
    addsteps(
      "Select Product",
      "prod",
      `${
        id
          ? `/runcampaign/selectproducts/${id}`
          : `/runcampaign/selectproducts/new`
      }`,
      campaignType === "product"
    )
  );
  steps.push(
    addsteps(
      "Select Customer",
      "cust",
      `${
        id
          ? `/runcampaign/selectcustomer/${id}`
          : `/runcampaign/selectcustomer/new`
      }`
    )
  );
  steps.push(
    addsteps(
      "Schedule Campaign",
      "camp",
      `${id ? `/runcampaign/schedule/${id}` : `/runcampaign/schedule/new`}`
    )
  );

  useEffect(() => {
    // console.log(completeSteps)
    if (id && completeSteps === 0) {
      dispatch(getCampaignById(id)).then((e) => {
        const details = e?.payload;

        dispatch(getTemplatesbyId(details.templateId))
          .unwrap()
          .then((x) => {
            dispatch(showShareMessageDialog({ selectedTemplate: { ...x } }));
            if (x) {
              dispatch(addUTemplate(x));
            }
            if (x?.templateType !== "TEXT" || x?.templateType !== "text") {
              const { mediaId } = JSON.parse(x.containerMeta);
              loadMedia(
                `https://api.gupshup.io/wa/${x.appId}/wa/media/${mediaId}?download=false`
              );
            } //loading the sample media
          }); // setting the template
        dispatch(changeCampaignName(details.campaignName)); //setting the camp name
        setValue("campaignName", details.campaignName, {
          shouldValidate: true,
        }); //setting the camp name to form
        dispatch(setCampDetails(details)); //setting the template form
        if (details?.productItems.length > 0) {
          let tempskuCode = details?.productItems.map((x) => {
            return x.SKUCode;
          });
          dispatch(
            setBroadcastDetails({
              configData: { ...details, SKUCodes: tempskuCode },
              media: details?.variables?.mediaUrl
                ? details?.variables?.mediaUrl
                : null,
            })
          );
        } else {
          dispatch(
            setBroadcastDetails({
              configData: details,
              media: details?.variables?.mediaUrl
                ? details?.variables?.mediaUrl
                : null,
            })
          );
        }

        // Commented because its no use and increasing the complexity not reusing sechedule campaign
        // if(id[0]==='s'){
        //       if (details?.dayOfWeek?.length > 0) {
        //           dispatch(setScheduler({
        //               dayOfWeek: details?.dayOfWeek,
        //               startDate: details?.startDate,
        //               endDate: details?.endDate,
        //               runTime: details?.runTime,
        //             }));
        //         }
        //   }
        dispatch(
          setBroadcastDetails({
            configData: {
              ...details,
              id: undefined,
            },
            media: details?.variables?.mediaUrl
              ? details?.variables?.mediaUrl
              : null,
          })
        );
      });
      dispatch(changestepcount(4));
    }
  }, [id]);

  const loadMedia = (url) => {
    if (url) {
      blob(url)
        .then((blobFile) => {
          return new File([blobFile], `file.${blobFile.type.split("/")[1]}`, {
            type: blobFile.type,
          });
        })
        .then((e) => {
          const reader = new FileReader();
          const selectedFile = e;
          if (selectedFile) {
            setFile(selectedFile);
            reader.readAsDataURL(selectedFile);
            reader.onload = (readerEvent) => {
              if (selectedFile.type.includes("image")) {
                setImagePreview(readerEvent.target.result);
              } else if (selectedFile.type.includes("video")) {
                setVideoPreview(readerEvent.target.result);
              } else if (selectedFile.type.includes("pdf")) {
                setDocumentPreview(readerEvent.target.result);
              }
            };
          }
        })
        .catch((e) => {
          toast?.current?.show({
            severity: "error",
            detail: "Unable To Fetch File",
          });
        });
    }
  };

  // handle sumbit formdata
  const onSubmit = (data) => {
    dispatch(changeCampaignName(data.campaignName));
    if (!id) dispatch(changestepcount(1));
    navigate(
      `${
        id
          ? `/runcampaign/selecttemplate/${id}`
          : `/runcampaign/selecttemplate/new`
      }`
    );
  };

  useEffect(() => {
    if (selectedTemplate != null) {
      const { containerMeta, variables, templateType } = selectedTemplate;
      if (containerMeta) {
        const { header, buttons, footer, data, sampleMedia, mediaId } =
          JSON.parse(containerMeta);
        if (templateType.toUpperCase() !== "TEXT") {
          axios
            .get(
              `https://api.gupshup.io/wa/${selectedTemplate.appId}/wa/media/${mediaId}?download=false`,
              { responseType: "blob" }
            )
            .then((res) => {
              return res.data;
            })
            .then((e) => {
              return new File([e], `file.${e.type.split(`/`)[1]}`, {
                type: e.type,
              });
            })
            .then((e) => {
              const reader = new FileReader();
              const selectedFile = e;
              if (selectedFile) {
                setFile(selectedFile);
                reader.readAsDataURL(selectedFile);
              }
              // console.log(selectedFile.type)
              reader.onload = (readerEvent) => {
                if (selectedFile.type.includes("image")) {
                  setImagePreview(readerEvent.target.result);
                } else if (selectedFile.type.includes("video")) {
                  setVideoPreview(readerEvent.target.result);
                } else if (selectedFile.type.includes("pdf")) {
                  setDocumentPreview(readerEvent.target.result);
                }
              };
            })
            .catch((e) => {
              console.log(e);
            });
        }
        let messStr = data || "";
        let messageNumber = 1;
        if (variables && variables.length != 0) {
          const templVars = variables?.map((item, index) => {
            messStr = messStr.replace(
              item.variableName,
              `{{${
                item.variableType == "customNote"
                  ? `message${messageNumber}`
                  : item.variableType
              }}}`
            );
            return {
              ...item,
              variableName:
                item.variableType == "customNote"
                  ? `{{message${messageNumber++}}}`
                  : `{{${item.variableType}}}`,
              id: Math.floor(1000 + Math.random() * 9000),
              proxyId: item.variableName,
              variableValue: item.variableValue || "",
            };
          });
          setTempvariables(templVars);
        }
        if (buttons != undefined) {
          const tempButtons = buttons?.map((item) => ({
            ...item,
            id: Math.floor(1000 + Math.random() * 9000),
            isSaved: true,
          }));
          setTemplateButtons(tempButtons);
        }

        setValue("message", messStr);
        setValue("header", header || "");
        setValue("footer", footer || "");
        setValue("templateType", templateType);
      }
    }
    return () => {
      setImagePreview(null);
      setVideoPreview(null);
      setDocumentPreview(null);
    };
  }, [selectedTemplate]);

  const switchform = (link) => {
    navigate(link);
  };
  const navigate = useNavigate();
  const itemslist = [{ label: "Run Campaign", url: "" }];
  const isValidTemplate = (data) => {
    const { status } = data;
    return status == "APPROVED";
  };
  const rowClassName = (data) => {
    return id || isValidTemplate(data) ? "" : "not-selectable";
  };
  const isRowSelectable = (e) => {
    return id || isSelectable(e.data);
  };
  const isSelectable = (data) => {
    const { status, id } = data;
    return status === "APPROVED" && selectedTemplate?.id !== id;
  };

  const onClearCategoryFilter = () => {
    setFilters1((prev) => {
      return {
        ...prev,
        category: { ...prev.category, value: "" },
      };
    });
  };
  const onClearTemplateTypeFilter = () => {
    setFilters1((prev) => {
      return {
        ...prev,
        templateType: { ...prev.templateType, value: "" },
      };
    });
  };

  const onApplyClick = (e) => {
    switch (e.field) {
      case "category":
        setFilters1((prev) => {
          return {
            ...prev,
            category: { ...prev.category, value: e.constraints.value },
          };
        });
        break;
      case "templateType":
        setFilters1((prev) => {
          return {
            ...prev,
            templateType: { ...prev.templateType, value: e.constraints.value },
          };
        });
        break;
      default:
        break;
    }
  };

  const filterItemTemplate = (option) => {
    return <span className="capitalize">{option.toLowerCase()}</span>;
  };
  const categoryFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={categoryFilterOptions}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        itemTemplate={filterItemTemplate}
        placeholder="Select Category"
        className="p-column-filter"
      />
    );
  };
  const templateTypeFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={templateFilterOptions}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        itemTemplate={filterItemTemplate}
        placeholder="Select type"
        className="p-column-filter"
      />
    );
  };

  useEffect(() => {
    if (!id) {
      dispatch(
        getTemplates({
          pageNo,
          limit,
          status: "APPROVED",
          campaignType: selectcampaignType,
          category: filters1.category.value,
          templateType: filters1.templateType.value,
        })
      )
        .unwrap()
        .catch((err) => {
          console.log(err);
        });
    }
  }, [pageNo, limit, filters1]);

  const templateActiopns = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <CustomButton
          varient="outline"
          label={"Preview Template"}
          onClick={(e) => {
            e.preventDefault();
            onSelectProduct(rowData);
          }}
          disabled={!(id || rowData.status === "APPROVED")}
        />
      </div>
    );
  };
  useEffect(() => {
    if (!id && selectedTemplate === null && templateList?.length > 0) {
      dispatch(
        showShareMessageDialog({
          title: "",
          selectedTemplate: templateList[0],
        })
      );
    }
  }, [selectedTemplate, templateList]);
  const onSelectProduct = (data) => {
    dispatch(resetBroadcast());
    dispatch(
      showShareMessageDialog({
        title: "",
        selectedTemplate: data,
      })
    );
  };
  const { navigation, setAlert } = useUtility();

  const cancelBroadcast = () => {
    dispatch(resetBroadcast());
    dispatch(resetAll());
    navigation(`${id ? `/campaigns/${id}` : `/campaigns`}`);
  };

  return (
    <div className="w-12  m-auto ">
      <div className="w-full m-auto overflow-x-auto">
        <CustomBreadcrumb
          home={{ label: "Campaign", url: "/campaigns" }}
          itemslist={itemslist}
        />
      </div>
      <div className="p-3 pt-1 ">
        <div
          className="w-full m-auto flex flex-row justify-content-between align-items-center  my-2 overflow-x-auto border-round-sm "
          style={{ background: "#f2f2f2" }}
        >
          <div className="p-2 py-3 flex flex-row ">
            {steps.map((x, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-row gap-2 mr-3 cursor-pointer"
                  onClick={() => {
                    index <= completeSteps && x.show && switchform(x.url);
                  }}
                >
                  <div>
                    <Badge
                      value={index + 1}
                      style={{
                        background: index <= completeSteps ? `#1c738e` : `grey`,
                        opacity: `${x.show ? `1` : `.5`}`,
                      }}
                    />
                  </div>
                  <div
                    className={`white-space-nowrap  ${
                      x.panel ? `border-none border-bottom-1 border-solid` : ``
                    }`}
                    style={{ borderColor: "#1c738e" }}
                  >
                    {x.name}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-row gap-2 align-items-center px-2">
            <div
              className="font-medium text-sm cursor-pointer"
              onClick={cancelBroadcast}
            >
              Cancel
            </div>
            <div>
              <CustomButton
                varient="plain-button w-full"
                type="reset"
                onClick={() => {
                  dispatch(resetBroadcast());
                  navigate(`${id ? `/campaigns/${id}` : `/campaigns`}`);
                }}
                label={"Back"}
              />
            </div>
            <div>
              <CustomButton
                varient="filled w-full"
                type="submit"
                onClick={handleSubmit(onSubmit)}
                label={<span>Save&nbsp;&&nbsp;Continue</span>}
                disabled={!(isValid && selectedTemplate)}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full flex-wrap pt-3">
          <div className="flex flex-column w-8 flex-grow-1 lg:flex-grow-0 xl:flex-grow-0 justify-content-between">
            <form className="p-fluid w-full">
              <div className="field">
                <label htmlFor="campaignName">Campaign Name *</label>
                <Controller
                  name="campaignName"
                  control={control}
                  rules={{
                    required: "Campaign name is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      maxLength={50}
                      value={field.value}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      placeholder="Enter campaign name"
                      {...field}
                      onChange={(e) => {
                        setAlert(true);
                        field.onChange(capitalizeFirstLetter(e.target.value));
                      }}
                    />
                  )}
                />
                {getFormErrorMessage("campaignName")}
              </div>
              <DataTable
                value={id ? [selectedTemplate] : [...templateList]}
                dataKey="id"
                className="skalebot-table"
                rowClassName={rowClassName}
                selection={selectedTemplate}
                onSelectionChange={(e) => {
                  setAlert(true);
                  !id && onSelectProduct(e.value);
                }}
                isDataSelectable={isRowSelectable}
                filters={filters1}
                filterDisplay="menu"
                globalFilterFields={["category", "templateType"]}
                removableSort
              >
                <Column
                  selectionMode="single"
                  headerStyle={{ width: "3rem" }}
                  header={`Select`}
                  exportable={false}
                ></Column>
                <Column
                  field="elementName"
                  header="Name"
                  sortable
                  className="capitalize text-sm font-normal"
                  style={{ height: "50px" }}
                />
                <Column
                  field="category"
                  header="Category"
                  filter
                  className="text-sm font-normal"
                  showFilterMatchModes={false}
                  showFilterMenuOptions={false}
                  filterElement={categoryFilterTemplate}
                  onFilterApplyClick={(e) => onApplyClick(e)}
                  onFilterClear={onClearCategoryFilter}
                  body={(rowData) => (
                    <Text cust="capitalize">
                      {rowData?.category?.toLowerCase()}
                    </Text>
                  )}
                />
                <Column
                  field="templateType"
                  header="Template Type"
                  filter
                  className="text-sm font-normal"
                  showFilterMatchModes={false}
                  showFilterMenuOptions={false}
                  filterElement={templateTypeFilterTemplate}
                  onFilterApplyClick={(e) => onApplyClick(e)}
                  onFilterClear={onClearTemplateTypeFilter}
                  body={(rowData) => (
                    <Text cust="capitalize">
                      {rowData?.templateType?.toLowerCase()}
                    </Text>
                  )}
                />
                <Column
                  field="status"
                  header="Status"
                  className="text-sm font-normal"
                  body={(rowData) => (
                    <Text cust="capitalize">
                      {rowData?.status?.toLowerCase()}
                    </Text>
                  )}
                />
              </DataTable>
              <div className="flex flex-row justify-content-end mb-6">
                {!id && (
                  <CustomPaginator
                    page={pageNo}
                    limit={limit}
                    totalRecords={totalTemplatesCount}
                    changePage={changeTemplatePage}
                    changelimit={changeTemplateLimit}
                  />
                )}
              </div>
            </form>
          </div>
          <div
            className={`flex flex-grow-1 justify-content-center mob-preview-container `}
            style={{ minHeight: "620px" }}
          >
            <div className="relaive w-full flex justify-content-center">
              <div className="static lg:fixed xl:fixed">
                <div className="flex flex-column justify-content-center align-items-center">
                  {selectedTemplate && (
                    <div className="min-h-full">
                      <TemplatePreview
                        header={watch("header")}
                        footer={watch("footer")}
                        buttons={templateButtons}
                        content={formateMessageInput(
                          watch("message"),
                          "preview",
                          tempVariables
                        )}
                        videoPreview={videoPreview}
                        documentPreview={documentPreview}
                        imagePreview={imagePreview}
                        file={file}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
