import styles from "./styles.module.css";
import Canvas from "../Canvas";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import SidePanel from "../../../components/SidePanel";
import { Text } from "../../../components/Text";
import { CustomButton } from "../../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import {
  addBotFlowSteps,
  changeBotMode,
  changeSelectectBotFlow,
  closeConfigScreen,
  getBotFlow,
  resetCanvas,
  undoNodeData,
  updateBotFlow,
} from "../../../reducers/canvas/canvasSlice";
import SklbtConfirmation from "../../../components/common/SklbtConfirmation";
import { Toast } from "primereact/toast";
import {
  handleSequenceAndStepId,
  isConnected,
  remodeIdFromNodeList,
} from "../../../utils/canvasUtils";
import { tailNodes } from "../../../components/CustomNodes/node_constant";
import { NON_EDIT_BOTFLOW_MESSAGE, isAllNodeValid } from "./node-constant";
import { useLayout } from "../../../context/LayoutContext";
import Notice from "../../../components/Alert/Notice";
import { BOT_MODES } from "../bot-constants";
const breadcumbHome = { label: "Bot Flow", url: "/bots/list" };
const breadcumnList = [
  {
    label: `Update Bot Flow`,
    url: "#",
  },
];

function BotBuilder() {
  const {
    isEditable,
    nodes,
    edges,
    rootStepId,
    configScreenOpen,
    botFlowExists,
    selectedBotFlowId,
  } = useSelector((state) => state.canvas);

  const { toast, setLoading } = useLayout(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [botPublishAlert, setBotPublishAlert] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetCanvas());
    };
  }, []);

  const areNodesConnected = () => {
    const isReadyToPublish = isConnected(nodes, edges, rootStepId);
    if (!isReadyToPublish) {
      toast.current.show({
        severity: "warn",
        detail:
          "Please make sure all nodes are connected before publishing the Bot Flow.",
      });
    }
    return isReadyToPublish;
  };

  const handlePublishBotflow = () => {
    if (!areNodesConnected()) {
      setBotPublishAlert(false);
      return;
    }

    let isAllNodeshavevalidData = isAllNodeValid(nodes);

    if (!isAllNodeshavevalidData) {
      toast.current.show({
        severity: "warn",
        detail: `Please make sure all nodes must have valid information.`,
      });
      return;
    } else {
      let nodesObject = [];

      nodes.forEach((node) => {
        let nodeId = node.id;
        let flowData = {
          stepId: nodeId,
          id: nodeId,
          actions: [],
          sequence: 1,
        };

        switch (node.type) {
          case "quick_reply":
            flowData.message = {
              type: node.type,

              options: remodeIdFromNodeList(node.data.options),
            };

            if (node.data?.image) {
              flowData.message = {
                ...flowData.message,
                msgId: "textmsg",
                caption: "Skalebot",
                content: {
                  type: "image",
                  text: node.data.label,
                  url: node.data.image,
                },
              };
            } else if (node.data?.video) {
              flowData.message = {
                ...flowData.message,
                msgId: "textmsg",
                caption: "Skalebot",
                content: {
                  type: "video",
                  text: node.data.label,
                  url: node.data.video,
                },
              };
            } else {
              flowData.message = {
                ...flowData.message,
                content: {
                  type: "text",
                  text: node.data.label,
                },
              };
            }
            break;
          case "list":
            flowData.message = {
              body: node.data.body,
              type: "list",
              msgId: "list1",
              globalButtons: [
                {
                  type: "text",
                  title: node.data.globalButton,
                },
              ],
              items: [
                {
                  title: node.data.listHeading,
                  options: remodeIdFromNodeList(node.data.options),
                },
              ],
            };

            break;
          case "text":
            if (node.data.type === "image") {
              flowData.message = {
                type: "image",
                caption: node.data?.label || "",
                previewUrl: node.data.image,
                originalUrl: node.data.image,
              };
            } else if (node.data.type === "video") {
              flowData.message = {
                type: "video",
                caption: node.data?.label || "",
                url: node.data.video,
              };
            } else if (tailNodes.includes(node.data.type)) {
              flowData = {
                ...flowData,
                actions: node.data?.actions || [],
              };
            } else {
              flowData.message = {
                type: "text",
                text: node.data?.label || "",
              };
            }
            break;
          case "http":
            flowData.httpRequest = {
              type: node.data.method,
              url: node.data.url,
              body: node.data.body,
              headers: node.data.headers,
            };
            break;
          case "template":
            flowData.template = {
              templateId: node.data.templateId,
              options: remodeIdFromNodeList(node.data.options),
              templateName: node.data.templateName,
            };
            break;
          case "pattern":
            flowData.pattern = node.data.label;
            break;
          default:
            // no action
            break;
        }

        nodesObject.push(flowData);
      });

      nodesObject = handleSequenceAndStepId(nodesObject, edges, rootStepId);
      const botFlowSteps = {
        steps: remodeIdFromNodeList(nodesObject),
        rootStepId,
      };
      setLoading(true);
      dispatch(addBotFlowSteps({ id: selectedBotFlowId, botFlowSteps }))
        .unwrap()
        .then(() => {
          toast.current.show({
            severity: "success",
            detail: "New botflow is deployed successfully!",
          });
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            detail: err?.error || err?.message,
          });
        })
        .finally(() => {
          setLoading(false);
          setBotPublishAlert(false);
        });
    }
  };

  const handleBotSave = () => {
    const botFlowData = {
      nodes,
      edges,
      rootStepId,
    };

    setLoading(true);
    dispatch(updateBotFlow({ id: selectedBotFlowId, botFlowData }))
      .unwrap()
      .then(() => {
        setLoading(false);

        toast.current.show({
          severity: "success",
          detail: "Bot flow is saved!",
        });
        dispatch(closeConfigScreen());
      })
      .catch((err) => {
        setLoading(false);
        toast.current.show({
          severity: "error",
          detail: err?.error || err?.message,
        });
      });
  };

  const handleCancel = () => {
    dispatch(undoNodeData());
    dispatch(closeConfigScreen());
  };

  const getCurrentBotFlow = (id) => {
    setLoading(true);
    dispatch(getBotFlow(id))
      .unwrap()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        toast.current.show({
          severity: "error",
          detail: err?.error || err?.message,
        });
      });
  };

  useEffect(() => {
    if (id) {
      getCurrentBotFlow(id);
      dispatch(changeSelectectBotFlow(id));
    }

    return () => {
      dispatch(changeSelectectBotFlow(null));
      dispatch(changeBotMode(BOT_MODES.NEW));
    };
  }, []);

  return (
    <div className={`w-full h-full relative ${styles["bot-builder"]}`}>
      <Toast ref={toast} />
      {botPublishAlert && (
        <SklbtConfirmation
          visible={botPublishAlert}
          handleConfirm={handlePublishBotflow}
          handleReject={() => setBotPublishAlert(false)}
          message={`Are you sure? You want to save and publish this botflow.`}
        />
      )}
      <div>
        <CustomBreadcrumb home={breadcumbHome} itemslist={breadcumnList} />
      </div>
      {!isEditable && <Notice message={NON_EDIT_BOTFLOW_MESSAGE} />}

      <div className={`my-2 mx-3 p-2 ${styles["control-panel"]}`}>
        <div className="flex gap-3 justify-content-between align-items-center flex-wrap">
          <Text type={"heading"}>
            {botFlowExists ? "Update" : "Create New"} Bot Flow
          </Text>
          <div>
            <div className=" flex gap-2">
              <CustomButton
                varient="text"
                label={"Cancel"}
                onClick={() => navigate("/bots")}
              />
              <CustomButton
                varient="btn cancel"
                onClick={() => dispatch(resetCanvas())}
                label={"Reset"}
                disabled={!isEditable}
              />
              <CustomButton
                varient="outline"
                label={"Save"}
                onClick={handleBotSave}
                disabled={!isEditable}
              />
              <CustomButton
                varient="filled"
                onClick={() => setBotPublishAlert(true)}
                label={"Publish"}
                disabled={!isEditable}
              />
            </div>
          </div>
        </div>
      </div>
      <Canvas />
      {configScreenOpen && (
        <SidePanel handleSave={handleBotSave} handleCancel={handleCancel} />
      )}
    </div>
  );
}

export default BotBuilder;
