import axiosInstance from "./axios.instance";

const API_SEND_NOTIFICATION = async (message) =>{
    try{
        const resp = await axiosInstance.post('/notification',message)

        return resp;
    }catch(err){
        throw err
    }

}

const API_GET_NOTIFICATION_PREF = async () =>{
    try{
        const resp = await axiosInstance.get('/notification-preference')
        return resp.data;
    }catch(err){
        throw err
    }

}


const API_GET_NOTIFICATION_PREF_ID = async (id) =>{
    try{
        const resp = await axiosInstance.get(`/notification-preference/${id}`)
        return resp.data;
    }catch(err){
        throw err
    }

}

const API_SET_NOTIFICATION_PREF = async (id,data) =>{
    try{
        const resp = await axiosInstance.put(`/notification-preference/${id}`,data)
        return resp;
    }catch(err){
        throw err
    }

}

const API_SET_NOTIFICATION_PREF_BULK = async (data) =>{
    try{
        const resp = await axiosInstance.put(`/notification-preference/bulk`,data)
        return resp;
    }catch(err){
        throw err
    }

}


export {
         API_SEND_NOTIFICATION,
         API_GET_NOTIFICATION_PREF,
         API_SET_NOTIFICATION_PREF,
         API_GET_NOTIFICATION_PREF_ID,
         API_SET_NOTIFICATION_PREF_BULK
    };