import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_ADD_NEW_USER,
  API_CHANGE_AGENT,
  API_EDIT_USER,
  API_GET_ALL_USERS,
  API_GET_USER_BY_ID,
  API_ADD_QUICK_NOTE,
  API_DELETE_QUICK_NOTES,
  API_GET_QUICK_NOTES,
  API_PUT_QUICK_NOTES,
  API_BUISENESS_INFO,
  API_UPDATE_BUISENESS_INFO,
  API_GET_QUICK_NOTE,
} from "../api/account.service";

const initialState = {
  allUsers: [],
  selectedUser: {},
  superAdminUser: {},
  mode: "",
  loading: false,
  userToast: "",
  quickNotes: [],
  quickNotesCounts: 0,
  qNotePage: 0,
  qNoteLimit: 10,
  businessdata: {
    upi: "",
  },
};

export const getAllUsers = createAsyncThunk(
  "account/getAllUsers",
  async (_, thunkAPI) => {
    try {
      let users = await API_GET_ALL_USERS();
      return users;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getUserById = createAsyncThunk(
  "account/getUserById",
  async (id, thunkAPI) => {
    try {
      let user = await API_GET_USER_BY_ID(id);
      return user;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const addNewUser = createAsyncThunk(
  "account/addNewUser",
  async (userData, thunkAPI) => {
    try {
      let user = await API_ADD_NEW_USER(userData);
      return user;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "account/updateUser",
  async ({ id, userData }, thunkAPI) => {
    try {
      let user = await API_EDIT_USER(id, userData);
      return user;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const changeTicketAgent = createAsyncThunk(
  "account/changeAgent",
  async ({ ticketId, configData }, thunkAPI) => {
    try {
      let agent = await API_CHANGE_AGENT(ticketId, configData);
      return agent;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getQuickNotes = createAsyncThunk(
  "account/get_quickNotes",
  async ({ page, limit }, thunkAPI) => {
    try {
      const { data } = await API_GET_QUICK_NOTES(page, limit);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getQuickNote = createAsyncThunk(
  "account/get_quickNote",
  async (id, thunkAPI) => {
    try {
      const { data } = await API_GET_QUICK_NOTE(id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const addQuickNote = createAsyncThunk(
  "account/add_quickNotes",
  async ({ payload }, thunkAPI) => {
    try {
      const { data } = await API_ADD_QUICK_NOTE(payload);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const updateQuickNote = createAsyncThunk(
  "account/update_quickNotes",
  async ({ id, payload }, thunkAPI) => {
    try {
      const { data } = await API_PUT_QUICK_NOTES(id, payload);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const deleteQuickNote = createAsyncThunk(
  "account/delete_quickNotes",
  async ({ id }, thunkAPI) => {
    try {
      const { data } = await API_DELETE_QUICK_NOTES(id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getBuisenessinfo = createAsyncThunk(
  "account/getBuisenessinfo",
  async ({}, thunkAPI) => {
    try {
      const data = await API_BUISENESS_INFO();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const postBuisenessinfo = createAsyncThunk(
  "account/postBuisenessinfo",
  async ({ data }, thunkAPI) => {
    try {
      const resp = await API_UPDATE_BUISENESS_INFO(data);
      return resp;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    handleAccountUser(state, action) {
      state.selectedUser = action.payload;
    },
    resetAccountUser(state) {
      state.selectedUser = {};
    },
    changeUserToast(state, action) {
      state.userToast = action.payload;
    },
    changeMode(state, action) {
      state.mode = action.payload;
    },
    changeQNotepage(state, { payload }) {
      state.qNotePage = payload;
    },
    changeQuickNoteLimit(state, { payload }) {
      state.qNoteLimit = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.allUsers = action.payload.user.rows;
      state.superAdminUser = action.payload.user.rows
        .slice()
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))[0];
      state.loading = false;
    });
    builder.addCase(getAllUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllUsers.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.selectedUser = action.payload.user;
      state.loading = false;
    });
    builder.addCase(getUserById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserById.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addNewUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addNewUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addNewUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(changeTicketAgent.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(changeTicketAgent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeTicketAgent.rejected, (state) => {
      state.loading = false;
    });
    //quick notes
    builder.addCase(getQuickNotes.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.quickNotes = [...state.quickNotes, ...payload.rows];
      state.quickNotesCounts = payload.count;
    });
    builder.addCase(getQuickNotes.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(getQuickNotes.rejected, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(updateQuickNote.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.quickNotes = state.quickNotes?.map((item) => {
        if (item.id == payload?.quickNotes?.id) return payload?.quickNotes;
        else return item;
      });
    });
    builder.addCase(updateQuickNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateQuickNote.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteQuickNote.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.quickNotes = state.quickNotes?.filter(
        (item) => item.id != payload?.quickNotes.id
      );
    });
    builder.addCase(deleteQuickNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteQuickNote.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addQuickNote.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.quickNotes = [payload.quickNotes, ...state.quickNotes];
    });
    builder.addCase(addQuickNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addQuickNote.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getBuisenessinfo.fulfilled, (state, { payload }) => {
      state.businessdata = payload;
      state.loading = false;
    });
    builder.addCase(getBuisenessinfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBuisenessinfo.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(postBuisenessinfo.fulfilled, (state, { payload }) => {
      state.businessdata = payload;
      state.loading = false;
    });
    builder.addCase(postBuisenessinfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postBuisenessinfo.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getQuickNote.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getQuickNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getQuickNote.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  handleAccountUser,
  resetAccountUser,
  changeUserToast,
  changeMode,
  changeQNotepage,
  changeQuickNoteLimit,
} = accountSlice.actions;
export default accountSlice.reducer;
