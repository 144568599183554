export function getDate(timestamp) {
  const time = new Date(timestamp).toLocaleString("en-IN", {
    dateStyle: "short",
    timeStyle: "short",
  });
  return time;
}
export function addOneDay(date = null) {
  if(date!==undefined&&date!==null){
    date=new Date(date)
    date?.setDate(date?.getDate() + 1);
    return new Date(date).toISOString();
  }
  return date;
}
