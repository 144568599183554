import { Dialog } from "primereact/dialog";
import { CustomButton } from "../CustomButton";
import { addCampaign } from "../../reducers/campaignSlice";
import { useDispatch } from "react-redux";
import { resetSelectedProductsList } from "../../reducers/productTableSlice";
import { changeShowGroupShareDialog, changeSuccessfullCampaign } from "../../reducers/appSlice";
import { calculateConversationCharge } from "../../utils/shareUtils";
import { Text } from "../Text";
import * as Messag from '../../config/ToastMessage';
export const ConversationAlert = ({
  CampaignData,
  selectedImage,
  Conversation,
  displayAlert,
  setDisplayAlert,
  schedule=false,
  toast,
}) => {
  const dispatch = useDispatch();

  const onHide = () => {
    setDisplayAlert(false);
  };
  const handleShare = () => {
    
    const campaign = { configData: CampaignData, image: selectedImage ,schedule };
    dispatch(addCampaign(campaign))
      .unwrap()
      .then((res) => {
        let Message_Success = Messag.Share_Camp_ToastSuccessMessage;
        toast.current.show({ severity: "success", detail: Message_Success });
        dispatch(changeShowGroupShareDialog(false));
        dispatch(resetSelectedProductsList());
        dispatch(changeSuccessfullCampaign(true));
      })
      .catch((err) => {
        toast.current.show({ severity: "error", detail: err.message });
      });
    onHide();
  };

  const dialogFooter = () => {
    return (
      <div className="flex justify-content-center mt-4  ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={onHide}
        />
        <CustomButton label="Share" varient="filled" onClick={handleShare} />
      </div>
    );
  };
  return (
    <Dialog
      header="You are about to share"
      visible={displayAlert}
      footer={dialogFooter()}
      onHide={onHide}
      style={{ width: "35vw" }}
      className={"dialog-custom"}
    >
      <div className="flex justify-content-center mt-4 flex-column">
        <Text
          type={"heading"}
          style={{ textAlign: "center", marginBottom: "20px" }}
        >
          This will consume {calculateConversationCharge(Conversation)}{" "}
          Conversations.
        </Text>
        <Text type={"heading"} style={{ textAlign: "center" }}>
          Are You Sure?
        </Text>
      </div>
    </Dialog>
  );
};
