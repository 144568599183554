import { Outlet, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";

function Products() {
  const location = useLocation();
  const currentPath = location.pathname;
  if (currentPath == "/products" || currentPath == "/products/") {
    return <Navigate to={"/products/list"} replace />;
  }
  return <Outlet />;
}

export default Products;
