import {
  checkIsValidPrams,
  checkValidUrl,
} from "../../../components/MultiInput/HttpInput/http-constant";
import { isOptionValid } from "../../../utils/canvasUtils";

export const nodeTypes = [
  {
    name: "Text",
    value: "text",
    icon: "/svgIcons/Bot builder icons/left-align-icon.svg",
  },
  {
    name: "Image",
    value: "image",
    icon: "/svgIcons/Bot builder icons/image-icon.svg",
  },
  {
    name: "Video",
    value: "video",
    icon: "/svgIcons/Bot builder icons/image-icon.svg",
  },
  {
    name: "Quick Reply",
    value: "quick_reply",
    icon: "/svgIcons/Bot builder icons/quick-reply-icon.svg",
  },
  {
    name: "List",
    value: "list",
    icon: "/svgIcons/Bot builder icons/List.svg",
  },
  {
    name: "HTTP",
    value: "http",
    icon: "/svgIcons/Bot builder icons/http-icon.svg",
  },
  {
    name: "Assign Agent",
    value: "assignAgent",
    icon: "/svgIcons/Bot builder icons/assign-agent.svg",
  },
  {
    name: "Save Address",
    value: "addressInfo",
    icon: "/svgIcons/Bot builder icons/location.svg",
  },
  {
    name: "Share Catalog",
    value: "shareCatalog",
    icon: "/svgIcons/Bot builder icons/catalog.svg",
  },
  {
    name: "Template",
    value: "template",
    icon: "/svgIcons/Bot builder icons/template.svg",
  },
  {
    name: "Pattern",
    value: "pattern",
    icon: "/svgIcons/Bot builder icons/left-align-icon.svg",
  },
];

export const BOTFLOW_EDIT_KEY = "custom-botbuilder";
export const NON_EDIT_BOTFLOW_MESSAGE =
  "This botflow is not editable. Please contact Skalebot team.";

export const isTextNodeValid = (nodeData) => {
  let isValid = true;
  switch (nodeData.type) {
    case "text":
      if (nodeData.label?.trim() == "") {
        isValid = false;
      }
      break;
    case "image":
      if (nodeData.label?.trim() == "" || !nodeData?.image) {
        isValid = false;
      }
      break;
    case "video":
      if (nodeData.label?.trim() == "" || !nodeData?.video) {
        isValid = false;
      }
      break;
    case "pattern":
      if (nodeData.label?.trim() == "") {
        isValid = false;
      }
      break;
    default:
      //no actions
      break;
  }
  return isValid;
};

export const isQuickReplyNodeValid = (nodeData) => {
  let isValidPayload = isTextNodeValid(nodeData);
  let isValidOption =
    nodeData.options.length !== 0 && isOptionValid(nodeData.options);
  if (!isValidOption || !isValidPayload) {
    return false;
  } else {
    return true;
  }
};

export const islistNodeValid = (nodeData) => {
  let isBodyText = nodeData.body?.trim() !== "";
  let isListTitle = nodeData.lestHeading?.trim() !== "";
  let isGlobalButtonText = nodeData.globalButton?.trim() !== "";
  let isValidOption = isOptionValid(nodeData?.options || []);

  if (!isBodyText || !isGlobalButtonText || !isListTitle || !isValidOption) {
    return false;
  } else {
    return true;
  }
};

export const ishttpsNodeValid = (nodeData) => {
  const { params, headers, url, method } = nodeData;
  let isValidaPrams = checkIsValidPrams(params);
  let isValidHeaders = checkIsValidPrams(headers);
  let isValidUrl = checkValidUrl(url);
  let isMethod = method?.trim() !== "";

  if (!isValidHeaders || !isValidaPrams || !isValidUrl || !isMethod) {
    return false;
  } else {
    return true;
  }
};

export const isAllNodeValid = (nodeArr) => {
  let isValid = true;
  for (const node of nodeArr) {
    const { data, type } = node;
    switch (type) {
      case "text":
        isValid = isTextNodeValid(data);
        break;
      case "quick_reply":
        isValid = isQuickReplyNodeValid(data);

        break;
      case "list":
        isValid = islistNodeValid(data);
        break;
      case "http":
        isValid = ishttpsNodeValid(data);
        break;
      case "pattern":
        isValid = isTextNodeValid(data);
        break;
      default:
        break;
    }

    if (!isValid) return isValid;
  }

  return isValid;
};

export const interactiveNodes = ["quick_reply", "list", "template"];
