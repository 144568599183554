import axiosInstance from "./axios.instance";

// apies calls for categories

const API_GET_CATEGORIES = async (
  pageNo,
  limit,
  filterData,
  globalFilterValue
) => {
  let resp;
  try {
    if (filterData || globalFilterValue) {
      let allFilter = "";
      filterData.forEach((element) => {
        allFilter += `&${element.key}=${element.value}`;
      });
      if (globalFilterValue) {
        allFilter += `&global=${globalFilterValue}`;
      }
      resp = await axiosInstance.get(
        `/category?page=${pageNo}&limit=${limit}&isActive=1${allFilter}`
      );
    } else {
      resp = await axiosInstance.get(
        `/category?page=${pageNo}&limit=${limit}&isActive=1`
      );
    }
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_ADD_CATEGORY = async (configData) => {
  try {
    const resp = await axiosInstance.post(`/category`, configData);
    return resp.data;
  } catch (err) {
    throw err;
  }
};
const API_PUT_CATEGORY = async (categoryID, updatedData) => {
  try {
    const resp = await axiosInstance.put(
      `/category/${categoryID}`,
      updatedData
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_GET_CATEGORY_ID = async (categoryID) => {
  try {
    const resp = await axiosInstance.get(`/category/${categoryID}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_DELETE_CATEGORY = async (categoryID) => {
  try {
    const resp = await axiosInstance.delete(`/category/${categoryID}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_BULK_DELETE_CATEGORY = async (categoryIDs) => {
  try {
    await axiosInstance.delete(`/category/bulk`, {
      data: { ids: categoryIDs },
    });
    return categoryIDs;
  } catch (err) {
    throw err;
  }
};

export {
  API_ADD_CATEGORY,
  API_DELETE_CATEGORY,
  API_GET_CATEGORIES,
  API_PUT_CATEGORY,
  API_GET_CATEGORY_ID,
  API_BULK_DELETE_CATEGORY,
};
