import { useEffect } from "react";
import { ReactComponent as UploadIcon } from "../../svg/upload.svg";
import { Text } from "../Text";
import { useState } from "react";
import { Skeleton } from "primereact/skeleton";
const ImportValidationInfo = ({ handleValidate = () => {} }) => {
  const [isValidating, setvalidating] = useState(false);
  const [validationInfo, setValidationInfo] = useState(null);

  useEffect(() => {
    setvalidating(true);
    handleValidate()
      .then((res) => {
        setvalidating(false);
        setValidationInfo(res);
      })
      .catch((err) => {
        setvalidating(false);

        console.log(err);
      });
  }, []);
  return (
    <div className="my-6">
      <div className="flex flex-column gap-3 justify-content-center align-items-center">
        <UploadIcon />
        <Text type={"heading"}>You have found</Text>
        {isValidating ? (
          <>
            <Skeleton width="8rem" className="mb-2"></Skeleton>
            <Skeleton width="8rem" className="mb-2"></Skeleton>
          </>
        ) : (
          <>
            <Text type={"sub-heading"}>
              {validationInfo?.new} New data to add.
            </Text>
            <Text type={"sub-heading"}>
              {validationInfo?.update} data for update.
            </Text>
          </>
        )}

        {/* <div className="flex gap-2 mt-4">
            <CustomButton
              label={"Cancel"}
              varient={"icon-button"}
              onClick={handleHideValidatioInfoDialog}
            />
            <CustomButton
              label={"Save"}
              varient={"filled"}
              onClick={handleSaveData}
            />
          </div> */}
      </div>
    </div>
  );
};

export default ImportValidationInfo;
