import React, { useState, useEffect } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import { ReactComponent as MicIcon } from "../../assets/svgIcons/mic.svg";
import { useDispatch } from "react-redux";
import { changeIsRecAudioSelect } from "../../reducers/enqChatSlice";

const Mp3Recorder = new MicRecorder({
  bitRate: 64,
  prefix: "data:audio/wav;base64,",
});

function Record({ setblobUrl, setBlobData,isRecording,setIsRecording }) {
 // const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const dispatch = useDispatch()

  const startRecording = async () => {
    dispatch(changeIsRecAudioSelect(true))
    setBlobData(null)
    setblobUrl(null)
    try {
      navigator.getUserMedia(
        { audio: true },
        async () => {
          setIsBlocked(false);
          try {
            await Mp3Recorder.start();
            setIsRecording(true);
          } catch (error) {
            console.error(error);
          }
        },
        () => {
          setIsBlocked(true);
        }
      );
    } catch (error) {
      console.log(error)
    }
  };

  const stopRecording = async () => {
    try {
      setIsRecording(false);
      const [buffer, blob] = await Mp3Recorder.stop().getMp3();
      setBlobData(blob);
      const blobURL = URL.createObjectURL(blob);
      const binaryString = btoa(blobURL);
      setblobUrl(blobURL);
    } catch (e) {
      console.error("Error while stopping recording:", e);
    }
  };

  return (
    <button
      onClick={!isRecording ? startRecording : stopRecording}
      className={isRecording ? "audioBtn glowEffect" : "audioBtn"}
    >
      {!isRecording ? <MicIcon /> : <i class="pi pi-pause"></i>}
    </button>
  );
}

export default Record;
