import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios.instance";
const getTemplates = createAsyncThunk(
  "template/getTemplates",
  async (
    {
      pageNo,
      limit,
      status,
      campaignType = null,
      category = null,
      templateType = null,
    },
    thunkAPI
  ) => {
    try {
      var queryParams = `page=${pageNo}&limit=${limit}&isActive=1`;

      if (campaignType) queryParams += `&campaignType=${campaignType}`;
      if (status) queryParams += `&status=${status}`;
      if (templateType) queryParams += `&templateType=${templateType}`;
      if (category) queryParams += `&category=${category}`;
      const { data } = await axiosInstance.get(
        `/whatsappTemplate/gupshup?${queryParams}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const getTemplatesbyId = createAsyncThunk(
  "template/getTemplatesbytemplateId",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstance.get(
        `/whatsappTemplate/gupshup/template/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const getTemplatesbyappId = createAsyncThunk(
  "template/getTemplatesbyId",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstance.get(
        `/whatsappTemplate/gupshup/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const addTemplate = createAsyncThunk(
  "template/addTemplate",
  async ({ configData, media }, thunkAPI) => {
    try {
      if (media !== null) {
        let formData = new FormData();
        //Adding files to the formdata
        formData.append("FILE", media);
        //formData.append("file_type", "image/png");
        const { data } = await axiosInstance.post(
          "/image/gupshupHandler",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const { mediaId } = data;
        configData.template["mediaId"] = mediaId;
        configData.template["exampleMediaId"] = mediaId;
      }
      const { data } = await axiosInstance.post(
        `/whatsappTemplate/gupshup`,
        configData
      );

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const updateTemplate = createAsyncThunk(
  "template/updateTemplate",
  async ({ configData, media, id }, thunkAPI) => {
    try {
      if (media !== null) {
        let formData = new FormData();
        //Adding files to the formdata
        formData.append("FILE", media);
        //formData.append("file_type", "image/png");
        const { data } = await axiosInstance.post(
          "/image/gupshupHandler",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const { mediaId } = data;
        configData.template["mediaId"] = mediaId;
        configData.template["exampleMediaId"] = mediaId;
      }
      const { data } = await axiosInstance.put(
        `/whatsappTemplate/gupshup/${id}`,
        configData
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const deleteTemplate = createAsyncThunk(
  "template/deleteTemplate",
  async ({ elementName }, thunkAPI) => {
    try {
      const resp = await axiosInstance.delete(
        `/whatsappTemplate/gupshup/${elementName}`
      );
      return elementName;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export {
  addTemplate,
  getTemplates,
  getTemplatesbyId,
  getTemplatesbyappId,
  updateTemplate,
  deleteTemplate,
};
