import { Dialog } from "primereact/dialog";
// import BulkContactsList from "../BulkContactsList";
import { underlineStyle } from "../../utils/commonStyles";
import { Text } from "../Text";

const GoogleContactsUpload = ({
  contactList,
  showGoogleContacts,
  hideGoogleContacts,
  toast,
}) => {
  const dialogHeader = () => {
    return (
      <Text type={"heading"} style={underlineStyle}>
        Add customer
      </Text>
    );
  };

  return (
    <div>
      {showGoogleContacts && contactList.length !== 0 && (
        <Dialog
          header={dialogHeader}
          visible={showGoogleContacts}
          onHide={hideGoogleContacts}
          className={"md-dialog"}
        >
          {/* <BulkContactsList contactList={contactList}  hideDialog={hideGoogleContacts} toast={toast}/> */}
        </Dialog>
      )}
    </div>
  );
};

export default GoogleContactsUpload;
