import axios from "axios";
import baseUrl from "./server";
import axiosInstance from "./axios.instance";

const axiosInstance1 = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
});

const API_GET_IMG_URL = async (user) => {
  try {
    const resp = await axiosInstance.post("/image", {
      imageType: `chat/${user?.mob}`,
    });
    return resp;
  } catch (err) {
    throw err;
  }
};

const API_UPLOAD_IMG = async (imgUploadUrl, img, contentType) => {
  try {
    const resp = await axiosInstance1.put(imgUploadUrl, img, {
      headers: { "Content-Type": contentType },
    });
    return resp;
  } catch (err) {
    throw err;
  }
};

const API_UPLOAD_FILE = async (imgUploadUrl, img, config) => {
  try {
    const resp = await axiosInstance1.put(imgUploadUrl, img, config);
    return resp;
  } catch (err) {
    throw err;
  }
};

const API_SEND_MSG = async (id, data) => {
  try {
    const resp = await axiosInstance.post(`/agent-ticket/${id}/message`, data);
    return resp;
  } catch (err) {
    throw err;
  }
};

const API_START_CHAT = async (data) => {
  try {
    const resp = await axiosInstance.post(`/agent-ticket/startChat`, data);
    return resp;
  } catch (err) {
    throw err;
  }
};

const API_POST_UNREADMESSAGE = async (updatedData) => {
  try{
    const resp = await axiosInstance.post(`/agent-ticket/resetUnreadMessage`, updatedData);
  return resp.data;
  }catch(err){
    throw err
  }
};
 
export {
  API_UPLOAD_IMG,
  API_GET_IMG_URL,
  API_SEND_MSG,
  API_START_CHAT,
  API_UPLOAD_FILE,
  API_POST_UNREADMESSAGE
};
