import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_GET_OVER_ALL_COUNT,
          API_GET_COUNT_ORDER,
          API_GET_COUNT_ENQUIRY,
          API_GET_COUNT_CAMPAIGN,
          API_GET_COUNT_AGENT,
          API_GET_COUNT_AGENT_TOP,
          API_GET_COUNT_TICKET,
          API_GET_COUNT_TICKET_RESPONSE,
          API_GET_COUNT_AGENT_TICKETS,
          API_GET_TICKETS_OVERALL,
          API_GET_ENQUIRIES_OVERALL,
          API_GET_CONVERSATION,
        } from '../api/analytic.service'


const initialState = {
  overAllCount: {},
  analyticalDetails: {
      order:{},
      enquiry:{},
      campaign:{},
      agent:{},
      ticket:{},
  },
  skeletonloading:{
    order:false,
    enquiry:false,
    campaign:false,
    agent:false,
    ticket:false,
    campaignConversation:false,
    responseTime:false,
    agenttop:false,
    agentsTickets:false,
    enquiryOverall:false,
    ticketsOverall:false
  },
  responseTime:{},
  dateRange:{
    type:'all',
    fromDate:null,
    toDate:null,
  },
  loading: false,
};

export const getOverAllCount = createAsyncThunk(
  "analytics/getOverAllCount",
  async ({ startDate,endDate }, thunkAPI) => {
    try {
      let resp = await API_GET_OVER_ALL_COUNT(startDate,endDate);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getCountOrder = createAsyncThunk(
  "analytics/getOrderDetail",
  async ({ dateRange }, thunkAPI) => {
    try {
      const {fromDate: startDate, toDate: endDate} =dateRange;
      let resp = await API_GET_COUNT_ORDER(startDate,endDate);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCountEnquiry = createAsyncThunk(
  "analytics/getEnquiryDetail",
  async ({ dateRange }, thunkAPI) => {
    try {
      const {fromDate: startDate, toDate: endDate} =dateRange;
      let resp = await API_GET_COUNT_ENQUIRY(startDate,endDate);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCountCampaign = createAsyncThunk(
  "analytics/getCampaignDetail",
  async ({ dateRange }, thunkAPI) => {
    try {
      const {fromDate: startDate, toDate: endDate} =dateRange;
      let resp = await API_GET_COUNT_CAMPAIGN(startDate,endDate);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCountConversation = createAsyncThunk(
  "analytics/getCountConversation",
  async ({ dateRange }, thunkAPI) => {
    try {
      const {fromDate: startDate, toDate: endDate} =dateRange;
      let resp = await API_GET_CONVERSATION(startDate,endDate);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCountAgent = createAsyncThunk(
  "analytics/getAgentDetail",
  async ({ dateRange }, thunkAPI) => {
    try {
      const {fromDate: startDate, toDate: endDate} =dateRange;
      let resp = await API_GET_COUNT_AGENT(startDate,endDate);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCountAgentTop = createAsyncThunk(
  "analytics/getAgentTopDetail",
  async ({ dateRange }, thunkAPI) => {
    try {
      let {fromDate: startDate, toDate: endDate} =dateRange;
      if (!endDate) endDate = startDate
      let resp = await API_GET_COUNT_AGENT_TOP(startDate,endDate);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);


export const getAgentTickets = createAsyncThunk(
  "analytics/getAgentTickets",
  async ({pageNo,limit, dateRange,filter,globalFilterValue }, thunkAPI) => {
    try {
      let {fromDate: startDate, toDate: endDate} =dateRange;
      if (!endDate) endDate = startDate
      let resp = await API_GET_COUNT_AGENT_TICKETS(pageNo,limit,startDate,endDate,filter,globalFilterValue);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getCountTickets = createAsyncThunk(
  "analytics/getTicketsDetail",
  async ({ dateRange }, thunkAPI) => {
    try {
      const {fromDate: startDate, toDate: endDate} =dateRange;
      let resp = await API_GET_COUNT_TICKET(startDate,endDate);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getResponseTIme = createAsyncThunk(
  "analytics/getResponseTIme",
  async ({ dateRange }, thunkAPI) => {
    try {
      const {fromDate: startDate, toDate: endDate} =dateRange;
      let resp = await API_GET_COUNT_TICKET_RESPONSE(startDate,endDate);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getTicketsOverall = createAsyncThunk(
  "analytics/getTicketsOverall",
  async ({pageNo=0,limit=10, dateRange,filter,globalFilterValue }, thunkAPI) => {
    try {
      let {fromDate: startDate, toDate: endDate} =dateRange;
      if (!endDate) endDate = startDate
      let resp = await API_GET_TICKETS_OVERALL(pageNo,limit,startDate,endDate,filter,globalFilterValue);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getEnquiriesOverall = createAsyncThunk(
  "analytics/getEnquiriesOverall",
  async ({pageNo=0,limit=10, dateRange,filter,globalFilterValue }, thunkAPI) => {
    try {
      let { fromDate: startDate, toDate: endDate} =dateRange;
      let resp = await API_GET_ENQUIRIES_OVERALL(pageNo,limit,startDate,endDate,filter,globalFilterValue);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const analyticSlice = createSlice({
  name: "analytic",
  initialState,
  reducers: {
    setAnalyticalRange(state,action){
      state.dateRange.type=action.payload.type
      state.dateRange.fromDate=action.payload.fromDate?new Date(action.payload.fromDate)?.toISOString().substring(0,10):null
      state.dateRange.toDate=action.payload.toDate?new Date(action.payload.toDate)?.toISOString().substring(0,10):null
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getOverAllCount.fulfilled, (state, action) => {
      state.overAllCount = action.payload;
      state.loading = false;
    });
    builder.addCase(getOverAllCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOverAllCount.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCountOrder.fulfilled, (state, action) => {
      state.analyticalDetails.order = action.payload;
      state.orderstat=action.payload.statistics;
      state.loading = false;
      state.skeletonloading.order=false;
    });
    builder.addCase(getCountOrder.pending, (state) => {
      state.loading = true;
      state.skeletonloading.order=true;
    });
    builder.addCase(getCountOrder.rejected, (state) => {
      state.loading = false;
      state.skeletonloading.order=false;
    });

    builder.addCase(getCountEnquiry.fulfilled, (state, action) => {
      state.analyticalDetails.enquiry = action.payload;
      state.loading = false;
      state.skeletonloading.enquiry=false;
    });
    builder.addCase(getCountEnquiry.pending, (state) => {
      state.loading = true;
      state.skeletonloading.enquiry=true;
    });
    builder.addCase(getCountEnquiry.rejected, (state) => {
      state.loading = false;
      state.skeletonloading.enquiry=false;
    });

    builder.addCase(getCountCampaign.fulfilled, (state, action) => {
      state.analyticalDetails.campaign = action.payload;
      state.loading = false;
      state.skeletonloading.campaign=false;
    });
    builder.addCase(getCountCampaign.pending, (state) => {
      state.loading = true;
      state.skeletonloading.campaign=true;
    });
    builder.addCase(getCountCampaign.rejected, (state) => {
      state.loading = false;
      state.skeletonloading.campaign=false;
    });

    builder.addCase(getCountConversation.fulfilled, (state, action) => {
      state.analyticalDetails.campaignConversation = action.payload;
      state.loading = false;
      state.skeletonloading.campaignConversation=false;
    });
    builder.addCase(getCountConversation.pending, (state) => {
      state.loading = true;
      state.skeletonloading.campaignConversation=true;
    });
    builder.addCase(getCountConversation.rejected, (state) => {
      state.loading = false;
      state.skeletonloading.campaignConversation=false;
    });

    builder.addCase(getResponseTIme.fulfilled, (state, action) => {
      state.responseTime = action.payload;
      state.loading = false;
      state.skeletonloading.responseTime=false;
    });
    builder.addCase(getResponseTIme.pending, (state) => {
      state.loading = true;
      state.skeletonloading.responseTime=true;
    });
    builder.addCase(getResponseTIme.rejected, (state) => {
      state.loading = false;
      state.skeletonloading.responseTime=false;
    });
    
    builder.addCase(getCountAgent.fulfilled, (state, action) => {
      state.analyticalDetails.agent = action.payload;
      state.loading = false;
      state.skeletonloading.agent=false;
    });
    builder.addCase(getCountAgent.pending, (state) => {
      state.loading = true;
      state.skeletonloading.agent=true;
    });
    builder.addCase(getCountAgent.rejected, (state) => {
      state.loading = false;
      state.skeletonloading.agent=false;
    });

    builder.addCase(getCountAgentTop.fulfilled, (state, action) => {
      state.agenttop = action.payload.rows;
      state.loading = false;
      state.skeletonloading.agenttop=false;
    });
    builder.addCase(getCountAgentTop.pending, (state) => {
      state.loading = true;
      state.skeletonloading.agenttop=true;
    });
    builder.addCase(getCountAgentTop.rejected, (state) => {
      state.loading = false;
      state.skeletonloading.agenttop=false;
    });

    builder.addCase(getAgentTickets.fulfilled, (state, action) => {
      state.agentsTickets = action.payload.row;
      state.loading = false;
      state.skeletonloading.agentsTickets=false;
    });
    builder.addCase(getAgentTickets.pending, (state) => {
      state.loading = true;
      state.skeletonloading.agentsTickets=true;
    });
    builder.addCase(getAgentTickets.rejected, (state) => {
      state.loading = false;
      state.skeletonloading.agentsTickets=false;
    });

    builder.addCase(getCountTickets.fulfilled, (state, action) => {
      state.analyticalDetails.ticket = action.payload;
      state.loading = false;
      state.skeletonloading.ticket=false;
    });
    builder.addCase(getCountTickets.pending, (state) => {
      state.loading = true;
      state.skeletonloading.ticket=true;
    });
    builder.addCase(getCountTickets.rejected, (state) => {
      state.loading = false;
      state.skeletonloading.ticket=false;
    });

    builder.addCase(getEnquiriesOverall.fulfilled, (state, action) => {
      state.enquiryOverall = action.payload.row;
      state.loading = false;
      state.skeletonloading.enquiryOverall=false;
    });
    builder.addCase(getEnquiriesOverall.pending, (state) => {
      state.loading = true;
      state.skeletonloading.enquiryOverall=true;
    });
    builder.addCase(getEnquiriesOverall.rejected, (state) => {
      state.loading = false;
      state.skeletonloading.enquiryOverall=false;
    });

    builder.addCase(getTicketsOverall.fulfilled, (state, action) => {
      state.ticketsOverall = action.payload.row;
      state.loading = false;
      state.skeletonloading.ticketsOverall=false;
    });
    builder.addCase(getTicketsOverall.pending, (state) => {
      state.loading = true;
      state.skeletonloading.ticketsOverall=true;
    });
    builder.addCase(getTicketsOverall.rejected, (state) => {
      state.loading = false;
      state.skeletonloading.ticketsOverall=false;
    });
  },
});

export const {setAnalyticalRange} = analyticSlice.actions;
export default analyticSlice.reducer;