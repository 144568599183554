import { useEffect, useRef } from "react";
import { useState } from "react";
import axiosInstance from "../../api/axios.instance";
import axios from "axios";
import { Text } from "../../components/Text";
import styles from "./style.module.css";
import { ProgressBar } from "primereact/progressbar";
import { Image } from "primereact/image";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMedia,
  editMedia,
  uploadMedia,
} from "../../reducers/mediaTabelSlice";
import { ReactComponent as UploadIcon } from "../../svg/uploadIcon.svg";
import { dateCompiler } from "../../utils/tableUtils";
import { compressImg } from "../../utils/templateUtils";
import { Skeleton } from "primereact/skeleton";
import CONSTANTS from "../../config/Constants";
import SklbtConfirmation from "../../components/common/SklbtConfirmation";
import { useLayout } from "../../context/LayoutContext";
import * as Messag from "../../config/ToastMessage";
import { SKUCodefilter } from "../../utils/commonUtils";
import permissions from "../../roles/permissions";
import modulesName from "../../roles/modulesName";

function ImagePreview({
  imgId = null,
  image = null,
  removeImgClick = () => {},
  isSaved = false,
  select = false,
  showSelect = () => {},
  deletedImages = [],
  setDeletedImages = () => {},
  closeselectRef = null,
}) {
  const [name, setName] = useState(image?.name || image?.fileName || "");
  const [isUploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(isSaved);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [localImageUrl, setLocalImageUrl] = useState("");
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [load, setLoad] = useState(true);
  const [edit, setEdit] = useState(false);

  const { toastMessage } = useLayout();
  const { accesskey } = useSelector((state) => state.authenticate);

  const dispatch = useDispatch();
  const timerRef = useRef();
  const isLongPress = useRef();

  const imageUpload = async (image) => {
    try {
      const uploadAt = await axiosInstance.post("/image", {
        imageType: `product`,
      });

      const imageUploadURL = uploadAt?.data.src;

      var config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadPercentage(percentCompleted);
        },
        headers: { "Content-Type": "image/png" },
      };

      image = await compressImg(image);

      await axios.put(imageUploadURL, image, config);
      let imageData = {
        fileName: image.name,
        url: uploadAt?.data.url,
        SKUCode: SKUCodefilter(image.name),
      };

      dispatch(uploadMedia({ imageData }))
        .unwrap()
        .then((res) => {
          removeImgClick({ id: imgId });
        })
        .catch((e) => {});
    } catch (error) {
      throw error;
    }
  };

  const handleRemove = () => {
    if (uploaded) {
      dispatch(deleteMedia({ id: image.id }))
        .unwrap()
        .then((res) => {
          toastMessage({
            message: "success",
            detail: Messag.Delete_MEDIA_ToastSuccessMessage,
          });
          setDeleteConfirm(false);
        })
        .catch((err) => {});
    } else {
      removeImgClick({ id: imgId });
    }
  };

  const valideImage = (img) => {
    return img?.size <= 1048576 * 8;
  };

  function startPressTimer() {
    isLongPress.current = false;
    timerRef.current = setTimeout(() => {
      isLongPress.current = true;
      showSelect();
    }, 500);
  }

  function handleOnClick(e) {
    if (!uploaded) return;

    if (deletedImages?.includes(image?.id)) {
      let img = deletedImages?.filter((x) => x !== image?.id);
      setDeletedImages([...img]);
    } else {
      setDeletedImages((x) => {
        return [image?.id, ...x];
      });
    }
    if (isLongPress.current) {
      return;
    }
  }

  function handleOnMouseDown() {
    startPressTimer();
  }

  function handleOnMouseUp() {
    clearTimeout(timerRef.current);
  }

  function handleOnTouchStart() {
    startPressTimer();
  }

  function handleOnTouchEnd() {
    if (select) return;
    clearTimeout(timerRef.current);
  }
  const longPress = {
    onClick: handleOnClick,
    onMouseDown: handleOnMouseDown,
    onMouseUp: handleOnMouseUp,
    onTouchStart: handleOnTouchStart,
    onTouchEnd: handleOnTouchEnd,
  };

  const handleImageLoaded = () => {
    setLoad(false);
  };

  const saveChanges = () => {
    let imageData = {
      id: image?.id,
      fileName: name,
      url: image?.url,
      SKUCode: SKUCodefilter(name),
    };
    dispatch(editMedia({ imageData }))
      .unwrap()
      .then((res) => {
        setEdit(false);
        toastMessage({
          message: "success",
          detail: Messag.Rename_MEDIA_ToastSuccessMessage,
        });
      })
      .catch((err) => {
        toastMessage({ message: "error", detail: err?.message });
      });
  };

  const editFileName = () => {
    accesskey[modulesName.MEDIA]?.includes(permissions.UPDATE) && setEdit(true);
    closeselectRef.current = image?.id;
  };

  useEffect(() => {
    if (image?.id && closeselectRef?.current === image?.id) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [closeselectRef?.current]);

  useEffect(() => {
    if (image != null && !uploaded) {
      var reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (readerEvent) => {
        if (image.type.includes("image")) {
          setLocalImageUrl(readerEvent.target.result);
        }
      };

      if (valideImage(image)) {
        setUploading(true);
        imageUpload(image)
          .then((res) => {
            setUploading(false);
            setUploaded(true);
          })
          .catch((err) => {
            setUploading(false);
          });
      } else return;
    }
  }, []);

  return (
    <div
      className={`p-1 card relative border-round-xs  flex flex-column justify-content-between ${styles["preview-container"]} card bg-white`}
      style={{ border: "1px solid #F0F0F0" }}
      {...longPress}
    >
      <div className={`${styles["img-preview-container"]} relative`}>
        {deleteConfirm && (
          <SklbtConfirmation
            icon="pi pi-trash"
            message={CONSTANTS.MEDIA_DELETE_CONFIRMATION}
            visible={deleteConfirm}
            handleConfirm={handleRemove}
            handleReject={() => setDeleteConfirm(false)}
          />
        )}
        {load && <Skeleton width="100%" height="200px" />}
        {select && uploaded && (
          <input
            type="checkbox"
            multiple
            style={{ position: "absolute" }}
            value={image?.id}
            checked={deletedImages?.includes(image?.id)}
            onChange={() => {}}
            className={`z-5 ${styles["image-checkbox"]}`}
          />
        )}
        <Image
          src={image?.url || localImageUrl}
          alt="Image"
          width="100%"
          height="100%"
          className={load ? "opacity-0" : " h-full w-full"}
          onError={(e) => (e.target.src = "/images/ImgPlaceholder.svg")}
          onLoad={handleImageLoaded}
          preview={true && !select}
        />
      </div>

      <Text type={"sub-heading"}>
        <div className="flex w-full justify-content-between align-items-center p-1 font-semibold">
          {!edit ? (
            <div
              className={`${styles["ellipsis-text"]} cursor-pointer`}
              onClick={editFileName}
              style={{ fontSize: "14px" }}
            >
              {image?.name || image?.fileName}
            </div>
          ) : (
            <input
              className={`${styles["img"]}`}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          )}

          <div className="flex gap-2">
            {isSaved || uploaded ? (
              <i
                className={`pi ${
                  edit ? "pi pi-check cursor-pointer" : "pi-check-circle "
                }`}
                style={{ fontSize: "12px", color: "green" }}
                onClick={edit ? saveChanges : () => {}}
              />
            ) : (
              <></>
            )}
            {isUploading ? (
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "12px" }}
              />
            ) : !edit ? (
              accesskey[modulesName.MEDIA]?.includes(permissions.DELETE) && (
                <i
                  className="pi pi-trash cursor-pointer"
                  onClick={() => setDeleteConfirm(true)}
                  style={{ fontSize: "12px" }}
                />
              )
            ) : (
              <i
                className="pi pi-times cursor-pointer"
                onClick={() => {
                  setName(image?.name || image?.fileName);
                  setEdit(false);
                }}
                style={{ fontSize: "12px" }}
              />
            )}
          </div>
        </div>

        <Text
          type={"sub-heading"}
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            flexGrow: "1",
            fontSize: "12px",
          }}
          cust="pl-1"
        >
          {dateCompiler(image?.updatedAt ? image?.updatedAt : new Date())}
        </Text>
      </Text>

      {isUploading && (
        <div className="my-2">
          <ProgressBar
            value={uploadPercentage}
            className="w-full custom-progess-img"
            style={{ height: "10px", fontSize: "0.6rem" }}
          />
        </div>
      )}

      {!uploaded && !valideImage(image) && (
        <Text type={"small-text"} color={"red"}>
          Image size should be less then 8MB
        </Text>
      )}
    </div>
  );
}
export default ImagePreview;
