import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CustomButton } from "../../components/CustomButton";
import { ReactComponent as Filter } from "../../assets/svgIcons/filter-icon.svg";
import { InputText } from "primereact/inputtext";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog"; // For the popup modal

import {
  changeAgentFilter,
  changeAgentSearchtUser,
  changeTicketStatusFilter,
} from "../../reducers/enqChatSlice";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import { API_GET_ALL_AGENTS } from "../../api/account.service";
import ToggleCard from "../../components/ToggelCad/Index";

const Search = () => {
  const [username, setUsername] = useState("");
  const [err, setErr] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false); 
  const [selectedStatus, setSelectedStatus] = useState(""); 
  const [selectedAgent, setSelectedAgent] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [allAgents, setAllAgents] = useState([]);
  const filterButtonRef = useRef(null);  
  const navigate = useNavigate();
  const { ticketStatusFilter } = useSelector((state) => state.enqChats);
  const dispatch = useDispatch();
  const { mob } = useParams();
  const { accesskey } = useSelector((state) => state.authenticate);
  const { allotedAgent } = useSelector((state) => state.enqChats);
  useEffect(() => {
    const debouncedSearch = setTimeout(() => {
      dispatch(changeAgentSearchtUser(username));
    }, 1000);
    return () => {
      clearTimeout(debouncedSearch);
    };
  }, [username]);

  useEffect(() => {
    if (mob && mob?.length > 4) {
      setUsername(mob);
      dispatch(changeAgentSearchtUser(mob));
    }
  }, [mob]);

  useEffect(() => {
    if (accesskey[modulesName.SALES]?.includes(permissions.CREATE)) {
      API_GET_ALL_AGENTS()
        .then((data) => {
          let supportAgents = data.user.rows.map((agent) => {
            return { value: agent?.id, label: agent?.userName };
          });
          setAllAgents(supportAgents);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
  }, [isDialogVisible]);
  // Ticket status options
  const ticketStatusOptions = [
    { label: "All", value: "" },
    { label: "New", value: "New" },
    { label: "In Progress", value: "In Progress" },
    { label: "Follow Up", value: "Follow Up" },
    { label: "Closed", value: "Closed" },
  ];

  // Other filter options (can be customized as per requirement)
  const handleSave = () => {
    dispatch(changeTicketStatusFilter(selectedStatus)); // Update status filter in Redux
    dispatch(changeAgentFilter(selectedAgent));
    setIsDialogVisible(false); // Close the dialog
  };

  const handleClear = () => {
    setSelectedStatus(""); // Clear selected status
    setSelectedAgent(""); // Clear other filter
    dispatch(changeTicketStatusFilter('')); // Update status filter in Redux
    dispatch(changeAgentFilter(''));
    setIsDialogVisible(false);
  };

  const handleFilterButtonClick = () => {
    setIsDialogVisible(true); // Show the card
  };

  return (
    <div className="search">
      <div className="searchForm flex gap-2 align-items-center justify-content-center pl-2 p-1">
        <div className="flex-grow-1">
          <span className="p-input-icon-right w-full">
            {!username || username?.length === 0 ? (
              <i className="pi pi-search" />
            ) : (
              <i
                className="pi pi-times cursor-pointer"
                onClick={() => {
                  setUsername("");
                  navigate(`/chat`);
                }}
              />
            )}
            <InputText
              type="text"
              placeholder="Search customer"
              onChange={(e) => {
                setInitialLoad(false);
                setUsername(e.target.value);
              }}
              value={username}
              className="chat-search capitalize"
            />
          </span>
        </div>
        <div style={{ height: "31.75px" }}>
          <CustomButton
            ref={filterButtonRef} // Attach the ref to the filter button
            varient="cus-filter-chat outline"
            type="button"
            icon={<Filter />}
            onClick={handleFilterButtonClick} // Open the dialog below the filter button
          />
        </div>
      </div>
      <ToggleCard
        isVisible={isDialogVisible}
        setIsVisible={setIsDialogVisible}
        cardRef={filterButtonRef}
        header={"Chat Filter"}
      >
        <div>
          <div className="field">
            <label htmlFor="ticketStatus">Select Ticket Status</label>
            <Dropdown
              id="ticketStatus"
              value={selectedStatus}
              options={ticketStatusOptions}
              onChange={(e) => setSelectedStatus(e.value)}
              placeholder="Select a Status"
              className="w-full"
            />
          </div>
          {allotedAgent && allotedAgent?.role === "support" ? (
            <></>
          ) : (
            <div className="field">
              <label htmlFor="otherFilter">Select an Agent</label>
              <Dropdown
                id="otherFilter"
                value={selectedAgent}
                options={allAgents}
                onChange={(e) => setSelectedAgent(e.value)}
                placeholder="Select an Option"
                className="w-full"
              />
            </div>
          )}
          <div className="custom-dialog-footer">
            <CustomButton
              varient="outline"
              type="button"
              label="Clear"
              onClick={handleClear}
            />
            <CustomButton
              varient="filled"
              type="submit"
              label={"Save"}
              onClick={handleSave}
            />
          </div>
        </div>
      </ToggleCard>
      {err && <span>Customer not found!</span>}
    </div>
  );
};

export default Search;
