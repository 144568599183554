import { Avatar } from "primereact/avatar";
import { Text } from "../../../../components/Text";

const ContactPlate = (props) => {
  const { contact } = props;
  const { name, phones } = contact;
  return (
    <div className="mb-3">
      <div className="flex align-items-center">
        <Avatar icon="pi pi-user" shape="circle" />
        <Text type={"heading"} cust="ml-2">
          {name.formatted_name}
        </Text>
      </div>
      <div>
        {phones?.map((item) => (
          <>
            <Text type={"sub-heading"} cust=" mt-1">
              <span className="mr-1">{item?.type}</span>:
              <span className="ml-1">{item?.phone}</span>
            </Text>
          </>
        ))}
      </div>
    </div>
  );
};

export default ContactPlate;
