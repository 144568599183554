import React from "react"; 
import ErrorPage from "./ErrorPage";

class ErrorBoundary extends React.Component { 
  constructor(props) { 
    super(props); 
    this.state = { error: null, errorInfo: null }; 
  } 
  
  componentDidCatch(error, errorInfo) {  
    this.setState({ 
      error: error, 
      errorInfo: errorInfo 
    }) 
  
  } 
  
  render() { 
    if (this.state.errorInfo) { 
      return ( 
        <div> 
          <ErrorPage error={{...this.state}}/>
        </div> 
      );    
    } 
    return this.props.children; 
  } 
} 
export default ErrorBoundary;