import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getDate } from "../../utils/datemaker";
import CustomPaginator from "../../components/CustomPaginator";
import { useEffect, useState, useRef } from "react";
import SkalebotCarousel from "../../components/SkalebotCarousel";
//import SbDropdown from "../../components/CustomDropDown/Dropdown";
import sampleCampaignData from "./sampleCampaigndata.json";
import { useSelector, useDispatch } from "react-redux";
import PaymentGateway from "../../components/PaymentGateway";
import {
  getCampaigns,
  changeSelectedCampaign,
  changePage,
  resetSelectedCampaign,
  changelimit,
  rchangeSelectedCampaign,
  setrerunCampaign,
  getCampaignCustomers,
} from "../../reducers/campaignSlice";
import { API_GET_CAMPAIGNS } from "../../api/campaign.service";
import { changeShowGroupShareDialog } from "../../reducers/appSlice";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Text } from "../../components/Text";

var schedule = true;
export const CampaignTable = () => {
  const globalFilterValue = useRef("");

  const [campaignOptions, setCampaignOptions] = useState([
    { id: "all", campaignName: "All Campaigns" },
  ]);
  const [campaignOpationpage, seCampaignOptionPage] = useState(0);
  const [gateway, setGateway] = useState(false);

  const {
    campaignData,
    page,
    limit,
    campaignCount,
    selectedCampaign,
    campaignType,
    selectedCampaignId,
  } = useSelector((state) => state.campaign);

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const resetDate = () => {
    setFromDate(null);
    setToDate(null);
  };

  const handlegateway = () => {
    // setGateway(true);
  };

  const gatewayPopup = () => {
    return (
      <PaymentGateway
        gateway={gateway}
        setGateway={setGateway}
        category={"SUBSCRIPTION"}
        //toast={toast}
      />
    );
  };

  const { userSub } = useSelector((state) => state.authenticate);

  const dispatch = useDispatch();

  useEffect(() => {
    globalFilterValue.current = "";
  }, [userSub.isUserSub]);

  const imageBodyTemplate = (rowData) => {
    return <SkalebotCarousel productItems={rowData.productItems} />;
  };

  const handleCampaignSelect = (campaign) => {
    if (selectedCampaignId !== campaign.id) {
      dispatch(changeSelectedCampaign(campaign));
    }
    // console.log(campaign)
  };

  const reRunC = (rowData) => {
    const event = (e) => {
      e.stopPropagation();
      if (selectedCampaignId !== rowData.id) {
        // console.log(rowData.id)
        dispatch(setrerunCampaign(true));
        dispatch(rchangeSelectedCampaign(rowData));
        dispatch(changeShowGroupShareDialog(true));
        dispatch(
          getCampaignCustomers({ id: rowData.id, page: 0, limit: 10000000 })
        )
          .unwrap()
          .then((e) => {
            console.log(e);
          });
      }
    };
    const Cancelevent = (e) => {
      e.stopPropagation();
      if (selectedCampaignId !== rowData.id) {
        // console.log(rowData.id)
        dispatch(setrerunCampaign(true));
        dispatch(rchangeSelectedCampaign(rowData));
        dispatch(changeShowGroupShareDialog(true));
        dispatch(
          getCampaignCustomers({ id: rowData.id, page: 0, limit: 10000000 })
        )
          .unwrap()
          .then((e) => {
            console.log(e);
          });
      }
    };
    return (
      <div className="flex gap-2">
        <div onClick={event}>Rerun Campaign</div>
        {/* <div onClick={Cancelevent} className="text-xl">❌</div> */}
      </div>
    );
  };

  const dropdownScroll = (dpRefCurrent) => {
    const { scrollTop, scrollHeight, clientHeight } = dpRefCurrent;
    if (
      Math.floor(scrollTop + clientHeight) === scrollHeight ||
      Math.ceil(scrollTop + clientHeight) === scrollHeight
    ) {
      if (campaignOptions.length - 1 < campaignCount) {
        seCampaignOptionPage((prev) => prev + 1);
      }
    }
  };

  useEffect(() => {
    if (userSub.isUserSub) {
      const fetchData = async () => {
        const { rows } = await API_GET_CAMPAIGNS(
          campaignOpationpage,
          20,
          "",
          "",
          "",
          true
        );
        setCampaignOptions((prev) => [...prev, ...rows]);
      };
      fetchData();
    }
  }, [campaignOpationpage, userSub.isUserSub]);

  useEffect(
    () => {
      if (userSub.isUserSub) {
        const paginationData = managePaginationData();
        dispatch(getCampaigns(paginationData))
          .unwrap()
          .catch((err) => {
            //show toast here
          });
      }
    },
    toDate
      ? [page, userSub.isUserSub, limit, toDate, fromDate]
      : [page, userSub.isUserSub, limit, toDate]
  );

  //date filter----------------

  const managePaginationData = () => {
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let paginationData = {
      page: page,
      limit: limit,
      startDate: fromDate
        ? new Date(fromDate - tzoffset).toISOString().substring(0, 10)
        : fromDate,
      endDate: toDate
        ? new Date(toDate - tzoffset).toISOString().substring(0, 10)
        : toDate,
      globalFilterValue: globalFilterValue.current,
      schedule,
    };
    return paginationData;
  };

  //search

  const [isSearch, setIsSearch] = useState(true);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    setGlobalFilterValue1(value);
    globalFilterValue.current = value;
    setIsSearch(true);
    if (globalFilterValue.current === "") {
      let paginationData = managePaginationData();
      dispatch(getCampaigns(paginationData))
        .unwrap()
        .catch(() => {
          //show toast from here
        });
    }
  };
  const onGlobalFilterClick = (e) => {
    if (globalFilterValue.current) {
      if (isSearch) {
        let paginationData = managePaginationData();
        dispatch(getCampaigns(paginationData))
          .unwrap()
          .catch(() => {
            //show toast from here
          });
        setIsSearch(false);
      } else {
        globalFilterValue.current = "";
        let paginationData = managePaginationData();
        dispatch(getCampaigns(paginationData))
          .unwrap()
          .catch(() => {
            //show toast from here
          });
        setIsSearch(true);
      }
    }
  };

  function handelKeyDown(e) {
    if (e.key === "Enter") {
      onGlobalFilterClick();
    }
  }

  // const renderHeader1 = () => {
  //   return (
  //     <div className="w-full flex justify-content-between align-items-center flex-wrap gap-2 ">
  //       <div className="w-full lg:w-max">
  //         <SbDropdown
  //           className="standardTable"
  //           options={
  //             userSub.isUserSub 
  //               ? campaignOptions
  //               : sampleCampaignData[0]
  //           }
  //           selectedOptionlabel={"campaignName"}
  //           selectedOption={selectedCampaign}
  //           onChange={handleCampaignSelect}
  //           onScroll={dropdownScroll}
  //         ></SbDropdown>
  //       </div>
  //       {selectedCampaignId === "all" && (
  //         <div className="flex justify-content-between flex-grow-1 gap-2 flex-wrap">
  //           <div className="w-full lg:w-max">
  //             <div className="flex gap-2">
  //               <div className="w-full flex justify-content-between align-items-center gap-2">
  //                 <Text type={"heading"}>From</Text>
  //                 <Calendar
  //                   showIcon={true}
  //                   placeholder="dd/mm/yyyy"
  //                   dateFormat="dd/mm/yy"
  //                   className={`sklbt-calender `}
  //                   value={fromDate}
  //                   onChange={(e) => setFromDate(e.value)}
  //                   maxDate={new Date()}
  //                 ></Calendar>
  //               </div>

  //               <div className="w-full flex justify-content-between align-items-center gap-2">
  //                 <Text type={"heading"}>To</Text>
  //                 <Calendar
  //                   showIcon={true}
  //                   placeholder="dd/mm/yyyy"
  //                   dateFormat="dd/mm/yy"
  //                   className={`sklbt-calender`}
  //                   value={toDate}
  //                   onChange={(e) => setToDate(e.value)}
  //                   maxDate={new Date()}
  //                   minDate={fromDate}
  //                 ></Calendar>

  //                 {(fromDate || toDate) && (
  //                   <Button
  //                     type="button"
  //                     icon={"pi pi-times"}
  //                     style={{
  //                       backgroundColor: "transparent",
  //                       border: "transparent",
  //                       color: "black",
  //                       fontWeight: "200",
  //                     }}
  //                     className="__searchButton border-300"
  //                     onClick={resetDate}
  //                   />
  //                 )}
  //               </div>
  //             </div>
  //           </div>
  //           <div className="w-full lg:w-max">
  //             <div className="flex __search">
  //               <InputText
  //                 ref={globalFilterValue}
  //                 value={globalFilterValue.current}
  //                 className="w-full __searchInput"
  //                 onChange={onGlobalFilterChange1}
  //                 placeholder="Search by Campaign Name"
  //                 onKeyPress={handelKeyDown}
  //               />
  //               <div className="flex  gap-1 lg:mt-0 ">
  //                 <Button
  //                   type="button"
  //                   icon={isSearch ? "pi pi-search" : "pi pi-times"}
  //                   style={{
  //                     backgroundColor: "#ededed",
  //                     color: "black",
  //                     fontWeight: "200",
  //                   }}
  //                   className="__searchButton border-300"
  //                   onClick={onGlobalFilterClick}
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   );
  // };
  //const header1 = renderHeader1();

  const nameBody = (rowData) => {
    return (
      <div className="flex">
        <div
          style={{
            width: "auto",
            minWidth: "50px",
            maxWidth: "150px",
            textOverflow: "ellipsis",
          }}
        >
          {rowData?.campaignName}
        </div>
        {rowData.scheduleCampaignId !== null && (
          <div className="text-xl">⏰</div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className={`py-6`}>
        {userSub.isUserSub && userSub.userSubType === "standard" && (
          <div className="disableTableForPro">
            <div className="my-3 flex justify-content-start align-items-center gap-5">
              {gateway && gatewayPopup()}
            </div>
            <DataTable
              value={sampleCampaignData[0]}
              key="id"
              selectionMode="single"
              selection={"all"}
              onSelectionChange={(e) => handleCampaignSelect(e.value)}
              className="skalebot-table standardTable"
              responsiveLayout="scroll"
              emptyMessage="No data found."
              resizableColumns
              columnResizeMode="expand"
              globalFilterFields={["date"]}
              // header={header1}
            >
              <Column field="campaignName" header="Campaign Name " />
              <Column
                sortable={false}
                field="createdAt"
                header="Date"
                body={(rowData) => getDate(rowData.createdAt)}
                bodyStyle={{ textTransform: "uppercase" }}
              />
              <Column
                field="message"
                header={"Message"}
                body={"Campaign Information"}
                bodyStyle={{ minWidth: "150px" }}
              />
              <Column
                field="conversationsCount"
                header="Conversations count"
                body={"45"}
              />
              <Column
                header="Image"
                body={imageBodyTemplate}
                bodyStyle={{ display: "flex", justifyContent: "center" }}
                headerStyle={{ display: "flex", justifyContent: "center" }}
              ></Column>
            </DataTable>
            <div className="hideContent">
              <div className="__card">
                <div
                  className="__card-body cursor-pointer"
                  onClick={handlegateway}
                >
                  Unlock With Pro Subscription
                </div>

                <i className="pi pi-unlock mx-1"></i>
              </div>
            </div>
          </div>
        )}

        {userSub.isUserSub&& (
          <>
            <DataTable
              value={
                selectedCampaignId == "all" ? campaignData : [selectedCampaign]
              }
              key="id"
              selectionMode="single"
              selection={selectedCampaignId}
              onSelectionChange={(e) => handleCampaignSelect(e.value)}
              className="skalebot-table"
              responsiveLayout="scroll"
              emptyMessage="No data found."
              resizableColumns
              columnResizeMode="expand"
              globalFilterFields={["date"]}
              //header={header1}
            >
              <Column
                field="campaignName"
                header="Campaign Name "
                bodyStyle={
                  selectedCampaignId == "all"
                    ? {
                        width: "auto",
                        minWidth: "50px",
                        maxWidth: "150px",
                        textOverflow: "ellipsis",
                      }
                    : {}
                }
                body={nameBody}
              />

              <Column
                field="createdAt"
                header="Date"
                body={(rowData) => getDate(rowData.createdAt)}
                bodyStyle={{ textTransform: "uppercase" }}
              />

              <Column
                field="startDate"
                header="Schedule Date"
                body={(rowData) => {
                  let data = new Date(rowData.startDate);
                  let data2 = new Date(rowData.endDate);
                  return (
                    <div>
                      {`${data.getDate()}/${
                        data.getMonth() + 1
                      }/${data.getFullYear()}`}{" "}
                      -{" "}
                      {`${data2.getDate()}/${
                        data2.getMonth() + 1
                      }/${data2.getFullYear()}`}
                    </div>
                  );
                }}
                bodyStyle={{ textTransform: "uppercase" }}
              />

              <Column
                field="startDate"
                header="Schedule For"
                body={(rowData) => {
                  let data = new Date(rowData.startDate);
                  let data2 = new Date(rowData.endDate);
                  let limit = data2.getDate() - data.getDate();
                  const weekday = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ];
                  return <div>{limit} days</div>;
                }}
                bodyStyle={{ textTransform: "uppercase" }}
              />

              <Column
                field="startDate"
                header="Schedule Date"
                body={(rowData) => {
                  let data = new Date(rowData.startDate);
                  let data2 = new Date(rowData.endDate);
                  let limit = data2.getDate() - data.getDate();
                  const weekday = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ];
                  const totaldays = rowData?.dayOfWeek
                    ?.map((x) => {
                      return weekday[x];
                    })
                    .join(" / ");
                  console.log(limit);
                  return <div>{totaldays}</div>;
                }}
                bodyStyle={{ textTransform: "uppercase" }}
              />

              <Column
                field="message"
                header={"Message"}
                bodyStyle={
                  selectedCampaignId == "all"
                    ? {
                        width: "auto",
                        minWidth: "150px",
                        maxWidth: "350px",
                        textOverflow: "ellipsis",
                      }
                    : {}
                }
              />
              <Column
                header="Image"
                body={imageBodyTemplate}
                bodyStyle={{ display: "flex", justifyContent: "center" }}
                headerStyle={{ display: "flex", justifyContent: "center" }}
              ></Column>

              {selectedCampaignId === "all" ? (
                <Column
                  body={"View details ➡️"}
                  bodyStyle={{ color: "#005068", minWidth: "120px" }}
                />
              ) : (
                <></>
              )}
            </DataTable>
            {selectedCampaignId === "all" && (
              <div className="flex  justify-content-end">
                <CustomPaginator
                  page={page}
                  limit={limit}
                  changelimit={changelimit}
                  totalRecords={campaignCount}
                  changePage={changePage}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
