import React, { Fragment, useState } from "react";
import {
  Page,
  Document,
  StyleSheet,
  Image,
  Text,
  View,
} from "@react-pdf/renderer";
import { getDate } from "../../utils/datemaker";
import skalebotlogo from "../../assets/skalebotlogo.png";
import calendarIcon from '../../assets/orderInvoiceIcons/calendarIcon.png'
import companyIcon from '../../assets/orderInvoiceIcons/companyIcon.png'
import emailIcon from '../../assets/orderInvoiceIcons/emailIcon.png'
import rupeeIcon from '../../assets/orderInvoiceIcons/rupeeIcon.png'

// Table
const stylesTable = StyleSheet.create({
  tableContainer: {
    marginTop: 5,
    marginBottom: 5,
    backgroundColor: "#fff",
  },
  marginTopBottom: {
    marginBottom: "10px",
  },
});

const InvoiceItemsTable = ({ order, invoice }) => {
  
  return (
    <>
    <View style={{ backgroundColor: "#fff" }}>
      {order.productItems.map((item, index) => (
        <View style={stylesTable.tableContainer} key={index}>
          <InvoiceTableRow item={item} />
        </View>
      ))}
    </View>
    </>
  );
};

// Table Header
const stylesTableHeader = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "start",
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Helvetica",
    color: "rgba(0, 0, 0, 0.65)",
    borderStyle: "solid",
    borderBottomWidth: 0.2,
    borderBottomColor: "rgb(150, 150, 150)",
    backgroundColor: "#fff",
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: "12px",
    paddingBottom: "6px",
  },
  id: {
    width: "15%",
    color: "black",
    fontSize: 10,
  },
  price: {
    width: "20%",
    color: "black",
    fontSize: 10,
  },
  name: {
    width: "35%",
    color: "black",
    fontSize: 10,
  },
  phone: {
    width: "20%",
    color: "black",
    fontSize: 10,
  },
  space: {
    width: "10%",
    color: "black",
    fontSize: 10,
  },
});

const InvoiceTableHeader = () => (
  <View style={stylesTableHeader.container}>
    <Text style={stylesTableHeader.id}>Item Code</Text>
    <Text style={stylesTableHeader.name}>Item Name</Text>
    <Text style={stylesTableHeader.price}>Price</Text>
    <Text style={stylesTableHeader.phone}>Qty</Text>
    <Text style={stylesTableHeader.space}>Total</Text>
  </View>
);

const stylesTableFooter = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: 'center',
    backgroundColor: "#008069",
    paddingLeft: '15px',
    paddingRight: '25px',
    paddingTop: "6px",
    paddingBottom: "4px",
    marginTop: 10,
    marginBottom: 10,
    width: "100%",
    color: "white",
    borderRadius: '4px'
  },
  textPrice: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 12,
  }
});

const stylesTableAddress = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: "6px",
    paddingBottom: "4px",
    marginTop: 10,
    width: "100%",
  },
  textAddress: {
    fontSize: 10,
    color: "gray",
    marginTop: 4,
  },
});

const InvoiceTableFooter = ({ order }) => {
  const calculateTotalPrice = () => {
    let total = 0;
    order?.productItems.forEach((product) => {
      total += product.price * product.quantity;
    });
    return total;
  };
  return(
  <View style={stylesTableFooter.container}>
    <Text style={stylesTableFooter.textTotal}>Total</Text>
    {/* <Text style={stylesTableFooter.textPrice}>Rs {order?.totalPrice}</Text> */}
    <Text style={stylesTableFooter.textPrice}>Rs {calculateTotalPrice()}</Text>
  </View>
  )
  };

const InvoiceTableAddress = ({ invoice }) => (
  <View style={stylesTableAddress.container}>
    <Text style={stylesTableAddress.textTotal}>{invoice.companyName}</Text>
    <Text style={stylesTableAddress.textAddress}>{invoice.companyAddress}</Text>
  </View>
);

// Table Row
const stylesTableRow = StyleSheet.create({
  header: {
    flexDirection: "row",
    paddingLeft: '15px',
    paddingRight: '15px',
    textAlign: 'left'
  },
  id: {
    width: "15%",
    fontSize: 10,
    color: "rgb(80,80,80)",
    textOverflow: 'ellipsis'
  },
  priceContainer: {
    width: "20%",
    display: 'flex',
    flexDirection: 'row',
  },
  price: {
    fontSize: 10,
    color: "rgb(80,80,80)",
  },
  name: {
    width: "35%",
    fontSize: 10,
    color: "rgb(80,80,80)",
    textOverflow: 'ellipsis'
  },
  qty: {
    width: "20%",
    fontSize: 10,
    color: "rgb(80,80,80)",
  },
  total: {
    width: "10%",
    fontSize: 10,
    color: "rgb(80,80,80)",
    display: 'flex',
    flexDirection: 'row',
    textOverflow: 'ellipsis',
    textAlign: 'right'
  },
});

const InvoiceTableRow = ({ item }) => {
  return (
    <View style={stylesTableRow.header}>
      <Text style={stylesTableRow.id}>{item?.SKUCode}</Text>
      <Text style={stylesTableRow.name}>{item?.productName}</Text>
      <View style={stylesTableRow.priceContainer}>
        <Image
          source={rupeeIcon}
          style={{ width: 9, height: 9, marginTop: 1, marginRight: 2 }}
        />
        <Text style={stylesTableRow.price}>{item?.price}</Text>
      </View>
      <Text style={stylesTableRow.qty}>{item?.quantity}</Text>
      <View style={stylesTableRow.total}>
        <Image
            source={rupeeIcon}
            style={{ width: 9, height: 9, marginTop: 1, marginRight: 2 }}
          />
        <Text>
          {item?.price * item?.quantity}
        </Text>
      </View>
    </View>
  );
};

// Company Details
const stylesCompanyDetails = StyleSheet.create({
  companyName: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    marginBottom: 4,
  },
  invoice: {
    marginTop: 20,
    marginLeft: 15,
    marginRight: 15,
    paddingBottom: 5,
    fontSize: 12.5,
    borderBottomWidth: 0.2,
    borderBottomColor: "rgb(150, 150, 150)",
  },
  line: {
    marginTop: 5,
    marginLeft: 15,
    marginRight: 15,
    fontSize: 12.5,
    borderBottomWidth: 0.2,
    borderBottomColor: "rgba(150, 150, 150, 0.1)",
  },
  invoiceNumberText: {
    marginTop: 6,
    marginLeft: 15,
    marginRight: 15,
    fontSize: 10,
    width: '150px',
    height: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2
  },
  subContainerBottomText: {
    fontSize: 10,
  },
  subContainerBottomText2: {
    fontSize: 10,
    marginLeft: 5,
    color: "rgb(90,90,90)",
  },
  address: {
    fontSize: 10.5,
    color: "rgba(0,0,0,0.65)",
    width: "70%",
    textAlign: "right",
    alignSelf: "flex-end",
  },
  companyLabel: {
    fontSize: 10,
    color: "gray",
    marginLeft: 15,
    marginTop: 4,
  },
  Label: {
    fontSize: 10,
    color: "gray",
  },
  gstin: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    marginBottom: 5,
    marginRight: 15,
  },
  gstinText: {
    marginLeft: 15,
    fontSize: 10,
  },
  bottomDetail: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 15,
    paddingTop: 7,
    paddingRight: 15,
    paddingBottom: 3,
  },
  imageIcon: {
    width: 12,
    height: 12,
  },
  labelIcon: {
    marginTop: 4,
    marginLeft: 2,
  }
});


const CompanyDetails = ({ order, invoice }) => (
  <View>
    <View style={styles.subContainerInvoice}>
      <View style={styles.subContainerLeft}>
        <Text style={{marginTop: 12, marginLeft: 15, fontSize: 12}}>Invoice</Text>
        <Text style={stylesCompanyDetails.line}></Text>
        <View style={stylesCompanyDetails.invoiceNumberText}>
          <Image
            source={calendarIcon} 
            style={stylesCompanyDetails.imageIcon}
          />
          <Text style={{marginTop: 5, marginLeft: 2}}>Date</Text>
        </View>
        <Text style={stylesCompanyDetails.companyLabel}>
          {new Date(order?.updatedAt).toISOString().split("T")[0]}
        </Text>
      </View>

      <View style={styles.subContainerRight}>
        <View style={stylesCompanyDetails.invoiceNumberText}>
          <Image
            source={companyIcon} 
            style={stylesCompanyDetails.imageIcon}
          />
          <Text style={stylesCompanyDetails.labelIcon}>Company Name</Text>
        </View>
        <Text style={stylesCompanyDetails.companyLabel}>
          {invoice.companyName}
        </Text>

        <View style={stylesCompanyDetails.invoiceNumberText}>
          <Image
            source={emailIcon}
            style={stylesCompanyDetails.imageIcon}
          />
          <Text style={stylesCompanyDetails.labelIcon}>Contact</Text>
        </View>
        <Text style={stylesCompanyDetails.companyLabel}>
          {invoice.companyEmail}
        </Text>
        <Text style={stylesCompanyDetails.line}></Text>
        <View style={stylesCompanyDetails.gstin}>
          <Text style={stylesCompanyDetails.gstinText}>GSTIN</Text>
          <Text style={stylesCompanyDetails.companyLabel}>{invoice.GSTIN}</Text>
        </View>
      </View>
    </View>
    <View style={styles.subContainerBottom}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: "10px" }}>Order No. : </Text>
        <Text style={stylesCompanyDetails.Label}>{order?.orderNumber}</Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: "10px" }}>Customer Name : </Text>
        <Text style={stylesCompanyDetails.Label}>{order?.name}</Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: "10px" }}>Phone : </Text>
        <Text style={stylesCompanyDetails.Label}>+{order?.phone}</Text>
      </View>
    </View>
  </View>
);
const Footer=()=>{
  return (<Text style={styles.skfooter}>
  Powered by <Text style={styles.skfooterlogo}>Skalebot</Text>
</Text>)
}
// Main Component
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    backgroundColor: "#FAF9F6",
    height: "100%",
    fontSize: 11,
    lineHeight: 1.5,
    flexDirection: "column",
    paddingTop: 25,
    paddingLeft: 45,
    paddingRight: 45,
    paddingBottom: 30,
    position: "relative",
  },
  containerWrap: {
    maxHeight: '40px',
    maxWidth: '50px',
    marginBottom: 3
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: "rgb(22, 35, 87)",
  },
  subContainerInvoice: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "10px",
  },

  subContainerRight: {
    width: "63%",
    backgroundColor: "#fff",
  },
  subContainerLeft: {
    width: "35%",
    backgroundColor: "#fff",
    paddingBottom: 20,
  },
  subContainerBottom: {
    backgroundColor: "#fff",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "flex",
    paddingTop: "12px",
    paddingBottom: "6px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  skContainer: {
    width: 80,
    height: 38,
    padding: 4,
    border: 1,
    borderColor: "rgba(238,238,238,1)",
    position: "absolute",
    bottom: 5,
    left: 45,
  },
  txt: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  skbotlogo: {
    width: 57,
    height: 18,
  },
  skbotText: {
    fontSize: 6,
    marginTop: 4,
    marginRight: 3,
  },
  skbotTxt: {
    fontFamily: "Helvetica-Bold",
    fontSize: 6,
    marginTop: 4,
  },
  skfooter:{
    position:'absolute',
    fontSize:8,
    bottom:10,
    right:10,
    textAlign:'right',
    color:'#000',
  },
  skfooterlogo:{
    color:'#005068'
  }
});

const PdfDocument = ({ invoicedata }) => {
  return (
    <Document>
      {invoicedata?.orders.map((item, index) => (
        <Page key={index} size="A4" style={styles.page}>
          <>
            <View style={styles.containerWrap}>
              <Image
                style={styles.logo}
                src={invoicedata.logo ? invoicedata.logo : "abc.png"}
              />
            </View>

            <CompanyDetails order={item} invoice={invoicedata} />

            <InvoiceTableHeader />
            <InvoiceItemsTable order={item} invoice={invoicedata} />
            <InvoiceTableFooter order={item} />

            <InvoiceTableAddress invoice={invoicedata} />
            <Footer/>
          </>
        </Page>
      ))}
      
    </Document>
  );
};

export default PdfDocument;
