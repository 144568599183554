const getSKUCodes = (products) => {
  const skuCodes = [];
  for (let i = 0; i < products.length; i++) {
    skuCodes.push(products[i].SKUCode);
  }
  return skuCodes;
};

const getIds = (elementsArr) => {
  const ids = [];
  for (let i = 0; i < elementsArr.length; i++) {
    ids.push(elementsArr[i].id);
  }
  return ids;
};
const calculateConversationCharge = (customerArr) => {
  let indianCharge = 1;
  let internationCharge = 12;

  let totalCharges = 0;

  customerArr.forEach((customer) => {
    if (customer.phone.toString().slice(0, 2) == "91")
      totalCharges += indianCharge;
    else totalCharges += internationCharge;
  });

  return totalCharges;
};

export { getSKUCodes, getIds, calculateConversationCharge };
