import { CustomButton } from "../CustomButton"
import { useEffect, useState } from "react";
const defaultToolTip = 'Copy to Clipboard'
function CopyToClipboard({toClipboard, label}){
  const [isCopy, setIsCopy] = useState(false);
  const [tooltip,setTooltip] = useState(defaultToolTip);


  const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
    setIsCopy(true);
    setTooltip("Copied")
  };

  useEffect(()=>{
    if(isCopy){
        setTimeout(()=>{
            setIsCopy(false)
            setTooltip(defaultToolTip)
        },2000)
    }
  },[isCopy])

    return (
        <CustomButton
            varient="icon-button"
            icon={isCopy ? "pi pi-check" : "pi pi-copy"}
            type="button"
            onClick={() => copyToClipboard(toClipboard)}
            label={label}
            tooltip={tooltip}
        />)
}

export default CopyToClipboard;