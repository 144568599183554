import styles from "./style.module.css";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { CustomButton } from "../../components/CustomButton";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector } from "react-redux";
import { addCompany } from "../../reducers/companySlice";
import { signupSendOTP, signupVerifyOTP } from "../../reducers/authSlice";
import Loader from "../../components/Loader";
import { Text } from "../../components/Text";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useLocation} from "react-router-dom";
const SignUp = () => {
  const toast = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [buttonDisabled, setButtondisabled] = useState(false)
  const [otpId, setOtpId] = useState();
  const [companyId, setCompanyId] = useState();
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const [number, setNumber] = useState("");

  const { loading } = useSelector((state) => state.authenticate);
  const { loadingC } = useSelector((state) => state.company);
  const location = useLocation()
  const searchValue = location?.search;
  let promo = null;

  if(searchValue){
    promo = searchValue.split('=')[1]
  }

  const defaultValues = {
    otp: "",
    companyName: "",
    email: "",
    userName: "",
    personalPhone: "",
    password: "",
    confirmPassword: ""
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm({ defaultValues });

  const phone = watch("personalPhone");

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const modalLoad = () => {
    return <Loader visible={loading || loadingC} />;
  };

  const resendOTP = () => {
    const _data = { personalPhone: phone };
    if(promo){
      _data.couponCode = promo;
    }
    dispatch(signupSendOTP({..._data}))
      .unwrap()
      .then((response) => {
        setOtpId(response.otpId);
        toast.current.show({ severity: "success", detail: response.message });
      })
      .catch((error) => {
        toast.current.show({ severity: "error", detail: error.message });
      });

    setMinutes(1);
    setSeconds(30);
  };

  useEffect(() => {
    if (otpSent)
    {
      const interval = setInterval(() => {
        if (seconds > 0)
        {
          setSeconds(seconds - 1);
        }

        if (seconds === 0)
        {
          if (minutes === 0)
          {
            clearInterval(interval);
          } else
          {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, otpSent]);

  const onSubmit = async (data) => {
    data.personalPhone = parseInt(data.personalPhone.substring(1));
    setNumber(data.personalPhone);
    if (otpSent && !otpVerified)
    {
      const _data = {
        personalPhone: data.personalPhone,
        otp: data.otp,
        otpId: otpId
      };

     
      dispatch(signupVerifyOTP({..._data}))
        .unwrap()
        .then((response) => {
          setOtpVerified(true);
          setCompanyId(response.companyId);
          setButtondisabled(true)
          toast.current.show({ severity: "success", detail: response.message });
        })
        .catch((error) => {
          toast.current.show({ severity: "error", detail: error.message });
        });
    } else if (!otpSent)
    {
      const _data = { personalPhone: data.personalPhone };
      if(promo){
        _data.couponCode = promo;
      }
      dispatch(signupSendOTP({..._data}))
        .unwrap()
        .then((response) => {
          setOtpId(response.otpId);
          setOtpSent(true);
          toast.current.show({ severity: "success", detail: response.message });
        })
        .catch((error) => {
          toast.current.show({ severity: "error", detail: error.message });
        });
    } else if (otpVerified)
    {
      if (data.password !== data.confirmPassword)
      {
        toast.current.show({
          severity: "error",
          detail: "Password do not match"
        });
        return;
      }
      data.companyId = companyId;
      delete data.confirmPassword;
      delete data.otp;
      dispatch(addCompany(data))
        .unwrap()
        .then(() => {
          reset();
          navigate("/login");
        })
        .catch((error) => {
          toast.current.show({ severity: "error", detail: error.message });
        });
    }
  };

  return (

    <div className={` ${styles["login-module"]}  `}>
      <Toast ref={toast} />
      {modalLoad()}
      <div className={`${styles["login-form"]}    `}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`p-fluid `}
        >
          {!otpSent && (
            <>
              <p className="text-base font-semibold m-0 line-height-2 pb-1 ">
                Sign up
              </p>
              <p className="text-xs font-normal opacity-50 m-0 line-height-2 pb-1">
                Get started with a demo account on Skalebot
              </p>
              <div className="field pt-3 ">
                <label htmlFor="personalPhone">Phone Number</label>
                <Controller
                  name="personalPhone"
                  control={control}
                  rules={{
                    required: true && "Please enter phone number.",
                    validate: (value) =>
                      isValidPhoneNumber(value.toString()) ||
                      "Please enter a valid phone number."
                  }}
                  render={({ field, fieldState }) => (
                    <PhoneInputWithCountry
                      name="personalPhone"
                      control={control}
                      defaultCountry="IN"
                      id={field.name}
                      placeholder="Enter phone number"
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      className={classNames({
                        "p-invalid": fieldState.invalid
                      })}
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage("personalPhone")}
              </div>
            </>
          )}

          {otpSent && !otpVerified && (
            <>
              <p className="text-base font-semibold m-0 line-height-2 py-1 ">
                Verify OTP
              </p>
              <p className="text-xs font-normal opacity-50 m-0 line-height-2 py-1">
                We've sent you 6 digit otp on{" "}
                <span className="text-xs font-semibold">{number}</span> number
              </p>
              <div className="field pt-3">
                <label htmlFor="otp">OTP</label>
                <Controller
                  name="otp"
                  control={control}
                  rules={{
                    required: true && "Please enter otp.",
                    validate: (value) =>
                      value.trim().length === 6 || "Please enter 6-digit OTP"
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      placeholder="Enter OTP"
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("otp")}
              </div>

              <div className="flex justify-content-between">
                {seconds > 0 || minutes > 0 ? (
                  <Text type={"sub-heading"}>
                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </Text>
                ) : (
                  <Text type={"sub-heading"}>Didn't receive otp?</Text>
                )}

                <button
                  className={`${styles.resendButton} ${seconds > 0 || minutes > 0 ? styles.disabled : ""
                    }`}
                  disabled={seconds > 0 || minutes > 0}
                  onClick={resendOTP}
                >
                  Resend OTP
                </button>
              </div>
            </>
          )}

          {otpVerified && (
            <div>
              <p className="text-base font-semibold m-0 line-height-2 pb-1 ">
                Create Account
              </p>
              <p className="text-xs font-normal opacity-50 m-0 line-height-2 pb-2">
                Ready to unleash the power of Skalebot and elevate your user
                experience.
              </p>
              <div className="field pt-2">
                <label
                  htmlFor="companyName"
                  className={classNames({ "p-error": errors.name })}
                >
                  Company Name*
                </label>
                <Controller
                  name="companyName"
                  control={control}
                  rules={{
                    required: "Please enter company name",
                    validate: (value) =>
                      value.trim() === value ||
                      "Company name should not start or end with white-spaces"
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      className={classNames({
                        "p-invalid": fieldState.invalid
                      })}
                      placeholder="Enter company name"
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage("companyName")}
              </div>
              <div className="field">
                <label
                  htmlFor="userName"
                  className={classNames({ "p-error": errors.name })}
                >
                  Username*
                </label>
                <Controller
                  name="userName"
                  control={control}
                  rules={{
                    required: true && "Please enter user name.",
                    validate: (value) =>
                      (value.trim().length >= 6 &&
                        value.trim().length <= 44) ||
                      "Username should have min 6 or max 44 characters",
                    pattern: {
                      value: /^[^\s]+$/,
                      message: "Username should not have spaces"
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      className={classNames({
                        "p-invalid": fieldState.invalid
                      })}
                      placeholder="Enter username"
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage("userName")}
              </div>
              <div className="field">
                <label
                  htmlFor="email"
                  className={classNames({ "p-error": errors.name })}
                >
                  Email
                </label>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Enter a valid e-mail address"
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      className={classNames({
                        "p-invalid": fieldState.invalid
                      })}
                      placeholder="Enter email"
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage("email")}
              </div>

              <div className="field">
                <label htmlFor="password">Password</label>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: "Please enter a password.",
                    pattern: {
                      value:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+]).{8,}$/,
                      message:
                        "Password must have at least 8 characters, one uppercase, one lowercase, one numeric, and one special character (!@#$%^&*()_+)."
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <span className="p-input-icon-right">
                      <i
                        className={`pi ${showPassword ? "pi-eye" : "pi-eye-slash"
                          }`}
                        onClick={() => setShowPassword((prev) => !prev)}
                      />
                      <InputText
                        type={`${showPassword ? "text" : "password"}`}
                        id={field.name}
                        placeholder="Enter password"
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid
                        })}
                      />
                    </span>
                  )}
                />
                {getFormErrorMessage("password")}
              </div>
              <div className="field">
                <label htmlFor="password">Confirm Password</label>
                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{ required: true && "Re-enter password." }}
                  render={({ field, fieldState }) => (
                    <span className="p-input-icon-right">
                      <i
                        className={`pi ${showPassword2 ? "pi-eye" : "pi-eye-slash"
                          }`}
                        onClick={() => setShowPassword2((prev) => !prev)}
                      />
                      <InputText
                        type={`${showPassword2 ? "text" : "password"}`}
                        id={field.name}
                        placeholder="Confirm password"
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid
                        })}
                      />
                    </span>
                  )}
                />
                {getFormErrorMessage("confirmPassword")}
              </div>
              <div className="flex flex-content-center gap-2">
                <input type="checkbox" name="checkbox" checked={!buttonDisabled} onClick={() => { setButtondisabled(x => !x) }} />
                <div>Preview Your <a className="text-blue-500 cursor-pointer" href="/terms&conditions" target="_blank" >Terms & Conditions</a></div>
              </div>
            </div>
          )}

          <div className="w-5 m-auto mt-5">
            <CustomButton
              type="submit"
              label={
                otpSent && !otpVerified
                  ? "Verify OTP"
                  : otpVerified
                    ? "Save"
                    : "Send OTP"
              }
              disabled={buttonDisabled}
              varient="surface-900  font-medium text-sm border-0 p-3"
            />
          </div>
        </form>

        <div className="mt-3">
          <span>Have an account already? </span>
          <span
            className={`cursor-pointer ${styles.signup}`}
            role="button"
            onClick={() => navigate("/login")}
          >
            Log in
          </span>
        </div>
      </div>


    </div>


  )
}

export default SignUp;
