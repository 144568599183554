import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../../api/axios.instance";
import axios from "axios";
import { Text } from "../../components/Text";
import styles from "./style.module.css";
import { ProgressBar } from "primereact/progressbar";
import "./index.css";
import { Image } from "primereact/image";
import { compressImg } from "../../utils/templateUtils";
import { Skeleton } from "primereact/skeleton";

function ImagePreview({
  imgId = null,
  image = null,
  removeImgClick = () => {},
  handleSaveImages = () => {},
  isSaved = false,
}) {
  const [isUploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(isSaved);
  const [err, setErr] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [localImageUrl, setLocalImageUrl] = useState("");

  const imageUpload = async (image) => {
    try {
      const uploadAt = await axiosInstance.post("/image", {
        imageType: `product`,
      });

      const imageUploadURL = uploadAt?.data.src;

      var config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadPercentage(percentCompleted);
        },
        headers: { "Content-Type": "image/png" },
      };

      image = await compressImg(image);

      await axios.put(imageUploadURL, image, config);
      let imageData = {
        fileName: image.name,
        url: uploadAt?.data.url,
        id: imgId,
      };

      handleSaveImages(imageData);
    } catch (error) {
      throw error;
    }
  };
  const handleRemove = () => {
    if (isSaved) {
      removeImgClick(image);
    } else {
      removeImgClick({ id: imgId }, uploaded);
    }
  };

  const valideImage = (img) => {
    return img?.size <= 1048576 * 8;
  };

  useEffect(async () => {
    if (image != null && !uploaded) {
      var reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (readerEvent) => {
        if (image.type.includes("image")) {
          setLocalImageUrl(readerEvent.target.result);
        }
      };

      if (valideImage(image)) {
        setUploading(true);
        imageUpload(image)
          .then((res) => {
            setUploading(false);
            setUploaded(true);
          })
          .catch((err) => {
            setUploading(false);
            setErr(true);
          });
      } else return;
    }
  }, []);
  const [load, setLoad] = useState(true);
  return (
    <div
      className={`p-1 relative flex flex-column justify-content-between ${styles["preview-container"]}`}
    >
      <div className={`${styles["img-preview-container"]}`}>
        {load && <Skeleton width="100%" height="100%" />}
        <Image
          src={image?.url || localImageUrl}
          alt="Image"
          width="100%"
          height="100%"
          preview
          onLoad={() => {
            setLoad(false);
          }}
          onError={(e) => (e.target.src = "/images/ImgPlaceholder.svg")}
          className="prod-img-up"
        />
      </div>
      <div className="py-2">
        <Text
          type={"sub-heading"}
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            flexGrow: "1",
          }}
        >
          <div className={`${styles["ellipsis-text"]}`}>
            {image?.name || image?.fileName}
          </div>
          {"  "}
          <div className="flex gap-2">
            {isSaved || uploaded ? (
              <i
                className="pi pi-check-circle"
                style={{ fontSize: "12px", color: "green" }}
              />
            ) : (
              <></>
            )}
            {isUploading ? (
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "12px" }}
              />
            ) : (
              <i
                className="pi pi-trash cursor-pointer"
                onClick={handleRemove}
                style={{ fontSize: "12px" }}
              />
            )}
          </div>
        </Text>
      </div>

      {isUploading && (
        <div className="my-2">
          <ProgressBar
            value={uploadPercentage}
            className="w-full custom-progess-img"
            style={{ height: "10px", fontSize: "0.6rem" }}
          />
        </div>
      )}

      {!uploaded && !valideImage(image) && (
        <Text type={"small-text"} color={"red"}>
          Image size should be less then 8MB
        </Text>
      )}
    </div>
  );
}
export default ImagePreview;
