import alosiaSvg from "./team/alosia.svg";
import goldwinPng from "./team/goldwin.png";
import rojaPng from "./team/roja.png";

export const SalesTeamData = [
  {
    name: "Alosia rose",
    position: "Marketing Head",
    mobileNo: "+91  9847536687",
    mailId: "alosia.rose@deskala.in",
    img: alosiaSvg
  },
  {
    name: "Goldwin paul kavala",
    position: "Sr. Business Development Manager",
    mobileNo: "+91  9490337682",
    mailId: "goldwin.paul@deskala.in",
    img: goldwinPng
  },
  {
    name: "Chiruvolu Roja ",
    position: "Sr. Business Development Manager",
    mobileNo: "+91  9113041388",
    mailId: "chiruvolu.roja@deskala.in",
    img: rojaPng
  }
];
