import { Outlet, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
function Bots() {
  const location = useLocation();
  const currentPath = location.pathname;
  if (currentPath == "/bots" || currentPath == "/bots/") {
    return <Navigate to={"/bots/list"} replace />;
  }
  return <Outlet />;
}

export default Bots;
