import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import store from "./store";
import { LayoutProvider } from "./context/LayoutContext";
import ErrorBoundary from "./view/ErrorBoundary";
import { UtilityProvider } from "./context/UtilityContext";

ReactDOM.render(
  <ErrorBoundary>
    <BrowserRouter>
      <LayoutProvider>
        <UtilityProvider>
          <Provider store={store}>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            >
              <App />
            </GoogleOAuthProvider>
          </Provider>
        </UtilityProvider>
      </LayoutProvider>
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
