import React from 'react';
import { useSelector } from 'react-redux';
import styles from './style.module.css';
import { Text } from '../../components/Text';

const ActiveSubscription = () => {
  const { activePlan } = useSelector((state) => state.payment);
  const planStartDate = new Date(activePlan?.createdAt).toLocaleDateString();
  const renewDate = new Date(new Date(activePlan?.createdAt).setMonth(new Date(activePlan?.createdAt).getMonth() + activePlan?.validityMonths)).toLocaleDateString();

  return (
    <div className={`w-full p-3 ${styles['sub-container']}`}>
      <div>
        <div className="flex flex-row justify-content-between">
          <Text type="sub-heading" color="#64748B">Active Subscription</Text>
          {/* <div
            className={`border-1 p-2 border-round cursor-pointer`}
            style={{ background: '#A988CD', color: '#FFFFFF', borderColor: '#FFFFFF' }}
            onClick={() => setSubsModal(true)}
          >
            Upgrade Plan
          </div> */}
        </div>

        <div className="my-3">
          <div className={`mt-2 p-2 flex justify-content-between align-items-center gap-2 flex-wrap ${styles['conversation-container']}`}>
            <div className="flex  align-items-center">
              <Text type="heading">Plan:</Text>
            </div>
            <div className="mr-3">
              <Text type="sub-heading" color="#64748B">
                 Skalebot {activePlan?.name}
              </Text>
            </div>
          </div>
        </div>

        <div className="my-3">
          <div className="flex justify-content-between gap-3 align-items-center w-11">
            <Text type="sub-heading">Platform Charges</Text>
            <Text type="heading">₹{activePlan?.amount}</Text>
          </div>
        </div>
        <div className="my-3">
          <div className="flex justify-content-between gap-3 align-items-center w-11">
            <Text type="sub-heading">Start Date</Text>
            <Text type="heading">{planStartDate}</Text>
          </div>
        </div>
        <div className="my-3">
          <div className="flex justify-content-between gap-3 align-items-center w-11">
            <Text type="sub-heading">End Date</Text>
            <Text type="heading">{renewDate}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveSubscription;
