import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_GET_MEDIA,
  API_MEDIA_DELETE,
  API_MEDIA_DELETE_BULK,
  API_MEDIA_EDIT,
  API_MEDIA_UPLOAD,
} from "../api/imageinventory.service";

const initialState = {
  mediaData: [],
  totalMediaCount: 0,
  loading: false,
  page: 0,
  limit: 30,
};

export const getMedia = createAsyncThunk(
  "mediaTable/getMediaList",
  async (
    { page, limit, startDate, endDate, filterData, globalFilterValue },
    thunkAPI
  ) => {
    try {
      const resp = await API_GET_MEDIA(
        page,
        limit,
        startDate,
        endDate,
        filterData,
        globalFilterValue
      );
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const uploadMedia = createAsyncThunk(
  "mediaTable/uploadMedia",
  async ({ imageData }, thunkAPI) => {
    try {
      const resp = await API_MEDIA_UPLOAD(imageData);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const editMedia = createAsyncThunk(
  "mediaTable/editMedia",
  async ({ imageData }, thunkAPI) => {
    try {
      const resp = await API_MEDIA_EDIT(imageData);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteMedia = createAsyncThunk(
  "mediaTable/media",
  async ({ id }, thunkAPI) => {
    try {
      const resp = await API_MEDIA_DELETE(id);
      return id;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deletebulkMedias = createAsyncThunk(
  "mediaTable/deletebulkMedias",
  async ({ id }, thunkAPI) => {
    try {
      const resp = await API_MEDIA_DELETE_BULK(id);
      return id;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const mediaTableSlice = createSlice({
  name: "mediaTable",
  initialState,
  reducers: {
    changePage(state, action) {
      state.page = action.payload;
    },
    changelimit(state, action) {
      state.limit = action.payload;
    },
    reset(state) {
      state.page = initialState.page;
      state.limit = initialState.limit;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getMedia.fulfilled, (state, action) => {
      state.totalMediaCount = action.payload?.count;
      state.mediaData = action.payload.rows;
      state.loading = false;
    });
    builder.addCase(getMedia.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMedia.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(uploadMedia.fulfilled, (state, { payload }) => {
      let currData = [...state.mediaData];
      currData.pop();
      currData.unshift(payload);
      state.mediaData = [...currData];
      state.totalMediaCount += 1;
    });
    builder.addCase(uploadMedia.pending, (state) => {});
    builder.addCase(uploadMedia.rejected, (state) => {});

    builder.addCase(editMedia.fulfilled, (state, { payload }) => {
      state.mediaData = state.mediaData?.map((item) => {
        if (item.id == payload?.id) return payload;
        else return item;
      });
    });

    builder.addCase(editMedia.pending, (state) => {});
    builder.addCase(editMedia.rejected, (state) => {});
    builder.addCase(deleteMedia.fulfilled, (state, { payload }) => {
      state.mediaData = state.mediaData?.filter((item) => {
        if (![payload].includes(item.id)) return item;
      });
      state.totalMediaCount -= 1;
    });

    builder.addCase(deletebulkMedias.fulfilled, (state, { payload }) => {
      state.mediaData = state.mediaData?.filter((item) => {
        if (!payload.includes(item.id)) return item;
      });
      state.totalMediaCount -= payload.length;
    });
    builder.addCase(deletebulkMedias.pending, (state) => {});
    builder.addCase(deletebulkMedias.rejected, (state) => {});
  },
});

export const { changePage, changelimit, reset } = mediaTableSlice.actions;

export default mediaTableSlice.reducer;
