import { Dialog } from "primereact/dialog";
import { CustomButton } from "../CustomButton";
import { Text } from "../Text";
import { underlineStyle } from "../../utils/commonStyles";
import CONSTANTS from "../../config/Constants";
const SklbtConfirmation = ({
  icon = "pi pi-exclamation-triangle",
  message = CONSTANTS.DELETE_CONFIRMATION,
  header = "Alert",
  handleConfirm = () => {},
  handleReject = () => {},
  visible = false,
}) => {
  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Yes"
          varient="filled"
          icon="pi pi-check"
          onClick={handleConfirm}
        />
        <CustomButton
          label="No"
          varient="cancel"
          icon="pi pi-times"
          autofocus={true}
          onClick={handleReject}
        />
      </div>
    );
  };
  return (
    <Dialog
      header={<Text type={"heading"}>{header}</Text>}
      footer={dialogFooter}
      visible={visible}
      onHide={handleReject}
      style={{ width: "45vw" }}
      className={"dialog-custom"}
    >
      <div className={`flex flex-column  gap-3`}>
        <Text className={`alertText`}>{message}</Text>
      </div>
    </Dialog>
  );
};

export default SklbtConfirmation;
