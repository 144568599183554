import axiosInstance from "./axios.instance";

// api calls for TICKETS
const API_GET_TICKETS = async (
  pageNo,
  limit,
  startDate,
  endDate,
  filterData,
  globalFilterValue
) => {
  try {
    var resp;
    if (filterData || globalFilterValue || (startDate && endDate)) {
      let allFilter = "";
      if (filterData) {
        filterData.forEach((element) => {
          allFilter += `&${element.key}=${element.value}`;
        });
      }
      if (globalFilterValue) {
        allFilter += `&global=${globalFilterValue}`;
      }
      if (startDate && endDate) {
        allFilter += `&startDate=${startDate}&endDate=${endDate}`;
      }
      resp = await axiosInstance.get(
        `/dashboard/ticket/overall?page=${pageNo}&limit=${limit}&isActive=1${allFilter}`
      );
      return resp.data;
    } else {
      resp = await axiosInstance.get(
        `/dashboard/ticket/overall?page=${pageNo}&limit=${limit}&isActive=1`
      );
      return resp.data;
    }
  } catch (err) {
    throw err;
  }
};


export {
  API_GET_TICKETS,
};
