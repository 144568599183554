import { useEffect, useRef, useState } from "react"
import {
  API_GET_CUSTOMER_GROUPS,
  API_GET_GROUPS,
  API_PUT_GROUP,
} from "../../../api/customer.services"
import { formatMobNumber } from "../../../utils/commonUtils"
import { useDispatch, useSelector } from "react-redux"
import { Tag } from "primereact/tag"
import { CustomButton } from "../../../components/CustomButton"
import { MultiSelect } from "primereact/multiselect"
import { Text } from "../../../components/Text"
import styles from "./style.module.css"
import { Toast } from "primereact/toast"
import { useNavigate } from "react-router-dom"
import { Add_Cust_In_Group_ToastSuccessMessage } from "../../../config/ToastMessage"
import CustomSaveModel from "../../../components/CustomeSaveModel"
import CONSTANTS from "../../../config/Constants"
import { useLayout } from "../../../context/LayoutContext"
import {
  addCustomer,
  changeMode,
  getCustomerbyid,
  resetMode,
  resetSelectedCustomer,
} from "../../../reducers/customerTableSlice"
import * as Messag from "../../../config/ToastMessage"
import Loader from "../../../components/Loader"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"

const AddToGroup = ({ addToGroupSliderOpen, setAddToGroupSliderOpen }) => {
  const overlayRef = useRef(null)
  const { user } = useSelector((state) => state.enqChats)
  const { mode } = useSelector((state) => state.customerTable)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [availableGroups, setAvailableGroups] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [customerInGroups, setCustomerInGroups] = useState([])
  const [showSave, setShowSave] = useState(false)
  const [loadingAddGroups, setLoadingAddGroups] = useState(false)
  const [name, setName] = useState("")
  const { toast } = useLayout()
  const id = user?.customerId

  const SaveModel = () => {
    return (
      <CustomSaveModel
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={() => setShowSave(false)}
      />
    )
  }
  const alertText = () => {
    return <div>{CONSTANTS.CUSTOMER_SAVE_CONFIRMATION}</div>
  }

  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false)
          }}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={() => onSubmit()}
        />
      </div>
    )
  }

  const onSubmit = async () => {
    if (mode === "create" && !name) {
      toast.current.show({ severity: "warn", detail: "Name is required!" })
      return
    }

    let data = {
      groupId: selectedGroups,
      name: name,
      phone: user?.mob,
    }

    if (mode === "update") {
      const data = { customerIds: [user.customerId] }
      setLoadingAddGroups(true)
      try {
        const results = await Promise.all(
          selectedGroups.map(async (groupId) => {
            return await API_PUT_GROUP(groupId, data)
          })
        )
        getCustomersGroup()
        toast.current.show({
          severity: "success",
          detail: Add_Cust_In_Group_ToastSuccessMessage,
        })
      } catch (err) {
        toast.current.show({
          severity: "error",
          detail: err?.message,
        })
      } finally {
        setLoadingAddGroups(false)
        setShowSave(false)
      }
      setShowSave(false)
    } else {
      dispatch(addCustomer({ data }))
        .unwrap()
        .then((res) => {
          getCustomersGroup(res.id)
          let Message_Success = Messag.Add_Cust_ToastSuccessMessage
          toast.current.show({ severity: "success", detail: Message_Success })
          setAddToGroupSliderOpen(false)
        })
        .catch((err) => {
          toast.current.show({ severity: "error", detail: err.message })
        })
      setShowSave(false)
    }
  }

  const handleOutsideClick = (event) => {
    if (
      overlayRef.current &&
      overlayRef.current.contains(event.target) &&
      addToGroupSliderOpen
    ) {
      setAddToGroupSliderOpen(false)
    }
  }

  const getCustomersGroup = () => {
    API_GET_CUSTOMER_GROUPS(id)
      .then((resp) => {
        const _customerInGroups = resp?.map((grp) => {
          return {
            id: grp.id,
            groupName: grp.groupName,
          }
        })
        if (resp) {
          const selectedGroupIds = _customerInGroups.map((group) => group.id)
          setCustomerInGroups(_customerInGroups)
          setSelectedGroups(selectedGroupIds)
        }
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          detail: err?.message,
        })
      })
  }

  const handleCopyPhoneNoToClipboard = () => {
    const mobWithCountryCode = formatMobNumber(user?.mob)
    navigator.clipboard
      .writeText(mobWithCountryCode)
      .then(() => {
        toast.current.show({
          severity: "success",
          detail: "Phone number copied to clipboard",
        })
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          detail: "Failed to copy phone number",
        })
      })
  }

  useEffect(() => {
    if (id) {
      getCustomersGroup(id)

      dispatch(getCustomerbyid({ id }))
        .unwrap()
        .then((e) => {})
        .catch(() => {
          dispatch(changeMode("create"))
          dispatch(resetSelectedCustomer())
        })

      dispatch(changeMode("update"))
    } else {
      dispatch(changeMode("create"))
      dispatch(resetSelectedCustomer())
    }

    return () => {
      setCustomerInGroups([])
      setSelectedGroups([])
      setName("")
    }
  }, [id])

  useEffect(() => {
    API_GET_GROUPS(0, 100000)
      .then((res) => {
        setAvailableGroups(res?.rows)
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          detail: err?.message,
        })
      })

    return () => {
      dispatch(resetSelectedCustomer())
      dispatch(resetMode())
    }
  }, [])

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick)

    return () => {
      document.removeEventListener("click", handleOutsideClick)
    }
  }, [addToGroupSliderOpen])

  return (
    <div className={styles["addToGroupContainer"]}>
      <Toast ref={toast} />
      <Loader visible={loadingAddGroups} />
      <SaveModel />
      <div
        className={`flex flex-column p-3 ${styles["slidebar"]} ${
          addToGroupSliderOpen ? styles["open-slide"] : styles["close-slide"]
        }`}
      >
        <div className="flex flex-column">
          <div className="flex justify-content-between align-items-center gap-2">
            {id ? (
              <div>
                {user?.displayName && (
                  <div className="capitalize">
                    <Text type={"heading"}>{user.displayName}</Text>
                  </div>
                )}
              </div>
            ) : (
              <InputText
                label="Name *"
                name="name"
                rules={{ required: "Customer name is required." }}
                placeholder="Enter name"
                value={name}
                className="w-12"
                onChange={(e) => setName(e.target.value)}
              />
            )}

            <div onClick={() => setAddToGroupSliderOpen(false)}>
              <i className="pi pi-times cursor-pointer text-black" />
            </div>
          </div>

          <Text type={"small-text"} cust="mt-3" style={{ color: "#3F4254" }}>
            Mobile number
          </Text>
          <div className="flex gap-2 align-items-center mt-1">
            <Text type={"sub-heading"}>{formatMobNumber(user?.mob)}</Text>
            <i
              title="copy"
              className="pi pi-copy cursor-pointer"
              onClick={() => handleCopyPhoneNoToClipboard()}
            ></i>
          </div>

          <div className={styles["divider"]}></div>

          <Text type={"small-text"} style={{ color: "#3F4254" }} cust="mt-1">
            Current groups
          </Text>
          <div className="flex flex-wrap align-items-center gap-2 mt-1">
            {customerInGroups?.length === 0 ? (
              <Text type={"sub-heading"}>
                Customer is not present in any group.
              </Text>
            ) : (
              customerInGroups.map((cstGrp) => (
                <Tag
                  key={cstGrp.id}
                  style={{
                    backgroundColor: "#F0FBFF",
                    color: "#30B3D5",
                    fontSize: "0.8rem",
                    fontFamily: "Lato",
                  }}
                  value={cstGrp.groupName}
                  onClick={() => navigate(`/customers/${cstGrp.id}/gupdate`)}
                  className="cursor-pointer"
                ></Tag>
              ))
            )}
          </div>

          {id ? (
            <div className="mt-2">
              <Text type={"small-text"} style={{ color: "#3F4254" }} cust="mt-1">Add to groups</Text>
              <MultiSelect
                key={id}
                value={selectedGroups}
                onChange={(e) => setSelectedGroups(e.value)}
                options={availableGroups}
                optionLabel="groupName"
                optionValue="id"
                display="chip"
                filter
                placeholder="Select groups"
                maxSelectedLabels={3}
                className="w-full my-2"
                style={{ fontSize: "12px" }}
              />
            </div>
          ) : (
            <div className="mt-2">
              <Text type={"small-text"} style={{ color: "#3F4254" }} cust="mt-1">Add to group: </Text>
              <Dropdown
                options={availableGroups}
                className="w-full my-2"
                optionValue="id"
                optionLabel="groupName"
                label="Group"
                name="groupId"
                placeholder="Choose"
                required
                value={selectedGroups}
                onChange={(e) => setSelectedGroups(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className="flex justify-content-end gap-2 mt-auto mb-3">
          <CustomButton
            varient="cancel"
            label={"Cancel"}
            style={{ width: "5rem" }}
            onClick={() => setAddToGroupSliderOpen(false)}
          />
          <CustomButton
            varient="filled"
            label={mode === "update" ? "Update" : "Add"}
            style={{ width: "5rem" }}
            onClick={() => {
              if (mode === "create" && !name) {
                toast.current.show({
                  severity: "warn",
                  detail: "Name is required!",
                })
                return
              }
              setShowSave(true)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default AddToGroup
