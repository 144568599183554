// Thumbnail.jsx

import { useState } from "react";
import { Image } from "primereact/image";
import { OverlayPanel } from "primereact/overlaypanel";
import { Text } from "../../Text";
import styles from "./style.module.css";

export default function Thumbnail({ images = [], onClick = () => {} }) {
  const [isHovered, setIsHovered] = useState(false);
  const mediaUrl = images?.mediaUrl;

  return (
    <div className="flex flex-row align-items-center align-content-center  justify-content-between "
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <div  className={`flex flex-row align-items-center align-content-center  justify-content-center surface-100 ${styles.thumbnailContainer}`}>
        <div className={styles.imageContainer}>
        <Image
          src={images[0]?.url || mediaUrl }
          zoomSrc={images[0]?.url}
          onError={(e) => (e.target.src = "/images/ImgPlaceholder.svg")}
          alt="img"
          width="100%"
          height="100%"
          />
        </div>
        {isHovered && (images.length > 0 || mediaUrl?.length >= 0)  && (
          <>
         <div className={styles.previewOverlay} />
          <i className={`pi pi-eye ${styles.previewIcon}`} onClick={(e) => e.preventDefault()} />
          </>
        )}
      </div>
      <div className="pl-2">
      {images.length > 1 && <Text>+{images.length - 1}</Text>}
      {images.length <= 1 && <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>}
      {mediaUrl?.length >= 1 && <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>}
      </div>
    </div>
  );
}
