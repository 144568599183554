import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inputType: "",
  isSidePanelVisible: false,
  isImageSelected: false,
  isVideoSelected: false,
};

const multiInputSlice = createSlice({
  name: "multiInput",
  initialState,
  reducers: {
    handleInputType(state, action) {
      state.inputType = action.payload;
      state.isSidePanelVisible = true;
    },
    changeIsSidePanelVisible(state, action) {
      state.isSidePanelVisible = action.payload;
    },
    setIsImageSelected(state, action) {
      state.isImageSelected = action.payload;
    },
    setIsVideoSelected(state, action) {
      state.isVideoSelected = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  handleInputType,
  changeIsSidePanelVisible,
  setIsImageSelected,
  setIsVideoSelected,
} = multiInputSlice.actions;

export default multiInputSlice.reducer;
