import { Text } from "../../components/Text";
import styles from "./style.module.css";
import { ReactComponent as ChatIcon } from "../../assets/svgIcons/Chat.svg";
import { useEffect, useRef, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { CustomButton } from "../../components/CustomButton";
import { getPlans } from "../../api/payment.service";
import { Skeleton } from "primereact/skeleton";

function TopUp({ topup, handleChangeTopUp, toast }) {
  const [topUpLoading, setTopUpLoading] = useState(false);
  const [topUpList, setTopUpList] = useState([]);

  useEffect(() => {
    setTopUpLoading(true);
    getPlans("topup")
      .then((data) => {
        setTopUpLoading(false);
        let allTopUps = data?.rows;
        handleChangeTopUp(allTopUps[0]);
        setTopUpList(allTopUps);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  return (
    <div className={`w-full p-3 ${styles["sub-container"]}`}>
      <div>
        <Text type={"sub-heading"} color={"#64748B"}>
          Adding Money
        </Text>
        <div className="my-3">
          <Text type={"sub-heading"}>Conversations</Text>
          <div
            className={`mt-2 p-2 flex justify-content-between align-items-center gap-2 flex-wrap ${styles["conversation-container"]}`}
          >
            <div className="flex gap-2 align-items-center">
              <ChatIcon />
              <Text type={"heading"}>{topup?.totalConversation}</Text>
            </div>
            <div className="mr-3">
              <Text type={"sub-heading"} color={"#64748B"}>
                Plan: Skalebot Pro
              </Text>
            </div>
          </div>
        </div>
        <div className="my-3">
          <Text type={"sub-heading"}>
            <label>How much would you like to Add? </label>
          </Text>
          <br />
          <InputNumber
            className="w-full"
            value={topup?.amount}
            // onValueChange={(e) => handleChangeTopUp({ id: 3, amount: e.value })}
            useGrouping={false}
            minFractionDigits={2}
            maxFractionDigits={2}
            mode="currency"
            currency="INR"
            placeholder="Enter amount"
            disabled={true}
          />
        </div>
        <div className="mt-3">
          {topUpLoading ? (
            <Skeleton width="100%" height="2rem" />
          ) : (
            <div className="flex gap-3 flex-wrap">
              {topUpList?.map((element) => (
                <div key={element?.id}>
                  <CustomButton
                    label={element?.amount}
                    varient="outline"
                    onClick={() => handleChangeTopUp(element)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TopUp;
