import axiosInstance from "./axios.instance";
import axios from "axios";
// apies calls for products
const API_GET_PRODUCTS = async (
  pageNo,
  limit,
  filterData,
  globalFilterValue
) => {
  try {
    var resp;
    if (filterData || globalFilterValue) {
      let allFilter = "";
      filterData.forEach((element) => {
        allFilter += `&${element.key}=${element.value}`;
      });
      if (globalFilterValue) {
        allFilter += `&global=${globalFilterValue}`;
      }
      resp = await axiosInstance.get(
        `/product?page=${pageNo}&limit=${limit}&isActive=1${allFilter}`
      );
    } else {
      resp = await axiosInstance.get(
        `/product?page=${pageNo}&limit=${limit}&isActive=1`
      );
    }
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_GET_PRODUCTS_SORT = async (
  pageNo,
  limit,
  filterData,
  globalFilterValue,
  productIds
) => {
  try {
    var resp;
    if (filterData || globalFilterValue) {
      let allFilter = "";
      filterData.forEach((element) => {
        allFilter += `&${element.key}=${element.value}`;
      });
      if (globalFilterValue) {
        allFilter += `&global=${globalFilterValue}`;
      }
      resp = await axiosInstance.put(
        `/product/selected?page=${pageNo}&limit=${limit}&isActive=1${allFilter}`,
        { productIds }
      );
    } else {
      resp = await axiosInstance.put(
        `/product/selected?page=${pageNo}&limit=${limit}&isActive=1`,
        {
          productIds,
        }
      );
    }
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_ADD_PRODUCT = async (configData) => {
  try {
    let { data } = await axiosInstance.post(`/product`, configData);
    return data;
  } catch (err) {
    throw err;
  }
};

const API_GET_PRODUCT_ID = async (productId) => {
  try {
    const { data } = await axiosInstance.get(`/product/${productId}`);
    return data;
  } catch (err) {
    throw err;
  }
};

const API_PUT_PRODUCT = async (productId, updatedData) => {
  try {
    const { data } = await axiosInstance.put(
      `/product/${productId}`,
      updatedData
    );
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const API_DELETE_PRODUCT = async (productID) => {
  try {
    const resp = await axiosInstance.delete(`/product/${productID}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_BULK_DELETE_PRODUCT = async (productIds) => {
  try {
    const resp = await axiosInstance.delete(`/product/bulk`, {
      data: { ids: productIds },
    });
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_BULK_PRODUCT_ADD = async (configDataArr = []) => {
  try {
    const { data } = await axiosInstance.post("/product/bulk", configDataArr);
    return data;
  } catch (error) {
    throw error;
  }
};
const API_BULK_PRODUCT_VALIDATE = async (configDataArr = []) => {
  try {
    const { data } = await axiosInstance.post(
      "/product/bulk/validate",
      configDataArr
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export {
  API_ADD_PRODUCT,
  API_GET_PRODUCTS,
  API_PUT_PRODUCT,
  API_GET_PRODUCT_ID,
  API_DELETE_PRODUCT,
  API_GET_PRODUCTS_SORT,
  API_BULK_DELETE_PRODUCT,
  API_BULK_PRODUCT_ADD,
  API_BULK_PRODUCT_VALIDATE,
};
