import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_GET_CATEGORIES,
  API_ADD_CATEGORY,
  API_PUT_CATEGORY,
  API_DELETE_CATEGORY,
  API_GET_CATEGORY_ID,
  API_BULK_DELETE_CATEGORY,
} from "../api/category.services";
import { removeDeleteData, updateTableData } from "../utils/tableUtils";
import { getUnselectedItems, isItemInList } from "../utils/commonUtils";

const initialState = {
  categoryData: [],
  totalCategoryCount: 0,
  loading: false,
  selectedCategory: null,
  page: 0,
  limit: 30,
  mode: "create",
  selectedCategories: [],
};

export const getCategories = createAsyncThunk(
  "categoryTable/getCategoryList",
  async ({ page, limit, filterData, globalFilterValue }, thunkAPI) => {
    try {
      const categories = await API_GET_CATEGORIES(
        page,
        limit,
        filterData,
        globalFilterValue
      );
      return categories;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const addCategory = createAsyncThunk(
  "categoryTable/addCategory",
  async (configData, thunkAPI) => {
    try {
      const resp = await API_ADD_CATEGORY(configData);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCategoriesbyId = createAsyncThunk(
  "categoryTable/getCategoriesbyId",
  async ({ categoryId }, thunkAPI) => {
    try {
      // console.log("ss",categoryId)
      const resp = await API_GET_CATEGORY_ID(categoryId);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "categoryTable/updateCategory",
  async ({ categoryId, data }, thunkAPI) => {
    try {
      const resp = await API_PUT_CATEGORY(categoryId, data);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "categoryTable/deleteCategory",
  async (categoryId, thunkAPI) => {
    try {
      const resp = await API_DELETE_CATEGORY(categoryId);
      return categoryId;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const bulkDeleteCategory = createAsyncThunk(
  "categoryTable/bulkDeleteCategory",
  async (categoryIds, thunkAPI) => {
    try {
      await API_BULK_DELETE_CATEGORY(categoryIds);
      return categoryIds;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const categoryTableSlice = createSlice({
  name: "categoryTable",
  initialState,
  reducers: {
    changeMode(state, action) {
      state.mode = action.payload;
    },

    changeSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
    },
    resetSelectedCategory(state) {
      state.selectedCategory = null;
      state.mode = initialState.mode;
    },
    resetMode(state) {
      state.mode = initialState.mode;
    },
    changePage(state, action) {
      state.page = action.payload;
    },
    changelimit(state, action) {
      state.limit = action.payload;
    },
    resetCategoryList(state) {
      state.page = initialState.page;
      state.limit = initialState.limit;
    },
    changeSelectedCategories(state, { payload }) {
      const { selectedData, categoryData } = payload;
      if (state.selectedCategories.length == 0) {
        state.selectedCategories = selectedData;
      } else {
        const newItems = selectedData.filter((category) => {
          if (!isItemInList(state.selectedCategories, category))
            return category;
        });
        const unselectedItems = getUnselectedItems(categoryData, selectedData);

        const onlySelectedItems = state.selectedCategories.filter(
          (category) => {
            if (!isItemInList(unselectedItems, category)) return category;
          }
        );
        state.selectedCategories = [...onlySelectedItems, ...newItems];
      }
    },
    reseSelectedCategories(state) {
      state.selectedCategories = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.totalCategoryCount = action.payload.count;
      state.categoryData = action.payload.rows;
      state.loading = false;
    });
    builder.addCase(getCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategories.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCategoriesbyId.fulfilled, (state, action) => {
      state.selectedCategory = action.payload;
      state.loading = false;
    });
    builder.addCase(getCategoriesbyId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategoriesbyId.rejected, (state) => {
      state.loading = false;
    });
    //add category

    builder.addCase(addCategory.fulfilled, (state, action) => {
      let data = action.payload;
      if (state.categoryData.length < state.limit) {
        state.categoryData = [data, ...state.categoryData];
      } else {
        state.categoryData = [
          data,
          ...state.categoryData.slice(0, state.limit - 1),
        ];
      }
      state.totalCategoryCount += 1;
      state.loading = false;
    });
    builder.addCase(addCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCategory.rejected, (state) => {
      state.loading = false;
    });

    //update category
    builder.addCase(updateCategory.fulfilled, (state, action) => {
      state.categoryData = updateTableData(state.categoryData, action.payload);
      state.loading = false;
    });
    builder.addCase(updateCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCategory.rejected, (state) => {
      state.loading = false;
    });

    //delete category
    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      state.categoryData = removeDeleteData(state.categoryData, action.payload);
      state.totalCategoryCount -= 1;
      state.loading = false;
    });
    builder.addCase(deleteCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCategory.rejected, (state) => {
      state.loading = false;
    });

    //bulk delete
    builder.addCase(bulkDeleteCategory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.categoryData = state.categoryData?.filter(
        (category) => !payload?.includes(category.id)
      );
      state.totalCategoryCount -= payload.length;
      state.selectedCategories = [];
    });
    builder.addCase(bulkDeleteCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(bulkDeleteCategory.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  changeMode,
  resetMode,
  changeSelectedCategory,
  resetSelectedCategory,
  changePage,
  changelimit,
  resetCategoryList,
  changeSelectedCategories,
  reseSelectedCategories,
} = categoryTableSlice.actions;

export default categoryTableSlice.reducer;
