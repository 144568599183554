import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import Loader from "../../components/Loader";
import { Text } from "../../components/Text";
import { CustomButton } from "../../components/CustomButton";
import { AddNewProduct } from "../../components/Forms/AddNewProduct";
import { ReactComponent as Edit } from "../../svg/edit.svg";
import { ReactComponent as Delete } from "../../svg/delete.svg";
import { DeleteAlert } from "../../components/Alert/DeleteAlert";
import GroupShare from "../../components/GroupShare";
import CustomPaginator from "../../components/CustomPaginator";
import { ReactComponent as Approve } from "../../svg/approve.svg";
import { ReactComponent as DisApprove } from "../../svg/disapprove.svg";
import { ReactComponent as Pending } from "../../svg/inprogress.svg";
import { getAlphabeticalSortedArr } from "../../utils/tableUtils";
import {
  changeSelectedProduct,
  getProducts,
  changeMode,
  changePage,
  updateSelectedProductsList,
  resetAll,
  changelimit,
  changeSelectedProductBlist,
  bulkDeleteProduct,
} from "../../reducers/productTableSlice";
import { useDispatch, useSelector } from "react-redux";
import Notice from "../../components/Alert/Notice";
import { Dropdown } from "primereact/dropdown";
import { API_GET_CATEGORIES } from "../../api/category.services";
import {
  changeShowGroupShareDialog,
  changeSuccessfullCampaign,
} from "../../reducers/appSlice";
import { Image } from "primereact/image";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import DebounceSearch from "../../components/common/DebounceSearch";
import EllipsisText from "../../components/common/EllipsisText/indix";
import { useNavigate } from "react-router";
import { changeTemplateType } from "../../reducers/templateReducer/templateSlice";
import { resetBroadcast } from "../../reducers/ShareMessage/messageSlice";
import { updatecustomers } from "../../reducers/customerTableSlice";
import SklbtConfirmation from "../../components/common/SklbtConfirmation";
import ImageGallary from "../../components/common/ImageGallary";
import ImportProductCSV from "./ImportProductCSV";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
const ProductList = () => {
  const {
    productData,
    loading,
    page,
    limit,
    totalProductCount,
    selectedProductsList,
  } = useSelector((state) => state.productTable);

  const [displayAddProductModule, setDisplayAddProductModule] = useState(false);
  const [displayAlertDelete, setDisplayAlertDelete] = useState(false);
  const [bulkDeleteAlert, setBulkDeleteAlert] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { showNotice, showGroupShareDialog } = useSelector(
    (state) => state.application
  );
  const { successfullCampaign } = useSelector((state) => state.application);
  const { user } = useSelector((state) => state.authenticate);

  const dispatch = useDispatch();
  const toast = useRef(null);
  const navigate = useNavigate();
  ///filter--------------------------------
  const [filtersData, setFiltersData] = useState({
    categoryId: "",
    status: "",
  });

  const [filters1, setFilters1] = useState({
    categoryName: { value: "" },
    status: { value: "" },
  });
  const [categories, setCategories] = useState([]);
  const [allCategory, setAllCategory] = useState([]);

  useEffect(() => {
    if (successfullCampaign) {
      let Message_Success = "Campaign successfully added";
      toast.current.show({ severity: "success", detail: Message_Success });
    }
    dispatch(changeSuccessfullCampaign(false));
  }, [successfullCampaign]);

  let stocks = ["Available", "Unavailable"];

  const stockFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={stocks}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        itemTemplate={stockItemTemplate}
        placeholder="Select Stock"
        className="p-column-filter"
      />
    );
  };

  const stockItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };

  const categoryFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={categories}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        itemTemplate={categoryItemTemplate}
        placeholder="Select Category"
        className="p-column-filter"
      />
    );
  };

  const categoryItemTemplate = (option) => {
    return (
      <span className={`customer-badge status-${option} capitalize`}>
        {option}
      </span>
    );
  };

  const onClearCategoryFilter = () => {
    setFilters1((prev) => {
      return {
        ...prev,
        categoryName: {
          ...prev.categoryName,
          value: "",
        },
      };
    });

    setFiltersData((prev) => {
      return {
        ...prev,
        categoryId: "",
      };
    });
  };
  const onClearStatusFilter = () => {
    setFilters1((prev) => {
      return {
        ...prev,
        status: {
          ...prev.status,
          value: "",
        },
      };
    });

    setFiltersData((prev) => {
      return {
        ...prev,
        status: "",
      };
    });
  };

  const onClickFilter = (e) => {
    switch (e.field) {
      case "categoryName":
        var id = "";
        for (let i = 0; i < allCategory.length; i++) {
          if (e.constraints.value === allCategory[i].categoryName) {
            id = `${allCategory[i].id}`;
            break;
          }
        }
        setFilters1((prev) => {
          return {
            ...prev,
            categoryName: {
              ...prev.categoryName,
              value: e.constraints.value,
            },
          };
        });

        setFiltersData((prev) => {
          return {
            ...prev,
            categoryId: id,
          };
        });
        break;
      case "status":
        setFilters1((prev) => {
          return {
            ...prev,
            status: {
              ...prev.status,
              value: e.constraints.value,
            },
          };
        });

        setFiltersData((prev) => {
          return {
            ...prev,
            status: e.constraints.value,
          };
        });
        break;
      default:
        break;
    }
  };

  const managePaginationData = () => {
    let filterData = [];
    for (var key in filtersData) {
      if (filtersData[key]) {
        filterData.push({
          key: key,
          value: filtersData[key],
        });
      }
    }
    let paginationData = {
      page: page,
      limit: limit,
      filterData,
      globalFilterValue: inputValue,
    };
    return paginationData;
  };

  //--------------------------------------------

  const handleEdit = (product) => {
    dispatch(changeMode("update"));
    dispatch(changeSelectedProduct(product));
    navigate(`/products/${product.id}/update`);
  };
  const handleDelete = (product) => {
    dispatch(changeMode("delete"));
    dispatch(changeSelectedProduct(product));
    setDisplayAlertDelete(true);
  };
  const { userSub, accesskey } = useSelector((state) => state.authenticate);

  const handleShare = () => {
    if (!userSub.isUserSub) {
      toast.current.show({
        severity: "error",
        detail: "For broadcast inquiries, contact our agent.",
      });
      return;
    }
    if (selectedProductsList.length > 30) {
      toast.current.show({
        severity: "error",
        detail: "Only Less Than 30 Products Can be Share",
      });
      return;
    }
    const temp = selectedProductsList;
    dispatch(resetAll());
    dispatch(resetBroadcast());
    dispatch(changeTemplateType("product"));
    dispatch(updatecustomers({ selectedCust: [] }));
    dispatch(changeSelectedProductBlist([...temp]));
    navigate(`/runcampaign/new`);
  };

  const addProductModal = () => {
    return (
      <AddNewProduct
        displayAddProductModule={displayAddProductModule}
        setDisplayAddProductModule={setDisplayAddProductModule}
        toast={toast}
      />
    );
  };

  const modalLoad = () => {
    return (
      <div>
        <Loader visible={loading} />
      </div>
    );
  };

  const deleteModule = () => {
    return (
      <DeleteAlert
        item="product"
        displayAlertDelete={displayAlertDelete}
        setDisplayAlertDelete={setDisplayAlertDelete}
        toast={toast}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    const checkProductSelected = (prod) => {
      for (let i = 0; i < selectedProductsList.length; i++) {
        if (selectedProductsList[i].id === prod.id) return true;
      }

      return false;
    };
    return (
      <div className="flex justify-content-start align-items-center">
        {accesskey[modulesName.PRODUCT]?.includes(permissions.UPDATE) && (
          <CustomButton
            varient="icon-button"
            icon={<Edit />}
            onClick={() => handleEdit(rowData)}
            disabled={checkProductSelected(rowData)}
            style={{ padding: "0px" }}
          />
        )}
        {accesskey[modulesName.PRODUCT]?.includes(permissions.DELETE) && (
          <CustomButton
            varient="icon-button"
            icon={<Delete />}
            onClick={() => handleDelete(rowData)}
            disabled={checkProductSelected(rowData)}
            style={{ padding: "0px" }}
          />
        )}
      </div>
    );
  };

  //will use fb review status later

  const statusimg = (rowData) => {
    let status = rowData.fbReviewStatus;
    return (
      <>
        {status === "APPROVED" ? (
          <Approve />
        ) : status === "REJECTED" ? (
          <DisApprove />
        ) : (
          <Pending />
        )}
      </>
    );
  };

  function onSelectProduct(e) {
    const selectedProducts = e.value;
    const currProducts = productData;
    dispatch(updateSelectedProductsList({ selectedProducts, currProducts }));
  }

  const isSelectable = (data) => {
    const { status, fbReviewStatus, categoryStatus } = data;
    // return status === "Available" && fbReviewStatus == "APPROVED" && categoryStatus==="Available";
    return status === "Available" && categoryStatus === "Available";
  };

  const isRowSelectable = (e) => {
    return isSelectable(e.data);
  };

  const rowClassName = (data) => {
    return isSelectable(data) ? "" : "not-selectable";
  };

  const handleBulkDelete = () => {
    const prodIds = selectedProductsList?.map((prod) => prod.id);
    dispatch(bulkDeleteProduct(prodIds))
      .unwrap()
      .then((res) => {
        setBulkDeleteAlert(false);
        toast.current.show({
          severity: "success",
          detail: `${res.length} Products deleted successfully`,
          life: 3000,
        });
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          detail: err?.message,
          life: 3000,
        });
      });
  };

  useEffect(() => {
    const getAllCategories = async () => {
      try {
        const AllCategories = await API_GET_CATEGORIES(0, 100000);
        let category = [];
        AllCategories.rows.forEach((element) => {
          category.push(element.categoryName);
        });

        setAllCategory(AllCategories.rows);
        // const alphabeticalSortedCategory = getAlphabeticalSortedArr(category);
        setCategories(category);
      } catch (error) {}
    };
    getAllCategories();
    return ()=>{
      dispatch(resetAll())
    }
  }, []);

  useEffect(() => {
    let paginationData = managePaginationData();
    dispatch(getProducts(paginationData))
      .unwrap()
      .catch(() => {
        //show toast from here
      });
  }, [page, limit, inputValue, filtersData]);

  return (
    <div className="p-3">
      {bulkDeleteAlert && (
        <SklbtConfirmation
          visible={bulkDeleteAlert}
          handleConfirm={handleBulkDelete}
          handleReject={() => setBulkDeleteAlert(false)}
          message={`Are you sure, you want to delete ${selectedProductsList.length} products?`}
        />
      )}
      <Toast ref={toast} />
      <TableActionsContainer>
        <div className="flex justify-content-between align-items-center flex-wrap gap-3">
          <div className="flex gap-2 align-items-center">
            <Text type={"heading"}>Product List</Text>
            {/* {selectedProductsList.length != 0 && (
              <CustomButton
                label={
                  <Text>({selectedProductsList.length}) Delete Selected </Text>
                }
                varient="icon-button"
                onClick={() => setBulkDeleteAlert(true)}
              />
            )} */}
          </div>
          <div className="flex gap-3 flex-wrap-reverse">
            <DebounceSearch
              placeholder={"Search Product"}
              inputValue={inputValue}
              handleInputChange={(value) => setInputValue(value)}
            />
            <div className="flex gap-2 ">
              {accesskey[modulesName.PRODUCT]?.includes(permissions.CREATE) && (
                <ImportProductCSV />
              )}
              {accesskey[modulesName.PRODUCT]?.includes(permissions.CREATE) && (
                <CustomButton
                  varient="filled"
                  label={"Add Product"}
                  icon={"pi pi-plus"}
                  onClick={() => navigate("/products/new")}
                />
              )}
              {accesskey[modulesName.CAMPAIGN]?.includes(
                permissions.CREATE
              ) && (
                <CustomButton
                  varient="filled"
                  label={"Share Selected"}
                  onClick={() => handleShare("all")}
                  icon={"pi pi-external-link"}
                  disabled={!selectedProductsList.length}
                />
              )}
            </div>
          </div>
        </div>
      </TableActionsContainer>
      <div className="card my-3">
        {displayAddProductModule && addProductModal()}
        {showGroupShareDialog && (
          <GroupShare label={"Share selected product"} />
        )}
        {modalLoad()}
        {displayAlertDelete && deleteModule()}
        <div className="p-fluid">
          {/* {showNotice && (
            <Notice message={"Products will be uploaded every midnight."} />
          )} */}
          <DataTable
            value={[...productData]}
            selection={selectedProductsList}
            onSelectionChange={onSelectProduct}
            dataKey="id"
            className="skalebot-table"
            selectionMode={accesskey[modulesName.CAMPAIGN]?.includes(permissions.CREATE)?"checkbox":""}
            filters={filters1}
            filterDisplay="menu"
            globalFilterFields={["categoryName", "status"]}
            isDataSelectable={isRowSelectable}
            rowClassName={rowClassName}
          >
            {accesskey[modulesName.CAMPAIGN]?.includes(permissions.CREATE) && (
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
                exportable={false}
              ></Column>
            )}
            <Column
              field="SKUCode"
              header="SKUId"
              style={{ maxWidth: "25ch" }}
              className="uppercase text-sm font-normal"
            ></Column>
            <Column
              field="productName"
              header="Name"
              className="text-sm font-normal"
              style={{ maxWidth: "300px" }}
              body={(rowData) => <EllipsisText className={'white-space-nowrap'} text={rowData.productName} />}
            ></Column>
            <Column
              field="categoryName"
              style={{ maxWidth: "100px" }}
              header={() => {
                return (
                  <div className="flex gap-2 align-items-end">
                    <Text>Category</Text>
                  </div>
                );
              }}
              showFilterMatchModes={false}
              filter
              filterElement={categoryFilterTemplate}
              onFilterApplyClick={(e) => onClickFilter(e)}
              onFilterClear={onClearCategoryFilter}
            />

            <Column
              field="status"
              header={() => {
                return (
                  <div className="flex gap-2 align-items-end">
                    <Text>Stock</Text>
                  </div>
                );
              }}
              showFilterMatchModes={false}
              filter
              style={{ maxWidth: "8rem" }}
              filterElement={stockFilterTemplate}
              onFilterApplyClick={(e) => onClickFilter(e)}
              onFilterClear={onClearStatusFilter}
            ></Column>
            <Column field="quantity" header="Quantity"></Column>
            <Column
              field="price"
              header={<Text>Price&nbsp;(₹) </Text>}
            ></Column>
            <Column
              header="Image"
              body={(rowData) => <ImageGallary images={rowData?.images} />}
              bodyStyle={{ display: "flex" }}
            ></Column>
            <Column
              field="desc"
              header="Description"
              style={{ minWidth: "250px", maxWidth: "350px" }}
              body={(rowData) => <EllipsisText text={rowData.desc} />}
            ></Column>
            {(accesskey[modulesName.PRODUCT]?.includes(permissions.CREATE) ||
              accesskey[modulesName.PRODUCT]?.includes(permissions.DELETE)) && (
              <Column
                header="Actions"
                body={actionBodyTemplate}
                exportable={false}
                style={{ maxWidth: "100px" }}
              ></Column>
            )}
          </DataTable>
        </div>
        <div className="mt-3">
          <TableActionsContainer>
            <div className="flex  justify-content-end">
              <CustomPaginator
                page={page}
                limit={limit}
                changelimit={changelimit}
                totalRecords={totalProductCount}
                changePage={changePage}
              />
            </div>
          </TableActionsContainer>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
