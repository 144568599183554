import {
  query,
  collection,
  onSnapshot,
  orderBy,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import React, { useEffect, useState, useRef } from "react";
import Message from "./Message";
import { db } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { formatMessage } from "../../utils/chatUtils";
import { Checkbox } from "primereact/checkbox";
import {
  clearForwardFirstMessageId,
  clearForwardMessage,
  setForwardMessage,
} from "../../reducers/enqChatSlice";
import ForwardMessagesModal from "../../components/ForwardMessagesModal/ForwardMessagesModal";
import { ReactComponent as ShareSvg } from "../../assets/svgIcons/svgIcons/whatsappForward.svg";
import { Text } from "../../components/Text";

/* eslint-disable */
const Messages = ({mobileNumber}) => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [lastMessage, setLastMessage] = useState(null); // Track the last loaded message
  const [selectedMessages, setSelectedMessages] = useState([]); // State to store selected messages
  const [selectedMessagesContent, setSelectedMessagesContent] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showSelectionLimitMessage, setShowSelectionLimitMessage] =
    useState(false);

  const {
    chatId,
    forwardMessagesFlag,
    forwardMessages,
    selectedForwardMessagesContent,
    clickedForwardFirstMessageId,
  } = useSelector((state) => state.enqChats);
  const scrollContainerRef = useRef(null);
  const [prevDate, setPrevDate] = useState(null);
  const [msgWithDate, setMsgWithDate] = useState(null);
  const [loadMsg, setLoadMsg] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!chatId) {
      setMessages([]);
      return;
    }
    setLoading(true);
    const q = query(
      collection(db, "chats", chatId, "messages"),
      orderBy("lastUpdatedAt", "desc"),
      limit(25)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setLoading(false);
      let chats = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        let formatedMessage = formatMessage(
          JSON.parse(data.message),
          data.messageType
        );
        let chat = {
          ...data,
          message: formatedMessage,
        };
        chats.push(chat);
      });
      if (chats.length > 0) {
        setLastMessage(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
      chats.reverse();
      setMessages(chats);
    });

    return () => {
      unsubscribe();
    };
  }, [chatId]);

  const loadOlderMessages = async () => {
    if (!lastMessage) {
      setMessages([]);
      return; // No more older messages to load
    }
    const q = query(
      collection(db, "chats", chatId, "messages"),
      orderBy("lastUpdatedAt", "desc"),
      startAfter(lastMessage),
      limit(25)
    );
    setLoading(true);
    const querySnapshot = await getDocs(q);
    setLoading(false);
    if (querySnapshot.docs.length === 0) {
      setLastMessage(null);
      return;
    }
    let chats = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      let formattedMessage = formatMessage(
        JSON.parse(data.message),
        data.messageType
      );
      let chat = {
        ...data,
        message: formattedMessage,
      };
      chats.push(chat);
    });

    chats.reverse();
    setMessages((prevMessages) => [...chats, ...prevMessages]);

    // Update the last loaded message
    if (chats.length > 0) {
      setLastMessage(querySnapshot.docs[querySnapshot.docs.length - 1]);
    }
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleScroll = async () => {
    if (scrollContainerRef.current) {
      const { scrollTop } = scrollContainerRef.current;

      if (scrollTop === 0) {
        const previousScrollHeight = scrollContainerRef.current.scrollHeight;

        await loadOlderMessages();

        const contentHeightChange =
          scrollContainerRef.current.scrollHeight - previousScrollHeight;
        const newScrollTop = scrollTop + contentHeightChange;

        scrollContainerRef.current.scrollTop = newScrollTop;
      }
    }
  };

  function getDaysDifference(timestamp1) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const date1 = new Date(timestamp1).getTime() * 1000; // Convert to milliseconds
    const date2 = new Date().getTime() * 1000; // Convert to milliseconds
    const differenceInMilliseconds = date2 - date1;

    const differenceInDays = Math.floor(
      differenceInMilliseconds / millisecondsPerDay
    );
    return differenceInDays;
  }

  // Function to format the date difference for display
  function formatDaysDifference(daysDifference, message) {
    const todayDate = new Date();
    const date = new Date(message.lastUpdatedAt); // Convert to milliseconds'
    if (
      todayDate.getFullYear() === date.getFullYear() &&
      todayDate.getMonth() === date.getMonth() &&
      todayDate.getDate() === date.getDate()
    ) {
      return "Today";
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 since months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  }

  function CallDateFunction(message) {
    const date = formatDaysDifference(
      getDaysDifference(message?.lastUpdatedAt),
      message
    );
    return date;
  }

  useEffect(() => {
    const dateValueMap = [];
    messages.forEach((msg) => {
      const date = CallDateFunction(msg);

      if (!dateValueMap[date]) {
        dateValueMap[date] = { values: [msg] };
      } else {
        // If the date exists in the map, push the value to its values array
        dateValueMap[date].values.push(msg);
      }
    });
    setLoadMsg(false);
    setMsgWithDate(dateValueMap);
  }, [messages]);

  const HandelLoadMsg = async () => {
    handleScroll();
    setLoadMsg(true);
  };

  const SelectionLimitWarning = ({ onClose }) => {
    return (
      <div
        className="mb-2 absolute p-2  center flex justify-content-center"
        style={{
          zIndex: 99999999,
          top: 100,
          background: "#f8f8fc",
          borderRadius: "8px",
        }}
      >
        <Text style={{ color: "#c79807", fontSize: "16px" }}>
          {" "}
          You can select up to 5 messages.
        </Text>
      </div>
    );
  };

  const toggleSelectMessage = (message) => {
    if (
      selectedMessages.length === 5 &&
      !selectedMessages.includes(message.gsId)
    ) {
      return; // Do not allow selecting more messages
    }
    setSelectedMessages((prevSelectedMessages) => {
      let updatedSelectedMessages;
      if (prevSelectedMessages && prevSelectedMessages.includes(message.gsId)) {
        updatedSelectedMessages = prevSelectedMessages.filter(
          (id) => id !== message.gsId
        );
      } else {
        updatedSelectedMessages = [
          ...(prevSelectedMessages || []),
          message?.gsId,
        ];
      }
      setSelectedMessagesContent((prevSelectedMessagesContent) => {
        let updatedSelectedMessagesContent;
        if (
          prevSelectedMessagesContent &&
          prevSelectedMessagesContent.find((msg) => msg.gsId === message.gsId)
        ) {
          updatedSelectedMessagesContent = prevSelectedMessagesContent.filter(
            (msg) => msg.gsId !== message.gsId
          );
        } else {
          updatedSelectedMessagesContent = [
            ...(prevSelectedMessagesContent || []),
            message,
          ];
        }
        return updatedSelectedMessagesContent;
      });
      return updatedSelectedMessages;
    });
  };

  useEffect(() => {
    if (clickedForwardFirstMessageId) {
      const clickedMessage = messages.find(
        (message) => message.gsId === clickedForwardFirstMessageId
      );
      if (clickedMessage) {
        // Automatically select the clicked message
        toggleSelectMessage(clickedMessage);
      }
    }
  }, [clickedForwardFirstMessageId]);

  const handleCloseForwardMessages = () => {
    // Dispatch the clearForwardMessage action to reset the selected messages
    dispatch(clearForwardMessage());
    setShowShareModal(false);
    setSelectedMessages([]);
    setSelectedMessagesContent([]);
  };

  // Function to handle selection of all messages
  const handleSelectAllMessages = () => {
    if (selectedMessages.length === messages.length) {
      // If all messages are already selected, clear selection
      setSelectedMessages([]);
    } else {
      // If not all messages are selected, select all messages
      setSelectedMessages(messages.map((message) => message.id));
    }
  };

  const handleShareMessages = () => {
    dispatch(
      setForwardMessage({
        forwardMessagesFlag: false,
        selectedForwardMessagesContent: selectedMessagesContent, // Pass the selectedMessages to the forwardMessages state
      })
    );

    setShowShareModal(true);
    setSelectedMessages([]);
    setSelectedMessagesContent([]);
    dispatch(clearForwardFirstMessageId());
  };

  return (
    <div
      className="messages flex-grow-1 overflow-y-auto"
      ref={scrollContainerRef}
      // onScroll={handleScroll}
    >
      {!loadMsg && chatId && (
        <div className="w-full flex justify-content-center align-items-center mb-4 mt-3 __line">
          <button className="__loadBtn" onClick={HandelLoadMsg}>
            Load History
          </button>
        </div>
      )}

      {loading && (
        <div className="text-center">
          <i
            className="pi pi-spin pi-spinner"
            key={(Math.random() * 100) | 0}
            style={{ fontSize: "1rem" }}
          ></i>
        </div>
      )}

      {/* {msgWithDate &&
        Object.keys(msgWithDate).map((date, i) => (
          <div key={i}>
            <div className="w-full flex align-items-center justify-content-center">
              <span className="__dateView">{date}</span>
            </div>
            {msgWithDate[date].values.map((m, id) => (

             <Message message={m} key_id={id} /> 
            ))}
          </div>
        ))} */}
      {msgWithDate &&
        Object.keys(msgWithDate).map((date, i) => (
          <div key={i}>
            <div className="w-full flex align-items-center justify-content-center">
              <span className="__dateView">{date}</span>
            </div>
            {msgWithDate[date].values.map((m, id) => {
              return !forwardMessagesFlag ? (
                <Message message={m} key_id={id} key={id} />
              ) : (
                <div
                  className={`flex align-items-center p-1 mt-1 ${
                    selectedMessages?.includes(m.gsId) ? "selectedMessage" : ""
                  }`}
                >
                  <div className=" flex align-items-center ">
                    <Checkbox
                      checked={selectedMessages?.includes(m.gsId)}
                      onChange={() => toggleSelectMessage(m)}
                      disabled={
                        !(
                          m.message.messageType === "audio" ||
                          m.message.messageType === "video" ||
                          m.message.messageType === "image" ||
                          m.message.messageType === "file" ||
                          m.message.messageType === "text"
                        )
                      }
                    />
                  </div>
                  <div className=" w-12 m-0">
                    <Message message={m} key_id={id} />
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      {forwardMessagesFlag && (
        <div className="h-4rem mb-2 px-4 flex justify-content-between align-items-center surface-100  ">
          <div
            // onClick={(e) => dispatch(clearForwardMessage())}
            onClick={handleCloseForwardMessages}
          >
            {" "}
            <i
              className={`pi pi-times`}
              style={{ fontSize: "1.125rem", color: "#000" }}
            ></i>{" "}
          </div>
          {selectedMessages?.length ? selectedMessages?.length : 0} selected
          {selectedMessages?.length > 0 && (
            <div
              className="mt-1"
              style={{
                width: "1.125rem",
                cursor: "pointer",
                opacity: selectedMessages?.length > 0 ? 1 : 0,
              }}
              onClick={handleShareMessages}
            >
              <ShareSvg />
            </div>
          )}
        </div>
      )}

      {showShareModal && (
        <ForwardMessagesModal
          selectedMessages={selectedForwardMessagesContent}
          onClose={() => setShowShareModal(false)}
        />
      )}
      <div className="flex justify-content-center w-full">
        {/* {showSelectionLimitMessage && (
        <SelectionLimitWarning onClose={() => setShowSelectionLimitMessage(false)} />
       )} */}
        {selectedMessages.length === 5 && (
          <SelectionLimitWarning
            onClose={() => setShowSelectionLimitMessage(false)}
          />
        )}
      </div>
    </div>
  );
};
/* eslint-enable */
export default Messages;
