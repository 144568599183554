import {
  doc,
  query,
  onSnapshot,
  collection,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";

import { db } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  changeIsUserSelected,
  changeUser,
  clearReplyMessage,
  resetChatUser,
  updateUserStatus,
} from "../../reducers/enqChatSlice";
import {
  StatusIcon,
  formatAMPM,
  formatMessage,
  isTimeOver,
  ticketStausSorted,
} from "../../utils/chatUtils";
import CustomerChatInfo from "./CustomerChatInfo";
import { isTextOrNumber } from "../../utils/commonUtils";
import { extractVariablesFormMessage } from "../../utils/templateUtils";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import { API_POST_UNREADMESSAGE } from "../../api/chat.service";

const Chats = () => {
  const customerContainerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [lastMessage, setLastMessage] = useState(null);
  const [acitveListener, setActiveListener] = useState(null);

  const { allotedAgent, agentSearchItem, chatId, user, ticketStatusFilter,selectedAgent } =
    useSelector((state) => state.enqChats);

  const { company } = useSelector((state) => state.company);
  const { accesskey } = useSelector((state) => state.authenticate);
  const dispatch = useDispatch();

  const botNumber = company?.phone;
  const handleSelect = (u,chat) => {
    API_POST_UNREADMESSAGE({"customerMobile":user?.mob})
    dispatch(changeIsUserSelected(true));
    dispatch(changeUser(u));
    dispatch(clearReplyMessage())
    chat?.unreadMessages&&API_POST_UNREADMESSAGE({"customerMobile":u?.mob});
  };

  useEffect(() => {
    if (!botNumber || !allotedAgent.role || !allotedAgent.mob) return;
    const getChats = async () => {
      let q;
      const searchField = isTextOrNumber(agentSearchItem)
        ? "phone"
        : "businessName";
      const searchValue = isTextOrNumber(agentSearchItem)
        ? `91${agentSearchItem}`
        : agentSearchItem;
      const isAdmin = accesskey[modulesName.SALES]?.includes(
        permissions.UPDATE
      );
      const hasTicketStatusFilter =
        ticketStatusFilter && ticketStatusFilter.length !== 0;
      const hasAgentSearchItem =
        agentSearchItem && agentSearchItem.length !== 0;
      let requests = [];
      requests.push(collection(db, "groups"));
      requests.push(where("botNumber", "==", botNumber));
      if (allotedAgent.role === "support") {
        let IDs = [allotedAgent?.agentId, -1];
        requests.push(where("agentId", "in", IDs));
      }
      if (allotedAgent.role !=="support" && selectedAgent) {
        requests.push(where("agentId", "==", selectedAgent));
      }
      if (hasTicketStatusFilter) {
        requests.push(where("ticketStatus", "==", ticketStatusFilter));
      }
      if (hasAgentSearchItem) {
        requests.push(where(searchField, ">=", searchValue));
        requests.push(where(searchField, "<=", searchValue + "\uf8ff"));
        requests.push(orderBy(searchField));
      }
      requests.push(orderBy("lastUpdatedAt", "desc"));
      requests.push(limit(25));
      const baseQuery = query(...requests);
      q = query(baseQuery);
      setLoading(true);

      if (acitveListener) {
        acitveListener();
      }

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        setLoading(false);
        const groups = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          let conversationBetween = doc.id.split("-");

          let bot = conversationBetween[0];
          if (bot == allotedAgent.mob) {
            let lastMessage = "";
            if (data?.lastMessage) {
              try {
                lastMessage = formatMessage(
                  JSON.parse(data?.lastMessage),
                  data.messageType
                );
              } catch (error) {
                console.log(error);
              }
            }
            const conversation = {
              ...data,
              customerInfo: {
                displayName: data.businessName,
                mob: data?.phone,
                ticketId: data?.ticketId,
                ticketStatus: data?.ticketStatus,
                enqStartedAt: data?.enquiryStartTime,
                agentId: data?.agentId,
                customerId: data?.customerId
              },
              lastMessage: lastMessage,
              enqStartedAt: data?.enquiryStartTime,
            };
            groups.push(conversation);
          }
        });
        setChats(groups);
        if (groups.length > 0) {
          setLastMessage(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
      });

      setActiveListener(() => unsubscribe);

      return () => {
        unsubscribe();
      };
    };
    getChats();
  }, [allotedAgent, agentSearchItem, ticketStatusFilter,selectedAgent]);

  useEffect(() => {
    let userStatus = null;
    if (chats) {
      chats?.forEach((item) => {
        if (user?.mob == item?.phone) {
          userStatus = item.customerInfo;
          if(user.ticketStatus!==userStatus?.ticketStatus||user.ticketId!==userStatus?.ticketId||user.enqStartedAt!==userStatus?.enqStartedAt){
            dispatch(updateUserStatus({...item.customerInfo,}));
          }
        }
      });
      // if (userStatus === null) {
      //   dispatch(resetChatUser());
      // }
    }

    if (Object.keys(user).length == 0 && chats.length != 0) {
      dispatch(changeUser(chats[0].customerInfo));
    }
  }, [chats]);

  useEffect(() => {
    return () => {
      dispatch(resetChatUser());
    };
  }, []);

  const loadMoreCustomersChat = async () => {
    if (!lastMessage) return;
    const isAdmin = accesskey[modulesName.SALES]?.includes(permissions.UPDATE);
    const hasTicketStatusFilter =
      ticketStatusFilter && ticketStatusFilter.length !== 0;
    const hasAgentSearchItem = agentSearchItem && agentSearchItem.length !== 0;
    const searchField = isTextOrNumber(agentSearchItem)
      ? "phone"
      : "businessName";
    const searchValue = isTextOrNumber(agentSearchItem)
      ? `91${agentSearchItem}`
      : agentSearchItem;
    let q;
    let requests = [];
    requests.push(collection(db, "groups"));
    requests.push(where("botNumber", "==", botNumber));
    if (allotedAgent.role === "support") {
      let IDs = [allotedAgent?.agentId, -1];
      requests.push(where("agentId", "in", IDs));
    }
    if (allotedAgent.role !=="support" && selectedAgent) {
      requests.push(where("agentId", "==",selectedAgent));
    }
    if (hasTicketStatusFilter) {
      requests.push(where("ticketStatus", "==", ticketStatusFilter));
    }
    if (hasAgentSearchItem) {
      requests.push(where(searchField, ">=", searchValue));
      requests.push(where(searchField, "<=", searchValue + "\uf8ff"));
      requests.push(orderBy(searchField));
    }
    requests.push(orderBy("lastUpdatedAt", "desc"));
    requests.push(startAfter(lastMessage));
    requests.push(limit(25));
    const baseQuery = query(...requests);
    q = query(baseQuery);
    setLoading(true);
    const querySnapshot = await getDocs(q);
    setLoading(false);
    if (querySnapshot.docs.length === 0) {
      setLastMessage(null);
      return;
    }
    const groups = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      let conversationBetween = doc.id.split("-");
      let bot = conversationBetween[0];
      if (bot == allotedAgent.mob) {
        let lastMessage = "";
        if (data?.lastMessage) {
          try {
            lastMessage = formatMessage(
              JSON.parse(data?.lastMessage),
              data.messageType
            );
          } catch (error) {
            console.log(error);
          }
        }
        const conversation = {
          ...data,
          customerInfo: {
            displayName: data.businessName,
            mob: data?.phone,
            ticketId: data?.ticketId,
            ticketStatus: data?.ticketStatus,
            enqStartedAt: data?.enquiryStartTime,
            agentId: data?.agentId,
          },
          lastMessage: lastMessage,
          enqStartedAt: data?.enquiryStartTime,
        };

        groups.push(conversation);
      }
    });
    setChats((prev) => [...prev, ...groups]);
    if (groups.length > 0) {
      setLastMessage(querySnapshot.docs[querySnapshot.docs.length - 1]);
    }
  };

  const handleScroll = () => {
    if (customerContainerRef.current && !loading) {
      const { scrollTop, clientHeight, scrollHeight } =
        customerContainerRef.current;

      const tolerance = 1; // to handle decimal values of scrollTop

      if (scrollTop + clientHeight + tolerance >= scrollHeight) {
        const previousScrollTop = customerContainerRef.current.scrollTop;
        setLoading(true);

        loadMoreCustomersChat()
          .then(() => {
            setLoading(false);
            customerContainerRef.current.scrollTop = previousScrollTop;
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
      }
    }
  };

  return (
    <>
      <div
        ref={customerContainerRef}
        onScroll={handleScroll}
        className="chats flex-grow-1 "
      >
        {loading && (
          <div className="text-center">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "1rem" }}
            ></i>
          </div>
        )}
        {chats && chats.length !== 0 ? (
          chats.map((chat, i) => (
            <div
              className={
                user?.mob == chat?.customerInfo?.mob
                  ? "userChat userChatSeleceted"
                  : "userChat"
              }
              key={i}
              onClick={() => handleSelect(chat.customerInfo,chat)}
            >
              <CustomerChatInfo customer={chat} />
            </div>
          ))
        ) : (
          <div className="flex justify-content-center">No customers found</div>
        )}
      </div>
    </>
  );
};

export default Chats;
