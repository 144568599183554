import { ReactComponent as Edit } from "../../svg/edit.svg";
import { CustomButton } from "../CustomButton";
import { useSelector } from "react-redux";
function EditButton({ onClick }) {
  const { isEditable } = useSelector((state) => state.canvas);
  return (
    <CustomButton
      varient="icon-button  absolute"
      style={{ top: 0, right: 0 }}
      onClick={onClick}
      icon={<Edit />}
      disabled={!isEditable}
    />
  );
}

export default EditButton;
