import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useEffect, useState } from "react";
import styles from "./style.module.css";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewOption,
  updateNodePayload,
  updateOptionTitle,
  deleteOption,
} from "../../reducers/canvas/canvasSlice";
import { CustomButton } from "../CustomButton";
import { Text } from "../Text";
import { isOptionValueValid } from "../../utils/canvasUtils";
import StartNodeCheckMark from "../../view/Bots/components/start-node-checkmark";

const List = ({ onSave = () => {}, onCancel = () => {} }) => {
  const dispatch = useDispatch();
  const [bodyText, setBodyText] = useState("");
  const [globalButton, setGloabalButton] = useState("");
  const [listHeading, setListHeading] = useState("");
  const [listOption, setListOption] = useState([]);

  const { selectedNode } = useSelector((state) => state.canvas);
  const { inputType } = useSelector((state) => state.multiInput);

  const handleBodyText = (e) => {
    setBodyText(e.target.value);
    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { body: e.target.value },
      })
    );
  };
  const handleGlobalButton = (e) => {
    setGloabalButton(e.target.value);
    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { globalButton: e.target.value },
      })
    );
  };
  const handleListHeading = (e) => {
    setListHeading(e.target.value);
    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { listHeading: e.target.value },
      })
    );
  };

  const addListOption = () => {
    dispatch(
      addNewOption({
        selectedNodeId: selectedNode.id,
        newOption: {
          id: uuidv4(),
          type: "text",
          title: "",
          postbackText: "",
        },
      })
    );
  };

  const handleDeleteOption = (id) => {
    setListOption((prevOptions) =>
      prevOptions?.filter((option) => option.id != id)
    );
    dispatch(deleteOption({ nodeId: selectedNode.id, optionId: id }));
  };

  const handleOptionChange = (id, value) => {
    const updatedOption = listOption.map((option) =>
      option.id === id ? { ...option, value } : option
    );
    setListOption(updatedOption);
    dispatch(
      updateOptionTitle({
        selectedNodeId: selectedNode.id,
        optionId: id,
        optionTitle: value,
      })
    );
  };

  const handleSaveClick = () => {
    if (
      !listHeading.trim() ||
      !globalButton.trim() ||
      !bodyText.trim() ||
      !isOptionValueValid(listOption)
    )
      return;
    else {
      onSave();
    }
  };

  useEffect(() => {
    if (!selectedNode) return;
    setBodyText(selectedNode.data.body);
    setGloabalButton(selectedNode.data.globalButton);
    setListHeading(selectedNode.data.listHeading);
    if (selectedNode.data.options.length > 0) {
      const options = selectedNode.data.options.map((opt) => {
        const optId = opt.id;
        const optObj = {
          id: optId,
          value: opt?.title,
        };
        return optObj;
      });
      setListOption(options);
    }
    return () => setListOption([]);
  }, [selectedNode, inputType]);

  return (
    <div>
      <div className="mb-3">
        <Text>Title *</Text>

        <InputText
          value={listHeading}
          onChange={(e) => {
            handleListHeading(e);
          }}
          className={`w-full mt-3`}
          maxLength={20}
          placeholder="Tile (max 20 chars)"
        />

        {!listHeading?.trim() && (
          <small className="p-error">Please enter title.</small>
        )}
      </div>
      <div className="flex flex-column">
        <label htmlFor="bodyText">Message *</label>

        <InputTextarea
          autoFocus
          value={bodyText}
          onChange={(e) => {
            handleBodyText(e);
          }}
          cols={"3"}
          className={`mt-2`}
          placeholder="Enter message (max 1028 chars)"
          maxLength={1028}
        />

        {!bodyText?.trim() && (
          <small className="p-error">Please enter message.</small>
        )}
      </div>
      <div className="mt-3 field flex flex-column">
        <label htmlFor="globalButton">Button *</label>

        <InputText
          value={globalButton}
          onChange={(e) => {
            handleGlobalButton(e);
          }}
          className={`w-full `}
          maxLength={20}
          placeholder="Button label ( max 20 chars)"
        />

        {!globalButton?.trim() && (
          <small className="p-error">Please enter button label</small>
        )}
      </div>

      <div>
        <div className="mb-3 flex justify-content-between align-items-center gap-3">
          <div>
            <Text>List Options :</Text>
            <Text type={"small-text"} color={"grey"}>
              (*Upto 10 options)
            </Text>
          </div>

          {listOption.length < 10 && (
            <CustomButton
              varient="outline my-3"
              icon="pi pi-plus"
              label="Add Option"
              className={styles.plusButton}
              onClick={addListOption}
            />
          )}
        </div>
        {listOption.map((option, idx) => (
          <div
            key={option.id}
            className={"w-full flex flex-column justify-content-start mt-1"}
          >
            <div className="flex w-full justify-content-start align-items-center gap-2">
              <InputText
                placeholder={`Option label (max 20 chars)`}
                value={option.value}
                className={`w-full ${styles.quickReplyButton}`}
                autoFocus
                onChange={(e) => {
                  handleOptionChange(option.id, e.target.value);
                }}
                maxLength={20}
              />

              <i
                className="pi pi-trash cursor-pointer"
                onClick={() => handleDeleteOption(option.id)}
              />
            </div>
            {!option.value?.trim() && (
              <small className="p-error">Enter option label</small>
            )}
          </div>
        ))}
      </div>
      <StartNodeCheckMark />
      <div className=" mt-6 mb-3 flex justify-content-end gap-3 align-items-center">
        <CustomButton varient="outline" label={"Cancel"} onClick={onCancel} />
        <CustomButton
          varient="filled"
          label={"Save"}
          onClick={handleSaveClick}
        />
      </div>
    </div>
  );
};

export default List;
