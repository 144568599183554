import styles from "./styles.module.css";
import { Sidebar } from "primereact/sidebar";
import { useState, useEffect, useRef } from "react";
import { Text } from "../../components/Text";
import { Badge } from "primereact/badge";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { db } from "../../firebase";
import NotificationSound from "./../../assets/notificationSound.mp3";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  onSnapshot,
  writeBatch,
  orderBy,
  limit,
} from "firebase/firestore";
import { formatAMPM, formatMessage } from "../../utils/chatUtils";

const getMessageAge = (timestamp) => {
  if (!timestamp) return;
  const currentDate = new Date();
  const messageDate = timestamp.toDate();

  const timeDiff = currentDate.getTime() - messageDate.getTime();
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) return years === 1 ? "1 year ago" : `${years} years ago`;
  if (months > 0) return months === 1 ? "1 month ago" : `${months} months ago`;
  if (days > 0) return days === 1 ? "1 day ago" : `${days} days ago`;
  if (hours > 0) return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  if (minutes > 0)
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;
};
function Notifications() {
  const [visibleRight, setVisibleRight] = useState(false);
  const [unReadMsg, setUnReadMsg] = useState(0);
  const [countEnquiryToast, setCountEnquiryToast] = useState(0);
  const [newOrderNotifications, setNewOrderNotifications] = useState([]);
  const [newEnquiryNotifications, setNewEnquiryNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.authenticate);
  const { company } = useSelector((state) => state.company);
  const userId = user?.id;
  const botNumber = company?.phone;
  const toast = useRef(null);
  const notificationContainerRef = useRef(null);

  const generateToastNotification = () => {
    const maxNotificationsToShow = 3;
    const _notifications = newEnquiryNotifications.slice(
      0,
      maxNotificationsToShow
    );

    return (
      <div className="w-full">
        {_notifications.map((toastNoti, idx) => (
          <div key={idx} className="my-1">
            <div className="flex align-items-center gap-2">
              <Text type={"heading"}>{toastNoti.name}</Text>
              <div className="text-xs">{toastNoti.lastUpdatedAt}</div>
            </div>
            <Text type={"sub-heading"}>{toastNoti?.lastMessage?.text}</Text>
          </div>
        ))}
        {newEnquiryNotifications.length > maxNotificationsToShow && (
          <div className="flex justify-content-center">.....</div>
        )}
      </div>
    );
  };

  const handleOrderNotiStatusUpdate = async () => {
    if (userId) {
      try {
        const linkerRef = collection(db, "notification_linkers");
        const querySnapshot = await getDocs(
          query(
            linkerRef,
            where("userId", "==", userId),
            where("status", "==", "unread"),
            limit(25)
          )
        );

        const batch = writeBatch(db);

        querySnapshot.forEach((d) => {
          const linkerDocRef = doc(linkerRef, d.id);
          batch.update(linkerDocRef, { status: "read" });
        });

        await batch.commit();
      } catch (error) {
        console.error("Error updating status: ", error);
      }
    }
  };

  const handleEnquiryNotiStatus = async () => {
    const player = new Audio(NotificationSound);
    try {
      await player.play();
    } catch (error) {
      console.error("Error during playback:", error.message);
    }

    try {
      const groupRef = collection(db, "groups");
      const querySnapshot = await getDocs(
        query(
          groupRef,
          where("botNumber", "==", botNumber),
          where("notificationStatus", "==", "new"),
          where("messageType", "==", "inbound"),
          where("isAgentActive", "==", true),
          where("agentId", "==", userId),
          limit(25)
        )
      );

      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        const docRef = doc.ref;
        batch.update(docRef, { notificationStatus: "read" });
      });

      await batch.commit();
    } catch (error) {
      console.error("Error updating status: ", error);
    }
  };

  const NotificationMessage = ({ data, idx }) => {
    const darkBg = "#F5F5F5";
    const lightBg = "#ffffff";
    return (
      <div
        key={idx}
        className="p-2"
        style={{ background: idx % 2 === 0 ? darkBg : lightBg }}
      >
        <Text type={"sub-heading"}>
          <i className="pi pi-circle-fill" style={{ fontSize: "0.5rem" }} />{" "}
          {data.message}
        </Text>
        <Text type={"small-text"} style={{ opacity: "0.4" }}>
          {getMessageAge(data?.createdAt)}
        </Text>
      </div>
    );
  };

  const handleNotificatioIconClick = () => {
    setVisibleRight(true);
  };

  useEffect(() => {
    if (userId) {
      const linkerRef = collection(db, "notification_linkers");
      const notificationsRef = collection(db, "notifications");

      const linkerQuery = query(
        linkerRef,
        where("userId", "==", userId),
        where("status", "==", "unread"),
        orderBy("createdAt", "desc"),
        limit(25)
      );

      const unsubscribe = onSnapshot(linkerQuery, async (querySnapshot) => {
        const promises = [];
        const updatedNotifications = [];

        querySnapshot.forEach((linkerDoc) => {
          const notificationId = linkerDoc.data().notificationId;
          const status = linkerDoc.data().status;

          const promise = getDoc(doc(notificationsRef, notificationId)).then(
            (notificationDoc) => {
              if (notificationDoc.exists()) {
                const notification = notificationDoc.data();
                notification.status = status;
                notification.id = notificationDoc.id;
                updatedNotifications.push(notification);
              }
            }
          );

          promises.push(promise);
        });

        await Promise.all(promises);
        setNewOrderNotifications(updatedNotifications);
      });

      return () => {
        unsubscribe();
        setNewOrderNotifications([]);
      };
    }
  }, [userId]);

  useEffect(() => {
    if (!botNumber) return;

    const groupRef = collection(db, "groups");
    const currentTimestamp = new Date().getTime();
    const fiveMinutesAgoTimestamp = currentTimestamp - 5 * 60 * 1000;
    const enquiryNotificationQuery = query(
      groupRef,
      where("botNumber", "==", botNumber),
      where("agentId", "==", userId),
      where("notificationStatus", "==", "new"),
      where("messageType", "==", "inbound"),
      where("lastUpdatedAt", ">=", fiveMinutesAgoTimestamp),
      orderBy("lastUpdatedAt", "desc"),
      limit(4)
    );

    // where("isAgentActive", "==", true),
    const unsubscribe = onSnapshot(
      enquiryNotificationQuery,
      async (querySnapshot) => {
        const enqNoti = [];
        querySnapshot.forEach((doc) => {
          const enqData = doc.data();
          const _enquiryNotification = {};
          if (enqData.lastMessage?.text !== "") {
            _enquiryNotification.lastMessage = formatMessage(
              JSON.parse(enqData.lastMessage),
              enqData.messageType
            );
          }
          _enquiryNotification.notificationStatus = enqData.notificationStatus;
          _enquiryNotification.lastUpdatedAt = formatAMPM(
            new Date(enqData.lastUpdatedAt)
          );
          _enquiryNotification.customerPhone = enqData.phone;
          _enquiryNotification.name = enqData.name || "+" + enqData.phone;

          enqNoti.push(_enquiryNotification);
        });

        if (enqNoti.length > 0) {
          setCountEnquiryToast((prev) => prev + enqNoti.length);
        }

        setNewEnquiryNotifications(enqNoti);
      }
    );

    return () => {
      unsubscribe();
      setNewEnquiryNotifications([]);
    };
  }, [botNumber]);

  useEffect(() => {
    if (newEnquiryNotifications.length > 0) {
      if (countEnquiryToast > 2) {
        toast.current.clear();
        setCountEnquiryToast(0);
      }
      toast.current.show({
        severity: "info",
        content: generateToastNotification(),
        life: 8000,
      });
    }
  }, [newEnquiryNotifications]);

  useEffect(() => {
    let count = 0;
    for (let i = 0; i < newOrderNotifications.length; i++) {
      if (newOrderNotifications[i]?.status === "unread") count++;
    }
    setUnReadMsg(count);
  }, [newOrderNotifications]);

  const loadMoreNotificationsChat = async () => {
    try {
      if (!userId || !botNumber) return;

      const lastOrderTimestamp = newOrderNotifications.length
        ? newOrderNotifications[newOrderNotifications.length - 1].createdAt
        : new Date();
      const olderOrderNotificationsQuery = query(
        collection(db, "notification_linkers"),
        where("userId", "==", userId),
        where("status", "==", "unread"),
        orderBy("createdAt", "desc"),
        where("createdAt", "<", lastOrderTimestamp),
        limit(25)
      );
      const [orderQuerySnapshot] = await Promise.all([
        getDocs(olderOrderNotificationsQuery),
      ]);
      const notificationsRef = collection(db, "notifications");
      const fetchedOrderNotifications = [];
      const orderPromises = [];
      orderQuerySnapshot.forEach((document) => {
        const notificationId = document.data().notificationId;
        const promise = getDoc(doc(notificationsRef, notificationId)).then(
          (notificationDoc) => {
            if (notificationDoc.exists()) {
              const notification = notificationDoc.data();
              notification.id = notificationDoc.id;
              fetchedOrderNotifications.push(notification);
            }
          }
        );
        orderPromises.push(promise);
      });

      await Promise.all(orderPromises);

      setNewOrderNotifications((prevNotifications) => [
        ...prevNotifications,
        ...fetchedOrderNotifications,
      ]);
    } catch (error) {
      console.error("Error loading more notifications:", error);
    }
  };

  const handleScroll = () => {
    if (notificationContainerRef.current && !loading) {
      const { scrollTop, clientHeight, scrollHeight } =
        notificationContainerRef.current;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        const previousScrollTop = notificationContainerRef.current.scrollTop;
        setLoading(true);

        loadMoreNotificationsChat()
          .then(() => {
            setLoading(false);
            notificationContainerRef.current.scrollTop = previousScrollTop;
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
      }
    }
  };

  return (
    <div>
      <Toast
        ref={toast}
        onShow={handleEnquiryNotiStatus}
        className={`cursor-pointer ${styles["custom-toast"]} border-none`}
      />
      <div
        className="relative cursor-pointer"
        onClick={handleNotificatioIconClick}
      >
        <i className="pi pi-bell cursor-pointer"></i>
        {unReadMsg > 0 && (
          <Badge
            value={unReadMsg > 9 ? "9+" : unReadMsg}
            severity="danger"
            className={`absolute custom-badge `}
            style={{ fontSize: "0.5rem" }}
          />
        )}
      </div>
      <Sidebar
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
        className="custom-sidebar"
      >
        <div className="my-3 notification-sidebar">
          <div className="flex justify-content-between align-items-center gap-3 flex-wrap">
            <Text type={"heading"}>Notifications</Text>
            <div onClick={handleOrderNotiStatusUpdate}>
              <Text
                type={"sub-heading"}
                color={"#1C738E"}
                style={{ padding: "4px", cursor: "pointer" }}
              >
                Mark all as read
              </Text>
            </div>
          </div>
          <div
            className="my-3 "
            ref={notificationContainerRef}
            onScroll={handleScroll}
            style={{ maxHeight: "80vh", overflowY: "auto" }}
          >
            {[...(newOrderNotifications || [])].map((element, i) => (
              <NotificationMessage
                key={element.id || i}
                data={element}
                idx={i + 1}
              />
            ))}
            {loading && <div>Loading more...</div>}
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default Notifications;
