import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Tag } from "primereact/tag";
import {
  registerCompany,
  stopAIService,
  startAIService,
  checkCompanyRegistration,
  updateAICompany,
} from "../../reducers/aiSlice";
import "./dialogStyle.css";
const FileUploadComponent = ({
  uploadedFile,
  onFileSelect,
  url,
  setUrl,
  onSubmit,
}) => {
  const header = (
    <div className="w-full py-4 px-6 text-xl font-semibold uppercase text-gray-900 text-center">
      Start AI Service
    </div>
  );

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    e.target.value = "";
    if (file) {
      if (file.size > 1000000) {
        // 1MB limit
        alert("File size should not exceed 1MB");
        return;
      }
      const allowedTypes = [".json", ".csv", ".txt"];
      const fileExtension = "." + file.name.split(".").pop().toLowerCase();
      if (!allowedTypes.includes(fileExtension)) {
        alert("Please upload only JSON, CSV, or TXT files");
        return;
      }
      onFileSelect(file);
    }
  };

  return (
    <div className="sm:w-[32rem] mx-auto my-10 overflow-hidden rounded-2xl bg-white shadow-lg sm:max-w-lg">
      <Card header={header} className="p-0">
        <div className="space-y-4 px-8 py-10">
          <div className="flex flex-col items-center justify-center rounded-lg border-4 border-dashed px-4 py-10">
            <p className="mt-4 text-center text-xl font-medium text-gray-800">
              Drop Files here or
              <label className="shadow-blue-100 mt-2 block rounded-full border bg-white px-4 py-0.5 font-normal text-blue-500 shadow hover:bg-blue-50 cursor-pointer">
                <input
                  disabled={url}
                  className="hidden"
                  type="file"
                  name="file"
                  accept=".json,.csv,.txt"
                  onChange={handleFileSelect}
                />
                Browse
                <i className="pi pi-upload text-xl ml-2 text-blue-500 mb-4"></i>
              </label>
            </p>
          </div>

          {/* Add Website URL Input Field */}
          <div className="flex flex-row items-center justify-center rounded-lg border-4 border-dashed px-4 py-10 mt-4">
            <p className="mt-4 text-center center text-xl font-medium text-gray-800">
              Or Enter Website URL
              <i className="pi pi-globe text-xl text-blue-500 mb-4"></i>
            </p>
            <div>
              <input
                type="url"
                placeholder="https://app.skalebot.com"
                className="mt-4 w-full px-4 py-2"
                onChange={(e) => setUrl(e.target.value)}
                disabled={uploadedFile}
              />
            </div>
          </div>

          {uploadedFile && (
            <div className="mt-4 p-4 bg-gray-50 rounded-lg">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <i className="pi pi-file text-blue-500"></i>
                  <span className="text-sm font-medium text-gray-700">
                    {uploadedFile.name}
                  </span>
                </div>
                <Button
                  icon="pi pi-times"
                  className="p-button-text p-button-rounded p-button-plain"
                  onClick={() => onFileSelect(null)}
                />
              </div>
              <div className="mt-2">
                <span className="text-xs text-gray-500">
                  {(uploadedFile.size / 1024).toFixed(2)} KB
                </span>
              </div>
            </div>
          )}

          <Button
            label="Start Service"
            severity="primary"
            className="w-full ai-button mt-4 px-10 py-2"
            onClick={onSubmit}
            disabled={!uploadedFile && !url}
          />
        </div>
      </Card>
    </div>
  );
};

// RegistrationFormComponent.jsx
const RegistrationFormComponent = ({
  registrationForm,
  setRegistrationForm,
  onSubmit,
  isLoading,
  sectorOptions,
  featureOptions,
  update,
}) => {
  const header = (
    <div className="text-center">
      <h3 className="text-xl font-semibold m-0 text-gray-900">
        Register For AI Service
      </h3>
    </div>
  );

  return (
    <Card className="p-4" header={header}>
      <div className="space-y-4">
        <div className="mb-2">
          <label className="block mb-2 text-gray-900">Company Sector</label>
          <Dropdown
            value={registrationForm.companySector}
            options={sectorOptions}
            onChange={(e) =>
              setRegistrationForm((prev) => ({
                ...prev,
                companySector: e.value,
              }))
            }
            className="w-full"
            placeholder="Select a sector"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-gray-900">AI Features</label>
          <Dropdown
            value={registrationForm.aiFeature}
            options={featureOptions}
            onChange={(e) =>
              setRegistrationForm((prev) => ({
                ...prev,
                aiFeature: e.value,
              }))
            }
            className="w-full"
            placeholder="Select features"
          />
        </div>

        <Button
          label={update ? "Update Company" : "Register Company"}
          onClick={onSubmit}
          disabled={
            isLoading ||
            !registrationForm.companySector ||
            !registrationForm.aiFeature
          }
          className="w-full ai-button"
        />
      </div>
    </Card>
  );
};

// ActiveServiceComponent.jsx
const ActiveServiceComponent = ({ onStop, isLoading, setCurrentStep }) => {
  const header = (
    <div className="text-center">
      <h3 className="text-xl font-semibold m-0 text-gray-900">
        Stop AI Service
      </h3>
    </div>
  );

  return (
    <Card header={header} className="shadow-2">
      <div className="flex flex-column gap-4">
        <div className="flex align-items-center justify-content-center gap-2">
          <ProgressSpinner
            style={{ width: "20px", height: "20px" }}
            strokeWidth="4"
            animationDuration=".5s"
          />
          <Tag severity="success" value="AI Service is currently active" />
        </div>

        <Button
          label={isLoading ? "Processing..." : "Stop AI Service"}
          icon={isLoading ? "pi pi-spinner" : "pi pi-power-off"}
          loading={isLoading}
          onClick={onStop}
          disabled={isLoading}
          severity="danger"
          className="w-full ai-button"
        />

        <Button
          label="Update Configuration"
          onClick={() => setCurrentStep("UPDATE_COMPANY")}
          disabled={isLoading}
          className="w-full ai-button"
        />

        <Button
          label="Update File"
          onClick={() => setCurrentStep("UPDATE_FILE")}
          disabled={isLoading}
          className="w-full ai-button"
        />
      </div>
    </Card>
  );
};

// Main AIServiceDialog component
const AIServiceDialog = ({ visible, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.ai);

  const [currentStep, setCurrentStep] = useState("CHECKING");
  const [registrationForm, setRegistrationForm] = useState({
    companySector: "",
    aiFeature: "",
  });
  const [uploadedFile, setUploadedFile] = useState(null);
  const [url, setUrl] = useState(null);
  const [error, setError] = useState(null);

  const sectorOptions = [
    {
      label: "Ecommerce",
      value: "ECOMMERCE",
    },
  ];

  const featureOptions = [
    {
      label: "Conversational AI",
      value: "CONVERSATIONAL",
    },
    { label: "FAQs & Conversation AI", value: "FAQ_CONVERSATIONAL" },
    { label: "Actions & Conversation AI", value: "ACTIONS_CONVERSATIONAL" },
    {
      label: "FAQ & Actions & Conversational AI",
      value: "ACTIONS_FAQ_CONVERSATIONAL",
    },
  ];
  useEffect(() => {
    setError(null);
  }, [
    uploadedFile,
    currentStep,
    featureOptions,
    sectorOptions,
    registrationForm,
    isLoading,
  ]);

  useEffect(() => {
    const checkStatus = async () => {
      if (visible) {
        setCurrentStep("CHECKING");
        try {
          const registrationResult = await dispatch(
            checkCompanyRegistration()
          ).unwrap();
          if (registrationResult) {
            setRegistrationForm({
              companySector: registrationResult.companySector,
              aiFeature: registrationResult.aiFeature,
            });
            setCurrentStep(
              registrationResult.botStatus === "ACTIVE"
                ? "ACTIVE"
                : "FILE_UPLOAD"
            );
          } else {
            setCurrentStep("REGISTRATION");
          }
        } catch (err) {
          setError(err.message || "An error occurred while checking status");
          setCurrentStep("REGISTRATION");
        }
      }
    };

    checkStatus();
  }, [visible, dispatch]);

  useEffect(() => {
    if (!visible) {
      setRegistrationForm({
        companySector: "",
        aiFeature: "",
      });
      setUploadedFile(null);
      setError(null);
    }
  }, [visible]);

  const handleRegistrationSubmit = async () => {
    try {
      await dispatch(registerCompany(registrationForm)).unwrap();
      setError(null);
      setCurrentStep("FILE_UPLOAD");
    } catch (err) {
      setError(err.message || "Registration failed");
    }
  };

  const handleStartService = async () => {
    if (!uploadedFile && !url) return;
    try {
      await dispatch(startAIService({ file: uploadedFile, url })).unwrap();
      setCurrentStep("ACTIVE");
    } catch (err) {
      setError(err.message || "Failed to start AI service");
    }
  };

  const handleStopService = async () => {
    try {
      await dispatch(stopAIService()).unwrap();
      setCurrentStep("FILE_UPLOAD");
    } catch (err) {
      setError(err.message || "Failed to stop AI service");
    }
  };
  const handleCompanyUpdateSubmit = async () => {
    try {
      await dispatch(updateAICompany(registrationForm)).unwrap();
      setError(null);
      setCurrentStep("ACTIVE");
    } catch (err) {
      setError(err.message || "Failed to update company botconfiguration");
    }
  };
  const renderContent = () => {
    switch (currentStep) {
      case "CHECKING":
        return (
          <div className="flex justify-center items-center p-4">
            <ProgressSpinner />
          </div>
        );
      case "REGISTRATION":
      case "UPDATE_COMPANY":
        return (
          <RegistrationFormComponent
            registrationForm={registrationForm}
            setRegistrationForm={setRegistrationForm}
            onSubmit={
              currentStep == "REGISTRATION"
                ? handleRegistrationSubmit
                : handleCompanyUpdateSubmit
            }
            isLoading={isLoading}
            sectorOptions={sectorOptions}
            featureOptions={featureOptions}
            update={currentStep == "REGISTRATION" ? false : true}
          />
        );
      case "FILE_UPLOAD":
      case "UPDATE_FILE":
        return (
          <FileUploadComponent
            uploadedFile={uploadedFile}
            setUrl={setUrl}
            url={url}
            onFileSelect={setUploadedFile}
            onSubmit={handleStartService}
          />
        );
      case "ACTIVE":
        return (
          <ActiveServiceComponent
            onStop={handleStopService}
            isLoading={isLoading}
            setCurrentStep={setCurrentStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog visible={visible} onHide={onHide} className="custom-dialog">
      {error && (
        <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg">
          {error}
        </div>
      )}
      {renderContent()}
    </Dialog>
  );
};

export default AIServiceDialog;
