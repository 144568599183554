import axios from "axios";
import axiosInstance from "./axios.instance";
import baseUrl from "./server";

const axiosInstance1 = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
});

const API_GET_FILE_URL = async () => {
  try {
    const resp = await axiosInstance.post("/image", {
      imageType: `botflow`,
    });
    return resp;
  } catch (err) {
    throw err;
  }
};

const API_UPLOAD_FILE = async (fileUploadUrl, file, contentType) => {
  try {
    const resp = await axiosInstance1.put(fileUploadUrl, file, {
      headers: { "Content-Type": contentType },
    });
    return resp;
  } catch (err) {
    throw err;
  }
};

export { API_GET_FILE_URL, API_UPLOAD_FILE }