import { useState, useEffect, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { API_PUT_TICKET_STATUS } from "../../../api/enquiry.services";
import { useSelector } from "react-redux";
import { CustomButton } from "../../../components/CustomButton";
import CustomSaveModel from "../../../components/CustomeSaveModel";
import CONSTANTS from "../../../config/Constants";
import { useLayout } from "../../../context/LayoutContext";

const ticketStatusOptions = [
  {
    name: "Follow Up",
    code: "Follow Up",
  },
  {
    name: "Closed",
    code: "Closed",
  },
];
let defaultTicketStatus = { name: "", code: "" };
function ChangeTicketStatus({ handleSend = () => { } }) {
  const [ticketStatus, setTitcketStatus] = useState(defaultTicketStatus);
  const { user } = useSelector((state) => state.enqChats);
  const { toast } = useLayout()

  const changeStatus = (newStatus) => {
    API_PUT_TICKET_STATUS(user.ticketId, {
      status: newStatus.code,
    })
      .then(() => {
        setTitcketStatus(newStatus);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const status = useRef(null);
  const [showSave, setShowSave] = useState(false);
  const SaveModel = () => {
    return (
      <CustomSaveModel
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };
  const alertText = () => {
    let value = status?.current?.value?.code
    return <div>{CONSTANTS.TICKET_STATUS_CONFIRMATION.replace("{{value}}", value)}</div>;
  };
  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false);
          }}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={() => {
            handleTicketStatusChange(status?.current)
            setShowSave(false);
          }
          }
        />
      </div>
    );
  };
  const onHide = () => {
    setShowSave(false);
  };
  const handleTicketStatusChange = (e) => {
    if (e.value.code == "Closed")
    {
      changeStatus(e.value);
      toast.current.show({
        severity: "success",
        detail: CONSTANTS.TICKET_CLOSED_CONFIRMATION,
      });
      // let CLOSED_MESSAGE ="We're delighted to inform you that your concern has been successfully resolved! 🎉";
      // handleSend(CLOSED_MESSAGE)
      //   .then(() => {

      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    } else
    {
      changeStatus(e.value);
    }
  };

  useEffect(() => {
    let currentStatus = { ...defaultTicketStatus };
    if (Object.keys(user).length != 0)
    {
      ticketStatusOptions?.forEach((ele) => {
        if (user?.ticketStatus && (ele?.code?.toLowerCase() === user?.ticketStatus?.toLowerCase()))
        {
          currentStatus = { ...ele };
        }
      });
      setTitcketStatus(currentStatus);
    } else
    {
      setTitcketStatus(currentStatus);
    }
    return () => {
      setTitcketStatus(currentStatus);
    };
  }, [user]);
  return (
    <>
      <SaveModel />
      {ticketStatus?.code !== 'Closed' && <Dropdown
        value={ticketStatus}
        onChange={(e) => {
          status.current = e
          setShowSave(true);
        }}
        options={ticketStatusOptions}
        optionLabel="name"
        placeholder="Change Status"
        className="w-full"
      />}
    </>
  );
}

export default ChangeTicketStatus;
