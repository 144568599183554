import { useSelector } from "react-redux";
import { Route, Navigate } from "react-router-dom";

const RoleBasedRoute = ({ children, allowedRoles,condition="", location, permission=[] }) => {
  const { accesskey, pause } = useSelector((state) => state.authenticate);
  let access=false;
  switch(condition){
    case "OR":
      access = permission.some(perm => accesskey[location]?.includes(perm)) ;
      break;
    case "AND":
      access = permission.every(perm => accesskey[location]?.includes(perm)) ;
      break;
    case "NEITHER":
      access = permission.every(perm => !accesskey[location]?.includes(perm));
      break;
    default: 
        access=accesskey[location]?.includes(permission);
      break;
  }
  if (access|| pause) {
    return children;
  } else {
    return <Navigate to="/unauthorized" />;
  }
};

export default RoleBasedRoute;
