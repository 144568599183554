import styles from "./styles.module.css";
import { Text } from "../../../components/Text";
import { useState, useRef } from "react";
import { Image } from "primereact/image";

function LogoInput({ handleSelectedImage = () => {}, currLogo = null }) {
  const [previewUrl, setPreiewUrl] = useState(null);
  const mediaFileRef = useRef(null);

  const handleImg = (e) => {
    let img = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = (readerEvent) => {
      if (img.type.includes("image")) {
        setPreiewUrl(readerEvent.target.result);
      }
    };
    handleSelectedImage(img);
  };

  return (
    <div>
      <input
        ref={mediaFileRef}
        type="file"
        accept={`image/*`}
        hidden
        onChange={handleImg}
      />
      <button
        type="button"
        onClick={() => mediaFileRef.current.click()}
        className={`w-full cursor-pointer p-3 ${styles["logo-input-container"]}`}
      >
        <div className="flex justify-content-center align-items-center gap-2">
          <Text type={"sub-heading"}>Upload bussiness logo</Text>
          <i className="pi pi-cloud-upload" />
        </div>
      </button>
      {previewUrl && (
        <div className="my-3">
          <Image src={previewUrl} alt="Image" width="100%" />
        </div>
      )}
    </div>
  );
}

export default LogoInput;
