import {CampaignTable} from "./CampaignTable";
import "./tabStyles.css";
import { useSelector, useDispatch } from "react-redux";
import GroupShare from "../../components/GroupShare";
import {
  changeCampaignType,
  resetCampaignType,
} from "../../reducers/campaignSlice";
import { useEffect } from "react";
import Loader from "../../components/Loader";
import CustomerAnalyticsDataView from "./CustomerAnalyticsDataView";
import CustomSwitch from "../../components/CustomSwitch";
import { changeShowGroupShareDialog } from "../../reducers/appSlice";
import { CustomButton } from "../../components/CustomButton";
import TrailDashboard from "../TrailDashboard";
const campaignTypes = [{ name: "Schedule Campaigns", value: "product" }];

const ScheduleCampaigns = () => {
  const { showGroupShareDialog } = useSelector((state) => state.application);

  const { campaignLoading, selectedCampaignId, campaignType } = useSelector(
    (state) => state.campaign
  );
  const { userSub } = useSelector((state) => state.authenticate);
  const dispatch = useDispatch();

  const renderTable = () => {
    return <CampaignTable />;
  };

  const handleSwitch = (campType) => {
    dispatch(changeCampaignType(campType));
  };

  const handleBroadCastFormDialog = () => {
    dispatch(changeShowGroupShareDialog(true));
  };

  const handleRerunFormDialog = () => {
    dispatch(changeShowGroupShareDialog(true));
  };

  useEffect(() => {
    return () => {
      dispatch(resetCampaignType());
    };
  }, []);

  return (
    <div className="w-11 m-auto">
      {!userSub.isUserSub ? (
        <TrailDashboard/>
      ) : (
        <>
          {renderTable()}
          {selectedCampaignId !== "all" && <CustomerAnalyticsDataView />}
        </>
      )}
    </div>
  );
};

export default ScheduleCampaigns;
