import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "../style.module.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axiosInstance from "../../../api/axios.instance";
import { getDate } from "../../../utils/datemaker";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, ArcElement, Tooltip, Legend  } from "chart.js";
import { Bar } from "react-chartjs-2";
import { Dropdown } from "primereact/dropdown";
import DebounceSearch from "../../../components/common/DebounceSearch";
import { Badge } from "primereact/badge";
import { useDispatch, useSelector } from "react-redux";
import { getAgentTickets, getCountAgentTop } from "../../../reducers/analyticSlice";
import CustomSkeleton from "../../../components/CustomSkeleton";

ChartJS.register(ArcElement, CategoryScale,LinearScale,BarElement,Title,Tooltip, Legend);

function AgentsSections() {
    const phoneBodyTemplate = (rowdata) => {
        return `+${rowdata.phone}`;
      };
      const { agenttop,analyticalDetails,agentsTickets,skeletonloading, loading,dateRange } = useSelector((state) => state.analytic);
      const [agentDetail,setAgentDetails]=useState({agent:0,active:0,inactive:0})
      const [filter,setFilter]=useState("")
      const [inputValue, setInputValue] = useState("");
      const dispatch=useDispatch();
      useEffect(()=>{
          dispatch(getCountAgentTop({dateRange}))
          dispatch(getAgentTickets({pageNo:0,limit:10,dateRange,filter,globalFilterValue:inputValue}))
      },[dateRange,filter,inputValue])
      
      const labels = ['Agents','Active','Inactive'];
     
      const options = {
        elements: {
          bar: {
            borderWidth:0,
          },
        },
        responsive: true,
        plugins: {
          legend: false
        },
        scales: {
          y: {
            stacked: true,
            ticks: {
              crossAlign: "far",
              stepSize: 1,
              suggestedMin: 1, 
            },
          },
          x: {
            stacked: true,
              barPercentage: 0.4,
              grid: {
              display: false
            }
          },
        },
      };
      const data = {
        labels,
        title:{display: true,text:"testTitle", position: "left" },
        showDatapoints: true,
        datasets: [
          {
            data: [agentDetail.agent,agentDetail.active,agentDetail.inactive],
            datalabels: {
              align: 'end',
              anchor: 'end',
            },
            backgroundColor: [
              '#005068',
              '#BBA0CA',
              '#92D5E6',
            ],
            borderColor: [
              '#005068',
              '#BBA0CA',
              '#92D5E6',
            ],
            borderWidth: 1,
            barPercentage: 0.2,
          },
          // {
          //   data: [agentDetail.agent,agentDetail.active,agentDetail.inactive],
          //   datalabels: {
          //     align: 'end',
          //     anchor: 'end',
          //   },
          //   backgroundColor: [
          //     '#BBA0CA',
          //   ],
          //   borderColor: [
          //     'transparent',
          //   ],
          //   borderWidth: 1,
          //   barPercentage: 0.2,
          // },
          // {
          //   data: [agentDetail.agent,agentDetail.active,agentDetail.inactive],
          //   datalabels: {
          //     align: 'end',
          //     anchor: 'end',
          //   },
          //   backgroundColor: [
          //     '#A1EF8B',
          //   ],
          //   borderColor: ['transparent'],
          //   borderWidth: 1,
          //   barPercentage: 0.2,
          // },
        ],
      };
      const statusOptions = [
        { key:'All Agents',value:""},
        { key: "Active", value: "1" },
        { key: "In Active", value: "0" },
      ];
      const shortusername=(a,y)=>{
        return a?a.split(" ")
        .map((x) => {
          return x[0];
        })
        .join("")
        .toUpperCase():y.split(" ")
        .map((x) => {
          return x[0];
        })
        .join("")
        .toUpperCase();
      }
      const agentname=(rowData)=>{
        const colorPairs = [
          { background: "#3498db", color: "#ffffff" }, // Blue background with white font
          { background: "#2ecc71", color: "#ffffff" }, // Green background with white font
          { background: "#e74c3c", color: "#ffffff" }, // Red background with white font
          { background: "#f39c12", color: "#ffffff" }, // Orange background with white font
          { background: "#9b59b6", color: "#ffffff" }, // Purple background with white font
          { background: "#1abc9c", color: "#ffffff" }, // Teal background with white font
          { background: "#34495e", color: "#ffffff" }, // Dark background with white font
          { background: "#ecf0f1", color: "#2c3e50" }, // Light background with dark font
          { background: "#95a5a6", color: "#ffffff" }, // Gray background with white font
          { background: "#d35400", color: "#ffffff" }, // Pumpkin background with white font
        ];
        
        const randomIndex = Math.floor(Math.random() * colorPairs.length);
        const selectedColorPair = colorPairs[randomIndex];
        return <div className={`flex flex-row gap-2 cursor-pointer  align-items-center`}>
                  <div className="relative">
                    <Badge
                      value={shortusername(rowData?.name,rowData?.userName)}
                      className={`text-xs  border-circle`}
                      style={{ background: `${selectedColorPair.background}`,color:`${selectedColorPair.color}`}}
                      size={"large"}
                    ></Badge>
                  </div>

                <div className={`flex flex-column `}>
                  <div
                    className={`text-sm capitalize bold `}
                    style={{ color: `#000` }}>
                    {rowData?.name?rowData?.name:rowData.userName}
                  </div>
                </div>
              </div>
              }

    useEffect(()=>{
      let agent=analyticalDetails?.agent?.statistics?.allAgentsCount|0
      let active=analyticalDetails?.agent?.statistics?.activeAgentsCount|0;
      let inactive=analyticalDetails?.agent?.statistics?.inactiveAgentsCount|0
      setAgentDetails({agent,active,inactive})
    },[analyticalDetails,dateRange])

    return (<div className={`w-full cp-10 flex flex-column justify-content-between align-items-start  w-12 ${styles["record-wrap-stat"]}`}>
      <div className={` ${styles["record-wrap-stat"]} flex  w-full`}>
        <div className="w-full gap-3">
            <div className="w-full flex flex-column md:flex-row gap-2 mb-2">
                <div className={`w-full md:w-8 flex flex-column ${styles['dashboard-tablecontent']} border-round-sm p-2`}>
                <div className={`w-full flex flex-column bg-white border-round-sm p-2`}>
                    <div className="p-2 pl-3 flex flex-column justify-content-start font-semibold">
                      <div className="text-lg font-medium">
                        Overview
                      </div>
                      <div className="text-sm font-normal">
                        Agents 
                      </div>
                    </div>
                    <div className="w-12 p-2 px-3 m-auto" style={{maxHeight:"300px"}}>
                      {skeletonloading?.agent?<CustomSkeleton parentcss={"flex flex-row gap-4"} countline={1} height={"300px"} className="border-round w-full"/>:
                        <Bar options={options} data={data}  />}
                    </div>
                </div>
                </div>
                <div className={`w-full md:w-4 flex flex-column ${styles['dashboard-tablecontent']} border-round-sm p-2`} >
                <div className={`w-full flex flex-column bg-white border-round-sm p-2`} >
                    <div className="p-2 pl-3 flex flex-column justify-content-start ">
                      <div className="text-lg font-normal">
                        Top Ticket Solver
                      </div>
                    </div>
                    <div className="w-12 p-2 px-3 m-auto" >
                    {skeletonloading?.agenttop?<div className="flex flex-column gap-4 p-2">
                      <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={2} height={"1rem"} className="border-round w-4 "/>
                      <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={2} height={"1rem"} className="border-round w-4"/>
                      <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={2} height={"1rem"} className="border-round w-4"/>
                      <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={2} height={"1rem"} className="border-round w-4"/>
                       </div>:<DataTable
                      className={`${styles['dashboard-table']} w-full`} 
                      value={agenttop}
                      responsiveLayout="scroll"
                      stripedRows 
                      resizableColumns
                      columnResizeMode="expand"
                      emptyMessage="No data found."
                      
                    >
                      <Column
                        field="name"
                        header="Agent Name"
                        style={{minWidth:'20ch'}}
                        body={(rowData)=><div className="text-sm">{rowData?.name?rowData?.name:rowData?.userName}</div>}
                        />
                      <Column
                        field="solvedTicketsCount"
                        header="Solved Tickets"
                        className="text-sm"
                        style={{width:'20ch'}}
                      />
                    </DataTable>}
                    </div>
                </div>
                </div>
            </div>

          <div className={`w-full flex flex-column mt-2 ${styles['dashboard-tablecontent']} border-round-sm p-2`}>
                    <div className="w-full flex flex-column lg:flex-row justify-content-start p-2">
                      <div className="w-full flex flex-row lg:w-9 justify-content-between align-items-center p-2">
                        <div className="text-md font-semibold">Support Information</div>
                      </div>
                      <div className="w-full flex flex-row justify-content-between align-items-center lg:w-3 gap-1 p-2">
                        {/* <div className="w-5">
                          <Dropdown
                            value={filter}
                            onChange={(e) => {
                              setFilter(e.value);
                            }}
                            options={statusOptions}
                            optionLabel="key"
                            className="capitalize w-full"
                          />
                        </div> */}
                        <div className="w-12">
                        <DebounceSearch
                            placeholder={"Search Agent"}
                            inputValue={inputValue}
                            handleInputChange={(value) => setInputValue(value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                    {skeletonloading?.agentsTickets?<div className="flex flex-column gap-2 p-2">
                      <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={6} height={"1rem"} className="border-round w-2 "/>
                      <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={6} height={"1rem"} className="border-round w-2"/>
                      <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={6} height={"1rem"} className="border-round w-2"/>
                      <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={6} height={"1rem"} className="border-round w-2"/>
                       </div>:<DataTable
                      className={`${styles['dashboard-table']} w-full`} 
                      value={agentsTickets}
                      responsiveLayout="scroll"
                      resizableColumns
                      columnResizeMode="expand"
                      emptyMessage="No data found."
                    >
                      <Column field="name" header="Agent Name" 
                        body={agentname}
                      />
                      <Column field="assigned" header="Assigned" 
                        style={{width:'15ch'}}
                        body={(rowData)=><div className=" text-sm font-semibold p-2">{rowData?.assignedTicketsCount|0}</div>}
                      />
                      <Column field="ongoing" header="Ongoing" 
                        style={{width:'15ch'}}
                        body={(rowData)=><div className="text-sm font-semibold p-2">{rowData?.totalProgressTickets|0}</div>}
                      />
                      <Column field="followup" header="Follow Up" 
                        style={{width:'15ch'}}
                        body={(rowData)=><div className="text-sm font-semibold p-2">{rowData?.totalFfollowUpTickets|0}</div>}
                      />
                      <Column field="closed" header="Closed" 
                        style={{width:'15ch'}}
                        body={(rowData)=><div className="text-sm font-semibold p-2">{rowData?.totalClosedTickets|0}</div>}
                      />
                      <Column field="new" header="New" 
                        style={{width:'15ch'}}
                        body={(rowData)=><div className="text-sm font-semibold p-2 ">{rowData?.totalNewTickets|0}</div>}
                      />
                    </DataTable>}
                    </div>
            </div>
        </div>
      </div>
    </div>
    );
}

export default AgentsSections;