import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactFlow, {
  Panel,
  ReactFlowProvider,
  useReactFlow,
  Controls,
  MiniMap,
} from "reactflow";
import Dagre from "@dagrejs/dagre";
import "reactflow/dist/style.css";
import {
  addEdgeToStore,
  deleteEdge,
  setEdges,
  setNodes,
  layoutNodes,
  layoutEdges,
  changeRootStepId,
  updatePostbackText,
} from "../../../reducers/canvas/canvasSlice";
import TextNode from "../../../components/CustomNodes/TextNode";
import QuickReplyNode from "../../../components/CustomNodes/QuickReplyNode";
import HttpNode from "../../../components/CustomNodes/HttpNode/index";
import ListNode from "../../../components/CustomNodes/ListNode";
import { Toast } from "primereact/toast";
import { CustomButton } from "../../../components/CustomButton";
import NodeOptions from "../BotBuilder/NodeOptions";
import {
  getEdgeDataWithOptionId,
  isValidToConnectNewNode,
} from "../../../utils/canvasUtils";
import { useLayout } from "../../../context/LayoutContext";
import { TemplateNode } from "../../../components/CustomNodes/TemplateNode";
import { interactiveNodes } from "../BotBuilder/node-constant";

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes, edges, options) => {
  g.setGraph({ rankdir: options.direction });
  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  nodes.forEach((node) => g.setNode(node.id, node));
  Dagre.layout(g);
  return {
    nodes: nodes.map((node) => {
      const { x, y } = g.node(node.id);
      return { ...node, position: { x, y } };
    }),
    edges,
  };
};
const Canvas = () => {
  const [showOptions, setShowOptions] = useState(false);
  const dispatch = useDispatch();
  const { fitView } = useReactFlow();
  const { toast } = useLayout(null);

  const { nodes, edges, isEditable, configScreenOpen } = useSelector(
    (state) => state.canvas
  );

  const handleConnect = useCallback((params) => {
    const _edge = { ...params, id: `${params.source}-${params.target}` };

    const sourceNode = nodes.find((node) => node.id === params.source);
    if (interactiveNodes.includes(sourceNode.type)) {
      let validToConnect = isValidToConnectNewNode(sourceNode);

      if (!validToConnect) {
        let warningMessage = "";
        switch (sourceNode?.type) {
          case "list":
            warningMessage = "Please add a new 'Option' to connect more node.";
            break;
          case "quick_reply":
            warningMessage = "Please add a new 'Button' to connect more node.";
            break;
          case "template":
            warningMessage =
              "This Template node does't have 'Button' to connect more node. Please select other template.";
            break;
          default:
            warningMessage = "";
            break;
        }
        toast?.current.show({
          severity: "warn",
          detail: warningMessage,
          life: 3000,
        });
        return;
      }

      let { edgeData, optionId } = getEdgeDataWithOptionId(
        sourceNode.data.options,
        sourceNode.type
      );

      if (optionId) {
        _edge["label"] = edgeData.label;
        _edge["optionId"] = optionId;
      }

      dispatch(
        updatePostbackText({
          selectedNodeId: params.source,
          optionId,
          targetNodeId: params.target,
        })
      );
    }

    dispatch(addEdgeToStore(_edge));
  });

  const handleEdgeClick = (e, edgeData) => {
    dispatch(deleteEdge({ edgeId: edgeData.id }));
  };

  const nodeTypes = useMemo(
    () => ({
      text: TextNode,
      quick_reply: QuickReplyNode,
      list: ListNode,
      http: HttpNode,
      template: TemplateNode,
      pattern: TextNode,
    }),
    []
  );

  const onNodesChange = (changes) => {
    dispatch(setNodes(changes));
  };

  const onEdgesChange = (changes) => {
    dispatch(setEdges(changes));
  };

  const onLayout = useCallback(
    (direction) => {
      const deepCopiedNodes = JSON.parse(JSON.stringify(nodes));
      const deepCopiedEdges = JSON.parse(JSON.stringify(edges));
      const layouted = getLayoutedElements(deepCopiedNodes, deepCopiedEdges, {
        direction,
      });

      dispatch(layoutNodes(layouted.nodes));
      dispatch(layoutEdges(layouted.edges));

      window.requestAnimationFrame(() => {
        fitView();
      });
    },
    [nodes, edges]
  );

  return (
    <div className="relative" style={{ width: "100%", height: "100%" }}>
      <Toast ref={toast} />
      <div style={{ width: "100%", height: "78vh" }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeClick={handleEdgeClick}
          fitView
          nodeTypes={nodeTypes}
          onConnect={handleConnect}
          className="react-flow-subflows-example"
          deleteKeyCode={null}
        >
          <Panel position="top-left">
            <div className={`flex gap-2`}>
              <CustomButton
                icon={"pi pi-plus"}
                varient="outline"
                disabled={!isEditable || configScreenOpen}
                label={"Add New Node"}
                onClick={() => setShowOptions((prev) => !prev)}
              />
              <CustomButton
                varient="outline"
                label={"Beautify"}
                onClick={() => onLayout("LR")}
                disabled={nodes.length == 0}
              />
            </div>
            {showOptions && (
              <NodeOptions
                onHide={() => setShowOptions((prev) => !prev)}
                toast={toast}
              />
            )}
          </Panel>
          <Controls />
          <MiniMap />
        </ReactFlow>
      </div>
    </div>
  );
};

export default function () {
  return (
    <ReactFlowProvider>
      <Canvas />
    </ReactFlowProvider>
  );
}
