import { ProgressBar } from "primereact/progressbar";
import "./style.css";

const CustomProgressBar = ({ progress = 0 }) => {
  return (
    <div className="my-2">
      <ProgressBar
        value={progress}
        className={`w-full custom-progess-bar`}
        style={{ height: "10px", fontSize: "0.6rem" }}
      />
    </div>
  );
};
export default CustomProgressBar;
