import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { CustomButton } from "../../components/CustomButton";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import { Text } from "../../components/Text";
import { useEffect, useState } from "react";
import { RadioButton } from "primereact/radiobutton";
import TopUp from "./TopUp";
import MonthlySubs from "./MonthlySubs";
import { useDispatch, useSelector } from "react-redux";
import { ActivePlan, sendPayment } from "../../reducers/paymentSlice";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import { getPlans } from "../../api/payment.service";
import axiosInstance from "../../api/axios.instance";
import ActiveSubscription from "./ActiveSubscription";
const rechargeTypes = [
  {
    id: "sub002",
    label: "Subscription",
    value: "subscription",
  },
  {
    id: "sub001",
    label: "Topup",
    value: "topup",
  },
];
function Recharge() {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState("subscription");
  const { user, conversation, accesskey } = useSelector((state) => state.authenticate);
  const [planModal, setPlanModal] = useState(false)
  const { activePlan } = useSelector((state) => state.payment);
  const [subscriptionPlans, setSubscriptionPlans] = useState({})
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef();

  const breadcumbHome = { label: "Dashboard", url: "/dashboard" };
  const breadcumbList = [{ label: `Subscription `, url: "#" }];
  useEffect(() => {
    dispatch(ActivePlan(user?.companyId))
    getPlans("subscription").then((data) => {
      let allTopUps = data?.rows;
      const obj = {};
      if (allTopUps.length)
      {
        allTopUps.forEach((pack) => {
          obj[pack?.id] = pack;
        });
      }
      setSubscriptionPlans({ ...obj })
    })
      .catch((err) => {
        console.log("err", err);
      });
  }, [user?.companyId])
  useEffect(() => {
    function isSubscriptionExpired(subscription) {
      const createdAt = new Date(subscription.createdAt); 
      const validityMonths = subscription.validityMonths;
      const expirationDate = new Date(createdAt);
      expirationDate.setMonth(createdAt.getMonth() + validityMonths);
      const today = new Date();
      return today > expirationDate;
    }
    const planExpired = isSubscriptionExpired(activePlan)
    if (activePlan?.name === "trail"||planExpired){
      setSubscriptionType("subscription")
      navigate("/dashboard/subscription")
    }
  }, [activePlan])
  const handlePay = (plan) => {
    let data = {
      productType: subscriptionType.toUpperCase(),
      ...user,
      ...plan,
    };
    dispatch(sendPayment({ data }))
      .unwrap()
      .then((res) => { })
      .catch((err) => {
        toast.current.show({ severity: "error", detail: err.message });
        //  alert("err", err);
      });
  };

  const subscriptionForm = (sub) => {
    switch (sub)
    {
      case "topup":
        return (
          <TopUp topup={selectedPlan} handleChangeTopUp={setSelectedPlan} />
        );
      case "subscription":
        return <ActiveSubscription/>
      default:
        return (
          <TopUp topup={selectedPlan} handleChangeTopUp={setSelectedPlan} />
        );
    }
  };
  const setSubs = (type) => {
    setSubscriptionType(type)
  }

  return (
    <div className="w-full">
      <Toast ref={toast} />
      <CustomBreadcrumb home={breadcumbHome} itemslist={breadcumbList} />
      <div className="m-2 pl-3">
        <TableActionsContainer>
          <div className="flex justify-content-between align-items-center">
            <Text type={"heading"}>Subscription </Text>
            <CustomButton
              varient="outline"
              label={"Cancel"}
              onClick={() => navigate("/dashboard")}
            />
          </div>
        </TableActionsContainer>
      </div>

      <div className="flex justify-content-center">
        <div className="mt-4 w-11 lg:w-4">
          <div className="card ml-3 flex justify-content-start">
            {/* <div className="flex flex-wrap gap-3">
              {rechargeTypes?.map((element, i) => (
                <div className="flex align-items-center" key={i}>
                  <RadioButton
                    inputId={element.id}
                    name="recharge"
                    value={element.value}
                    onChange={(e) => setSubs(e.value)}
                    checked={subscriptionType === element.value}
                  />
                  <Text className={"sub-heading"}>
                    <label htmlFor={element.id} className="ml-2">
                      {element.label}
                    </label>
                  </Text>
                </div>
              ))}
            </div> */}
          </div>
          <div className="w-full my-4">
            {subscriptionForm(subscriptionType)}
          </div>

          {subscriptionType==="topup"&&<CustomButton
            varient={"filled"}
            label={<span>Pay &#x20B9;{selectedPlan?.amount}</span>}
            type="button"
            onClick={() => handlePay(selectedPlan)}
            style={{ width: "100%" }}
          />}
        </div>
      </div>
    </div>
  );
}

export default Recharge;
