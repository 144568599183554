import axios from "axios";
import axiosInstance from "./axios.instance";

const API_MEDIA_UPLOAD = (mediaFile) => {
  try {
    let formData = new FormData();
    //Adding files to the formdata
    formData.append("file", mediaFile);
    formData.append("file_type", "image/png");
    const resp = axios.post(
      "https://api.gupshup.io/wa/d30d0c13-f579-49a7-b6c1-78479d75be89/wa/media/",
      formData,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return resp.data;
  } catch (error) {
    return error;
  }
};

const API_START_CONVERSATION= async (data) => {
  try {
    const resp = await axiosInstance.post(`/user/startChat`, data);
    return resp.data;
  } catch (error) {
    throw error;
  }
  
};

export { API_MEDIA_UPLOAD,API_START_CONVERSATION };
