import styles from "./style.module.css";
import { ReactComponent as SkalebotLogo } from "../../assets/svgIcons/Skalebot.svg";
import { ReactComponent as HumburgerMenu } from "../../assets/navbarIcons/menu.svg";
import { SlideBar } from "../SlideBar";
import { changeSidebarOpenStatus } from "../../reducers/appSlice";
import { useSelector, useDispatch } from "react-redux";
import { SideIcon } from "../SideIcon";
import Notifications from "../../view/Notifications";
import { useUtility } from "../../context/UtilityContext";
import AccountChanger from "../AccountChanger";

function Header({ type, setType }) {
  const { slidebarOpen } = useSelector((state) => state.application);

  const { user, userSub } = useSelector((state) => state.authenticate);

  const dispatch = useDispatch();

  const { navigation } = useUtility();
  const handleSlide = () => {
    dispatch(changeSidebarOpenStatus());
  };
  return (
    <div className="relative">
      <div
        className={`${styles["header"]} w-12 px-3  md:px-4 py-2 fixed top-0 left-0 overflow-x-auto`}
      >
        <div className="flex justify-content-between align-items-center w-full h-full gap-2">
          <div className="min-w-min flex justify-content-start align-items-center">
            <div
              className="mr-2 pt-1 pr-2 cursor-pointer"
              onClick={() => {
                setType((x) => !x);
              }}
            >
              <SideIcon icon={type ? `openmenu` : `closemenu`} />
            </div>
            <SkalebotLogo
              className={`${styles["logo-css"]} cursor-pointer `}
              onClick={() => navigation("/dashboard")}
            />
            {userSub.userSubType == "pro" && (
              <span
                style={{
                  marginLeft: "5px",
                  marginTop: "3px",
                  fontSize: "0.7rem",
                }}
              >
                Pro
              </span>
            )}
          </div>

          <div className="flex align-items-center justify-content-end ">
            <div className="flex align-items-center justify-content-between gap-4">
              {user?.childCompanyIds?.length > 0 && <AccountChanger />}
              <Notifications />
              <HumburgerMenu
                className={`cursor-pointer min-w-max ${
                  !slidebarOpen ? "" : "opacity-0"
                }`}
                onClick={handleSlide}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-0 right-0">
        <SlideBar setType={setType} />
      </div>
    </div>
  );
}

export default Header;
