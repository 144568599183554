import React, { useState, useEffect, useRef } from "react";

import EnqChat from "../EnqChat";
import { useSelector } from "react-redux";
import TrailDashboard from "../TrailDashboard";

const ChatEnq = () => {
  const { userSub } = useSelector((state) => state.authenticate);
  return userSub.isUserSub ? (
    <div>
      <EnqChat />
    </div>
    ):(
    <TrailDashboard />
  )
}

export default ChatEnq