import React from "react";
import { Text } from "../../components/Text";
import { CustomButton } from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { ReactComponent as UnauthorizedIcon } from "../../svg/unauth.svg";
import { ReactComponent as SkalebotLogo } from "../../svg/bot.svg";

const Unauthorized = () => {
  const navigate = useNavigate();
  return (
    <div
      className="w-full flex flex-column justify-content-center "
      style={{ minHeight: "100vh" }}
    >
      <div className="w-full flex justify-content-center ">
        <div
          className="w-11 lg:w-9 p-6 flex flex-row justify-content-around align-items-center gap-6  flex-wrap-reverse lg:flex-nowrap"
          style={{ border: "1px solid #0000001A" }}
        >
          <div className="flex flex-column gap-1">
            <SkalebotLogo />
            <Text type={"heading"} cust="mt-2">
              You are not authorized to visit this page.
            </Text>
            <Text type={"sub-heading"} cust="mt-1">
              Please reach to Admin if this is a mistake
            </Text>
            <div className="mt-3">
              <CustomButton
                label={"Take me back to home"}
                onClick={() => navigate("/")}
                varient="filled"
              />
            </div>
          </div>
          <div>
            <UnauthorizedIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
