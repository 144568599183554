import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_GET_USAGE } from "../api/usage.services";

const initialState = {
  usageData: [],
  totalUsageCount: 0,
  loading: false,
  page: 0,
  limit: 30,
};

export const getUsages = createAsyncThunk(
  "usageTable/getUsageList",
  async (
    { page, limit, startDate, endDate, filterData, globalFilterValue, companyids },
    thunkAPI
  ) => {
    try {
      const enquiries = await API_GET_USAGE(
        page,
        limit,
        startDate,
        endDate,
        filterData,
        globalFilterValue,
        companyids
      );
      return enquiries;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const usageTableSlice = createSlice({
  name: "usagesTable",
  initialState,
  reducers: {
    changePage(state, action) {
      state.page = action.payload;
    },
    changelimit(state, action) {
      state.limit = action.payload;
    },
    resetUsagePagination(state) {
      state.page = initialState.page;
      state.limit = initialState.limit;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getUsages.fulfilled, (state, action) => {
      state.totalUsageCount = action.payload.count;
      state.usageData = action.payload.rows;
      state.loading = false;
    });
    builder.addCase(getUsages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsages.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { changePage, changelimit, resetUsagePagination } =
  usageTableSlice.actions;

export default usageTableSlice.reducer;
