import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TabMenu } from "primereact/tabmenu";
import Params from "./Params";
import Body from "./Body";
import Headers from "./Headers";
import { useDispatch, useSelector } from "react-redux";
import { updateNodePayload } from "../../../reducers/canvas/canvasSlice";
import { getDataFromId } from "../../../utils/nodeUtils";
import { CustomButton } from "../../CustomButton";
import {
  methods,
  httpMenu,
  checkValidUrl,
  checkIsValidPrams,
} from "./http-constant";
import StartNodeCheckMark from "../../../view/Bots/components/start-node-checkmark";

const HttpInput = ({ onCancel = () => {}, onSave = () => {} }) => {
  const { selectedNode, nodes } = useSelector((state) => state.canvas);

  const [selectedMethod, setSelectedMethods] = useState("GET");
  const [url, setUrl] = useState("");
  const [selectedHttpMenu, setSelectedHttpMenu] = useState(0);

  const dispatch = useDispatch();

  const renderHttpComponents = () => {
    if (selectedHttpMenu === 0) return <Params url={url} setUrl={setUrl} />;
    else if (selectedHttpMenu === 1) return <Headers />;
    else if (selectedHttpMenu === 2) return <Body />;
  };

  const handleMethodSelection = (e) => {
    setSelectedMethods(e.value);
    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { method: e.value },
      })
    );
  };

  const handleUrl = (e) => {
    setUrl(e.target.value);
    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { url: e.target.value },
      })
    );
  };

  const handleSave = () => {
    const { params, headers } = selectedNode.data;

    if (
      !checkValidUrl(url) ||
      !checkIsValidPrams(params) ||
      !checkIsValidPrams(headers)
    ) {
      return;
    } else {
      onSave();
    }
  };

  useEffect(() => {
    if (selectedNode) {
      const selectedNodeData = getDataFromId(nodes, selectedNode?.id);
      setUrl(selectedNodeData.url);
      setSelectedMethods(selectedNode.data?.method);
    }
  }, [selectedNode]);

  return (
    <div>
      <div className="card flex gap-3">
        <div className="flex flex-column gap-1 w-4 ">
          <label className="text-sm font-bold">Request Type</label>
          <Dropdown
            value={selectedMethod}
            onChange={(e) => {
              handleMethodSelection(e);
            }}
            options={methods}
            optionLabel="name"
            placeholder="Method"
            className="w-12"
            style={{ borderRadius: "4px" }}
          />
        </div>
        <div className="flex flex-column gap-1 w-7">
          <label className="text-sm font-bold">Request URL</label>

          <InputText
            className={`w-12 `}
            value={url}
            onChange={(e) => {
              handleUrl(e);
            }}
            placeholder="Enter url"
            style={{ borderRadius: "4px" }}
          />
        </div>
      </div>
      {!checkValidUrl(url) && (
        <small className="p-error my-2">Please enter a valid url.</small>
      )}
      <div className="mt-2 w-12">
        <TabMenu
          model={httpMenu}
          activeIndex={selectedHttpMenu}
          onTabChange={(e) => setSelectedHttpMenu(e.index)}
          className="custom-tab text-sm "
        />
        {renderHttpComponents()}
      </div>
      <StartNodeCheckMark />
      <div className=" mt-6 mb-3 flex justify-content-end gap-3 align-items-center">
        <CustomButton varient="outline" label={"Cancel"} onClick={onCancel} />
        <CustomButton varient="filled" label={"Save"} onClick={handleSave} />
      </div>
    </div>
  );
};

export default HttpInput;
