import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "../style.module.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axiosInstance from "../../../api/axios.instance";
import { getDate } from "../../../utils/datemaker";
import { useDispatch, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
} from "chart.js";
import {
  changeSelectedOrder,
  resetMode,
  resetSelectedOrder,
  resetSelectedOrdersList,
  updateMode,
  updateSelectedOrdersList
} from "../../../reducers/orderTableSlice";
import { CustomButton } from "../../../components/CustomButton";
import { Bar, Doughnut } from "react-chartjs-2";
import { Dropdown } from "primereact/dropdown";
import { emptyContent } from "../../../utils/tableUtils";
import CustomSkeleton from "../../../components/CustomSkeleton";
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function OrdersSection() {
  const [recentOrders, setRecentOrders] = useState([]);
  const [recentOrderLoading, setRecentOrdersLoading] = useState(false);
  const { userSub, loading } = useSelector((state) => state.authenticate);
  const [clickedOrder, setClickedOrder] = useState();
  const [filter, setFilter] = useState("all");
  const [ordersCounts, setOrdersCounts] = useState(0);

  const navigate = useNavigate();
  const {
    loading: analyticloading,
    dateRange,
    orderstat,
    skeletonloading
  } = useSelector((state) => state.analytic);
  const getRecentOrders = () => {
    setRecentOrdersLoading(true);
    var { fromDate: startDate, toDate: endDate } = dateRange;
    if (!endDate) endDate = startDate;
    var range =
      startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : "";
    axiosInstance
      .get(
        `order?page=0&limit=10${
          filter !== "all" ? `&status=${filter}` : ""
        }${range}`
      )
      .then((resp) => {
        setRecentOrdersLoading(false);
        let { count, rows } = resp.data;
        if (rows.length < 10) {
          // setOrdersCounts(count);
          setOrdersCounts(rows?.length);
          rows = [...rows, { name: "me" }];
        } else {
          setOrdersCounts(10);
        }
        setRecentOrders(resp.data.rows);
      })
      .catch((err) => {
        setRecentOrdersLoading(false);
        console.error(err);
      });
  };

  const phoneBodyTemplate = (rowdata) => {
    return `+${rowdata.phone}`;
  };

  const dispatch = useDispatch();

  useEffect(() => {
    getRecentOrders();
  }, [filter, dateRange]);

  useEffect(() => {
    if (clickedOrder && clickedOrder.id)
      navigate(`/orders/${clickedOrder?.id}`);
  }, [clickedOrder]);

  const handleOrderSelect = (order) => {
    if (order.id !== "all" && !order["orderLabel"]) {
      let orderWithLabel = { ...order, orderLabel: `Order Id #${order.id}` };
      dispatch(changeSelectedOrder(orderWithLabel));
    } else {
      dispatch(changeSelectedOrder(order));
    }
    navigate(`/orders/${order.id}`);
  };
  const labels = ["Bangle", "Ear Rings", "Necklace", "Rings", "Chains"];

  const data = {
    labels,
    title: { display: true, text: "testTitle", position: "left" },
    datasets: [
      {
        data: [142, 94, 23, 25, 50],
        backgroundColor: ["#61f2c2", "#FFC832", "#F0C6B7", "#018E42"],
        borderColor: ["#61f2c2", "#FFC832", "#F0C6B7", "#018E42"],
        borderWidth: 1
      }
    ]
  };
  const analytics = [
    orderstat?.newOrderCount,
    orderstat?.progressOrderCount,
    orderstat?.deliveredOrderCount,
    orderstat?.cancelledOrderCount
  ];

  let donutdata = {
    labels: false,
    datasets: [
      {
        data: [...analytics],
        // data: [data],
        backgroundColor: ["#C4E0A5", "#FFC832", "#018E42", "#F0C6B7"],
        borderColor: ["#C4E0A5", "#FFC832", "#018E42", "#F0C6B7"],
        borderWidth: 1
      }
    ]
  };
  const isAnalyticsDataPresent =
    analytics && analytics.some((count) => count > 0);
  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 0
      }
    },
    responsive: true,
    plugins: {
      legend: false
    },
    scales: {
      y: {
        ticks: {
          crossAlign: "far"
        },
        grid: {
          display: false
        }
      }
    }
  };

  const statusOptions = [
    { key: "All ", value: "all" },
    { key: "New", value: "New" },
    { key: "In Progress", value: "In Progress" },
    { key: "Delivered", value: "Delivered" },
    { key: "Cancelled", value: "Cancelled" }
  ];
  return (
    <div
      className={`w-full cp-10 flex flex-column justify-content-between align-items-start  w-12 ${styles["record-wrap-stat"]}`}
    >
      <div className={`  ${styles["record-wrap-stat"]} flex  w-full`}>
        <div className="w-full flex flex-column lg:flex-row gap-2 ">
          <div
            className={`w-full flex flex-column w-full lg:w-8 ${styles["dashboard-tablecontent"]} border-round-sm p-2`}
            style={{ height: "700px" }}
          >
            <div className="w-full flex flex-row justify-content-between align-items-center lg:align-items-start   p-2">
              <div className="p-2 flex flex-column  gap-1">
                <div className="text-lg font-medium">Orders</div>
                <div className="text-sm font-normal">
                  Showing {ordersCounts} Orders
                </div>
              </div>
              <div className="hidden lg:block">
                <Dropdown
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.value);
                  }}
                  options={statusOptions}
                  optionLabel="key"
                  className="capitalize "
                />
              </div>
            </div>
            <div>
              {recentOrderLoading ? (
                <div className="flex flex-column gap-4">
                  <CustomSkeleton
                    parentcss={"flex flex-row gap-4"}
                    countline={4}
                    height={"1rem"}
                    className="border-round w-3"
                  />
                  <CustomSkeleton
                    parentcss={"flex flex-row gap-4"}
                    countline={4}
                    height={"1rem"}
                    className="border-round w-3"
                  />
                  <CustomSkeleton
                    parentcss={"flex flex-row gap-4"}
                    countline={4}
                    height={"1rem"}
                    className="border-round w-3"
                  />
                  <CustomSkeleton
                    parentcss={"flex flex-row gap-4"}
                    countline={4}
                    height={"1rem"}
                    className="border-round w-3"
                  />
                </div>
              ) : (
                <DataTable
                  className={`${styles["dashboard-table"]} w-full`}
                  value={recentOrders}
                  stripedRows
                  responsiveLayout="scroll"
                  resizableColumns
                  columnResizeMode="expand"
                  emptyMessage="No data found."
                  selection={clickedOrder}
                  selectionMode="single"
                  onSelectionChange={(e) => setClickedOrder(e.value)}
                >
                  <Column
                    field="orderNumber"
                    header="Order No."
                    className="text-sm"
                    body={(rowData) => {
                      return <div>{rowData.orderNumber}</div>;
                    }}
                  />
                  <Column
                    field="name"
                    header={"Customer Name"}
                    bodyStyle={{ textTransform: "capitalize" }}
                    className="text-sm"
                    body={(rowData) => emptyContent(rowData?.name)}
                  />
                  <Column
                    header="Order Date"
                    body={(rowData) =>
                      rowData.createdAt && getDate(rowData.createdAt)
                    }
                    className="text-sm"
                  />
                  <Column
                    field="status"
                    header={"Order Status"}
                    className="text-sm"
                  />
                </DataTable>
              )}
            </div>
          </div>
          <div className="w-full lg:w-4">
            <div className="w-full flex flex-column gap-2">
              <div
                className={`w-full flex flex-column bg-white border-round-sm p-2`}
              >
                <div className="w-12 flex pl-2  py-3 m-auto text-lg font-normal">
                  Order Overview
                </div>
                {/* <div
                  className="w-8 lg:w-6 py-2 flex justify-content-center m-auto align-items-center"
                  style={{ maxHeight: "367px" }}
                >
                  <Doughnut
                    data={donutdata}
                    
                    options={{
                      plugins: {
                        tooltip: {
                          enabled: false
                        }
                      }
                    }}
                  />
                </div> */}
                {skeletonloading?.order ? (
                  <div>
                    <CustomSkeleton
                      parentcss={"flex flex-row gap-4"}
                      countline={1}
                      height={"20rem"}
                      className="border-round w-full"
                    />
                  </div>
                ) : (
                  <div>
                    {isAnalyticsDataPresent ? (
                      <div
                        className="w-8 lg:w-6 py-2 flex justify-content-center m-auto align-items-center"
                        style={{ maxHeight: "367px" }}
                      >
                        <Doughnut
                          data={donutdata}
                          options={{
                            plugins: {
                              tooltip: {
                                enabled: false
                              }
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        className="w-8 lg:w-6 py-2 flex justify-content-center m-auto align-items-center"
                        style={{ maxHeight: "367px" }}
                      >
                        <Doughnut
                          data={{
                            labels: false,
                            datasets: [
                              {
                                data: [12],
                                backgroundColor: ["#D3D3D3"],
                                borderColor: ["#D3D3D3"],
                                borderWidth: 1
                              }
                            ]
                          }}
                          options={{
                            plugins: {
                              tooltip: {
                                enabled: false
                              }
                            }
                          }}
                        />
                      </div>
                    )}
                    {isAnalyticsDataPresent ? (
                      <div className="w-12  lg:w-8 align-items-center xl:w-6 m-auto flex flex-column pl-0 py-2 ">
                        <div className="flex flex-column gap-2">
                          <div className="flex flex-row font-normal justify-content-between gap-4">
                            <div className="flex flex-row">
                              <div
                                className={`${styles["orderDot"]}`}
                                style={{ background: "#C4E0A5" }}
                              ></div>
                              &nbsp;&nbsp;New&nbsp;Orders&nbsp;
                            </div>
                            ({analytics[0]})
                          </div>
                          <div className="flex flex-row font-normal justify-content-between gap-4">
                            <div className="flex flex-row">
                              <div
                                className={`${styles["orderDot"]}`}
                                style={{ background: "#FFC832" }}
                              ></div>
                              &nbsp;&nbsp;In&nbsp;Progress&nbsp;
                            </div>
                            ({analytics[1]})
                          </div>
                          <div className="flex flex-row font-normal justify-content-between gap-4">
                            <div className="flex flex-row">
                              <div
                                className={`${styles["orderDot"]}`}
                                style={{ background: "#018E42" }}
                              ></div>
                              &nbsp;&nbsp;Delivered&nbsp;
                            </div>
                            ({analytics[2]})
                          </div>
                          <div className="flex flex-row font-normal justify-content-between gap-4">
                            <div className="flex flex-row">
                              <div
                                className={`${styles["orderDot"]}`}
                                style={{ background: "#F0C6B7" }}
                              ></div>
                              &nbsp;&nbsp;Cancel&nbsp;
                            </div>
                            ({analytics[3]})
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="w-12  lg:w-8 align-items-center xl:w-6 m-auto flex flex-column pl-0 py-2 ">
                        <div className="flex flex-column gap-2">
                          <div className="flex flex-row font-normal justify-content-between gap-4">
                            <div className="flex flex-row">
                              <div
                                className={`${styles["orderDot"]}`}
                                style={{ background: "#D3D3D3" }}
                              ></div>
                              &nbsp;&nbsp;No&nbsp;Orders&nbsp;
                            </div>
                            {/* ({analytics[0]}) */}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* <div className={`w-full flex flex-column bg-white border-round-sm p-2`}>
                <div className="p-2 pl-3 flex flex-column justify-content-start ">
                  <div className="flex text-lg font-semibold">
                    Top Selling Products
                  </div>
                  <div className=" flex text-md font-normal" style={{color:'#00000080'}}>
                    10+ Orders
                  </div>
                </div>
                <div className="w-12 p-2 px-3 m-auto">
                  <Bar options={options} data={data} />
                </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrdersSection;
