import styles from "./style.module.css";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import { CustomButton } from "../../components/CustomButton";
import { useState, useEffect, useRef } from "react";
import { ReactComponent as SkalebotLogo } from "../../assets/svgIcons/Skalebot.svg";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Text } from "../../components/Text";
import VersionTag from "../../config/VersionTag";
import Loader from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { changeForgetToast } from "../../reducers/companySlice";
import { forgetSendOTP, resetPass } from "../../reducers/authSlice";
import Header from "../Login/Header";

export const ResetPass = () => {
  const toast = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [otpId, setOtpId] = useState();
  const [otpSent, setOtpSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  const { loading } = useSelector((state) => state.authenticate);

  const modalLoad = () => {
    return <Loader visible={loading} />;
  };

  const defaultValues = {
    userName: "",
    otp: "",
    password: "",
    confirmPassword: ""
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm({ defaultValues });

  const user = watch("userName");

  //function form get error message
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const resendOTP = () => {
    const _data = { userName: user };
    dispatch(forgetSendOTP(_data))
      .unwrap()
      .then((response) => {
        setOtpSent(true);
        setOtpId(response.otpId);
        toast.current.show({ severity: "success", detail: response.message });
      })
      .catch((error) => {
        toast.current.show({ severity: "error", detail: error.message });
      });

    setMinutes(1);
    setSeconds(30);
  };

  useEffect(() => {
    if (otpSent) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, otpSent]);

  const onSubmit = (data) => {
    if (otpSent) {
      if (data.password !== data.confirmPassword) {
        toast.current.show({
          severity: "error",
          detail: "Password do not match"
        });
        return;
      }
      data.otpId = otpId;
      delete data.confimPassword;

      dispatch(resetPass(data))
        .unwrap()
        .then(() => {
          dispatch(changeForgetToast(true));
          reset();
          navigate("/login");
        })
        .catch((error) => {
          toast.current.show({ severity: "error", detail: error.message });
        });
    } else if (!otpSent) {
      const _data = { userName: data.userName };

      dispatch(forgetSendOTP(_data))
        .unwrap()
        .then((response) => {
          setOtpSent(true);
          setOtpId(response.otpId);
          toast.current.show({ severity: "success", detail: response.message });
        })
        .catch((error) => {
          console.log("otpsend err", error);
          toast.current.show({ severity: "error", detail: error.message });
        });
    }
  };

  return (
    <div className={` flex flex-column w-full ${styles["login-component"]}`}>
      <Toast ref={toast} />
      {modalLoad()}
      <Header />
      <div
        className={`flex-grow-1 w-full justify-content-center ${styles["wrapper-login"]}`}
      >
        <div
          className={`w-full h-full flex flex-column justify-content-center`}
        >
          <div className={` ${styles["login-module"]}`}>
            {/* <div className="w-5 py-2">
              <SkalebotLogo />
            </div> */}
            <div className={`${styles["login-form"]}`}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={`p-fluid`}
              >
                {!otpSent && (
                  <>
                    <p className="text-base font-semibold m-0 line-height-2 py-1 ">
                      Forgot Password?
                    </p>
                    <p className="text-xs font-normal opacity-50 m-0 line-height-2 py-1">
                      Enter your username to get password reset{" "}
                    </p>

                    <div className={`field  pt-3`} >
                      <label htmlFor="userName">Username</label>
                      <Controller
                        name="userName"
                        control={control}
                        rules={{
                          required: true && "Please enter username.",
                          validate: (value) =>
                            value.trim().length > 0 || "username is required"
                        }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="Enter username"
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("userName")}
                    </div>
                  </>
                )}

                {otpSent && (
                  <>
                    <div className="field">
                      <label htmlFor="otp">OTP</label>
                      <Controller
                        name="otp"
                        control={control}
                        rules={{
                          required: true && "Please enter otp.",
                          validate: (value) =>
                            value.trim().length > 0 || "OTP is required"
                        }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="Enter OTP"
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("otp")}
                    </div>
                    <div className="field">
                      <label htmlFor="password">Password</label>
                      <Controller
                        name="password"
                        control={control}
                        rules={{ required: true && "Please enter password." }}
                        render={({ field, fieldState }) => (
                          <span className="p-input-icon-right">
                            <i
                              className={`pi ${
                                showPassword ? "pi-eye" : "pi-eye-slash"
                              }`}
                              onClick={() => setShowPassword((prev) => !prev)}
                            />
                            <InputText
                              type={`${showPassword ? "text" : "password"}`}
                              id={field.name}
                              placeholder="Enter password"
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid
                              })}
                            />
                          </span>
                        )}
                      />
                      {getFormErrorMessage("password")}
                    </div>
                    <div className="field">
                      <label htmlFor="password">Confirm Password</label>
                      <Controller
                        name="confirmPassword"
                        control={control}
                        rules={{ required: true && "Re-enter password." }}
                        render={({ field, fieldState }) => (
                          <span className="p-input-icon-right">
                            <i
                              className={`pi ${
                                showPassword2 ? "pi-eye" : "pi-eye-slash"
                              }`}
                              onClick={() => setShowPassword2((prev) => !prev)}
                            />
                            <InputText
                              type={`${showPassword2 ? "text" : "password"}`}
                              id={field.name}
                              placeholder="Confirm password"
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid
                              })}
                            />
                          </span>
                        )}
                      />
                      {getFormErrorMessage("confirmPassword")}
                    </div>
                    <div className="flex justify-content-between">
                      {seconds > 0 || minutes > 0 ? (
                        <Text type={"sub-heading"}>
                          Time Remaining:{" "}
                          {minutes < 10 ? `0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </Text>
                      ) : (
                        <Text type={"sub-heading"}>Didn't receive otp?</Text>
                      )}

                      <button
                        className={`${styles.resendButton} ${
                          seconds > 0 || minutes > 0 ? styles.disabled : ""
                        }`}
                        disabled={seconds > 0 || minutes > 0}
                        onClick={resendOTP}
                      >
                        Resend OTP
                      </button>
                    </div>
                  </>
                )}

                <div className="w-7 m-auto mt-5">
                  <CustomButton
                    type="submit"
                    label={otpSent ? "Save" : "Send OTP"}
                    varient="surface-900  font-medium text-sm border-0 p-3"
                  />
                </div>
              </form>
              <div className={` ${styles["page-swap"]}`}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/login")}
                >
                  <Text type={"sub-heading"} color="#1C738E"> 
                    Back to Login
                  </Text>
                </span>
              </div>
              <div className="pt-3 text-center">
                <VersionTag />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
