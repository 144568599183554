import { Text } from "../Text";
function Footer() {
  return (
    <div className="py-3 px-5" style={{ borderTop: "1px solid #E1E1EA" }}>
      <div className="flex flex column md:flex-row lg:flex-row xl:flex-row align-items-center  justify-content-center md:justify-content-between lg:justify-content-between xl:justify-content-between gap-1 flex-wrap">
        <Text type={"small-text"} color={"#5E5E72"}>
          &copy; 2023 Deskala, All Rights Reserved.
        </Text>
        <Text type={"small-text"}>
          <a
            href="/terms&conditions"
            rel="noreferrer"
            style={{ color: "#5E5E72", textDecoration: "none" }}
          >
            Terms & Conditions | Skalebot.com
          </a>
        </Text>
      </div>
    </div>
  );
}

export default Footer;
