import styles from "./templateStyle.module.css";
import { Text } from "../Text";
import { ReactComponent as PreviewHeader } from "../../assets/new_preview_header.svg";
import { ReactComponent as PreviewFooter } from "../../assets/new_preview_footer.svg";
const TemplatePreview = ({
  header = "",
  content = "",
  file = null,
  videoPreview = null,
  documentPreview = null,
  imagePreview = null,
  footer = "",
  buttons = [],
}) => {
  return (
    <div className={` flex flex-column ${styles["mobile-preview"]}`}>
      <div className={`flex ${styles["logo-container"]}`}>
        <PreviewHeader className="w-full h-full" />
      </div>
      <div className={`w-full flex-grow-1 ${styles["body-container"]}`}>
        <div className={`mx-2 mt-2 p-2 ${styles["message-card"]}`}>
          <div>
            {file !== null && (
              <div className="img-preview">
                {imagePreview != null && (
                  <img
                    src={imagePreview}
                    alt=""
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      maxHeight: "180px",
                    }}
                  />
                )}
                {videoPreview != null && (
                  <video
                    controls
                    src={videoPreview}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      maxHeight: "180px",
                    }}
                  ></video>
                )}
                {documentPreview != null && (
                  <iframe
                    frameBorder={0}
                    src={documentPreview}
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      maxHeight: "180px",
                    }}
                  />
                )}
              </div>
            )}
            {header && <Text type={"heading"}>{header}</Text>}
            {content && (
              <Text type="sub-heading" style={{ whiteSpace: "pre-line" }}>
                {content}
              </Text>
            )}
            {footer && (
              <Text
                type="sub-heading"
                color={"grey"}
                style={{ fontSize: "0.8rem" }}
              >
                {footer}
              </Text>
            )}

            {buttons.length == 1 && buttons[0].type !== "QUICK_REPLY" && (
              <div className="w-full mt-2 flex-grow-1 flex justify-content-center align-items-center gap-2 border-top-1 py-2">
                {buttons[0].type == "PHONE_NUMBER" && (
                  <i className={`pi pi-phone ${styles["preview-icons"]}`} />
                )}
                {buttons[0].type == "URL" && (
                  <i
                    className={`pi pi-external-link  ${styles["preview-icons"]}`}
                  />
                )}{" "}
                <Text type={"sub-heading"} color={"#068FFF"}>
                  {buttons[0].text}
                </Text>
              </div>
            )}
          </div>
        </div>
        {buttons.length == 1 && buttons[0].type !== "QUICK_REPLY" ? (
          <></>
        ) : (
          <div className="flex gap-2 flex-wrap m-2">
            {buttons.map((item, i) => (
              <div
                key={i}
                className="w-5 p-2 flex-grow-1 flex justify-content-center align-items-center gap-2"
                style={{ backgroundColor: "#f5f6f7", borderRadius: "10px" }}
              >
                <Text type={"sub-heading"} color={"#068FFF"}>
                  {item.text}
                </Text>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={`${styles["logo-container"]}`}>
        <PreviewFooter width={"100%"} height={"100%"} />
      </div>
    </div>
  );
};

export default TemplatePreview;
