import Loader from "../../../components/Loader";
import CSVupload from "../../../components/CSVupload";
import { useState } from "react";
import { groupDefaultColumns } from "../../../utils/csvUtils";
import { CustomButton } from "../../../components/CustomButton";
import { ReactComponent as ExcelIcon } from "../../../assets/svgIcons/excelIcon.svg";
import { API_BULK_CUSTOMER_VALIDATE } from "../../../api/customer.services";
import { useDispatch } from "react-redux";
import { bulkAddCustomers } from "../../../reducers/customerTableSlice";
import { useLayout } from "../../../context/LayoutContext";
import { useNavigate } from "react-router";
import { removePlusFormPhoneNumber } from "../../../utils/commonUtils";

function ImportGroupFromExcel({ toast = null, groupName = "" }) {
  const { toastMessage } = useLayout();
  const [newCustomerList, setNewCustomerList] = useState([]);
  const [visiable, setVisiable] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleValidateData = async () => {
    const configData = {
      customers: newCustomerList?.map((item) => {
        return { ...item, phone: removePlusFormPhoneNumber(item.phone) };
      }),
    };
    try {
      let { data } = await API_BULK_CUSTOMER_VALIDATE(configData);
      const { newCustomers, noChangedCustomers, changedCustomers } = data;

      return {
        new: newCustomers?.length,
        update: noChangedCustomers?.length + changedCustomers?.length,
      };
    } catch (error) {
      throw error;
    }
  };

  const handleSaveNewContacts = () => {
    const configData = {
      customers: newCustomerList.map((item) => {
        return {
          ...item,
          groupName: groupName,
          phone: removePlusFormPhoneNumber(item.phone),
        };
      }),
    };
    dispatch(bulkAddCustomers(configData))
      .unwrap()
      .then((res) => {
        handleHide();
        toastMessage({
          severity: "success",
          detail: `${newCustomerList.length} Customers got added in group success.`,
        });
        setNewCustomerList([]);
        navigate("/customers/groups");
      })
      .catch((err) => {
        toastMessage({ severity: "error", detail: err?.message });
        //  toast?.current.show({ severity: "error", detail: err?.message });
      });
  };

  const handleHide = () => {
    setVisiable((prev) => !prev);
  };
  return (
    <>
      <CustomButton
        varient="icon-button"
        onClick={handleHide}
        icon={<ExcelIcon />}
      />

      {visiable && (
        <CSVupload
          showDialog={visiable}
          hideDialog={handleHide}
          headerTitle="Import customers from excel"
          targetColumns={groupDefaultColumns}
          validateData={handleValidateData}
          dataToAdd={newCustomerList}
          handleDataChange={(value) => setNewCustomerList(value)}
          handleSaveData={handleSaveNewContacts}
          sampleFileUrl="/assets/sample_contact_group.xlsx"
          resetSelectedData={() => setNewCustomerList([])}
        />
      )}
    </>
  );
}

export default ImportGroupFromExcel;
