import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_ADD_CAMPAIGN,
  API_DEL_CAMPAIGN,
  API_GET_CAMPAIGNS,
  API_GET_CAMPAIGN_BY_ID,
  API_GET_IMAGE_CONFIG,
  API_GET_TEMPLATES,
  API_PUT_CAMPAIGN,
} from "../api/campaign.service";
import {
  API_GET_CAMPAIGN_CUSTOMERS,
  API_GET_CAMPAIGN_ANALYTICS,
} from "../api/dashboard.service";
import { removeDeleteData } from "../utils/tableUtils";

const initialState = {
  page: 0,
  limit: 30,
  campaignLoading: false,
  campaignType: "product",
  campaignTab: "campaign",
  campaignData: [],
  broadcastData: [],
  campaignCount: 0,
  broadcastCount: 0,
  campaignTemplates: {},
  selectedCampaignId: "",
  selectedCampaign: {},
  campCustomerPage: 0,
  campaignCustomers: [],
  stats: {
    All: 0,
    read: 0,
    sent: 0,
    replied: 0,
    ["opt-out"]: 0,
    delivered: 0,
    failed: 0,
  },
  rerunCamp: false,
  mode: null,
  selectedCampaign: null,
  selectedCampaignId: "all",
  rselectedCampaign: { id: "all", campaignName: "All Campaigns" },
  rselectedCampaignId: "all",
  totalCampaignCustomersCount: 0,
};

export const addCampaign = createAsyncThunk(
  "campaign/addCampaign",
  async ({ configData, image, schedule = false }, thunkAPI) => {
    try {
      if (image) {
        const { src, url } = await API_GET_IMAGE_CONFIG({
          imageType: "broadcast",
        });

        await axios.put(src, image, {
          headers: { "Content-Type": "image/png" },
        });
        configData["variables"] = {
          var1: url,
          var2: configData.message,
        };
      } else {
        configData["variables"] = {
          var1: configData.message,
        };
      }
      let data = await API_ADD_CAMPAIGN(configData, schedule);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const putCampaign = createAsyncThunk(
  "campaign/putCampaign",
  async ({ id, configData, image, schedule = false }, thunkAPI) => {
    try {
      if (image) {
        const { src, url } = await API_GET_IMAGE_CONFIG({
          imageType: "broadcast",
        });

        await axios.put(src, image, {
          headers: { "Content-Type": "image/png" },
        });
        configData["variables"] = {
          var1: url,
          var2: configData.message,
        };
      } else {
        configData["variables"] = {
          var1: configData.message,
        };
      }
      let data = await API_PUT_CAMPAIGN(id, configData, schedule);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCampaignTemplates = createAsyncThunk(
  "campaign/getTemplates",
  async (_, thunkAPI) => {
    try {
      const data = await API_GET_TEMPLATES();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCampaigns = createAsyncThunk(
  "campaign/getCampaigns",
  async (
    { page, limit, startDate, endDate, globalFilterValue, schedule = false },
    thunkAPI
  ) => {
    try {
      const data = await API_GET_CAMPAIGNS(
        page,
        limit,
        startDate,
        endDate,
        globalFilterValue,
        schedule
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCampaignCustomers = createAsyncThunk(
  "campaign/getCampaignCustomers",
  async ({ id, page, limit, status }, thunkAPI) => {
    try {
      const data = await API_GET_CAMPAIGN_CUSTOMERS(id, page, limit, status);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCampaignsAnalytics = createAsyncThunk(
  "campaign/getCampaignAnalytics",
  async ({ id }, thunkAPI) => {
    try {
      const data = await API_GET_CAMPAIGN_ANALYTICS(id);

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCampaignById = createAsyncThunk(
  "campaign/getCampaignById",
  async (id, thunkAPI) => {
    try {
      const data = await API_GET_CAMPAIGN_BY_ID(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const delCampaignById = createAsyncThunk(
  "campaign/delCampaignById",
  async (id, thunkAPI) => {
    try {
      const data = await API_DEL_CAMPAIGN(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    changeCampaignType(state, action) {
      state.campaignType = action.payload;
      state.page = 0;
      state.selectedCampaignId = "all";
      if (action.payload === "product") {
        state.selectedCampaign = { id: "all", campaignName: "All Campaigns" };
      } else {
        state.selectedCampaign = { id: "all", campaignName: "All Broadcasts" };
      }
    },
    changeMode(state, action) {
      state.mode = action.payload;
    },
    resetCampaignMode(state) {
      state.mode = null;
    },
    changeCampaignTab(state, action) {
      state.campaignTab = action.payload;
      state.page = 0;
      state.limit = 10;
    },
    resetCampaignType(state) {
      state.campaignType = "product";
      state.selectedCampaignId = "all";
      state.selectedCampaign = { id: "all", campaignName: "All Campaigns" };
    },
    changePage(state, action) {
      state.page = action.payload;
    },
    changecampCustomerPage(state, action) {
      state.campCustomerPage = action.payload;
    },
    setrerunCampaign(state, action) {
      state.rerunCamp = action.payload;
    },
    rchangeSelectedCampaign(state, action) {
      state.rselectedCampaignId = action.payload.id;
      state.rselectedCampaign = action.payload;
    },
    changelimit(state, action) {
      state.limit = action.payload;
    },
    changeSelectedCampaignId(state, action) {
      if (action.payload !== "all") {
        const campDetails = state.campaigns.filter(
          (camp) => camp.id === action.payload
        );
        state.selectedCampaign = campDetails[0];
      } else {
        state.selectedCampaign = { id: "all", campaignName: "All Campaigns" };
      }
      state.selectedCampaignId = action.payload;
    },
    changeSelectedCampaign(state, action) {
      state.selectedCampaignId = action.payload.id;
      state.selectedCampaign = action.payload;
    },
    clearCampaignCustomers(state) {
      state.campaignCustomers = [];
    },
    resetSelectedCampaign(state) {
      state.selectedCampaignId = "";
      state.selectedCampaign = {};
    },
    resetCampaignStats(state) {
      state.stats = {
        ...initialState.stats,
      };
    },
    resetCampaignData(state) {
      state.campaignData = [];
    },
    resetCampaignPagination(state) {
      state.page = initialState.page;
      state.limit = initialState.limit;
    },
  },
  extraReducers: (builder) => {
    //get templates
    builder.addCase(getCampaignTemplates.fulfilled, (state, action) => {
      state.campaignTemplates = action.payload;
    });

    //add new campaign
    builder.addCase(addCampaign.fulfilled, (state, action) => {
      state.campaignLoading = false;
      if (state.campaignType === "broadcast") {
        if (state.broadcastData.length < state.limit) {
          state.broadcastData = [action.payload, ...state.broadcastData];
        } else {
          state.broadcastData = [
            action.payload,
            ...state.broadcastData.slice(0, state.limit - 1),
          ];
        }
        state.campaignCount += 1;
      }
    });
    builder.addCase(addCampaign.pending, (state) => {
      state.campaignLoading = true;
    });
    builder.addCase(addCampaign.rejected, (state) => {
      state.campaignLoading = false;
    });

    builder.addCase(putCampaign.fulfilled, (state, action) => {
      state.campaignLoading = false;
      if (state.campaignType === "broadcast") {
        if (state.broadcastData.length < state.limit) {
          state.broadcastData = [action.payload, ...state.broadcastData];
        } else {
          state.broadcastData = [
            action.payload,
            ...state.broadcastData.slice(0, state.limit - 1),
          ];
        }
        state.campaignCount += 1;
      }
    });
    builder.addCase(putCampaign.pending, (state) => {
      state.campaignLoading = true;
    });
    builder.addCase(putCampaign.rejected, (state) => {
      state.campaignLoading = false;
    });

    //get campaigns
    builder.addCase(getCampaigns.fulfilled, (state, action) => {
      state.campaignData = action.payload.rows;
      state.campaignCount = action.payload.count;
      state.campaignType = state.campaignTab;
      // console.log(action.payload)
      if (state.campaignType === "product") {
        state.campaignData = action.payload.rows;
        state.campaignCount = action.payload.count;
      } else {
        state.broadcastData = action.payload.rows;
        state.broadcastCount = action.payload.count;
      }
      state.campaignLoading = false;
    });
    builder.addCase(getCampaigns.pending, (state) => {
      state.campaignLoading = true;
    });
    builder.addCase(getCampaigns.rejected, (state) => {
      state.campaignLoading = false;
    });

    builder.addCase(getCampaignCustomers.fulfilled, (state, { payload }) => {
      state.campaignLoading = false;
      state.campaignCustomers = [...payload.rows];
      state.totalCampaignCustomersCount = payload.count;
    });

    builder.addCase(getCampaignCustomers.pending, (state) => {
      state.campaignLoading = true;
    });
    builder.addCase(getCampaignCustomers.rejected, (state) => {
      state.campaignLoading = false;
    });

    builder.addCase(getCampaignsAnalytics.fulfilled, (state, { payload }) => {
      state.campaignLoading = false;
      let customerStatusCount = [...payload];
      let currStats = { ...state.stats };
      customerStatusCount?.forEach((customerStatus) => {
        const { count, status } = customerStatus;
        currStats = {
          ...currStats,
          [`${status}`]: count,
        };
      });
      state.stats = { ...currStats };
    });
    builder.addCase(getCampaignsAnalytics.pending, (state) => {
      state.campaignLoading = true;
    });
    builder.addCase(getCampaignsAnalytics.rejected, (state) => {
      state.campaignLoading = false;
    });

    builder.addCase(getCampaignById.fulfilled, (state, action) => {
      state.campaignLoading = false;
      state.selectedCampaign = action.payload;
      state.selectedCampaignId = action.payload.id;
    });

    builder.addCase(getCampaignById.pending, (state) => {
      state.campaignLoading = true;
    });
    builder.addCase(getCampaignById.rejected, (state) => {
      state.campaignLoading = false;
    });

    builder.addCase(delCampaignById.fulfilled, (state, action) => {
      state.campaignData = removeDeleteData(state.campaignData, action.payload);
      state.totalCampaignCustomersCount -= 1;
      state.loading = false;
      state.mode = null;
    });

    builder.addCase(delCampaignById.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(delCampaignById.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  changeSelectedCampaign,
  changePage,
  clearCampaignCustomers,
  changecampCustomerPage,
  changeSelectedCampaignId,
  resetSelectedCampaign,
  changeCampaignType,
  resetCampaignType,
  changelimit,
  setrerunCampaign,
  rchangeSelectedCampaign,
  resetCampaignStats,
  updateSelectedCampaignWithSR,
  changeCampaignTab,
  resetCampaignData,
  resetCampaignMode,
  changeMode,
  resetCampaignPagination,
} = campaignSlice.actions;

export default campaignSlice.reducer;
