import styles from "./style.module.css";
import { useEffect } from "react";
import { NavIcon } from "../NavIcon";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  changeSelectedTab,
  changeSidebarOpenStatus,
} from "../../reducers/appSlice";
import { Text } from "../Text";

const navbarItems = [
  { label: "Dashboard", icon: "dashboard", route: "/dashboard" },
  { label: "Campaigns", icon: "campaign", route: "/campaigns" },
  { label: "Schedule", icon: "campaign", route: "/schedule" },
  { label: "Products", icon: "product", route: "/products/list" },
  { label: "Orders", icon: "order", route: "/orders" },
  { label: "Enquiries", icon: "enquiry", route: "/enquries" },
  { label: "Categories", icon: "category", route: "/categories" },
  { label: "Customers", icon: "customer", route: "/customers" },
];
Object.freeze(navbarItems);

export const SbNavbar = () => {
  const { selectedTab, slidebarOpen } = useSelector(
    (state) => state.application
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNav = (tabIndex) => {
    dispatch(changeSelectedTab(navbarItems[tabIndex].route));
    if (slidebarOpen) dispatch(changeSidebarOpenStatus());
    navigate(navbarItems[tabIndex].route);
  };

  useEffect(() => {
    let currentPath = location.pathname;
    if (currentPath == "/") {
      currentPath = "/dashboard";
    }
    dispatch(changeSelectedTab(currentPath));
  });

  return (
    <div className={`flex ${styles["sb-navbar"]}`}>
      <ul className="list-none flex gap-2 m-0">
        {navbarItems.map((navItem, index) => (
          <li
            key={index}
            className={`flex justify-content-start align-items-center cursor-pointer py-2 px-1 gap-2 ${
              styles["tab"]
            } ${
              navItem.route === selectedTab ||
              navItem.route.substring(1) === selectedTab.split("/")[1]
                ? styles["selected"]
                : ""
            }`}
            onClick={() => handleNav(index)}
          >
            <NavIcon icon={navItem.icon} />
            <Text type={"nav-text"}>{navItem.label}</Text>
          </li>
        ))}
      </ul>
    </div>
  );
};
