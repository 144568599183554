import { CustomButton } from "../CustomButton";
import CustomDialog from "../CustomDialog";
import './index.css'
import { Text } from "../Text";
function CustomSaveModel({
  showSave = false,
  header = "",
  topheader="Confirmation",
  dialogFooter,
  onHide = () => {} 
}) {
  return (
    <CustomDialog
     header={topheader}
      visible={showSave}
      prevCss={false}
      onHide={onHide}
      footer={dialogFooter}
      style={{ width: '45vw' }}
      className={`dialog-custom`}
    >
        <div className={`flex flex-column  gap-3 `}>
          <Text className={`alertText`} >
            {header}
          </Text>
        </div>
    </CustomDialog>
  );
}

export default CustomSaveModel;
