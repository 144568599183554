import { Handle, Position } from "reactflow";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedNode,
  closeConfigScreen,
  openConfigScreen,
  undoNodeData,
} from "../../../reducers/canvas/canvasSlice";
import { handleInputType } from "../../../reducers/multiInputSlice";
import { getTheNode } from "../../../utils/canvasUtils";
import { Tag } from "primereact/tag";
import { CANVAS_NODE_TITLES } from "../node_constant";
import { NodeHeader } from "../NodeHeader";
import { Text } from "../../Text";
import { StartTag } from "../StartTag";
import EditButton from "../Editbutton";
import { ishttpsNodeValid } from "../../../view/Bots/BotBuilder/node-constant";
const HttpNode = (props) => {
  const dispatch = useDispatch();
  const { nodes, selectedNode, rootStepId, configScreenOpen } = useSelector(
    (state) => state.canvas
  );
  const handleNodeSelection = () => {
    if (selectedNode?.id !== props.id) {
      if (configScreenOpen) {
        dispatch(undoNodeData());
        dispatch(closeConfigScreen());
      }
      dispatch(changeSelectedNode(getTheNode(nodes, props?.id)));
    }
  };
  const handleEditClick = (e) => {
    e.stopPropagation();
    dispatch(undoNodeData());
    dispatch(handleInputType("http"));
    dispatch(openConfigScreen(getTheNode(nodes, props?.id)));
  };

  return (
    <>
      {rootStepId == props?.id && <StartTag />}
      <div
        onClick={handleNodeSelection}
        className={`p-2 ${styles.httpNode} ${
          selectedNode?.id == props?.id
            ? styles["selected-node"]
            : !ishttpsNodeValid(props.data)
            ? styles["invalid-node"]
            : ""
        }`}
      >
        <EditButton onClick={handleEditClick} />
        <Handle type="target" position={Position.Left} />
        <NodeHeader nodeType={props.data.type} />
        <div className={`mt-2 ${styles["text-message"]}`}>
          {props?.data?.method && <Text>{props.data.method}</Text>}
          {props?.data?.url && <Text>{props.data.url}</Text>}
        </div>
        <Handle type="source" position={Position.Right} />
      </div>
    </>
  );
};

export default HttpNode;
