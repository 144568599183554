import { useEffect, useRef, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { useDispatch, useSelector } from "react-redux";
import {
  updateNodePayload,
  changeRootStepId,
} from "../../reducers/canvas/canvasSlice";
import imageCompression from "browser-image-compression";
import { API_GET_FILE_URL } from "../../api/media.service";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";

import { Text } from "../Text";
import { FaRegFileVideo } from "react-icons/fa";
import { tailNodes } from "../CustomNodes/node_constant";
import CustomProgressBar from "../CustomProgressBar";
import axios from "axios";
import { CustomButton } from "../CustomButton";
import { iSValidFileSize } from "../../utils/canvasUtils";
import {
  IMAGE_SIZE_LIMIT_IN_MB,
  VIDEO_SIZE_LIMT_IN_MB,
} from "./multiInput-constant";

import { Checkbox } from "primereact/checkbox";
import StartNodeCheckMark from "../../view/Bots/components/start-node-checkmark";

const TextInput = ({
  inputType = "text",
  onCancel = () => {},
  onSave = () => {},
}) => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const mediaFileRef = useRef(null);
  const { selectedNode, rootStepId } = useSelector((state) => state.canvas);
  const [caption, setCaption] = useState("");
  const [fileChoosen, setFileChoosen] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isUploading, setUploading] = useState(false);
  const errorToast = (message) => {
    toast.current.show({
      severity: "error",
      detail: message,
    });
  };

  const handleUpload = async (e) => {
    try {
      let file = e.target.files[0];
      if (file) {
        if (
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/jpg"
        ) {
          if (!iSValidFileSize(file.size, IMAGE_SIZE_LIMIT_IN_MB)) {
            errorToast(
              `Image file size should not be more than ${IMAGE_SIZE_LIMIT_IN_MB} MB.`
            );
            return;
          } else {
            const options = {
              maxSizeMB: IMAGE_SIZE_LIMIT_IN_MB,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            const imageFile = file;
            const compressedFile = await imageCompression(imageFile, options);
            let data = new File([compressedFile], compressedFile.name, {
              type: compressedFile.type,
            });
            setFileChoosen(data);
          }
        } else if (file.type === "video/mp4") {
          if (!iSValidFileSize(file.size, VIDEO_SIZE_LIMT_IN_MB)) {
            errorToast(
              `Video file size should not be more than ${VIDEO_SIZE_LIMT_IN_MB} MB.`
            );
            return;
          } else {
            setFileChoosen(file);
          }
        }
      }
    } catch (error) {
      console.error("Error occurred during file upload:", error);
      errorToast("Error occurred during upload");
    }
  };

  const handleCaption = (e) => {
    setCaption(e.target.value);
    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { label: e.target.value },
      })
    );
  };

  const uploadFile = async () => {
    const resp = await API_GET_FILE_URL();
    const fileType = inputType === "image" ? "image/png" : "video/mp4";

    var config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadPercentage(percentCompleted);
      },
      headers: { "Content-Type": fileType },
    };

    await axios.put(resp.data.src, fileChoosen, config);

    const _data = {};
    _data[inputType === "image" ? "image" : "video"] = resp.data.url;

    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: _data,
      })
    );
    setFilePreview(resp.data.url);
  };

  //function form get error message
  const getFormErrorMessage = () => {
    return (
      !caption.trim() && (
        <small className="p-error">Please enter your message.</small>
      )
    );
  };

  const onSubmit = () => {
    if (!caption.trim()) return;
    else if ((inputType == "image" || inputType == "video") && !filePreview) {
      toast.current.show({
        severity: "error",
        detail: `Please add your ${inputType} file.`,
      });
      return;
    } else {
      onSave();
    }
  };

  useEffect(() => {
    setCaption(selectedNode?.data?.label || "");
    setFilePreview(
      selectedNode?.data?.image || selectedNode?.data?.video || ""
    );
  }, [selectedNode]);

  useEffect(() => {
    if (fileChoosen) {
      setUploading(true);

      uploadFile()
        .then((res) => {
          setUploading(false);
          setUploadPercentage(0);

          // setUploaded(true);
        })
        .catch((err) => {
          setUploading(false);
        });
    }
  }, [fileChoosen]);

  return (
    <>
      <Toast ref={toast} />
      {(inputType === "image" || inputType === "video") && (
        <div>
          <input
            ref={mediaFileRef}
            type="file"
            accept={inputType === "image" ? "image/*" : "video/*"}
            id="imageAndVideo"
            hidden
            onChange={handleUpload}
          />
          <button
            type="button"
            className="w-full flex justify-content-center gap-3 p-3 cursor-pointer"
            onClick={() => mediaFileRef.current.click()}
            style={{ border: "1px dashed #5E5E5E", background: "#EDEDED" }}
          >
            {inputType === "image" ? (
              <i className={`pi pi-image`} />
            ) : (
              <FaRegFileVideo />
            )}

            <Text type={"sub-heading"}>{`Upload ${inputType}`}</Text>
          </button>
        </div>
      )}

      {inputType === "video" && filePreview && selectedNode.data?.video && (
        <video controls width="100%" className="my-3">
          <source src={filePreview} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      {inputType === "image" && filePreview && selectedNode.data?.image && (
        <Image src={filePreview} preview width="100%" className="my-3" />
      )}

      {isUploading && <CustomProgressBar progress={uploadPercentage} />}

      {!tailNodes.includes(inputType) ? (
        <div className={`${inputType !== "text" ? "mt-3" : ""}`}>
          <InputTextarea
            autoFocus
            id="caption"
            value={caption}
            rows={6}
            placeholder="Write your text here"
            onChange={handleCaption}
            className="w-full"
          />
          {getFormErrorMessage()}
        </div>
      ) : (
        <div>
          <Text type={"sub-heading"} color={"grey"}>
            This is an 'Action' node. The server will provide a default message
            for this node.
          </Text>
        </div>
      )}
      <StartNodeCheckMark />
      <div className=" mt-6 mb-3 flex justify-content-end gap-3 align-items-center">
        <CustomButton varient="outline" label={"Cancel"} onClick={onCancel} />
        {!tailNodes.includes(inputType) && (
          <CustomButton
            varient="filled"
            label={"Save"}
            type="submit"
            onClick={onSubmit}
          />
        )}
      </div>
    </>
  );
};

export default TextInput;
