import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import TrailDashboard from "../TrailDashboard";
const MessageTemplates = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { userSub } = useSelector((state) => state.authenticate);

  if (
    currentPath == "/campaign-templates" ||
    currentPath == "/campaign-templates/"
  ) {
    return !userSub.isUserSub ? (
      <TrailDashboard/>
    ) :<Navigate to={"/campaign-templates/list"} replace />;
  }
  return <Outlet />;
};

export default MessageTemplates;
