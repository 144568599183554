export const urlreg =
  /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/;

export const httpMenu = [
  {
    id: 0,
    label: "Params",
  },
  {
    id: 1,
    label: "Headers",
  },
  {
    id: 2,
    label: "Body",
  },
];

export const methods = [
  {
    name: "GET",
    value: "GET",
  },
  {
    name: "POST",
    value: "POST",
  },
  {
    name: "PUT",
    value: "PUT",
  },
  {
    name: "DELETE",
    value: "DELETE",
  },
];
export const checkValidUrl = (value) => {
  return urlreg.test(value);
};

export const checkIsValidPrams = (dataList) => {
  for (const data of dataList) {
    if (!data.key.trim() || !data.value?.trim()) {
      return false;
    }
  }

  return true;
};
