import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect } from "react";
import { getDate } from "../../utils/datemaker";
import CustomPaginator from "../../components/CustomPaginator";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Edit } from "../../svg/edit.svg";
import { ReactComponent as Delete } from "../../svg/delete.svg";
import { Dropdown } from "primereact/dropdown";
import {
  deleteTemplate,
  getTemplates,
} from "../../reducers/templateReducer/templateActions";
import Loader from "../../components/Loader";
import {
  changeTemplateLimit,
  changeTemplateMode,
  changeTemplatePage,
  resetPage,
  resetSelectedTemplate,
} from "../../reducers/templateReducer/templateSlice";
import { Share } from "../../components/Share";
import {
  resetBroadcast,
  showShareMessageDialog,
} from "../../reducers/ShareMessage/messageSlice";
import { CustomButton } from "../../components/CustomButton";
import { changeSelectedTemplate } from "../../reducers/templateReducer/templateSlice";
import { useState } from "react";
import SklbtConfirmation from "../../components/common/SklbtConfirmation";
import { Text } from "../../components/Text";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import { resetSelectedProductBlist } from "../../reducers/productTableSlice";
import { updatecustomers } from "../../reducers/customerTableSlice";
import * as Messag from "../../config/ToastMessage";
import CONSTANTS from "../../config/Constants";
import { categoryFilterOptions } from "../../utils/templateUtils";
import { templateFilterOptions } from "../../utils/templateUtils";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
const TemplateList = () => {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [filters1, setFilters1] = useState({
    category: { value: "" },
    templateType: { value: "" },
  });

  const onClearCategoryFilter = () => {
    setFilters1((prev) => {
      return {
        ...prev,
        category: { ...prev.category, value: "" },
      };
    });
  };
  const onClearTemplateTypeFilter = () => {
    setFilters1((prev) => {
      return {
        ...prev,
        templateType: { ...prev.templateType, value: "" },
      };
    });
  };

  const onApplyClick = (e) => {
    switch (e.field) {
      case "category":
        setFilters1((prev) => {
          return {
            ...prev,
            category: { ...prev.category, value: e.constraints.value },
          };
        });

        break;
      case "templateType":
        setFilters1((prev) => {
          return {
            ...prev,
            templateType: { ...prev.templateType, value: e.constraints.value },
          };
        });
        break;
      default:
        break;
    }
  };
  const {
    templateList,
    totalTemplatesCount,
    loading,
    pageNo,
    limit,
    selectedTemplate,
  } = useSelector((state) => state.template);

  const { shareLoading } = useSelector((state) => state.shareMessage);
  const { accesskey } = useSelector((state) => state.authenticate);

  const navigate = useNavigate();
  const toast = useRef();
  const handlePickTemplate = (rowData) => {
    dispatch(resetBroadcast());
    dispatch(resetSelectedTemplate());
    dispatch(updatecustomers({ selectedCust: [] }));
    dispatch(resetSelectedProductBlist());
    dispatch(
      showShareMessageDialog({
        title: "Edit Template Message",
        selectedTemplate: rowData,
      })
    );
    navigate("/runcampaign/new");
  };

  const dispatch = useDispatch();
  const deleteSelectedTemplate = () => {
    const { elementName } = selectedTemplate;
    dispatch(deleteTemplate({ elementName }))
      .unwrap()
      .then(() => {
        setDeleteConfirm(false);
        toast.current.show({
          severity: "success",
          detail: Messag.Delete_Template_ToastSuccessMessage,
        });
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          detail: err.message,
        });
      });
  };

  const templateActiopns = (template) => {
    const editAction = () => {
      dispatch(changeTemplateMode("update"));
      dispatch(changeSelectedTemplate(template));
      navigate(`/campaign-templates/${template.id}/update`);
    };
    const deleteAction = () => {
      dispatch(changeSelectedTemplate(template));
      setDeleteConfirm(true);
    };
    const handleClone = () => {
      dispatch(changeTemplateMode("update"));
      dispatch(changeSelectedTemplate(template));
      navigate(`/campaign-templates/${template.id}/clone`);
    };
    return (
      <div className="flex align-items-center gap-2">
        {accesskey[modulesName.CAMPAIGN]?.includes(permissions.CREATE) && (
          <CustomButton
            varient="text font-light no-underline  bg-white border-solid  border-1  "
            label={"Use Template"}
            onClick={() => handlePickTemplate(template)}
            disabled={!(template.status === "APPROVED")}
          />
        )}
        {accesskey[modulesName.TEMPLATE]?.includes(permissions.CREATE) && (
          <CustomButton
            varient="icon-button"
            icon={"pi pi-clone"}
            onClick={handleClone}
          />
        )}
        {accesskey[modulesName.TEMPLATE]?.includes(permissions.DELETE) &&
          template?.isDefault !== true && (
            <CustomButton
              varient="icon-button"
              icon={<Delete />}
              disabled={false}
              onClick={deleteAction}
            />
          )}
      </div>
    );
  };

  const isValidTemplate = (data) => {
    const { status } = data;
    return status == "APPROVED";
  };
  const rowClassName = (data) => {
    return isValidTemplate(data) ? "" : "not-selectable";
  };
  const filterItemTemplate = (option) => {
    return <span className="capitalize">{option.toLowerCase()}</span>;
  };
  const categoryFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={categoryFilterOptions}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        itemTemplate={filterItemTemplate}
        placeholder="Select Category"
        className="p-column-filter"
      />
    );
  };
  const templateTypeFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={templateFilterOptions}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        itemTemplate={filterItemTemplate}
        placeholder="Select type"
        className="p-column-filter"
      />
    );
  };

  useEffect(() => {
    dispatch(
      getTemplates({
        pageNo,
        limit,
        category: filters1.category.value,
        templateType: filters1.templateType.value,
      })
    )
      .unwrap()
      .catch((err) => {
        console.log(err);
      });
  }, [pageNo, limit, filters1]);

  useEffect(() => {
    return () => {
      dispatch(resetPage());
    };
  }, []);
  return (
    <div className="m-3">
      <Toast ref={toast} />
      <Share toast={toast} />
      <Loader visible={loading || shareLoading} />
      <SklbtConfirmation
        icon="pi pi-trash"
        message={CONSTANTS.TEMPLATE_DELETE_CONFIRMATION}
        visible={deleteConfirm}
        handleConfirm={deleteSelectedTemplate}
        handleReject={() => setDeleteConfirm(false)}
      />
      <TableActionsContainer>
        <div className="flex justify-content-between align-items-center gap-3">
          <Text type="heading">Campaign Templates</Text>
          {accesskey[modulesName.TEMPLATE]?.includes(permissions.CREATE) && (
            <CustomButton
              varient="filled"
              type="button"
              label={"Create Template"}
              icon={"pi pi-plus"}
              onClick={() => navigate("/campaign-templates/new")}
            />
          )}
        </div>
      </TableActionsContainer>
      <DataTable
        value={templateList}
        dataKey="id"
        filters={filters1}
        filterDisplay="menu"
        globalFilterFields={["category", "templateType"]}
        className="skalebot-table mt-3"
        rowClassName={rowClassName}
        removableSort
      >
        <Column
          field="templateId"
          header={<Text>Template&nbsp;Id</Text>}
          className="text-sm font-normal"
          style={{ textTransform: "capitalize" }}
        />
        <Column
          field="elementName"
          header="Name"
          sortable
          className="text-sm font-normal"
          style={{ textTransform: "capitalize" }}
        />
        <Column
          field="category"
          header="Category"
          filterField="category"
          style={{ minWidth: "25ch", width: "25ch" }}
          filter
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterElement={categoryFilterTemplate}
          onFilterApplyClick={(e) => onApplyClick(e)}
          onFilterClear={onClearCategoryFilter}
          className="text-sm font-normal capitalize"
          body={(rowData) => (
            <Text cust="capitalize">{rowData?.category?.toLowerCase()}</Text>
          )}
        />
        <Column
          field="templateType"
          header="Template Type"
          filter
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterElement={templateTypeFilterTemplate}
          onFilterApplyClick={(e) => onApplyClick(e)}
          onFilterClear={onClearTemplateTypeFilter}
          style={{ minWidth: "20ch", width: "20ch" }}
          body={(rowData) => (
            <Text cust="capitalize text-sm font-normal">
              {rowData?.templateType.toLowerCase()}
            </Text>
          )}
        />
        <Column
          field="status"
          header="Status"
          style={{ minWidth: "20ch", width: "20ch" }}
          body={(rowData) => (
            <Text cust="capitalize text-sm font-normal">
              {rowData?.status.toLowerCase()}
            </Text>
          )}
        />
        <Column
          field=""
          header="Date & Time"
          className="text-sm font-normal"
          style={{ minWidth: "20ch", width: "20ch" }}
          body={(rowData) => getDate(rowData.createdOn)}
          bodyStyle={{ textTransform: "uppercase" }}
        />
        {(accesskey[modulesName.TEMPLATE]?.includes(permissions.CREATE) ||
          accesskey[modulesName.TEMPLATE]?.includes(permissions.UPDATE) ||
          accesskey[modulesName.TEMPLATE]?.includes(permissions.DELETE)) && (
          <Column
            field=""
            header="Actions"
            style={{ minWidth: "32ch", width: "32ch" }}
            body={(rowData) => templateActiopns(rowData)}
          />
        )}
      </DataTable>
      <div className="mt-3">
        <TableActionsContainer>
          <div className="flex justify-content-end">
            <CustomPaginator
              page={pageNo}
              limit={limit}
              totalRecords={totalTemplatesCount}
              changePage={changeTemplatePage}
              changelimit={changeTemplateLimit}
            />
          </div>
        </TableActionsContainer>
      </div>
    </div>
  );
};

export default TemplateList;
