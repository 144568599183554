import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback
} from "react";
import styles from "./forwardMessagesModal.module.css";
import {
  doc,
  query,
  onSnapshot,
  collection,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs
} from "firebase/firestore";
import { db } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  changeIsUserSelected,
  changeUser,
  clearForwardMessage,
  resetChatUser,
  updateUserStatus
} from "../../reducers/enqChatSlice";
import {
  StatusIcon,
  formatAMPM,
  formatMessage,
  isTimeOver,
  ticketStausSorted
} from "../../utils/chatUtils";
import CustomerChatInfo from "../../view/EnqChat/CustomerChatInfo";
import { isTextOrNumber } from "../../utils/commonUtils";
import { extractVariablesFormMessage } from "../../utils/templateUtils";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import ForwardCustomers from "./ForwardCustomers";
import { Text } from "../Text";
import { ReactComponent as AudioIcon } from "../../svg/audio.svg";
import { formatMobNumber } from "../../utils/commonUtils";
import { Checkbox } from "primereact/checkbox";
import PieChartTimer from "../../view/EnqChat/PieChartTimer";
import { ReactComponent as SendButton } from "../../assets/svgIcons/svgIcons/sendbuttonicon.svg";
import Search from "./Search";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { API_SEND_MSG } from "../../api/chat.service";
import Loader from "../Loader";
const ticketStatusColor = {
  New: "#005068",
  "In Progress": "#2DBECD",
  "Follow Up": "#000000",
  Closed: "#767676"
};

const ForwardMessagesModal = ({ selectedMessages, onClose }) => {
  const customerContainerRef = useRef(null);
  const WarningToastRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [lastMessage, setLastMessage] = useState(null);
  const [acitveListener, setActiveListener] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const [selected, setSelected] = useState({});
  const [selectedChatDetails, setSelectedChatDetails] = useState([]);
  const [namesOrNumbersString, setNamesOrNumbersString] = useState("");
  const [truncatedString, setTruncatedString] = useState("");
  const [selectedCount, setSelectedCount] = useState(0);
  const MAX_SELECTION = 5; // Maximum number of selections allowed
  const [loader, setLoader] = useState(false);

  const {
    allotedAgent,
    agentSearchItem,
    forwardModalAgentSearchItem,
    chatId,
    user,
    ticketStatusFilter,
    forwardModalTicketStatusFilter
  } = useSelector((state) => state.enqChats);
  const { company } = useSelector((state) => state.company);
  const { accesskey } = useSelector((state) => state.authenticate);
  const dispatch = useDispatch();

  const botNumber = company?.phone;

  // const handleSelect = (u) => {
  //   dispatch(changeIsUserSelected(true));
  //   dispatch(changeUser(u));
  // };

  const SelectionLimitWarning = ({ onClose }) => {
    return (
      <div
        className="  pt-2  center flex justify-content-center"
        style={{ zIndex: 9999, background: "#fff", borderRadius: "8px" }}
      >
        <Text style={{ color: "#c79807" }}>
          {" "}
          You can select up to 5 contacts.
        </Text>
      </div>
    );
  };

  useEffect(() => {
    if (!botNumber || !allotedAgent.role || !allotedAgent.mob) return;
    const getChats = async () => {
      let q;
      const searchField = isTextOrNumber(forwardModalAgentSearchItem)
        ? "phone"
        : "businessName";
      const searchValue = isTextOrNumber(forwardModalAgentSearchItem)
        ? `91${forwardModalAgentSearchItem}`
        : forwardModalAgentSearchItem;
      const isAdmin = accesskey[modulesName.SALES]?.includes(
        permissions.UPDATE
      );
      const hasTicketStatusFilter =
        forwardModalTicketStatusFilter &&
        forwardModalTicketStatusFilter.length !== 0;
      const hasAgentSearchItem =
        forwardModalAgentSearchItem && forwardModalAgentSearchItem.length !== 0;
      let requests = [];
      requests.push(collection(db, "groups"));
      requests.push(where("botNumber", "==", botNumber));
      if (allotedAgent.role === "support") {
        let IDs = [allotedAgent?.agentId, -1];
        requests.push(where("agentId", "in", IDs));
      }
      if (hasTicketStatusFilter) {
        requests.push(
          where("ticketStatus", "==", forwardModalTicketStatusFilter)
        );
      }
      if (hasAgentSearchItem) {
        requests.push(where(searchField, ">=", searchValue));
        requests.push(where(searchField, "<=", searchValue + "\uf8ff"));
        requests.push(orderBy(searchField));
      }
      requests.push(orderBy("lastUpdatedAt", "desc"));
      requests.push(limit(25));
      const baseQuery = query(...requests);
      q = query(baseQuery);
      setLoading(true);

      if (acitveListener) {
        acitveListener();
      }

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        setLoading(false);
        const groups = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          let conversationBetween = doc.id.split("-");

          let bot = conversationBetween[0];
          if (bot == allotedAgent.mob) {
            let lastMessage = "";
            if (data?.lastMessage) {
              try {
                lastMessage = formatMessage(
                  JSON.parse(data?.lastMessage),
                  data.messageType
                );
              } catch (error) {
                console.log(error);
              }
            }
            const conversation = {
              ...data,
              customerInfo: {
                displayName: data.businessName,
                mob: data?.phone,
                ticketId: data?.ticketId,
                ticketStatus: data?.ticketStatus,
                enqStartedAt: data?.enquiryStartTime,
                agentId: data?.agentId
              },
              lastMessage: lastMessage,
              enqStartedAt: data?.enquiryStartTime
            };
            groups.push(conversation);
          }
        });
        setChats(groups);
        if (groups.length > 0) {
          setLastMessage(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
      });

      setActiveListener(() => unsubscribe);

      return () => {
        unsubscribe();
      };
    };
    getChats();
  }, [
    allotedAgent,
    forwardModalAgentSearchItem,
    forwardModalTicketStatusFilter
  ]);

  // useEffect(() => {
  //   let userStatus = null;
  //   if (chats) {
  //     chats?.forEach((item) => {
  //       if (user?.mob == item?.phone) {
  //         userStatus = item?.customerInfo;
  //         dispatch(
  //           updateUserStatus({
  //             ...item?.customerInfo
  //           })
  //         );
  //       }
  //     });
  //     // if (userStatus === null) {
  //     //   dispatch(resetChatUser());
  //     // }
  //   }

  //   if (Object.keys(user).length == 0 && chats.length != 0) {
  //     dispatch(changeUser(chats[0]?.customerInfo));
  //   }
  // }, [chats]);

  const loadMoreCustomersChat = async () => {
    if (!lastMessage) return;
    const isAdmin = accesskey[modulesName.SALES]?.includes(permissions.UPDATE);
    const hasTicketStatusFilter =
      forwardModalTicketStatusFilter &&
      forwardModalTicketStatusFilter.length !== 0;
    const hasAgentSearchItem =
      forwardModalAgentSearchItem && forwardModalAgentSearchItem.length !== 0;
    const searchField = isTextOrNumber(forwardModalAgentSearchItem)
      ? "phone"
      : "businessName";
    const searchValue = isTextOrNumber(forwardModalAgentSearchItem)
      ? `91${forwardModalAgentSearchItem}`
      : forwardModalAgentSearchItem;
    let q;
    let requests = [];
    requests.push(collection(db, "groups"));
    requests.push(where("botNumber", "==", botNumber));
    if (allotedAgent.role === "support") {
      let IDs = [allotedAgent?.agentId, -1];
      requests.push(where("agentId", "in", IDs));
    }
    if (hasTicketStatusFilter) {
      requests.push(
        where("ticketStatus", "==", forwardModalTicketStatusFilter)
      );
    }
    if (hasAgentSearchItem) {
      requests.push(where(searchField, ">=", searchValue));
      requests.push(where(searchField, "<=", searchValue + "\uf8ff"));
      requests.push(orderBy(searchField));
    }
    requests.push(orderBy("lastUpdatedAt", "desc"));
    requests.push(startAfter(lastMessage));
    requests.push(limit(25));
    const baseQuery = query(...requests);
    q = query(baseQuery);
    setLoading(true);
    const querySnapshot = await getDocs(q);
    setLoading(false);
    if (querySnapshot.docs.length === 0) {
      setLastMessage(null);
      return;
    }
    const groups = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      let conversationBetween = doc.id.split("-");
      let bot = conversationBetween[0];
      if (bot == allotedAgent.mob) {
        let lastMessage = "";
        if (data?.lastMessage) {
          try {
            lastMessage = formatMessage(
              JSON.parse(data?.lastMessage),
              data.messageType
            );
          } catch (error) {
            console.log(error);
          }
        }
        const conversation = {
          ...data,
          customerInfo: {
            displayName: data.businessName,
            mob: data?.phone,
            ticketId: data?.ticketId,
            ticketStatus: data?.ticketStatus,
            enqStartedAt: data?.enquiryStartTime,
            agentId: data?.agentId
          },
          lastMessage: lastMessage,
          enqStartedAt: data?.enquiryStartTime
        };

        groups.push(conversation);
      }
    });
    setChats((prev) => [...prev, ...groups]);
    if (groups.length > 0) {
      setLastMessage(querySnapshot.docs[querySnapshot.docs.length - 1]);
    }
  };

  const HandleScroll = () => {
    if (customerContainerRef.current && !loading) {
      const { scrollTop, clientHeight, scrollHeight } =
        customerContainerRef.current;

      const tolerance = 1; // to handle decimal values of scrollTop

      if (scrollTop + clientHeight + tolerance >= scrollHeight) {
        const previousScrollTop = customerContainerRef.current.scrollTop;
        setLoading(true);

        loadMoreCustomersChat()
          .then(() => {
            setLoading(false);
            customerContainerRef.current.scrollTop = previousScrollTop;
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
      }
    }
  
  };
  useEffect(() => {
    // Update the current time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 1000);

    // Cleanup the interval on unmount
    return () => clearInterval(intervalId);
  }, []);
  const isWithin24Hour = (timestamp) => {
    const difference = currentTime - timestamp;
    const millisecondsIn24Hours = 24 * 60 * 60 * 1000;
    return difference <= millisecondsIn24Hours;
  };


  const toggleCheckbox = (chat) => {
    if (selectedCount === MAX_SELECTION && !selected[chat.customerInfo.mob]) {
      // If the maximum selection limit is reached and user tries to select more
      setSelectedCount(5);

      return;
    }

    setSelected((prevSelected) => {
      const newSelected = {
        ...prevSelected,
        [chat.customerInfo.mob]: !prevSelected[chat.customerInfo.mob]
      };

      if (newSelected[chat.customerInfo.mob]) {
        // If the checkbox is being checked
        setSelectedCount((prevCount) => prevCount + 1);
      } else {
        // If the checkbox is being unchecked
        setSelectedCount((prevCount) => prevCount - 1);
      }

      return newSelected;
    });
    if (
      !selectedChatDetails.some(
        // (selectedChat) => selectedChat.gsId === chat.gsId
        (selectedChat) =>
          selectedChat.customerInfo.mob === chat.customerInfo.mob
      )
    ) {
      // If the chat is not already in the selectedChatDetails array, add it
      setSelectedChatDetails((prevSelectedChatDetails) => [
        ...prevSelectedChatDetails,
        chat
      ]);
    } else {
      // If the chat is already in the selectedChatDetails array, remove it
      setSelectedChatDetails((prevSelectedChatDetails) =>
        prevSelectedChatDetails.filter(
          // (selectedChat) => selectedChat.gsId !== chat.gsId
          (selectedChat) =>
            selectedChat.customerInfo.mob !== chat.customerInfo.mob
        )
      );
    }
  };

  

  useEffect(() => {
    // function to generate namesOrNumbersString
    const generateNamesOrNumbersString = (selectedChatDetails) => {
      let namesOrNumbers = [];

      selectedChatDetails
        .slice(0)
        .reverse()
        .forEach((selectedChat) => {
          const nameOrNumber = selectedChat?.customerInfo?.displayName
            ? selectedChat?.customerInfo?.displayName.charAt(0).toUpperCase() +
              selectedChat?.customerInfo?.displayName
                .split(" ")[0] // Get only the first name
                .substring(1)
            : formatMobNumber(selectedChat?.customerInfo?.mob);

          namesOrNumbers.push(nameOrNumber);
        });
      return namesOrNumbers.join(", ");
    };

    // Generate namesOrNumbersString
    const newNamesOrNumbersString =
      generateNamesOrNumbersString(selectedChatDetails);
    setNamesOrNumbersString(newNamesOrNumbersString);

    // Generate truncatedString
    const newTruncatedString =
      newNamesOrNumbersString.length > 25
        ? newNamesOrNumbersString.substring(0, 25) + "..."
        : newNamesOrNumbersString;
    setTruncatedString(newTruncatedString);
  }, [selectedChatDetails]);

  const handleShare = async () => {
    const phoneNumbers = [];
    const ticketIds = [];
    selectedChatDetails.forEach((item) => {
      phoneNumbers.push(item.customerInfo.mob); // Assuming 'mob' is the property containing the phone number
      ticketIds.push(item.customerInfo.ticketId); 
    });

    const apiPromises = [];

    const ApiCall = async (number, messages, ticketId) => {
      setLoader(true);
      for (let i = 0; i < messages?.length; i++) {
        let message = messages[i];
        let payload = {};

      switch (message.message.messageType) {
        case "text":
          payload = {
            type: "text",
            text: message.message.text
          };
          break;
        case "image":
          payload = {
            type: "image",
            caption: message.message.text,
            originalUrl: message.message.url,
            previewUrl: message.message.url
          };
          break;
        case "video":
          payload = {
            type: "video",
            caption: message.message.text,
            url: message.message.url,
            previewUrl: message.message.url
          };
          break;
        case "audio":
          payload = {
            type: "audio",
            url: message.message.url
          };
          break;
        case "file":
          payload = {
            type: "file",
            caption: message.message.text,
            url: message.message.url,
            filename: message.message.filename
          };
          break;

          default:
            break;
        }

        const data = {
          destinationNo: number,
          payload
        };
        try {
          setLoader(true)
          // Make the API call
          const resp = await API_SEND_MSG(ticketId, data);
         
        } catch (error) {
          // Handle errors
          console.error("Error sending message:", error);
        }
      }
    };

    for (let i = 0; i < phoneNumbers.length; i++) {
      apiPromises.push(ApiCall(phoneNumbers[i], selectedMessages, ticketIds[i]));
    }


    try {
      await Promise.all(apiPromises);
    } catch (error) {
      console.error("Error sending messages:", error);
    }

    dispatch(clearForwardMessage());

    onClose(); // Close the modal after sharing
    setLoader(false);
  };

  return (
    <Dialog
      visible={true}
      onHide={onClose}
      showHeader={false}
      modalClassName={` `}
      className={`w-11 md:w-6 lg:w-5 xl:w-3 ${styles.modal} `}
      contentClassName={` ${styles.content}  `}
    >
      {/* <div className="w-full border-1 p-0" > */}
      <Loader visible={loader} />
      <div
        ref={customerContainerRef}
        onScroll={HandleScroll}
        className={` ${styles.modalContainer}  `}
      >
        <div className={` ${styles.header} `}>
          <i
            className={`pi pi-times`}
            style={{ fontSize: "1.125rem", color: "#e2e2e2" }}
            onClick={onClose}
          ></i>
          <Text type={"heading"} style={{ color: "#e2e2e2" }}>
            Forward messages to
          </Text>
        </div>
        {loading && (
          <div className="text-center">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "1rem" }}
            ></i>
          </div>
        )}
        <div style={{ padding: "8px 8px 0px 8px", background: "#fff" }}>
          <Search />
        </div>
        <div className="flex justify-content-center w-full">
          {selectedCount === 5 && <SelectionLimitWarning />}
        </div>

        <div className={`${styles.userChatContainer}`}>
          {chats && chats.length !== 0 ? (
            chats.map((chat, i) => {
              return (
                <div className={`${styles.userChat}`} key={i}>
                  {chat !== null && (
                    <div className={`${styles.userChatInfo}`}>
                      <div className="pt-1 ">
                        <Checkbox
                          checked={selected[chat.customerInfo.mob]}
                          onChange={() => toggleCheckbox(chat)}
                          disabled={
                            !isWithin24Hour(chat.enqStartedAt) ||
                            chat?.ticketStatus === "Closed" ||
                            !chat?.ticketStatus
                          }
                          style={{
                            opacity:
                              !isWithin24Hour(chat.enqStartedAt) ||
                              chat?.ticketStatus === "Closed" ||
                              !chat?.ticketStatus
                                ? 0.5
                                : 1
                          }}
                          className={`${styles["p-checkbox-box"]} ${styles["p-checkbox"]}`}
                        />
                      </div>

                      <div className="  w-full flex justify-content-between ">
                        <Text
                          type={"heading"}
                          cust="ch-limit-30"
                          style={{
                            fontWeight: 500,
                            opacity:
                              // selected[chat.customerInfo.mob] ||
                              chat?.ticketStatus === "Closed" ||
                              !isWithin24Hour(chat.enqStartedAt) ||
                              !chat?.ticketStatus
                                ? 0.5
                                : 1
                          }}
                        >
                          <span>
                            {chat?.customerInfo?.displayName
                              ? chat?.customerInfo?.displayName
                                  .charAt(0)
                                  .toUpperCase() +
                                chat?.customerInfo?.displayName.substring(1)
                              : formatMobNumber(chat?.customerInfo?.mob)}
                          </span>
                        </Text>
                        <div className="flex flex-column align-items-end">
                          <div
                            className="mb-1 ml-2 flex flex-column  justify-content-center"
                            style={{
                              width: "20px",
                              height: "20px",
                              border: "1px solid rgba(0, 0, 0, 0.7)",
                              borderRadius: "50%",
                              padding: "2px"
                            }}
                          >
                            <PieChartTimer
                              currentTime={currentTime}
                              startTimestamps={chat.enqStartedAt}
                              duration={24 * 60 * 60 * 1000}
                            />
                          </div>
                          <Text
                            type={"small-text"}
                            cust={`font-weigh-700`}
                            style={{
                              color: ticketStatusColor[chat?.ticketStatus]
                            }}
                          >
                            {chat?.ticketStatus}
                          </Text>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className="flex justify-content-center">
              No customers found
            </div>
          )}
        </div>

        {selectedChatDetails.length > 0 && (
          <div className={`${styles.shareContainer}`}>
            <div className="flex flex-column w-10 ">
              <div className={`${styles.selectedCustomersContainer}`}>
                <span className={`${styles.selectedCustomer} mt-1 px-1`}>
                  {truncatedString}
                </span>
              </div>

              {selectedChatDetails.length > 0 && (
                <span className={`${styles.selectedCustomer} mt-1 px-1`}>
                  Selected : {selectedChatDetails.length}
                </span>
              )}
            </div>
            <div onClick={handleShare} className="pr-2" style={{cursor:"pointer"}} >
              {" "}
              <SendButton />{" "}
            </div>
          </div>
        )}
      </div>
      {/* </div> */}
    </Dialog>
  );
};

export default ForwardMessagesModal;
