import { useNavigate, useParams } from "react-router";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { CustomButton } from "../../components/CustomButton";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import { Text } from "../../components/Text";
import { useDispatch, useSelector } from "react-redux";
import CustomInputText from "../../components/common/CustomInputText";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import * as Messag from "../../config/ToastMessage";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import states from "../../utils/state.json";
import {
  addCustomer,
  addressDeleteWithIndex,
  changeMode,
  getCustomerbyid,
  resetMode,
  resetSelectedCustomer,
  updateCustomer,
} from "../../reducers/customerTableSlice";
import { classNames } from "primereact/utils";
import { useLayout } from "../../context/LayoutContext";
import CustomSaveModel from "../../components/CustomeSaveModel";
import CONSTANTS from "../../config/Constants";
import CustomDropdown from "../../components/common/CustomDropdown";
import { API_GET_GROUPS } from "../../api/customer.services";
import { Checkbox } from "primereact/checkbox";
import CustomInputNumber from "../../components/common/CustomInputNumber";
import CustomInputTextArea from "../../components/common/CustomInputTextArea";
import { useUtility } from "../../context/UtilityContext";
import { ReactComponent as Delete } from "../../svg/delete.svg";
import { addressModifyWithIndex } from "../../reducers/customerTableSlice";
import { Chip } from "primereact/chip";
import { Toast } from "primereact/toast";

function NewCustomer() {
  const [address, setAddress] = useState([]);
  const { setLoading ,toastMessage} = useLayout();
  const toast =useRef(null)
  const navigate = useNavigate();
  const [availableGroups, setAvailableGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [editGroup, setEditGroup] = useState(false);
  const { mode, selectedCustomer, loading } = useSelector(
    (state) => state.customerTable
  );
  const defaultValues = {
    name: "",
    phone: "",
    email: "",
    groupId: "",
    fullAddress0: "",
    fullAddress1: "",
    fullAddress2: "",
    fullAddress4: "",
  };
  const dispatch = useDispatch();
  const { setAlert } = useUtility();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({ defaultValues });

  //function form get error message
  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small></small>
    );
  };

  const { id } = useParams();

  useEffect(() => {
    return () => {
      setAlert(false);
    };
  }, []);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const breadcumbHome = { label: "Customers", url: "/customers/list" };
  const breadcumbList = [
    { label: `${id ? "Update" : "New"} Customers`, url: "#" },
  ];
  const { navigation } = useUtility();

  useEffect(() => {
    if (id) {
      dispatch(getCustomerbyid({ id }))
        .unwrap()
        .then((e) => {});

      dispatch(changeMode("update"));
    }
  }, [id]);

  const getaddress = () => {
    let oldAddress = [];
    let i = 0;
    if (address?.length > 0) {
      oldAddress = address?.map((x) => {
        if (x.isActive) {
          let fullAddress = getValues(`fullAddress${i++}`);
          return {
            ...x,
            fullAddress,
          };
        } else {
          return x;
        }
      });
    } else {
      oldAddress.push({ fullAddress: getValues(`fullAddress0`) });
    }
    return oldAddress;
  };
  const onSubmit = (data) => {
    data = {
      groupId: data.groupId,
      name: data.name,
      phone: data.phone,
      email: data.email,
      addresses: getaddress(),
    };
    data = { ...data, phone: data.phone.substring(1) };
    if (mode === "update") {
      const customerId = selectedCustomer.id;
      dispatch(updateCustomer({ customerId, data }))
        .unwrap()
        .then((res) => {
          reset();
          dispatch(resetSelectedCustomer());
          dispatch(resetMode());
          let Message_Success = Messag.Update_Cust_ToastSuccessMessage;
          toast?.current?.show({ severity: "success", detail: Message_Success });
          navigate("/customers/list");
        })
        .catch((err) => {
          toast?.current?.show({ severity: "error", detail: err?.message });
        });
      setShowSave(false);
    } else {
      dispatch(addCustomer({ data }))
        .unwrap()
        .then((res) => {
          reset();
          dispatch(resetSelectedCustomer());
          dispatch(resetMode());
          let Message_Success = Messag.Add_Cust_ToastSuccessMessage;
          toastMessage({ severity: "success", detail: Message_Success });
          if (mode == "convert") navigate("/enquries");
          else {
            navigate("/customers/list");
          }
          //show toast here
        })
        .catch((err) => {
          //show toast here
          toast?.current?.show({ severity: "error", detail: err.message });
        });
      setShowSave(false);
    }
  };
  const setForm = () => {
    if (mode === "update" && selectedCustomer) {
      setValue("name", selectedCustomer.name, { shouldValidate: true });
      setValue("phone", "+" + selectedCustomer.phone, { shouldValidate: true });
      setValue("email", selectedCustomer.email || "", { shouldValidate: true });
      if (selectedCustomer?.addresses?.length > 0) {
        let temp = selectedCustomer?.addresses.filter((x) => x?.isActive);
        for (let [x] in temp) {
          setValue(`fullAddress${x}`, temp.at(x)?.fullAddress || "", {
            shouldValidate: true,
          });
        }
        setAddress(selectedCustomer?.addresses);
      }

      if (selectedCustomer?.groups) {
        const groupsId = selectedCustomer.groups?.map((item) => {
          return {
            id: item.id,
            label: item.groupName,
          };
        });
        setSelectedGroups(groupsId);
      }
    }
    if (mode === "convert" && selectedCustomer) {
      setValue("name", selectedCustomer.name || "", { shouldValidate: true });
      setValue("phone", "+" + selectedCustomer.phone, { shouldValidate: true });
      setValue("email", selectedCustomer.email || "", { shouldValidate: true });
    }
  };

  useEffect(() => {
    setForm();
  }, [selectedCustomer]);

  const [showSave, setShowSave] = useState(false);
  const SaveModel = () => {
    return (
      <CustomSaveModel
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };
  const alertText = () => {
    return <div>{CONSTANTS.CUSTOMER_SAVE_CONFIRMATION}</div>;
  };
  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false);
          }}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    );
  };
  const onHide = () => {
    setShowSave(false);
  };
  useEffect(() => {
    API_GET_GROUPS(0, 100000)
      .then((res) => {
        const formatedGroups = res?.rows?.map((item) => {
          return {
            id: item.id,
            label: item.groupName,
          };
        });
        setAvailableGroups(formatedGroups);
      })
      .catch((err) => {
        console.log("group err:", err);
      });
  }, []);

  const addressModifyWithIndex = (index, isActive) => {
    const temp = address.map((x) => {
      if (x.id === index) {
        return { ...x, isActive };
      } else {
        return x;
      }
    });
    setAddress([...temp]);
  };

  const handleDeleteAddress = (index, isActive = false) => {
    addressModifyWithIndex(index, isActive);
  };

  return (
    <div>
      <CustomBreadcrumb home={breadcumbHome} itemslist={breadcumbList} />
      <SaveModel />
      <Toast ref={toast}/>
      <form className="m-3">
        <TableActionsContainer>
          <div className=" hidden sm:hidden md:flex justify-content-between align-items-center gap-3 flex-wrap">
            <Text type={"heading"}>Customer Information</Text>
            <div className="flex gap-2">
              <CustomButton
                varient="text"
                label={"Cancel"}
                onClick={() => navigate("/customers/list")}
              />
              <CustomButton
                varient="outline"
                label={"Reset"}
                onClick={() => {
                  id ? setForm() : reset();
                }}
              />
              <CustomButton
                varient="filled"
                label={id ? "Update" : "Save"}
                onClick={handleSubmit((e) => {
                  setShowSave(true);
                })}
              />
            </div>
          </div>
          {/* ***** For Mobile Screens ***** */}
          <div className="hidden sm:flex md:hidden flex flex-column justify-content-between align-items-start gap-3 flex-wrap ">
            <div className="flex justify-content-start">
              <Text type={"heading"}>Customer Information</Text>
            </div>
            <div className="flex justify-content-end  w-full ">
              <div className="flex gap-3">
                <CustomButton
                  varient="text"
                  label={"Cancel"}
                  onClick={() => navigation("/customers/list")}
                />
                <CustomButton
                  varient="outline"
                  label={"Reset"}
                  onClick={() => reset()}
                />
                <CustomButton
                  varient="filled"
                  label={id ? "Update" : "Save"}
                  onClick={handleSubmit((e) => {
                    setShowSave(true);
                  })}
                />
              </div>
            </div>
          </div>
        </TableActionsContainer>
      </form>

      <form
        className="m-3 p-3 form-container"
        onChange={() => {
          setAlert(true);
        }}
      >
        <div className="flex gap-3 flex-column  flex-wrap">
          <div className="flex flex-column lg:flex-row  md:gap-2 ">
            <div className="flex gap-3 flex-column md:flex-row w-full lg:w-6 ">
              <div className="w-full md:w-6 ">
                <CustomInputText
                  label="Name *"
                  name="name"
                  control={control}
                  rules={{ required: "Customer name is required." }}
                  placeholer="Enter Name here"
                  onChangealert={true}
                />
                {getFormErrorMessage("name")}
              </div>
              <div className="w-full md:w-6  ">
                <div className="flex flex-column  flex-grow-1">
                  <label htmlFor="phone" className="pb-2">
                    Phone *
                  </label>
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      required: "phone number is required.",
                      validate: (value) =>
                        isValidPhoneNumber(value.toString()) ||
                        "Please enter a valid phone number. ",
                    }}
                    render={({ field, fieldState }) => (
                      <PhoneInputWithCountry
                        name="phone"
                        control={control}
                        defaultCountry="IN"
                        id={field.name}
                        placeholder="Enter phone number"
                        value={field.value}
                        onChange={(e) => {
                          setAlert(true);
                          field.onChange(e.value);
                        }}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        {...field}
                      />
                    )}
                  />
                  {getFormErrorMessage("phone")}
                </div>
              </div>
            </div>
            <div className="flex gap-3 flex-column md:flex-row w-full lg:w-6 ">
              <div className="w-full md:w-6 ">
                <CustomInputText
                  label="Email"
                  name="email"
                  control={control}
                  rules={{
                    required: false,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Enter a valid e-mail",
                    },
                  }}
                  maxLength={50}
                  placeholer="Enter your email here"
                  capitalize={false}
                  onChangealert={true}
                />
                {getFormErrorMessage("email")}
              </div>
              <div className="w-full md:w-6 ">
                <div className="flex justify-content-between align-items-center">
                  <label>Groups</label>
                  <i
                    className={`pi ${
                      editGroup ? "pi-times" : "pi-pencil"
                    } cursor-pointer`}
                    style={{ fontSize: "0.8rem" }}
                    onClick={() => setEditGroup((prev) => !prev)}
                  ></i>
                </div>

                {editGroup || mode !== "update" ? (
                  <CustomDropdown
                    options={availableGroups}
                    optionValue="id"
                    optionLabel="label"
                    name="groupId"
                    control={control}
                    placeholder="Choose"
                    onChangealert={true}
                  />
                ) : (
                  <div className="flex-1">
                    <div className="flex gap-1 mt-2 overflow-y-auto">
                      {selectedGroups?.map((grp) => (
                        <Chip id={grp?.id} label={grp?.label} />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex w-full  flex-row align-items-center flex-wrap gap-1 ">
            {!selectedCustomer?.addresses?.length && (
              <div className="w-full flex  md:w-6 lg:w-3  flex-column ">
                <div className="flex-1">Address</div>
                <div className="flex-1">
                  <CustomInputTextArea
                    label={null}
                    name="fullAddress0"
                    control={control}
                    cols="auto"
                    placeholer="Delivery Address"
                    onChangealert={true}
                  />
                </div>
              </div>
            )}

            {address?.length > 0 &&
              address
                .filter((x) => {
                  return x?.isActive;
                })
                ?.map((address, index) => {
                  return (
                    <div className=" flex flex-column  _expd" key={index}>
                      <div className="flex-1">Address</div>
                      <div className="relative">
                        <CustomInputTextArea
                          label={null}
                          name={`fullAddress${index}`}
                          control={control}
                          rows={6}
                          placeholer="Delivery Address"
                          onChangealert={true}
                          className="relative h-auto overflow-y-scroll"
                        />
                        {index > 0 && (
                          <Delete
                            className="pi pi-trash absolute cursor-pointer "
                            style={{ color: "black", top: 15, right: 10 }}
                            onClick={() =>
                              handleDeleteAddress(address.id, false)
                            }
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </form>
    </div>
  );
}

export default NewCustomer;
