import React, { useState, useEffect } from 'react';
import styles from "./index.module.css";
import { ReactComponent as RupeeSvg } from "../../../assets/rupeeWhite.svg";
import { ReactComponent as RupeeBSvg } from "../../../assets/rupeeBlack.svg";
import plansRightCheckSvg from "../../../assets/plansRightCheck1.jpg";

const PlanCarousalItem = ({ id, plan, time, planIndex, setPlansIndex, subscriptionPlans, activePlan }) => {
  let plandetails = subscriptionPlans[plan?.price[time?.value]?.databaseid];
  return (
    <div className={`${styles.basicPlancontainer} relative`} key={id} onClick={() => setPlansIndex(plan?.price[time?.value]?.databaseid)}>
      <div className={`${styles.PlanNameContainer}`}>
        <p className={`${styles.PlanName}`}>{plan?.name}</p>
      </div>
      <div className={`${styles.pricingContainer}`} style={{ background: planIndex === plan?.price[time?.value]?.databaseid ? plan?.color : '#ECEDF1', color: "#000" }}>
        <p className={`${styles.pricingdetails}`} style={{ color: planIndex === plan?.price[time?.value]?.databaseid ? '#fff' : '#000' }}>{plan?.description}</p>
        <div>
          <div className='flex align-items-center justify-content-center gap-1'>
            {planIndex === plan?.price[time?.value]?.databaseid ? <RupeeSvg className={`${styles.rupee}`} /> : <RupeeBSvg className={`${styles.rupee}`} />}
            <p className={`${styles.amount}`} style={{ color: planIndex === plan?.price[time?.value]?.databaseid ? '#fff' : '#000' }}>{plandetails?.amount}</p>
            <div>
            </div>
          </div>
          <p style={{ color: planIndex === plan?.price[time?.value]?.databaseid ? '#fff' : '#000' }} className='mt-2 mb-0'>&#x20B9; {plandetails?.monthlyPrice} per month basis</p>

        </div>

        {/* <p className={`${styles.pricingdetails}`} style={{ fontWeight: 800 }}>
          {plan.price[time?.value]?.time}<br />
        </p> */}
      </div>
      <div className={`${styles.conversationContainer}`}>
        <p className={`${styles.conversation}`}>Conversation charges: {plan?.charges}</p>
        <p className={`${styles.conversation}`}>{plan?.addonLicence}</p>
      </div>
      <div className='flex justify-content-center' style={{ marginTop: "3px" }}>
        <div style={{ background: "#D9D9D9", width: "85%", height: "1px" }}></div>
      </div>

      <div className={`${styles.serviceContainer}`}>
        <p className={`${styles.services} my-1`}>Services Included</p>
        {plan?.addon && <p>{plan?.addon}</p>}
        {plan?.features?.map((data, index) => (
          <div className='flex flex-row align-items-start gap-2 my-2' key={index}>
            <img src={plansRightCheckSvg} alt='plans' title='rightCheck' className={`${styles.plansCheck}`} />
            <p className={`${styles.servicesListItem}`}>{data}</p>
          </div>
        ))}
      </div>
      <div
        className={`${planIndex === plan?.price[time?.value]?.databaseid ? styles["plan-box-btn"] : styles["plan-box-btn-s"]} 
        px-2 absolute w-full flex align-items-center justify-content-center m-1 cursor-pointer`}
        style={{ bottom: "1.5rem" }}
        onClick={() => setPlansIndex(plan?.price[time?.value]?.databaseid)}
      >{planIndex === plan?.price[time?.value]?.databaseid ? (
        <div className='flex flex-row align-items-center justify-content-center w-5 m-auto p-2' style={{ background: '#005068', color: '#fff' }}>
          {" "}Selected&nbsp;Plan{" "}
        </div>
      ) : (
        <div className='flex flex-row align-items-center justify-content-center w-5 m-auto p-2' style={{ background: '#ECEDF1', color: '#000' }} >
          {" "}Select&nbsp;Plan{" "}
        </div>
      )}
      </div>
      <div className='opacity-0 w-2rem h-3rem'>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    </div>
  );
}

export default PlanCarousalItem;
