const CONSTANTS = {
  CATEGORY_SAVE_CONFIMATION: "You are about to save the category.",
  CATEGORY_DELETE_CONFIMATION: "Are you Sure, you want to delete category?",
  DELETE_CONFIRMATION: "Are you Sure, you want to delete.",
  CUSTOMER_SAVE_CONFIRMATION: "You are about to save the customer.",
  CUSTOMER_ADD_TO_GROUP_SAVE_CONFIRMATION: "You are about to add the customer in selected groups.",
  CUSTOMER_DELETE_CONFIMATION: "Are you Sure, you want to delete customer?",
  USER_CREATE_CONFIRMATION: "You are about to create the user.",
  PRODUCT_SAVE_CONFIMATION: "You are about to save the product.",
  PRODUCT_DELETE_CONFIMATION: "Are you Sure, you want to delete product?",
  GROUP_SAVE_CONFIRMATION: "You are about to save the group.",
  CAMPAIGN_SAVE_CONFIRMATION: "You are about to save campaign.",
  TEMPLATE_SAVE_CONFIRMATION: "You are about to save template.",
  CAMPAIGN_SHARE_CONFIRMATION: "You are about to Share Campaign!",
  TEMPLATE_DELETE_CONFIRMATION:
    "Are you Sure, You are about to delete Template.",
  TICKET_REASSIGN_CONFIRMATION:
    "Are you sure, You want to assign this ticket to {{value}}.",
  TICKET_STATUS_CONFIRMATION:
    "Are you sure, You want to change the status to {{value}}.",
  ORDER_STATUS_CONFIRMATION:
    "Are you sure, You want to change the order status to {{value}}.",
  SIGNOUT_CONFIRMATION: "Are you sure, You want to Log Out.",
  ENQ_CHAT_BROADCAST_MSG_HEADER: "Broadcast Only Chat",
  ENQ_CHAT_BROADCAST_MSG:
    "Please note that, until the user initiate a conversation with us, WhatsApp restricts us to sending only predefined template messages.",
  ENQ_CHAT_BROADCAST_CONFIRMATION:
    "Are you sure, You want to create a new ticket",
  ENQ_CHAT_TICKET_CONFIRMATION: "Are you sure, You want to create a new ticket",
  MEDIA_DELETE_CONFIRMATION: "Are you Sure, you want to delete this Image?",
  MEDIA_BULK_DELETE_CONFIRMATION:
    "Are you Sure, you want to delete {{value}} Image?",
  UPI: "Are you Sure, you want to delete this UPI ID?",
  BOT_EDIT_CONFIRMATION: "Are you Sure, you want to Edit this Bot Flow?",
  BOT_DELETE_CONFIRMATION: "Are you Sure, you want to delete this Bot Flow?",
  TICKET_CLOSED_CONFIRMATION: "Ticket is Closed",
};

export default CONSTANTS;
