function CampScheduleTime({ isHold = 1, nextRun = null }) {
  return (
    <>
      {nextRun != null ? (
        <div className="w-8rem">
          {!parseInt(isHold) ? (
            <div className="text-green-500">
              {`${nextRun?.days} Days `}
              &nbsp;
              {`${nextRun?.hours} hr `}
              &nbsp;
              {`${nextRun?.minutes} mins `}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default CampScheduleTime;
