import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_ADD_CUSTOMER,
  API_DELETE_CUSTOMER,
  API_GET_CUSTOMERS,
  API_PUT_CUSTOMER,
  API_GET_GROUPS,
  API_GET_GROUP_CUSTOMERS,
  API_CREATE_GROUP,
  API_PUT_GROUP,
  API_BULK_ADD_CUSTOMER,
  API_GET_CUSTOMERS_ID,
  API_GET_GROUP,
  API_GET_CUSTOMERS_SORT,
  API_BULK_DELETE_CUSTOMER,
} from "../api/customer.services";
import {
  removeDeleteData,
  updateTableData,
  sortAlphabeticalObjectArr,
  isCustomerInList,
  getUnselectedCust,
  selectElements,
} from "../utils/tableUtils";
import { getUnselectedItems, isItemInList } from "../utils/commonUtils";

const initialState = {
  loading: false,
  groupCustomerLoading: false,
  showCustomersType: "all",
  selectedGroupID: "all",
  groups: [],
  selectedGroup: { id: "all", groupName: "All Groups" },
  selectedCustomers: [],
  customerData: [],
  customersInSelectedGroup: [],
  totalGroupCount: 0,
  customerCountInGroup: 0,
  totalCustomerCount: 0,
  selectedCustomer: [],
  groupPage: 0,
  groupCustomerPage: 0,
  page: 0,
  limit: 30,
  spage: 0,
  slimit: 30,
  sgpage: 0,
  sglimit: 30,
  mode: null,
  chunkpage: 0,
};

export const getCustomers = createAsyncThunk(
  "customerTable/getCustomerList",
  async ({ page, limit, globalFilterValue }, thunkAPI) => {
    try {
      const customers = await API_GET_CUSTOMERS(page, limit, globalFilterValue);
      return customers;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCustomersinChunk = createAsyncThunk(
  "customerTable/getCustomerListinChunk",
  async ({ page, limit, globalFilterValue }, thunkAPI) => {
    try {
      const customers = await API_GET_CUSTOMERS(page, limit, globalFilterValue);
      return customers;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getSortedCustomersinChunk = createAsyncThunk(
  "customerTable/getSortedCustomerListinChunk",
  async ({ page, limit, globalFilterValue }, thunkAPI) => {
    try {
      const customers = await API_GET_CUSTOMERS_SORT(
        page,
        limit,
        globalFilterValue
      );
      return customers;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getsortedCustomers = createAsyncThunk(
  "customerTable/getSortedCustomerList",
  async ({ page, limit, globalFilterValue, customerIds = [] }, thunkAPI) => {
    try {
      const customers = await API_GET_CUSTOMERS_SORT(
        page,
        limit,
        globalFilterValue,
        customerIds
      );
      return customers;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCustomerbyid = createAsyncThunk(
  "customerTable/getCustomerbyid",
  async ({ id }, thunkAPI) => {
    try {
      const customer = await API_GET_CUSTOMERS_ID(id);
      return customer;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCustomerRole = createAsyncThunk(
  "customerTable/getCustomerList",
  async ({ page, limit }, thunkAPI) => {
    try {
      const customerRole = "pro"; //await API_GET_CUSTOMERS(page, limit);
      return customerRole;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const addCustomer = createAsyncThunk(
  "customerTable/addCustomer",
  async ({ data }, thunkAPI) => {
    try {
      const resp = await API_ADD_CUSTOMER(data);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "customerTable/updateCustomer",
  async (args, thunkAPI) => {
    try {
      const { customerId, data } = args;
      const resp = await API_PUT_CUSTOMER(customerId, data);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "customerTable/deleteCustomer",
  async (customerId, thunkAPI) => {
    try {
      await API_DELETE_CUSTOMER(customerId);
      return customerId;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createGroup = createAsyncThunk(
  "customers/group",
  async (data, thunkAPI) => {
    try {
      const resp = await API_CREATE_GROUP(data);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getGroups = createAsyncThunk(
  "customerTable/getGroups",
  async ({ page, limit, globalFilterValue }, thunkAPI) => {
    try {
      const groups = await API_GET_GROUPS(page, limit, globalFilterValue);
      return groups;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getCustomersInGroups = createAsyncThunk(
  "customerTable/getCustomersInGropus",
  async ({ id, page, limit }, thunkAPI) => {
    try {
      const customersInGroups = await API_GET_GROUP_CUSTOMERS(id, page, limit);
      return customersInGroups;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getGroup = createAsyncThunk(
  "customerTable/getGroup",
  async ({ id }, thunkAPI) => {
    try {
      const customersInGroups = await API_GET_GROUP(id);
      return customersInGroups;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateGroup = createAsyncThunk(
  "group/updateGroup",
  async ({ groupId, data }, thunkAPI) => {
    try {
      const updatedGroupData = await API_PUT_GROUP(groupId, data);
      return updatedGroupData;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const bulkAddCustomers = createAsyncThunk(
  "customer/bulkAdd",
  async (contacts, thunkAPI) => {
    try {
      const bulkUploadData = await API_BULK_ADD_CUSTOMER(contacts);
      return bulkUploadData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const bulkDeleteCustomer = createAsyncThunk(
  "customerTable/bulkDeleteCustomer",
  async (customerIds, thunkAPI) => {
    try {
      await API_BULK_DELETE_CUSTOMER(customerIds);
      return customerIds;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);



const customerTableSlice = createSlice({
  name: "customerTable",
  initialState,
  reducers: {
    changeMode(state, action) {
      state.mode = action.payload;
    },

    resetMode(state) {
      state.mode = null;
    },
    changeSelectedGroupId(state, action) {
      if (action.payload == "all") {
        state.selectedGroup = { id: "all", groupName: "All Groups" };
        state.customersInSelectedGroup = [];
      } else {
        const group = state.groups.filter((grp) => grp.id == action.payload);
        state.selectedGroup = group[0];
      }
      state.selectedGroupID = action.payload;
    },
    changeSelectedCustomer(state, action) {
      state.selectedCustomer = action.payload;
    },
    resetSelectedCustomer(state) {
      state.selectedCustomer = [];
    },
    resetPagination(state){
      state.page = initialState.page;
      state.limit = initialState.limit;
    },
    changePage(state, action) {
      state.page = action.payload;
    },
    schangePage(state, action) {
      state.spage = action.payload;
    },
    sgchangePage(state, action) {
      state.sgpage = action.payload;
    },
    schangelimit(state, action) {
      state.slimit = action.payload;
    },
    sgchangelimit(state, action) {
      state.sglimit = action.payload;
    },
    changeShowCustomersType(state, action) {
      const customerType = action.payload;
      if (customerType == "all") {
        state.selectedGroupID = "all";
        state.customersInSelectedGroup = [];
        state.selectedGroup = { id: "all", groupName: "All Groups" };
      }
      state.showCustomersType = action.payload;
    },
    changeSelectedGroup(state, action) {
      state.selectedGroupID = action.payload.id;
      state.selectedGroup = action.payload;
    },
    resetSelectedGroup(state) {
      state.selectedGroupID = "all";
      state.selectedGroup = { id: "all", groupName: "All Groups" };
    },
    changeGroupPage(state, action) {
      state.groupPage = action.payload;
    },
    changeGroupCustomerPage(state, action) {
      state.groupCustomerPage = action.payload;
    },
    setSelectedGroupIdToNull(state) {
      state.selectedGroupID = null;
    },
    setChunkpage(state, action) {
      if (action.payload * 100 <= state.totalCustomerCount + 100) {
        state.chunkpage = action.payload;
      }
    },
    changelimit(state, action) {
      state.limit = action.payload;
      state.chunkpage = 0;
    },
    updatecustomersInSelectedGroup(state, action) {
      const { selectedCust, currCust } = action.payload;
      state.customersInSelectedGroup = selectElements(
        state.customersInSelectedGroup,
        selectedCust,
        currCust
      );
    },
    updatecustomers(state, action) {
      const { selectedCust } = action.payload;
      state.selectedCustomer = selectedCust;
    },
    changeSelectedCustomers(state, { payload }) {
      const { selectedData, customerData } = payload;
      if (state.selectedCustomers.length == 0) {
        state.selectedCustomers = selectedData;
      } else {
        const newItems = selectedData.filter((customer) => {
          if (!isItemInList(state.selectedCustomers, customer)) return customer;
        });
        const unselectedItems = getUnselectedItems(customerData, selectedData);

        const onlySelectedItems = state.selectedCustomers.filter((customer) => {
          if (!isItemInList(unselectedItems, customer)) return customer;
        });
        state.selectedCustomers = [...onlySelectedItems, ...newItems];
      }
    },
    reseSelectedCustomers(state) {
      state.selectedCustomers = [];
      state.customersInSelectedGroup=[];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCustomers.fulfilled, (state, action) => {
      const { count, rows } = action.payload;
      state.totalCustomerCount = count;
      state.customerData = rows;
      //state.customerData = [...sortAlphabeticalObjectArr([...rows],"customerCode").reverse()];
      state.loading = false;
    });
    builder.addCase(getCustomers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCustomers.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getCustomersinChunk.fulfilled, (state, action) => {
      const { count, rows } = action.payload;
      if ((action.page = 0)) {
        state.totalCustomerCount = count;
        state.customerData = rows;
      } else {
        state.customerData = [...state.customerData, ...rows];
      }
      state.loading = false;
    });
    builder.addCase(getCustomersinChunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCustomersinChunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSortedCustomersinChunk.fulfilled, (state, action) => {
      const { count, rows } = action.payload;
      if ((action.page = 0)) {
        state.totalCustomerCount = count;
        state.customerData = rows;
      } else {
        state.customerData = [...state.customerData, ...rows];
      }
      state.loading = false;
    });
    builder.addCase(getSortedCustomersinChunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSortedCustomersinChunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getsortedCustomers.fulfilled, (state, action) => {
      const { count, rows } = action.payload;
      state.totalCustomerCount = count;
      state.customerData = rows;
      state.loading = false;
    });
    builder.addCase(getsortedCustomers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getsortedCustomers.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCustomerbyid.fulfilled, (state, action) => {
      let addresses = action.payload.addresses.sort((a,b)=> (
        a.isDefault < b.isDefault ? 1 : b.isDefault < a.isDefault ? -1 : 0
      ))
      state.selectedCustomer = action.payload;
      state.loading = false;
    });
    builder.addCase(getCustomerbyid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCustomerbyid.rejected, (state) => {
      state.loading = false;
    });

    //add customer
    builder.addCase(addCustomer.fulfilled, (state, action) => {
      let data = action.payload;
      if (state.customerData.length < state.limit) {
        state.customerData = [data, ...state.customerData];
      } else {
        state.customerData = [
          data,
          ...state.customerData.slice(0, state.limit - 1),
        ];
      }
      state.totalCustomerCount += 1;
      state.loading = false;
    });
    builder.addCase(addCustomer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCustomer.rejected, (state) => {
      state.loading = false;
    });

    //update customer
    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      state.customerData = updateTableData(state.customerData, action.payload);
      state.loading = false;
    });
    builder.addCase(updateCustomer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCustomer.rejected, (state) => {
      state.loading = false;
    });

    //delete customer
    builder.addCase(deleteCustomer.fulfilled, (state, action) => {
      state.customerData = removeDeleteData(state.customerData, action.payload);
      state.totalCustomerCount -= 1;
      state.loading = false;
    });
    builder.addCase(deleteCustomer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCustomer.rejected, (state) => {
      state.loading = false;
    });

    //get all groups
    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.totalGroupCount = action.payload.count;
      state.groups = action.payload.rows;
      state.loading = false;
    });
    builder.addCase(getGroups.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGroups.rejected, (state) => {
      state.loading = false;
    });

    //add group
    builder.addCase(createGroup.fulfilled, (state, action) => {
      let data = action.payload;
      if (state.groups.length < state.limit) {
        state.groups = [data, ...state.groups];
      } else {
        state.groups = [data, ...state.groups.slice(0, state.limit - 1)];
      }
      state.totalGroupCount += 1;
      state.loading = false;
    });
    builder.addCase(createGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createGroup.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCustomersInGroups.fulfilled, (state, action) => {
      state.customerCountInGroup = action.payload.count;
      state.customersInSelectedGroup = action.payload.rows;
      state.groupCustomerLoading = false;
    });
    builder.addCase(getCustomersInGroups.pending, (state) => {
      state.groupCustomerLoading = true;
    });
    builder.addCase(getCustomersInGroups.rejected, (state) => {
      state.groupCustomerLoading = false;
    });

    builder.addCase(getGroup.fulfilled, (state, action) => {
      state.selectedGroup = action.payload;
      state.groupCustomerLoading = false;
    });
    builder.addCase(getGroup.pending, (state) => {
      state.groupCustomerLoading = true;
    });
    builder.addCase(getGroup.rejected, (state) => {
      state.groupCustomerLoading = false;
    });

    builder.addCase(updateGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.groups = updateTableData(state.groups, action.payload);
      state.selectedGroup = action.payload;
    });
    builder.addCase(updateGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateGroup.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(bulkAddCustomers.fulfilled, (state, action) => {
      // let newAddedCustomers = action.payload;
      // let prevData = [...state.customerData];
      // let mergedCustomers = [...newAddedCustomers, ...prevData];
      // if (mergedCustomers.length < state.limit) {
      //   state.customerData = [...mergedCustomers];
      // } else {
      //   state.customerData = mergedCustomers.slice(0, state.limit);
      // }
      // state.totalCustomerCount += newAddedCustomers.length;
      state.loading = false;
    });
    builder.addCase(bulkAddCustomers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(bulkAddCustomers.rejected, (state) => {
      state.loading = false;
    });
    //bulk delete customer
    builder.addCase(bulkDeleteCustomer.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.customerData = state.customerData.filter(
        (customer) => !payload?.includes(customer.id)
      );
      state.totalCustomerCount -= payload.length;
      state.selectedCustomers = [];
    });
    builder.addCase(bulkDeleteCustomer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(bulkDeleteCustomer.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  changeMode,
  resetMode,
  changeSelectedCustomer,
  resetSelectedCustomer,
  changePage,
  changeGroupCustomerPage,
  changeGroupPage,
  changeShowCustomersType,
  changeSelectedGroup,
  resetSelectedGroup,
  changeSelectedGroupId,
  setSelectedGroupIdToNull,
  changelimit,
  schangePage,
  sgchangePage,
  schangelimit,
  sgchangelimit,
  setChunkpage,
  updatecustomersInSelectedGroup,
  updatecustomers,
  changeSelectedCustomers,
  reseSelectedCustomers,
  resetPagination,
} = customerTableSlice.actions;

export default customerTableSlice.reducer;
