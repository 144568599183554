import "./index.css";
import { Paginator } from "primereact/paginator";
import { useEffect, useState } from "react";
import { classNames } from "primereact/utils";
import { Ripple } from "primereact/ripple";
import { useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
const CustomPaginator = ({
  page,
  limit,
  changelimit,
  totalRecords,
  changePage,
}) => {
  const [basicFirst, setBasicFirst] = useState(0);
  const dispatch = useDispatch();
  const handlePageChange = (event) => {
    setBasicFirst(event.first);
    dispatch(changePage(event.page));
    dispatch(changelimit(event.rows));
  };
  const customTemplate = {
    layout:
      "RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 30, value: 30 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
        { label: "All", value: options.totalRecords },
      ];

      return (
        <div className="flex-nowrap flex align-items-center lft-resp">
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </div>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <>
          <span
            className=" res-mar"
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              textAlign: "center",
            }}
          >
            {options.first} - {options.last} of {options.totalRecords}
          </span>
          <div className="empty">.....</div>
        </>
      );
    },
    FirstPageLink: (options) => {
      return (
        <button
          type="button"
          className={`${options.className}`}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <i className="pi pi-angle-double-left"></i>
          <Ripple />
        </button>
      );
    },
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <i className="pi pi-angle-left"></i>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <i className="pi pi-angle-right"></i>
          <Ripple />
        </button>
      );
    },
    LastPageLink: (options) => {
      return (
        <button
          type="button"
          className={`${options.className}`}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <i className="pi pi-angle-double-right"></i>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span className={className} style={{ userSelect: "none" }}>
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  useEffect(() => {
    setBasicFirst(page * limit);
  }, []);
  return (
    <Paginator
      template={customTemplate}
      pageLinkSize={5}
      first={basicFirst}
      rows={limit}
      rowsPerPageOptions={[10, 20, 30]}
      totalRecords={totalRecords}
      onPageChange={handlePageChange}
      className="custom-paginator respon-dp p-0"
    />
  );
};

export default CustomPaginator;
