import { configureStore } from "@reduxjs/toolkit";
import categoryTableSlice from "./reducers/categoryTableSlice";
import customerTableSlice from "./reducers/customerTableSlice";
import enquiryTabelSlice from "./reducers/enquiryTabelSlice";
import orderTableSlice from "./reducers/orderTableSlice";
import productTableSlice from "./reducers/productTableSlice";
import authSlice from "./reducers/authSlice";
import appSlice from "./reducers/appSlice";
import dashboardSlice from "./reducers/dashboardSlice";
import paymentHistorySlice from "./reducers/paymentHistorySlice";
import campaignSlice from "./reducers/campaignSlice";
import analyticSlice from "./reducers/analyticSlice";
import companyslice from "./reducers/companySlice";
import paymentSlice from "./reducers/paymentSlice";
import templateSlice from "./reducers/templateReducer/templateSlice";
import messageSlice from "./reducers/ShareMessage/messageSlice";
import enqChatSlice from "./reducers/enqChatSlice";
import accountSlice from "./reducers/accountSlice";
import ticketTabelSlice from "./reducers/ticketTabelSlice";
import usageTabelSlice from "./reducers/usageTabelSlice";
import mediaTableSlice from "./reducers/mediaTabelSlice";
import multiInputSlice from "./reducers/multiInputSlice";
import canvasSlice from "./reducers/canvas/canvasSlice";
import notificationSlice from "./reducers/notificationSlice";
import aiSlice from './reducers/aiSlice'
const store = configureStore({
  reducer: {
    productTable: productTableSlice,
    categoryTable: categoryTableSlice,
    orderTable: orderTableSlice,
    enquiryTable: enquiryTabelSlice,
    customerTable: customerTableSlice,
    ticketTable: ticketTabelSlice,
    authenticate: authSlice,
    application: appSlice,
    dashboard: dashboardSlice,
    paymentHistory: paymentHistorySlice,
    campaign: campaignSlice,
    analytic: analyticSlice,
    company: companyslice,
    payment: paymentSlice,
    template: templateSlice,
    shareMessage: messageSlice,
    enqChats: enqChatSlice,
    account: accountSlice,
    media: mediaTableSlice,
    notification: notificationSlice,
    multiInput: multiInputSlice,
    canvas: canvasSlice,
    ai:aiSlice,
    usageTable:usageTabelSlice,
  },
});

export default store;
