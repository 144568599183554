import React, { useContext, useState, useEffect, useRef } from "react";
import { API_GET_PRODUCTS } from "../../api/product.services";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTicketID,
  setIsProductSelected,
  updateUserStatus,
} from "../../reducers/enqChatSlice";
import {
  API_GET_IMG_URL,
  API_SEND_MSG,
  API_START_CHAT,
  API_UPLOAD_IMG,
} from "../../api/chat.service";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { getCategories } from "../../reducers/categoryTableSlice";
import { API_GET_CATEGORIES } from "../../api/category.services";
import { Dropdown } from "primereact/dropdown";
import { Text } from "../../components/Text";
import { ReactComponent as SendButton } from "../../assets/svgIcons/svgIcons/sendbuttonicon.svg";
import { Skeleton } from "primereact/skeleton";
import CustomSaveModel from "../../components/CustomeSaveModel";
import CONSTANTS from "../../config/Constants";
import { CustomButton } from "../../components/CustomButton";

const Products = () => {
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const globalFilterValue = useRef("");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.enqChats);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryData, setCategoryData] = useState([]);

  const closedTicketStatus = user?.ticketStatus === "Closed";

  const [showSave, setShowSave] = useState(false);
  const SaveModel = () => {
    return (
      <CustomSaveModel
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };
  const alertText = () => {
    return <div>{CONSTANTS.ENQ_CHAT_TICKET_CONFIRMATION}</div>;
  };
  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false);
          }}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={() => {
            handleSend();
            setShowSave(false);
          }}
        />
      </div>
    );
  };
  const onHide = () => {
    setShowSave(false);
  };

  useEffect(() => {
    //console.log(selectedCategory)
    globalFilterValue.current = "";
    getCategory();

    if (selectedCategory) {
      getProducts(
        [
          {
            key: "categoryId",
            value: selectedCategory?.id,
          },
        ],
        globalFilterValue.current
      );
    } else {
      getProducts([], globalFilterValue.current);
    }
  }, [selectedCategory]);

  const getCategory = async () => {
    const category = await API_GET_CATEGORIES(0, 100000);
    //console.log(category)
    setCategoryData(category.rows);
  };

  const getProducts = async (filterData, globalSearch) => {
    try {
      setLoading(true);
      const __products = await API_GET_PRODUCTS(
        0,
        100000,
        filterData,
        globalSearch
      );
      __products.rows.forEach((prod) => {
        prod["checked"] = false;
      });
      setProducts(__products.rows);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  function handelCancelClick() {
    dispatch(setIsProductSelected(false));
  }

  const imageBodyTemplate = (rowData) => {
    return (
      <div
        className="flex justify-content-center"
        style={{ width: "120px", height: "80px" }}
      >
        <img
          src={`${rowData.url}?v=${rowData.updatedAt}`}
          onError={(e) => (e.target.src = "./images/ImgPlaceholder.svg")}
          style={{ maxWidth: "100%", height: "100%" }}
        />
      </div>
    );
  };

  function handleSend() {
    //console.log(products)
    try {
      products?.forEach(async (prod) => {
       // console.log(prod)
        if (prod.checked) {
          const data = {
            payload: {
              type: "image",
              originalUrl: prod.url ? prod.url : prod.images[0].url,
              previewUrl: prod.url ? prod.url : prod.images[0].url,
              caption: prod.productName + ` , price : ${prod.price}₹`,
            },
          };
          if (closedTicketStatus) {
            data.customerMobile = user?.mob;
          } else {
            data.destinationNo = user?.mob;
          }
          const resp2 = !closedTicketStatus
            ? await API_SEND_MSG(user?.ticketId, data).then(() => {
                dispatch(setIsProductSelected(false));
              })
            : await API_START_CHAT(data).then((e) => {
                dispatch(changeTicketID(e.data.id));
                dispatch(
                  updateUserStatus({ ticketStatus: e.data.ticketStatus })
                );
                dispatch(setIsProductSelected(false));
              });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  function OnSelectProduct(prod) {
    prod["checked"] = !prod?.checked;
  }

  //Search-----------------------
  const [isSearch, setIsSearch] = useState(true);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    setGlobalFilterValue1(value);
    globalFilterValue.current = value;
    setIsSearch(true);
    if (globalFilterValue.current === "") {
    }
  };
  const onGlobalFilterClick = (e) => {
    if (globalFilterValue.current) {
      if (isSearch) {
        getProducts([], globalFilterValue.current);
        setIsSearch(false);
      } else {
        globalFilterValue.current = "";
        getProducts([], globalFilterValue.current);
        setIsSearch(true);
      }
    }
  };

  function handelKeyDown(e) {
    if (e.key === "Enter") {
      onGlobalFilterClick();
    }
  }
  const renderHeader1 = () => {
    return (
      <div className="flex flex-column lg:flex-row justify-content-between align-items-center">
        <div className="flex flex-row-reverse md:flex-row gap-1 w-12 md:w-8 ">
          <div className="__cancel ">
            <button onClick={handelCancelClick}>
              <i className="pi pi-times"></i>
            </button>
          </div>
          <Dropdown
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.value)}
            options={categoryData}
            optionLabel="categoryName"
            showClear
            placeholder="Select a category"
            className="w-12  "
          />
        </div>
        <div className="flex gap-1 mt-2 lg:mt-0 w-12 md:w-8">
          <InputText
            ref={globalFilterValue}
            value={globalFilterValue.current}
            onChange={onGlobalFilterChange1}
            placeholder="Search by product name"
            className="w-12 md:ml-2"
            onKeyPress={handelKeyDown}
          />
          <Button
            type="button"
            icon={isSearch ? "pi pi-search" : "pi pi-times"}
            style={{
              backgroundColor: "#ededed",
              color: "black",
              fontWeight: "200",
              width: "100%",
            }}
            className="border-300 h-2rem"
            onClick={onGlobalFilterClick}
          />
        </div>
      </div>
    );
  };

  function ProductsComp(params) {
    if (products && products.length === 0)
      return <Text type={"sub-heading"}>No product found!</Text>;
    if (loading) return <Skeleton width="100%" height="100%" />;
    return (
      <div className="flex flex-wrap w-11 " >
        {products?.map((product, index) => {
          return (
            <div className="image-grid-div relative border-1 "   key={index}>
              <div className="__imageInfo ">
                <input
                  className="largerCheckbox"
                  type="checkbox"
                  onChange={() => OnSelectProduct(product)}
                />
                <div className="__productInfo">
                  <Text type={"heading"} cust="text-overflow-ellipsis">
                    {product?.productName}
                  </Text>
                  <Text type={"small-text"} >Status : {product?.status}</Text>
                  <Text type={"small-text"}>Price : {product?.price}</Text>
                  <Text type={"small-text"}  >Category : {product?.categoryName} </Text>
                </div>
              </div>
              {/* <div className="__imageDiv">
                <img className="__image" src={product?.images?.url}  alt="products"  />
              </div> */}
              <div className="__imageDiv">
              {product?.images && product.images.length > 0 && (
                <img
                 
                  className="__image"
                  src={product.images[0]?.url} 
                  alt={`Product ${index + 1}`}
                />
              )}
            </div>

            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="__productComponenet ">
      <SaveModel />
      <div className="___Container px-2">
        {renderHeader1()}
        <div className="__productBox mt-2 flex justify-content-center ">
          <ProductsComp />
        </div>
        <div className="flex justify-content-between align-items-center mt-3">
          <Text type="sub-heading">
            <span className="capitalize custom-background">
              {user?.displayName || user?.mob}
            </span>
          </Text>
          <div className="sendbutton">
            <button
              onClick={
                closedTicketStatus ? () => setShowSave(true) : handleSend
              }
            >
              <SendButton />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
