import { useEffect, useRef, useState } from "react";
import { ReactComponent as QuickNoteIcon } from "../../../assets/svgIcons/Notes_logo.svg";

import { OverlayPanel } from "primereact/overlaypanel";
import { CustomButton } from "../../../components/CustomButton";
// import { useDispatch, useSelector } from "react-redux";
import { Text } from "../../../components/Text";
// import { changeQNotepage, getQuickNotes } from "../../../reducers/accountSlice";
import EllipsisText from "../../../components/common/EllipsisText/indix";
// import { API_GET_QUICK_NOTES } from "../../../api/account.service";
import { useDispatch, useSelector } from "react-redux";
function QuickNotes({ handleClick }) {
  // const [page, setPage] = useState(0);
  // const [count, setCount] = useState(0);
  // const [quickNotes, setQuickNotes] = useState([]);
  const op = useRef(null);
  const dispatch = useDispatch();
  const { quickNotes, quickNotesCounts, qNotePage, qNoteLimit } = useSelector(
    (state) => state.account
  );
  const scrollRef = useRef();

  // const handleScroll = () => {
  //   if (scrollRef.current && !loading) {
  //     const { scrollTop, clientHeight, scrollHeight } = scrollRef.current;

  //     const tolerance = 1; // to handle decimal values of scrollTop

  //     if (scrollTop + clientHeight + tolerance >= scrollHeight) {
  //       const previousScrollTop = scrollRef.current.scrollTop;
  //       //dispatch(changeQNotepage(qNotePage + 1));
  //       if (count > quickNotes.length) {
  //         setPage((prev) => prev + 1);
  //         scrollRef.current.scrollTop = previousScrollTop;
  //       } else return;
  //     }
  //   }
  // };
  return (
    <div className="card flex justify-content-center">
      <CustomButton
        type="button"
        varient="text"
        icon={<QuickNoteIcon />}
        onClick={(e) => op.current.toggle(e)}
      />
      <OverlayPanel ref={op} className="w-9 m-auto lg:w-3">
        <div
          style={{ maxHeight: "500px", overflowY: "auto" }}
          ref={scrollRef}
          // onScroll={handleScroll}
          className="w-full"
        >
          <div className="w-full h-full">
            {quickNotes?.map((item) => (
              <div
                key={item.id}
                onClick={(e) => {
                  handleClick(item.notes);
                  op.current.toggle(e);
                }}
                className="mb-3 cursor-pointer"
              >
                <Text type={"heading"}>{item?.title}</Text>
                <Text type={"small-text"}>
                  <EllipsisText text={item?.notes} />
                </Text>
              </div>
            ))}
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
}

export default QuickNotes;
