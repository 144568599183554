import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { applyNodeChanges, applyEdgeChanges, addEdge } from "reactflow";
import {
  API_ADD_BOT_FLOW_STEPS,
  API_DELETE_BOT_FLOW,
  API_GET_ALL_BOT_FLOWS,
  API_GET_BOT_FLOW_SCHEMA,
  API_UPDATE_BOT_FLOW_SCHEMA,
  API_UPDATE_BOT_FLOW,
  API_ADD_BOTFLOW,
} from "../../api/botFlow.service";
import {
  BOTFLOW_EDIT_KEY,
  interactiveNodes,
} from "../../view/Bots/BotBuilder/node-constant";
import { BOT_MODES } from "../../view/Bots/bot-constants";

const initialState = {
  botFlows: [],
  page: 0,
  limit: 30,
  selectedBotFlowId: null,
  totalbotflowCount: 0,
  nodes: [],
  edges: [],
  selectedNode: null,
  quickReplyNode: {},
  listNode: {},
  rootStepId: null,
  botFlowExists: false,
  loading: false,
  configScreenOpen: false,
  editNodeCache: null,
  isEditable: true,
  deletedOptionNodeEdgeCache: [],
  mode: BOT_MODES.NEW,
};

export const addBotFlowSteps = createAsyncThunk(
  "canvas/addBotFlow",
  async ({ id, botFlowSteps }, thunkAPI) => {
    try {
      const resp = await API_ADD_BOT_FLOW_STEPS(id, botFlowSteps);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getBotFlow = createAsyncThunk(
  "canvas/getBotFlow",
  async (id, thunkAPI) => {
    try {
      const resp = await API_GET_BOT_FLOW_SCHEMA(id);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateBotFlow = createAsyncThunk(
  "canvas/updateBotFlow",
  async ({ id, botFlowData }, thunkAPI) => {
    try {
      const data = await API_UPDATE_BOT_FLOW_SCHEMA(botFlowData, id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getAllBotFlow = createAsyncThunk(
  "canvas/getAllBotFlow",
  async ({ page, limit }, thunkAPI) => {
    try {
      const data = await API_GET_ALL_BOT_FLOWS(page, limit);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteBotFlow = createAsyncThunk(
  "canvas/deleteBotFlow",
  async (id, thunkAPI) => {
    try {
      const data = await API_DELETE_BOT_FLOW(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateBotflowDetails = createAsyncThunk(
  "canvas/updateBotflowDetails",
  async ({ id, updatedData }, thunkAPI) => {
    try {
      const data = await API_UPDATE_BOT_FLOW(id, updatedData);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const addNewBotFlow = createAsyncThunk(
  "canvas/addNewBotflow",
  async (botflowPayload, thunkAPI) => {
    try {
      const data = await API_ADD_BOTFLOW(botflowPayload);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const flowSlice = createSlice({
  name: "flow",
  initialState,
  reducers: {
    changeBotflowPage(state, { payload }) {
      state.page = payload;
    },
    changeBotflowLimit(state, { payload }) {
      state.limit = payload;
    },
    changeSelectectBotFlow(state, { payload }) {
      state.selectedBotFlowId = payload;
    },
    openConfigScreen(state, { payload }) {
      state.editNodeCache = payload;
      state.selectedNode = payload;
      state.configScreenOpen = true;
    },
    closeConfigScreen(state) {
      state.deletedOptionNodeEdgeCache = [];
      state.configScreenOpen = false;
      state.editNodeCache = null;
    },
    undoNodeData(state) {
      if (state.editNodeCache !== null) {
        state.nodes = state.nodes?.map((node) => {
          if (node.id == state.editNodeCache.id) return state.editNodeCache;
          else return node;
        });
        if (["list", "quick_reply"].includes(state.editNodeCache?.type)) {
          state.edges = [...state.edges, ...state.deletedOptionNodeEdgeCache];
        }
      } else {
        //no action
      }
    },
    setNodes(state, { payload }) {
      state.nodes = applyNodeChanges(payload, state.nodes);
    },
    setEdges(state, action) {
      state.edges = applyEdgeChanges(action.payload, state.edges);
    },
    addNodeToStore(state, { payload }) {
      state.nodes = [...state.nodes, payload];
    },
    addEdgeToStore(state, action) {
      return {
        ...state,
        edges: [...state.edges, action.payload],
      };
    },
    changeSelectedNode(state, action) {
      state.selectedNode = action.payload;
    },
    changeQuickReplyNode(state, action) {
      state.quickReplyNode = action.payload;
    },

    updateTextNode(state, action) {
      const { nodeId, label } = action.payload;
      const nodeToUpdate = state.nodes.find((node) => node.id === nodeId);
      if (nodeToUpdate) {
        nodeToUpdate.data = {
          ...nodeToUpdate.data,
          label,
        };
      }
    },
    deleteNode(state, action) {
      const { nodeId } = action.payload;
      state.nodes = state.nodes?.filter((node) => node.id !== nodeId);

      if (state.nodes.length === 0) state.rootStepId = null;
    },
    deleteConnectedNodes(state, { payload }) {
      const deleteNode = payload.deleteNode;

      if (deleteNode.id === state.rootStepId) {
        let edgeConnectedToRoot = state.edges.find(
          (edge) => edge.source === state.rootStepId
        );
        state.rootStepId = edgeConnectedToRoot?.target;
      }

      let sourceNodeIdList = [];
      let newEdgesList = [];

      const currentEdges = [...state.edges];
      for (const edge of currentEdges) {
        if (edge.target == deleteNode.id) {
          sourceNodeIdList.push(edge.source);
        }

        if (edge.source != deleteNode.id && edge.target != deleteNode.id) {
          newEdgesList.push(edge);
        }
      }

      let updatedInteractiveNodes = {};

      for (const nodeId of sourceNodeIdList) {
        let sourceNode = state.nodes.find((node) => node.id == nodeId);
        if (["quick_reply", "list", "template"].includes(sourceNode.type)) {
          sourceNode = {
            ...sourceNode,
            data: {
              ...sourceNode.data,
              options: sourceNode.data?.options?.map((opt) => {
                let postbackId = opt.postbackText.split("=")[1] || "";
                if (postbackId == deleteNode.id) {
                  opt.postbackText = "";
                  return opt;
                } else return opt;
              }),
            },
          };
          updatedInteractiveNodes[sourceNode.id] = sourceNode;
        }
      }

      let newNodeList = [];
      for (const node of state.nodes) {
        if (node.id != deleteNode.id) {
          if (Object.keys(updatedInteractiveNodes)?.includes(node.id)) {
            newNodeList.push(updatedInteractiveNodes[node.id]);
          } else {
            newNodeList.push(node);
          }
        } else {
          // skip
        }
      }
      state.nodes = newNodeList;
      state.edges = newEdgesList;

      state.selectedNode = null;
      state.editNodeCache = null;
      state.configScreenOpen = false;
      if (newNodeList.length === 0) state.rootStepId = null;
    },
    deleteEdge(state, action) {
      const { edgeId } = action.payload;
      let deleteEdge = null;
      let updatedNode = null;

      let currentEdges = [...state.edges];
      const newEdges = currentEdges.filter((edge) => {
        if (edge.id !== edgeId) {
          return edge;
        } else {
          deleteEdge = { ...edge };
        }
      });
      state.edges = [...newEdges];

      if (deleteEdge && deleteEdge?.optionId) {
        const { optionId, source, target } = deleteEdge;
        const currNodes = [...state.nodes];
        const updatedNodeList = currNodes?.map((node) => {
          if (
            node.id == source &&
            interactiveNodes.includes(node.type) &&
            node.data?.options
          ) {
            updatedNode = {
              ...node,
              data: {
                ...node.data,
                options: node.data.options?.map((opt) => {
                  if (
                    opt.id == optionId ||
                    (opt.postbackText &&
                      opt.postbackText.split("=")[1] == target)
                  ) {
                    return {
                      ...opt,
                      postbackText: "",
                    };
                  } else return opt;
                }),
              },
            };
            return updatedNode;
          } else {
            return node;
          }
        });
        state.nodes = [...updatedNodeList];
      }

      if (updatedNode?.id == state.selectedNode?.id) {
        state.selectedNode = updatedNode;
      }
    },
    layoutNodes(state, action) {
      state.nodes = [...action.payload];
    },
    layoutEdges(state, action) {
      state.edges = [...action.payload];
    },
    changeRootStepId(state, action) {
      state.rootStepId = action.payload;
    },
    deleteNodeData(state, action) {
      const { nodeId, propertyToDelete } = action.payload;

      let updatedSelectedNode = state.selectedNode;

      if (updatedSelectedNode && updatedSelectedNode.id === nodeId) {
        const updatedData = { ...updatedSelectedNode.data };
        delete updatedData[propertyToDelete];

        updatedSelectedNode = {
          ...updatedSelectedNode,
          data: updatedData,
        };
      }

      const nodes = state.nodes.map((node) =>
        node.id === nodeId
          ? {
              ...node,
              data: Object.fromEntries(
                Object.entries(node.data).filter(
                  ([key]) => key !== propertyToDelete
                )
              ),
            }
          : node
      );

      return {
        ...state,
        nodes,
        selectedNode: updatedSelectedNode,
      };
    },

    addNewOption(state, { payload }) {
      let { selectedNodeId, newOption } = payload;

      let updatedNode = null;

      state.nodes = state.nodes?.map((node) => {
        if (node.id == selectedNodeId) {
          updatedNode = {
            ...node,
            data: {
              ...node.data,
              options: [...node.data.options, newOption],
            },
          };
          return updatedNode;
        } else return node;
      });

      if (updatedNode) {
        state.selectedNode = updatedNode;
      }
    },

    updateNodePayload(state, { payload }) {
      let { selectedNodeId, updatedData } = payload;
      state.selectedNode = {
        ...state.selectedNode,
        data: { ...state.selectedNode.data, ...updatedData },
      };

      state.nodes = state.nodes?.map((node) => {
        if (node.id == selectedNodeId) {
          return {
            ...node,
            data: { ...node.data, ...updatedData },
          };
        } else return node;
      });
    },

    updateNodeData(state, action) {
      const { nodeId, updatedData } = action.payload;
      return {
        ...state,
        nodes: state.nodes.map((node) =>
          node.id === nodeId ? { ...node, ...updatedData } : node
        ),
      };
    },

    updateOptionTitle(state, { payload }) {
      let { selectedNodeId, optionId, optionTitle } = payload;
      let { options } = state.selectedNode.data;

      options = options?.map((option) => {
        if (option.id == optionId) return { ...option, title: optionTitle };
        else return option;
      });

      state.selectedNode = {
        ...state.selectedNode,
        data: { ...state.selectedNode.data, options },
      };
      let updatedNode = null;
      state.nodes = state.nodes?.map((node) => {
        if (node.id == selectedNodeId) {
          updatedNode = {
            ...node,
            data: {
              ...node.data,
              options: node.data.options?.map((option) => {
                if (option.id == optionId) {
                  return { ...option, title: optionTitle };
                } else return option;
              }),
            },
          };

          return updatedNode;
        } else return node;
      });

      state.edges = state.edges?.map((edge) => {
        if (edge.optionId == optionId) {
          return { ...edge, label: optionTitle };
        } else return edge;
      });
    },

    updatePostbackText(state, { payload }) {
      let { selectedNodeId, optionId, targetNodeId } = payload;

      let updatedNode = null;
      state.nodes = state.nodes?.map((node) => {
        if (node.id == selectedNodeId) {
          updatedNode = {
            ...node,
            data: {
              ...node.data,
              options: node.data.options?.map((option) => {
                if (option.id == optionId) {
                  return { ...option, postbackText: `stepId=${targetNodeId}` };
                } else return option;
              }),
            },
          };

          return updatedNode;
        } else return node;
      });

      if (updatedNode) {
        state.selectedNode = updatedNode;
      }
    },

    deleteOption(state, { payload }) {
      const { nodeId, optionId } = payload;
      let currNodeOptions = JSON.parse(
        JSON.stringify([...state.selectedNode.data.options])
      );

      let updateOptions = currNodeOptions?.filter((opt) => opt.id != optionId);

      let updatedCurrNode = {
        ...state.selectedNode,
        data: {
          ...state.selectedNode.data,
          options: [...updateOptions],
        },
      };
      state.selectedNode = updatedCurrNode;

      state.nodes = state.nodes?.map((node) => {
        if (node.id == nodeId) {
          return updatedCurrNode;
        } else return node;
      });
      let deletedEdge = state.edges.find((edge) => edge.optionId == optionId);
      state.edges = state.edges?.filter((edge) => edge.optionId !== optionId);
      state.deletedOptionNodeEdgeCache = [
        ...state.deletedOptionNodeEdgeCache,
        deletedEdge,
      ];
    },

    resetCanvas(state) {
      state.nodes = [];
      state.edges = [];
      state.selectedNode = null;
      state.rootStepId = null;
    },
    changeBotMode(state, { payload }) {
      state.mode = payload;
    },

    resetBotFlowList(state) {
      state.botFlows = [];
    },
    changeListNode(state, action) {
      state.listNode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addBotFlowSteps.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addBotFlowSteps.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addBotFlowSteps.rejected, (state) => {
      state.loading = false;
    });

    // get bot flow schema
    builder.addCase(getBotFlow.fulfilled, (state, action) => {
      const { nodes, edges, rootStepId } = action.payload;
      if (nodes && edges) {
        state.nodes = nodes;
        state.edges = edges;
        state.rootStepId = rootStepId;
        state.botFlowExists = true;
      } else {
        state.botFlowExists = false;
      }
      state.loading = false;

      if (action.payload?.name) {
        state.isEditable = BOTFLOW_EDIT_KEY == action.payload.name;
      }
    });
    builder.addCase(getBotFlow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBotFlow.rejected, (state) => {
      state.loading = false;
    });

    // add botflow
    builder.addCase(addNewBotFlow.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.botFlows = [payload, ...state.botFlows];
      state.totalbotflowCount += 1;
    });

    builder.addCase(addNewBotFlow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addNewBotFlow.rejected, (state) => {
      state.loading = false;
    });

    // update bot flow
    builder.addCase(updateBotFlow.fulfilled, (state, action) => {
      state.nodes = action.payload.nodes;
      state.edges = action.payload.edges;
      state.loading = false;
      state.configScreenOpen = false;
    });
    builder.addCase(updateBotFlow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBotFlow.rejected, (state) => {
      state.loading = false;
    });

    //update bot flow details
    builder.addCase(updateBotflowDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.botFlows = state.botFlows?.map((item) => {
        if (item.id == payload.id) {
          return payload;
        } else {
          return item;
        }
      });
    });
    builder.addCase(updateBotflowDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBotflowDetails.rejected, (state) => {
      state.loading = false;
    });

    //get botflow list
    builder.addCase(getAllBotFlow.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.botFlows = [...payload.rows];
      state.totalbotflowCount = payload.count;
    });
    builder.addCase(getAllBotFlow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllBotFlow.rejected, (state) => {
      state.loading = false;
    });

    // delete botflow
    builder.addCase(deleteBotFlow.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.botFlows = state.botFlows.filter((item) => item.id != payload);
      state.totalbotflowCount -= 1;
    });
    builder.addCase(deleteBotFlow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBotFlow.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  changeBotflowPage,
  changeBotflowLimit,
  changeSelectectBotFlow,
  setNodes,
  setEdges,
  addNodeToStore,
  addEdgeToStore,
  changeSelectedNode,
  changeQuickReplyNode,
  updateTextNode,
  deleteNode,
  deleteConnectedNodes,
  deleteEdge,
  layoutEdges,
  layoutNodes,
  changeRootStepId,
  changeListNode,
  deleteNodeData,
  resetCanvas,
  addNewOption,
  updatePostbackText,
  updateNodePayload,
  updateOptionTitle,
  updateNodeData,
  deleteOption,
  openConfigScreen,
  closeConfigScreen,
  undoNodeData,
  changeBotMode,
  resetBotFlowList,
} = flowSlice.actions;
export default flowSlice.reducer;
