import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Loader from "../../components/Loader";
import { Text } from "../../components/Text";
import { CustomButton } from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { getDate } from "../../utils/datemaker";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import CustomPaginator from "../../components/CustomPaginator";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import DateRangePicker from "../../components/common/DateRangePicker";
import DebounceSearch from "../../components/common/DebounceSearch";
import { useNavigate } from "react-router-dom";
import {
  getUsages,
  changePage,
  changelimit,
  resetUsagePagination,
} from "../../reducers/usageTabelSlice";
import { emptyContent } from "../../utils/tableUtils";
import { changeUser } from "../../reducers/enqChatSlice";
import {
  formatDate,
  formatMobNumber,
  maskMobNumber,
  removeCountryCode,
} from "../../utils/commonUtils";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import TrailDashboard from "../TrailDashboard";
import {getAllUsers} from '../../reducers/accountSlice'
import axiosInstance from "../../api/axios.instance";
import { getCompany } from "../../reducers/companySlice";
const Usage = () => {
  const [inputValue, setInputValue] = useState("");
  const [dateRange, setDateRange] = useState({ fromDate: "", toDate:new Date() });
  const toast = useRef(null);
  const { user,userSub, accesskey } = useSelector((state) => state.authenticate);
  const { allUsers } = useSelector((state) => state.account);
  const [companies,setCompanies]=useState([])
  const dispatch = useDispatch();
  const { usageData, page, limit, loading, totalUsageCount } = useSelector(
    (state) => state.usageTable
  );
  const {company} = useSelector((state) => state.company);

  const handleDateRangeFrom = (newDate) => {
    setDateRange((prev) => {
      return { ...prev, fromDate: newDate };
    });
  };
  const handleDateRangeTo = (newDate) => {
    setDateRange((prev) => {
      return { ...prev, toDate: newDate };
    });
  };

  const modalLoad = () => {
    return <Loader visible={loading} />;
  };

  const navigate = useNavigate();

  ///filter--------------------------------
  const [filtersData, setFiltersData] = useState({
    name: null,
    usageStatus: null,
    agentName:null,
  });

  const [filters1, setFilters1] = useState({
    name: { value: null },
    usageStatus: { value: null },
    agentName:{value:null}
  });

  let status = ["New", "In Progress", "Follow Up", "Closed"];

  const filterTemplate = (options, filterOptions ) => {
    return (
      <Dropdown
        value={options.value}
        options={filterOptions}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        itemTemplate={filterItemTemplate}
        placeholder="Select"
        className="p-column-filter"
        showClear
      />
    );
  };

  const filterItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };

  const onClearStatusFilter = () => {
    filters1.usageStatus.value = null;
    filtersData.usageStatus = null;
    onApplyAllFilter();
  };

 const onClearAgentFilter = () =>{
    filters1.agentName.value = null;
    filtersData.agentName = null;
    onApplyAllFilter();
  }

  const onClickFilter = (e) => {
    switch (e.field) {
      case "name":
        filters1.name.value = e.constraints.constraints[0].value;
        filtersData.name = e.constraints.constraints[0].value;
        break;
      case "usageStatus":
        filters1.usageStatus.value = e.constraints.value;
        filtersData.usageStatus = e.constraints.value;
        break;
      case 'agentName':
        filters1.agentName.value = e.constraints.value;
        filtersData.agentName = e.constraints.value; 
        break;
      default:
        break;
    }

    onApplyAllFilter();
  };

  const managePaginationData = () => {
    let filterData = [];
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    var { fromDate, toDate } = dateRange;
    if (!toDate) toDate = fromDate;
    for (var key in filtersData) {
      if (filtersData[key]) {
        filterData.push({
          key: key,
          value: filtersData[key],
        });
      }
    }

    let paginationData = {
      page: page,
      limit: limit,
      startDate: fromDate
        ? new Date(fromDate - tzoffset).toISOString().substring(0, 10)
        : fromDate,
      endDate: toDate
        ? new Date(toDate - tzoffset).toISOString().substring(0, 10)
        : toDate,
      filterData,
      globalFilterValue: inputValue,
      companyids:companies?.length>0?companies:[]
    };
    return paginationData;
  };
  useEffect(()=>{
    dispatch(getCompany(user?.companyId))
    axiosInstance.get("/user/child/company").then((s) => {
      let data=s?.data?.map(x=>x?.id);
      setCompanies(data);
    });
  },[])

  useEffect(()=>{
    const startDate=  new Date();
    startDate.setDate(1);
      setDateRange((x)=> 
      { return {
        fromDate: startDate,
        toDate:x?.toDate}
      }
    )
  },[company])
  const onApplyAllFilter = () => {
    let paginationData = managePaginationData();
    dispatch(getUsages(paginationData));
  };

  useEffect(() => {
    const paginationData = managePaginationData();

    const { fromDate, toDate } = dateRange;
    if ((fromDate && !toDate) || (!fromDate && toDate)) return;

    dispatch(getUsages(paginationData))
      .unwrap()
      .then((res) => {})
      .catch(() => {
        //show toast from here
        toast.current.show({
          severity: "error",
          detail: "Something went wrong!",
        });
      });
  }, [inputValue, dateRange, page, limit,companies]);

  useEffect(() => {
    dispatch(getAllUsers());
    //reset state on unmount
    return () => {
      dispatch(resetUsagePagination());
    };
  }, []);
  const handleUsageSelect = (data) => {
    return;
  };
  return (
    <div className="w-full m-auto">
      <Toast ref={toast} />
      <div className="m-3">
        {!userSub.isUserSub ? (
          <TrailDashboard/>
        ) : (
          <div className="">
            <TableActionsContainer>
              <div className="flex align-items-center justify-content-between flex-wrap gap-3">
                <Text type="heading">Usage</Text>
                <div className="flex gap-2 flex-wrap ">
                  <DateRangePicker
                    fromDate={dateRange.fromDate}
                    toDate={dateRange.toDate}
                    handleFromDate={handleDateRangeFrom}
                    handleToDate={handleDateRangeTo}
                    reset={false}
                  />
                </div>
              </div>
            </TableActionsContainer>
            <div className="card lg:my-2">
              {modalLoad()}
              <div
                className={`w-full flex flex-column dashboard-tablecontent border-round-sm `}
              >
                <DataTable
                  value={usageData}
                  resizableColumns
                  columnResizeMode="expand"
                  className="skalebot-table "
                  style={{ borderTop: "0px" }}
                  filters={filters1}
                  filterDisplay="menu"
                  onSelectionChange={(e) => handleUsageSelect(e.value)}
                  globalFilterFields={["name", "status"]}
                >
                        <Column
                          field="id"
                          header="ID"
                          style={{ width: "5rem" }}
                          className="text-sm"
                        />

                        <Column
                          field="name"
                          header="Name"
                          style={{ width: "15rem" }}
                          className="text-sm"
                          bodyStyle={{ textTransform: "capitalize" }}
                        />
                        <Column
                          field="appName"
                          header="App Name"
                          style={{ width: "10rem" }}
                          className="text-sm"
                          bodyStyle={{ textTransform: "capitalize" }}
                        />

                        {/* Phone */}
                        <Column
                          field="phone"
                          header="Phone"
                          style={{ width: "10rem" }}
                          className="text-sm"
                        />

                        {/* User-Initiated Messages */}
                        <Column
                          field="userInitiatedMessages"
                          header="User Initiated"
                          style={{ width: "8rem" }}
                          className="text-sm"
                          bodyStyle={{ textAlign: "center" }}
                        />

                        {/* Business-Initiated Messages */}
                        <Column
                          field="businessInitiatedMessages"
                          header="Business Initiated"
                          style={{ width: "8rem" }}
                          className="text-sm"
                          bodyStyle={{ textAlign: "center" }}
                        />

                        {/* Total Messages */}
                        <Column
                          field="totalMessages"
                          header="Total Conversations"
                          style={{ width: "8rem" }}
                          className="text-sm"
                          bodyStyle={{ textAlign: "center" }}
                        />
                        <Column
                          field="usersCount"
                          header="Total User"
                          style={{ width: "8rem" }}
                          className="text-sm"
                        />

                        {/* Is Active */}
                        <Column
                          field="isActive"
                          header="Active Status"
                          style={{ width: "8rem" }}
                          className="text-sm"
                          body={(rowData) => (rowData.isActive ? "Active" : "Inactive")}
                        />

                        {/* Created At */}
                        <Column
                          field="createdAt"
                          header="Created At"
                          style={{ width: "10rem" }}
                          className="text-sm"
                          body={(rowData) =>
                            rowData?.createdAt && rowData?.createdAt
                          }
                          bodyStyle={{
                            textTransform: "uppercase",
                          }}
                        />
                </DataTable>
              </div>
            </div>
            <div className="mt-3">
              <TableActionsContainer>
                <div className="flex  justify-content-end">
                  <CustomPaginator
                    page={page}
                    limit={limit}
                    changelimit={changelimit}
                    totalRecords={totalUsageCount}
                    changePage={changePage}
                  />
                </div>
              </TableActionsContainer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Usage;
