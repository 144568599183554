import { InputText } from "primereact/inputtext";
import { useDebounce } from "primereact/hooks";
import { useEffect } from "react";
import { capitalizeFirstLetter } from "../../utils/tableUtils";
const DebounceSearch = ({
  placeholder = "",
  inputValue = "",
  className = "",
  handleInputChange = () => {},
}) => {
  const [searchText, debouncedValue, setSearchText] = useDebounce(
    inputValue,
    2000
  );

  useEffect(() => {
    handleInputChange(searchText);
  }, [debouncedValue]);

  return (
    <div className="flex-grow-1 lg:flex-grow-0 xl:flex-grow-0">
      <span className="p-input-icon-right w-full">
        {searchText ? (
          <i
            className="pi pi-times cursor-pointer"
            style={{ color: "#005068", fontSize: "14px" }}
            onClick={() => setSearchText("")}
          />
        ) : (
          <i className="pi pi-search" style={{ color: "#005068" }} />
        )}
        <InputText
          type="text"
          placeholder={placeholder}
          value={searchText}
          onChange={(e) => setSearchText(capitalizeFirstLetter(e.target.value))}
          className={`w-full ${className} pr-5`}
        />
      </span>
    </div>
  );
};

export default DebounceSearch;
