import { CustomButton } from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "primereact/badge";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import {
  resetBroadcast,
  saveProduct,
  setBroadcastDetails,
} from "../../reducers/ShareMessage/messageSlice";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import { DataTable } from "primereact/datatable";
import Loader from "../../components/Loader";
import { Text } from "../../components/Text";
import CustomPaginator from "../../components/CustomPaginator";
import { getAlphabeticalSortedArr } from "../../utils/tableUtils";
import {
  getProducts,
  changePage,
  resetAll,
  changelimit,
  updateSelectedProductsBList,
  getSortedProducts,
  changeSelectedProductBlist,
} from "../../reducers/productTableSlice";
import { Dropdown } from "primereact/dropdown";
import { API_GET_CATEGORIES } from "../../api/category.services";
import EllipsisText from "../../components/common/EllipsisText/indix";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import SelectedItemList from "../../components/SelectedItemsList";
import ImageGallary from "../../components/common/ImageGallary";
import { useUtility } from "../../context/UtilityContext";
import DebounceSearch from "../../components/common/DebounceSearch";
const addsteps = (name, value, url, show = true, panel = false) => {
  return { name, value, url, show, panel };
};
export const ProductSelect = () => {
  const [inputValue, setInputValue] = useState("");
  const { id } = useParams();
  const { completeSteps, broadcastdetails, selectedTemplate } = useSelector(
    (state) => state.shareMessage
  );
  const toast = useRef(null);
  const {
    productData,
    loading,
    page,
    limit,
    totalProductCount,
    selectedProductBlist,
  } = useSelector((state) => state.productTable);
  const [filtersData, setFiltersData] = useState({
    categoryId: null,
    status: null,
  });

  const [filters1, setFilters1] = useState({
    categoryName: { value: null },
    status: { value: null },
  });
  const [categories, setCategories] = useState([]);
  const [allCategory, setAllCategory] = useState([]);

  useEffect(() => {
    const getAllCategories = async () => {
      try {
        const AllCategories = await API_GET_CATEGORIES(0, 100000);
        let category = [];
        AllCategories.rows.forEach((element) => {
          category.push(element.categoryName);
        });

        setAllCategory(AllCategories.rows);
        const alphabeticalSortedCategory = getAlphabeticalSortedArr(category);
        setCategories(alphabeticalSortedCategory);
      } catch (error) {}
    };
    getAllCategories();
  }, []);

  const initFilters1 = () => {
    setFilters1({
      categoryName: { value: null },
      status: { value: null },
    });
  };

  let stocks = ["Available", "Unavailable"];

  const stockFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={stocks}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        itemTemplate={stockItemTemplate}
        placeholder="Select Category"
        className="p-column-filter"
        showClear
      />
    );
  };

  const stockItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };

  const categoryFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={categories}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        itemTemplate={categoryItemTemplate}
        placeholder="Select Category"
        className="p-column-filter"
        showClear
      />
    );
  };

  const categoryItemTemplate = (option) => {
    return (
      <span className={`customer-badge capitalize status-${option}`}>
        {option}
      </span>
    );
  };

  const onClearCategoryFilter = () => {
    filters1.categoryName.value = null;
    filtersData.categoryId = null;
    onApplyAllFilter();
  };
  const onClearStatusFilter = () => {
    filters1.status.value = null;
    filtersData.status = null;
    onApplyAllFilter();
  };

  const onClickFilter = (e) => {
    switch (e.field) {
      case "categoryName":
        var id;
        for (let i = 0; i < allCategory.length; i++) {
          if (e.constraints.value === allCategory[i].categoryName) {
            id = allCategory[i].id;
            filters1.categoryName.value = id;
            filtersData.categoryId = id;

            break;
          }
        }

        break;
      case "status":
        filters1.status.value = e.constraints.value;
        filtersData.status = e.constraints.value;
        break;
      default:
        break;
    }
    onApplyAllFilter();
  };

  const managePaginationData = () => {
    let filterData = [];
    for (var key in filtersData) {
      if (filtersData[key]) {
        filterData.push({
          key: key,
          value: filtersData[key],
        });
      }
    }
    let selectedproductid = broadcastdetails?.configData?.productItems?.map(
      (x) => {
        return x.id;
      }
    );
    let paginationData = {
      page: page,
      limit: limit,
      filterData,
      globalFilterValue: inputValue,
      productIds: selectedproductid,
    };
    return paginationData;
  };

  const onApplyAllFilter = () => {
    callProducts();
  };

  const modalLoad = () => {
    return (
      <div>
        <Loader visible={loading} />
      </div>
    );
  };

  useEffect(() => {
    callProducts();
    initFilters1();
  }, [page, limit, inputValue]);

  useEffect(() => {
    if (broadcastdetails) {
      if (broadcastdetails?.configData?.SKUCodes?.length > 0) {
        let prod = broadcastdetails.configData.SKUCodes.map((x) => {
          return { SKUCode: x };
        });
        dispatch(changeSelectedProductBlist(prod));
      }
    }
  }, [broadcastdetails]);

  function onSelectProduct(e) {
    const selectedProducts = e.value;
    const currProducts = productData;
    // console.log(selectedProducts, currProducts)
    setAlert(true);
    dispatch(updateSelectedProductsBList({ selectedProducts, currProducts }));
  }

  const isSelectable = (data) => {
    const { status, categoryStatus } = data;
    return status === "Available" && categoryStatus === "Available";
  };

  const isRowSelectable = (e) => {
    return isSelectable(e.data);
  };

  const rowClassName = (data) => {
    return isSelectable(data) ? "" : "not-selectable";
  };

  //search-----------------------

  const callProducts = () => {
    let paginationData = managePaginationData();
    if (id) {
      dispatch(getSortedProducts(paginationData))
        .unwrap()
        .catch(() => {
          //show toast from here
        });
    } else {
      dispatch(getProducts(paginationData))
        .unwrap()
        .catch(() => {
          //show toast from here
        });
    }
  };

  const renderHeader1 = () => {
    return (
      <div className="lg:flex justify-content-end  lg:mb-0 sm:mb-0">
        <div className="pr-3">
          <DebounceSearch
            placeholder={"Search Product"}
            inputValue={inputValue}
            handleInputChange={(value) => setInputValue(value)}
          />
        </div>
      </div>
    );
  };
  const header1 = renderHeader1();

  const dispatch = useDispatch();
  if (selectedTemplate) {
    var { campaignType } = selectedTemplate;
  }
  const steps = [];
  steps.push(
    addsteps(
      "Information",
      "info",
      `${id ? `/runcampaign/${id}` : `/runcampaign/new`}`
    )
  );
  steps.push(
    addsteps(
      "Edit Template",
      "info",
      `${
        id
          ? `/runcampaign/selecttemplate/${id}`
          : `/runcampaign/selecttemplate/new`
      }`
    )
  );
  steps.push(
    addsteps(
      "Select Product",
      "prod",
      `${
        id
          ? `/runcampaign/selectproducts/${id}`
          : `/runcampaign/selectproducts/new`
      }`,
      campaignType === "product",
      true
    )
  );
  steps.push(
    addsteps(
      "Select Customer",
      "cust",
      `${
        id
          ? `/runcampaign/selectcustomer/${id}`
          : `/runcampaign/selectcustomer/new`
      }`
    )
  );
  steps.push(
    addsteps(
      "Schedule Campaign",
      "camp",
      `${id ? `/runcampaign/schedule/${id}` : `/runcampaign/schedule/new`}`
    )
  );

  const onSubmit = (data) => {
    if (selectedProductBlist?.length === 0) {
      toast.current.show({
        severity: "warn",
        detail: "Please Select Products.",
      });
      return;
    }
    dispatch(saveProduct([...selectedProductBlist]));
    dispatch(
      setBroadcastDetails({
        ...broadcastdetails,
        configData: {
          ...broadcastdetails.configData,
          SKUCodes: selectedProductBlist.map((x) => {
            return x.SKUCode;
          }),
        },
      })
    );
    navigate(
      `${
        id
          ? `/runcampaign/selectcustomer/${id}`
          : `/runcampaign/selectcustomer/new`
      }`
    );
  };

  const itemslist = [{ label: "Run Campaign", url: "" }];
  const navigate = useNavigate();
  const { navigation, setAlert } = useUtility();
  const switchform = (link) => {
    navigate(link);
  };
  const cancelBroadcast = () => {
    dispatch(resetBroadcast());
    dispatch(resetAll());
    navigation(`${id ? `/campaigns/${id}` : `/campaigns`}`);
  };

  useEffect(() => {
    if (completeSteps === 0) {
      navigate(`${id ? `/runcampaign/${id}` : `/runcampaign/new`}`);
    }
  }, [completeSteps]);

  return (
    <div className="w-full m-auto ">
      <Toast ref={toast} />
      {modalLoad()}
      <div className="w-full m-auto overflow-x-auto">
        <CustomBreadcrumb
          home={{ label: "Campaign", url: "/campaigns" }}
          itemslist={itemslist}
        />
      </div>
      <div className="p-3 pt-1">
        <div
          className="w-full m-auto flex flex-row justify-content-between my-2 overflow-x-auto "
          style={{ background: "#f2f2f2" }}
        >
          <div className="p-2 py-3 flex flex-row ">
            {steps.map((x, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-row gap-2 mr-3 cursor-pointer"
                  onClick={() => {
                    index <= completeSteps && x.show && switchform(x.url);
                  }}
                >
                  <div>
                    <Badge
                      value={index + 1}
                      style={{
                        background: index <= completeSteps ? `#1c738e` : `grey`,
                        opacity: `${x.show ? `1` : `.5`}`,
                      }}
                    />
                  </div>
                  <div
                    className={`white-space-nowrap  ${
                      x.panel ? `border-none border-bottom-1 border-solid` : ``
                    }`}
                    style={{ borderColor: "#1c738e" }}
                  >
                    {x.name}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-row gap-2 align-items-center px-2">
            <div
              className="font-medium text-sm cursor-pointer"
              onClick={cancelBroadcast}
            >
              Cancel
            </div>
            <div>
              <CustomButton
                varient="plain-button w-full"
                type="submit"
                onClick={() => {
                  navigate(
                    `${
                      id
                        ? `/runcampaign/selecttemplate/${id}`
                        : `/runcampaign/selecttemplate/new`
                    }`
                  );
                }}
                label={"Back"}
              />
            </div>
            <div>
              <CustomButton
                varient="filled w-full"
                type="submit"
                disabled={selectedProductBlist.length === 0}
                onClick={onSubmit}
                label={<span>Save&nbsp;&&nbsp;Continue</span>}
              />
            </div>
          </div>
        </div>
        <div>
          {selectedProductBlist.length !== 0 && (
            <div className=" my-2 flex gap-2 align-items-center">
              <Text type={"sub-heading"}>
                Selected&nbsp;Products&nbsp;[{selectedProductBlist.length}
                ]&nbsp;:
              </Text>
              <SelectedItemList
                items={selectedProductBlist}
                itemLabel={"SKUCode"}
                removeId={"SKUCode"}
                target="product"
              />
            </div>
          )}
        </div>
        <div className="flex w-full flex-column">
          <DataTable
            value={productData}
            selection={selectedProductBlist}
            onSelectionChange={onSelectProduct}
            dataKey="SKUCode"
            className="skalebot-table"
            selectionMode="checkbox"
            filters={filters1}
            filterDisplay="menu"
            globalFilterFields={[
              "SKUCode",
              "productName",
              "categoryName",
              "status",
            ]}
            header={header1}
            isDataSelectable={isRowSelectable}
            rowClassName={rowClassName}
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
              exportable={false}
            ></Column>
            <Column
              field="SKUCode"
              header={<span>SKU&nbsp;Id</span>}
              className="uppercase text-sm font-normal"
            ></Column>
            <Column
              field="productName"
              header="Name"
              style={{ maxWidth: "300px" }}
              body={(rowData) => (
                <EllipsisText
                  className={"white-space-nowrap"}
                  text={rowData.productName}
                />
              )}
            ></Column>
            <Column
              field="categoryName"
              header="Category"
              showFilterMatchModes={false}
              filterMenuStyle={{ width: "8rem" }}
              style={{ maxWidth: "8rem" }}
              filter
              filterElement={categoryFilterTemplate}
              onFilterApplyClick={(e) => onClickFilter(e)}
              onFilterClear={onClearCategoryFilter}
              className="capitalize text-sm font-normal"
            />

            <Column
              field="status"
              header="Stock"
              showFilterMatchModes={false}
              filterMenuStyle={{ width: "8rem" }}
              style={{ maxWidth: "8rem" }}
              filter
              filterElement={stockFilterTemplate}
              onFilterApplyClick={(e) => onClickFilter(e)}
              onFilterClear={onClearStatusFilter}
              className="text-sm font-normal"
            ></Column>
            <Column
              field="quantity"
              header="Quantity"
              className="text-sm font-normal"
            ></Column>
            <Column
              field="price"
              header={<span>Price&nbsp;(₹)</span>}
              className="text-sm font-normal"
            ></Column>
            <Column
              header="Image"
              body={(rowData) => <ImageGallary images={rowData?.images} />}
              className="text-sm font-normal"
            ></Column>
            <Column
              field="desc"
              header="Desciption"
              className="text-sm font-normal"
              bodyStyle={{ minWidth: "250px", maxWidth: "350px" }}
              body={(rowData) => <EllipsisText text={rowData.desc} />}
            ></Column>
          </DataTable>
          <div className="mt-3">
            <TableActionsContainer>
              <div className="flex  justify-content-end">
                <CustomPaginator
                  page={page}
                  limit={limit}
                  changelimit={changelimit}
                  totalRecords={totalProductCount}
                  changePage={changePage}
                />
              </div>
            </TableActionsContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
