import { Text } from "../components/Text";
import versionData from "../utils/appVersion";

function VersionTag({ color }) {
  return (
    <>
      <Text type="sub-heading" color={color}>
        Powered by{" "}
        <a
          href="https://www.deskala.in/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#1C738E", textDecoration: "underline" }}
        >
          {" "}
          Deskala{" "}
        </a>
      </Text>
      <Text type="small-text" cust="pt-1 mt-2" color={color}>
        Version {versionData.version} Launch date {versionData.date}
      </Text>
    </>
  );
}

export default VersionTag;
