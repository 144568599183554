import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  API_ADD_COMPANY,
  API_DELETE_COMPANY,
  API_GET_COMPANY,
  API_PUT_COMPANY,
} from "../api/company.service";
import axios from "axios";

const initialState = {
  company: null,
  newCompanyToast: false,
  forgetToast: false,
  loadingC: false,
};

export const getCompany = createAsyncThunk(
  "company/getCompany",
  async (companyId, thunkAPI) => {
    try {
      const company = await API_GET_COMPANY(companyId);
      return company;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateCompany = createAsyncThunk(
  "company/updateCompany",
  async ({ companyId, updatedData, logoUpdate }, thunkAPI) => {
    try {
      const { src, newLogo } = logoUpdate;
      if (src && newLogo) {
        await axios.put(src, newLogo, {
          headers: { "Content-Type": "image/png" },
        });
      }

      const company = await API_PUT_COMPANY(companyId, updatedData);
      return company;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const addCompany = createAsyncThunk(
  "company/addCompany",
  async (data, thunkAPI) => {
    try {
      const company = await API_ADD_COMPANY(data);
      return company;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany(state, action) {
      state.company = action.payload;
    },
    changeCompanyToast(state, action) {
      state.newCompanyToast = action.payload;
    },
    resetCompanyToast(state) {
      state.newCompanyToast = false;
    },
    changeForgetToast(state, action) {
      state.forgetToast = action.payload;
    },
    resetForgetToast(state) {
      state.forgetToast = false;
    },
    resetCompany(state) {
      state.company = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompany.fulfilled, (state, { payload }) => {
      state.loadingC = false;
      state.company = payload.company;
    });
    builder.addCase(getCompany.pending, (state) => {
      state.loadingC = true;
    });
    builder.addCase(getCompany.rejected, (state) => {
      state.loadingC = false;
    });

    builder.addCase(addCompany.fulfilled, (state) => {
      state.newCompanyToast = true;
      state.loadingC = false;
    });
    builder.addCase(addCompany.pending, (state) => {
      state.loadingC = true;
    });
    builder.addCase(addCompany.rejected, (state) => {
      state.loadingC = false;
    });
    builder.addCase(updateCompany.fulfilled, (state, { payload }) => {
      state.loadingC = false;
      state.company = payload.company;
    });
    builder.addCase(updateCompany.pending, (state) => {
      state.loadingC = true;
    });
    builder.addCase(updateCompany.rejected, (state) => {
      state.loadingC = false;
    });
  },
});

export const {
  setCompany,
  changeCompanyToast,
  resetCompanyToast,
  changeForgetToast,
  resetForgetToast,
  resetCompany,
} = companySlice.actions;
export default companySlice.reducer;
