import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  selectedTab: "/",
  slidebarOpen: false,
  showNotice: true,
  showGroupShareDialog: false,
  showBroadcastTemplateForm: false,
  successfullCampaign: false,
  fbRegistrationModule: false,
};

const appSlice = createSlice({
  name: "skalebotApp",
  initialState,
  reducers: {
    changeLoadingStatus(state) {
      state.loading = !state.loading;
    },
    changeSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
    changeSidebarOpenStatus(state) {
      state.slidebarOpen = !state.slidebarOpen;
    },
    changeShowNotice(state, action) {
      state.showNotice = action.payload;
    },
    changeShowGroupShareDialog(state, action) {
      state.showGroupShareDialog = action.payload;
    },
    changeShowBroadcastTemplateForm(state, action) {
      state.showBroadcastTemplateForm = action.payload;
    },
    changeSuccessfullCampaign(state, action) {
      state.successfullCampaign = action.payload;
    },
    changeFbRegistrationModule(state, { payload }) {
      state.fbRegistrationModule = payload;
    },
  },
});

export const {
  changeLoadingStatus,
  changeSelectedTab,
  changeSidebarOpenStatus,
  changeShowNotice,
  changeShowGroupShareDialog,
  changeShowBroadcastTemplateForm,
  changeSuccessfullCampaign,
  changeFbRegistrationModule,
} = appSlice.actions;

export default appSlice.reducer;
