import {
  ENQUEUE_ICON_URL,
  SINGLE_TICK_ICON_URL,
  DOUBLE_TICK_ICON_URL,
  DOUBLE_GREEN_TICK_ICON_URL,
} from "./status-constants";
const MessageStatus = (props) => {
  const { message } = props;
  if (message?.isRead) {
    return (
      <img
        width="14"
        height="14"
        className="mt-2"
        src={DOUBLE_GREEN_TICK_ICON_URL}
        alt="status-icon"
      />
    );
  }else if (message?.isDelivered) {
    return (
      <img
        width="14"
        height="14"
        className="mt-2"
        src={DOUBLE_TICK_ICON_URL}
        alt="status-icon"
      />
    );
  } else if (message?.isSent) {
    return (
      <img
        width="14"
        height="14"
        className="mt-2"
        src={SINGLE_TICK_ICON_URL}
        alt="status-icon"
      />
    );
  } else if (message?.isEnqueued&&!(message.status === "failed")) {
    let currentDate = new Date();
    let oldTime = new Date(message?.lastUpdatedAt);
    if (currentDate - oldTime < 336763) {
      return (
        <img width="14" height="14" src={ENQUEUE_ICON_URL} alt="status-icon" />
      );
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
};
export default MessageStatus;
