import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { useState, useEffect, useRef } from "react";
import { ConversationAlert } from "../../components/ConversationAlert";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { useForm, Controller } from "react-hook-form";
import { Toast } from "primereact/toast";
import { RadioButton } from "primereact/radiobutton";
import {
  getCampaignTemplates,
  putCampaign,
} from "../../reducers/campaignSlice";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { CustomButton } from "../../components/CustomButton";
import {
  cancelScheduler,
  resetBroadcast,
  setScheduler,
} from "../../reducers/ShareMessage/messageSlice";
import { Badge } from "primereact/badge";
import { useNavigate, useParams } from "react-router-dom";
import { shareBroadcast } from "../../reducers/ShareMessage/actions";
import { getSKUCodes } from "../../utils/shareUtils";
import {
  resetAll,
  resetSelectedProductBlist,
} from "../../reducers/productTableSlice";
import { Dialog } from "primereact/dialog";
import { ReactComponent as ShareIcon } from "../../assets/share.svg";
import style from "./templateStyle.module.css";
import CustomDialog from "../../components/CustomDialog";
import { useLayout } from "../../context/LayoutContext";
import CustomSaveModel from "../../components/CustomeSaveModel";
import CONSTANTS from "../../config/Constants";
import { useUtility } from "../../context/UtilityContext";

const addsteps = (name, value, url, show = true, panel = false) => {
  return { name, value, url, show, panel };
};
function ScheduleCamp() {
  const { toast, setLoading } = useLayout();
  const itemslist = [{ label: "Run Campaign", url: "" }];
  const {
    completeSteps,
    broadcastdetails,
    selectedTemplate,
    scheduleDetail,
    shareLoading: loading,
    schedule: scd,
    mode,
    campDetails,
    selectedCustomers,
  } = useSelector((state) => state.shareMessage);
  const [schedule, setSchedule] = useState(false);
  useEffect(() => {
    setSchedule(scd);
  }, []);
  const { selectedGroup } = useSelector((state) => state.customerTable);

  if (selectedTemplate) {
    var { campaignType } = selectedTemplate;
  }
  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const { id } = useParams();
  const steps = [];
  steps.push(
    addsteps(
      "Information",
      "info",
      `${id ? `/runcampaign/${id}` : `/runcampaign/new`}`
    )
  );
  steps.push(
    addsteps(
      "Edit Template",
      "info",
      `${
        id
          ? `/runcampaign/selecttemplate/${id}`
          : `/runcampaign/selecttemplate/new`
      }`
    )
  );
  steps.push(
    addsteps(
      "Select Product",
      "prod",
      `${
        id
          ? `/runcampaign/selectproducts/${id}`
          : `/runcampaign/selectproducts/new`
      }`,
      campaignType === "product"
    )
  );
  steps.push(
    addsteps(
      "Select Customer",
      "cust",
      `${
        id
          ? `/runcampaign/selectcustomer/${id}`
          : `/runcampaign/selectcustomer/new`
      }`
    )
  );
  steps.push(
    addsteps(
      "Schedule Campaign",
      "camp",
      `${id ? `/runcampaign/schedule/${id}` : `/runcampaign/schedule/new`}`,
      true,
      true
    )
  );

  const defaultScheduleValues = {
    startDate: scheduleDetail.startDate,
    endDate: scheduleDetail.endDate,
    dayOfWeek: scheduleDetail.dayOfWeek,
    runTime: scheduleDetail.runTime,
  };
  const navigate = useNavigate();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit: handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm(defaultScheduleValues);

  useEffect(() => {
    if (id && (id[0] === "s" || id[0] === "S")) {
      setValue("startDate", new Date(scheduleDetail.startDate), {
        shouldValidate: true,
      });
      setValue("endDate", new Date(scheduleDetail.endDate), {
        shouldValidate: true,
      });
      setValue("dayOfWeek", scheduleDetail.dayOfWeek, { shouldValidate: true });
      setValue("runTime", scheduleDetail.runTime, { shouldValidate: true });
      setSchedule(true);
    } else {
      setSchedule(scd);
    }
  }, []);

  //function form get error message
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCampaignTemplates());
  }, []);

  const onSubmit = (e) => {
    dispatch(setScheduler(schedule));
    setShowSave(false);
    if (e) {
      broadcast(e);
    } else broadcast();
  };

  const [days,setDays] = useState([
    { name: "Sunday", value: 0 ,disabled:true},
    { name: "Monday", value: 1 ,disabled:true},
    { name: "Tuesday", value: 2 ,disabled:true},
    { name: "Wednesday", value: 3 ,disabled:true},
    { name: "Thursday", value: 4 ,disabled:true},
    { name: "Friday", value: 5 ,disabled:true},
    { name: "Saturday", value: 6 ,disabled:true},
  ]);
  const [time,setTime] = useState([
    { name: "00:00 AM", value: 0 },
    { name: "01:00 AM", value: 1 },
    { name: "02:00 AM", value: 2 },
    { name: "03:00 AM", value: 3 },
    { name: "04:00 AM", value: 4 },
    { name: "05:00 AM", value: 5 },
    { name: "06:00 AM", value: 6 },
    { name: "07:00 AM", value: 7 },
    { name: "08:00 AM", value: 8 },
    { name: "09:00 AM", value: 9 },
    { name: "10:00 AM", value: 10 },
    { name: "11:00 AM", value: 11 },
    { name: "12:00 PM", value: 12 },
    { name: "01:00 PM", value: 13 },
    { name: "02:00 PM", value: 14 },
    { name: "03:00 PM", value: 15 },
    { name: "04:00 PM", value: 16 },
    { name: "05:00 PM", value: 17 },
    { name: "06:00 PM", value: 18 },
    { name: "07:00 PM", value: 19 },
    { name: "08:00 PM", value: 20 },
    { name: "09:00 PM", value: 21 },
    { name: "10:00 PM", value: 22 },
    { name: "11:00 PM", value: 23 },
  ]);
  useEffect(()=>{
    const subscription = watch((value, { name, type }) =>{
      if(value?.startDate&&value?.endDate){
        const timeDifference = value?.endDate?.getTime() -value?.startDate?.getTime(); 
        let daysDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24))+1;
        let dayOfWeek = value?.startDate.getDay()
        const daysOfWeeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let dayarray=new Set()
        while(daysDifference){
          if(dayOfWeek===7){
              dayOfWeek=0
            }
            dayarray.add(dayOfWeek)
            dayOfWeek++;
            daysDifference--
        }
        let temp=days
        for(let x of dayarray){
          temp[x]={...temp[x],disabled:false}
        }
        setDays([...temp])
      }}
    )
    return () => subscription.unsubscribe()
  },[watch(["startDate", "enddate"])])
  const datecompile = (date) => {
    let x = new Date(date);
    return `${x.getFullYear()}-${x.getMonth() + 1}-${x.getDate()}`;
  };

  const broadcast = (data = {}) => {
    setShowSave(false);
    var configData = { ...broadcastdetails.configData };

    if (data) {
      configData = {
        ...configData,
        startDate: datecompile(data?.startDate),
        endDate: datecompile(data?.endDate),
        dayOfWeek: data?.dayOfWeek,
        runTime: data?.runTime,
      };
    }

    if (mode === "update" && id[0] === "s") {
      dispatch(
        putCampaign({
          id: campDetails.id,
          configData: configData,
          media: broadcastdetails.media,
          schedule,
        })
      )
        .unwrap()
        .then((resp) => {
          toast?.current?.show({
            severity: "success",
            detail: "Broadcast Updated",
          });
          dispatch(resetAll());
          dispatch(resetBroadcast());
          setAlert(false); 
          navigate(`/campaigns/${id}`);
        })
        .catch((err) => {
          toast?.current?.show({
            severity: "error",
            detail: err.message,
          });
        });
    } else {
      dispatch(
        shareBroadcast({
          configData: configData,
          media: broadcastdetails.media,
          schedule,
        })
      )
        .unwrap()
        .then((resp) => {
          toast?.current?.show({
            severity: "success",
            detail: "Broadcast Shared",
          });
          dispatch(resetAll());
          dispatch(resetBroadcast());
          dispatch(resetSelectedProductBlist());
          setAlert(false); 
          navigate("/campaigns");
        })
        .catch((err) => {
          console.log(err);
          toast?.current?.show({
            severity: "error",
            detail: err.message,
          });
        });
    }
  };

  const switchform = (link) => {
    navigate(link);
  };
  const sendNow = () => {
    dispatch(cancelScheduler());
  };
  const {navigation,setAlert}=useUtility()

  const cancelBroadcast = () => {
    dispatch(resetBroadcast());
    dispatch(resetAll());
    navigation(`${id ? `/campaigns/${id}` : `/campaigns`}`);
  };

  useEffect(() => {
    if (completeSteps === 0) {
      navigate(`${id ? `/runcampaign/${id}` : `/runcampaign/new`}`);
    }
  }, []);

  const [showSave, setShowSave] = useState(false);

  function addOneDay(date = new Date()) {
    date.setDate(date.getDate() + 1);
    return date;
  }
  const totalConversation = () => {
    let s_date = new Date(getValues("startDate"));
    let e_date = new Date(getValues("endDate"));
    let dow = getValues("dayOfWeek");
    let rt = getValues("runTime");
    let conversation = 0;
    for (; s_date.getTime() <= e_date.getTime(); s_date = addOneDay(s_date)) {
      if (dow.includes(s_date.getDay())) {
        //  console.log(s_date.getDate(),s_date.getDay())
        conversation += rt.length;
      }
    }
    return conversation;
  };
  const conversation = () => {
    if (broadcastdetails?.configData?.groupIds.length > 0) {
      let len = selectedGroup?.customerIds?.length;
      return len;
      // return schedule?totalConversation()*len:len;
    } else {
      let len = broadcastdetails?.configData?.customerIds?.length;
      return len;
      // return schedule?totalConversation()*len:len;
    }
  };
  const SaveModel = () => {
    return (
      <CustomSaveModel
        topheader="Broadcast Confirmation"
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };
  const alertText = () => {
    return <div>
      <div>Are you ready to launch your campaign? </div>
      <div>Once initiated, your message will be broadcast to the selected audience. </div>
      <div>Please ensure all details are accurate.</div>
      </div>
    ;
  };
  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false);
          }}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={schedule ? handleSubmit(onSubmit) : () => onSubmit()}
        />
      </div>
    );
  };
  const onHide = () => {
    setShowSave(false);
  };

  return (
    <div className="w-full">
      <div className="w-12  m-auto overflow-x-auto">
        <CustomBreadcrumb
          home={{ label: "Campaign", url: "/campaigns" }}
          itemslist={itemslist}
        />
      </div>
      {showSave && <SaveModel />}
      <div className="w-12 p-3 pt-1">
        <div
          className="m-auto   flex flex-row justify-content-between my-2 overflow-x-auto"
          style={{ background: "#f2f2f2" }}
        >
          <div className="p-2 py-3 flex flex-row  ">
            {steps.map((x, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-row gap-2 mr-3 cursor-pointer"
                  onClick={() => {
                    index <= completeSteps && x.show && switchform(x.url);
                  }}
                >
                  <div>
                    <Badge
                      value={index + 1}
                      style={{
                        background: index <= completeSteps ? `#1c738e` : `grey`,
                        opacity: `${x.show ? `1` : `.5`}`,
                      }}
                    />
                  </div>
                  <div
                    className={`white-space-nowrap  ${
                      x.panel ? `border-none border-bottom-1 border-solid` : ``
                    }`}
                    style={{ borderColor: "#1c738e" }}
                  >
                    {x.name}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-row gap-2 align-items-center px-2">
            <div
              className="font-medium text-sm cursor-pointer"
              onClick={cancelBroadcast}
            >
              Cancel
            </div>
            <div>
              <CustomButton
                varient="plain-button w-full"
                type="submit"
                onClick={() => {
                  navigate(
                    `${
                      id
                        ? `/runcampaign/selectcustomer/${id}`
                        : `/runcampaign/selectcustomer/new`
                    }`
                  );
                }}
                label={"Back"}
              />
            </div>
            <div>
              <CustomButton
                varient="filled w-full"
                type="submit"
                onClick={
                  schedule
                    ? handleSubmit(() => setShowSave(true))
                    : () => setShowSave(true)
                }
                disabled={schedule ? !isValid : schedule}
                label={<span>Broadcast</span>}
              />
            </div>
          </div>
        </div>
        <div className="m-auto p-2 mt-3 ">
          <div className="h-10 mb-4">
            <div className="flex flex-column align-items-start gap-2 ">
              <div className="flex flex text-4xl font-bold">
                Schedule Campaign
              </div>
              <div className="flex flex-row gap-3">
                Select a Date & Time To Schedule Launch
              </div>
            </div>
          </div>

          <div className="h-10 mb-2">
            <div className="flex flex-column align-items-start gap-2">
              <div
                className="flex flex-row align-items-center gap-2"
                onClick={() => {
                  setSchedule(false);
                  sendNow();
                }}
              >
                <RadioButton checked={!schedule} />
                <div>Send Now</div>
              </div>
              <div
                className="flex flex-row align-items-center gap-2 mt-2"
                onClick={() => {setAlert(true);setSchedule(true)}}
              >
                <RadioButton checked={schedule} />
                <div>On Specific Date</div>
              </div>
            </div>

            {schedule && (
              <div className="flex flex-column flex-wrap  w-11 md:w-11 lg:w-6 xl:w-5 justify-content-start md:pl-5 mt-2">
                <div className="card w-full  align-items-center flex flex-wrap">
                  <label htmlFor="startDate">
                    Start Date <span style={{ color: `#C6272D` }}>*</span>
                  </label>
                  <div className=" flex flex-row align-items-center w-12 flex-wrap">
                    <div className="field flex flex-row  align-items-center w-12 pt-1 md:pr-2">
                      {/* <label htmlFor="startDate" className="pt-1 pr-2" >Date</label> */}
                      <Controller
                        name="startDate"
                        control={control}
                        rules={{ required: "Please enter startDate." }}
                        render={({ field, fieldState }) => (
                          <Calendar
                            id={field.name}
                            showIcon={true}
                            placeholder="DD/MM/YYYY"
                            dateFormat="dd/mm/yy"
                            className={`sklbt-calender x-camp w-full mt-1 `}
                            value={field.value}
                            minDate={new Date()}
                            {...field}
                          />
                        )}
                      />
                      {getFormErrorMessage("startDate")}
                    </div>
                    {/* <div className="field flex flex-row  align-items-center w-12 lg:w-4 pr-2">
                                <label htmlFor="startTime" className="pt-1 pr-2" >Time</label>
                                <Controller
                                  name="startTime"
                                  control={control}
                                  rules={{ required: "Please enter Day Of Weeks." }}
                                  render={({ field, fieldState }) => (
                                    <MultiSelect 
                                      id={field.name}
                                      value={field.value} 
                                      options={time} 
                                      optionLabel="name" 
                                      display="chip" 
                                      placeholder="Select Time" 
                                      maxSelectedLabels={1} 
                                      className="w-full"
                                      {...field} 
                                      />
                                  )}
                                />
                                {getFormErrorMessage("runTime")}
                              </div> */}
                  </div>
                </div>

                <div className="card w-full  align-items-center flex flex-wrap">
                  <label htmlFor="endDate">
                    End Date <span style={{ color: `#C6272D` }}>*</span>
                  </label>
                  <div className=" flex flex-row align-items-center w-12 flex-wrap">
                    <div className="field flex flex-row  align-items-center pt-1 w-12 md:pr-2">
                      {/* <label htmlFor="endDate" className="pt-1 pr-2" >Date</label> */}
                      <Controller
                        name="endDate"
                        control={control}
                        rules={{ required: "Please enter endDate." }}
                        render={({ field, fieldState }) => (
                          <Calendar
                            id={field.name}
                            showIcon={true}
                            placeholder="DD/MM/YYYY"
                            dateFormat="dd/mm/yy"
                            className={`sklbt-calender x-camp w-full mt-1 `}
                            value={field.value}
                            minDate={new Date()}
                            {...field}
                          />
                        )}
                      />
                      {getFormErrorMessage("endDate")}
                    </div>
                    {/* <div className="field flex flex-row  align-items-center w-12 lg:w-4 pr-2">
                                <label htmlFor="endTime" className="pt-1 pr-2" >Time</label>
                                <Controller
                                  name="endTime"
                                  control={control}
                                  rules={{ required: "Please enter Day Of Weeks." }}
                                  render={({ field, fieldState }) => (
                                    <MultiSelect 
                                      id={field.name}
                                      value={field.value} 
                                      options={time} 
                                      optionLabel="name" 
                                      display="chip" 
                                      placeholder="Select Time" 
                                      maxSelectedLabels={1} 
                                      className="w-full"
                                      {...field} 
                                      />
                                  )}
                                />
                                {getFormErrorMessage("endTime")}
                              </div> */}
                  </div>
                </div>

                <div className="card w-full  align-items-center flex flex-wrap">
                  <div className="field w-full">
                    <label htmlFor="dayOfWeek">
                      Day Schedule <span style={{ color: `#C6272D` }}>*</span>
                    </label>
                    <div className="w-full sm:pr-2">
                      <Controller
                        name="dayOfWeek"
                        control={control}
                        rules={{ required: "Please enter Day Of Weeks." }}
                        render={({ field, fieldState }) => (
                          <MultiSelect
                            id={field.name}
                            value={field.value}
                            options={days}
                            optionDisabled={"disabled"}
                            optionLabel="name"
                            display="chip"
                            placeholder="Choose Day"
                            maxSelectedLabels={7}
                            className="w-full mt-1"
                            {...field}
                          />
                        )}
                      />
                      {getFormErrorMessage("dayOfWeek")}
                    </div>
                  </div>
                </div>

                <div className="card w-full  align-items-center flex flex-wrap ">
                  <label htmlFor="runTime w-full my-2">
                    Choose Time <span style={{ color: `#C6272D` }}>*</span>
                  </label>
                  <div className="field flex flex-row align-items-center w-12 pt-1">
                    {/* <label htmlFor="runTime" className="w-1 pr-1" >Time</label> */}
                    <div className="w-12  pr-2">
                      <Controller
                        name="runTime"
                        control={control}
                        rules={{ required: "Please enter Day Of Weeks." }}
                        render={({ field, fieldState }) => (
                          <MultiSelect
                            id={field.name}
                            value={field.value}
                            options={time}
                            optionLabel="name"
                            display="chip"
                            placeholder="Select Time"
                            maxSelectedLabels={7}
                            className="w-12 "
                            {...field}
                          />
                        )}
                      />
                      {getFormErrorMessage("runTime")}
                    </div>
                  </div>
                </div>

                {/* <div className="w-12 justify-content-center align-items-center flex">
                          or
                        </div> */}
                {/* <div className="card w-full  align-items-center flex flex-wrap">
                              <label htmlFor="startDate" >Choose Date & TIme </label>
                              <div className=" flex flex-row align-items-center w-12 flex-wrap">
                              <div className="field flex flex-row  align-items-center w-12 lg:w-8 pr-2">
                                <label htmlFor="startDate" className="pt-1 pr-2" >Date</label>
                                <Controller
                                  name="startDate"
                                  control={control}
                                  rules={{ required: "Please enter startDate." }}
                                  render={({ field, fieldState }) => (
                                    <Calendar
                                    id={field.name}
                                    showIcon={true}
                                    placeholder="DD/MM/YYYY"
                                    dateFormat="dd/mm/yy"
                                    className={`sklbt-calender x-camp w-full mt-1 `}
                                    value={field.value}
                                    minDate={new Date()}
                                    onChange={(e)=>{
                                      field.onChange(e)
                                      console.log(e)
                                      setValue('endDate',new Date(new Date(e)+1))
                                      setValue('endDate',[new Date(e).getDay])
                                    
                                    }}
                                    />
                                  )}
                                />
                                {getFormErrorMessage("startDate")}
                              </div>
                              <div className="field flex flex-row  align-items-center w-12 lg:w-4 pr-2">
                                <label htmlFor="runTime" className="pt-1 pr-2" >Time</label>
                                <Controller
                                  name="runTime"
                                  control={control}
                                  rules={{ required: "Please enter Day Of Weeks." }}
                                  render={({ field, fieldState }) => (
                                    <MultiSelect 
                                      id={field.name}
                                      value={field.value} 
                                      options={time} 
                                      optionLabel="name" 
                                      display="chip" 
                                      placeholder="Select Time" 
                                      maxSelectedLabels={1} 
                                      className="w-full"
                                      {...field} 
                                      />
                                  )}
                                />
                                {getFormErrorMessage("runTime")}
                              </div>
                              </div>
                        </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScheduleCamp;
