import { useEffect, useRef } from "react";
import QRCodeStyling from "qr-code-styling";
import { useSelector } from "react-redux";
import QrIcon from "../../../../assets/qrcenter.png";
import { CustomButton } from "../../../../components/CustomButton";

const PatternQr = ({ pattern = "", showQr = true, showDownload = true }) => {
  const { company } = useSelector((state) => state.company);
  const qrRef = useRef(null);

  const qrCode = new QRCodeStyling({
    width: 180,
    height: 180,
    image: QrIcon,
    dotsOptions: {
      color: "#000",
      type: "rounded",
    },
    imageOptions: {
      crossOrigin: "anonymous",
    },
    backgroundOptions: {
      color: "#f8f9fe",
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff",
      },
    },
    qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "L" },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.5, margin: 0 },
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#6a1a4c",
        color2: "#6a1a4c",
        rotation: "0",
      },
    },
    cornersSquareOptions: { type: "square", color: "#000000" },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    cornersDotOptions: { type: "", color: "#000000" },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
  });

  useEffect(() => {
    qrCode.update({
      data: `https://wa.me/${company?.phone}?text=${pattern}`,
    });
  }, [company?.phone]);

  const onDownloadClick = () => {
    qrCode.update({
      data: `https://wa.me/${company?.phone}?text=${pattern}`,
    });
    qrCode.download({
      extension: "png",
      name: `${pattern}_qr`,
    });
  };

  useEffect(() => {
    qrCode.append(qrRef.current);
  }, []);

  return (
    <div className="">
      {showQr && (
        <div className="flex justify-content-center mb-6" ref={qrRef}></div>
      )}
      {showDownload && (
        <div className="mt-2 flex justify-content-center">
          <CustomButton
            icon={"pi pi-qrcode"}
            varient="text"
            type={"button"}
            onClick={onDownloadClick}
            label={"Download QR"}
          />
        </div>
      )}
    </div>
  );
};

export default PatternQr;
