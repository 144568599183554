import {
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from "react-phone-number-input";
function formatMobNumber(mob) {
  if (!mob) return "";
  mob = "+" + mob.toString();
  let phoneParseData = parsePhoneNumber(mob);
  let phoneNumber = phoneParseData?.number;
  return formatPhoneNumberIntl(phoneNumber) || mob;
}

function maskMobNumber(mob) {
  if (!mob) return "";
  mob = "+" + mob.toString();
  let phoneParseData = parsePhoneNumber(mob);
  let phoneNumber = phoneParseData?.number;
  const countryCode = phoneParseData?.countryCallingCode;
  // const lastTwoDigits = phoneNumber.slice(-2);
  const hiddenDigits = 'X'.repeat(phoneParseData.nationalNumber.length); // Replace middle digits with X
  return `+${countryCode} ${hiddenDigits}`;
}

function capitalizeFLetter(string = "") {
  return string[0]?.toUpperCase() + string?.slice(1)?.toLowerCase();
}
function isItemInList(itemArr = [], item) {
  for (let i = 0; i < itemArr.length; i++) {
    if (itemArr[i].id == item?.id) return true;
  }
  return false;
}
function getUnselectedItems(itemArr, selectedItems) {
  let unselectedItems = [];
  for (let i = 0; i < itemArr.length; i++) {
    if (!isItemInList(selectedItems, itemArr[i]))
      unselectedItems.push(itemArr[i]);
  }
  return unselectedItems;
}

function SKUCodefilter(string = "") {
  let str = string.split(".")[0];
  str = str.split(" ")[0];
  return str.split("-")[0];
}
function removeCountryCode(phoneNumber) {
  const phoneNumberString = String(phoneNumber);
  const cleanedNumber = phoneNumberString.split("").slice(2).join("");
  return cleanedNumber;
}

function isTextOrNumber(input) {
  if (typeof input !== "string") {
    return false;
  }
  const numberRegex = /^\d+$/;
  if (numberRegex.test(input)) {
    return true;
  }
  return false;
}

function removePlusFormPhoneNumber(value) {
  let mob = value ? value.toString() : "";
  if (mob && mob[0] == "+") {
    return value.slice(1);
  }
  return value;
}

function fileSizeInMB(file) {
  // Get file size in bytes
  const fileSizeInBytes = file.size;

  // Convert bytes to MB
  const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

  return fileSizeInMB.toFixed(2); // Round to 2 decimal places
}
export {
  formatMobNumber,
  maskMobNumber,
  capitalizeFLetter,
  isItemInList,
  getUnselectedItems,
  SKUCodefilter,
  removeCountryCode,
  isTextOrNumber,
  fileSizeInMB,
  removePlusFormPhoneNumber,
};
