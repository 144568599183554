import { useDispatch, useSelector } from "react-redux";
import { StartTag } from "../StartTag";
import { Handle, Position } from "reactflow";
import EditButton from "../Editbutton";
import { NodeHeader } from "../NodeHeader";
import styles from "./style.module.css";
import { Text } from "../../Text";
import {
  changeSelectedNode,
  closeConfigScreen,
  openConfigScreen,
  undoNodeData,
} from "../../../reducers/canvas/canvasSlice";
import { getTheNode } from "../../../utils/canvasUtils";
import { handleInputType } from "../../../reducers/multiInputSlice";
export const TemplateNode = (props) => {
  const { content, options } = props.data;

  const { nodes, selectedNode, rootStepId, configScreenOpen } = useSelector(
    (state) => state.canvas
  );
  const dispatch = useDispatch();

  const handleNodeSelection = () => {
    if (selectedNode?.id !== props.id) {
      if (configScreenOpen) {
        dispatch(undoNodeData());
        dispatch(closeConfigScreen());
      }
      dispatch(changeSelectedNode(getTheNode(nodes, props?.id)));
    }
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    dispatch(undoNodeData());
    dispatch(handleInputType(props.data.type));
    dispatch(openConfigScreen(getTheNode(nodes, props?.id)));
  };
  return (
    <>
      {rootStepId == props?.id && <StartTag />}
      <div
        onClick={handleNodeSelection}
        className={`${styles.textNode}
        ${selectedNode?.id == props?.id ? styles["selected-node"] : ""}
        `}
      >
        <Handle type="target" position={Position.Left} />
        <div className={`${styles.nodeData} p-2 w-full`}>
          <div className={`w-full h-full `}>
            <EditButton onClick={handleEditClick} />
            <NodeHeader nodeType={props.data.type} />

            <div className={` mt-2 p-2 ${styles["text-message"]}`}>
              <Text type={"sub-heading"}>
                <div
                  style={{ whiteSpace: "pre-line" }}
                  className={`w-full mt-2 `}
                >
                  {content.header}
                </div>
              </Text>
              <Text type={"small-text"}>
                <div
                  style={{ whiteSpace: "pre-line" }}
                  className={`w-full mt-2 `}
                >
                  {content.data}
                </div>
              </Text>
              <div className="py-1 w-full">
                <div className="flex gap-2 flex-column mb-2">
                  {options?.map((btn, i) => (
                    <button
                      style={{
                        flexGrow: "1",
                        padding: "4px",
                        fontSize: "0.8rem",
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #ECECEC",
                        borderRadius: "4px",
                      }}
                      key={btn.id}
                      onClick={() => {}}
                    >
                      {`${btn.title}` || <i>label text ...</i>}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
      </div>
    </>
  );
};
