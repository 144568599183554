import { Outlet, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";

function Categories() {
  const location = useLocation();
  const currentPath = location.pathname;
  if (currentPath == "/categories" || currentPath == "/categories/") {
    return <Navigate to={"/categories/list"} replace />;
  }
  return <Outlet />;
}

export default Categories;
