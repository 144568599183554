import React, { useState, useEffect } from "react";
import styles from "./proPlan.module.css";
import { ReactComponent as RupeeSvg } from "../../../assets/welcomeScreen/paymentPlan/rupeeWhite.svg";
import plansRightCheckSvg from "../../../assets/welcomeScreen/paymentPlan/plansRightCheck1.jpg";
import starSvg from "../../../assets/welcomeScreen/paymentPlan/star.svg";
import {
  ProPlanData,
  amounts,
  conversationDetails,
  additionalUserLicenseDetails,
  additionalUserLicenseCost,
  currencyIcons,
} from "../../../assets/welcomeScreen/ProPlanData";
import { ReactComponent as DollarSvg } from "../../../assets/welcomeScreen/paymentPlan/dollarWhite.svg";

const ProPlan = () => {
  const [amount, setAmount] = useState("Loading");
  const [country, setCurrencyIcon] = useState(null);

  useEffect(() => {
    const fetchAmount = () => {
      const userLocation = localStorage.getItem("SkalebotuserLocation");
      if (userLocation) {
        const parsedLocation = JSON.parse(userLocation);
        const countryCode =
          parsedLocation.country === "IN"
            ? parsedLocation.country
            : "otherCountry";
        setAmount(amounts[countryCode]);
        setCurrencyIcon(countryCode);
      } else {
        setAmount(amounts["IN"]);
        setCurrencyIcon("IN");
        setTimeout(fetchAmount, 1000);
      }
    };

    fetchAmount();
  }, []);

  // Ensure ProPlanData[country] is defined before mapping over it
  const serviceItems = ProPlanData[country] || [];

  return (
    <div className={styles.ProPlancontainer}>
      <div className={styles.PlanNameContainer}>
        <p className={styles.PlanName}> Pro</p>
      </div>
      <div className={styles.pricingContainer}>
        <p className={styles.pricingdetails}>
          {" "}
          Everything tailored for your success and business growth on WhatsApp.
        </p>
        <div className="flex align-items-center  gap-1">
          {country === "IN" ? (
            <RupeeSvg className={styles.rupee} />
          ) : (
            <DollarSvg className={styles.dollar} />
          )}
          <p className={styles.amount}>{amount}</p>
        </div>
        <p className={styles.pricingdetails} style={{ fontWeight: 700 }}>
          ( Per Month )
        </p>
      </div>
      <div className={styles.conversationContainer}>
        <p className={styles.conversation}>{conversationDetails[country]}</p>
        <p className={styles.rechargeText}>
          {" "}
          Additional users licence <br />
          <span style={{ fontWeight: 500 }}>
            {additionalUserLicenseCost[country]}{" "}
          </span>
        </p>
      </div>
      <div className="flex justify-content-center">
        <div
          style={{ background: "#D9D9D9", width: "85%", height: "1px" }}
        ></div>
      </div>
      <div className={styles.serviceContainer}>
        <p className={`${styles.services} my-1 `}>Services Included</p>
        <div className="flex align-items-start gap-2 mb-2 ">
          <p className={styles.servicesListItem}>everything in Standard +</p>
        </div>
        {serviceItems.map((data, index) => (
          <div className="flex align-items-start gap-2 my-2" key={index}>
            <img
              src={plansRightCheckSvg}
              alt="plans"
              title="RightCheck"
              className={styles.plansCheck}
            />
            <p className={styles.servicesListItem}>{data}</p>
          </div>
        ))}
      </div>
      <div className={styles.tag}>
        <img src={starSvg} alt="star" title="star" />
        <p className={styles.pricingdetails}>Most Popular</p>
      </div>
    </div>
  );
};

export default ProPlan;
