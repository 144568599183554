import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Loader from "../../components/Loader";
import { Text } from "../../components/Text";
import { ReactComponent as Edit } from "../../svg/edit.svg";
import { CustomButton } from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { getDate } from "../../utils/datemaker";
import * as Messag from "../../config/ToastMessage";
import {
  changeSelectedOrder,
  updateMode,
  resetMode,
  updateOrder,
  resetSelectedOrder,
  updateSelectedOrdersList,
  resetSelectedOrdersList,
  getOrdersbyid,
} from "../../reducers/orderTableSlice";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import "./style.css";
import OrderPreview from "./OrderPreview";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import CustomSaveModel from "../../components/CustomeSaveModel";
import CONSTANTS from "../../config/Constants";
import { ReactComponent as PrintIcon } from "../../svg/printicon.svg";
import { formatMobNumber } from "../../utils/commonUtils";
import { Image } from "primereact/image";
import ImageGallary from "../../components/common/ImageGallary";
import TrailDashboard from "../TrailDashboard";
const statusOptions = [
  { key: "New", value: "New" },
  { key: "In Progress", value: "In Progress" },
  { key: "Delivered", value: "Delivered" },
  { key: "Cancelled", value: "Cancelled" },
];
const OrderDetails = () => {
  const {
    orderData,
    loading,
    mode,
    selectedOrder,
    selectedOrderId,
    selectedOrderProducts,
    selectedOrdersList,
  } = useSelector((state) => state.orderTable);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getOrdersbyid({ orderId: id }))
        .unwrap()
        .then((e) => {
          const selectedOrders = [e];
          dispatch(updateSelectedOrdersList({ selectedOrders }));
        })
        .catch((err) => {
          navigate("/orders");
        });
    } else {
      dispatch(updateSelectedOrdersList({ selectedOrders: [] }));
      dispatch(resetSelectedOrder());
    }
  }, [id]);
  const { userSub } = useSelector((state) => state.authenticate);

  const [status, setStatus] = useState(selectedOrder.status);
  const [previewOrder, setPreviewOrder] = useState(false);

  const dispatch = useDispatch();

  const toast = useRef(null);
  const modalLoad = () => {
    return <Loader visible={loading} />;
  };

  const handleEdit = (rowdata) => {
    if (rowdata.id !== "all" && !rowdata["orderLabel"]) {
      let orderWithLabel = {
        ...rowdata,
        orderLabel: `Order Id #${rowdata.id}`,
      };
      dispatch(changeSelectedOrder(orderWithLabel));
    } else {
      dispatch(changeSelectedOrder(rowdata));
    }
    dispatch(updateMode("update"));
  };
  const [showSave, setShowSave] = useState(false);
  const status_value = useRef(null);

  const SaveModel = () => {
    return (
      <CustomSaveModel
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };

  const alertText = () => {
    let value =
      status_value?.current?.charAt(0)?.toUpperCase() +
      status_value?.current?.slice(1);
    return (
      <div>
        {CONSTANTS.ORDER_STATUS_CONFIRMATION.replace("{{value}}", value)}
      </div>
    );
  };

  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false);
          }}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={() => {
            handleSaveStatus();
            setShowSave(false);
          }}
        />
      </div>
    );
  };

  const onHide = () => {
    setShowSave(false);
  };

  const handleSaveStatus = () => {
    const orderId = selectedOrder.id;
    const updatedStatus = {
      status: status,
    };
    dispatch(updateOrder({ orderId, updatedStatus }))
      .unwrap()
      .then((res) => {
        if (updatedStatus.status === "Cancelled") {
          let Message_Success = Messag.Cancel_Order_ToastSuccessMessage;
          toast.current.show({ severity: "success", detail: Message_Success });
        }
        if (updatedStatus.status === "Delivered") {
          let Message_Success = Messag.Delivered_Order_ToastSuccessMessage;
          toast.current.show({ severity: "success", detail: Message_Success });
        }
        if (updatedStatus.status === "In Progress") {
          let Message_Success = Messag.Progress_Order_ToastSuccessMessage;
          toast.current.show({ severity: "success", detail: Message_Success });
        }
      })
      .catch((err) => {
        //show toast from here
        toast.current.show({ severity: "error", detail: err.response.data });
      });
  };

  const handleCancelUpdate = () => {
    dispatch(resetMode());
  };

  const actionBodyTemplate = (rowData) => {
    if (mode === "update" && selectedOrder.id === rowData.id) {
      return (
        <div className="flex gap-2">
          <CustomButton
            varient="icon-button"
            onClick={() => {
              setShowSave(true);
            }}
            icon={"pi pi-check"}
          />
          <CustomButton
            varient="icon-button"
            onClick={() => handleCancelUpdate()}
            icon={"pi pi-times"}
          />
        </div>
      );
    } else {
      return (
        <CustomButton
          varient="icon-button"
          onClick={() => handleEdit(rowData)}
          icon={<Edit />}
        />
      );
    }
  };

  useEffect(() => {
    if (selectedOrderId !== "all") {
      setStatus(selectedOrder.status);
    }
  }, [selectedOrder]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedOrdersList());
      dispatch(resetSelectedOrder());
    };
  }, []);

  const imageBodyTemplate = (rowData) => {
    console.log("rowData", rowData);

    return (
      <div
        className="flex justify-content-center"
        style={{ width: "120px", height: "60px" }}
      >
        <div>
          <Image
            src={`${rowData.images}`}
            alt="productImg"
            onError={(e) => (e.target.src = "/images/ImgPlaceholder.svg")}
            width="100%"
            preview
            imageStyle={{ objectFit: "contain" }}
          />
        </div>

        {/* <img
          src={`${rowData.images}`}
          alt="productImg"
          onError={(e) => (e.target.src = "/images/ImgPlaceholder.svg")}
          style={{ maxWidth: "100%", height: "100%" }}
        /> */}
      </div>
    );
  };

  const priceBodyTemplate = (rowData) => {
    return `${rowData.price} ${rowData.currency}`;
  };
  const skuBodyTemplate = (rowData) => {
    return `${rowData.SKUCode} `;
  };

  const phoneBodyTemplate = (rowdata) => {
    return rowdata?.phone ? formatMobNumber(rowdata.phone) : ``;
  };
  const navigate = useNavigate();
  const handleOrderSelect = (order) => {
    dispatch(resetSelectedOrdersList());
    if (order.id !== selectedOrderId) {
      if (mode === "update") dispatch(resetMode());

      if (order.id !== "all" && !order["orderLabel"]) {
        let orderWithLabel = { ...order, orderLabel: `Order Id #${order.id}` };
        dispatch(changeSelectedOrder(orderWithLabel));
        navigate(`/orders/${order.id}`);
      } else {
        dispatch(changeSelectedOrder(order));
      }
    } else return;
  };

  ///filter--------------------------------
  const [filtersData, setFiltersData] = useState({
    name: null,
    status: null,
  });

  const [filters1, setFilters1] = useState({
    name: { value: null },
    status: { value: null },
  });

  // order printout
  const openPreview = () => {
    setPreviewOrder(true);
  };

  const orderPreviewModal = () => {
    return (
      <OrderPreview
        previewOrder={previewOrder}
        setPreviewOrder={setPreviewOrder}
        toast={toast}
      />
    );
  };

  const onSelectOrder = (e) => {
    const selectedOrders = e.value;
    const currOrders = orderData;
    dispatch(updateSelectedOrdersList({ selectedOrders, currOrders }));
  };

  const itemslist = [{ label: `# ${id}`, url: "#" }];

  const statusModule = (rowData) => {
    if (mode === "update" && selectedOrder?.id === rowData.id) {
      return (
        <Dropdown
          value={status}
          onChange={(e) => {
            status_value.current = e.target.value;
            setStatus(e.target.value);
          }}
          options={statusOptions}
          optionLabel="key"
          optionValue="value"
        />
      );
    } else return rowData.status;
  };

  return (
    <div>
      <SaveModel />
      <CustomBreadcrumb
        home={{ label: "Orders", url: "/orders" }}
        itemslist={itemslist}
      />
      <div className="p-3">
        <Toast ref={toast} />
        {!userSub.isUserSub ? (
          <TrailDashboard/>
        ) : (
          <div>
            <div className="card">
              <div className="mb-3">
                <TableActionsContainer>
                  <div className="flex justify-content-between align-items-center flex-wrap gap-3">
                    <div className="flex gap-3 justify-content-between align-items-center flex-grow-1">
                      <Text type={"heading"}>Order Details</Text>

                      <div className="flex gap-2">
                        <CustomButton
                          label={"Back"}
                          varient="text"
                          onClick={() => navigate("/orders")}
                        />
                        <div
                          className={` cursor-pointer ${
                            !selectedOrdersList?.length ? "opacity-60" : ""
                          }`}
                          onClick={() => {
                            selectedOrdersList?.length && openPreview();
                          }}
                        >
                          <PrintIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                </TableActionsContainer>
              </div>

              {modalLoad()}
              {previewOrder ? orderPreviewModal() : <></>}
              <DataTable
                value={[selectedOrder]}
                className="skalebot-table"
                dataKey="id"
              >
                <Column
                  field="orderNumber"
                  header={<span>Order&nbsp;No.</span>}
                  className="text-sm font-normal"
                  bodyClassName={"white-space-nowrap"}
                />
                {/* <Column field="id" header="Order Id" /> */}
                <Column
                  field="createdAt"
                  header="Date"
                  style={{ width: "30ch" }}
                  className="text-sm font-normal"
                  body={(rowData) =>
                    rowData?.createdAt ? getDate(rowData.createdAt) : ""
                  }
                  bodyStyle={{ textTransform: "uppercase" }}
                  bodyClassName={"white-space-nowrap"}
                />
                <Column
                  field={(rowData) =>
                    rowData.businessName ? rowData.businessName : rowData.name
                  }
                  header="Name"
                  className="capitalize text-sm font-normal"
                  bodyClassName={"white-space-nowrap"}
                />
                <Column
                  field="phone"
                  header="Phone"
                  style={{ width: "30ch" }}
                  body={(rowData) => formatMobNumber(rowData?.phone)}
                  bodyClassName={"white-space-nowrap"}
                />
                <Column
                  field="status"
                  header={() => (
                    <div className="flex gap-2 align-items-end">
                      <Text>Status</Text>
                    </div>
                  )}
                  body={statusModule}
                  style={{ width: "20ch" }}
                  bodyClassName={"white-space-nowrap"}
                  className="text-sm font-normal"
                />
                <Column
                  header="Actions"
                  body={actionBodyTemplate}
                  bodyStyle={{ display: "flex", justifyContent: "flex-around" }}
                />
              </DataTable>
            </div>

            <div className="card my-3">
              <DataTable
                value={selectedOrderProducts}
                className="skalebot-table"
                // header={
                //   <div className="my-3 ml-3">
                //     <Text type={"sub-heading"}>Product list</Text>
                //   </div>
                // }
              >
                <Column
                  field="product_retailer_id"
                  className="text-sm font-normal"
                  header={<span>SKU&nbsp;Code</span>}
                  body={skuBodyTemplate}
                  bodyClassName={"white-space-nowrap"}
                />
                <Column
                  field="productName"
                  className="text-sm font-normal"
                  header={<span>Product&nbsp;Name</span>}
                  bodyClassName={"white-space-nowrap"}
                />
                <Column
                  header="Price"
                  className="text-sm font-normal"
                  body={priceBodyTemplate}
                  bodyClassName={"white-space-nowrap"}
                />
                <Column
                  field="quantity"
                  className="text-sm font-normal"
                  header="Quantity"
                  bodyClassName={"white-space-nowrap"}
                />
                <Column
                  header="Image"
                  body={(rowData) => (
                    <ImageGallary
                      images={rowData?.images?.map((url) => {
                        return { url: url };
                      })}
                    />
                  )}
                  bodyStyle={{ display: "flex" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
