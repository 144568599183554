import { CustomButton } from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { useEffect } from "react";
import { Badge } from "primereact/badge";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import {
  changestepcount,
  resetBroadcast,
  setBroadcastDetails,
} from "../../reducers/ShareMessage/messageSlice";
import { getIds } from "../../utils/shareUtils";
import { Toast } from "primereact/toast";
import { resetAll } from "../../reducers/productTableSlice";
import { ModSelectableCustomer } from "../../components/ModSelectableCustomer";
import { useUtility } from "../../context/UtilityContext";

const addsteps = (name, value, url, show = true, panel = false) => {
  return { name, value, url, show, panel };
};

export const CampaignCustomer = () => {
  const {
    completeSteps,
    broadcastdetails,
    selectedTemplate,
    selectedGroupId,
    selectedCustomers,
  } = useSelector((state) => state.shareMessage);
  const { id } = useParams();
  const toast = useRef(null);
  const dispatch = useDispatch();

  if (selectedTemplate) {
    var { campaignType } = selectedTemplate;
  }
  const steps = [];
  steps.push(
    addsteps(
      "Information",
      "info",
      `${id ? `/runcampaign/${id}` : `/runcampaign/new`}`
    )
  );
  steps.push(
    addsteps(
      "Edit Template",
      "info",
      `${
        id
          ? `/runcampaign/selecttemplate/${id}`
          : `/runcampaign/selecttemplate/new`
      }`
    )
  );
  steps.push(
    addsteps(
      "Select Product",
      "prod",
      `${
        id
          ? `/runcampaign/selectproducts/${id}`
          : `/runcampaign/selectproducts/new`
      }`,
      campaignType === "product"
    )
  );
  steps.push(
    addsteps(
      "Select Customer",
      "cust",
      `${
        id
          ? `/runcampaign/selectcustomer/${id}`
          : `/runcampaign/selectcustomer/new`
      }`,
      true,
      true
    )
  );
  steps.push(
    addsteps(
      "Schedule Campaign",
      "camp",
      `${id ? `/runcampaign/schedule/${id}` : `/runcampaign/schedule/new`}`
    )
  );

  const onSubmit = (data) => {
    // console.log(selectedCustomers,selectedGroupId)
    if (selectedCustomers.length == 0 && selectedGroupId == "all") {
      toast.current.show({
        severity: "warn",
        detail: "Please select customers or any group.",
      });
      return;
    }
    const selectedCustomerIds =
      selectedGroupId == "all" ? getIds(selectedCustomers) : [];
    const groupIds = selectedGroupId !== "all" ? [selectedGroupId] : [];
    const configData = {
      ...broadcastdetails?.configData,
      groupIds: groupIds,
      customerIds: selectedCustomerIds,
    };
    dispatch(
      setBroadcastDetails({ configData, media: broadcastdetails?.media })
    );
    dispatch(changestepcount(4));
    navigate(
      `${id ? `/runcampaign/schedule/${id}` : `/runcampaign/schedule/new`}`
    );
  };

  const itemslist = [{ label: "Run Campaign", url: "" }];
  const navigate = useNavigate();
  const { navigation } = useUtility();
  const switchform = (link) => {
    navigate(link);
  };
  const cancelBroadcast = () => {
    dispatch(resetBroadcast());
    dispatch(resetAll());
    navigation(`${id ? `/campaigns/${id}` : `/campaigns`}`);
  };

  useEffect(() => {
    if (completeSteps === 0) {
      navigate(`${id ? `/runcampaign/${id}` : `/runcampaign/new`}`);
    }
  }, [completeSteps]);

  return (
    <div className="w-full m-auto ">
      <Toast ref={toast} />
      <div className="w-full m-auto overflow-x-auto">
        <CustomBreadcrumb
          home={{ label: "Campaign", url: "/campaigns" }}
          itemslist={itemslist}
        />
      </div>
      <div className="p-3 pt-1">
        <div
          className="w-full m-auto flex flex-row justify-content-between my-2 overflow-x-auto "
          style={{ background: "#f2f2f2" }}
        >
          <div className="p-2 py-3 flex flex-row ">
            {steps.map((x, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-row gap-2 mr-3 cursor-pointer"
                  onClick={() => {
                    index <= completeSteps && x.show && switchform(x.url);
                  }}
                >
                  <div>
                    <Badge
                      value={index + 1}
                      style={{
                        background: index <= completeSteps ? `#1c738e` : `grey`,
                        opacity: `${x.show ? `1` : `.5`}`,
                      }}
                    />
                  </div>
                  <div
                    className={`white-space-nowrap  ${
                      x.panel ? `border-none border-bottom-1 border-solid` : ``
                    }`}
                    style={{ borderColor: "#1c738e" }}
                  >
                    {x.name}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-row gap-2 align-items-center px-2">
            <div
              className="font-medium text-sm cursor-pointer"
              onClick={cancelBroadcast}
            >
              Cancel
            </div>
            <div>
              <CustomButton
                varient="plain-button w-full"
                type="submit"
                onClick={() => {
                  navigate(
                    campaignType === "product"
                      ? `${
                          id
                            ? `/runcampaign/selectproducts/${id}`
                            : `/runcampaign/selectproducts/new`
                        }`
                      : `${
                          id
                            ? `/runcampaign/selecttemplate/${id}`
                            : `/runcampaign/selecttemplate/new`
                        }`
                  );
                }}
                label={"Back"}
              />
            </div>
            <div>
              <CustomButton
                varient="filled w-full"
                type="submit"
                // disabled={selectedCustomers.length == 0 || selectedGroupId == "all"}
                onClick={onSubmit}
                label={<span>Save&nbsp;&&nbsp;Continue</span>}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full flex-wrap-reverse">
          <div className="flex flex-column w-12 flex-grow-1 lg:flex-grow-0 xl:flex-grow-0 justify-content-center ">
            <form onSubmit={onSubmit} className="p-fluid w-full">
              <ModSelectableCustomer />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
