import { useEffect, useRef, useState } from "react";
import { ReactComponent as ChatIcon } from "../../assets/sidebarIcons/chaticonblack.svg";
import { useNavigate } from "react-router-dom";
import { SideIcon } from "../SideIcon";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "primereact/badge";
import { getCompany } from "../../reducers/companySlice";
import styles from "./style.module.css";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import { useUtility } from "../../context/UtilityContext";

function Sidebar({ type, setType }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, accesskey } = useSelector((state) => state.authenticate);

  const navlist = [
    {
      label: "Dashboard",
      icon: <SideIcon icon={"dashboardb"} />,
      sicon: <SideIcon icon={"dashboard"} />,
      path:
        user?.role === "admin" || user?.role === "campaign"
          ? ["", "dashboard"]
          : ["dashboard"],
      navigate: "/dashboard",
      show: accesskey[modulesName.DASHBOARD]?.includes(permissions.READ),
    },
    {
      label: "Campaigns",
      icon: <SideIcon icon={"campaignb"} />,
      sicon: <SideIcon icon={"campaign"} />,
      path: ["campaigns", "runcampaign"],
      navigate: "/campaigns",
      show: accesskey[modulesName.CAMPAIGN]?.includes(permissions.READ),
    },
    {
      label: "Customers",
      icon: <SideIcon icon={"customerb"} />,
      sicon: <SideIcon icon={"customer"} />,
      path: ["customers"],
      navigate: "/customers",
      show: accesskey[modulesName.CUSTOMER]?.includes(permissions.READ)||accesskey[modulesName.CUSTOMER]?.includes(permissions.CREATE),
    },
    {
      label: "Enquiries",
      icon: <SideIcon icon={"enquiryb"} />,
      sicon: <SideIcon icon={"enquiry"} />,
      path: ["enquries"],
      navigate: "/enquries",
      show: accesskey[modulesName.ENQUIRES]?.includes(permissions.READ),
    },
    {
      label: "Products",
      icon: <SideIcon icon={"productb"} />,
      sicon: <SideIcon icon={"product"} />,
      path: ["products"],
      navigate: "/products/list",
      show: accesskey[modulesName.PRODUCT]?.includes(permissions.READ),
    },
    {
      label: "Categories",
      icon: <SideIcon icon={"categoryb"} />,
      sicon: <SideIcon icon={"category"} />,
      path: ["categories"],
      navigate: "/categories",
      show: accesskey[modulesName.CATEGORY]?.includes(permissions.READ),
    },
    {
      label: "Orders",
      icon: <SideIcon icon={"orderb"} />,
      sicon: <SideIcon icon={"order"} />,
      path: ["orders"],
      navigate: "/orders",
      show: accesskey[modulesName.ORDER]?.includes(permissions.READ),
    },
    {
      label: "Templates",
      icon: <SideIcon icon={"template"} />,
      sicon: <SideIcon icon={"template"} />,
      path: ["campaign-templates"],
      navigate: "/campaign-templates",
      show: accesskey[modulesName.TEMPLATE]?.includes(permissions.READ),
    },
    {
      label: "Tickets",
      icon: <SideIcon icon={"ticket"} />,
      sicon: <SideIcon icon={"ticket"} />,
      path: ["ticket"],
      navigate: "/ticket",
      show: accesskey[modulesName.TICKET]?.includes(permissions.READ),
    },
    {
      label: "Account Settings",
      icon: <SideIcon icon={"settingb"} />,
      sicon: <SideIcon icon={"setting"} />,
      path: ["account"],
      navigate: "/account",
      show: accesskey[modulesName.ACCOUNT]?.includes(permissions.READ),
    },
    {
      label: "Sales Support",
      icon: <SideIcon icon={"chats"} />,
      sicon: <SideIcon icon={"chats"} />,
      path: ["chat"],
      navigate: "/chat",
      show: accesskey[modulesName.SALES]?.includes(permissions.READ),
      onOpennav: true,
    },
  ];

  const allowedRoutesForCampaign = [
    "/dashboard",
    "/campaigns",
    "/products/list",
    "/orders",
    "/enquries",
    "/categories",
    "/customers",
    "/campaign-templates",
    "/chat",
  ];

  const allowedRoutesForSupport = ["/campaigns", "/customers", "/chat"];

  const [head, sethead] = useState(`/`);
  useEffect(() => {
    let href = document.location.href
      .slice(document.location.origin.length)
      .split("/")
      .slice(1);
    if (href.length > 1) {
      href = href.slice((x) => x !== "");
    }

    sethead(href);
  }, [document.location.href]);

  const { navigation } = useUtility();
  const nav = (path) => {
    navigation(path);
  };

  const includes = (x) => {
    for (let i = 0; i < x.length; i++) {
      if (head.includes(x[i])) {
        return true;
      }
    }
    return false;
  };
  const [userName, setUserName] = useState("");
  useEffect(() => {
    if (user) {
      let x = user?.userName;
      x = x
        ?.split(" ")
        .map((x) => {
          return x[0];
        })
        .join("")
        .toUpperCase();
      setUserName(x);
      dispatch(getCompany(user?.companyId))
        .unwrap()
        .catch((err) => {
          console.log("err get comp", err);
        });
    }
  }, [user]);
  return (
    <div className={`s_c ${type ? `dis-block` : `dis-open`} `}>
      <div
        className={`bg-white pt-3 ${
          type ? `sidebar minw` : `sidebar-s w-4rem`
        }`}
        style={{ border: "none" }}
      >
        <div className="pt-2">
          {navlist.map((x, key) => {
            const isNotHidden = x.show;
            if (!isNotHidden) return <div key={key}></div>;
            else
              return (
                <div
                  key={key}
                  className={`flex pl-3 mb-1  sideitem w-full align-content-center cursor-pointer ${
                    includes(x.path) ? `selected-el` : ``
                  }`}
                  onClick={() => {
                    if (isNotHidden) {
                      nav(x.navigate);
                      if (window.innerWidth < 768 || x.onOpennav) {
                        setType(false);
                      }
                    }
                  }}
                  title={x.label}
                >
                  <div
                    className={`mr-2 ${
                      !includes(x.path) ? "unselected-icon" : "selected-icon"
                    }`}
                  >
                    {x.icon}
                  </div>
                  {type && (
                    <div
                      className=""
                      style={{
                        fontWeight: !includes(x.path) ? `400` : `600`,
                      }}
                    >
                      {x.label}
                    </div>
                  )}
                </div>
              );
          })}
        </div>

        <div
          className={`flex flex-row pl-4 py-2 gap-2 cursor-pointer mt-2 align-items-center`}
          style={{ background: "#F8F9FE" }}
          onClick={() => {
            nav("/userprofile");
            if (window.innerWidth < 768) {
              setType(false);
            }
          }}
        >
          <div>
            <Badge
              value={userName}
              className={`text-xs text-white border-circle`}
              style={{ background: `#000` }}
              size={"medium"}
            >
              {" "}
            </Badge>
          </div>
          {type && (
            <div className={`flex flex-column `}>
              <div
                className={`text-sm capitalize bold `}
                style={{ color: `#000` }}
              >
                {user?.userName}
              </div>
              <div className={`text-xs`} style={{ color: `#000` }}>
                {`${user?.role?.charAt(0).toUpperCase()}${user?.role?.substring(
                  1
                )} User`}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
