import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  addNewOption,
  deleteNodeData,
  deleteOption,
  updateNodePayload,
  updateOptionTitle,
} from "../../reducers/canvas/canvasSlice";
import { PiTextTBold } from "react-icons/pi";
import { BsImageFill } from "react-icons/bs";
import { FaRegFileVideo } from "react-icons/fa";
import imageCompression from "browser-image-compression";
import { API_GET_FILE_URL } from "../../api/media.service";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
import { CustomButton } from "../CustomButton";
import { InputTextarea } from "primereact/inputtextarea";
import { Text } from "../Text";
import SklbtConfirmation from "../common/SklbtConfirmation";
import axios from "axios";
import CustomProgressBar from "../CustomProgressBar";
import { RadioButton } from "primereact/radiobutton";
import { iSValidFileSize, isOptionValueValid } from "../../utils/canvasUtils";
import {
  IMAGE_SIZE_LIMIT_IN_MB,
  VIDEO_SIZE_LIMT_IN_MB,
} from "./multiInput-constant";
import StartNodeCheckMark from "../../view/Bots/components/start-node-checkmark";

const QuickReply = ({ onSave = () => {}, onCancel = () => {} }) => {
  const dispatch = useDispatch();
  const mediaFileRefQuick = useRef(null);
  const [inputs, setInputs] = useState([]);
  const [quickReplyText, setQuickReplyText] = useState("");
  const [fileChoosen, setFileChoosen] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [removeFileAlert, setRemoveFileAlert] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [tempActiveIndex, setTempActiveIndex] = useState(0);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isUploading, setUploading] = useState(false);

  const toast = useRef(null);

  const items = [
    { icon: PiTextTBold, name: "Text" },
    { icon: BsImageFill, name: "Image" },
    { icon: FaRegFileVideo, name: "Video" },
  ];

  const { selectedNode } = useSelector((state) => state.canvas);

  const handleAddButton = () => {
    dispatch(
      addNewOption({
        selectedNodeId: selectedNode.id,
        newOption: {
          id: uuidv4(),
          type: "text",
          title: "",
          postbackText: "",
        },
      })
    );
  };

  const handleDeleteInput = (id) => {
    setInputs((prevbtnInputs) =>
      prevbtnInputs?.filter((btnInput) => btnInput.id != id)
    );
    dispatch(deleteOption({ nodeId: selectedNode.id, optionId: id }));
  };

  const handleInputChange = (id, value) => {
    const updatedInputs = inputs.map((input) =>
      input.id === id ? { ...input, value } : input
    );
    setInputs(updatedInputs);
    dispatch(
      updateOptionTitle({
        selectedNodeId: selectedNode.id,
        optionId: id,
        optionTitle: value,
      })
    );
  };

  const handleQuickReplyText = (e) => {
    setQuickReplyText(e.target.value);
    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode.id,
        updatedData: { label: e.target.value },
      })
    );
  };

  useEffect(() => {
    if (!selectedNode?.data) return;
    let { label, options, type, image, video } = selectedNode.data;

    setQuickReplyText(label);

    if (options?.length > 0) {
      const _inputs = options.map((btn) => {
        let { id, title } = btn;
        const optObj = {
          id: id,
          value: title,
        };
        return optObj;
      });
      setInputs(_inputs);
    }

    switch (type) {
      case "text":
        setActiveIndex(0);
        break;
      case "image":
        setActiveIndex(1);
        break;
      case "video":
        setActiveIndex(2);
        break;
      default:
        setActiveIndex(0);
    }
    if (image) setImagePreview(image);
    else setImagePreview(null);

    if (video) setVideoPreview(video);
    else setVideoPreview(null);

    // cleaup
    return () => {
      setInputs([]);
      setVideoPreview(null);
      setImagePreview(null);
      setFileChoosen(null);
    };
  }, [selectedNode]);

  const errorToast = (message) => {
    toast.current.show({
      severity: "error",
      detail: message,
    });
  };

  const handleUpload = async (e) => {
    try {
      let file = e.target.files[0];
      if (file) {
        if (
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/jpg"
        ) {
          if (!iSValidFileSize(file.size, IMAGE_SIZE_LIMIT_IN_MB)) {
            errorToast(
              `Image file size should not be more than ${IMAGE_SIZE_LIMIT_IN_MB} MB.`
            );
            return;
          } else {
            const options = {
              maxSizeMB: IMAGE_SIZE_LIMIT_IN_MB,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            const imageFile = file;
            const compressedFile = await imageCompression(imageFile, options);
            let data = new File([compressedFile], compressedFile.name, {
              type: compressedFile.type,
            });
            setFileChoosen(data);
          }
        } else if (file.type === "video/mp4") {
          if (!iSValidFileSize(file.size, VIDEO_SIZE_LIMT_IN_MB)) {
            errorToast(
              `Video file size should not be more than ${VIDEO_SIZE_LIMT_IN_MB} MB.`
            );
            return;
          } else {
            setFileChoosen(file);
          }
        }
      }
    } catch (error) {
      console.error("Error occurred during file upload:", error);
      toast.current.show({
        severity: "error",
        detail: "Error occurred during upload",
      });
    }
  };
  const uploadFile = async () => {
    const resp = await API_GET_FILE_URL();
    const fileType = activeIndex === 1 ? "image/png" : "video/mp4";

    var config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadPercentage(percentCompleted);
      },
      headers: { "Content-Type": fileType },
    };

    await axios.put(resp.data.src, fileChoosen, config);

    const _data = {};
    _data[activeIndex === 1 ? "image" : "video"] = resp.data.url;

    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: _data,
      })
    );
    if (activeIndex === 1) setImagePreview(resp.data.url);
    else if (activeIndex === 2) setVideoPreview(resp.data.url);
  };

  useEffect(() => {
    if (fileChoosen && activeIndex !== 0) {
      setUploading(true);
      uploadFile()
        .then((res) => {
          setUploading(false);
          setUploadPercentage(0);

          // setUploaded(true);
        })
        .catch((err) => {
          setUploading(false);
        });
    }
  }, [fileChoosen, activeIndex]);

  const handleTabChange = (index) => {
    if (selectedNode.data?.image || selectedNode.data?.video) {
      setTempActiveIndex(index);
      setRemoveFileAlert(true);
    } else {
      dispatch(
        updateNodePayload({
          selectedNodeId: selectedNode?.id,
          updatedData: {
            type: index === 0 ? "text" : index === 1 ? "image" : "video",
          },
        })
      );
      setActiveIndex(index);
    }
  };

  const handleFileRemove = () => {
    setImagePreview(null);
    setVideoPreview(null);
    setFileChoosen(null);
    setRemoveFileAlert(false);
    setUploadPercentage(0);
    setActiveIndex(tempActiveIndex);

    dispatch(
      deleteNodeData({
        nodeId: selectedNode.id,
        propertyToDelete: selectedNode.data?.image ? "image" : "video",
      })
    );

    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { type: "text" },
      })
    );
  };

  const handleSaveClick = () => {
    if (!quickReplyText.trim() || !isOptionValueValid(inputs)) return;

    if (inputs.length == 0) {
      toast.current.show({
        severity: "error",
        detail: `Quick Reply should have atleast one button.`,
      });
      return;
    }
    if (activeIndex === 1 && !imagePreview) {
      toast.current.show({
        severity: "error",
        detail: `Please add an image.`,
      });
      return;
    } else if (activeIndex == 2 && !videoPreview) {
      toast.current.show({
        severity: "error",
        detail: `Please add a video.`,
      });
      return;
    } else {
      onSave();
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      {removeFileAlert && (
        <SklbtConfirmation
          visible={removeFileAlert}
          handleConfirm={handleFileRemove}
          handleReject={() => setRemoveFileAlert(false)}
          message={`Are you sure, this will delete the previous media file?`}
        />
      )}
      <div>
        <div className="flex">
          {items.map((item, i) => {
            return (
              <div key={i} className="flex align-items-center mx-2">
                <RadioButton
                  inputId={item.name}
                  name={item.name}
                  value={i}
                  onChange={() => handleTabChange(i)}
                  checked={i === activeIndex}
                />
                <label htmlFor={item.name} className="mx-2">
                  {item.name}
                </label>
              </div>
            );
          })}
        </div>
        {activeIndex !== 0 ? (
          <>
            <div className="mt-3">
              <input
                ref={mediaFileRefQuick}
                type="file"
                accept={activeIndex === 1 ? "image/*" : "video/*"}
                id="imageAndVideoForQuickReply"
                onChange={handleUpload}
                hidden
                className="mt-2"
              />
              <button
                type="button"
                className="w-full flex justify-content-center gap-3 p-3 cursor-pointer"
                onClick={() => mediaFileRefQuick.current.click()}
                style={{
                  border: "1px dashed #5E5E5E",
                  background: "#EDEDED",
                }}
              >
                {activeIndex === 1 ? (
                  <i className={`pi pi-image`} />
                ) : (
                  <FaRegFileVideo />
                )}
                <Text type={"sub-heading"}>{`Upload ${
                  activeIndex === 1 ? "Image" : "Video"
                }`}</Text>
              </button>
            </div>
            {isUploading && <CustomProgressBar progress={uploadPercentage} />}
          </>
        ) : (
          <div></div>
        )}
        {activeIndex === 2 && videoPreview && (
          <video controls width="100%" className="mt-2">
            <source src={videoPreview} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}

        {activeIndex === 1 && imagePreview && (
          <Image src={imagePreview} preview width="100%" className="mt-2" />
        )}
      </div>

      <div className="mt-3">
        <label>Message *</label>
        <InputTextarea
          value={quickReplyText}
          onChange={(e) => {
            handleQuickReplyText(e);
          }}
          className={`w-full mt-1`}
          placeholder="Write your text here (max 1028 chars)"
          autoFocus
          cols={6}
          maxLength={1028}
        />
        {!quickReplyText?.trim() && (
          <small className="p-error">Please enter your message.</small>
        )}
      </div>

      <div className="mt-3">
        <div className="mb-3 flex justify-content-between align-items-center gap-3">
          <div className="flex flex-column gap-1">
            <Text type={"sub-heading"}>Buttons *</Text>
            <Text type={"small-text"} color={"grey"}>
              ( Upto 3 buttons )
            </Text>
          </div>
          {inputs?.length < 3 && (
            <div className="">
              <CustomButton
                varient="outline"
                icon={"pi pi-plus"}
                label="Add Button"
                className={styles.plusButton}
                onClick={handleAddButton}
              />
            </div>
          )}
        </div>

        {inputs.map((input, idx) => (
          <div key={input.id} className="w-full mb-2">
            <div
              className={
                "w-full flex justify-content-start align-items-center gap-2 border-1 border-700 px-2 quickReplybuttonContainer "
              }
              style={{
                background: "#fff",
                borderRadius: "4px",
              }}
            >
              <InputText
                value={input.value}
                placeholder={`Enter label (Max. 20 characters)`}
                className={`${styles.quickReplyButton}`}
                autoFocus
                onChange={(e) => {
                  handleInputChange(input.id, e.target.value);
                }}
                maxLength={20}
              />
              <i
                className="pi pi-trash cursor-pointer"
                onClick={() => handleDeleteInput(input.id)}
              />
            </div>
            {!input.value.trim() && (
              <small className="p-error">Enter button label.</small>
            )}
          </div>
        ))}
      </div>
      <StartNodeCheckMark />
      <div className=" mt-6 mb-3 flex justify-content-end gap-3 align-items-center">
        <CustomButton varient="outline" label={"Cancel"} onClick={onCancel} />
        <CustomButton
          varient="filled"
          label={"Save"}
          onClick={handleSaveClick}
        />
      </div>
    </div>
  );
};

export default QuickReply;
