import { Controller } from "react-hook-form";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import { useUtility } from "../../../context/UtilityContext";
function CustomInputNumber({
  label = "",
  name = "",
  required=false,
  placeholder = "",
  control = control,
  maxlength=null,
  disabled=false,
  isMandatory = false,
  onChangealert=false
}) {
  const { setAlert }=useUtility()
  return (
    <div className="flex flex-column gap-2 flex-grow-1">
      {label&&<label htmlFor={name}>
        {label}
        {required && <span>*</span>}
      </label>}
      <Controller
        name={name}
        control={control}
        rules={{ required: isMandatory && "This is required." }}
        render={({ field, fieldState }) => (
          <InputNumber
            id={field.name}
            value={field.value}
            onChange={(e) => {onChangealert&&setAlert(true);field.onChange(e.value)}}
            useGrouping={false}
            maxLength={maxlength}
            placeholder={placeholder}
            disabled={disabled}
            className={classNames({ "p-invalid": fieldState.invalid })}
          />
        )}
      />
    </div>
  );
}

export default CustomInputNumber;
