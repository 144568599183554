import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_GET_NOTIFICATION_PREF, API_GET_NOTIFICATION_PREF_ID, API_SET_NOTIFICATION_PREF, API_SET_NOTIFICATION_PREF_BULK } from "../api/notification.service";

const initialState = {
  loading: false,
  notificationdata:{},
  specificnotificationdata:{}
};

export const getNoticationpref = createAsyncThunk(
  "getnotification",
  async (_, thunkAPI) => {
    try {
      let res = await API_GET_NOTIFICATION_PREF();
      return res;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getNoticationprefid = createAsyncThunk(
  "getnotificationId/specific",
  async ({id}, thunkAPI) => {
    try {
      let res = await API_GET_NOTIFICATION_PREF_ID(id);
      return res;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const setNoticationpref = createAsyncThunk(
  "setNotification/specific",
  async ({id, data }, thunkAPI) => {
    try {
      let res = await API_SET_NOTIFICATION_PREF(id,data);
      return res;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const setNoticationprefbulk = createAsyncThunk(
  "setNotification/specific/bulk",
  async ({data}, thunkAPI) => {
    try {
      let res = await API_SET_NOTIFICATION_PREF_BULK(data);
      return res;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNoticationpref.fulfilled, (state, action) => {
      state.notificationdata = action.payload.rows;
      state.loading = false;
    });
    builder.addCase(getNoticationpref.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNoticationpref.rejected, (state, { payload }) => {
      state.loading = false;
    });

    builder.addCase(getNoticationprefid.fulfilled, (state, action) => {
      state.specificnotificationdata = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getNoticationprefid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNoticationprefid.rejected, (state, { payload }) => {
      state.loading = false;
    });

    builder.addCase(setNoticationpref.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(setNoticationpref.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setNoticationpref.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(setNoticationprefbulk.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(setNoticationprefbulk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setNoticationprefbulk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default notificationSlice.reducer;
