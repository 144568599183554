import React from "react";
import classes from "./terms&Conditions.module.css";
import Header from "./Header";
import Footer from "../Login/Footer";

const TermsNdConditions = () => {
  return (
    <div>
      <Header/>
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <h3 className={classes.heading}>Terms & Conditions</h3>
          <h3 className={`${classes.terms} text-center `}>
              Last updated on 26/11/24
          </h3>
          <div className={classes.termsContainer}>
            <p className={classes.terms}>
              This Terms an Conditions Agreement is entered between
            </p>
            <p className={classes.terms}>
              1.{" "}
              <span className="fw-semibold">
                Deskala Research And Design Consulting Private Limited
              </span>
              , having its registered office at F No. BG07, Manar Manha Apt 58/3
              Somasundarapalya Road, Kudlu Bengaluru KA 560068 with CIN
              U74900KA2009PTC051613 (hereinafter referred to as the “Deskala”
              which expression shall unless it be repugnant to the context or
              meaning thereof, be deemed to mean and include its
              successors-in-title and assigns)
              <br />
              And Customer (hereinafter referred to as the “Customer” which
              expression shall unless it be repugnant to the context or meaning
              thereof, be deemed to mean and include its/his/her
              successors-in-title and assigns).
              <br />
              By accessing or using Skalebot (product By Deskala) you agree to be
              bound by these Terms and Conditions. Any violations of the T&C will
              make the contract/agreement void ab initio. The Service Provider is
              the exclusive owner of the product, “SKALEBOT” and the Customer is
              entering into this agreement for using the said product. For the
              purpose of this agreement, the Service Provider is addressed as
              “SKALEBOT”.
              <br />
              For Customer’s use of the Services (defined below) to which Customer
              has subscribed as specified in one or more service order form(s)
              (“Order Form”). Additional terms may also be set forth in the Order
              Forms or on Exhibits to this Agreement. In the event of a conflict
              between the Agreement and an Order Form, the conflicting term(s) in
              the Order Form will not be considered an amendment to the Agreement
              but the conflicting term(s) in the Order Form will only apply to
              that individual order.
            </p>
            <p className={`${classes.terms} mt-2 `}>
              {" "}
              <span className="fw-semibold"> I. Definitions.</span>{" "}
            </p>
            <p className={classes.terms}>
              The following definitions will apply in this Agreement and the Order
              Forms, and any reference to the singular includes a reference to the
              plural and vice versa. Service specific definitions are found on
              <span className="fw-semibold"> Exhibit A.</span>
            </p>
            <p className={classes.terms}>
              “Agreement” means this Agreement, together with all Exhibits and all
              Order Forms entered into pursuant to this Agreement, each of which
              is incorporated herein by reference.
            </p>
            <p className={classes.terms}>
            “Renewal” means that the term of this Agreement commences on the Effective Date and will 
            continue after renewal through the expiration of any Service Order placed hereunder (the “Term”).
            </p>
            <p className={classes.terms}>
            The term of the Agreement shall not be extended automatically. The Customer and Deskala will 
              decide on terms for renewal after expiry of this agreement for the period of One(1) year. 
            </p>
            <p className={classes.terms}>
              “Charges” is defined in this agreement
            </p>
            <p className={classes.terms}>“Claim” is defined in this agreement.</p>
            <p className={classes.terms}>
              “Cloud Services” means, collectively, the SKALEBOT cloud services
              listed in the Order Form and defined in the
            </p>
            <p className={classes.terms}>
              Service Specifications. The term does not include Professional
              Services.
            </p>
            <p className={classes.terms}>
              “Confidential Information” is defined in this agreement.
            </p>
            <p className={classes.terms}>
              “Customer Content” defined in this agreement.
            </p>
            <p className={classes.terms}>
              “Customer Data” is defined in this agreement
            </p>
            <p className={classes.terms}>
            “Data Center Region” refers to the geographic region in which the Services Environment is physically 
            located.
            </p>
            <p className={classes.terms}>
            “Downtime” means the Services were not available to the Internet due to causes within the 
            reasonable control of SKALEBOT other than scheduled maintenance performed after prior 
            communication with the Customer. 
            </p>
            <p className={classes.terms}>
              Downtime does NOT include any inability of Customer to access the Services caused by third parties 
              outside of the control of SKALEBOT (such as internet service providers, network service providers or 
              telecommunications service providers) or caused by Customer hardware, software, systems or 
              networks.
            </p>
            <p className={classes.terms}>
              “End User” means a Host or Participant (as defined in Exhibit A) who uses the Services. 
              “Initial Subscription Term” means the initial subscription term for a Service as specified in an Order 
              Form.
            </p>
            <p className={classes.terms}>
            “Laws” includes the existing Government regulations, local laws, statutes, rules, regulations, 
              ordinances, administrative rulings, judgments, decrees, orders, directives, policies, or treaties 
              applicable to SKALEBOT’s provision and Customer’s use of the Services.
            </p>
            <p className={classes.terms}>
              “Professional Services” means, collectively, the consulting and other professional services that has 
              been entioned in the Order Form. The term “Professional Services” does not include cloud services. 
              “Service Effective Date” means the date that an Initial Subscription Term begins as specified in an 
              Order Form.
            </p>
            <p className={classes.terms}>
              “Renewal Term” means the renewal subscription term for a Service commencing after the Initial 
              Subscription Term or another Renewal Term as specified in an Order Form. 
            </p>
            <p className={classes.terms}>
              “Services” means the Cloud services and professional services and such services to which Customer 
              has subscribed as specified in one or more SKALEBOT Order Form(s).
            </p>
            <p className={classes.terms}>
              “Taxes and Fees” and “Taxes or Fees” means all applicable sales, use, value-added or regulatory
              taxes, fees, duties, charges, surcharges or assessments levied on the provision of Services to
              Customer (exclusive of any income tax imposed on the Service Provider).
            </p>
            <p className={classes.terms}>
              “Users” means those employees, contractors, and end users, as applicable, authorized by the
              Customer to use the Services in accordance with this Agreement and the order form.
            </p>
            <p className={`${classes.terms} mt-3 fw-semibold`}>
              <span className="fw-semibold">II. Access, Use, Customer Responsibility.</span>
            </p>
            <p className={`${classes.terms}`}>a. Right to Use</p>
            <p className={classes.terms}>
              SKALEBOT hereby grants to Customer a non-exclusive, non-transferable right for Customer to use
              the Services, subject to the terms and conditions of this Agreement for the Initial Subscription Term
              and any Renewal Term as specified in the Order Form. SKALEBOT reserves the right to enhance or
              modify features of the Services but will not materially reduce the core functionality or discontinue
              any Services without providing prior written notice to Customer. Customer will receive standard
              updates to the SKALEBOT Services that are made generally available by SKALEBOT during the term
              specified in the Order Form. However, SKALEBOT reserves the right to offer additional functionality
              or premium feature improvements for an additional cost. All rights not expressly granted herein are
              reserved by SKALEBOT and its licensors.
            </p>
            <p className={`${classes.terms} mt-3`}>b. Beta Versions</p>
            <p className={classes.terms}>
              SKALEBOT or its Affiliates may, from time to time, offer access to services that are classified as Beta
              version (i.e., a version that is not generally available). Access to and use of Beta versions may be
              subject to additional agreements. SKALEBOT makes no representations that a Beta version will ever
              be made generally available and reserves the right to discontinue or modify a Beta version at any
              time without notice. Beta versions are provided AS IS, may contain bugs, errors or other defects, and
              Customer’s use of a Beta version is at the sole risk of the Customer.
            </p>
            <p className={`${classes.terms} mt-3`}>c. Customer Use and Responsibility</p>
            <p className={classes.terms}>
              Customer may only use the Services pursuant to the terms of this Agreement and all use must
              conform to SKALEBOT’s limits imposed by the purchased plan level. Customer is solely responsible
              for its and its End Users use of the Services and shall abide by, and ensure compliance with, all Laws
              in connection with its and each End User’s use of the Services, including but not limited to Laws
              related to recording, intellectual property, privacy and export control/economic sanctions.
            </p>
            <p className={`${classes.terms} mt-3`}>d. Prohibited Use, Notification of Unauthorized Use</p>
            <p className={classes.terms}>
              Customer shall not use, and shall not permit any End User to use, the Services to: (a) modify,
              disassemble, decompile, prepare derivative works of, reverse engineer or otherwise attempt to gain
              access to the source code of the Services; (b) knowingly or negligently use the Services in a way that
              abuses, interferes with, or disrupts SKALEBOT’s networks, Customer accounts, or the Services; (c)
              engage in activity that is illegal, fraudulent, false, or misleading, (d) transmit through the Services
              any material that may infringe the intellectual property or other rights of third parties; (e) build or
              benchmark a competitive product or service, or copy any features, functions or graphics of the
              Services; or (f) use the Services in violation of SKALEBOT’s Acceptable Use Policy or any other policy
              referenced herein, or any applicable Law. Customer shall notify SKALEBOT immediately if it becomes
              aware of any unauthorized use of any password or account or any other known or suspected breach
              of security or misuse of the Services. If Customer becomes aware of any violation of this Agreement
              in connection with use of the Services by any person, Customer may contact SKALEBOT at Sales@
              deskala.in SKALEBOT will investigate any complaints of violations that come to its attention and may
              take any action that it believes is appropriate, in its sole discretion, including, but not limited to,
              issuing warnings, removing content, suspending services, or terminating accounts and/or End User
              profiles.
            </p>
            <p className={`${classes.terms} mt-3`}>e. Professional Use; No Commercial Transfer</p>
            <p className={classes.terms}>
              Customer may subscribe to and use the Services for business purposes, and Customer agrees, if
              Customer is an individual, that the Services are being purchased in a business or professional
              capacity. Customer may not sublicense, sell, resell, transfer, assign, distribute, use on a timeshare or
              service bureau basis, or charge fees to other parties for use of the Services.
            </p>
            <p className={`${classes.terms} mt-3 fw-semibold`}>
              <span className="fw-semibold">
                III. Customer Data and Content; Privacy & Responsibility for Use.
              </span>
            </p>
            <p className={`${classes.terms}`}>a. Customer Data</p>
            <p className={classes.terms}>
              Customer Data is information provided to SKALEBOT so that SKALEBOT can fulfill the terms of the
              Agreement and provide access to the Services (e.g., Company name, billing address, contact name
              and information). Customer is solely responsible for the accuracy of Customer Data, and SKALEBOT
              has no liability whatsoever for errors and omissions in Customer Data.
            </p>
            <p className={`${classes.terms} mt-3`}>b. Customer Content and Privacy</p>
            <p className={classes.terms}>
              Customer Content is any data or content originated by Customer, or an End User, and stored or
              transmitted using the Services. Customer Content includes files, documents, recordings, chat logs,
              meeting subject and attendees, transcripts, and any other information Customer or End Users may
              upload into the Services in connection with the use of the Services. SKALEBOT collects and processes
              Customer Content only at the direction of Customer and for no other purposes than the provision of
              Services hereunder. As between Customer and SKALEBOT, Customer shall retain ownership of all
              Customer Content. For the avoidance of doubt, in no event shall SKALEBOT be a Data Controller, as
              defined in the GDPR, or the substantial equivalent of a Data Controller under any Law.
            </p>
            <p className={`${classes.terms} mt-3`}>c. Customer Responsibility for Customer Content</p>
            <p className={classes.terms}>
              As between SKALEBOT and Customer, Customer is solely responsible for the use of the Customer
              Content and compliance with all Laws pertaining to the Customer Content, including, but not limited
              to, Laws requiring Customer to obtain the consent of a third party to use the Customer Content and
              to provide appropriate notices of third-party rights. Customer grants to SKALEBOT a limited right to
              modify, reproduce and distribute the Customer Content, solely in connection with providing the
              Services. Customer represents and warrants that it has the right to upload the Customer Content to
              SKALEBOT and that such use does not violate or infringe on any rights of any third party. Under no
              circumstances will SKALEBOT be liable in any way for any (a) Customer Content that is transmitted or
              viewed while using the Services, (b) errors or omissions in Customer Content, or (c) any loss or
              damage of any kind incurred as a result of the use of, access to, or denial of access to Customer
              Content. As per the Deskala data retention policy, any messages/chats will not be available in the
              application after 1 month of creation and might get permanently deleted from its servers.
              Customers are advised to take regular backups of their data as and when needed. Deskala shall
              make its best efforts to provide such backups within 1 month duration upon request, if required.
            </p>
            <p className={`${classes.terms} mt-3`}>d. SKALEBOT Obligations for Customer Content</p>
            <p className={classes.terms}>
              SKALEBOT will maintain reasonable physical and technical safeguards to prevent unauthorized
              disclosure of or access to Customer Content, in accordance with industry standards. SKALEBOT will
              notify Customer if it becomes aware of unauthorized access to Customer Content. SKALEBOT will not
              access, view or process Customer Content except (a) as provided for in this Agreement; (b) as
              authorized or instructed by Customer, (c) as required to perform its obligations under this
              Agreement; or (d) as required by Law. SKALEBOT has no other obligations with respect to Customer
              Content.
            </p>
            <p className={`${classes.terms} mt-3`}>e. Data Processing Agreements and Similar Agreements</p>
            <p className={classes.terms}>
              Upon request, SKALEBOT will prepare and execute a data processing agreement or addendum to this
              Agreement further delineating the Parties’ responsibilities with respect to information that
              reasonably identifies a specific individual.
            </p>
            <p className={`${classes.terms} mt-3 `}>
              <span className="fw-semibold"> IV. Prices and Fulfillment. </span>
            </p>
            <p className={classes.terms}>
              For each Service subscription that SKALEBOT provisions to Customer, SKALEBOT will bill Customer 
              certain non-recurring and recurring charges at prices set forth in the applicable Order Form. The 
              prices specified in the Order Form include all SKALEBOT charges for the right to use the Services and 
              are exclusive of all Taxes and Fees. Prices include standard support and generally available updates 
              to the Services. Separate charges for overage amounts and per-use charges may also apply, which 
              charges will be described in the Order Form, and Customer agrees to pay these charges if Customer 
              incurs them. Prices for professional services, if any, will be set forth in a professional services Order 
              Form. All such SKALEBOT charges are referred to as “Charges”. 
            </p>
            <p className={`${classes.terms} mt-3 `}>a. Price Changes</p>
            <p className={classes.terms}>
            SKALEBOT may change prices for the Services from time to time, in its sole discretion. Any price 
            changes will be effective upon the commencement of Customer’s next Renewal Term; provided, that 
            SKALEBOT shall provide Customer with reasonable notice of any such fee increase prior to the 
            expiration of the Term or any Renewal Term. Prices for Services may also change if Customer 
            chooses to increase or decrease the number of subscriptions or add Services. Price changes will be 
            effective at the time Customer makes such changes to Customer’s account. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              b. Discounts and Promotional Pricing
            </p>
            <p className={classes.terms}>
            Prices specified in the Order Form may include discounts or promotional pricing. These discounts or 
            promotional pricing amounts may be temporary and may expire upon the commencement of a 
            Renewal Term, without additional notice. SKALEBOT reserves the right to discontinue or modify any 
            promotion, sale or special offer at its sole and reasonable discretion.
            </p>
            <p className={`${classes.terms} mt-3 `}>c. Invoices and Payments</p>
            <p className={classes.terms}>
              Unless specified otherwise in an Order Form, Customer shall pay all invoices within thirty (30) days 
              receipt of such invoice. Invoices may be emailed to the address specified by the Customer. Except as 
              explicitly provided in this Agreement, all payment obligations are non-cancellable and all amounts 
              paid are non-refundable. SKALEBOT shall invoice Customer for all non-recurring Charges, overage 
              and per-use Charges, and associated Taxes and Fees, on the invoice following the provision of 
              Service giving rise to such Charges; and, shall invoice Customer for all recurring Charges and 
              associated Taxes and Fees on the invoice preceding the period in which Services will be provided. 
            </p>
            <p className={`${classes.terms} mt-3 `}>d. Purchase Order Numbers</p>
            <p className={classes.terms}>
              If a Purchase Order Number is required for processing an invoice, Customer will provide such 
              Purchase Order Number with the applicable Order Form. If issuance of a Purchase Order is delayed, 
              Customer will provide a Purchase Order Number within 5 days of the Service Effective Date via 
              email to Priyanka Ganguly (priyanka.ganguly@deskala.in) Notwithstanding the foregoing, the thirty 
              (30) day period for payment shall commence as of the applicable invoice date. Such payment period 
              shall not restart based on any delays in issuing a Purchase Order or any other Customer required 
              procurement process.
            </p>
            <p className={`${classes.terms} mt-3 `}>e. GST Invoices</p>
            <p className={classes.terms}>
              To the extent that any amounts payable by Customer are subject to withholding Taxes and Fees, the 
              amount payable shall be grossed up by Customer when customer remits payment such that the 
              amount paid net of withholding Taxes and Fees equals the amount invoiced by SKALEBOT.  
            </p>
            <p className={`${classes.terms} mt-3 `}>f. Withholding</p>
            <p className={classes.terms}>
              To the extent that any amounts payable by Customer are subject to withholding Taxes and Fees, the 
              amount payable shall be grossed up by Customer when customer remits payment such that the 
              amount paid net of withholding Taxes and Fees equals the amount invoiced by SKALEBOT. 
            </p>
            <p className={`${classes.terms} mt-3 `}>g. Tax Exemptions</p>
            <p className={classes.terms}>
              In the event Customer is exempt from any Tax or Fee, Customer will provide SKALEBOT with all 
              appropriate certificates, GST registration numbers, and/or other documentation satisfactory to the 
              applicable taxing authorities to substantiate such exemption status. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              h. Billing and Contract Information; Billing Disputes
            </p>
            <p className={classes.terms}>
            Customer represents and warrants that the Customer Data provided to SKALEBOT is complete and 
            accurate. If Customer believes an invoice is incorrect, Customer must contact SKALEBOT in writing 
            within thirty (30) days of the date of the invoice, and identify the amount in question, to be eligible 
            to receive an adjustment or credit, which adjustment or credit, if any, shall be determined by 
            SKALEBOT in SKALEBOT’s reasonable discretion after reviews all relevant information. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              <span className="fw-semibold">
                {" "}
                V. SKALEBOT Proprietary Rights.{" "}
              </span>
            </p>
            <p className={classes.terms}>
              SKALEBOT or its licensors own and shall retain all proprietary rights, including all copyright, patent, 
              trade secret, trademark, trade name and all other intellectual property rights, in and to the Services. 
              SKALEBOT shall retain ownership of any suggestions, ideas, enhancement requests, feedback, 
              recommendations or other information provided by Customer or any other party relating to the 
              Services. Customer acknowledges that the rights granted under this Agreement do not provide 
              Customer with title to or ownership of the Services, but only a right to use under the terms and 
              conditions of this Agreement. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              {" "}
              <span className="fw-semibold"> VI. Confidentiality. </span>
            </p>
            <p className={`${classes.terms}  `}>               
              a. Each party agrees to regard and preserve as confidential all non-public information provided by 
              the other party relating to the business, systems, operations, strategic plans, clients, pricing 
              (including, but not limited to, the pricing terms herein), methods, processes, financial data, 
              programs, and/or products of the other party in any form, that are designated as “confidential,” or a 
              reasonable person knows or reasonably should understand to be confidential (herein “Confidential 
              Information”). For purposes of this Agreement, Customer’s Confidential Information shall include 
              Customer Data, and any information disclosed to SKALEBOT by the Customer relating to the 
              business, systems, operations, strategic plans, clients, pricing, methods, processes, financial data, 
              programs, and/or products of the Customer. Each party agrees to limit its disclosure of the other 
              party’s Confidential Information to as few persons as possible and only to those persons with a need 
              to know that are its or its Affiliates’ personnel and subject to an obligation to keep such information 
              confidential. Except as needed to fulfill their respective obligations under this Agreement, neither 
              party shall, without first obtaining the other party’s prior written consent, disclose to any person, 
              firm or enterprise, except as expressly permitted herein, or use for its own benefit, or the benefit of 
              a third party, the Confidential Information of the other party. 
            </p>
            <p className={`${classes.terms} mt-3 `}>b. Exclusions</p>
            <p className={classes.terms}>
              “Confidential Information” shall not include Customer Content or information that (a) is already 
              rightfully known to a party at the time it is obtained from the other party, free from any obligation 
              to keep such information confidential; (b) is or becomes publicly known or available through no 
              wrongful act of a party; (c) is rightfully received from a third party without restriction and without 
              breach of this Agreement; or (d) is developed by a party without the use of any proprietary, non￾public information provided by the other party under this Agreement.
            </p>
            <p className={`${classes.terms} mt-3 `}>c. Exception</p>
            <p className={classes.terms}>
              Either party may disclose Confidential Information where required by law, regulation, or court order, 
              provided that the party subject to such law, regulation or court order shall, where permitted, notify 
              the other party of any such use or requirement prior to disclosure in order to afford such other party 
              an opportunity to seek a protective order to prevent or limit disclosure of the information to third 
              parties. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              d. Confidentiality Period and Obligations
            </p>
            <p className={classes.terms}>
                The confidentiality obligations set forth in this section of the Agreement shall remain in effect for a 
                period of five (5) years from the disclosure of the information. Both parties agree (a) to take 
                reasonable steps to protect the other party’s Confidential Information, and these steps must be at 
                least as protective as those the receiving party takes to protect its own Confidential Information, and 
                no less than a reasonable standard of care; (b) to notify the disclosing party promptly upon discovery 
                of any unauthorized use or disclosure of Confidential Information; and (c) in the event of any 
                unauthorized disclosure by a receiving party, to cooperate with the disclosing party to help regain 
                control of the Confidential Information and prevent further unauthorized use or disclosure of it. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              {" "}
              <span className="fw-semibold">
                {" "}
                VII. Term and Termination; Suspension.{" "}
              </span>
            </p>
            <p className={classes.terms}>
            Each Order Form will specify a Service Effective Date, an Initial Subscription Term, and a Renewal 
            Term for the Services subscribed to in that Order Form. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              a. Term and Renewal; Early Termination
            </p>
            <p className={classes.terms}>
                Unless specified otherwise in the Order Form, a Renewal Term shall only commence upon 
                Customer’s written request for a renewal and the execution of an applicable Order Form as agreed 
                between the parties that extends the terms. Customer acknowledges he can choose to opt for auto 
                renewal clause and that in the event Customer does not renew the Agreement, as of conclusion of 
                the Initial Subscription Term access to the Services shall cease without any additional notice. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              b. Termination by Either Party
            </p>
            <p className={classes.terms}>
                A Party may terminate this Agreement by: (a) providing written notice of termination without cause 
                to the other party, provided that all subscription terms for all outstanding Order Forms have expired 
                or been terminated, or (b) providing written notice of termination for cause if the other party has 
                materially breached the Agreement and has not cured such breach within thirty (30) days of written 
                notice of such breach. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              c. Termination or Suspension by SKALEBOT.
            </p>
            <p className={classes.terms}>
              In the event SKALEBOT reasonably believes that Customer or any End User is in material breach of 
                this Agreement, SKALEBOT may immediately suspend or disconnect access to Customer’s or such 
                End User’s use of the relevant Services, prior to termination for cause as provided above and until 
                such breach is cured. SKALEBOT may also suspend Customer’s and/or an End User’s use of or access 
                to any Service if it reasonably believes that such suspension is necessary to prevent imminent harm 
                to SKALEBOT, SKALEBOT’s network, any End User, or any third party communicating with an End 
                User. Any such suspension, disconnection, or termination shall be without liability to SKALEBOT, and 
                Customer will remain responsible for all recurring Charges incurred during the period of suspension 
                or disconnection. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              d. Termination by SKALEBOT Due to Change in Law
            </p>
            <p className={classes.terms}>
                In the event of any change in Law that has the effect of materially increasing SKALEBOT’s costs to 
                provide Service hereunder or effectively cancels, changes or supersedes any material term or 
                provision of this Agreement (collectively “Change in Law”) either party may, on thirty (30) days’ 
                prior written notice to the other require that they enter into good faith negotiations to revise the 
                Agreement to appropriately address the Change in Law. If the Parties are unable to agree on such 
                revisions within thirty (30) days from the date of notice, SKALEBOT may terminate this Agreement 
                with immediate effect. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              {" "}
              <span className="fw-semibold">
                VIII. Responsibilities upon Termination.
              </span>{" "}
            </p>
            <p className={`${classes.terms}  `}>a. Cessation of Use</p>
            <p className={classes.terms}>
              Upon any termination of this Agreement, Customer shall immediately cease any further use of the 
              Services. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              b. Return of Customer Content
            </p>
            <p className={classes.terms}>
              For thirty (30) days following expiration or termination of the Agreement, SKALEBOT will provide 
              Customer access to retrieve Customer Content, after which time Customer Content will be deleted 
              according to regularly scheduled deletion protocols. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              {" "}
              <span className="fw-semibold">IX. Warranties</span>{" "}
            </p>
            <p className={`${classes.terms} `}>a. Limited Warranty</p>
            <p className={classes.terms}>
                SKALEBOT warrants to Customer that the Services will, in all material respects, conform to the 
                functionality described in the SKALEBOT Documentation. SKALEBOT&#39;s sole and exclusive 
                obligation, and Customer’s sole and exclusive remedy for a breach of this warranty shall be that 
                SKALEBOT shall use commercially reasonable efforts to modify the Services to conform in all material 
                respects to the SKALEBOT documentation, and if SKALEBOT is unable to materially restore such 
                functionality within thirty (30) days from receipt of written notice of said breach, Customer shall be 
                entitled to terminate the Agreement upon written notice and shall be entitled to receive a pro-rata 
                refund of the unused Charges that have been paid in advance (if any) under this Agreement. This 
                warranty shall be in effect for the first thirty (30) days (&quot;Warranty Period&quot;) from the date 
                the applicable Services are first provided to the Customer. In the event of any material non￾conformance reported after the Warranty Period, SKALEBOT’ss sole and exclusive obligation and 
                Customer’s sole and exclusive remedy shall be to secure assistance through SKALEBOT’s technical 
                support services. 
            </p>
            <p className={`${classes.terms} mt-3 `}>b. Warranty Disclaimer</p>
            <p className={classes.terms}>
                EXCEPT AS EXPLICITLY PROVIDED IN THIS AGREEMENT, SKALEBOT AND ITS LICENSORS EXPRESSLY 
                DISCLAIM ANY AND ALL OTHER REPRESENTATIONS AND WARRANTIES, EITHER EXPRESS, IMPLIED, 
                STATUTORY, OR OTHERWISE WITH RESPECT THERETO, INCLUDING, BUT NOT LIMITED TO, ANY 
                IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON￾INFRINGEMENT, OR THE CONTINUOUS, UNINTERRUPTED, ERROR-FREE, VIRUS-FREE, OR SECURE 
                ACCESS TO OR OPERATION OF THE SERVICES AND/OR SKALEBOT SERVICES. 
              <br />
                SKALEBOT EXPRESSLY DISCLAIMS ANY WARRANTY AS TO THE ACCURACY OR COMPLETENESS OF ANY 
                INFORMATION OR DATA ACCESSED OR USED IN CONNECTION WITH THE SERVICES. TO THE EXTENT 
                SKALEBOT CANNOT DISCLAIM ANY SUCH WARRANTY AS A MATTER OF APPLICABLE LAW, THE SCOPE 
                AND DURATION OF SUCH WARRANTY SHALL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY 
                LAW. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              {" "}
              <span className="fw-semibold">X. Indemnification</span>{" "}
            </p>
            <p className={`${classes.terms}  `}>a. Indemnification by SKALEBOT</p>
            <p className={classes.terms}>
                Provided that Customer complies with the terms of this Agreement below, SKALEBOT agrees to 
                indemnify, defend and hold harmless Customer from any third party suits, claims or demands and 
                associated liabilities, costs, damages and expenses (including, but not limited to, attorneys’ fees, 
                expert fees and court costs) (collectively, “Claims”) that Customer may sustain or incur arising from 
                infringement by the Services of any copyright, trademark or trade secret of a third party, or any U.S. 
                patent. This indemnity will not apply to any Claim that the infringement arose from the combination 
                of the Services with software, hardware, content, data or other items not supplied by SKALEBOT. In 
                the event that the licensed Services are, or in SKALEBOT’s sole opinion are likely to be, enjoined due 
                to the type of infringement described in this Section 15, SKALEBOT, at its option and expense, (a) 
                replace the applicable Services with functionally equivalent non-infringing technology or (b) obtain a 
                license for Customer’s continued use of the applicable Services, or, if the foregoing alternatives are 
                not reasonably available to SKALEBOT (c) terminate this Agreement and refund any sums prepaid for 
                Services not provided as a result of such termination. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              b. Indemnification by Customer
            </p>
            <p className={classes.terms}>
                Provided that SKALEBOT complies with the terms below, Customer agrees to indemnify, defend and 
                hold harmless SKALEBOT and its Affiliates and their respective officers, directors, members, 
                employees, consultants, agents, suppliers and resellers from any Claims arising from (a) Customer’s 
                or Customer’s End Users’ use of the Services in violation of this Agreement; (b) any infringement or 
                violation by Customer or any End User of any intellectual property or other right of any person; and 
                (c) Customer’s or any End User’s violation of any Law. 
            </p>
            <p className={`${classes.terms} mt-3 `}>
              c. Indemnification Procedures
            </p>
            <p className={classes.terms}>
                In claiming any indemnification under this Section, the indemnified party shall promptly provide the 
                indemnifying party with notice of any claim that the indemnified party believes is within the scope of 
                the obligation to indemnify. The indemnified party may, at its own expense, assist in the defense if it 
                so chooses, but the indemnifying party shall control the defense and all negotiations relative to the 
                settlement of any such claim. Any settlement intended to bind the indemnified party shall not be 
                final without the indemnified party’s written consent, which consent shall not be unreasonably 
                withheld or delayed.
            </p>
            <p className={`${classes.terms} mt-3 `}>d. Limitation on Liability</p>
            <p className={classes.terms}>EXCLUSIONS </p>
            <p className={classes.terms}>
                SKALEBOT SHALL NOT BE LIABLE TO CUSTOMER OR ANY THIRD PARTY FOR (a) THE COST OF 
                PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; (b) ANY UNAUTHORIZED ACCESS TO, 
                OR ALTERATION, THEFT OR DESTRUCTION OF THE WEB SITE, ANY CONTENT, CUSTOMER 
                DATA, SYSTEM DATA, OTHER DATA FILES, PROGRAMS OR INFORMATION THROUGH ERROR, 
                OMISSION, ACCIDENT OR FRAUDULENT MEANS OR DEVICES NOT DIRECTLY ATTRIBUTABLE TO 
                SKALEBOT’S NEGLIGENT ACTS OR OMISSIONS, OR FOR OTHER CIRCUMSTANCES OUTSIDE OF 
                SKALEBOT’S REASONABLE CONTROL, OR (c) ANY MALFUNCTION OR CESSATION OF INTERNET 
                SERVICES BY INTERNET SERVICE PROVIDERS OR OF ANY OF THE NETWORKS THAT FORM THE 
                INTERNET WHICH MAY AFFECT THE OPERATION OF THE SERVICES. 
            </p>
            <p className={`${classes.terms} mt-3`}>NO INDIRECT DAMAGES </p>
            <p className={classes.terms}>
                IN NO EVENT SHALL EITHER PARTY OR ITS LICENSORS BE LIABLE FOR ANY INDIRECT, 
                INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR DAMAGES FOR LOSS OF 
                REVENUES OR PROFITS, LOSS OF USE, BUSINESS INTERRUPTION, LOSS OF DATA, BREACH 
                OF DATA, OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, WHETHER IN AN ACTION 
                IN CONTRACT OR TORT, EVEN IF THE OTHER PARTY HAS BEEN ADVISED OF THE 
                POSSIBILITY OF SUCH DAMAGES. EACH PARTY AGREES TO TAKE REASONABLE ACTION TO 
                MITIGATE ITS DAMAGES. 
            </p>
            <p className={`${classes.terms} mt-3`}>AGGREGATE LIABILITY CAP</p>
            <p className={classes.terms}>
                IN NO EVENT SHALL SKALEBOT’S LIABILITY FOR ANY DAMAGES EXCEED AN AMOUNT EQUAL 
                TO THE TOTAL CHARGES PAID TO SKALEBOT UNDER THIS AGREEMENT IN THE PRIOR TWELVE 
                (12) MONTHS PRECEDING THE INCIDENT GIVING RISE TO THE CLAIM. THIS LIMITATION 
                APPLIES TO ALL CAUSES OF ACTION IN THE AGGREGATE, INCLUDING, WITHOUT 
                LIMITATION, BREACH OF CONTRACT, MISREPRESENTATIONS, NEGLIGENCE, STRICT 
                LIABILITY AND OTHER TORTS. THESE LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY 
                FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY. 
            </p>
            <p className={`${classes.terms} mt-3 `}>e. Force Majeure</p>
            <p className={classes.terms}>
                Neither party hereto will be liable for defaults or delays (other than the non-payment of Charges) 
                due to Acts of God, or the public enemy, acts or demands of any government or governmental 
                agency, fires, floods, accidents, or other unforeseeable causes beyond its control and not due to its 
                fault or negligence.
            </p>
            <p className={`${classes.terms} mt-3 `}>
              {" "}
              <span className="fw-semibold">XI. Arbitration.</span>{" "}
            </p>
            <p className={`${classes.terms}  `}>
              a. Governing Law, Dispute Resolution and Jurisdiction.
            </p>
            <p className={classes.terms}>
              In the event of any differences or disputes arising out of the interpretation or application of the 
              provisions of this Agreement, the Party having a difference/dispute shall by written notice call upon the other 
              Party to resolve such difference or disputes in a spirit of mutual understanding and co-operation. In 
              case the Parties fail to resolve the dispute within a period of fifteen (15) days from the date of 
              receipt of the notice by the other Party, the matter shall be resolved through arbitration as per the 
              provision below. All questions, disputes and differences arising under or in relation to this 
              Agreement shall be referred to the sole Arbitrator appointed with the mutual consent of Receiving 
              Party and Disclosing Party. The award of the Arbitrator so appointed shall be final, conclusive and 
              binding on the Parties and provisions of the Arbitration and Conciliation Act, 1996 or any statutory 
              modification or re-enactment thereof and the rules made there under and for the time being in 
              force shall apply to the arbitration proceedings under this clause. The venue of the arbitration shall 
              be Bengaluru. This Agreement shall be governed by and construed and enforce in accordance with 
              the laws of India and parties submit to the non-exclusive Jurisdiction of the courts of Bengaluru. 
            </p>
            <p className={`${classes.terms} mt-3 `}>b. Severability.</p>
            <p className={classes.terms}>
              In the event that any provision of this Agreement or any word, phrase, clause, sentence or other 
              portion thereof should be held to be unenforceable or invalid for any reason, such provision or 
              portion thereof shall be modified or deleted in such a manner so as to make this Agreement as 
              modified legal and enforceable to the fullest extent permitted under applicable laws. In any event, 
              the Parties intend each and every provision of this Agreement to be enforceable, valid, and legal and 
              each of them waives to the fullest extent permitted by law the right to object to any such provision. 
              It is acknowledged that each Party has negotiated, bargained for, and participated in the drafting of 
              the provisions hereof and that, as a consequence, both Parties are equally responsible for the 
              drafting of this Agreement. Accordingly, there shall be no presumption against either party with 
              respect to the construction or interpretation of the provisions of this Agreement. 
            </p>
          </div>
        </div>
      </div>
      <footer>
        <Footer/>
      </footer>
    </div>
  );
};

export default TermsNdConditions;
