import React from "react";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function DataTableDemo({ columns = [] }) {
  const items = Array.from({ length: 5 }, (v, i) => i);

  return (
    <div className="card">
      <DataTable
        value={items}
        className="p-datatable-striped"
        scrollHeight="450px"
        scrollable
      >
        {columns?.map((col, i) => (
          <Column key={col.value} header={col.label} body={<Skeleton />} />
        ))}
      </DataTable>
    </div>
  );
}
