import React from "react";
import { Text } from "../../components/Text";
import { CustomButton } from "../../components/CustomButton";
import { ReactComponent as SkalebotLogo } from "../../svg/bot.svg";
import { ReactComponent as RefreshLogo } from "../../assets/refreshIcon.svg";
import { Message } from "primereact/message";
function ErrorPage({ error }) {
  const refresh = () => {
    window.location.reload();
  };
  // console.log("error",error.error , error.error.toString())
  // console.log("location", error.errorInfo.componentStack)
  return (
    <div>
      <div className="flex flex-column justify-content-center align-items-center mt-8 ">
        <SkalebotLogo />
        <Text type={"heading"} cust="mt-5 text-base">
          "Oops", it looks like there was a some error.{" "}
        </Text>
        <div className="my-3">
          <Message
            severity="error"
            className="lowercase"
            text={error?.error?.message}
          />
        </div>
        <div
          className="flex align-items-center"
          style={{
            height: "40px",
            borderRadius: "0.375rem",
            border: "0.5px solid #000",
          }}
        >
          <div
            className="flex align-items-center px-3  cursor-pointer"
            style={{
              background: "rgba(0, 0, 0, 0.05)",
              height: "40px",
              borderRight: "0.5px solid #000",
            }}
            onClick={refresh}
          >
            <RefreshLogo />
          </div>
          <div className="px-3 ">
            <p className="m-0 text-base font-medium ">Refresh</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
