import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Text } from "../../../../../components/Text";
import { InputText } from "primereact/inputtext";
import { updateBotflowDetails } from "../../../../../reducers/canvas/canvasSlice";
import { getDate } from "../../../../../utils/datemaker";
import "../../stylesheets/bot-item.css";
const dateShower = (d) => {
  let date = getDate(d)?.split(",");
  return (
    <>
      <span>{date[0]}</span>,<span>{date[1]}</span>
    </>
  );
};
export const BotflowName = ({ bflow }) => {
  const [editBotname, setEditBotname] = useState(false);
  const [botflowName, setBotflowName] = useState("");

  const dispatch = useDispatch();

  const handleEditNameClick = () => {
    setBotflowName(bflow?.flowName || "");
    setEditBotname(true);
  };

  const closeBotFlowEdit = () => {
    setEditBotname(false);
  };

  const handleUpdateName = () => {
    const { id } = bflow;
    if (botflowName.trim()) {
      const updatedData = {
        flowName: botflowName,
      };
      dispatch(updateBotflowDetails({ id, updatedData }))
        .unwrap()
        .then(() => {
          closeBotFlowEdit();
          setBotflowName("");
        })
        .catch((err) => {});
    }
  };

  return (
    <div>
      <div className="flex align-items-center">
        {!editBotname ? (
          <div
            className="w-full cursor-pointer editable-botname"
            onClick={handleEditNameClick}
          >
            <Text type={"sub-heading"} cust="capitalize ">
              {bflow?.flowName}
            </Text>{" "}
          </div>
        ) : (
          <InputText
            value={botflowName}
            onChange={(e) => setBotflowName(e.target.value)}
          />
        )}
        {editBotname && (
          <div className="ml-2 flex gap-2 align-items-center">
            <i
              className="pi pi-check cursor-pointer"
              style={{ fontSize: "1rem" }}
              onClick={handleUpdateName}
            ></i>
            <i
              style={{ fontSize: "1rem" }}
              className="pi pi-times cursor-pointer"
              onClick={closeBotFlowEdit}
            />
          </div>
        )}
      </div>
      <Text type="small-text" color="00000080">
        {dateShower(bflow?.updatedAt)}
      </Text>
    </div>
  );
};
