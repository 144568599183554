import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Skeleton } from "primereact/skeleton";
import axiosInstance from "../../api/axios.instance";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

function CampaignRowAnalytics({ campaignDetails }) {
  const [isAnalyticsLoading, setAnalyticsLoading] = useState(false);
  const [campaignWithSuccessRate, setCampaignWithSuccessRate] = useState(null);

  const item = {
    datasets: [
      {
        label: "Conversations",
        data: [
          campaignWithSuccessRate?.stats.sent,
          campaignWithSuccessRate?.stats.delivered,
          campaignWithSuccessRate?.stats.read,
          campaignWithSuccessRate?.stats.replied,
          campaignWithSuccessRate?.stats["opt-out"],
          campaignWithSuccessRate?.stats.failed,
        ],
        backgroundColor: [
          "grey",
          "#FFE66D",
          "#C4E0A5",
          "#018e42",
          "#E61E50",
          "#570000",
        ],
        borderColor: ["transparent"],
      },
    ],
  };

  useEffect(() => {
    setAnalyticsLoading(true);
    axiosInstance
      .get(`/campaign/${campaignDetails?.id}/analytics`)
      .then((res) => {
        let analytics = res.data;
        const allStatus = analytics.find((item) => item.status === "All");

        const failedStatus = analytics.find(
          (item) => item.status === "failed"
        ) || { count: 0 };

        const stats = {
          sent: 0,
          delivered: 0,
          read: 0,
          replied: 0,
          optout: 0,
          failed: 0,
        };

        analytics?.forEach((item) => (stats[item.status] = item.count));

        let successRate = 0;

        if (allStatus) {
          successRate =
            ((allStatus.count - failedStatus.count) / allStatus.count) * 100;
          successRate = Math.max(successRate, 0);
          successRate = parseFloat(successRate.toFixed(1));
        }

        const totalStatus = allStatus?.count || 0;

        let campaignAnalytics = {
          ...campaignDetails,
          successRate,
          totalStatus,
          stats: { ...stats },
          failedStatus: failedStatus.count,
        };
        setCampaignWithSuccessRate(campaignAnalytics);
        setAnalyticsLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, [campaignDetails]);

  return (
    <div>
      {isAnalyticsLoading ? (
        <Skeleton width={"3rem"} />
      ) : (
        <div className="flex gap-2 align-items-center">
          {(campaignWithSuccessRate?.totalStatus||campaignWithSuccessRate?.failedStatus)&&<div
            className="pl-4 pr-2 flex justify-content-start"
            style={{ minWidth: "8ch" }}
          >
            {campaignWithSuccessRate?.totalStatus -
              campaignWithSuccessRate?.failedStatus}
            /{campaignWithSuccessRate?.totalStatus}
          </div>}
          <div
            className="flex border-circle  relative justify-content-start"
            style={{
              width: "50px",
              height: "50px",
              borderColor: `#ecedf1`,
              borderWidth: "1px",
              borderStyle: "solid",
            }}
          >
            <div
              className="absolute top-1 "
              style={{
                width: "52px",
                height: "52px",
                top: "-7px",
                left: "-2px",
              }}
            >
              <Doughnut
                data={item}
                options={{
                  plugins: {
                    tooltip: {
                      enabled: false,
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CampaignRowAnalytics;
