import React from "react";
import * as XLSX from "xlsx";

export const exportToExcel = (exportData, fileName) => {
  const date = new Date();
  let newDate = date.toDateString();
  fileName = `${fileName}_${newDate}`;
  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};
