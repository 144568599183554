import { useForm, Controller } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";
import { CustomButton } from "../../components/CustomButton";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  changeselectedCustomers,
  changeselectedGroupId,
  changestepcount,
  hideShareMessageDialog,
  resetBroadcast,
  setBroadcastDetails,
  setCampDetails,
  setMode,
  setScheduler,
  showShareMessageDialog,
  stepChange,
} from "../../reducers/ShareMessage/messageSlice";
import TemplatePreview from "../../components/TemplatePreview";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { getIds } from "../../utils/shareUtils";

import {
  templateTypes,
  formateMessageInput,
  validateMediaSize,
  blob,
} from "../../utils/templateUtils";
import { useState, useRef } from "react";
import { Text } from "../../components/Text";
import { useEffect } from "react";
import { Badge } from "primereact/badge";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeSelectedProductsList,
  resetAll,
} from "../../reducers/productTableSlice";
import {
  getCampaignById,
  getCampaignCustomers,
  getCampaignsAnalytics,
} from "../../reducers/campaignSlice";
import { getTemplatesbyId } from "../../reducers/templateReducer/templateActions";
import {
  addUTemplate,
  closeTemplateForm,
} from "../../reducers/templateReducer/templateSlice";
import { compressImg } from "../../utils/templateUtils";
import axios from "axios";
import { useUtility } from "../../context/UtilityContext";

const addsteps = (name, value, url, show = true, panel = false) => {
  return { name, value, url, show, panel };
};

export const Template = ({ toast }) => {
  const [tempVariables, setTempvariables] = useState([]);
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [documentPreview, setDocumentPreview] = useState(null);
  const [templateButtons, setTemplateButtons] = useState([]);
  const {
    completeSteps,
    campaignName,
    broadcastdetails,
    selectedTemplate,
    mode,
    campDetails,
  } = useSelector((state) => state.shareMessage);
  const { selectedCampaign, campaignLoading, stats } = useSelector(
    (state) => state.campaign
  );
  const { id } = useParams();
  if (selectedTemplate) {
    var { templateType, campaignType } = selectedTemplate;
  }

  const defaultValues = {
    templateType: "",
    header: "",
    footer: "",
    message: "",
  };

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    reset,
    setValue,
  } = useForm(defaultValues);
  useEffect(() => {
    if (mode === "update") {
      setValue("templateType", campDetails.templateType);
      setValue("message", campDetails?.message);
      setValue("header", campDetails?.header);
      setValue("footer", campDetails?.footer);
    }
  }, []);

  //function form get error message
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  const [customNote, setCustomNote] = useState([]);
  useEffect(() => {
    let x = broadcastdetails?.configData.variables;
    if (x && Object.keys(x).length > 0) {
      let temp = [];
      for (let key in x) {
        // console.log(key)
        if (key !== "mediaUrl") {
          temp.push(x[key]);
        }
      }
      setCustomNote([...temp]);
    }
  }, [broadcastdetails]);

  useEffect(() => {
    if (customNote.length > 0) {
      let index = 0;
      let updatevar = tempVariables.map((x, i) => {
        if (x.variableType === "customNote") {
          return {
            ...x,
            variableValue: customNote[index++],
          };
        } else {
          return { ...x };
        }
      });
      setTempvariables(updatevar);
    }
  }, [customNote]);

  const dispatch = useDispatch();
  const mediaFileRef = useRef();

  useEffect(() => {
    if (broadcastdetails?.media) {
      blob(broadcastdetails?.media)
        .then((blobFile) => {
          let fname = broadcastdetails?.configData?.filename;
          return new File(
            [blobFile],
            fname ? fname : `file.${blobFile.type.split("/")[1]}`,
            { type: blobFile.type }
          );
        })
        .then((e) => {
          const reader = new FileReader();
          const selectedFile = e;
          if (selectedFile) {
            setFile(selectedFile);
            reader.readAsDataURL(selectedFile);
            reader.onload = (readerEvent) => {
              if (selectedFile.type.includes("image")) {
                setImagePreview(readerEvent.target.result);
              } else if (selectedFile.type.includes("video")) {
                setVideoPreview(readerEvent.target.result);
              } else if (selectedFile.type.includes("pdf")) {
                setDocumentPreview(readerEvent.target.result);
              }
            };
          }
        })
        .catch((e) => {
          toast?.current?.show({
            severity: "error",
            detail: "Unable To Fetch File",
          });
        });
    }
  }, [broadcastdetails]);
  const previewFile = (e) => {
    const reader = new FileReader();
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      // console.log(selectedFile)
      if (selectedFile.type.includes("image")) {
        compressImg(e.target.files[0]).then((e) => {
          setFile(e);
        });
      }
      reader.readAsDataURL(selectedFile);
    }
    // As the File loaded then set the stage as per the file type
    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes("image")) {
        setImagePreview(readerEvent.target.result);
      } else if (selectedFile.type.includes("video")) {
        setVideoPreview(readerEvent.target.result);
      } else if (selectedFile.type.includes("pdf")) {
        setDocumentPreview(readerEvent.target.result);
      }
    };
  };

  const headerContainer = () => {
    const mediaSelect = (icon, accept) => {
      if (file) {
        var { isValid, acceptedSize } = validateMediaSize(
          file?.size,
          templateType.toLowerCase()
        );
      }
      return !file ? (
        <div>
          <input
            ref={mediaFileRef}
            type="file"
            accept={`${accept}`}
            hidden
            onChange={previewFile}
          />
          <button
            type="button"
            className="w-full temp-img flex gap-3 p-3 my-2 cursor-pointer"
            onClick={() => mediaFileRef.current.click()}
          >
            <i className={`pi ${icon}`} />
            <Text type={"sub-heading"} cust="capitalize">
              Choose {templateType.toLowerCase()}
            </Text>
          </button>
        </div>
      ) : (
        <>
          <div className="temp-img-upload">
            <div className="flex gap-2 justify-content-between align-items-center">
              <div className="flex gap-2 align-items-center">
                <i className={`pi ${icon}`} />
                <Text type={"sub-heading"} cust="capitalize">
                  {file.name}
                </Text>
              </div>
              <i
                className="pi pi-trash cursor-pointer"
                style={{ float: "right" }}
                onClick={handleDeleteMedia}
              />
            </div>
          </div>
          {!isValid && (
            <div className="mb-2">
              <small className="p-error ">
                {templateType.toLowerCase()} size should be less than{" "}
                {acceptedSize} MB.
              </small>
            </div>
          )}
        </>
      );
    };

    const handleDeleteMedia = () => {
      setFile(null);
      switch (templateType) {
        case "IMAGE":
          setImagePreview(null);
          break;
        case "VIDEO":
          setVideoPreview(null);
        case "Document":
          setDocumentPreview(null);
        default:
          break;
      }
    };
    if (templateType) {
      switch (templateType) {
        case "IMAGE":
          // previously using this
          // return mediaSelect("pi-image", "image/*");
          return mediaSelect("pi-image", "image/jpeg, image/png");
        case "VIDEO":
          // previously using this
          // return mediaSelect("pi-video", "video/*");
          return mediaSelect("pi-video", "video/mp4");
        case "DOCUMENT":
          return mediaSelect("pi-file", ".doc,docx,.pdf");
        default:
          return <></>;
      }
    }
  };
  const handleEditVariable = (e, id) => {
    let updateVariableData = tempVariables.map((item) => {
      if (item.id !== id) return item;
      else return { ...item, variableValue: e.target.value };
    });
    setTempvariables(updateVariableData);
  };

  const handleUpdateVariable = (value, id) => {
    let updateVariableData = tempVariables.map((item) => {
      if (item.id !== id) return item;
      else return { ...item, variableValue: value };
    });
    setTempvariables(updateVariableData);
  };
  const handleButtonEdit = (e, id, key) => {
    let updatedButtons = templateButtons.map((btn) => {
      if (btn.id !== id) return btn;
      else return { ...btn, [`${key}`]: e.target.value };
    });
    setTemplateButtons(updatedButtons);
  };

  const handleSaveButton = (id) => {
    let updatedButtons = templateButtons.map((btn) => {
      if (btn.id !== id) return btn;
      else return { ...btn, isSaved: true };
    });
    setTemplateButtons(updatedButtons);
  };

  const steps = [];
  steps.push(
    addsteps(
      "Information",
      "info",
      `${id ? `/runcampaign/${id}` : `/runcampaign/new`}`
    )
  );
  steps.push(
    addsteps(
      "Edit Template",
      "info",
      `${
        id
          ? `/runcampaign/selecttemplate/${id}`
          : `/runcampaign/selecttemplate/new`
      }`,
      true,
      true
    )
  );
  steps.push(
    addsteps(
      "Select Product",
      "prod",
      `${
        id
          ? `/runcampaign/selectproducts/${id}`
          : `/runcampaign/selectproducts/new`
      }`,
      campaignType === "product"
    )
  );
  steps.push(
    addsteps(
      "Select Customer",
      "cust",
      `${
        id
          ? `/runcampaign/selectcustomer/${id}`
          : `/runcampaign/selectcustomer/new`
      }`
    )
  );
  steps.push(
    addsteps(
      "Schedule Campaign",
      "camp",
      `${id ? `/runcampaign/schedule/${id}` : `/runcampaign/schedule/new`}`
    )
  );

  // handle sumbit formdata
  const onSubmit = (data) => {
    const watchTempType = watch("templateType");
    if (file == null && !["TEXT", "CAROUSEL"].includes(watchTempType)) {
      toast.current.show({
        severity: "warn",
        detail: `Please select a ${watchTempType}`,
      });
      return;
    }
    if (watchTempType !== "TEXT") {
      let { isValid, acceptedSize } = validateMediaSize(
        file.size,
        watchTempType.toLowerCase()
      );
      if (!isValid) {
        toast.current.show({
          severity: "warn",
          detail: `Please select ${watchTempType}, less than ${acceptedSize} MB in size`,
        });
        return;
      }
    }
    const { elementName, templateId, containerMeta } = selectedTemplate;
    const { buttons } = JSON.parse(containerMeta);
    const variablesData = {};
    let customvalue = false;
    tempVariables.forEach((item, i) => {
      if (item.variableValue.length === 0) {
        customvalue = true;
      }
      if (item.variableType === "customNote") {
        variablesData[item.proxyId] = item.variableValue;
      }
    });
    if (customvalue) {
      toast.current.show({
        severity: "warn",
        detail: `Please enter Custom Value`,
      });
      return;
    }
    let configData = {
      SKUCodes: [],
      ...broadcastdetails?.configData,
      ...data,
      campaignName: campaignName,
      campaignType: "broadcast",
      message: formateMessageInput(watch("message"), "", tempVariables),
      variables: { ...variablesData },
      templateId: templateId,
      filename: file ? file.name : "",
      buttons: buttons != undefined ? JSON.stringify([...buttons]) : [],
    };
    dispatch(
      setBroadcastDetails({
        configData,
        media: file ? window.URL.createObjectURL(file) : file,
      })
    );
    if (!id) {
      dispatch(dispatch(changestepcount(campaignType === "product" ? 2 : 3)));
    }
    navigate(
      `${
        campaignType === "product"
          ? `${
              id
                ? `/runcampaign/selectproducts/${id}`
                : `/runcampaign/selectproducts/new`
            }`
          : `${
              id
                ? `/runcampaign/selectcustomer/${id}`
                : `/runcampaign/selectcustomer/new`
            }`
      }`
    );
  };

  useEffect(() => {
    if (selectedTemplate != null) {
      const { containerMeta, variables, templateType } = selectedTemplate;

      if (containerMeta) {
        const { header, buttons, footer, data, mediaId } =
          JSON.parse(containerMeta);
        let messStr = data || "";
        let messageNumber = 1;
        if (variables && variables.length != 0) {
          const templVars = variables?.map((item, index) => {
            messStr = messStr.replace(
              item.variableName,
              `{{${
                item.variableType == "customNote"
                  ? `message${messageNumber}`
                  : item.variableType
              }}}`
            );
            return {
              ...item,
              variableName:
                item.variableType == "customNote"
                  ? `{{message${messageNumber++}}}`
                  : `{{${item.variableType}}}`,
              id: Math.floor(1000 + Math.random() * 9000),
              proxyId: item.variableName,
              variableValue: item.variableValue || "",
            };
          });
          setTempvariables(templVars);
        }
        if (buttons != undefined) {
          const tempButtons = buttons?.map((item) => ({
            ...item,
            id: Math.floor(1000 + Math.random() * 9000),
            isSaved: true,
          }));
          setTemplateButtons(tempButtons);
        }

        setValue("message", messStr);
        setValue("header", header || "");
        setValue("footer", footer || "");
        setValue("templateType", templateType);
      }
    }
  }, [selectedTemplate]);
  const switchform = (link) => {
    navigate(link);
  };
  const navigate = useNavigate();
  const { navigation, setAlert } = useUtility();

  const cancelBroadcast = () => {
    dispatch(resetBroadcast());
    dispatch(resetAll());
    navigation(`${id ? `/campaigns/${id}` : `/campaigns`}`);
  };

  useEffect(() => {
    if (completeSteps === 0) {
      navigate(`${id ? `/runcampaign/${id}` : `/runcampaign/new`}`);
    }
  }, [completeSteps]);

  const itemslist = [{ label: "Run Campaign", url: "" }];
  return (
    <div className="w-full m-auto ">
      <div className="w-full m-auto overflow-x-auto">
        <CustomBreadcrumb
          home={{ label: "Campaign", url: "/campaigns" }}
          itemslist={itemslist}
        />
      </div>
      <div className="p-3 pt-1">
        <div
          className="w-full m-auto flex flex-row justify-content-between align-items-center  my-2 overflow-x-auto border-round-sm "
          style={{ background: "#f2f2f2" }}
        >
          <div className="p-2 py-3 flex flex-row ">
            {steps.map((x, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-row gap-2 mr-3 cursor-pointer"
                  onClick={() => {
                    index <= completeSteps && x.show && switchform(x.url);
                  }}
                >
                  <div>
                    <Badge
                      value={index + 1}
                      style={{
                        background: index <= completeSteps ? `#1c738e` : `grey`,
                        opacity: `${x.show ? `1` : `.5`}`,
                      }}
                    />
                  </div>
                  <div
                    className={`white-space-nowrap  ${
                      x.panel ? `border-none border-bottom-1 border-solid` : ``
                    }`}
                    style={{ borderColor: "#1c738e" }}
                  >
                    {x.name}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-row gap-2 align-items-center px-2">
            <div
              className="font-medium text-sm cursor-pointer"
              onClick={cancelBroadcast}
            >
              Cancel
            </div>
            <div>
              <CustomButton
                varient="plain-button w-full"
                type="reset"
                onClick={() => {
                  navigate(`${id ? `/runcampaign/${id}` : `/runcampaign/new`}`);
                }}
                label={"Back"}
              />
            </div>
            <div>
              <CustomButton
                varient="filled w-full"
                type="submit"
                onClick={handleSubmit(onSubmit)}
                label={<span>Save&nbsp;&&nbsp;Continue</span>}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full flex-wrap-reverse pt-3">
          <div className="flex flex-column w-8 flex-grow-1 lg:flex-grow-0 xl:flex-grow-0 justify-content-between">
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid w-full">
              {headerContainer()}
              <div className="field mt-2">
                <label htmlFor="message">
                  Template&nbsp;Name&nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="capitalize text-base font-bold">
                    {selectedTemplate?.elementName}
                  </span>
                </label>
              </div>
              <div className="field mt-2">
                <label htmlFor="message">Body *</label>
                <Controller
                  name="message"
                  control={control}
                  rules={{ required: "message is required." }}
                  render={({ field, fieldState }) => (
                    <InputTextarea
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      id={field.name}
                      value={field.value}
                      rows={6}
                      cols={30}
                      {...field}
                      maxLength={1028}
                      autoResize
                      placeholder="Enter your message (max 1028 chars)"
                      disabled
                    />
                  )}
                />
                {getFormErrorMessage("message")}
                <div className="my-2">
                  {tempVariables
                    ?.filter((item) => item.variableType === "customNote")
                    ?.map((item, i) => (
                      <div
                        className="flex gap-2 justify-content-center align-items-center my-3"
                        key={item.id}
                      >
                        <div className="w-3">
                          {i == 0 && <label>Variable</label>}

                          <InputText value={item.variableName} disabled />
                        </div>
                        <div className="relative flex-grow-1">
                          {i == 0 && <label>Value</label>}
                          <div className="flex align-items-center gap-2">
                            <InputTextarea
                              rows={1}
                              autoResize
                              value={item.variableValue}
                              placeholder={`Enter value`}
                              onChange={(e) => handleEditVariable(e, item.id)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {false && templateButtons.length !== 0 && (
                <div className="field">
                  <Text>Buttons</Text>
                  <div className="button-preview-container w-full mb-2 py-2">
                    {templateButtons?.map((item) =>
                      item.isSaved ? (
                        <div
                          className="m-2  flex justify-content-between p-3"
                          style={{ backgroundColor: "#005068" }}
                          key={item.id}
                        >
                          <Text type={"sub-heading"} color={"#ffffff"}>
                            {item.text}
                          </Text>
                        </div>
                      ) : (
                        <div className="m-2 relative" key={item.id}>
                          <div className="relative mt-2">
                            <div className="flex flex-column gap-2">
                              <InputText
                                placeholder="Enter Label (Max 17 Char)"
                                value={item.text}
                                onChange={(e) =>
                                  handleButtonEdit(e, item.id, "text")
                                }
                                maxLength={17}
                              />
                              {item.type == "PHONE_NUMBER" && (
                                <InputText
                                  placeholder="Enter phone number"
                                  value={item.phone_number}
                                  onChange={(e) =>
                                    handleButtonEdit(e, item.id, "phone_number")
                                  }
                                />
                              )}
                              {item.type == "URL" && (
                                <InputText
                                  placeholder="Enter URL"
                                  value={item.url}
                                  onChange={(e) =>
                                    handleButtonEdit(e, item.id, "url")
                                  }
                                />
                              )}
                            </div>

                            <div
                              className=" flex justify-content-end gap-3 p-2"
                              style={{ right: 8, top: 8 }}
                            >
                              <i
                                className="pi pi-check"
                                onClick={() => handleSaveButton(item.id)}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}

              {/* <div className="flex gap-3 mt-6">
                  <CustomButton
                    varient="filled"
                    type="submit"
                    label={"Continue with Selected Template"}
                  />
                </div> */}
            </form>
          </div>
          <div
            className={`flex flex-grow-1 justify-content-center mob-preview-container `}
            style={{ minHeight: "620px" }}
          >
            <div className="relaive w-full flex justify-content-center">
              <div className="static lg:fixed xl:fixed">
                <div className="flex flex-column justify-content-center align-items-center">
                  <div className="min-h-full">
                    <TemplatePreview
                      header={watch("header")}
                      footer={watch("footer")}
                      buttons={templateButtons}
                      content={formateMessageInput(
                        watch("message"),
                        "preview",
                        tempVariables
                      )}
                      videoPreview={videoPreview}
                      documentPreview={documentPreview}
                      imagePreview={imagePreview}
                      file={file}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
