import "./style.css";
import { TabMenu } from "primereact/tabmenu";
import { useState } from "react";
const CustomTabMenu = ({ items = [],index=0, handleChange = () => {} }) => {
  const [activeIndex, setActiveIndex] = useState(index);

  const handleTabChange = (e) => {
    setActiveIndex(e.index);
    handleChange(e.value.value);
  };
  return (
    <TabMenu
      model={items}
      activeIndex={activeIndex}
      onTabChange={handleTabChange}
      className={`custom-tabmenu`}
    />
  );
};

export default CustomTabMenu;
