import CampaignTable from "./CampaignTable";
import "./tabStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router";
import { useEffect } from "react";
import {
  resetCampaignData,
  resetCampaignPagination,
} from "../../reducers/campaignSlice";
import TrailDashboard from "../TrailDashboard";

const Campaigns = () => {
  const { userSub } = useSelector((state) => state.authenticate);
  const { id } = useParams();
  const dispatch = useDispatch();
  const renderCampaign = () => {
    if (id) return <Outlet />;
    else return <CampaignTable />;
  };

  useEffect(() => {
    return () => {
      dispatch(resetCampaignData());
      dispatch(resetCampaignPagination());
    };
  }, []);
  return (
    <div className="w-full p-2 m-auto">
      {!userSub.isUserSub ? <TrailDashboard /> : renderCampaign()}
    </div>
  );
};

export default Campaigns;
