import { TwitterAuthProvider } from "firebase/auth";
import axiosInstance from "./axios.instance";

const API_GET_ALL_USERS = async () => {
  try {
    const resp = await axiosInstance.get("/user");
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_GET_USER_BY_ID = async (id) => {
  try {
    const resp = await axiosInstance.get(`/user/${id}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_ADD_NEW_USER = async (data) => {
  try {
    const resp = await axiosInstance.post(`/user`, data);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_EDIT_USER = async (id, data) => {
  try {
    const resp = await axiosInstance.put(`/user/${id}`, data);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_GET_ALL_AGENTS = async () => {
  try {
    const resp = await axiosInstance.get("/user?role=support&isActive=1");
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_CHANGE_AGENT = async (ticketId, payload) => {
  try {
    const resp = await axiosInstance.put(
      `agent-ticket/${ticketId}/assign`,
      payload
    );
    return resp?.data;
  } catch (error) {
    throw error;
  }
};

const API_GET_QUICK_NOTES = async (page = 0, limit = 10) => {
  try {
    const resp = await axiosInstance.get(
      `/quicknotes?page=${page}&limit=${limit}&isActive=1`
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
const API_GET_QUICK_NOTE = async (id) => {
  try {
    const resp = await axiosInstance.get(`/quicknotes/${id}`);
    return resp;
  } catch (error) {
    throw error;
  }
};
const API_ADD_QUICK_NOTE = async (configData) => {
  try {
    const resp = await axiosInstance.post("/quicknotes", configData);
    return resp;
  } catch (error) {
    throw error;
  }
};

const API_PUT_QUICK_NOTES = async (id, configData) => {
  try {
    const resp = await axiosInstance.put(`/quicknotes/${id}`, configData);
    return resp;
  } catch (error) {
    throw error;
  }
};

const API_DELETE_QUICK_NOTES = async (id) => {
  try {
    const resp = await axiosInstance.delete(`/quicknotes/${id}`);
    return resp;
  } catch (error) {
    throw error;
  }
};

const API_BUISENESS_INFO = async () => {
  try {
    const resp = await axiosInstance.get(`/businessInfo`);
    return resp.data;
  } catch (error) {
    throw error;
  }
};
const API_UPDATE_BUISENESS_INFO = async (data) => {
  try {
    const resp = await axiosInstance.post(`/businessInfo`, data);
    return resp.data;
  } catch (error) {
    throw error;
  }
};

const API_FB_CREATE_APP = async () => {
  try {
    const resp = await axiosInstance.post("company/create-gupshup-app");
    return resp.data;
  } catch (error) {
    throw error;
  }
};
const API_FB_APP_STATUS = async () => {
  try {
    const resp = await axiosInstance.post("/company/check-fb-status");
    return resp.data;
  } catch (error) {
    throw error;
  }
};
export {
  API_GET_ALL_USERS,
  API_GET_USER_BY_ID,
  API_ADD_NEW_USER,
  API_EDIT_USER,
  API_GET_ALL_AGENTS,
  API_CHANGE_AGENT,
  API_ADD_QUICK_NOTE,
  API_GET_QUICK_NOTES,
  API_DELETE_QUICK_NOTES,
  API_PUT_QUICK_NOTES,
  API_BUISENESS_INFO,
  API_UPDATE_BUISENESS_INFO,
  API_GET_QUICK_NOTE,
  API_FB_CREATE_APP,
  API_FB_APP_STATUS,
};
