import { Handle, Position } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  changeSelectedNode,
  closeConfigScreen,
  openConfigScreen,
  undoNodeData,
} from "../../../reducers/canvas/canvasSlice";
import styles from "./style.module.css";
import { Image } from "primereact/image";
import { handleInputType } from "../../../reducers/multiInputSlice";
import { getTheNode } from "../../../utils/canvasUtils";
import { tailNodes } from "../node_constant";
import { NodeHeader } from "../NodeHeader";
import { StartTag } from "../StartTag";
import EditButton from "../Editbutton";
import { Text } from "../../Text";
import { isTextNodeValid } from "../../../view/Bots/BotBuilder/node-constant";
function TextNode(props) {
  const dispatch = useDispatch();

  const { rootStepId, selectedNode, nodes, configScreenOpen } = useSelector(
    (state) => state.canvas
  );

  const handleNodeSelection = () => {
    if (selectedNode?.id !== props.id) {
      if (configScreenOpen) {
        dispatch(undoNodeData());
        dispatch(closeConfigScreen());
      }
      dispatch(changeSelectedNode(getTheNode(nodes, props?.id)));
    }
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    dispatch(undoNodeData());
    dispatch(handleInputType(props.data.type));
    dispatch(openConfigScreen(getTheNode(nodes, props?.id)));
  };

  const nodeStatusClass =
    selectedNode?.id == props?.id
      ? styles["selected-node"]
      : !isTextNodeValid(props.data)
      ? styles["invalid-node"]
      : "";

  return (
    <>
      {rootStepId == props?.id && <StartTag startNodeData={props?.data} />}
      <div
        onClick={handleNodeSelection}
        className={`${styles.textNode} ${nodeStatusClass}`}
      >
        <Handle type="target" position={Position.Left} />
        <div className={`${styles.nodeData} p-2 w-full`}>
          <div className={`w-full h-full `}>
            <EditButton onClick={handleEditClick} />
            <NodeHeader nodeType={props.data.type} />
            {!tailNodes.includes(props.data.type) && (
              <div className={` mt-2 p-2 ${styles["text-message"]}`}>
                {props.data?.image && (
                  <Image
                    src={props.data?.image}
                    width="100%"
                    alt="node_image"
                  />
                )}

                {props.data?.video && (
                  <video className={`${styles.videoElement}`} width="100%">
                    <source src={props.data.video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
                <Text type={"small-text"}>
                  <div
                    style={{ whiteSpace: "pre-line" }}
                    className={`w-full mt-2 `}
                  >
                    {props.data?.label}
                  </div>
                </Text>
              </div>
            )}
          </div>
        </div>
        <div>
          <Handle type="source" position={Position.Right} />
        </div>
      </div>
    </>
  );
}

export default TextNode;
