import React, { Children, useEffect, useRef, useState } from "react";
import styles from "./replymessage.module.css";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Text } from "../../components/Text";
import { Image } from "primereact/image";
import { ReactComponent as DownloadIcon } from "../../svg/download.svg";
import { ReactComponent as EmojiIcon } from "../../assets/svgIcons/Emoji1.svg";
import {
  setForwardMessage,
  setForwardFirstMessageId,
  setReplyMessage,
} from "../../reducers/enqChatSlice";
import { CustomMessageText } from "../../components/CustomMessageText/CustomMessageText";
import { ReactComponent as ShareSvg } from "../../assets/svgIcons/svgIcons/whatsappForward.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import MessageStatus from "./components/message-status.js";
import { formatAMPM } from "../../utils/chatUtils";
import Contacts from "./components/message-cards/contacts-card.js";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { API_SEND_MSG } from "../../api/chat.service";
import ReactionsModal from "../../components/ReactionsModal/ReactionsModal";
import {
  query,
  collection,
  onSnapshot,
  orderBy,
  limit,
  startAfter,
  getDocs,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { formatMessage } from "../../utils/chatUtils";
import { formatMobNumber } from "../../utils/commonUtils";

const ChatButton = ({ title, id }) => {
  return (
    <button
      key={id}
      style={{
        flexGrow: "1",
        color: "#1c738e",
        borderRadius: "4px",
        border: "none",
      }}
      className="mt-1 p-2"
    >
      {title}
    </button>
  );
};

const Message = ({ message, key_id = "" }) => {
  const [showArrow, setShowArrow] = useState(false);
  const [showDropdownModal, setShowDropdownModal] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [repliedMessText, setRepliedMessText] = useState({});

  const dropdownRef = useRef(null);
  const dropdownModalRef = useRef(null);
  const errorRef = useRef(null);
  const emojiButtonRef = useRef(null);
  const emojiPickerRef = useRef(null);

  const { forwardMessagesFlag, messageForReply, replyMessage, user, chatId } =
    useSelector((state) => state.enqChats);

  const { company, loadingC } = useSelector((state) => state.company);

  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setShowArrow(true);
  };

  const handleMouseLeave = () => {
    setShowArrow(false);
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdownModal((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownModalRef.current &&
        !dropdownModalRef?.current?.contains(event.target)
      ) {
        setShowArrow(false);
        setShowDropdownModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleForwardClick = (event) => {
    // Prevent the click event from propagating to the parent container
    // event.stopPropagation();
    dispatch(setForwardMessage({ forwardMessagesFlag: true }));
    dispatch(
      setForwardFirstMessageId({ clickedForwardFirstMessageId: message.gsId })
    );
  };
  const handleReplyClick = (e) => {
    dispatch(
      setReplyMessage({
        replyMessage: true,
        messageForReply: message,
        repliedMessageFlag: true,
      })
    );
  };

  const msgBody = () => {
    let date = formatAMPM(new Date(message?.lastUpdatedAt));

    return (
      <>
        <div className="flex w-full justify-content-between align-items-center ">
          <div>
            <CustomMessageText type={"sub-heading"}>
              {message.message.text}
            </CustomMessageText>
          </div>
          <div className="flex mt-1 ml-1">
            <span className="__date mt-1">{`${date}`}</span>
            {message?.messageType === "inbound" ? (
              <></>
            ) : (
              <div className="ml-1">{<MessageStatus message={message} />}</div>
            )}
          </div>
        </div>
      </>
    );
  };

  const failedIcon = () => {
    if (message?.err) {
      const { code, reason } = JSON.parse(message?.err);
      return (
        <div className="relative">
          <Image
            className="failIcon cursor-pointer"
            width="14"
            height="14"
            key={(Math.random() * 100) | 0}
            src="https://img.icons8.com/color/48/high-importance--v1.png"
            alt="checkmark--v1"
            data-pr-tooltip={reason}
            onClick={(e) => errorRef.current.toggle(e)}
          />
          <OverlayPanel ref={errorRef}>
            <Text type={"sub-heading"}>
              Error({code}):&nbsp;{reason}
            </Text>
          </OverlayPanel>
        </div>
      );
    }
  };

  const OrderTable = (items) => {
    return (
      <div
        className="card mt-1"
        style={{
          borderRadius: "4px",
          overflow: "hidden",
        }}
      >
        <DataTable
          className="skalebot-table"
          value={items}
          tableStyle={{ minWidth: "25rem" }}
        >
          <Column field="id" header="id"></Column>
          <Column field="amount" header="amount"></Column>
          <Column field="quantity" header="Quantity"></Column>
        </DataTable>
      </div>
    );
  };

  const CampaignLink = (msg) => {
    let date = formatAMPM(new Date(message?.lastUpdatedAt));
    return (
      <>
        <div className="flex w-full justify-content-between align-items-center">
          <Link to={`/${msg.link}`}>Go to Campaign</Link>
          <div className="flex mt-1 ml-1">
            <span className="__date mt-2">{`${date}`}</span>
            {message?.messageType == "outbound" && (
              <div className="ml-1">{<MessageStatus message={message} />}</div>
            )}
          </div>
        </div>
      </>
    );
  };

  const DocumentView = () => {
    return (
      <div className="chatImgContainer">
        <div className="flex __file justify-content-center align-items-center">
          <i
            className="pi pi-file "
            style={{ fontSize: "38px", color: "gray" }}
          />
          <div className="ml-1 flex flex-column  w-8 lg:w-full ">
            <span
              style={{ fontSize: "15px", color: "gray" }}
              className="overflow-hidden	 "
            >
              {message.message.filename}
            </span>
            {message.message.size && (
              <span
                className="mt-1"
                style={{ fontSize: "12px", color: "gray" }}
              >
                {message.message.size / 1000} kb
              </span>
            )}
          </div>
          <div
            className="flex  justify-content-center align-items-center cursor-pointer"
            style={{
              background: "transparent",
              top: "10px",
              paddingLeft: "20px",
            }}
            onClick={() => {
              handleDownload(
                message.message.url,
                extractFileNameFromUrl(message.message)
              );
            }}
          >
            <DownloadIcon />
          </div>
        </div>
      </div>
    );
  };

  const downloadFile = (msg) => {
    const fileUrl = msg.url;
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.target = "_blank";
    anchor.download = msg.filename;
    anchor.click();
  };
  function extractFileNameFromUrl(object) {
    const { url, contentType } = object;
    try {
      const urlObject = new URL(url);
      const pathnameWithoutQuery = urlObject.pathname.split("?")[0];
      const pathSegments = pathnameWithoutQuery.split("/");
      const lastSegment = pathSegments[pathSegments.length - 1];
      const hasExtension = /\.\w+$/.test(lastSegment);
      if (!hasExtension && contentType) {
        const contentTypeMatch = contentType.match(/\/([a-zA-Z0-9]+)/);
        const detectedExtension = contentTypeMatch
          ? contentTypeMatch[1]
          : "unknown";
        const modifiedFileName = `${lastSegment}.${detectedExtension}`;
        return modifiedFileName;
      } else {
        return `${lastSegment}.mp4`;
      }
    } catch (error) {
      console.error("Error parsing URL:", error);
      return null;
    }
  }
  const handleDownload = async (url, fileName) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const onClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      emojiButtonRef.current &&
      !emojiButtonRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, []);

  useEffect(() => {
    const msgId =
      message?.message?.context?.msgId ||
      message?.message?.context?.gsId ||
      message?.message?.context?.id;
    if (msgId) {
      const q = query(
        collection(db, "chats", chatId, "messages"),
        where("gsId", "==", msgId)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let chats = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          let formatedMessage = formatMessage(
            JSON.parse(data.message),
            data.messageType
          );

          setRepliedMessText({
            message: formatedMessage,
            messageType: data.messageType,
          });
        });
      });
    }
  }, [message]);
  // Handle emoji button click to toggle emoji picker
  const handleEmojiButtonClick = (event) => {
    event.stopPropagation();
    setShowEmojiPicker((prev) => !prev);
  };

  // Handle emoji selection
  const handleEmojiSelect = (emoji) => {
    setSelectedEmoji(emoji.native);
    setShowEmojiPicker(false);

    // Make the API call with the selected emoji
    sendEmojiReaction(emoji.native);
  };

  // Function to send emoji reaction to the server
  const sendEmojiReaction = async (emoji) => {
    const data = {
      destinationNo: user?.mob,
      payload: {
        type: "reaction",
        msgId: message.gsId,
        emoji,
      },
    };

    const ticketId = user?.ticketId;

    try {
      const resp = await API_SEND_MSG(ticketId, data);
    } catch (error) {}
    // Make an API call here (replace with actual API call)
  };

  // Function to render emoji reactions
  const renderReactions = () => {
    if (message?.reaction) {
      const reactions = Object?.entries(message?.reaction);
      if (reactions?.length > 0) {
        return (
          <div
            className="message-reactions"
            style={{
              background:
                message?.messageType === "inbound" ? "#f5f8fe" : "#f8f8f9",
              justifyContent:
                message?.messageType === "inbound" ? "flex-start" : "flex-end",
            }}
            onClick={openReactionsModal}
          >
            {reactions.map(([userId, emoji]) => (
              <span key={userId} className="reaction">
                {emoji}
              </span>
            ))}
          </div>
        );
      }
    }
    return null;
  };

  // Handle opening the modal
  const openReactionsModal = (event) => {
    setShowReactionsModal(true);
  };

  // Handle closing the modal
  const closeReactionsModal = () => {
    setShowReactionsModal(false);
  };

  const isEnquiryRecent = (enqStartedAt) => {
    const twentyFourHoursInMs = 24 * 60 * 60 * 1000;
    return Date.now() - enqStartedAt <= twentyFourHoursInMs;
  };

  const renderReplyMessage = (message) => {
    const getIconName = (messageType) => {
      switch (messageType) {
        case "image":
          return "pi-image";
        case "video":
          return "pi-video";
        case "audio":
          return "pi-volume-up";
        case "file":
        case "document":
          return "pi-file";
        case "link":
          return "pi-link";
        case "order":
          return "pi-shopping-cart";
        case "location":
          return "pi-map-marker";
        default:
          return "pi-file";
      }
    };

    const renderTextMessage = () => (
      <div className={styles.messageContainer}>
        {message?.messageType === "outbound" ? (
          <Text color={"#005068"}>You</Text>
        ) : (
          <Text color={"#005068"}>{customerTitle}</Text>
        )}
        <Text type={"sub-heading"}>{message?.message?.text}</Text>
      </div>
    );

    const renderMediaMessage = (mediaType, mediaElement) => (
      <div className={` ${styles.messageContainer} ${styles.mediaContainer}`}>
        <div className={styles.messageHeader}>
          {message?.messageType === "outbound" ? (
            <Text color={"#005068"}>You</Text>
          ) : (
            <Text color={"#005068"}>{customerTitle}</Text>
          )}
          <div className={styles.iconText}>
            <i
              className={`pi ${getIconName(mediaType)}`}
              style={{ fontSize: "1rem" }}
            ></i>
            <Text color={"#005068"}>{mediaType}</Text>
          </div>
        </div>
        {mediaElement}
      </div>
    );

    const renderMediaContent = (messageType) => {
      switch (messageType) {
        case "image":
          return (
            <Image
              src={message?.message?.url}
              alt="Image"
              width="34"
              height="38"
              preview
            />
          );
        case "video":
          return (
            <video width="34" height="38" controls={false}>
              <source src={message?.message?.url} />
              Your browser does not support the video tag.
            </video>
          );
        case "audio":
          return (
            <audio
              src={message?.message?.url}
              controls
              controlsList="nodownload noplaybackrate"
              className="audio"
            />
          );
        case "file":
          return (
            <div className="file-content">
              <span className="file-name">{message?.message?.filename}</span>
            </div>
          );
        default:
          return null;
      }
    };

    const customerName =
      user?.displayName &&
      user.displayName.charAt(0).toUpperCase() + user.displayName.substring(1);

    const customerTitle = user?.displayName
      ? customerName
      : formatMobNumber(user?.mob);

    if (message?.message?.messageType === "text") {
      return renderTextMessage();
    }

    // Render media content
    const mediaContent = renderMediaContent(message?.message?.messageType);
    return renderMediaMessage(message?.message?.messageType, mediaContent);
  };

  return (
    <>
      <div
        key={key_id}
        className={`message ${message?.messageType}`}
        style={{ alignItems: "center" }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="messageContent">
          {repliedMessText?.message && renderReplyMessage(repliedMessText)}
          {showArrow && !forwardMessagesFlag && (
            <div
              className="absolute right-0 top-0 cursor-pointer"
              style={{
                zIndex: 15,
                display: message.messageType === "inbound" ? "block" : "none",
              }}
              onClick={toggleDropdown}
            >
              <i
                className="pi pi-angle-down"
                style={{ color: "#cccccd", fontSize: "1.125rem" }}
              ></i>
              {showDropdownModal && (
                <div
                  className="flex flex-column gap-3 bg-white p-3 absolute right-0 top-10"
                  ref={dropdownRef}
                >
                  <h5
                    className={`m-0 ${
                      message.message.messageType === "image" ||
                      message.message.messageType === "video" ||
                      message.message.messageType === "file" ||
                      message.message.messageType === "audio" ||
                      message.message.messageType === "text"
                        ? // message.message.messageType === "location"
                          ""
                        : "disabledShareButton"
                    } `}
                    onClick={handleForwardClick}
                  >
                    Forward
                  </h5>
                  {isEnquiryRecent(user?.enqStartedAt) && (
                    <h5 className="m-0" onClick={(e) => handleReplyClick(e)}>
                      Reply
                    </h5>
                  )}
                </div>
              )}
            </div>
          )}
          <div className="flex flex-row justify-content-center align-items-center  w-full gap-2 relative">
            {showArrow && !forwardMessagesFlag && (
              <div
                className={`mt-1 ${
                  message.message.messageType === "image" ||
                  message.message.messageType === "video" ||
                  message.message.messageType === "file" ||
                  message.message.messageType === "audio" ||
                  message.message.messageType === "location" ||
                  message.message.messageType === "text"
                    ? ""
                    : "disabledShareButton"
                } `}
                style={{
                  width: "1.125rem",

                  display:
                    message.messageType === "outbound" ? "block" : "none",
                }}
                onClick={(e) => handleForwardClick(e)}
              >
                <ShareSvg />
              </div>
            )}

            <div
              className={
                message.message.messageType == "audio" ||
                message.message.messageType == "file"
                  ? "ml-0 w-full"
                  : "container ml-0 "
              }
            >
              {message.message.messageType == "location" && (
                <div
                  className="chatImgContainer"
                  style={{
                    position: "relative",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(
                      `https://maps.google.com/?q=${message?.message?.latitude},${message?.message?.longitude}`,
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <img
                    src={"./images/GooglemapMarker.svg"}
                    alt=""
                    preview
                    width="100%"
                    style={{
                      maxWidth: "300px",
                      aspectRatio: "auto",
                      border: ".5px solid #1c738e",
                      borderRadius: "6px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "30%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "14px",
                    }}
                  >
                    <i
                      class="pi pi-map-marker"
                      style={{ fontSize: "1rem", top: "30%" }}
                    >
                      {" "}
                      Location
                    </i>
                  </div>
                  <span
                    style={{
                      color: "#1c738e",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "10px",
                      display: "block",
                      textDecoration: "none",
                      cursor: "pointer",
                      flex: "start",
                    }}
                  >
                    View on Map
                  </span>
                </div>
              )}
              {message.message.messageType == "image" && (
                <div className="chatImgContainer">
                  <Image
                    src={message.message.url || "./images/ImgPlaceholder.svg"}
                    zoomSrc={
                      message.message.url || "./images/ImgPlaceholder.svg"
                    }
                    onError={(e) =>
                      (e.target.src = "./images/ImgPlaceholder.svg")
                    }
                    alt=""
                    preview
                    width="100%"
                    style={{ maxWidth: "300px", aspectRatio: "auto" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                  {message.messageType == "inbound" && (
                    <div
                      className="absolute cursor-pointer"
                      style={{
                        background: "transparent",
                        top: "15px",
                        right: "15px",
                      }}
                      onClick={() => {
                        handleDownload(
                          message.message.url,
                          extractFileNameFromUrl(message.message)
                        );
                      }}
                    >
                      <DownloadIcon />
                    </div>
                  )}
                </div>
              )}
              {message.message.messageType == "video" && (
                <div className="chatImgContainer relative">
                  <video
                    id="videoPlayer"
                    width={"100%"}
                    height={"250px"}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      maxHeight: "250px",
                      aspectRatio: "auto",
                    }}
                    controls
                    controlsList={
                      message.messageType == "inbound" ? "nodownload" : ""
                    }
                  >
                    <source src={message.message.url} type="video/mp4" />
                  </video>
                  {message.messageType == "inbound" && (
                    <div
                      className="absolute cursor-pointer"
                      style={{
                        background: "transparent",
                        top: "10px",
                        right: "10px",
                      }}
                      onClick={() => {
                        handleDownload(
                          message.message.url,
                          extractFileNameFromUrl(message.message)
                        );
                      }}
                    >
                      <DownloadIcon />
                    </div>
                  )}
                </div>
              )}
              {message.message.messageType == "file" && DocumentView()}
              {message.message.messageType == "audio" && (
                <div className="audio-playerl" style={{ position: "relative" }}>
                  <audio
                    src={message.message.url}
                    controls
                    controlsList="nodownload noplaybackrate"
                    className="audio"
                    style={{ height: "70px" }}
                  />
                  {message.messageType === "inbound" && (
                    <div
                      className="absolute cursor-pointer"
                      style={{
                        background: "transparent",
                        top: "75px",
                        left: "15px",
                      }}
                      onClick={() => {
                        handleDownload(
                          message.message.url,
                          extractFileNameFromUrl(message.message)
                        );
                      }}
                    >
                      <DownloadIcon />
                    </div>
                  )}
                </div>
              )}
              {message.message.messageType == "contact" && (
                <Contacts message={message.message} />
              )}
              {message.message.messageType == "link"
                ? CampaignLink(message.message)
                : message.message.text && msgBody()}
            </div>

            {message.messageType === "outbound" &&
              (message.status === "failed" ||
                !(message.isSent || message.isDelivered)) &&
              failedIcon()}

            {/* {showArrow && !forwardMessagesFlag && (
              <div
                className={`mt-1 ${message.message.messageType === "image" ||
                    message.message.messageType === "video" ||
                    message.message.messageType === "file" ||
                    message.message.messageType === "audio" ||
                    message.message.messageType === "text"
                    ? ""
                    : "disabledShareButton"
                  } `}
                style={{
                  width: "1.125rem",

                  display: message.messageType === "inbound" ? "block" : "none"
                }}
                onClick={(e) => handleForwardClick(e)}
              >
                <ShareSvg />
              </div>
            )} */}

            {/* {showArrow && !forwardMessagesFlag && user?.ticketStatus !== "Closed" && isEnquiryRecent(user?.enqStartedAt) && (
              <div
                className="cursor-pointer"
                onClick={handleEmojiButtonClick}
                style={{
                  marginTop: "10px",
                  display: message.messageType === "inbound" ? "block" : "none"
                }}
              >
                <EmojiIcon />
              </div>
            )} */}
          </div>

          <div className="flex flex-wrap gap-2">
            {message.message.messageType == "list" &&
              message.message.button?.map((ele, i) => (
                <div key={i}>
                  <ChatButton title={ele.title} id={i} />
                </div>
              ))}
            {message.message.messageType == "product_details" &&
              message.message.button?.map((ele, i) => (
                <ChatButton title={ele.title} id={i} key={i} />
              ))}
            {message.message.messageType == "order" &&
              OrderTable(message.message.orderItems)}
          </div>

          {renderReactions()}

          {showEmojiPicker && (
            <Picker
              data={data}
              onEmojiSelect={handleEmojiSelect}
              onClickOutside={() => setShowEmojiPicker(false)}
              style={{
                position: "absolute",
                bottom: "50px",
                left: "0",
                maxHeight: "200px !important", // Limit the height
                overflowY: "auto", // Add scroll if necessary
                zIndex: "100",
                border: "1px solid red",
              }}
            />
          )}
          <ReactionsModal
            reactions={message?.reaction}
            visible={showReactionsModal}
            onHide={closeReactionsModal}
          />
        </div>
        {showArrow &&
          !forwardMessagesFlag &&
          user?.ticketStatus !== "Closed" &&
          isEnquiryRecent(user?.enqStartedAt) && (
            <div
              className="cursor-pointer"
              onClick={handleEmojiButtonClick}
              style={{
                marginTop: "10px",
                display: message.messageType === "inbound" ? "block" : "none",
              }}
            >
              <EmojiIcon />
            </div>
          )}
      </div>
    </>
  );
};

export default Message;
