import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import styles from "./style.module.css";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { updateNodePayload } from "../../../reducers/canvas/canvasSlice";
import { getDataFromId } from "../../../utils/nodeUtils";

import { CustomButton } from "../../CustomButton";

const Headers = ({}) => {
  const dispatch = useDispatch();
  const { selectedNode, nodes } = useSelector((state) => state.canvas);
  const [headers, setHeaders] = useState([
    {
      id: uuidv4(),
      key: "",
      value: "",
    },
  ]);

  const handleAddHeader = () => {
    let id = uuidv4();
    let value = "";
    let key = "";
    const newHeader = {
      id,
      key,
      value,
    };
    const updatedHeaders = [...headers, newHeader];
    setHeaders(updatedHeaders);
    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { headers: updatedHeaders },
      })
    );
  };

  const handleHeaderChange = (id, e, type) => {
    let updatedHeaders = headers.map((header) =>
      header.id === id ? { ...header, [type]: e.target.value } : header
    );
    setHeaders(updatedHeaders);
    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { headers: updatedHeaders },
      })
    );
  };

  const handleHeaderDelete = (id) => {
    let updatedHeaders = headers.filter((header) => header.id !== id);
    setHeaders(updatedHeaders);
    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { headers: updatedHeaders },
      })
    );
  };

  useEffect(() => {
    if (selectedNode) {
      const selectedNodeData = getDataFromId(nodes, selectedNode?.id);
      setHeaders(selectedNodeData?.headers);
    }
  }, [selectedNode]);

  return (
    <div className="mt-3">
      {/* <Text type={"sub-heading"}>Headers</Text> */}
      <div className="w-12 flex gap-2  mt-3">
        <div className={styles.params}>Key</div>
        <div className={styles.params}>Value</div>
      </div>
      {headers &&
        headers.map((header, idx) => (
          <div key={header.id}>
            <div className="flex mt-1 gap-2 align-items-center">
              <InputText
                value={header.key}
                onChange={(e) => {
                  handleHeaderChange(header.id, e, "key");
                }}
                placeholder="Key"
                className={`${styles.customInput}`}
              />
              {/* )}
              /> */}

              {/* <Controller
                name={`${header.id}-value`}
                control={control}
                rules={{ required: false }}
                render={({ field, fieldState }) => ( */}
              <InputText
                value={header.value}
                onChange={(e) => {
                  handleHeaderChange(header.id, e, "value");
                }}
                placeholder="Value"
                className={`${styles.customInput}`}
              />

              <i
                className="pi pi-trash ml-1"
                onClick={() => handleHeaderDelete(header.id)}
              ></i>
            </div>

            {(!header.value?.trim() || !header.key?.trim()) && (
              <small className="p-error">
                Please enter a valid key, value pair.
              </small>
            )}
          </div>
        ))}
      <div className="mt-2">
        <CustomButton
          varient="outline"
          icon={"pi pi-plus"}
          label={"Add Header"}
          onClick={handleAddHeader}
        />
      </div>
    </div>
  );
};

export default Headers;
