import axiosInstance from "./axios.instance";

// api calls for enquries
const API_GET_ENQURIES = async (
  pageNo,
  limit,
  startDate,
  endDate,
  filterData,
  globalFilterValue
) => {
  try {
    var resp;
    if (filterData || globalFilterValue || (startDate && endDate)) {
      let allFilter = "";
      if (filterData) {
        filterData.forEach((element) => {
          allFilter += `&${element.key}=${element.value}`;
        });
      }
      if (globalFilterValue) {
        allFilter += `&global=${globalFilterValue}`;
      }
      if (startDate && endDate) {
        allFilter += `&startDate=${startDate}&endDate=${endDate}`;
      }
      resp = await axiosInstance.get(
        `/enquiry?page=${pageNo}&limit=${limit}&isActive=1${allFilter}`
      );
      return resp.data;
    } else {
      resp = await axiosInstance.get(
        `/enquiry?page=${pageNo}&limit=${limit}&isActive=1`
      );
      return resp.data;
    }
  } catch (err) {
    throw err;
  }
};

const API_PUT_ENQURY = async (enquiryId, updatedData) => {
  try {
    const resp = await axiosInstance.put(`/enquiry/${enquiryId}`, updatedData);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_GET_AGENT_STATUS = async (agentId) => {
  try {
    const resp = await axiosInstance.get(`/agent-status/${agentId}`);
    return resp?.data;
  } catch (error) {
    throw error;
  }
};

const API_PUT_AGENT_STATUS = async (agentId, payload) => {
  try {
    const resp = await axiosInstance.put(`/agent-status/${agentId}`, payload);
    return resp?.data;
  } catch (error) {
    throw error;
  }
};

const API_GET_AGENT_TITCKETS = async (agentId) => {
  try {
    const resp = await axiosInstance.get(`/agent-ticket/${agentId}`);
    return resp?.data;
  } catch (error) {
    throw error;
  }
};

const API_PUT_TICKET_STATUS = async (titcketId, payload) => {
  try {
    const resp = await axiosInstance.put(`/agent-ticket/${titcketId}`, payload);
    return resp?.data;
  } catch (error) {
    throw error;
  }
};

export {
  API_GET_ENQURIES,
  API_PUT_ENQURY,
  API_GET_AGENT_STATUS,
  API_PUT_AGENT_STATUS,
  API_GET_AGENT_TITCKETS,
  API_PUT_TICKET_STATUS,
};
