import React from "react";
import styles from "./welcome.module.css";
import { useState } from "react";
import { ReactComponent as WelcomeQr } from "../../assets/welcomeQr.svg";
import messagesicon from "../../assets/welcomeScreen/salesDetailsIcon/messagesIcon.svg";
import salesEnquiryIcon from "../../assets/welcomeScreen/salesDetailsIcon/salesEnquiryIcon.svg";
import servingicon from "../../assets/welcomeScreen/salesDetailsIcon/servingIcon.svg";
import agentsicon from "../../assets/welcomeScreen/salesDetailsIcon/agentsIcon.svg";
import rainbow from "../../assets/welcomeScreen/salesDetailsIcon/rainbow.svg";
import { WelcomeCardData } from "./WelcomeCardData";
import Login from "./Login";
import Signup from "../SignUp/index";
import { useLocation } from "react-router-dom";

const Welcome = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const location = useLocation();

  return (
    <div className={styles.welcomeContainer} id="home">
      <div className={styles.welcomeSubContainer}>
        <div className={styles.headingContainer}>
          <h3 className={styles.heading}>
            Your Trusted Sales Assistant <br /> for{" "}
            <strong> Business Optimization </strong>
          </h3>
          <p className={styles.skalebotParagraph}>
          Skalebot is AI-based WhatsApp chatbot by Deskala that enables businesses to engage with their customers on WhatsApp.
          </p>
          {/* <p className={styles.skalebotParagraph}>
            Skalebot is an AI solution by Deskala that enables businesses to tap
            into the ecommerce market.
          </p> */}
          <div className={styles.qr}>
            <WelcomeQr />
          </div>
          <p className={styles.skalebotParagraph} style={{ fontWeight: 600 }}>
            Scan and experience the Skalebot in Whatsapp
          </p>
          <div className={styles.buttonContainer}></div>
        </div>

        {location.pathname === "/login" && <Login />}
        {location.pathname === "/signup" && <Signup />}

        <div className={styles.rainbow}>
          <img src={rainbow} alt="rainbow" className={styles.rainbowImg} />
        </div>
      </div>
      <div className={styles.welcomeThirdSubContainer}>
        <div className={styles.thirdSubInsideContainer}>
          <div className={styles.statics}>
            <div className={styles.staticsIconContainer}>
              <img src={servingicon} alt="servingicon" />
              <p className={styles.staticsNum}> 30+</p>
            </div>
            <p className={styles.staticsDesc}>Customers</p>
          </div>
          <div className={styles.statics}>
            <div className={styles.staticsIconContainer}>
              <img src={salesEnquiryIcon} alt="servingicon" />
              <p className={styles.staticsNum}> 5000+</p>
            </div>
            <p className={styles.staticsDesc}>Sales Enquires</p>
          </div>
          <div className={styles.statics}>
            <div className={styles.staticsIconContainer}>
              <img src={agentsicon} alt="servingicon" />
              <p className={styles.staticsNum}> 150+</p>
            </div>
            <p className={styles.staticsDesc}>Agents</p>
          </div>
          <div className={styles.statics}>
            <div className={styles.staticsIconContainer}>
              <img src={messagesicon} alt="servingicon" />
              <p className={styles.staticsNum}> 200000+</p>
            </div>
            <p className={styles.staticsDesc}>Conversation used</p>
          </div>
        </div>
        <div className={styles.mobileThirdSubInsideContainer}>
          <div className={styles.mobileStatics}>
            <p className={styles.staticsNum}> 30+</p>
            <p className={styles.staticsNum}> 5000+</p>
            <p className={styles.staticsNum}> 150+</p>
            <p className={styles.staticsNum}> 200000+</p>
          </div>
          <div className={styles.mobileStatics}>
            <img src={servingicon} alt="servingicon" />
            <img src={salesEnquiryIcon} alt="servingicon" />
            <img src={agentsicon} alt="servingicon" />
            <img src={messagesicon} alt="servingicon" />
          </div>

          <div className={styles.mobileStaticsDesc}>
            <div className={styles.staticsIconContainer}>
              <p className={styles.staticsDesc}>Customers</p>
            </div>
            <div className={styles.staticsIconContainer}>
              <p className={styles.staticsDesc}>Sales Enquires</p>
            </div>
            <div className={styles.staticsIconContainer}>
              <p className={styles.staticsDesc}>Agents</p>
            </div>
            <div className={styles.staticsIconContainer}>
              <p className={styles.staticsDesc}>Conversation used</p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.welcomeCardsContainer}>
        {WelcomeCardData.map((item, index) => {
          return (
            <div
              className={styles.welcomeCards}
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              {hoveredIndex !== index ? (
                <>
                  <img alt="img" src={item.icon} />
                  <p className={styles.welcomeCardsTile}>{item.title}</p>
                </>
              ) : (
                <p className={styles.hoveredText}>{item.hoverText}</p>
              )}
              <img alt="img" src={item.bottom} className={styles.bottomImg} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Welcome;
