import { Toast } from "primereact/toast";
import React, { useState, useEffect, useContext, useRef } from "react";
import Loader from "../components/Loader";

const LayoutContext = React.createContext(null);
// Layout  Context
export function useLayout() {
  return useContext(LayoutContext);
}

export function LayoutProvider(props) {
  const [layout, setLayout] = useState({});
  const [loading, setLoading] = useState(false);
  const toast =useRef(null)
  const toastMessage=({severity= "success",detail=''})=>{
    toast.current?.show({ severity, detail:severity==="error"&&detail===''?'Something went wrong':detail });
    return; 
  }
  const value = {
    layout,
    setLayout,
    toast,
    loading, 
    setLoading,
    toastMessage
  };
  return (
    <LayoutContext.Provider value={value}>
      <Toast ref={toast}/>
      <Loader visible={loading}/>
      {props.children}
    </LayoutContext.Provider>
  );
}
