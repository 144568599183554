import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/axios.instance";
import { Dropdown } from "primereact/dropdown";
import { tempCategories } from "../../utils/templateUtils";
import { CustomButton } from "../CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { updateNodePayload } from "../../reducers/canvas/canvasSlice";
import { v4 as uuidv4 } from "uuid";
import StartNodeCheckMark from "../../view/Bots/components/start-node-checkmark";
import { classNames } from "primereact/utils";
import { Controller, useForm } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { data } from "jquery";

const TemplateNodeInput = ({ onCancel = () => {}, onSave = () => {} }) => {
  const [templateList, setTemplateList] = useState([]);
  const [selecteTemplate, setTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(null);
  const [message, setMessage] = useState();
  const { selectedNode } = useSelector((state) => state.canvas);

  const dispatch = useDispatch();
  const defaultValues = {};

  const fetchNextTemplates = async (category) => {
    try {
      var queryParams = `page=0&limit=100000&isActive=1`;
      if (category) queryParams += `&category=${category}`;
      setLoading(true);
      const { data } = await axiosInstance.get(
        `/whatsappTemplate/gupshup?${queryParams}`
      );
      setTemplateList(data.rows?.filter((item) => item.status == "APPROVED"));
      if (selectedNode && selectedNode.data.templateId) {
        const { variables } = selectedNode?.data;
        setTempvariables(variables);
        let findSelectedTemplate = data?.rows.find(
          (item) => item.templateId == selectedNode.data.templateId
        );
        if (findSelectedTemplate) {
          const updatedVars = findSelectedTemplate?.variables?.map((item) => {
            return {
              ...item,
              variableValue: variables[item.variableName] || item.variableValue,
            };
          });
          setVariables([...updatedVars]);
          setTemplate(findSelectedTemplate);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleTemplateSelect = (templateData) => {
    setTemplate(templateData);

    let templateNodeData = {
      templateId: null,
      templateName: null,
      options: [],
      variables: [],
      content: {
        header: "",
        data: "",
      },
    };

    if (templateData) {
      const { templateId, containerMeta, elementName, variables } =
        templateData;
      const { data, buttons, header } = JSON.parse(containerMeta);

      templateNodeData = {
        ...templateNodeData,
        templateId: templateId,
        content: {
          ...templateNodeData.content,
          header,
          data,
        },
        options: buttons?.map((item) => {
          return {
            id: uuidv4(),
            title: item.text || "",
            type: "text",
            postbackText: "",
          };
        }),
        variables: variables?.map((item) => {
          return {
            [item.variableName]: item.variableValue,
          };
        }),
        templateName: elementName,
      };

      let tempVariable = {};
      templateData?.variables?.map((x) => {
        tempVariable[x.variableName] = x.variableValue;
        setValue(x.variableName, x.variableValue, { shouldValidate: true });
      });
      setValue("message", data, { shouldValidate: true });
      setMessage(data);
      setTempvariables({ ...tempVariable });
      setVariables([...variables]);
    }

    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { ...templateNodeData },
      })
    );
  };

  useEffect(() => {
    fetchNextTemplates(category);
  }, [category]);

  const {
    control,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues });

  const [tempVariables, setTempvariables] = useState({});
  const [variables, setVariables] = useState([]);
  const handleEditVariable = (e, variableName) => {
    let currVariableData = { ...tempVariables };
    currVariableData[variableName] = `${e.target.value}`;
    setTempvariables({ ...currVariableData });
  };

  useEffect(() => {
    if (selecteTemplate?.containerMeta) {
      const { data } = JSON.parse(selecteTemplate.containerMeta);
      setValue("message", data, { shouldValidate: true });
      setMessage(data);
    }

    //cleanup function
    return () => {
      setTempvariables({});
    };
  }, [selecteTemplate]);

  useEffect(() => {
    if (selectedNode) {
      dispatch(
        updateNodePayload({
          selectedNodeId: selectedNode?.id,
          updatedData: { variables: { ...tempVariables } },
        })
      );
    }
  }, [tempVariables]);

  function templateMsg() {
    return (
      <form className="p-fluid w-full" encType="multipart/form-data">
        <div className="field mt-2 w-full">
          <label htmlFor="message">Body *</label>
          <Controller
            name="message"
            control={control}
            rules={{ required: "message is required." }}
            render={({ field, fieldState }) => (
              <InputTextarea
                className={classNames({
                  "p-invalid": fieldState.invalid,
                })}
                id={field.name}
                value={message}
                rows={5}
                cols={30}
                maxLength={1028}
                // autoResize
                disabled
                placeholder="Enter your message (max 1028 chars)"
              />
            )}
          />

          <div className="my-2">
            {variables?.map((item, i) => {
              return (
                <div
                  className="flex gap-2 justify-content-center align-items-center my-3"
                  key={i}
                >
                  <div className="w-3">
                    {i == 0 && <label>Variable</label>}
                    <InputText value={item.variableName} disabled />
                  </div>
                  <div className="relative flex-grow-1">
                    {i == 0 && <label>Value</label>}
                    <div className="flex align-items-center gap-2">
                      <Controller
                        rules={{ required: true }}
                        id={item.variableName}
                        name={item.variableName}
                        control={control}
                        render={({ field }) => (
                          <InputText
                            id={field.name}
                            name={field.name}
                            value={tempVariables[item.variableName]}
                            placeholder={`Enter value`}
                            onChange={(e) => {
                              handleEditVariable(e, item.variableName);
                              field.onChange(e.target.value);
                            }}
                            required
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </form>
    );
  }

  const handleOptionDisable = () => {
    let isDisbale = false;
    const { options } = selectedNode.data;
    if (options && options.length != 0) {
      for (const op of options) {
        if (op.postbackText != "") {
          isDisbale = true;
        }
      }
    }

    return isDisbale;
  };

  return (
    <>
      <div className="m-2">
        <Dropdown
          value={category}
          onChange={(e) => setCategory(e.value)}
          options={tempCategories}
          optionLabel="key"
          showClear
          placeholder="Select Category"
          className="w-full mb-2"
        />
        <Dropdown
          value={selecteTemplate}
          options={templateList}
          onChange={(e) => handleTemplateSelect(e.value)}
          optionLabel="elementName"
          placeholder="Choose Template"
          className="w-full capitalize"
          loading={loading}
          filter
          disabled={handleOptionDisable()}
        />
        {selecteTemplate && templateMsg()}
      </div>

      <StartNodeCheckMark />
      <div className=" mt-6 mb-3 flex justify-content-end gap-3 align-items-center">
        <CustomButton varient="outline" label={"Cancel"} onClick={onCancel} />
        <CustomButton varient="filled" label={"Save"} onClick={onSave} />
      </div>
    </>
  );
};

export default TemplateNodeInput;
