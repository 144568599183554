import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "../style.module.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axiosInstance from "../../../api/axios.instance";
import { addOneDay, getDate } from "../../../utils/datemaker";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import { Dropdown } from "primereact/dropdown";
import DebounceSearch from "../../../components/common/DebounceSearch";
import { Calendar } from "primereact/calendar";
import {
  getResponseTIme,
  getTicketsOverall,
} from "../../../reducers/analyticSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLayout } from "../../../context/LayoutContext";
import { emptyContent } from "../../../utils/tableUtils";
import { capitalizeFLetter, formatMobNumber } from "../../../utils/commonUtils";
import CustomSkeleton from "../../../components/CustomSkeleton";
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function TicketsSections() {
  const options = {
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      legend: false,
    },
    scales: {
      y: {
        stacked: true,
        ticks: {
          crossAlign: "far",
        },
      },
      x: {
        stacked: true,
        barPercentage: 0.4,
        grid: {
          display: false,
        },
      },
    },
  };
  const [ticketDetails, setTicketDetails] = useState({
    allticket: 0,
    progressticket: 0,
    followupticket: 0,
    closedticket: 0,
    newticket: 0,
  });
  const {
    analyticalDetails,
    ticketsOverall,
    loading,
    dateRange,
    responseTime,
    skeletonloading,
  } = useSelector((state) => state.analytic);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("all");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    let allticket = analyticalDetails?.ticket?.statistics?.allTicketsCount | 0;
    let progressticket =
      analyticalDetails?.ticket?.statistics?.progressTicketsCount | 0;
    let followupticket =
      analyticalDetails?.ticket?.statistics?.followUpTicketsCount | 0;
    let closedticket =
      analyticalDetails?.ticket?.statistics?.closedTicketsCount | 0;
    let newticket = analyticalDetails?.ticket?.statistics?.newTicketsCount | 0;

    dispatch(getResponseTIme({ dateRange }));
    setTicketDetails({
      allticket,
      progressticket,
      followupticket,
      closedticket,
      newticket,
    });
  }, [dateRange]);
  const { setLoading } = useLayout(null);
  const [unit, setUnit] = useState("No Data");
  const [needleValue, setNeedleValue] = useState({ unit: 0, meter: 0 });
  useEffect(() => {
    setLoading(loading);
  }, [loading]);
  useEffect(() => {
    let filterdetail = [{ key: "ticketStatus", value: filter }];
    dispatch(
      getTicketsOverall({
        page: 0,
        limit: 10,
        dateRange,
        filter: filterdetail,
        globalFilterValue: inputValue,
      })
    );
  }, [dateRange, filter, inputValue]);

  const labels = ["In Progress", "Follow Up", "Closed", "New"];
  const data = {
    labels,
    title: { display: true, text: "testTitle", position: "left" },
    datasets: [
      {
        data: [
          analyticalDetails?.ticket?.statistics?.progressTicketsCount | 0,
          analyticalDetails?.ticket?.statistics?.followUpTicketsCount | 0,
          analyticalDetails?.ticket?.statistics?.closedTicketsCount | 0,
          analyticalDetails?.ticket?.statistics?.newTicketsCount | 0,
        ],
        backgroundColor: ["#30B3D580"],
        borderColor: ["#30B3D5"],
        borderWidth: {
          top: 5,
          right: 0,
          bottom: 0,
          left: 0,
        },
        barPercentage: 0.2,
      },
      // {
      //   data: [
      //     analyticalDetails?.ticket?.statistics?.progressTicketsCount | 0,
      //     analyticalDetails?.ticket?.statistics?.followUpTicketsCount | 0,
      //     analyticalDetails?.ticket?.statistics?.closedTicketsCount | 0,
      //     analyticalDetails?.ticket?.statistics?.newTicketsCount | 0,
      //   ],

      //   // backgroundColor: ["#EEDDB0"],
      //   // borderColor: ["#EEDDB0"],
      //   borderWidth: {
      //     top: 5,
      //     right: 0,
      //     bottom: 0,
      //     left: 0,
      //   },
      //   barPercentage: 0.5,
      // },
      // {
      //   data: [
      //     analyticalDetails?.ticket?.statistics?.progressTicketsCount | 0,
      //     analyticalDetails?.ticket?.statistics?.followUpTicketsCount | 0,
      //     analyticalDetails?.ticket?.statistics?.closedTicketsCount | 0,
      //     analyticalDetails?.ticket?.statistics?.newTicketsCount | 0,
      //   ],
      //   backgroundColor: ["#C9DEE9"],
      //   borderColor: ["#C9DEE9"],
      //   borderWidth: {
      //     top: 5,
      //     right: 0,
      //     bottom: 0,
      //     left: 0,
      //   },
      //   barPercentage: 0.5,
      // },
      // {
      //   data: [
      //     analyticalDetails?.ticket?.statistics?.progressTicketsCount | 0,
      //     analyticalDetails?.ticket?.statistics?.followUpTicketsCount | 0,
      //     analyticalDetails?.ticket?.statistics?.closedTicketsCount | 0,
      //     analyticalDetails?.ticket?.statistics?.newTicketsCount | 0,
      //   ],
      //   backgroundColor: ["#F2D5E3"],
      //   borderColor: ["#F2D5E3"],
      //   borderWidth: {
      //     top: 5,
      //     right: 0,
      //     bottom: 0,
      //     left: 0,
      //   },
      //   barPercentage: 0.5,
      // },
      // {
      //   data: [
      //     analyticalDetails?.ticket?.statistics?.progressTicketsCount | 0,
      //     analyticalDetails?.ticket?.statistics?.followUpTicketsCount | 0,
      //     analyticalDetails?.ticket?.statistics?.closedTicketsCount | 0,
      //     analyticalDetails?.ticket?.statistics?.newTicketsCount | 0,
      //   ],
      //   backgroundColor: ["#D1E0C1"],
      //   borderColor: ["#D1E0C1"],
      //   borderWidth: {
      //     top: 5,
      //     right: 0,
      //     bottom: 0,
      //     left: 0,
      //   },
      //   barPercentage: 0.5,
      // },
    ],
  };
  const statusOptions = [
    { key: "All Tickets", value: "all" },
    { key: "New", value: "new" },
    { key: "Follow up", value: "Follow Up" },
    { key: "In Progress", value: "In Progress" },
    { key: "Closed", value: "Closed" },
  ];
  let donutdata = {
    labels: false,
    datasets: [
      {
        data: [33.3, 33.3, 33.3],
        backgroundColor: ["#D1E0C1", "#EEDDB0", "#F0C6B7"],
        borderColor: "#000",
        borderWidth: 1,
        circumference: 245,
        rotation: 239,
        cutout: "50%",
        needleValue: needleValue?.meter | 0,
      },
    ],
  };

  useEffect(() => {
    if (responseTime?.averageResponseTime?.days !== 0) {
      let meter = 68 + (responseTime?.averageResponseTime?.days / 7) * 33;
      setNeedleValue({ unit: responseTime?.averageResponseTime?.days, meter });
      setUnit("Day");
    } else if (responseTime?.averageResponseTime?.hours !== 0) {
      let meter = 34 + (responseTime?.averageResponseTime?.hours / 24) * 33;
      setNeedleValue({ unit: responseTime?.averageResponseTime?.hours, meter });
      setUnit("Hours");
    } else if (responseTime?.averageResponseTime?.minutes !== "0") {
      let meter = (responseTime?.averageResponseTime?.minutes / 60) * 33;
      setNeedleValue({
        unit: responseTime?.averageResponseTime?.minutes,
        meter,
      });
      setUnit("Minutes");
    } else {
      setNeedleValue({ unit: 0, meter: 0 });
      setUnit("No Data");
    }
  }, [responseTime, dateRange]);
  const gaugeNeddle = {
    id: "gaugeNeddle",
    afterDatasetsDraw(chart) {
      const { ctx, data } = chart;
      ctx.save();
      let needleValued = data?.datasets[0]?.needleValue;
      needleValued = Math.min(100, Math.max(1, needleValued));
      const xCenter = chart.getDatasetMeta(0).data[0].x;
      const yCenter = chart.getDatasetMeta(0).data[0].y;
      const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius - 20;
      const angle = Math.PI;
      let circumference =
        (chart.getDatasetMeta(0).data[0].circumference /
          Math.PI /
          data.datasets[0].data[0]) *
        needleValued;
      const needleValueAngle = circumference + 1.32;
      ctx.translate(xCenter, yCenter);
      ctx.rotate(angle * needleValueAngle);

      //neddle
      ctx.beginPath();
      ctx.strokeStyle = "#434348";
      ctx.fillStyle = "#434348";
      ctx.moveTo(-3, 0);
      ctx.lineWidth = 5;
      ctx.lineTo(0, -outerRadius);
      ctx.lineTo(+3, 0);
      ctx.stroke();
      ctx.fill();

      // dot
      ctx.beginPath();
      ctx.fillStyle = "#434348";
      ctx.arc(0, 0, 10, 0, 2 * angle);
      ctx.lineWidth = 10;
      ctx.strokeStyle = "#fff";
      ctx.fill();
      ctx.restore();

      ctx.beginPath();
      ctx.fillStyle = "#fff";
      ctx.strokeStyle = "#fff";
      ctx.arc(xCenter, yCenter, 5, 0, 2 * angle);
      ctx.fill();
      ctx.restore();
    },
  };
  const donutOption = {
    aspectRation: 1.8,
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  };
  return (
    <div
      className={`w-full cp-10 flex flex-column justify-content-between align-items-start  w-12 ${styles["record-wrap-stat"]}`}
    >
      <div className={` ${styles["record-wrap-stat"]} flex  w-full`}>
        <div className="w-full gap-3">
          <div className="w-full  flex flex-column md:flex-row gap-2 mb-2">
            <div
              className={`w-full md:w-8 flex flex-column ${styles["dashboard-tablecontent"]} border-round-sm p-2`}
            >
              <div
                className={`w-full flex flex-column bg-white border-round-sm p-2`}
              >
                <div className="p-2 pl-3 flex flex-column justify-content-start ">
                  <div className="text-lg font-medium">Overview</div>
                  <div className="text-sm font-normal">
                    Total Tickets{" "}
                    {analyticalDetails?.ticket?.statistics?.allTicketsCount}
                  </div>
                </div>
                {skeletonloading?.ticket ? (
                  <div>
                    <CustomSkeleton
                      parentcss={"flex flex-row gap-4"}
                      countline={1}
                      height={"367px"}
                      className="border-round w-full"
                    />
                  </div>
                ) : (
                  <div
                    className="w-12 p-2 px-3 m-auto "
                    style={{ maxHeight: "400px" }}
                  >
                    <Bar options={options} data={data} />
                  </div>
                )}
              </div>
            </div>
            <div
              className={`w-full md:w-4  flex flex-column ${styles["dashboard-tablecontent"]} border-round-sm p-2`}
            >
              <div
                className={`w-full flex flex-column bg-white border-round-sm p-2`}
              >
                <div className="p-2 pl-3 flex flex-column justify-content-start ">
                  <div className="w-12 flex pl-2  m-auto text-lg font-normal">
                    Response Time
                  </div>
                </div>
                {skeletonloading?.responseTime ? (
                  <div>
                    <CustomSkeleton
                      parentcss={"flex flex-row gap-4"}
                      countline={1}
                      height={"367px"}
                      className="border-round w-full"
                    />
                  </div>
                ) : (
                  <div
                    className="w-12 flex flex-column p-2 px-3 m-auto "
                    style={{ maxHeight: "50vh" }}
                  >
                    {needleValue ? (
                      <div className="w-8 lg:w-6 m-auto">
                        <Doughnut
                          data={donutdata}
                          options={donutOption}
                          plugins={[gaugeNeddle]}
                        />
                      </div>
                    ) : (
                      <div className="w-8 h-5rem"></div>
                    )}
                    <div className="flex justify-content-center font-semibold text-lg capitalize ">
                      {needleValue?.unit !== undefined && unit !== "No Data"
                        ? `${needleValue?.unit} ${unit}`
                        : "-"}{" "}
                    </div>
                    {needleValue && unit ? (
                      <div className="w-12  lg:w-8 align-items-center m-auto mt-2  flex flex-column pl-4 py-2 gap-1 ">
                        <div className="flex w-full flex-row font-normal justify-content-between">
                          <div
                            className={`${styles["orderDot"]}`}
                            style={{ background: "#018E42" }}
                          ></div>
                          &nbsp;&nbsp;Good&nbsp;
                        </div>
                        <div className="flex w-full flex-row font-normal justify-content-between">
                          <div
                            className={`${styles["orderDot"]}`}
                            style={{ background: "#F1D302" }}
                          ></div>
                          &nbsp;&nbsp;Average&nbsp;
                        </div>
                        <div className="flex w-full flex-row font-normal justify-content-between">
                          <div
                            className={`${styles["orderDot"]}`}
                            style={{ background: "#e61e50" }}
                          ></div>
                          &nbsp;&nbsp;Delayed&nbsp;
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className={`w-full flex flex-column mt-2 ${styles["dashboard-tablecontent"]} border-round-sm `}
          >
            <div className={`w-full justify-content-start pt-2`}>
              <div
                className={`w-full flex flex-row ${styles["enquries-filter"]} px-2`}
              >
                <div
                  className={` cursor-pointer text-lg mr-2 p-2 ${styles["overall-title"]}`}
                >
                  Overall Tickets
                </div>
              </div>
            </div>
            <div>
              <div className="w-full flex flex-column lg:flex-row justify-content-between p-2">
                <div className="w-full flex flex-row gap-2 align-items-center p-2">
                  <div className="">
                    <Dropdown
                      value={filter}
                      onChange={(e) => {
                        setFilter(e.value);
                      }}
                      options={statusOptions}
                      optionLabel="key"
                      placeholder="status"
                      className="capitalize w-full"
                    />
                  </div>
                </div>
                <div className=" flex flex-row align-items-center p-2 ">
                  <DebounceSearch
                    placeholder={"Search Ticket"}
                    inputValue={inputValue}
                    handleInputChange={(value) => setInputValue(value)}
                  />
                </div>
              </div>
            </div>
            <div className="p-2">
              {skeletonloading?.agenttop ? (
                <div className="flex flex-column gap-4">
                  <CustomSkeleton
                    parentcss={"flex flex-row gap-4"}
                    countline={4}
                    height={"1rem"}
                    className="border-round w-3"
                  />
                  <CustomSkeleton
                    parentcss={"flex flex-row gap-4"}
                    countline={4}
                    height={"1rem"}
                    className="border-round w-3"
                  />
                  <CustomSkeleton
                    parentcss={"flex flex-row gap-4"}
                    countline={4}
                    height={"1rem"}
                    className="border-round w-3"
                  />
                  <CustomSkeleton
                    parentcss={"flex flex-row gap-4"}
                    countline={4}
                    height={"1rem"}
                    className="border-round w-3"
                  />
                </div>
              ) : (
                <DataTable
                  className={`${styles["dashboard-table"]} w-full`}
                  value={ticketsOverall}
                  stripedRows
                  responsiveLayout="scroll"
                  resizableColumns
                  columnResizeMode="expand"
                  emptyMessage="No data found."
                >
                  <Column
                    field="ticketCode"
                    header={"Ticket Id"}
                    bodyStyle={{ textTransform: "capitalize" }}
                    style={{ width: "10ch" }}
                    className="text-sm"
                  />
                  <Column
                    field="customerName"
                    header={"Customer"}
                    bodyStyle={{ textTransform: "capitalize" }}
                    body={(rowData) => emptyContent(rowData?.customerName)}
                  />
                  <Column
                    field="customerMobile"
                    header={"Phone"}
                    style={{ width: "20ch" }}
                    body={(rowData) => formatMobNumber(rowData.customerMobile)}
                  />
                  <Column
                    field="ticketStatus"
                    header={"Status"}
                    style={{ width: "20ch" }}
                    bodyStyle={{ textTransform: "capitalize" }}
                    className="text-sm"
                  />
                  <Column
                    field="agentName"
                    header={"Assignee"}
                    bodyStyle={{ textTransform: "capitalize" }}
                    body={(rowData) => emptyContent(rowData?.agentName)}
                  />
                  {/* <Column field="priority" header={"Priority"} bodyStyle={{ textTransform: "capitalize" }}/> */}

                  <Column
                    field="createdAt"
                    header="Date & Time"
                    className="text-sm"
                    body={(rowData) =>
                      rowData.createdAt && getDate(rowData.createdAt)
                    }
                    bodyStyle={{
                      textTransform: "uppercase",
                      lineHeitght: "1rem",
                    }}
                    style={{ width: "20ch" }}
                  />
                </DataTable>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketsSections;
