import { useState } from "react";
import CustomDialog from "../../components/CustomDialog";
import { useNavigate } from "react-router-dom";
import styles from "./promotionModel.module.css"
import { ReactComponent as CancelButton } from "../../svg/cross.svg";

function PromotionModel({showModel=false,setShowModel=()=>{}}) {
    const navigate=useNavigate()
    const onContinue=()=>{
        navigate("subscription");
    }
    console.log(showModel)
  return (
    <div>
      <CustomDialog
        visible={showModel}
        header={()=>{<div></div>}}
        className=""
        onHide={() => setShowModel(false)}
        prevCss={true}
        style={{}}
        showHeader={false}
      >
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.heading}>
              <h1 className={styles.congratulations}>CONGRATULATIONS</h1>
              <p className={styles.subtitle}>
                <b>
                  You're One Step Away from
                  <br /> Smarter Marketing.
                </b>
              </p>
            </div>

            <button className={styles.signupButton} onClick={onContinue}>Choose Plan</button>
            <div className={styles.features}>
              <ul className={styles.featuresList}>
                <li className={styles.featuresListLi}>
                  <div className={styles.f2}> Enjoy a 50% Discount</div>
                  <div className={styles.f2Content}>
                    Get your first quarter subscription at half the price!
                  </div>
                </li>
                <li className={styles.featuresListLi}>
                  <div className={styles.f2}> Pay-as-You-Go Flexibility</div>
                  <div className={styles.f2Content}>
                    Conversation and user licenses will be billed monthly based
                    on actual usage.
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.crossBtn} onClick={() => setShowModel(false)} ><CancelButton width={"16px"} height={"16px"}/></div>
        </div>
      </CustomDialog>
    </div>
  );
}

export default PromotionModel;
