import { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ConversationAlert } from "../ConversationAlert";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import "./index.css";
import CustomDialog from "../CustomDialog";
import { Text } from "../Text";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { getIds, getSKUCodes } from "../../utils/shareUtils";
import { changeShowGroupShareDialog } from "../../reducers/appSlice";
import { CustomImageInput } from "../CustomImageInput";
import { Toast } from "primereact/toast";
import { RadioButton } from "primereact/radiobutton";
import { getCampaignTemplates, setrerunCampaign } from "../../reducers/campaignSlice";
import { SelectableCustomers } from "../SelectableCustomers";
import { ReactComponent as ImagePlaceholder } from "../../svg/ImgPlaceholder.svg";
import { changeSelectedProduct, changeSelectedProductsList, resetAll, updateSelectedProductsList } from "../../reducers/productTableSlice";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from 'primereact/multiselect';
import { Accordion, AccordionTab } from 'primereact/accordion';

import SelectionAnalytics from "../SelectionAnalytics";

function GroupShare({ label, tempType = "" }) {
  const [shareAlert, setShareAlert] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [campaignData, setCampaignData] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectMediaType, setSelectmediaType] = useState(tempType);
  const [selectedGroupId, setSelectedGroupId] = useState("all");

  const { selectedProductsList } = useSelector((state) => state.productTable);
  const { showGroupShareDialog } = useSelector((state) => state.application);
  const { campaignTemplates,rerunCamp, campaignLoading, campaignType,rselectedCampaign,campaignCustomers,selectedCampaign,selectedCampaignId} = useSelector(
    (state) => state.campaign
  );
  const [rerun,setRerun]=useState(false)
  const toast = useRef(null);
  const [edit,setEdit]=useState(false)
  const [schedule,setSchedule]=useState(false)

  const defaultValues = {
    campaignName: "",
    message: "",
  };

  const defaultScheduleValues={
    startDate:'',
    endDate:'',
    dayOfWeek:[],
    runTime:[],
  }
  const {
    control: control_S,
    formState: { errors :error_S ,isValid},
    handleSubmit : handleSubmit_S,
    reset : reset_S,
    setValue: setValue_S,
    getValues:getValues_S,
  } = useForm(defaultScheduleValues);

  useEffect(()=>{
    if(rerunCamp===true){
      setRerun(true)
      setEdit(true)
      dispatch(changeSelectedProductsList(rselectedCampaign.productItems))
      setValue('campaignName',rselectedCampaign.campaignName)
      setValue('message',rselectedCampaign.message)
      setSelectedCustomers(campaignCustomers)
      if(rselectedCampaign?.groupIds)
      {
        setSelectedGroupId(rselectedCampaign.groupIds[0])
      }
    }
    if(selectedCampaignId!=='all'){
      setRerun(true)
      dispatch(changeSelectedProductsList(selectedCampaign.productItems))
      setValue('campaignName',selectedCampaign.campaignName)
      setValue('message',selectedCampaign.message)
      setSelectedCustomers(campaignCustomers)
      if(selectedCampaign?.groupIds)
      {
        setSelectedGroupId(selectedCampaign.groupIds[0])
      }

    }
    return ()=>{
      if(rerunCamp===true){
        dispatch(resetAll());
      }
      reset()
      setRerun(false)
      dispatch(setrerunCampaign(false))
      setSelectedCustomers([])
    }
    // console.log(selectedProductsList)
  },[])
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm(defaultValues);

  //function form get error message
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const dispatch = useDispatch();

  const errorToast = (errMsg) => {
    toast.current.show({
      severity: "error",
      detail: errMsg,
    });
  };

  const onSubmit = (data) => {
    if (
      campaignType === "broadcast" &&
      selectMediaType === "image" &&
      !selectedImage
    ) {
      errorToast("Please select an image.");
      return;
    } else if (campaignType == "product" && selectedProductsList?.length === 0) {
      errorToast("please select atleast one product.");
      return;
    } else {
      if (selectedCustomers?.length === 0) {
        errorToast("Please select atleast one customer.");
        return;
      }
      if (selectMediaType === "" && campaignType === "broadcast") {
        errorToast("Please select the Mediatype.");
        return;
      }
    }

    const getTemplateId = () => {
      for (let i = 0; i < campaignTemplates?.length; i++) {
        let templateType = campaignTemplates[i].templateType;
        if (templateType === selectMediaType) return campaignTemplates[i].id;
        if (selectMediaType === "" && templateType === campaignType)
          return campaignTemplates[i].id;
      }
    };

    const campaignData = {
      campaignName: data.campaignName,
      message: data.message.split("\n").join(" "),
      templateId: getTemplateId(),
      customerIds: selectedGroupId == "all" ? getIds(selectedCustomers) : [],
      campaignType: campaignType,
      groupIds: selectedGroupId !== "all" ? [selectedGroupId] : [],
      SKUCodes:
        selectedProductsList?.length !== 0
          ? getSKUCodes(selectedProductsList)
          : [],
      
    };
    
    if(schedule&&!isValid){  
      errorToast("Please Fill The Schedule Form Properly")
      return;
    }
    if(schedule){  
      campaignData["startDate"]=getValues_S("startDate");
      campaignData["endDate"]=getValues_S("endDate");
      campaignData["dayOfWeek"]=getValues_S("dayOfWeek");
      campaignData["runTime"]= getValues_S("runTime")
    }    
    setCampaignData(campaignData);
    setShareAlert(true);
  };
  const SharePop = () => {
    return (
      <ConversationAlert
        CampaignData={campaignData}
        selectedImage={selectedImage}
        Conversation={selectedCustomers}
        displayAlert={shareAlert}
        setDisplayAlert={setShareAlert}
        schedule={schedule}
        toast={toast}
      />
    );
  };
  const modalLoad = () => {
    return (
      <div>
        <Loader visible={campaignLoading} />
      </div>
    );
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <div
        className="flex justify-content-center"
        style={{ width: "120px", height: "80px" }}
      >
        <img
          src={`${rowData.url}?v=${rowData.updatedAt}`}
          onError={(e) => (e.target.src = "./images/ImgPlaceholder.svg")}
          style={{ maxWidth: "100%", height: "100%" }}
        />
      </div>
    );
  };
  const [scheduleModel,setScheduleModel]=useState(false)
  const handleRadioSelect = (e) => {
    setSelectmediaType(e.value);
    if (e.value !== "image") setSelectedImage(null);
  };

  const handleImg = (img) => {
    setSelectedImage(img);
  };
  const renderMediaPreview = () => {
    const imagePreview = () => {
      return (
        <div className="w-full mt-3" style={{ maxHeight: "240px" }}>
          {selectedImage ? (
            <img
              src={`${URL.createObjectURL(selectedImage)}`}
              onError={(e) => (e.target.src = "./images/ImgPlaceholder.svg")}
              style={{
                maxWidth: "100%",
                maxHeight: "inherit",
                objectFit: "contain",
              }}
            />
          ) : (
            <ImagePlaceholder />
          )}
        </div>
      );
    };
    switch (selectMediaType) {
      case "image":
        return imagePreview();
      case "video":
        return;
      default:
        return;
    }
  };
  const handleKeyDown = (e) => {
    // prevent new lines
    if (e.keyCode === 13) {
      e.preventDefault();
    }
    // prevent tabs
    if (e.keyCode === 9) {
      e.preventDefault();
    }
    // prevent 4 consecutive spaces
    if (e.keyCode === 32 && e.target.value.slice(-3) === "   ") {
      e.preventDefault();
    }
  };
  const handleCloseDialog = () => {
    dispatch(changeShowGroupShareDialog(false));
  };

  useEffect(() => {
    dispatch(getCampaignTemplates());
    return ()=>{
      setEdit(false)
    }
  }, []);
  const saveForm=(e)=>{
    // console.log('sss')
    e.preventDefault();
    // setEdit(x=>!x)
    
    if(selectedCustomers.length===0){ 
     e.stopPropagation();
    }else{
      setEdit(x=>!x)
    }
    if(selectedCustomers.length===0&&edit===true)  
      {setEdit(x=>!x)}
  }
  const schedular=(e)=>{
      e.preventDefault();
      setScheduleModel(true)
   }

  const saveSchedular=(e)=>{
    // console.log(e)
    setSchedule(true)
    setScheduleModel(false)
  }
  const days=[
              {name:"Sunday",value:0},
              {name:"Monday",value:1},
              {name:"Tuesday",value:2},
              {name:"Wednesday",value:3},
              {name:"Thursday",value:4},
              {name:"Friday",value:5},
              {name:"Saturday",value:6}
            ];
  const time=[
    {name:"00:00 AM",value:0},
    {name:"01:00 AM",value:1},
    {name:"02:00 AM",value:2},
    {name:"03:00 AM",value:3},
    {name:"04:00 AM",value:4},
    {name:"05:00 AM",value:5},
    {name:"06:00 AM",value:6},
    {name:"07:00 AM",value:7},
    {name:"08:00 AM",value:8},
    {name:"09:00 AM",value:9},
    {name:"10:00 AM",value:10},
    {name:"11:00 AM",value:11},
    {name:"12:00 PM",value:12},
    {name:"01:00 PM",value:13},
    {name:"02:00 PM",value:14},
    {name:"03:00 PM",value:15},
    {name:"04:00 PM",value:16},
    {name:"05:00 PM",value:17},
    {name:"06:00 PM",value:18},
    {name:"07:00 PM",value:19},
    {name:"08:00 PM",value:20},
    {name:"09:00 PM",value:21},
    {name:"10:00 PM",value:22},
    {name:"11:00 PM",value:23},
  ];
  const dialogHeader = () =>{
    return <div className="flex flex-row align-items-center ">
              <Text type={'heading'}><span style={{textDecorationLine:"underline", textDecorationStyle:"dashed"}}>{label} </span></Text>
              <Text>:</Text>
              <Text cust={`cursor-pointer ml-2 p-2 px-3 border-round-xs  `}   type={'heading'}  ><span onClick={saveForm} style={{textDecorationLine:"underline" }} >  {edit?`edit`:`preview`}</span></Text>
           </div>
  }

  return (
    <CustomDialog
      header={dialogHeader}
      visible={showGroupShareDialog}
      onHide={handleCloseDialog}
      style={{maxHeight:"90vh"}}
    >
      <Toast ref={toast} />
      {SharePop()}
      {modalLoad()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomDialog
          header={'Schedule'}
          visible={scheduleModel}
          onHide={setScheduleModel}
          className="c-pop-schedule h-auto"
          defaultCss={false}
        >
        <div>
            <div className="card">
                  <div className="field">
                    <label htmlFor="startDate" >Start Date *</label>
                    <Controller
                      name="startDate"
                      control={control_S}
                      rules={{ required: "Please enter startDate." }}
                      render={({ field, fieldState }) => (
                        <Calendar
                        id={field.name}
                        showIcon={true}
                        placeholder="dd/mm/yyyy"
                        dateFormat="dd/mm/yy"
                        className={`sklbt-calender w-full `}
                        value={field.value}
                        minDate={new Date()}
                        {...field}
                        />
                      )}
                    />
                    {getFormErrorMessage("startDate")}
                  </div>
            </div>
            <div className="card">
                  <div className="field">
                    <label htmlFor="endDate" >End Date *</label>
                    <Controller
                      name="endDate"
                      control={control_S}
                      rules={{ required: "Please enter endDate." }}
                      render={({ field, fieldState }) => (
                        <Calendar
                        id={field.name}
                        showIcon={true}
                        placeholder="dd/mm/yyyy"
                        dateFormat="dd/mm/yy"
                        className={`sklbt-calender w-full `}
                        value={field.value}
                        minDate={new Date()}
                        {...field}
                        />
                      )}
                    />
                    {getFormErrorMessage("endDate")}
                  </div>
            </div>
            <div className="card">
                  <div className="field">
                    <label htmlFor="dayOfWeek" >Day Of Week*</label>
                    <Controller
                      name="dayOfWeek"
                      control={control_S}
                      rules={{ required: "Please enter Day Of Weeks." }}
                      render={({ field, fieldState }) => (
                        <MultiSelect 
                          id={field.name}
                          value={field.value} 
                          options={days} 
                          optionLabel="name" 
                          display="chip" 
                          placeholder="Select Days" 
                          maxSelectedLabels={7} 
                          className="w-full"
                          {...field} 
                          // onChange={field.onChange}
                          />
                      )}
                    />
                    {getFormErrorMessage("dayOfWeek")}
                  </div>
            </div>
            <div className="card">
                  <div className="field">
                    <label htmlFor="runTime" >Run Time</label>
                    <Controller
                      name="runTime"
                      control={control_S}
                      rules={{ required: "Please enter Day Of Weeks." }}
                      render={({ field, fieldState }) => (
                        <MultiSelect 
                          id={field.name}
                          value={field.value} 
                          options={time} 
                          optionLabel="name" 
                          display="chip" 
                          placeholder="Select Time" 
                          maxSelectedLabels={7} 
                          className="w-full"
                          {...field} 
                          />
                      )}
                    />
                    {getFormErrorMessage("runTime")}
                  </div>
            </div>
            <div className=" flex w-12 align-items-center justify-content-center">
                  <Button
                    varient="filled"
                    onClick={handleSubmit_S(saveSchedular)}
                    label={`🕛`}
                    className="skalebot-button flex w-12 mt-2 text-3xl "
                    style={{ background: "#005068", border: "none" }}
                  />
            </div>
            <div className=" flex w-12 align-items-center justify-content-center">
                  <Button
                    varient="filled"
                    onClick={()=>{reset_S();setSchedule(false);setScheduleModel(false);}}
                    label={`Cancel`}
                    className="skalebot-button flex w-12 mt-2 "
                    style={{ background: "#005068", border: "none" }}
                  />
            </div>
        </div>
        </CustomDialog>
        <div className="grid">
          <div className="col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12 relative overflow-y-auto" style={{ maxHeight: "700px" }} >
            <div>
              <div className="card mb-3">
                <SelectionAnalytics
                  items={[
                    { label: "Conversations", count: selectedCustomers.length },
                    campaignType === "product" && {
                      label: "Products",
                      count: selectedProductsList.length,
                    },
                  ]}
                />
                {/* <div
                  className="flex card-container md:flex-row xl:flex-row lg:flex-row  justify-content-start border-1 border-round-sm border-dashed border-grey-500"
                  style={{ backgroundColor: "#1C738E1A" }}
                >
                  {campaignType === "product" && (
                    <>
                      <div className="flex-1 m-2 px-3 py-2 flex align-items-center">
                        <Text type={"heading"}>
                          <span style={{ fontSize: "1.6rem" }}>
                            {selectedProductsList?.length}
                          </span>
                        </Text>
                        <Text type={"heading"}>&nbsp;Products</Text>
                      </div>
                    </>
                  )}

                  <div className="flex-1 m-2 px-3 py-2 flex align-items-center">
                    <Text type={"heading"}>
                      <span style={{ fontSize: "1.6rem" }}>
                        {selectedCustomers?.length}
                      </span>
                    </Text>
                    <Text type={"heading"}>&nbsp;Conversations</Text>
                  </div>
                </div> */}
              </div>
              <div className="card">
                <div className="field">
                  <label htmlFor="campaignName">Campaign Name *</label>
                  <Controller
                    name="campaignName"
                    control={control}
                    rules={{ required: "Please enter campaign name." }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        maxLength={50}
                        className={`w-full ${classNames({
                          "p-invalid": fieldState.invalid,
                        })}`}
                        placeholder="Enter campaign name"
                        {...field}
                        disabled={edit}
                      />
                    )}
                  />
                  {getFormErrorMessage("campaignName")}
                </div>
              </div>
              <div className="card">
                <div className="field">
                  <label htmlFor="message">Message *</label>
                  <Controller
                    name="message"
                    control={control}
                    rules={{ required: "Please enter campaign message." }}
                    render={({ field, fieldState }) => (
                      <InputTextarea
                        id={field.name}
                        maxLength={900}
                        onKeyDown={handleKeyDown}
                        className={`w-full ${classNames({
                          "p-invalid": fieldState.invalid,
                        })}`}
                        placeholder="Type campaign message here.."
                        {...field}
                        disabled={edit}
                      />
                    )}
                  />
                  {getFormErrorMessage("message")}
                </div>
              </div>
              {campaignType === "product" ? (
                <div className="w-full">
                  <div className="mb-2">
                    <Text type={"heading"}>
                      Selected Products to Share [{selectedProductsList?.length}]
                    </Text>
                  </div>
                  <DataTable
                    value={selectedProductsList}
                    dataKey="id"
                    responsiveLayout="scroll"
                    className={`skalebot-table2 min-h-full border-round-left-xs`}
                    scrollable
                    scrollHeight="300px"
                  >
                    <Column
                      field="SKUCode"
                      header="SKU Id"
                      style={{ width: "20%" }}
                      sortable
                    />
                    <Column
                      field="productName"
                      header="Name"
                      style={{ width: "20%" }}
                      sortable
                    />
                    <Column
                      header="Image"
                      style={{ width: "30%" }}
                      body={imageBodyTemplate}
                    />
                  </DataTable>
                </div>
              ) : (
                <>
                  <div className="card">
                    <div className="field">
                      <div>Choose Image *</div>
                      <div className="flex mt-2">
                        <div className="flex align-items-center">
                          <RadioButton
                            inputId="text"
                            value={"text"}
                            onChange={handleRadioSelect}
                            checked={selectMediaType === "text"}
                          />
                          <label htmlFor="text" className="ml-1 mr-3">
                            Default ( without image )
                          </label>
                          <RadioButton
                            inputId="f5"
                            value={"image"}
                            checked={selectMediaType === "image"}
                            onChange={handleRadioSelect}
                            disabled={edit}
                          />
                          <label htmlFor="f5" className="ml-1 mr-3">
                            Image
                          </label>

                          {/* <RadioButton
                           inputId="f6"
                           value={"video"}
                           onChange={handleRadioSelect}
                           checked={selectMediaType === "video"}
                         />
                         <label htmlFor="f6" className="ml-1 mr-3">
                           Video
                         </label> */}
                        </div>
                      </div>
                    </div>
                    {selectMediaType === "image" ? (
                      <CustomImageInput
                        setSelectedImage={handleImg}
                        label={"Choose image"}
                      />
                    ) : selectMediaType === "video" ? (
                      <div className="field">
                        <label
                          htmlFor="medialink"
                          className={classNames({ "p-error": errors.name })}
                        >
                          Video Link *
                        </label>
                        <Controller
                          name="medialink"
                          control={control}
                          rules={{ required: "Please enter video link." }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.name}
                              className={`w-full ${classNames({
                                "p-invalid": fieldState.invalid,
                              })}`}
                              placeholder="Enter Video link"
                              {...field}
                              disabled={edit}
                            />
                          )}
                        />
                        {getFormErrorMessage("medialink")}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="card">{renderMediaPreview()}</div>
                </>
              )}
            </div>
            <div  className="mt-3">
                <div className="h-10" >
                    <div className="flex flex-row align-items-center">
                      <div className="flex flex-row gap-3" onClick={() => setSchedule(x=>!x)}>
                        <RadioButton   checked={schedule} />
                        <div>Advance Settings</div>
                      </div>
                        {schedule&&<div className="ml-2 font-semibold text-2xl mb-2" onClick={() => setSchedule(x=>!x)}>x</div>}
                    </div>
                  {schedule&&<div className="flex flex-wrap gap-2 w-11 m-auto justify-content-start">
                      <div className="card w-5 align-items-center ">
                            <div className="field">
                              <label htmlFor="startDate" >Start Date *</label>
                              <Controller
                                name="startDate"
                                control={control_S}
                                rules={{ required: "Please enter startDate." }}
                                render={({ field, fieldState }) => (
                                  <Calendar
                                  id={field.name}
                                  showIcon={true}
                                  placeholder="dd/mm/yyyy"
                                  dateFormat="dd/mm/yy"
                                  className={`sklbt-calender w-full `}
                                  value={field.value}
                                  minDate={new Date()}
                                  {...field}
                                  />
                                )}
                              />
                              {getFormErrorMessage("startDate")}
                            </div>
                      </div>
                      <div className="card w-5 align-items-center ">
                            <div className="field">
                              <label htmlFor="endDate" >End Date *</label>
                              <Controller
                                name="endDate"
                                control={control_S}
                                rules={{ required: "Please enter endDate." }}
                                render={({ field, fieldState }) => (
                                  <Calendar
                                  id={field.name}
                                  showIcon={true}
                                  placeholder="dd/mm/yyyy"
                                  dateFormat="dd/mm/yy"
                                  className={`sklbt-calender w-full `}
                                  value={field.value}
                                  minDate={new Date()}
                                  {...field}
                                  />
                                )}
                              />
                              {getFormErrorMessage("endDate")}
                            </div>
                      </div>
                      <div className="card w-5 align-items-center ">
                            <div className="field">
                              <label htmlFor="dayOfWeek" >Day Of Week*</label>
                              <Controller
                                name="dayOfWeek"
                                control={control_S}
                                rules={{ required: "Please enter Day Of Weeks." }}
                                render={({ field, fieldState }) => (
                                  <MultiSelect 
                                    id={field.name}
                                    value={field.value} 
                                    options={days} 
                                    optionLabel="name" 
                                    display="chip" 
                                    placeholder="Select Days" 
                                    maxSelectedLabels={7} 
                                    className="w-full"
                                    {...field} 
                                    // onChange={field.onChange}
                                    />
                                )}
                              />
                              {getFormErrorMessage("dayOfWeek")}
                            </div>
                      </div>
                      <div className="card w-5 align-items-center ">
                            <div className="field">
                              <label htmlFor="runTime" >Run Time</label>
                              <Controller
                                name="runTime"
                                control={control_S}
                                rules={{ required: "Please enter Day Of Weeks." }}
                                render={({ field, fieldState }) => (
                                  <MultiSelect 
                                    id={field.name}
                                    value={field.value} 
                                    options={time} 
                                    optionLabel="name" 
                                    display="chip" 
                                    placeholder="Select Time" 
                                    maxSelectedLabels={7} 
                                    className="w-full"
                                    {...field} 
                                    />
                                )}
                              />
                              {getFormErrorMessage("runTime")}
                            </div>
                      </div>
                      {/* <div className="flex w-11 m-auto align-items-center gap-2  ">
                        <div className=" flex w-6 align-items-center justify-content-center">
                              <Button
                                varient="filled"
                                onClick={handleSubmit_S(saveSchedular)}
                                label={`🕛`}
                                className="skalebot-button flex w-12 mt-2 text-3xl "
                                style={{ background: "#005068", border: "none" }}
                              />
                        </div>
                        <div className=" flex w-6 align-items-center justify-content-center">
                              <Button
                                varient="filled"
                                onClick={(e)=>{e.preventDefault(); reset_S();setSchedule(false);setScheduleModel(false);}}
                                label={`Cancel`}
                                className="skalebot-button flex w-12 mt-2 "
                                style={{ background: "#005068", border: "none" }}
                              />
                        </div>
                      </div> */}
                    </div>
                  }
                </div>
            </div>
          </div>
          <div className="col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
            <Text type={"heading"}>Customers | Groups</Text>
            <div className="card mt-1">
              <SelectableCustomers
                selectedCustomer={selectedCustomers}
                setSelectedCustomer={setSelectedCustomers}
                customersFrom="group"
                selectedGroupId={selectedGroupId}
                setSelectedGroupId={setSelectedGroupId}
                saved={edit}
              />
            </div>
            <div className="flex justify-content-around">
            {/* <div className=" flex w-5 align-items-center justify-content-center">
                <Button
                  varient="filled"
                  onClick={saveForm}
                  label={edit?`Edit`:`Save`}
                  className="skalebot-button flex w-12 mt-2 "
                  style={{ background: "#005068", border: "none" }}
                />
              </div> */}
              {/* <div className=" flex w-1 align-items-center justify-content-center">
                <Button
                  varient="filled"
                  onClick={schedular}
                  label={`🕛`}
                  disabled={edit}
                  className="skalebot-button flex w-12 mt-2 text-3xl"
                  style={{ background: `${schedule?`#238e1c`:`#005068`}`, border: "none" }}
                />
              </div> */}

              <div className=" flex w-12 align-items-center justify-content-center">
                <Button
                  varient="filled"
                  type="submit"
                  // disabled={!edit}
                  label={"Share with the selected members"}
                  className="skalebot-button flex w-12 mt-2 "
                  style={{ background: "#005068", border: "none" }}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </CustomDialog>
  );
}

export default GroupShare;
