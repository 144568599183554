import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Skeleton } from "primereact/skeleton";
import {
  getCampaignById,
  getCampaignCustomers,
  getCampaignsAnalytics,
  putCampaign,
  rchangeSelectedCampaign,
  resetCampaignStats,
  resetSelectedCampaign,
  setrerunCampaign,
} from "../../reducers/campaignSlice";
import { getDate } from "../../utils/datemaker";
import CustomerAnalyticsDataView from "../Campaigns/CustomerAnalyticsDataView";
import { useParams } from "react-router";
import { Text } from "../../components/Text";
import CampaignPieChart from "../../components/CampaignPieChart";
import { Tag } from "primereact/tag";
import "./style.css";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import Loader from "../../components/Loader";
import { Toast } from "primereact/toast";
import TableActionContainer from "../../components/CustomTable/TableActionsContainer";
import CustomTabMenu from "../../components/CustomTabmenu";
import { Controller, useForm } from "react-hook-form";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";
import { CustomButton } from "../../components/CustomButton";
import { changeShowGroupShareDialog } from "../../reducers/appSlice";
import { useNavigate } from "react-router";
import { shareBroadcast } from "../../reducers/ShareMessage/actions";
import CustomDialog from "../../components/CustomDialog";
import { ReactComponent as SaveIcon } from "../../assets/info.svg";
import { Image } from "primereact/image";
import { resetBroadcast } from "../../reducers/ShareMessage/messageSlice";
import { resetSelectedProductBlist } from "../../reducers/productTableSlice";
import CONSTANTS from "../../config/Constants";
import CustomSaveModel from "../../components/CustomeSaveModel";
import * as Messag from "../../config/ToastMessage";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";

const items = [
  { label: "Information", icon: "", value: "info" },
  { label: "Conversations", icon: "", value: "conversations" },
];
const CampaignDetails = () => {
  const [activeTab, setActiveTab] = useState("info");
  const [prodImages, setProdImages] = useState([]);
  const { selectedCampaign, campaignLoading, stats } = useSelector(
    (state) => state.campaign
  );
  const [activeform, setactiveform] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef(null);
  const successRate = ((stats.All - stats.failed) / stats.All).toFixed(1) * 100;
  const itemslist = [{ label: `Campaign #${id}` }];
  const home = { label: "Campaign", url: "/campaigns" };
  const getSuccesRateSeverity = (successRate) => {
    if (successRate < 30) {
      return "danger";
    } else if (successRate < 60) {
      return "warning";
    } else if (successRate >= 60) {
      return "success";
    } else {
      return null;
    }
  };
  const handleTabChange = (value) => {
    setActiveTab(value);
  };

  const defaultScheduleValues = {
    startDate: "",
    endDate: "",
    hold: false,
    dayOfWeek: [],
    runTime: [],
  };
  const {
    control,
    formState: { errors, isValid },
    handleSubmit: handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm(defaultScheduleValues);
  const [schedule, setSchedule] = useState(true);
  const { selectedCampaignId } = useSelector((state) => state.campaign);
  const { user, accesskey } = useSelector((state) => state.authenticate);
  const submit = (data) => {
    dispatch(
      putCampaign({
        id: selectedCampaign.id,
        configData: {
          ...selectedCampaign,
          ...data,
        },
        schedule: true,
      })
    )
      .unwrap()
      .then((resp) => {
        toast.current.show({
          severity: "success",
          detail: Messag.Saved_Camp_Detail_ToastSuccessMessage,
        });
      })
      .catch((e) => {
        console.log(e);
        toast.current.show({ severity: "error", detail: e.message });
      });
    setShowSave(false);
    setactiveform(false);
  };

  const scheduleForm = () => {
    const days = [
      { name: "Sunday", value: 0 },
      { name: "Monday", value: 1 },
      { name: "Tuesday", value: 2 },
      { name: "Wednesday", value: 3 },
      { name: "Thursday", value: 4 },
      { name: "Friday", value: 5 },
      { name: "Saturday", value: 6 },
    ];
    const time = [
      { name: "00:00 AM", value: 0 },
      { name: "01:00 AM", value: 1 },
      { name: "02:00 AM", value: 2 },
      { name: "03:00 AM", value: 3 },
      { name: "04:00 AM", value: 4 },
      { name: "05:00 AM", value: 5 },
      { name: "06:00 AM", value: 6 },
      { name: "07:00 AM", value: 7 },
      { name: "08:00 AM", value: 8 },
      { name: "09:00 AM", value: 9 },
      { name: "10:00 AM", value: 10 },
      { name: "11:00 AM", value: 11 },
      { name: "12:00 PM", value: 12 },
      { name: "01:00 PM", value: 13 },
      { name: "02:00 PM", value: 14 },
      { name: "03:00 PM", value: 15 },
      { name: "04:00 PM", value: 16 },
      { name: "05:00 PM", value: 17 },
      { name: "06:00 PM", value: 18 },
      { name: "07:00 PM", value: 19 },
      { name: "08:00 PM", value: 20 },
      { name: "09:00 PM", value: 21 },
      { name: "10:00 PM", value: 22 },
      { name: "11:00 PM", value: 23 },
    ];

    const getFormErrorMessage = (name) => {
      return (
        errors[name] && (
          <small className="p-error">{errors[name].message}</small>
        )
      );
    };
    return (
      <div className="flex flex-column flex-wrap  w-11 md:w-11 lg:w-8 xl:w-8 justify-content-start mt-3">
        <div className="pb-1">
          <Text type={"sub-heading"}>Campaign Status</Text>
        </div>
        <div className="h-10 w-8 mb-2 mt-1">
          {
            <Controller
              name="hold"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-row justify-content-between align-items-center gap-2">
                  <div className="flex flex-row gap-2" onClick={() => {}}>
                    <RadioButton
                      value={false}
                      checked={field.value === false}
                      onClick={() => {
                        setSchedule(false);
                        field.onChange(false);
                      }}
                      disabled={!activeform}
                    />
                    <div>Active</div>
                  </div>
                  <div className="flex flex-row gap-2" onClick={() => {}}>
                    <RadioButton
                      value={true}
                      checked={field.value === true}
                      onClick={() => {
                        setSchedule(true);
                        field.onChange(true);
                      }}
                      disabled={!activeform}
                    />
                    <div>Hold</div>
                  </div>
                </div>
              )}
            />
          }
        </div>

        <div className="card w-full pt-2 align-items-center flex flex-wrap">
          <div className="field w-full">
            <label htmlFor="dayOfWeek" className="flex-row flex gap-2">
              <Text type={"sub-heading"}>Day Schedule </Text>{" "}
              <span style={{ color: `#C6272D` }}>*</span>
            </label>
            <div className="w-full sm:pr-2">
              <Controller
                name="dayOfWeek"
                control={control}
                rules={{ required: "Please enter Day Of Weeks." }}
                render={({ field, fieldState }) => (
                  <MultiSelect
                    id={field.name}
                    value={field.value}
                    options={days}
                    optionLabel="name"
                    display="chip"
                    placeholder="Choose Day"
                    maxSelectedLabels={7}
                    className="w-full mt-1"
                    {...field}
                    disabled={!activeform}
                  />
                )}
              />
              {getFormErrorMessage("dayOfWeek")}
            </div>
          </div>
        </div>

        <div className="card w-full pt-2 align-items-center flex flex-wrap ">
          <label htmlFor="runTime w-full my-2" className="flex-row flex gap-2">
            <Text type={"sub-heading"}>Choose Time</Text>{" "}
            <span style={{ color: `#C6272D` }}>*</span>
          </label>
          <div className="field flex flex-row align-items-center w-12 ">
            {/* <label htmlFor="runTime" className="w-1 pr-1" >Time</label> */}
            <div className="w-12 pt-2 pr-2">
              <Controller
                name="runTime"
                control={control}
                rules={{ required: "Please enter Day Of Weeks." }}
                render={({ field, fieldState }) => (
                  <MultiSelect
                    id={field.name}
                    value={field.value}
                    options={time}
                    optionLabel="name"
                    display="chip"
                    placeholder="Select Time"
                    maxSelectedLabels={7}
                    className="w-12 "
                    {...field}
                    disabled={!activeform}
                  />
                )}
              />
              {getFormErrorMessage("runTime")}
            </div>
          </div>
        </div>

        <div className="card w-full  align-items-center flex flex-wrap">
          <label htmlFor="startDate" className="flex-row flex gap-2">
            <Text type={"sub-heading"}>Start Date </Text>{" "}
            <span style={{ color: `#C6272D` }}>*</span>
          </label>
          <div className=" flex flex-row align-items-center w-12 flex-wrap">
            <div className="field flex flex-row  align-items-center w-12  md:pr-2">
              {/* <label htmlFor="startDate" className="pt-1 pr-2" >Date</label> */}
              <Controller
                name="startDate"
                control={control}
                rules={{ required: "Please enter startDate." }}
                render={({ field, fieldState }) => (
                  <Calendar
                    id={field.name}
                    showIcon={true}
                    placeholder="DD/MM/YYYY"
                    dateFormat="dd/mm/yy"
                    className={`sklbt-calender x-camp w-full mt-1 `}
                    value={field.value}
                    minDate={new Date()}
                    {...field}
                    disabled={!activeform}
                  />
                )}
              />
              {getFormErrorMessage("startDate")}
            </div>
          </div>
        </div>

        <div className="card w-full  align-items-center flex flex-wrap">
          <label htmlFor="endDate" className="flex-row flex gap-2">
            {" "}
            <Text type={"sub-heading"}>End Date </Text>{" "}
            <span style={{ color: `#C6272D` }}>*</span>
          </label>
          <div className=" flex flex-row align-items-center w-12 flex-wrap">
            <div className="field flex flex-row  align-items-center w-12 md:pr-2">
              {/* <label htmlFor="endDate" className="pt-1 pr-2" >Date</label> */}
              <Controller
                name="endDate"
                control={control}
                rules={{ required: "Please enter endDate." }}
                render={({ field, fieldState }) => (
                  <Calendar
                    id={field.name}
                    showIcon={true}
                    placeholder="DD/MM/YYYY"
                    dateFormat="dd/mm/yy"
                    className={`sklbt-calender x-camp w-full mt-1 `}
                    value={field.value}
                    minDate={new Date()}
                    {...field}
                    disabled={!activeform}
                  />
                )}
              />
              {getFormErrorMessage("endDate")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(getCampaignById(id))
        .unwrap()
        .then((res) => {
          dispatch(getCampaignsAnalytics({ id }));
        })
        .catch((err) => {
          navigate("/campaigns");
          //toast.current.show({ severity: "error", detail: err.message });
        });
    }

    return () => {
      dispatch(resetCampaignStats());
      dispatch(resetSelectedCampaign());
    };
  }, [id]);

  useEffect(() => {
    if (id[0] === `s` && selectedCampaign?.id !== "all") {
      setValue("startDate", new Date(selectedCampaign?.startDate), {
        shouldValidate: true,
      });
      setValue("endDate", new Date(selectedCampaign?.endDate), {
        shouldValidate: true,
      });
      setValue("dayOfWeek", selectedCampaign?.dayOfWeek, {
        shouldValidate: true,
      });
      setValue("runTime", selectedCampaign?.runTime, { shouldValidate: true });
      setValue("hold", selectedCampaign?.hold === 0 ? false : true, {
        shouldValidate: true,
      });
      setSchedule(selectedCampaign?.hold === 0 ? false : true);
    }

    if (
      selectedCampaign?.productItems &&
      selectedCampaign?.productItems.length > 0
    ) {
      const { productItems } = selectedCampaign;

      let prodImages = [];
      productItems?.forEach((prod) => {
        const images = prod?.images;
        if (images) {
          prodImages = [
            ...prodImages,
            { SKUCode: prod.SKUCode, image: prod?.images[0] },
          ];
        }
      });
      setProdImages(prodImages);
    }
  }, [selectedCampaign]);
  const cancelediting = () => {
    setactiveform(false);
  };
  const RenderTabView = (tab) => {
    switch (tab) {
      case "info":
        return (
          <div
            className="p-3 pt-1 mt-3 flex w-full flex-wrap"
            style={{ background: "#F8F9FE" }}
          >
            <div className="flex flex-column w-6 flex-grow-1">
              <div className="flex flex-wrap justify-content-between flex-grow-1">
                <div className="w-6">
                  <div className="my-2">
                    <Text type={"sub-heading"}>Campaign Name</Text>
                  </div>
                  <Text type={"heading"}>{selectedCampaign?.campaignName}</Text>
                </div>
                {id[0] !== "s" && (
                  <div className="w-6">
                    <div className="my-2">
                      <Text type={"sub-heading"}>Conversations Count</Text>
                    </div>
                    <Text type={"heading"}>
                      {selectedCampaign?.conversationsCount}
                    </Text>
                  </div>
                )}
                <div className="w-6">
                  <div className="my-2">
                    <Text type={"sub-heading"}>Date</Text>
                  </div>
                  <Text type={"heading"}>
                    {selectedCampaign?.createdAt
                      ? getDate(selectedCampaign?.createdAt)
                      : ""}
                  </Text>
                </div>
                {id[0] !== "s" && (
                  <div className="w-6">
                    <div className="my-2">
                      <Text type={"sub-heading"}>Broadcast Status</Text>
                    </div>
                    {campaignLoading ? (
                      <Skeleton width="7rem"></Skeleton>
                    ) : (
                      <Text type={"heading"}>
                        <div className="flex flex-column align-items-start">
                          <div className="pr-2">
                            {stats.All - stats.failed}/{stats.All}
                          </div>
                          <div className="__tag w-3rem">
                            {successRate ? `${successRate}%` : `0%`}
                          </div>
                          {/* <Tag
                          className="__tag w-3rem"
                          value={`${successRate}%`}
                          color="#000"
                          // severity={getSuccesRateSeverity(successRate)}
                        ></Tag> */}
                        </div>
                      </Text>
                    )}
                  </div>
                )}
              </div>
              {id[0] == "s" && scheduleForm()}
              <div className="mt-3">
                <div className="my-2">
                  <Text type={"sub-heading"}>Message</Text>
                </div>
                {selectedCampaign?.message &&
                  selectedCampaign?.message?.split("\n").map((x) => (
                    <div>
                      <Text type={"heading"}>{x !== "" ? x : <br />}</Text>
                    </div>
                  ))}
              </div>
              <div className="mt-5 ">
                <Text type={"sub-heading"}>Product Images</Text>
                <div className="flex flex-wrap justify-content-center md:justify-content-start gap-2 mt-3">
                  {prodImages ? (
                    <div className="flex gap-2 flex-wrap">
                      {prodImages?.map((prod, idx) => (
                        <div key={idx}>
                          <div className="prodImgContainer">
                            <Image
                              className="prodImg"
                              src={
                                prod?.image?.url ||
                                "./images/ImgPlaceholder.svg"
                              }
                              zoomSrc={
                                prod?.image?.url ||
                                "./images/ImgPlaceholder.svg"
                              }
                              onError={(e) =>
                                (e.target.src = "/images/ImgPlaceholder.svg")
                              }
                              alt="prod-img"
                              preview
                              width="100%"
                              height="100%"
                              style={{
                                objectFit: "cover",
                                maxWidth: "100%",
                                height: "100%",
                              }}
                            />
                          </div>
                          <div className="mt-2">
                            <div className="flex gap-1 align-items-center">
                              <Text type={"small-text"}>SKUID:</Text>
                              <Text
                                style={{
                                  overflowWrap: "break-word",
                                  maxWidth: "150px",
                                }}
                                type={"heading"}
                                cust="text-sm"
                              >{`${prod.SKUCode}`}</Text>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <Text type={"heading"}>No images here!</Text>
                  )}
                </div>
              </div>
              <div className="mt-5 ">
                <Text type={"sub-heading"}>Media</Text>
                <div className="flex flex-wrap justify-content-center md:justify-content-start gap-2 mt-3">
                  {selectedCampaign?.variables?.mediaUrl ? (
                    <div>
                      {selectedCampaign?.templateType.toUpperCase() ===
                        "IMAGE" && (
                        <div className="flex justify-content-start prodImgContainer ">
                          <Image
                            src={
                              selectedCampaign?.variables?.mediaUrl ||
                              "./images/ImgPlaceholder.svg"
                            }
                            alt="campagin-image"
                            style={{
                              objectFit: "cover",
                              maxWidth: "100%",
                              height: "100%",
                            }}
                            zoomSrc={
                              selectedCampaign?.variables?.mediaUrl ||
                              "./images/ImgPlaceholder.svg"
                            }
                            onError={(e) =>
                              (e.target.src = "./images/ImgPlaceholder.svg")
                            }
                            preview
                            width="100%"
                            height="100%"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          />
                        </div>
                      )}
                      {selectedCampaign?.templateType.toUpperCase() ===
                        "DOCUMENT" && (
                        <div className="flex justify-content-start docContainer">
                          <iframe
                            frameborder="0"
                            src={`${selectedCampaign?.variables?.mediaUrl}`}
                            style={{
                              maxWidth: "100%",
                              overflow: "hidden",
                              // maxHeight: "120px",
                              height: "100%",
                            }}
                          />
                        </div>
                      )}
                      {selectedCampaign?.templateType.toUpperCase() ===
                        "VIDEO" && (
                        <div className="flex justify-content-start videoContainer">
                          <video
                            id="mediaUrl"
                            controls
                            src={`${selectedCampaign?.variables?.mediaUrl}#t=1`}
                            preload="metadata"
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              maxHeight: "inherit",
                              height: "100%",
                            }}
                          ></video>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Text type={"heading"}>No Media File!</Text>
                  )}
                </div>
              </div>
            </div>

            <div
              className={`w-4 p-3 flex-grow-1 flex flex-column min-w-min camp-right-data`}
            >
              {id[0] != "s" && <CampaignPieChart />}
              <div></div>
            </div>
          </div>
        );
      case "conversations":
        return <CustomerAnalyticsDataView id={id} />;
      default:
        return <></>;
    }
  };
  const runcampaign = () => {
    if (id) {
      dispatch(resetBroadcast());
      dispatch(resetSelectedProductBlist());
      navigate(`/runcampaign/${id}`);
    } else {
      navigate(`/runcampaign`);
    }
  };
  const [showSave, setShowSave] = useState(false);
  const SaveModel = () => {
    return (
      <CustomSaveModel
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };
  const alertText = () => {
    return <div>{CONSTANTS.CAMPAIGN_SAVE_CONFIRMATION}</div>;
  };
  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false);
          }}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={handleSubmit(submit)}
        />
      </div>
    );
  };
  const onHide = () => {
    setShowSave(false);
  };
  return (
    <div className="m-auto">
      <Loader visible={campaignLoading} />
      <Toast ref={toast} />
      <CustomBreadcrumb home={home} itemslist={itemslist} />
      <SaveModel />

      <div className="p-3">
        <TableActionContainer>
          <div className="flex justify-content-between overflow-x-auto">
            <CustomTabMenu items={items} handleChange={handleTabChange} />
            <div className="flex gap-2 align-items-center">
              <CustomButton
                varient="text"
                label={"Back"}
                onClick={() => navigate("/campaigns")}
              />
              {user.role !== "support" && (
                <div className="flex flex-row gap-2 align-items-center px-2">
                  {activeform && (
                    <div
                      className="font-medium text-sm cursor-pointer"
                      onClick={cancelediting}
                    >
                      Cancel
                    </div>
                  )}
                  {accesskey[modulesName.CAMPAIGN]?.includes(
                    permissions.CREATE
                  ) &&
                    id &&
                    id[0] !== "s" && (
                      <div>
                        <CustomButton
                          varient="plain-button w-full"
                          type="submit"
                          onClick={runcampaign}
                          label={`${
                            id && id[0] == "s"
                              ? `Edit Campaign`
                              : `Re-use Campaign`
                          }`}
                        />
                      </div>
                    )}
                  {accesskey[modulesName.CAMPAIGN]?.includes(
                    permissions.UPDATE
                  ) &&
                    id &&
                    id[0] == "s" && (
                      <div>
                        <CustomButton
                          varient="filled w-full"
                          type="submit"
                          onClick={
                            activeform
                              ? handleSubmit(() => setShowSave(true))
                              : () => {
                                  setactiveform(true);
                                }
                          }
                          label={`${activeform ? `Save` : `Edit`}`}
                        />
                      </div>
                    )}
                </div>
              )}
            </div>
          </div>
        </TableActionContainer>
        {RenderTabView(activeTab)}
      </div>
    </div>
  );
};

export default CampaignDetails;
