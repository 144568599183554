import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Text } from "../../../components/Text";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { BOT_MODES } from "../bot-constants";
import CustomInputText from "../../../components/common/CustomInputText";
import { Dialog } from "primereact/dialog";
import { CustomButton } from "../../../components/CustomButton";
import {
  addNewBotFlow,
  changeSelectectBotFlow,
} from "../../../reducers/canvas/canvasSlice";
import { useLayout } from "../../../context/LayoutContext";
import { BOTFLOW_EDIT_KEY } from "../BotBuilder/node-constant";
const BotFlowForm = ({ showBotFlowForm = false, onCancel = () => {} }) => {
  const { toastMessage } = useLayout();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultValues = {
    name: BOTFLOW_EDIT_KEY,
    flowName: "",
    status: "Draft",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  //function form get error message
  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small></small>
    );
  };

  const onSubmit = (data) => {
    dispatch(addNewBotFlow(data))
      .unwrap()
      .then((res) => {
        dispatch(changeSelectectBotFlow(res.id));
        toastMessage({ severity: "success", detail: "Botflow saved!" });
        onCancel();
        navigate(`/bots/${res.id}/update`);
      })
      .catch((error) => {
        toastMessage({
          severity: "danger",
          detail: "Something went wrong!",
        });
      });
  };
  return (
    <Dialog
      header={<Text>Bot Flow form</Text>}
      onHide={onCancel}
      visible={showBotFlowForm}
      className="w-11 md:w-6"
    >
      <form
        className=" p-3 w-full form-container"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-full">
          <CustomInputText
            label="Bot Flow Name *"
            name="flowName"
            control={control}
            rules={{ required: "Botflow name is required." }}
            placeholer="Enter botflow name here"
            maxLength={200}
          />
          {getFormErrorMessage("flowName")}
        </div>
        <div className="flex mt-3 gap-2">
          <div className="flex-1">
            <CustomButton
              varient="outline"
              label={"Cancel"}
              onClick={onCancel}
              style={{ width: "100%" }}
            />
          </div>
          <div className="flex-1">
            <CustomButton
              varient="filled"
              label={"Save"}
              type="submit"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default BotFlowForm;
