import React,{ useRef, useEffect } from "react";
import Messages from "./Messages";
import Input from "./Input";
import { useDispatch, useSelector } from "react-redux";
import {
  changeIsUserSelected
} from "../../reducers/enqChatSlice";
import Products from "./Products";
import { Toast } from "primereact/toast";
import { CustomButton } from "../../components/CustomButton";
import { Text } from "../../components/Text";
import FileInputsComponenets from "../../components/FileInputsComponents";
import AgentPlace from "./AgentPlace";
import TicketReasign from "./TicketReasign";
import { formatMobNumber } from "../../utils/commonUtils";
import { Menu } from "primereact/menu";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import { ReactComponent as AddToGroupIcon } from "./../../svg/addToGroupIcon.svg"

import CopyToClipboard from "../../components/CopyToClipboard";

const Chat = ({ setAddToGroupSliderOpen }) => {
  const {
    user,
    isFileSelect,
    isProductSelect,
    isImageSelect,
    isVideoSelect,
  } = useSelector((state) => state.enqChats);
  const { accesskey } = useSelector((state) => state.authenticate);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const menuButtonRef = useRef(null);

  // const [displayBasic, setDisplayBasic] = useState(false);

  // const onHide = () => {
  //   setDisplayBasic(false);
  //   dispatch(resetSelectedCustomer());
  //   dispatch(resetMode());
  //   // dispatch(changeSelectedCustomer({phone:user.mob}));
  //   // dispatch(changeMode("convert"));
  // };

  // const addCustomerModal = () => {
  //   return (
  //     <AddNewCustomer
  //       onHide={onHide}
  //       displayBasic={displayBasic}
  //       toast={toast}
  //     />
  //   );
  // };

  // function handelClick() {
  //   dispatch(changeMode("convert"));
  //   dispatch(changeSelectedCustomer({ phone: user.mob }));
  //   setDisplayBasic(true);
  // }

  const mobNumber = formatMobNumber(user?.mob)

  let items = [{ label:mobNumber ,
    icon: 'pi pi-copy',
    command: () => {
        navigator.clipboard.writeText(mobNumber);
        toast.current.show({ severity: 'success', detail: 'Copied on clipboard ', life: 3000 });
    }
   }];

   function ChatHeader() {
    return (
      <div className="flex align-items-center gap-1">
        <CustomButton
          varient="icon-button md:hidden"
          icon={"pi pi-arrow-left"}
          onClick={() => dispatch(changeIsUserSelected(false))}
        />
        <Text type="sub-heading" cust="flex align-items-center">
          <div className="capitalize mr-3">
            <Text type={"sub-heading"}>
              {user?.displayName || mobNumber}
            </Text>
          </div>
          <div>
            {user?.displayName ? (
              <CustomButton
                varient="icon-button"
                icon={"pi pi-info-circle"}
                onClick={(event) => menuButtonRef.current.toggle(event)}
              />
            ):
            <CopyToClipboard toClipboard={mobNumber}/>
          }
          </div>
        </Text>
      </div>
    );
}

  return (
    <div className="h-full">
      <Toast ref={toast} />
      <Menu model={items} popup ref={menuButtonRef} id="menu_button" />
      <div className="flex flex-column h-full ">
        {!accesskey[modulesName.SALES]?.includes(permissions.UPDATE) && (
          <AgentPlace />
        )}
        {Object.keys(user)?.length != 0 && (
          <>
            <div className="chatInfo p-2">
              <div className="px-2 flex flex-column md:flex-row justify-content-between  align-items-start md:align-items-center">
                <div className="w-12">{ChatHeader()}</div>
                <div className="flex w-12">
                    <div className="w-full">
                      <TicketReasign />
                    </div>
                    {accesskey[modulesName.CUSTOMER]?.includes(permissions.CREATE) ?
                      <CustomButton
                        varient="plain-button ml-1"
                        icon={<AddToGroupIcon />}
                        onClick={() => setAddToGroupSliderOpen((prev) => !prev)}
                      />
                      : <></>
                    }  
                </div>
              </div>
            </div>

            {isFileSelect ? (
              <FileInputsComponenets />
            ) : isImageSelect ? (
              <FileInputsComponenets />
            ) : isVideoSelect ? (
              <FileInputsComponenets />
            ) : isProductSelect ? (
              <Products />
            ) : (
              <>
                <Messages/>
                <Input />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Chat;
