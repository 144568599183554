import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function PieChartTimer({ startTimestamps, duration, currentTime }) {
  let endTimestamp = new Date(startTimestamps + duration);
  const timeRemaining = endTimestamp - currentTime;
  const remainingPercentage = (timeRemaining / duration) * 100;
  return (
    <CircularProgressbar
      value={endTimestamp == "Invalid Date" ? 0 : remainingPercentage}
      strokeWidth={50}
      counterClockwise={true}
      styles={{
        path: {
          stroke: `rgba(0, 0, 0)`,
          strokeLinecap: "butt",
        },
        trail: {
          stroke: "inherit",
          strokeLinecap: "butt",
        },
      }}
    />
  );
}

export default PieChartTimer;
