import styles from "./style.module.css";
import { Handle, Position } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import {
  changeListNode,
  changeSelectedNode,
  closeConfigScreen,
  openConfigScreen,
  undoNodeData,
} from "../../../reducers/canvas/canvasSlice";
import { handleInputType } from "../../../reducers/multiInputSlice";
import { getTheNode } from "../../../utils/canvasUtils";
import { Tag } from "primereact/tag";
import { Text } from "../../Text";
import { CANVAS_NODE_TITLES } from "../node_constant";
import { NodeHeader } from "../NodeHeader";
import { StartTag } from "../StartTag";
import EditButton from "../Editbutton";
import { islistNodeValid } from "../../../view/Bots/BotBuilder/node-constant";

const ListNode = (props) => {
  const dispatch = useDispatch();
  const { nodes, selectedNode, rootStepId, configScreenOpen } = useSelector(
    (state) => state.canvas
  );
  const handleNodeSelection = () => {
    if (selectedNode?.id !== props.id) {
      if (configScreenOpen) {
        dispatch(undoNodeData());
        dispatch(closeConfigScreen());
      }
      dispatch(changeSelectedNode(getTheNode(nodes, props?.id)));
    }
  };
  const handleEditClick = (e) => {
    e.stopPropagation();
    dispatch(undoNodeData());
    dispatch(handleInputType("list"));
    dispatch(openConfigScreen(getTheNode(nodes, props?.id)));
  };
  return (
    <>
      {rootStepId == props?.id && <StartTag />}
      <div
        onClick={handleNodeSelection}
        className={`${styles.listNode} ${
          selectedNode?.id == props?.id
            ? styles["selected-node"]
            : !islistNodeValid(props.data)
            ? styles["invalid-node"]
            : ""
        } p-2 relative`}
      >
        <Handle type="target" position={Position.Left} />
        <div>
          <NodeHeader nodeType={props.data.type} />
          <EditButton onClick={handleEditClick} />

          <div className={`mt-2 ${styles["text-message"]}`}>
            <div
              style={{
                whiteSpace: "pre-line",
              }}
            >
              <Text type={"small-text"}>{props.data.body}</Text>
            </div>
            <div className="my-3">
              {props?.data?.globalButton && (
                <button
                  onClick={() => {}}
                  style={{
                    width: "100%",
                    padding: "4px",
                    fontSize: "0.8rem",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #ECECEC",
                    borderRadius: "4px",
                  }}
                >
                  {props?.data?.globalButton}
                </button>
              )}
            </div>
            <div className="flex gap-2 flex-column">
              {props.data.options?.map((opt, i) => (
                <Text type="sub-heading" style={{ flexGrow: "1" }} key={opt.id}>
                  {i + 1}. {opt.title}
                </Text>
              ))}
            </div>
          </div>
        </div>

        <Handle type="source" position={Position.Right} />
      </div>
    </>
  );
};

export default ListNode;
