import parseJwt from "../utils/authUtils";
import axiosInstance from "./axios.instance";

const API_LOGIN = async (userCredentials) => {
  try {
    const { data } = await axiosInstance.post("/user/login", userCredentials);
    return data;
  } catch (err) {
    throw err;
  }
};

const API_LOGOUT = async () => {
  try {
    const { data } = await axiosInstance.get("/user/logout");
    return data;
  } catch (err) {
    throw err;
  }
};

const API_USER_ROLE=async()=>{
  try {
    const { data } = await axiosInstance.get("/user/roles");
    return data;
  } catch (err) {
    throw err;
  }
}

const API_SUBSCRIPTION = async (companyId) => {
  try {
    const { data } = await axiosInstance.get(
      `/company/${companyId}/gs-conversation`
    );

    return data;
  } catch (err) {
    throw err;
  }
};

const API_GET_OTP = async (configData) => {
  try {
    const { data } = await axiosInstance.post(`/user/password/otp`, configData);
    return data;
  } catch (err) {
    throw err;
  }
};

const API_VERIFY_OTP = async (credential) => {
  try {
    const { data } = await axiosInstance.post(
      `/user/password/otp/verify`,
      credential
    );
    return data;
  } catch (err) {
    throw err;
  }
};

const API_RESET_PASS = async (credential) => {
  try {
    const { data } = await axiosInstance.post(
      `/user/password/reset`,
      credential
    );
    return data;
  } catch (err) {
    throw err;
  }
};

// sign up

const API_GET_SIGNUP_OTP = async (phone) => {
  try {
    const { data } = await axiosInstance.post(`/company/otp`, phone);
    return data;
  } catch (err) {
    throw err;
  }
};

const API_VERIFY_SIGNUP_OTP = async (credential) => {
  try {
    const { data } = await axiosInstance.post(`/company/verify`, credential);
    return data;
  } catch (err) {
    throw err;
  }
};

export {
  API_LOGIN,
  API_LOGOUT,
  API_SUBSCRIPTION,
  API_USER_ROLE,
  API_GET_OTP,
  API_VERIFY_OTP,
  API_RESET_PASS,
  API_GET_SIGNUP_OTP,
  API_VERIFY_SIGNUP_OTP,
};
