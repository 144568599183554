import { useSelector } from "react-redux";

const actionsKeyValue = {
  assignAgent: "Assign Agent",
  addressInfo: "Save Address",
  shareCatalog: "Share Catalog",
};

const ActionInput = () => {
  const { selectedNode } = useSelector((state) => state.canvas);

  return (
    <div className="card">{actionsKeyValue[`${selectedNode.data?.type}`]}</div>
  );
};

export default ActionInput;
