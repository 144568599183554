import { Text } from "../../../components/Text";
import { Doughnut, Pie } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import styles from "../style.module.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axiosInstance from "../../../api/axios.instance";
import { getDate } from "../../../utils/datemaker";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "primereact/skeleton";
import EllipsisText from "../../../components/common/EllipsisText/indix";
import { ReactComponent as Edit } from "../../../svg/edit.svg";
import { ReactComponent as DocIcon } from "../../../assets/docPreview.svg";
import { ReactComponent as CampaignIcon } from "../../../assets/campaign.svg";
import { ReactComponent as ConversationIcon } from "../../../assets/conversation.svg";
import { ReactComponent as VideoPreview } from "../../../assets/videoPreview.svg";
import { ReactComponent as DashboardCampaign } from "../../../assets/svgIcons/dashboard_campaign.svg";
import { ReactComponent as DashboardConversations } from "../../../assets/svgIcons/dashboard_conversation.svg";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { ReactComponent as ClockIcon } from "../../../assets/svgIcons/clock.svg";
import { ReactComponent as ClockHoldIcon } from "../../../assets/svgIcons/clockhold.svg";
import { Image } from "primereact/image";
import { useLayout } from "../../../context/LayoutContext";
import { getCampaigns } from "../../../reducers/campaignSlice";
import { refineMessage } from "../../../utils/templateUtils";
import { getCountConversation } from "../../../reducers/analyticSlice";
import CampScheduleTime from "../../../components/CampScheduleTime";
import CampaignRowAnalytics from "../../Campaigns/CampaignRowAnalytics";
import CustomSkeleton from "../../../components/CustomSkeleton";
import ImageGallary from "../../../components/common/ImageGallary";
ChartJS.register(ArcElement, Tooltip, Legend);

function CampaignsSections() {
  const [recentCampaigns, setRecentCamapigns] = useState([]);
  const [recentCampaignslaoding, setRecentCampaignsLoading] = useState(false);
  const { userSub, loading } = useSelector((state) => state.authenticate);
  const [campaignDataWithSR, setCampaignDataWithSR] = useState([]);
  const [loadingSRAnalytics, setLoadingSRAnalytics] = useState(false);
  const [clickedCampaign, setClickedCampaign] = useState();
  const {
    loading: analyticloading,
    dateRange,
    analyticalDetails,
    skeletonloading
  } = useSelector((state) => state.analytic);
  const { campaignData, campaignLoading } = useSelector(
    (state) => state.campaign
  );
  const navigate = useNavigate();

  const [campaignDetail, setCampaignDetail] = useState({
    totalcampaign: 0,
    totalschedule: 0,
    success: 0,
    onhold: 0
  });
  const [coversationDetail, setCoversationDetail] = useState({
    usedConversation: 0,
    successRate: 0
  });
  useEffect(() => {
    let totalcampaign =
      analyticalDetails?.campaign?.statistics?.allCampaignsCount | 0;
    let totalschedule =
      analyticalDetails?.campaign?.statistics?.scheduledCampaignsCount | 0;
    let success =
      analyticalDetails?.campaign?.statistics?.successCampaignsCount | 0;
    let onhold =
      analyticalDetails?.campaign?.statistics?.holdCampaignsCount | 0;
    let usedConversation =
      analyticalDetails?.campaignConversation?.usedConversation | 0;
    let successRate = analyticalDetails?.campaignConversation?.successRate
      ? analyticalDetails?.campaignConversation?.successRate
      : "0";
    setCoversationDetail({ usedConversation, successRate });
    setCampaignDetail({ totalcampaign, totalschedule, success, onhold });
  }, [analyticalDetails]);

  const mediaBody = (rowData) => {
    const continerStyle = {
      width: "auto",
      maxHeight: "50px",
      aspectRatio: "16/9"
    };
    // const handleImageClick = (e) => {
    //   e.stopPropagation(); // Prevents the row click event from triggering
    //   // Add your logic for handling the image click here
    //   // For example, you can open a modal or navigate to a new page to show the image.
    //   console.log("Image clicked!", rowData);
    // };
    const { campaignType, templateType, variables } = rowData;

    if (campaignType === "product") {
      const { productItems } = rowData;
      let prodImages = [];
      productItems?.forEach((prod) => {
        const images = prod?.images;
        if (images) {
          prodImages = [...prodImages, ...images];
        }
      });
      return (
        <div
          className="flex justify-content-start align-items-center gap-2"
          style={continerStyle}
          onClick={(e) => e.stopPropagation()}
        >
          {prodImages.length > 0 ? <ImageGallary images={prodImages} /> : ""}
        </div>
      ); 
    } else {
      switch (templateType?.toLowerCase()) {
        case "video":
          return (
            <div
              className="flex flex-row align-items-center align-content-center  justify-content-between "
            >
              <div  className={`flex flex-row align-items-center align-content-center  justify-content-center surface-100 cthumbnailContainer`}>
              <div className="flex flex-row justify-content-center align-items-center align-content-center surface-0" >
                    <div
                      style={{
                        objectFit: "cover",
                        width: "60px",
                        height: "100%",
                        background: "#000",
                        top:0,
                        left:20,
                        position:"absolute"
                      }}
                    >
                    <VideoPreview
                      width="auto w-12"
                      height="30"
                      className="absolute"
                      style={{top:10,left:0}}
                      />
                    </div>
                </div>
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              </div>
            </div>
          );
        case "image":
          return (
            <div
              className="flex justify-content-start z-5 "
              style={continerStyle}
              // onClick={(e)=>{handleTemplateImageClick(e,variables?.mediaUrl)}}
              onClick={(e) => e.stopPropagation()}
            >
              <ImageGallary images={variables} />
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
            </div>
          );
        case "document":
          return (
            <div className="flex justify-content-start " style={continerStyle}>
              <div className="flex justify-content-center pt-2 w-6 ">
                <a
                  href={`${variables?.mediaUrl}`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  target="_blank"
                >
                  <DocIcon width="35" height="35" />
                </a>
              </div>
              {/* <iframe
                frameborder="0"
                src={`${variables?.mediaUrl}`}
                style={{
                  maxWidth: "100%",
                  overflow: "hidden",
                  // maxHeight: "120px",
                  height: "100%",
                }}
              /> */}
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
            </div>
          );
        default:
          return (
            <div
              className="flex justify-content-start align-items-end gap-2"
              style={continerStyle}
            >
              <div className="flex justify-content-center w-6 pb-2">
                <Text>_</Text>
              </div>
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
            </div>
          );
      }
    }
  };

  const dispatch = useDispatch();
  const { setLoading } = useLayout(null);

  useEffect(() => {
    async function fetchAnalyticsForIds() {
      const analyticsPromises = campaignData.map(async (campaign) => {
        const id = campaign.id;
        try {
          const response = await axiosInstance.get(`/campaign/${id}/analytics`);
          return response.data;
        } catch (error) {
          console.error(
            `Error fetching analytics for campaign with id ${id}:`,
            error
          );
          setLoadingSRAnalytics(false); // eslint-disable-line

          return [];
        }
        setLoadingSRAnalytics(false); // eslint-disable-line
      });

      setLoadingSRAnalytics(true);
      const analyticsResponses = await Promise.all(analyticsPromises);

      const updatedCampaignData = campaignData.map((campaign, index) => {
        const analytics = analyticsResponses[index];
        const allStatus = analytics.find((item) => item.status === "All");
        const failedStatus = analytics.find(
          (item) => item.status === "failed"
        ) || { count: 0 };
        const stat = {
          sent: 0,
          delivered: 0,
          read: 0,
          replied: 0,
          optout: 0,
          failed: 0
        };
        analytics?.map((item) => {
          if (item.status === "send") {
            stat["sent"] = item.count;
          }
          if (item.status === "delivered") {
            stat["delivered"] = item.count;
          }
          if (item.status === "read") {
            stat["read"] = item.count;
          }
          if (item.status === "replied") {
            stat["replied"] = item.count;
          }
          if (item.status === "opt-out") {
            stat["opt-out"] = item.count;
          }
          if (item.status === "failed") {
            stat["failed"] = item.count;
          }
        });
        let successRate = 0;

        if (allStatus) {
          successRate =
            ((allStatus.count - failedStatus.count) / allStatus.count) * 100;
          successRate = Math.max(successRate, 0);
          successRate = parseFloat(successRate.toFixed(1));
        }

        const totalStatus = allStatus?.count || 0;

        return {
          ...campaign,
          successRate,
          totalStatus,
          ...stat,
          showgrapg:
            stat.sent ||
            stat.delivered ||
            stat.read ||
            stat.replied ||
            stat["opt-out"] ||
            stat.failed,
          failedStatus: failedStatus.count
        };
      });
      setCampaignDataWithSR(updatedCampaignData);
    }

    fetchAnalyticsForIds();
  }, [campaignData]);

  useEffect(() => {
    let { fromDate, toDate } = dateRange;
    if (!toDate) toDate = fromDate;
    let paginationData = {
      page: 0,
      limit: 10,
      // campaignType,
      startDate: fromDate
        ? new Date(fromDate).toISOString().substring(0, 10)
        : fromDate,
      endDate: toDate ? new Date(toDate).toISOString().substring(0, 10) : toDate
      // globalFilterValue: inputValue,
    };
    if ((fromDate && !toDate) || (!fromDate && toDate)) {
      return;
    } else {
      dispatch(getCampaigns(paginationData))
        .unwrap()
        .catch((err) => {
          //show toast here
        });
      dispatch(getCountConversation({ dateRange }))
        .unwrap()
        .catch((err) => {
          //show toast here
        });
    }
  }, [dateRange]);

  const handleCampaignSelect = (campaign) => {
    if (campaign.Type === "ScheduledCampaign") {
      navigate(`/campaigns/s-${campaign.id}`);
    } else navigate(`/campaigns/${campaign.id}`);
  };

  const successRateBodyTemplate = (data) => {
    return (
      <div className="flex gap-2 justify-content-between align-items-center w-12 ">
        {data.Type === "ScheduledCampaign" && (
          <div>
            {!(data.hold === "1" || data.hold === true) ? (
              <ClockIcon />
            ) : (
              <ClockHoldIcon />
            )}
          </div>
        )}
        {data.Type === "ScheduledCampaign" && (
          <CampScheduleTime isHold={data?.hold} nextRun={data?.nextRun} />
        )}

        {data.Type == "Campaign" && (
          <CampaignRowAnalytics campaignDetails={data} />
        )}
      </div>
    );
  };
  return (
    <div className="w-full">
      <div
        className={`w-full ${styles["record-wrap-stat"]} p-2 flex flex-column md:flex-row gap-3`}
      >
        <div className="w-full md:w-6 flex flex-column  bg-white">
          <div className="p-2 flex flex flex-row align-items-center gap-2">
            <div className="m-2">
              <DashboardCampaign />
            </div>
            <div className=" font-bold flex flex-row gap-2  ">
              <div className="font-normal">Total Campaigns</div>
              {skeletonloading?.campaign?<div>
                  <CustomSkeleton parentcss={"flex"} countline={1} height={"1.5rem"} width={"2rem"}  className="border-round "/>
                </div>:<div className="">{campaignDetail.totalcampaign}</div>}
            </div>
          </div>
          <div className="pl-8 ">
            <div className="flex flex-row pb-2 pr-2 gap-4 lg:gap-0 ">
              <div className={`flex-1 p-1`}>
              {skeletonloading?.campaign?<div>
                  <CustomSkeleton parentcss={"flex"} countline={1} height={"1.5rem"} width={"2rem"} className="border-round "/>
                </div>:<div className="font-semibold">
                  {campaignDetail.totalschedule}
                </div>}
                <div>Scheduled</div>
              </div>
              <div className={`flex-1 p-1`}>
              {skeletonloading?.campaign?<div>
                  <CustomSkeleton parentcss={"flex"} countline={1} height={"1.5rem"} width={"2rem"}  className="border-round "/>
                </div>:
                <div className="font-semibold">{campaignDetail.success}</div>}
                <div>Successful</div>
              </div>
              <div className={`flex-1 p-1`}>
              {skeletonloading?.campaign?<div>
                  <CustomSkeleton parentcss={"flex"} countline={1} height={"1.5rem"} width={"2rem"}  className="border-round "/>
                </div>:
                <div className="font-semibold">{campaignDetail.onhold}</div>}
                <div>Hold</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-6 flex flex-column  bg-white">
          <div className="p-2 flex flex flex-row align-items-center gap-2 ">
            <div className="m-2">
              <DashboardConversations />
            </div>
            <div className=" font-bold flex flex-row gap-2">
              <div className="font-normal">Total Conversation Count</div>
              {skeletonloading?.campaign?<div>
                  <CustomSkeleton parentcss={"flex"} countline={1} height={"1.5rem"} width={"2rem"} className="border-round"/>
                </div>:<div className="">{coversationDetail.usedConversation}</div>}
            </div>
          </div>
          <div>
            <div className="flex flex-row pl-8 pr-2 pb-2">
              {/* <div className={`w-3 p-1`}>
                    <div className="font-semibold">
                      {coversationDetail.successCount}  
                    </div>
                    <div>
                      Delivered
                    </div>
                </div>
                <div className={`w-3 p-1`}>
                    <div className="font-semibold">
                      5,784
                    </div>
                    <div>
                      Waiting to deliver
                    </div>
                </div> */}
              <div className={`w-full p-1`}>
              {skeletonloading?.campaign?<div>
                  <CustomSkeleton parentcss={"flex"} countline={1} height={"1.5rem"} width={"2rem"} className="border-round "/>
                </div>:
                <div className="font-semibold">
                  {`${coversationDetail.successRate} %`}
                </div>}
                <div>Campaigns Success</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-md font-semibold pb-1 mt-2 p-2">Campaigns</div>
      <div
        className={`w-full  flex flex-column justify-content-between align-items-start  w-12 `}
        style={{ maxHeight: "754px",height:"700px" }}
      >
        <div className={`my-3 mt-2 ${styles["record-wrap-stat"]} flex  w-full`}>
          <div className="w-full">
            { skeletonloading?.ticket?<div className="flex flex-column gap-4">
                <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={6} height={"1rem"} className="border-round w-2"/>
                <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={6} height={"1rem"} className="border-round w-2"/>
                <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={6} height={"1rem"} className="border-round w-2"/>
                <CustomSkeleton parentcss={"flex flex-row gap-4"} countline={6} height={"1rem"} className="border-round w-2"/>
              </div>:<DataTable
              className="skalebot-table"
              value={campaignDataWithSR}
              responsiveLayout="scroll"
              resizableColumns
              columnResizeMode="expand"
              emptyMessage="No data found."
              selection={clickedCampaign}
              stripedRows
              selectionMode="single"
              onSelectionChange={(e) => handleCampaignSelect(e.value)}
            >
              <Column
                field="campaignName"
                header="Campaign Name"
                bodyStyle={{
                  minWidth: "150px",
                  maxWidth: "200px",
                  textTransform: "capitalize"
                }}
                style={{
                  minWidth: "150px",
                  maxWidth: "200px",}}
                body={(rowData) => <EllipsisText text={rowData.campaignName} />}
              />
              <Column
                field="conversationsCount"
                className="text-sm font-normal"
                style={{width:'15ch'}}
                header={"Conversations"}
              />
              <Column
                field="createdAt"
                header="Date & Time"
                style={{width:'20ch'}}
                className="text-sm font-normal"
                body={(rowData) =>{let date= getDate(rowData.createdAt)?.split(",")
                  return <div className="flex flex-column gap-2">
                    <div>
                      {date[0]}
                    </div>
                    <div>
                      {date[1]}
                    </div>
                  </div>
                }}
                bodyStyle={{ textTransform: "uppercase" }}
                headerStyle={{ whiteSpace: "nowrap" }}
              />
              <Column
                field="message"
                header={"Message"}
                bodyStyle={{ minWidth: "250px", maxWidth: "350px" }}
                body={(rowData) => {
                  let message = refineMessage(
                    rowData?.variables,
                    rowData?.message
                  );
                  return <EllipsisText text={message} />;
                }}
              />

              <Column
                header="Campaign Resource"
                body={mediaBody}
                style={{width:'30ch'}}
                headerStyle={{ whiteSpace: "nowrap" }}
              ></Column>
              <Column
                header={() => <div className="pl-4">Status</div>}
                // field="successRate"
                style={{width:'25ch'}}
                body={successRateBodyTemplate}
              ></Column>
            </DataTable>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignsSections;
