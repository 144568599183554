import { useForm, Controller } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";
import { CustomButton } from "../CustomButton";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { hideShareMessageDialog } from "../../reducers/ShareMessage/messageSlice";
import TemplatePreview from "../TemplatePreview";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { calculateConversationCharge, getIds } from "../../utils/shareUtils";
import { templateTypes, formateMessageInput } from "../../utils/templateUtils";
import { useState, useRef } from "react";
import { Text } from "../Text";
import { underlineStyle } from "../../utils/commonStyles";
import { useEffect } from "react";
import CustomSwitch from "../CustomSwitch";
import { SelectableCustomers } from "../SelectableCustomers";
import { shareBroadcast } from "../../reducers/ShareMessage/actions";
import SelectionAnalytics from "../SelectionAnalytics";
import SklbtConfirmation from "../common/SklbtConfirmation";
import * as Messag from '../../config/ToastMessage';
const switchOption = [
  { name: "Preview", value: "preview", icon: "" },
  { name: "Customer", value: "customer", icon: "" },
];

export const Share = ({ toast }) => {
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState("all");
  const [showInRight, setShowInRight] = useState("preview");
  const [tempVariables, setTempvariables] = useState([]);
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [documentPreview, setDocumentPreview] = useState(null);
  const [templateButtons, setTemplateButtons] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [campaignData, setCampaignData] = useState(null);

  const { shareMessageDialog, selectedTemplate } = useSelector(
    (state) => state.shareMessage
  );

  if (selectedTemplate) {
    var { templateType } = selectedTemplate;
  }
  const defaultValues = {
    campaignName: "",
    templateType: "",
    header: "",
    footer: "",
    message: "",
  };

  const dialogHeader = () => {
    return (
      <div>
        <Text type={"heading"}>
          <span style={underlineStyle}>Share Broadcast</span>
        </Text>
      </div>
    );
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
    setValue,
  } = useForm(defaultValues);

  //function form get error message
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const dispatch = useDispatch();
  const mediaFileRef = useRef();

  const previewFile = (e) => {
    // Reading New File (open file Picker Box)
    const reader = new FileReader();
    // Gettting Selected File (user can select multiple but we are choosing only one)
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      reader.readAsDataURL(selectedFile);
    }
    // As the File loaded then set the stage as per the file type
    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes("image")) {
        setImagePreview(readerEvent.target.result);
      } else if (selectedFile.type.includes("video")) {
        setVideoPreview(readerEvent.target.result);
      } else if (selectedFile.type.includes("pdf")) {
        setDocumentPreview(readerEvent.target.result);
      }
    };
  };

  const headerContainer = () => {
    const mediaSelect = (icon, accept) => {
      return !file ? (
        <div className="field">
          <input
            ref={mediaFileRef}
            type="file"
            accept={`${accept}`}
            hidden
            onChange={previewFile}
          />
          <button
            type="button"
            className="w-full temp-img flex gap-3 p-3 my-2 cursor-pointer"
            onClick={() => mediaFileRef.current.click()}
          >
            <i className={`pi ${icon}`} />
            <Text type={"sub-heading"}>
              Choose {templateType.toLowerCase()}
            </Text>
          </button>
        </div>
      ) : (
        <div className="temp-img-upload field">
          <div className="flex gap-2 justify-content-between align-items-center">
            <div className="flex gap-2 align-items-center">
              <i className={`pi ${icon}`} />
              <Text type={"sub-heading"}>{file.name}</Text>
            </div>
            <i
              className="pi pi-trash cursor-pointer"
              style={{ float: "right" }}
              onClick={handleDeleteMedia}
            />
          </div>
        </div>
      );
    };

    const handleDeleteMedia = () => {
      setFile(null);
      switch (templateType) {
        case "IMAGE":
          setImagePreview(null);
          break;
        case "VIDEO":
          setVideoPreview(null);
        case "Document":
          setDocumentPreview(null);
        default:
          break;
      }
    };
    if (templateType) {
      switch (templateType) {
        case "IMAGE":
          return mediaSelect("pi-image", "image/*");
        case "VIDEO":
          return mediaSelect("pi-video", "video/*");
        case "DOCUMENT":
          return mediaSelect("pi-file", ".doc,docx,.pdf");
        default:
          return <></>;
      }
    }
  };
  const handleEditVariable = (e, id) => {
    let updateVariableData = tempVariables.map((item) => {
      if (item.id !== id) return item;
      else return { ...item, variableValue: e.target.value };
    });
    setTempvariables(updateVariableData);
  };

  const handleButtonEdit = (e, id, key) => {
    let updatedButtons = templateButtons.map((btn) => {
      if (btn.id !== id) return btn;
      else return { ...btn, [`${key}`]: e.target.value };
    });
    setTemplateButtons(updatedButtons);
  };

  const handleSaveButton = (id) => {
    let updatedButtons = templateButtons.map((btn) => {
      if (btn.id !== id) return btn;
      else return { ...btn, isSaved: true };
    });
    setTemplateButtons(updatedButtons);
  };

  const handleSwitch = (value) => {
    setShowInRight(value);
  };

  const hideShareModule = () => {
    reset();
    setConfirmAlert(false);
    setTempvariables([]);
    setTemplateButtons([]);
    setFile(null);
    setImagePreview(null);
    setVideoPreview(null);
    setDocumentPreview(null);
    setSelectedCustomers([]);
    setSelectedGroupId("all");
    dispatch(hideShareMessageDialog());
  };

  // handle sumbit formdata

  const handleShare = (configData) => {
    dispatch(shareBroadcast({ configData, media: file }))
      .unwrap()
      .then((resp) => {
        toast.current.show({ severity: "success", detail: Messag.Share_Camp_ToastSuccessMessage});
        hideShareModule();
      })
      .catch((err) => {
        console.log(err);
        toast.current.show({
          severity: "error",
          detail: err.message,
        });
      });
  };
  const onSubmit = (data) => {
    if (!file && watch("templateType") != "TEXT") {
      toast.current.show({
        severity: "warn",
        detail: Messag.ERR_Camp_Media_ToastFailMessage,
      });
      return;
    } else if (selectedCustomers.length == 0 && selectedGroupId == "all") {
      toast.current.show({
        severity: "warn",
        detail: Messag.ERR_Camp_Cust_ToastFailMessage,
      });
      return;
    }

    const { elementName, templateId, containerMeta } = selectedTemplate;
    const { buttons } = JSON.parse(containerMeta);
    const selectedCustomerIds =
      selectedGroupId == "all" ? getIds(selectedCustomers) : [];
    const groupIds = selectedGroupId !== "all" ? [selectedGroupId] : [];
    const variablesData = {};
    tempVariables.forEach((item) => {
      if (item.variableType == "customNote")
        variablesData[`var${item.variableName[2]}`] = item.variableValue;
    });

    let configData = {
      ...data,
      campaignType: "broadcast",
      customerIds: selectedCustomerIds,
      groupIds: groupIds,
      message: formateMessageInput(watch("message"), "content", tempVariables),
      SKUCodes: [],
      variables: { ...variablesData },
      templateId: templateId,
      buttons: buttons != undefined ? JSON.stringify([...buttons]) : [],
    };

    setConfirmAlert(true);
    setCampaignData(configData);
  };

  useEffect(() => {
    if (selectedTemplate != null) {
      const { containerMeta, variables, templateType } = selectedTemplate;

      const { header, buttons, footer, data } = JSON.parse(containerMeta);
      let messStr = data;
      if (variables != undefined) {
        const templVars = variables?.map((item) => {
          messStr = messStr.replace(
            item.variableName,
            `{{${item.variableType}}}`
          );
          return {
            ...item,
            id: Math.floor(1000 + Math.random() * 9000),
            variableValue: "",
          };
        });
        setTempvariables(templVars);
      }

      if (buttons != undefined) {
        const tempButtons = buttons?.map((item) => ({
          ...item,
          id: Math.floor(1000 + Math.random() * 9000),
          isSaved: true,
        }));
        setTemplateButtons(tempButtons);
      }
      setValue("message", messStr);
      setValue("header", header || "");
      setValue("footer", footer || "");
      setValue("templateType", templateType);
    }
  }, [selectedTemplate]);

  return (
    <Dialog
      header={dialogHeader}
      visible={shareMessageDialog}
      onHide={hideShareModule}
      className="w-11 template-form-module"
    >
      {confirmAlert && (
        <SklbtConfirmation
          header="You are about to share"
          message={`Are You Sure? \n\nThis will consume ${calculateConversationCharge(
            selectedCustomers
          )} Conversations.
      `}
          visible={confirmAlert}
          handleReject={() => setConfirmAlert(false)}
          handleConfirm={() => handleShare(campaignData)}
        />
      )}

      <div className="flex w-full flex-wrap-reverse">
        <div
          className="flex flex-column w-8 flex-grow-1 lg:flex-grow-0 xl:flex-grow-0 p-2 lg:p-6 xl:p-6 min-h-full"
          style={{
            backgroundColor: "#F8F8FC",
          }}
        >
          <SelectionAnalytics
            items={[
              { count: selectedCustomers.length, label: "Conversations" },
            ]}
          />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-fluid w-full h-full flex flex-column mt-3 justify-content-between"
          >
            <div>
              <div className="field">
                <label htmlFor="campaignName">Name *</label>
                <Controller
                  name="campaignName"
                  control={control}
                  rules={{
                    required: "Campaign name is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      maxLength={50}
                      value={field.value}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      placeholder="Enter campaign name"
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage("campaignName")}
              </div>
              {headerContainer()}
              <div className="field">
                <label htmlFor="message">Body *</label>
                <Controller
                  name="message"
                  control={control}
                  rules={{ required: "message is required." }}
                  render={({ field, fieldState }) => (
                    <InputTextarea
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      id={field.name}
                      value={field.value}
                      rows={3}
                      cols={30}
                      {...field}
                      maxLength={1028}
                      autoResize
                      placeholder="Enter your message (max 1028 chars)"
                      disabled
                    />
                  )}
                />
                {getFormErrorMessage("message")}
                <div className="my-2">
                  {tempVariables
                    ?.filter((item) => item.variableType === "customNote")
                    ?.map((item, i) => (
                      <div
                        className="flex gap-2 justify-content-center align-items-center my-3"
                        key={item.id}
                      >
                        <div className="w-3">
                          {i == 0 && <label>Variable</label>}

                          <InputText value={item.variableType} disabled />
                        </div>
                        <div className="relative flex-grow-1">
                          {i == 0 && <label>Value</label>}
                          <div className="flex align-items-center gap-2">
                            <InputText
                              value={item.variableValue}
                              placeholder={`Enter value`}
                              onChange={(e) => handleEditVariable(e, item.id)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              {templateButtons.length !== 0 && (
                <div className="field">
                  <Text>Buttons</Text>
                  <div className="button-preview-container w-full mb-2 py-2">
                    {templateButtons?.map((item) =>
                      item.isSaved ? (
                        <div
                          className="m-2  flex justify-content-between p-3"
                          style={{ backgroundColor: "#005068" }}
                          key={item.id}
                        >
                          <Text type={"sub-heading"} color={"#ffffff"}>
                            {item.text}
                          </Text>
                        </div>
                      ) : (
                        <div className="m-2 relative" key={item.id}>
                          <div className="relative mt-2">
                            <div className="flex flex-column gap-2">
                              <InputText
                                placeholder="Enter Label (Max 17 Char)"
                                value={item.text}
                                onChange={(e) =>
                                  handleButtonEdit(e, item.id, "text")
                                }
                                maxLength={17}
                                required
                              />
                              {item.type == "PHONE_NUMBER" && (
                                <InputText
                                  placeholder="Enter phone number"
                                  value={item.phone_number}
                                  onChange={(e) =>
                                    handleButtonEdit(e, item.id, "phone_number")
                                  }
                                  required
                                />
                              )}
                              {item.type == "URL" && (
                                <InputText
                                  placeholder="Enter URL"
                                  value={item.url}
                                  onChange={(e) =>
                                    handleButtonEdit(e, item.id, "url")
                                  }
                                  required
                                />
                              )}
                            </div>

                            <div
                              className=" flex justify-content-end gap-3 p-2"
                              style={{ right: 8, top: 8 }}
                            >
                              <i
                                className="pi pi-check"
                                onClick={() => handleSaveButton(item.id)}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="flex gap-3 mt-6">
              <CustomButton
                varient="filled"
                type="submit"
                label={"Share with selected customers"}
              />
            </div>
          </form>
        </div>
        <div
          className={`flex flex-grow-1 justify-content-center mob-preview-container `}
          style={{ minHeight: "620px" }}
        >
          <div className="relaive w-full flex justify-content-center">
            <div className="static lg:fixed xl:fixed">
              <div className="flex flex-column justify-content-center align-items-center">
                <div className="mb-2">
                  <CustomSwitch
                    options={switchOption}
                    value={showInRight}
                    handleSwitch={handleSwitch}
                  />
                </div>
                <div className="min-h-full">
                  {showInRight !== "preview" ? (
                    <SelectableCustomers
                      selectedCustomer={selectedCustomers}
                      setSelectedCustomer={setSelectedCustomers}
                      customersFrom="group"
                      selectedGroupId={selectedGroupId}
                      setSelectedGroupId={setSelectedGroupId}
                    />
                  ) : (
                    <TemplatePreview
                      header={watch("header")}
                      footer={watch("footer")}
                      buttons={templateButtons}
                      content={formateMessageInput(
                        watch("message"),
                        "preview",
                        tempVariables
                      )}
                      videoPreview={videoPreview}
                      documentPreview={documentPreview}
                      imagePreview={imagePreview}
                      file={file}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
