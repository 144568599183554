// Redux Slice for AI Service
import axiosInstance from "./axios.instance";

// Async actions for AI Slice
export const API_REGISTER_COMPANY = async (formData) => {
  try {
    const response = await axiosInstance.post("/ai/register", formData);
    return response.data;
  } catch (error) {
    console.log("Error in API_REGISTER_COMPANY:", error);
    throw error;
  }
};


export const API_CHECK_COMPANY_REGISTRATION = async () => {
  try {
    const response = await axiosInstance.get("/ai/company");
    return response.data;
  } catch (error) {
    console.log("Error in API_CHECK_COMPANY_REGISTRATION:", error);
    throw error;
  }
};
export const API_UPDATE_COMPANY = async (updates) => {
  try {
    const response = await axiosInstance.put("/ai/company", updates);
    return response.data;
  } catch (error) {
    console.log("Error in API_UPDATE_COMPANY:", error);
    throw error;
  }
};
export const API_STOP_AI_SERVICE = async () => {
  try {
    const response = await axiosInstance.delete("/ai/stop");
    return response.data;
  } catch (error) {
    console.log("Error in API_STOP_AI_SERVICE:", error);
    throw error;
  }
};

export const API_START_AI_SERVICE = async (form) => {
  try {
    const response = await axiosInstance.post("/ai/start", form);
    return response.data;
  } catch (error) {
    console.log("Error in API_START_AI_SERVICE:", error);
    throw error;
  }
};
