import React, { useEffect, useState } from "react";
import { SelectButton } from "primereact/selectbutton";
import { Text } from "../../../components/Text";
import { Checkbox } from "primereact/checkbox";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getNoticationpref,
  getNoticationprefid,
  setNoticationpref,
  setNoticationprefbulk,
} from "../../../reducers/notificationSlice";
import modulesName from "../../../roles/modulesName";
import permissions from "../../../roles/permissions";
function Notification() {
  const options = ["Off", "On"];
  const [noti_fication, setNotOptions] = useState(options[0]);
  const [notification, setNotification] = useState({ order: [], enquires: [] });
  const [person, setPerson] = useState([]);
  const [flag, setFlag] = useState(false); //api call flag
  const onNotificationChange = (e) => {
    let _notification = [...notification[e.target.name]];
    if (e.checked) _notification.push(e.value);
    else _notification.splice(_notification.indexOf(e.value), 1);
    setNotification({ ...notification, [e.target.name]: _notification });
    setFlag(true);
  };

  const onPersonChange = (e) => {
    let _person = [...person];
    if (e.checked) _person.push(e.value);
    else _person.splice(_person.indexOf(e.value), 1);
    setPerson(_person);
    setNotOptions(_person.length === 0 ? "Off" : "On");
    setFlag(true);
  };
  const { specificnotificationdata, notificationdata } = useSelector(
    (state) => state.notification
  );
  const { user, accesskey } = useSelector((state) => state.authenticate);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNoticationpref({}))
      .unwrap()
      .then((x) => {
        let noti_fication = x.rows;
        var access = { order: [], enquires: [] };
        if (noti_fication) {
          if (noti_fication.length > 0) {
            let _person = noti_fication
              ?.filter((x) => x.on === true)
              ?.map((x) => {
                for (let [i] in modules) {
                  access[modules[i]?.value] = x[modules[i]?.value];
                }
                return x.id;
              });
            setNotification(access);
            setPerson([..._person]);
            setNotOptions(_person?.length > 0 ? options[1] : options[0]);
          }
        }
      });
  }, []);

  const modules = [
    {
      id: 1,
      name: "Enquiry",
      value: "enquires",
      access: accesskey[modulesName.ENQUIRES]?.includes(permissions.READ),
    },
    {
      id: 2,
      name: "Order",
      value: "order",
      access: accesskey[modulesName.ORDER]?.includes(permissions.READ),
    },
  ];

  useEffect(() => {
    var access = { order: [], enquires: [] };
    if (notificationdata) {
      if (notificationdata.length > 0) {
        let _person = notificationdata
          ?.filter((x) => x.on === true)
          ?.map((x) => {
            for (let [i] in modules) {
              access[modules[i]?.value] = x[modules[i]?.value];
            }
            return x.id;
          });
        setNotification(access);
        setPerson([..._person]);
        setNotOptions(_person?.length > 0 ? options[1] : options[0]);
      }
    }
  }, [notificationdata]);

  const updateNotificationsetting = () => {
    if (notificationdata?.length > 0 && user?.id) {
      let preference = notificationdata?.map((x) => {
        return {
          ...x,
          id: x.id,
          on: person.includes(x.id) && noti_fication === "On",
          ...notification,
        };
      });
      dispatch(
        setNoticationprefbulk({
          data: { preference },
        })
      );
    }
  };

  useEffect(() => {
    if (notificationdata && flag) {
      updateNotificationsetting();
      setFlag(false);
    }
  }, [flag]);

  const turnNotification = (e) => {
    setFlag(true);
    setPerson([]);
    setNotOptions(e.value);
  };
  return (
    <div className="mt-3">
      <Text type={"heading"}>Notifications</Text>
      {/* <Text type={"small-text"} cust="mt-1 " style={{marginBottom:"15px"}}>Send notifications to my personal mobile number</Text> */}
      <div className="flex flex-wrap mt-2 gap-3">
        {notificationdata?.length &&
          notificationdata?.map((x, key) => (
            <div key={key} className="flex align-items-center">
              <Checkbox
                name="agent"
                value={x?.id}
                onChange={onPersonChange}
                checked={person.includes(x?.id)}
              />
              <label className="ml-2 mb-1">{x?.userDetails?.userName}</label>
            </div>
          ))}
      </div>
      <Text type={"small-text"} cust="mb-2 mt-2">
        Choose type of notifications that you want to receive:
      </Text>
      <table>
        <tbody>
          {modules
            ?.filter((x) => x?.access)
            ?.map((module, key) => (
              <tr className="flex flex-row align-items-center" key={key}>
                <td className=" center mr-2">
                  <Checkbox
                    className="flex w-full justify-content-center mt-1"
                    name={module?.value}
                    value={`add_${module?.value}`}
                    onChange={onNotificationChange}
                    checked={notification[module?.value]?.includes(
                      `add_${module?.value}`
                    )}
                  />
                </td>
                <td className="center ml-2" style={{ paddingBottom: "1px" }}>
                  {module.name}
                </td>
                {/* <td className="center ">
                <Checkbox className="flex w-full justify-content-center"  name={module?.value} value={`update_${module?.value}`} onChange={onNotificationChange} checked={notification[module?.value]?.includes(`update_${module?.value}`)} />
              </td>
              <td className="center ">
                <Checkbox className="flex w-full justify-content-center"  name={module?.value} value={`delete_${module?.value}`} onChange={onNotificationChange} checked={notification[module?.value]?.includes(`delete_${module?.value}`)} />
              </td> */}
              </tr>
            ))}
        </tbody>
      </table>
      <div style={{ margin: "15px 0" }}>
        <span className="">
          <SelectButton
            unselectable={false}
            value={noti_fication}
            defaultValue={"Off"}
            onChange={turnNotification}
            options={options}
            className={`flex btn-notification ${
              noti_fication === "Off" ? "off-btn" : "on-btn"
            } `}
          />
        </span>
      </div>
    </div>
  );
}

export default Notification;
