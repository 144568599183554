import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../../components/CustomButton";
import { Text } from "../../../components/Text";
import { ReactComponent as Edit } from "../../../svg/edit.svg";
import EllipsisText from "../../../components/common/EllipsisText/indix";
function QNoteCard({ data }) {
  const navigate = useNavigate();
  return (
    <div className="w-full">
      <div className="mr-3">
        <Text type={"heading"}>{data?.title}</Text>
        <div
          className="w-full my-2 p-2 relative"
          style={{ border: "1px solid rgba(0, 0, 0, 0.12)", height: "100px" }}
        >
          <div className="w-10">
            <Text
              type={"small-text"}
              style={{ lineHeight: "21px", whiteSpace: "pre-line" }}
            >
              <EllipsisText className={'h-auto max-h-full'}  text={data?.notes} />
            </Text>
          </div>
          <div className="absolute" style={{ top: "0.4rem", right: "0.5rem" }}>
            <Edit
              className="cursor-pointer"
              onClick={() => navigate(`/account/quick-notes/${data.id}`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default QNoteCard;
