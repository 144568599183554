import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { CustomButton } from "../CustomButton";
import { classNames } from "primereact/utils";
import { InputNumber } from "primereact/inputnumber";
import "./formStyle.css";
import { CustomImageInput } from "../CustomImageInput";
import { ReactComponent as ImagePlaceholder } from "../../svg/ImgPlaceholder.svg";
import { InputTextarea } from "primereact/inputtextarea";
import * as Messag from "../../config/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../api/axios.instance";
import { changeShowNotice } from "../../reducers/appSlice";
import { Text } from "../Text";
import { ReactComponent as Cross } from "../../svg/cross.svg";
import {
  addProduct,
  resetMode,
  resetSelectedProduct,
  updateProduct,
} from "../../reducers/productTableSlice";
import { sortAlphabeticalObjectArr } from "../../utils/tableUtils";

import { Image } from "primereact/image";

const statusOption = [
  { key: "Available", value: "Available" },
  { key: "Unavailable", value: "Unavailable" },
];

export const AddNewProduct = ({
  setDisplayAddProductModule,
  displayAddProductModule,
  toast,
}) => {
  const [categories, setCategories] = useState([]);
  const [selectedImage, setSeletedImage] = useState(null);

  const dispatch = useDispatch();
  const { mode, selectedProduct } = useSelector((state) => state.productTable);

  const handleImg = (img) => {
    setSeletedImage(img);
  };

  const onHide = (reset) => {
    dispatch(resetSelectedProduct());
    dispatch(resetMode());
    setDisplayAddProductModule(false);
    setSeletedImage(null);
    reset();
  };

  const defaultValues = {
    productName: "",
    categoryId: "",
    quantity: undefined,
    price: undefined,
    status: "",
    SKUCode: "",
    desc: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues });

  //function form get error message
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const typechecker = (selectedImage) => {
    if (
      selectedImage.type === "image/png" ||
      selectedImage.type === "image/jpeg"
    ) {
      return true;
    }
    return false;
  };

  const onSubmit = (data) => {
    if (selectedImage === null) {
      toast.current.show({
        severity: "error",
        detail: "Please select an image.",
      });
      return;
    }
    if (typechecker(selectedImage) || typeof selectedImage === "string") {
      if (selectedImage.size > 8000000) {
        toast.current.show({
          severity: "error",
          detail: "Image size should be less than 8 MB.",
        });
        return;
      }
    } else {
      toast.current.show({
        severity: "error",
        detail: "Please select .jpg or .png format image.",
      });
      return;
    }

    if (mode === "update") {
      const productId = selectedProduct.id;

      dispatch(updateProduct({ productId, data, selectedImage }))
        .unwrap()
        .then((res) => {
          //show toast here
          dispatch(changeShowNotice(true));
          let Message_Success = Messag.Update_Product_ToastSuccessMessage;
          toast.current.show({ severity: "success", detail: Message_Success });
          setDisplayAddProductModule(false);
        })
        .catch((err) => {
          //show toast here
          // console.log(err.response);
          toast.current.show({ severity: "error", detail: err.response.data });
        });
    } else {
      data = { ...data, isActive: 1 };
      dispatch(addProduct({ data, selectedImage }))
        .unwrap()
        .then(() => {
          setDisplayAddProductModule(false);
          dispatch(changeShowNotice(true));
          let Message_Success = Messag.Add_Product_ToastSuccessMessage;
          toast.current.show({ severity: "success", detail: Message_Success });
        })
        .catch((err) => {
          toast.current.show({ severity: "error", detail: err.message });
        });
    }
  };
  const imageBodyTemplate = () => {
    const d = new Date();
    if (mode == "update" && selectedImage && selectedImage.length > 2) {
      return (
        <img
          src={`${selectedImage}?v=${d.getTime()}`}
          onError={(e) => (e.target.src = "./images/ImgPlaceholder.svg")}
          style={{ width: "100px" }}
        />
      );
    } else
      return selectedImage ? (
        <div style={{ maxWidth: "120px", height: "100px" }}>
          <Image
            src={`${URL.createObjectURL(selectedImage)}`}
            onError={(e) => (e.target.src = "./images/ImgPlaceholder.svg")}
            width="100%"
            height="100%"
          />
        </div>
      ) : (
        <ImagePlaceholder width={100} />
      );
  };
  useEffect(() => {
    if (mode == "update" && selectedProduct) {
      setValue("productName", selectedProduct.productName);
      setValue("categoryId", selectedProduct.categoryId);
      setValue("quantity", selectedProduct.quantity);
      setValue("price", selectedProduct.price);
      setValue("status", selectedProduct.status);
      setValue("SKUCode", selectedProduct.SKUCode);
      setValue("desc", selectedProduct.desc);
      handleImg(selectedProduct.url);
    }
    //fetch all category list for dropdown
    axiosInstance
      .get(`/category?page=0&limit=100000&isActive=1`)
      .then((resp) => {
        let sortedCateGories = sortAlphabeticalObjectArr(
          resp.data.rows,
          "categoryName"
        );
        setCategories(sortedCateGories);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <Dialog
      header={
        <Text type={"heading"}>
          <span
            style={{
              textDecorationLine: "underline",
              textDecorationStyle: "dashed",
            }}
          >
            {mode === "update" ? "Update Product" : "Add New Product"}
          </span>
        </Text>
      }
      visible={displayAddProductModule}
      onHide={() => onHide(reset)}
      className="dialog-custom"
    >
      <div className={`card`}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="p-fluid"
          encType="multipart/form-data"
        >
          <div className="field">
            <label htmlFor="productName">Name *</label>
            <Controller
              name="productName"
              control={control}
              rules={{ required: "Product name is required." }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  maxLength={24}
                  {...field}
                  placeholder="Name of the product"
                  className={classNames({ "p-invalid": fieldState.invalid })}
                />
              )}
            />
            {getFormErrorMessage("productName")}
          </div>
          <div className="field">
            <label htmlFor="SKUCode">SKU Code *</label>
            <Controller
              name="SKUCode"
              control={control}
              rules={{
                required: "SKU Code is required.",
                pattern: {
                  value: /^[a-zA-Z0-9-]*$/,
                  message:
                    "Only alphanumeric characters without white spaces are allowed.",
                },
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  maxLength={24}
                  value={field.value}
                  {...field}
                  placeholder="Enter SKUCode"
                  className={classNames({ "p-invalid": fieldState.invalid })}
                  disabled={mode === "update" ? true : false}
                  onChange={(e) =>
                    field.onChange(
                      e.target.value.trim().split(" ").join("").toUpperCase()
                    )
                  }
                />
              )}
            />
            {getFormErrorMessage("SKUCode") || (
              <small>* Only alphanumeric characters are allowed.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="categoryId">Category *</label>
            <Controller
              name="categoryId"
              control={control}
              rules={{ required: "Please select a category." }}
              render={({ field, fieldState }) => (
                <Dropdown
                  id={field.name}
                  options={categories}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  optionLabel="categoryName"
                  optionValue="id"
                  placeholder="Choose"
                  className={classNames({ "p-invalid": fieldState.invalid })}
                />
              )}
            />
            {getFormErrorMessage("categoryId")}
          </div>
          <div className="field">
            <label htmlFor="status">Status *</label>
            <Controller
              name="status"
              control={control}
              rules={{ required: "Status is required." }}
              render={({ field, fieldState }) => (
                <Dropdown
                  id={field.name}
                  options={statusOption}
                  optionLabel="key"
                  optionValue="value"
                  placeholder="Choose"
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  className={classNames({ "p-invalid": fieldState.invalid })}
                />
              )}
            />
            {getFormErrorMessage("status")}
          </div>
          <div className="field">
            <label htmlFor="quantity">Quantity *</label>
            <Controller
              name="quantity"
              control={control}
              rules={{ required: "Quantity is required." }}
              render={({ field, fieldState }) => (
                <InputNumber
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  useGrouping={false}
                  placeholder="Enter Quantity"
                  className={classNames({ "p-invalid": fieldState.invalid })}
                />
              )}
            />
            {getFormErrorMessage("quantity")}
          </div>
          <div className="field">
            <label htmlFor="price">Price per unit (optional)</label>
            <Controller
              name="price"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  useGrouping={false}
                  mode="currency"
                  currency="INR"
                  currencyDisplay="code"
                  locale="en-IN"
                  placeholder="Enter Price"
                  className={classNames({ "p-invalid": fieldState.invalid })}
                />
              )}
            />
            {getFormErrorMessage("price")}
          </div>
          <div className="field">
            <label htmlFor="desc">Description (optional) </label>
            <br />
            <small>* It must have upper and lower case characters</small>
            <Controller
              name="desc"
              control={control}
              rules={{
                required: false,
                pattern: {
                  value: /[a-z]/,
                  message:
                    "Description must have upper and lower case characters",
                },
              }}
              render={({ field, fieldState }) => (
                <InputTextarea
                  className="mt-1"
                  id={field.name}
                  rows={3}
                  cols={30}
                  {...field}
                  autoResize
                  placeholder="Product description ....."
                />
              )}
            />
            {getFormErrorMessage("desc")}
          </div>
          <div className="field">
            <div className="flex justify-content-between  align-items-center gap-2">
              <div className="flex justify-content-between align-items-center gap-3">
                {imageBodyTemplate()}
                <div className="flex justify-content-center align-items-center gap-2">
                  <Text type={"sub-heading"}>
                    {selectedImage ? (
                      <>
                        <span style={{ maxWidth: "300px" }}>
                          {selectedImage.name}
                        </span>
                        <span
                          className="ml-4 cursor-pointer text-3xl"
                          onClick={() => handleImg(null)}
                        >
                          <Cross />
                        </span>
                      </>
                    ) : (
                      <span>No File Choosen*</span>
                    )}
                  </Text>
                </div>
              </div>
              <div className="min-w-max">
                <CustomImageInput
                  setSelectedImage={handleImg}
                  label="Choose File"
                />
              </div>
            </div>
          </div>

          <div className="flex gap-2">
            <CustomButton
              varient="filled"
              type="submit"
              label={!mode ? "Save" : "Update"}
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
};
