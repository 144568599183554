import { useNavigate,useParams } from "react-router";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { CustomButton } from "../../components/CustomButton";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import { Text } from "../../components/Text";
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "../../components/common/CustomDropdown";
import CustomInputText from "../../components/common/CustomInputText";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useRef } from "react";
import {
  addCategory,
  changeMode,
  getCategoriesbyId,
  resetMode,
  resetSelectedCategory,
  updateCategory,
} from "../../reducers/categoryTableSlice";
import Loader from "../../components/Loader";
import { getCampaignById } from "../../reducers/campaignSlice";
import CustomDialog from "../../components/CustomDialog";
import {ReactComponent as InfoIcon} from "../../assets/info.svg"
import { useLayout } from "../../context/LayoutContext";
import CustomSaveModel from "../../components/CustomeSaveModel";
import CONSTANTS from "../../config/Constants"
import * as Messag from '../../config/ToastMessage';
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import { useUtility } from "../../context/UtilityContext";

function NewCategory() {
  const navigate = useNavigate();

  const statusOption = [
    { key: "Available", value: "Available" },
    { key: "Unavailable", value: "Unavailable" },
  ];
  const defaultValues = {
    categoryName: "",
    status: "",
  };
  const { mode, selectedCategory, loading } = useSelector(
    (state) => state.categoryTable
  );
  const { accesskey } = useSelector(state => state.authenticate);

  const breadcumbHome = { label: "Categories", url: "/categories/list" };
  
  const dispatch = useDispatch();
  const {toast,setLoading} = useLayout();
  //function form get error message
  useEffect(()=>{
    setLoading(loading);
  },[loading])
  const getFormErrorMessage = (name) => {
    return (
      errors[name]?<small className="p-error">{errors[name].message}</small>:<small></small>
    );
  };
  const {setAlert,navigation}=useUtility();
    
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    if (mode === "update"||id) {
      const categoryId = selectedCategory?.id?selectedCategory?.id:id;
      dispatch(updateCategory({ categoryId, data }))
        .unwrap()
        .then((res) => {
          let Message_Success = Messag.Update_Cat_ToastSuccessMessage;
          toast.current.show({
            severity: "success",
            detail: Message_Success,
          });
          navigate(`/categories`)

        })
        .catch((err) => {
          toast.current.show({ severity: "error", detail: err.response.data });
        });
    } else {
      dispatch(addCategory(data))
        .unwrap()
        .then((res) => {
          let Message_Success = Messag.Add_Cat_ToastSuccessMessage;
          toast.current.show({
            severity: "success",
            detail: Message_Success,
          });
          navigate(`/categories`)
        })
        .catch((err) => {
          toast.current.show({ severity: "error", detail: err.message });
        });
    }
    setShowSave(false)
  };
  const {id} = useParams()
  useEffect(() => {
    if (id) {
      dispatch(getCategoriesbyId({categoryId:id})).then((e)=>{
      })
    }
    return () => {
      dispatch(resetSelectedCategory());
    };
  }, [id]);
  useEffect(()=>{
    return()=>{
      setAlert(false);
    }
  },[])
  const setForm=()=>{
    if (mode === "update" && selectedCategory) {
      setValue("categoryName", selectedCategory.categoryName);
      setValue("status", selectedCategory.status);
    }
    if(id&&selectedCategory){
      dispatch(changeMode("update"))
      setValue("categoryName", selectedCategory.categoryName,{shouldValidate:true});
      setValue("status", selectedCategory.status,{shouldValidate:true});
    }
  }
  useEffect(() => {
    setForm();
  }, [selectedCategory]);
  const breadcumbList = [
    { label: `${id ? "Update" : "New"} Category`, url: "#" },
  ];

  const alertText = () => {
    return <div>{CONSTANTS.CATEGORY_SAVE_CONFIMATION}</div>
  }
  const [showSave,setShowSave]=useState(false)
    const SaveModel=()=>{
      return (
        <CustomSaveModel
            showSave={showSave}
            dialogFooter={dialogFooter}
            header={alertText()}
            onHide={onHide}
        />
        )
    }
  const dialogFooter = () => {
    return (
      <div className='flex justify-content-end '>
        <CustomButton
          label='Cancel'
          varient='cancel'
          autofocus={true}
          onClick={()=>{ setShowSave(false) } }
        />
        <CustomButton
          label='Confirm'
          varient='filled'
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    )
  }
  const onHide = () => {
    setShowSave(false)
  }
  return (
    <div>
      <Loader visible={loading} />
      <CustomBreadcrumb home={breadcumbHome} itemslist={breadcumbList} />
      {showSave&&<SaveModel/>}
      <div className="m-3">
        <TableActionsContainer>
          <div className="hidden sm:hidden md:flex  justify-content-between align-items-center gap-3 flex-wrap">
            <Text type={"heading"}>Categories Information</Text>
            <div className="flex gap-2">
              <CustomButton
                varient="text"
                label={"Cancel"}
                onClick={() => navigation("/categories/list")}
              />
              <CustomButton
                varient="outline"
                label={"Reset"}
                onClick={() => {id?setForm():reset()}}
              />
              {accesskey[modulesName.CATEGORY]?.includes(`${mode == "update" ? permissions.UPDATE : permissions.CREATE}`)&&<CustomButton
                varient="filled"
                label={mode == "update" ? "Update" : "Save"}
                onClick={handleSubmit(()=>{ setShowSave(true) })}
              />}
            </div>
          </div>
           {/* ***** For Mobile Screens ***** */}
          <div className="hidden sm:flex md:hidden flex flex-column justify-content-between align-items-start gap-3 flex-wrap ">
          <div className="flex justify-content-start" >
            <Text type={"heading"}>Categories Information</Text>
            </div>
            <div className="flex justify-content-end  w-full "  >
            <div className="flex gap-3">
              <CustomButton
                varient="text"
                label={"Cancel"}
                onClick={() => navigation("/categories/list")}
              />
              <CustomButton
                varient="outline"
                label={"Reset"}
                onClick={() => {id?setForm():reset()}}
              />
              {accesskey[modulesName.CATEGORY]?.includes(`${mode == "update" ? permissions.UPDATE : permissions.CREATE}`)&&<CustomButton
                varient="filled"
                label={mode == "update" ? "Update" : "Save"}
                onClick={handleSubmit(()=>{ setShowSave(true) })}
              />}
            </div>
            </div>
          </div>
        </TableActionsContainer>
      </div>
      <div className="m-3 p-3 form-container">
        <div className="flex gap-3  align-items-center flex-wrap">
          <div className="w-full lg:w-3 xl:w-3 md:w-4 ">
            <CustomInputText
              label="Name "
              name="categoryName"
              control={control}
              placeholer="Enter category name"
              required={true}
              onChangealert={true}
            />
            {getFormErrorMessage("categoryName")}
          </div>
          <div className="w-full lg:w-3 xl:w-3 md:w-4">
            <CustomDropdown
              label="Status"
              name="status"
              control={control}
              options={statusOption}
              optionLabel="key"
              optionValue="value"
              placeholder="Choose"
              isMandatory={true}
              onChangealert={true}
            />
            {getFormErrorMessage("status")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewCategory;
