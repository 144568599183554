import { useCallback, useState } from "react";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { handleInputType } from "../../../reducers/multiInputSlice";
import {
  addEdgeToStore,
  addNodeToStore,
  updatePostbackText,
} from "../../../reducers/canvas/canvasSlice";
import { v4 as uuidv4 } from "uuid";
import {
  getEdgeDataWithOptionId,
  getNextNodePosition,
  isValidToConnectNewNode,
} from "../../../utils/canvasUtils";
import { interactiveNodes, nodeTypes } from "./node-constant";
import { Image } from "primereact/image";

const isSelectedNodeInteractive = (node) => {
  return node && interactiveNodes.includes(node.type);
};

const NodeOptions = ({ onHide = () => {}, toast = null }) => {
  const dispatch = useDispatch();
  const { nodes, edges, selectedNode } = useSelector((state) => state.canvas);

  const addNewEdge = (parentId, newNodeId) => {
    let edge = {};
    let isInteractionalSource = isSelectedNodeInteractive(selectedNode);

    if (isInteractionalSource) {
      let { edgeData, optionId } = getEdgeDataWithOptionId(
        selectedNode.data?.options,
        selectedNode.type
      );

      if (optionId) {
        edge["label"] = edgeData.label;
        edge["optionId"] = optionId;
      }

      dispatch(
        updatePostbackText({
          selectedNodeId: selectedNode.id,
          optionId,
          targetNodeId: newNodeId,
        })
      );
    }

    if (parentId) {
      const edgeId = `${parentId}-${newNodeId}`;
      edge = { ...edge, id: edgeId, source: parentId, target: newNodeId };
      dispatch(addEdgeToStore(edge));
    }
  };

  const addNewNode = useCallback(
    (parentId, _inputType) => {
      let nodeId = uuidv4();

      let newNode = {
        id: nodeId,
        position: getNextNodePosition(nodes, selectedNode?.id),
        data: {
          label: "",
          type: _inputType,
        },
        type: "text",
      };

      if (["assignAgent", "addressInfo", "shareCatalog"].includes(_inputType)) {
        newNode.data["actions"] = [_inputType];
      }

      addNewEdge(parentId, nodeId);
      dispatch(addNodeToStore(newNode));
    },
    [nodes]
  );

  const addNewQuickReplyNode = useCallback(
    (parentId) => {
      let nodeId = uuidv4();

      const newNode = {
        id: nodeId,
        position: getNextNodePosition(nodes, selectedNode?.id),
        data: {
          label: "",
          type: "text",
          options: [],
        },
        type: "quick_reply",
      };

      addNewEdge(parentId, nodeId);
      dispatch(addNodeToStore(newNode));
    },
    [nodes]
  );

  const addNewListNode = useCallback(
    (parentId) => {
      let nodeId = uuidv4();
      const newNode = {
        id: nodeId,
        position: getNextNodePosition(nodes, selectedNode?.id),
        data: {
          body: "",
          listHeading: "",
          globalButton: "",
          type: "list",
          options: [],
        },

        type: "list",
      };

      addNewEdge(parentId, nodeId);
      dispatch(addNodeToStore(newNode));
    },
    [nodes]
  );

  const addNewHttpNode = useCallback(
    (parentId) => {
      let nodeId = uuidv4();
      const newNode = {
        id: nodeId,
        position: getNextNodePosition(nodes, selectedNode?.id),
        data: {
          url: "",
          method: "",
          headers: "",
          params: "",
          body: "",
          type: "http",
        },

        type: "http",
      };
      addNewEdge(parentId, nodeId);
      dispatch(addNodeToStore(newNode));
    },
    [nodes]
  );

  const addTemplateNode = useCallback(
    (parentId) => {
      let nodeId = uuidv4();
      const newNode = {
        id: nodeId,
        position: getNextNodePosition(nodes, selectedNode?.id),
        data: {
          type: "template",
          templateId: null,
          options: [],
          content: {
            header: "",
            data: "",
          },
          variables: [],
        },
        type: "template",
      };

      addNewEdge(parentId, nodeId);
      dispatch(addNodeToStore(newNode));
    },
    [nodes]
  );

  const addPatternNode = useCallback(
    (parentId, nodeType) => {
      let nodeId = uuidv4();
      const newNode = {
        id: nodeId,
        position: getNextNodePosition(nodes, selectedNode?.id),
        data: {
          type: nodeType,
          label: "",
        },
        type: nodeType,
      };

      addNewEdge(parentId, nodeId);
      dispatch(addNodeToStore(newNode));
    },
    [nodes]
  );

  const handleAddNewNode = (newNodeType) => {
    onHide();

    let validToConnect = isValidToConnectNewNode(selectedNode);

    if (!validToConnect) {
      let warningMessage = "";
      switch (selectedNode?.type) {
        case "list":
          warningMessage = "Please add a new 'Option' to connect more node.";
          break;
        case "quick_reply":
          warningMessage = "Please add a new 'Button' to connect more node.";
          break;
        case "template":
          warningMessage =
            "This Template node does't have any 'Button' to connect more node. Please select other template.";
          break;
        default:
          warningMessage = "";
          break;
      }
      toast?.current.show({
        severity: "warn",
        detail: warningMessage,
        life: 3000,
      });
      return;
    }
    let sourceNodeId = selectedNode?.id;

    const edgeData = edges.find((edge) => edge.source === sourceNodeId);
    if (edgeData?.target && !interactiveNodes.includes(selectedNode.type)) {
      toast.current.show({
        severity: "warn",
        detail: "More than one node can't be added to this node!!!",
      });
      return;
    }

    switch (newNodeType) {
      case "quick_reply":
        addNewQuickReplyNode(sourceNodeId);
        break;
      case "list":
        addNewListNode(sourceNodeId);
        break;
      case "http":
        addNewHttpNode(sourceNodeId);
        break;
      case "template":
        addTemplateNode(sourceNodeId);
        break;
      case "pattern":
        addPatternNode(sourceNodeId, newNodeType);
        break;
      default:
        addNewNode(sourceNodeId, newNodeType);
    }

    dispatch(handleInputType(newNodeType));
  };

  return (
    <div className={styles.options}>
      {nodeTypes.map((option, idx) => (
        <button
          key={idx}
          className={`flex justify-content-start align-items-center gap-3 mt-2 cursor-pointer ${styles.node_button}`}
          onClick={() => handleAddNewNode(option?.value)}
        >
          {option?.icon && (
            <Image src={option?.icon} alt="icon" className="mx-2" />
          )}

          {option?.name}
        </button>
      ))}
    </div>
  );
};

export default NodeOptions;
