import { Checkbox } from "primereact/checkbox";
import { useSelector, useDispatch } from "react-redux";
import { changeRootStepId } from "../../../reducers/canvas/canvasSlice";
import { Text } from "../../../components/Text";
const StartNodeCheckMark = () => {
  const { selectedNode, rootStepId } = useSelector((state) => state.canvas);

  const dispatch = useDispatch();
  const handleRootNode = (nodeId) => {
    if (nodeId !== rootStepId) {
      dispatch(changeRootStepId(nodeId));
    } else {
      dispatch(changeRootStepId(null));
    }
  };
  const isRootNode = selectedNode?.id === rootStepId;
  return (
    <div className="mt-3 card flex justify-content-start">
      <Checkbox
        inputId="rootnode_check"
        onChange={() => handleRootNode(selectedNode?.id)}
        checked={isRootNode}
      ></Checkbox>
      <label htmlFor="rootnode_check" className="ml-2">
        <Text type={"sub-heading"}>Mark as Start Node.</Text>
      </label>
    </div>
  );
};

export default StartNodeCheckMark;
