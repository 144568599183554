import { useEffect } from "react";
import { Text } from "../../components/Text";
import "./index.css";
import { ReactComponent as UserIcon } from "../../assets/svgIcons/UserIcon.svg";
import { ReactComponent as RupeeIcon } from "../../assets/svgIcons/RupeeIcon.svg";
import CustomPaginator from "../../components/CustomPaginator";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPayments,
  changePage,
  changelimit,
} from "../../reducers/paymentHistorySlice";
import Loader from "../../components/Loader";
import { getDate } from "../../utils/datemaker";
import { getCompany, setCompany } from "../../reducers/companySlice";
import { subscription } from "../../reducers/authSlice";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";

const PaymentHistory = () => {
  const { paymentHistory, totalPaymentHistoryCount, loading, page, limit } =
    useSelector((state) => state.paymentHistory);

  const { user, userSub } = useSelector((state) => state.authenticate);

  const { company } = useSelector((state) => state.company);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPayments({ page, limit }))
      .unwrap()
      .catch((err) => {
        //show toast here
      });
  }, [page, limit]);

  useEffect(() => {
    if (user.companyId) {
      dispatch(subscription(user.companyId)).then((data) => {});
    }
    dispatch(getCompany(user.companyId))
      .unwrap()
      .then((data) => {
        if (data.company) {
          dispatch(setCompany(data.company));
        }
      });
  }, []);

  const modalLoad = () => {
    return <Loader visible={loading} />;
  };

  return (
    <>
      {modalLoad()}
      <div className="w-full py-3 px-4 m-auto">
        <div className="mb-2">
          <Text type="heading">Payment History</Text>
        </div>
        <div
          className="flex align-items-center mb-3 pb-2"
          style={{ borderBottom: "0.5px dashed #000" }}
        >
          <UserIcon />
          <div className="flex flex-column align-items-start sm:flex-row sm:justify-content-between sm:align-items-center w-12">
            <div className="flex align-items-center mb-2">
              <div className="ml-3">
                <Text type="heading">{company?.companyName}</Text>
              </div>
            </div>
            <div className="flex align-items-center mb-2">
              <div className="ml-3 sm:ml-0">
                <Text type="heading">
                  Subscription Type :{" "}
                  {userSub.isUserSub ? userSub.userSubType : "none"}
                  {userSub.isUserSub ? `(${userSub.plan})` : ""}
                </Text>
              </div>
            </div>
          </div>
        </div>

        {paymentHistory.length === 0 ? (
          <Text type={"heading"}>No payment history available</Text>
        ) : (
          <div className="flex flex-column">
            {paymentHistory.map((payment, index) => (
              <div key={index} className="topup-container mt-2 mb-2 p-2 h-auto">
                <div className="mb-2">
                  <Text type={"sub-heading"} color={"#1A1A1A"}>
                    {payment.topuptype ? "Top Up" : "Subscription"}
                  </Text>
                </div>
                <div className="flex sm:gap-3">
                  <div className="flex align-items-center w-3 sm:w-2">
                    <div className="big-text">
                      {payment.amount
                        ? payment.amount
                        : payment.subscriptiontype.amount}{" "}
                    </div>
                    <RupeeIcon />
                  </div>
                  <div className="text-conversation flex align-items-center px-2 md:px-0 ">
                    {payment.topuptype
                      ? payment.topuptype.totalConversation
                      : payment.subscriptiontype.totalConversation}{" "}
                    Conversation
                  </div>
                </div>
                <div className="flex flex-column sm:flex-row sm:gap-3 ">
                  <div className="flex align-items-center w-12 mt-2 sm:w-4 md:w-2">
                    <div className="mr-2 w-3 sm:w-auto">
                      <Text type={"sub-heading"} color={"rgba(0, 0, 0, 0.5)"}>
                        Status
                      </Text>
                    </div>
                    <Text
                      type={"heading"}
                      color={
                        payment.status === "SUCCESS" ? "#4DAD01" : "#A00F30"
                      }
                    >
                      {payment.status}
                    </Text>
                  </div>
                  <div className="flex align-items-center w-12 mt-2 sm:w-4 md:w-2">
                    <div className="mr-2 w-3 sm:w-auto">
                      <Text type={"sub-heading"} color={"rgba(0, 0, 0, 0.5)"}>
                        Date
                      </Text>
                    </div>
                    <Text type={"sub-heading"}>
                      {getDate(payment.transactionDate)}
                    </Text>
                  </div>
                  <div className="flex align-items-center mt-2">
                    <div className="mr-2 w-3 sm:w-auto">
                      <Text type={"sub-heading"} color={"rgba(0, 0, 0, 0.5)"}>
                        Transaction ID
                      </Text>
                    </div>
                    <Text type={"sub-heading"}>{payment.transactionId}</Text>
                  </div>
                </div>
              </div>
            ))}
            <div className="mt-3">
              <TableActionsContainer>
                <div className="flex justify-content-end">
                  <CustomPaginator
                    page={page}
                    limit={limit}
                    changelimit={changelimit}
                    totalRecords={totalPaymentHistoryCount}
                    changePage={changePage}
                  />
                </div>
              </TableActionsContainer>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentHistory;
