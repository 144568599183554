import { Badge } from "primereact/badge";
function AgentName({ data }) {
  const shortusername = (a, y) => {
    return a
      ? a
          .split(" ")
          .slice(0, 2)
          .map((x) => {
            return x[0];
          })
          .join("")
          .toUpperCase()
      : y
          .split(" ")
          .slice(0, 2)
          .map((x) => {
            return x[0];
          })
          .join("")
          .toUpperCase();
  };

  const colorPairs = [
    { background: "#3498db", color: "#ffffff" }, // Blue background with white font
    { background: "#2ecc71", color: "#ffffff" }, // Green background with white font
    { background: "#e74c3c", color: "#ffffff" }, // Red background with white font
    { background: "#f39c12", color: "#ffffff" }, // Orange background with white font
    { background: "#9b59b6", color: "#ffffff" }, // Purple background with white font
    { background: "#1abc9c", color: "#ffffff" }, // Teal background with white font
    { background: "#34495e", color: "#ffffff" }, // Dark background with white font
    { background: "#ecf0f1", color: "#2c3e50" }, // Light background with dark font
    { background: "#95a5a6", color: "#ffffff" }, // Gray background with white font
    { background: "#d35400", color: "#ffffff" }, // Pumpkin background with white font
  ];
  
  const randomIndex = Math.floor(Math.random() * colorPairs.length);
  const selectedColorPair = colorPairs[randomIndex];
  return (
    <div className={`flex flex-row gap-2 cursor-pointer  align-items-center`}>
      <div className="relative">
        <Badge
          value={shortusername(data?.name, data?.userName)}
          className={`text-xs  border-circle`}
          style={{
            background: `${selectedColorPair['background']}`,
            color: `${selectedColorPair['color']}`,
          }}
          size={"large"}
        ></Badge>
      </div>

      <div className={`flex flex-column `}>
        <div className={`text-sm capitalize bold `} style={{ color: `#000` }}>
          {data?.name ? data?.name : data.userName}
        </div>
      </div>
    </div>
  );
}
export default AgentName;
