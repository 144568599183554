import { ONE_MB_IN_BYTES } from "../components/MultiInput/multiInput-constant";

function getNextNodePosition(nodeArr = [], selectedNodeId = null) {
  let position = {
    x: Math.random() * 100,
    y: Math.random() * 100,
  };

  if (!selectedNodeId) return position;
  else {
    let currNode = null;

    nodeArr.forEach((node) => {
      if (node.id == selectedNodeId) {
        currNode = { ...node };
      }
    });
    if (currNode == null) return position;
    else {
      position.x = currNode.position.x + 180 * 2;
      position.y = currNode.position.y + Math.floor(Math.random() * 100);
    }
  }
  return position;
}
function getTheNode(nodes, nodeId) {
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].id == nodeId) return nodes[i];
  }
  return null;
}

function getEdgeDataWithOptionId(options, type) {
  if (!options) return null;

  for (let i = 0; i < options.length; i++) {
    if (options[i].postbackText == "") {
      return {
        edgeData: {
          label: `${type == "quick_reply" ? "Button" : "Option"} ` + (i + 1),
          label: options[i]?.title || "Edge label ...",
        },
        optionId: options[i].id,
      };
    }
  }

  return null;
}

function isOptionAvailable(options) {
  if (!options) return false;

  for (let i = 0; i < options.length; i++) {
    if (!options[i].postbackText) return true;
  }
  return false;
}

function isValidToConnectNewNode(selectedNode) {
  if (!selectedNode) return true;
  let isValid = true;
  let { type } = selectedNode;
  switch (type) {
    case "list":
      isValid = isOptionAvailable(selectedNode.data?.options);
      break;
    case "quick_reply":
      isValid = isOptionAvailable(selectedNode.data?.options);
      break;
    case "template":
      isValid = isOptionAvailable(selectedNode.data?.options);
      break;
    default:
      isValid = true;
      break;
  }
  return isValid;
}

function isConnected(nodes, edges, rootStepId) {
  const adjacencyList = {};
  edges.forEach((edge) => {
    const { source, target } = edge;
    if (!adjacencyList[source]) {
      adjacencyList[source] = [];
    }
    adjacencyList[source].push(target);
  });

  // BFS
  const visited = new Set();
  const queue = [];

  const startNode = rootStepId;
  queue.push(startNode);
  visited.add(startNode);

  while (queue.length > 0) {
    const currentNode = queue.shift();
    adjacencyList[currentNode]?.forEach((neighbor) => {
      if (!visited.has(neighbor)) {
        visited.add(neighbor);
        queue.push(neighbor);
      }
    });
  }
  return visited.size === nodes.length;
}

function handleSequenceAndStepId(nodes, edges, rootStepId) {
  const adjacencyList = {};
  edges?.forEach((edge) => {
    const { source, target } = edge;
    if (!adjacencyList[source]) {
      adjacencyList[source] = [];
    }
    adjacencyList[source].push(target);
  });

  const visited = new Set();
  const queue = [];

  queue.push(rootStepId);
  visited.add(rootStepId);

  let nodesObject = [...nodes]; //  nodes.map((node) => ({ ...node })); // copy node

  while (queue.length > 0) {
    const currentNodeId = queue.shift();
    const currentNode = nodes.find((node) => node.id === currentNodeId);
    adjacencyList[currentNodeId]?.forEach((neighborId) => {
      if (!visited.has(neighborId)) {
        visited.add(neighborId);
        queue.push(neighborId);

        const neighborNode = nodesObject.find((node) => node.id === neighborId);
        if (
          !["quick_reply", "list"].includes(currentNode?.message?.type) &&
          !currentNode?.template
          //  &&
          // !currentNode?.pattern
        ) {
          const sourceInNodeObject = nodesObject.find(
            (node) => node.id === currentNodeId
          );

          neighborNode.stepId =
            sourceInNodeObject?.stepId || sourceInNodeObject.id;
          neighborNode.sequence = sourceInNodeObject.sequence + 1;
        }
      }
    });
  }

  return nodesObject;
}

function remodeIdFromNodeList(nodelist) {
  let nodeWithoutid = [];

  for (const node of nodelist) {
    const { id, ...newObj } = node;
    nodeWithoutid.push(newObj);
  }

  return nodeWithoutid;
}

const isOptionValid = (optList) => {
  for (const opt of optList) {
    if (!opt.title?.trim()) {
      return false;
    }
  }
  return true;
};

const isOptionValueValid = (optList) => {
  for (const opt of optList) {
    if (!opt.value?.trim()) {
      return false;
    }
  }
  return true;
};

const iSValidFileSize = (currFileSize, validFileSize) => {
  return currFileSize <= validFileSize * ONE_MB_IN_BYTES;
};
export {
  isOptionValid,
  getNextNodePosition,
  getTheNode,
  getEdgeDataWithOptionId,
  isValidToConnectNewNode,
  isConnected,
  handleSequenceAndStepId,
  remodeIdFromNodeList,
  iSValidFileSize,
  isOptionValueValid,
};
