import { InputTextarea } from "primereact/inputtextarea";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNodePayload } from "../../../reducers/canvas/canvasSlice";
import { getDataFromId } from "../../../utils/nodeUtils";

const Body = () => {
  const dispatch = useDispatch();
  const { selectedNode, nodes } = useSelector((state) => state.canvas);
  const [httpBody, setHttpBody] = useState("");

  const handleHttpBody = (e) => {
    setHttpBody(e.target.value);
    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { body: e.target.value },
      })
    );
  };

  useEffect(() => {
    if (selectedNode) {
      const selectedNodeData = getDataFromId(nodes, selectedNode?.id);
      setHttpBody(selectedNodeData.body);
    }
  }, [selectedNode]);

  return (
    <div className="mt-3">
      <InputTextarea
        className={`w-12`}
        rows={10}
        autoResize
        value={httpBody}
        placeholder="Enter payload here..."
        onChange={(e) => {
          handleHttpBody(e);
        }}
      />
    </div>
  );
};

export default Body;
