import React from "react";
import styles from "./styles.module.css";
import { useNavigate, useLocation } from "react-router-dom";
// import questionMarkSvg from "../../images/questionMark.svg";
import BasicPlan from "./BasicPlan";
import StandardPlan from "./StandardPlan";
import ProPlan from "./ProPlan";
import CustomPlan from "./CustomPlan";

const onbordinglistData = [
  "Onboarding fee covers Facebook page setup and verification authenticated by Meta and WhatsApp, Skalebot registration, and one-year maintenance.",
  `Unrestricted backend data uploads for customers.`,
  `Tailor business flow to specific scenarios.`,
  `Form multiple user groups with distinct categories.`,
  `Initiate business messages with pre-designed advertising template, and get a business website landing page from Skalebot.`
];

const PaymentPlan = () => {
  const navigate = useNavigate();
  const handleClickScroll = (idName) => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById(idName);
      if (element) {
        // Calculate the offset based on the header's height
        const header = document.querySelector(`.${styles.header}`);
        const headerHeight = header ? header.clientHeight : 0;
        const offset = element.offsetTop - (headerHeight + 80);

        window.scrollTo({
          top: offset,
          behavior: "smooth"
        });
      }
    }, 300);
  };
  return (
    <div className={styles.container} id="paymentPlan">
      <div className={styles.subContainer}>
        <h1 className={styles.heading}>Select your plan</h1>
        <h1 className={styles.subHeading}>
          Choose a plan that’s right for you
        </h1>
        {/* <div className={`${styles.scrollableContainer}`}> */}
        <div className={styles.plansContainer}>
          <BasicPlan/>
          <StandardPlan/>
          <ProPlan/>
          <CustomPlan/>
          
        </div>
        {/* </div> */}
        <div className={styles.onboardingContainer}>
          {/* <h1 className={styles.onboardingHeading}>
            On-boarding Perks and Services
          </h1> */}
          <div className={styles.listContainer}>
            {/* <ul className={styles.customOl}>
              {onbordinglistData.map((list, index) => {
                return (
                  <li className={styles.listItem} key={index}>
                    {list}
                  </li>
                );
              })}
            </ul> */}

            {/* <div className={styles.questionmarkcontainer}>
              <img
                src={questionMarkSvg}
                alt="questionMarkSvg"
                className={styles.questionMark}
              />
              <button
                className={styles.button}
                onClick={() => handleClickScroll("team")}
              >
                Contact sales
              </button>
            </div> */}
          </div>
          <div className={styles.noteContainer}>
            {/* <h1 className={styles.noteHeading}>Note</h1> */}
            <div className={styles.noteSubContainer}>
              <h1 className={styles.note}>
                *All messages between a business and user within 24 hours time will be counted as one conversation
              </h1>
              <h1 className={styles.note}>
                *The fee remains consistent for text, media & documents regardless of the message type.
              </h1>
              <h1 className={styles.note}>
                *The started prices are exclusive of an additional 18% GST
                charge.
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPlan;
